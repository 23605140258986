<div class="row">
  <div class="col-12">
    <div class="card">
      <div
        class="card-header spear-table-header-background d-flex justify-content-between"
      >
        <h6>{{ clientData?.client_name }}</h6>
        <div class="d-flex gap-1">
          <div>
            <button
              class="btn viewBtn btn-secondary text-white btn-sm"
              (click)="
                editDropdownMenu = false; newDropdownMenu = !newDropdownMenu
              "
            >
              New
            </button>
            <div
              class="bg-white p-2 rounded position-absolute z-index-1 dropdown-option border-grey"
              *ngIf="newDropdownMenu"
            >
              <ul>
                <li
                  class="border-bottom text-sm"
                  (click)="openPopup(newCaseModal)"
                >
                  New Case
                </li>
                <li class="text-sm" (click)="openPopup(newNoteModal)">
                  New Note
                </li>
                <li class="border-bottom text-sm"></li>
                <li class="text-sm" (click)="openPopup(newContactModal)">
                  New Contact
                </li>
              </ul>
            </div>
          </div>
          <div>
            <button
              class="btn viewBtn btn-info text-white btn-sm"
              (click)="
                newDropdownMenu = false; editDropdownMenu = !editDropdownMenu
              "
            >
              Edit
            </button>
            <div
              class="bg-white p-2 rounded position-absolute z-index-1 dropdown-option border-grey"
              *ngIf="editDropdownMenu"
            >
              <ul>
                <li
                  class="border-bottom text-sm"
                  (click)="openPopup(editContactModal)"
                >
                  Profile
                </li>
                <li class="text-sm">Notification Preferences</li>
              </ul>
            </div>
          </div>
          <div>
            <button
              class="btn viewBtn btn-danger text-white btn-sm"
              (click)="openDeletePopUP(deleteContactModal)"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="col-12 primary-tab">
          <ul class="nav nav-tabs">
            <li class="nav-item" *ngFor="let tab of pageTabs">
              <button
                class="nav-link text-uppercase"
                [ngClass]="{
                  'active search-spear-background': activeTab == tab
                }"
                (click)="activeTab = tab"
              >
                {{ tab }}
              </button>
            </li>
          </ul>
          <ng-container *ngIf="activeTab == 'Overview'">
            <div class="search-spear-background p-2">
              <div class="row my-3">
                <div class="col-12">
                  <div
                    class="accordian-tab card-header title-space spear-table-header-background"
                  >
                    <h6 class="m-0">Account Information</h6>
                    <div>
                      <button class="btn">
                        <em
                          (click)="generalInfo = !generalInfo"
                          class="fa fa-sort-desc"
                          [ngClass]="generalInfo ? '' : 'antirotate'"
                        ></em>
                      </button>
                    </div>
                  </div>
                  <div [ngClass]="generalInfo ? 'collapsecard' : 'expandcard'">
                    <div class="card border-0 card-scrollbar bg-transparent">
                      <div
                        class="card-body p-3 text-black-contrast text-sm text-dark-bold"
                      >
                        <div class="row">
                          <div class="col-1 text-right">Name:</div>
                          <div class="col-2">
                            {{ clientData?.program_name }}
                          </div>
                        </div>

                        <div class="row my-2">
                          <div class="col-1 text-right">Client:</div>
                          <div class="col-3">{{ clientData?.tpa }}</div>
                        </div>

                        <div class="row">
                          <div class="col-1 text-right">Role:</div>
                          <div class="col-3">{{ clientData?.mga }}</div>
                        </div>

                        <div class="row my-2">
                          <div class="col-1 text-right">Access Level:</div>
                          <div class="col-2">
                            An email address is required to enable system
                            access.
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-1 text-right">Main Phone:</div>
                          <div class="col-3">{{ clientData?.main_phone }}</div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-1 text-right">Fax:</div>
                          <div class="col-3">{{ clientData?.fax }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-12">
                  <div
                    class="accordian-tab card-header title-space spear-table-header-background"
                  >
                    <h6 class="m-0">Contact Information</h6>
                    <div>
                      <button class="btn">
                        <em
                          (click)="addressInfo = !addressInfo"
                          class="fa fa-sort-desc"
                          [ngClass]="addressInfo ? '' : 'antirotate'"
                        ></em>
                      </button>
                    </div>
                  </div>
                  <div [ngClass]="addressInfo ? 'collapsecard' : 'expandcard'">
                    <div class="card border-0 card-scrollbar bg-transparent">
                      <div
                        class="card-body p-3 text-black-contrast text-sm text-dark-bold"
                      >
                        <div class="row">
                          <div class="col-1 text-right">Primary Email:</div>
                          <div class="col-2">
                            {{ clientData?.address?.location_name }}
                          </div>
                        </div>

                        <div class="row my-2">
                          <div class="col-1 text-right">Location Address:</div>
                          <div class="col-3">
                            {{ clientData?.address?.country }}
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-1 text-right">Address:</div>
                          <div class="col-3">
                            {{ clientData?.address?.address }}
                          </div>
                        </div>

                        <div class="row my-2">
                          <div class="col-1 text-right">Home Phone:</div>
                          <div class="col-2">
                            {{ clientData?.address?.address_2 }}
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-1 text-right">Mobile Phone:</div>
                          <div class="col-3">
                            {{ clientData?.address?.mobile_phone }}
                          </div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-1 text-right">Work Phone:</div>
                          <div class="col-3">
                            {{ clientData?.address?.phone }}
                          </div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-1 text-right">Fax:</div>
                          <div class="col-3">
                            {{ clientData?.address?.Fax }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div
                    class="accordian-tab card-header title-space spear-table-header-background"
                  >
                    <h6 class="m-0">Other Information</h6>
                    <div>
                      <button class="btn">
                        <em
                          (click)="instructionNotesInfo = !instructionNotesInfo"
                          class="fa fa-sort-desc"
                          [ngClass]="instructionNotesInfo ? '' : 'antirotate'"
                        ></em>
                      </button>
                    </div>
                  </div>
                  <div
                    [ngClass]="
                      instructionNotesInfo ? 'collapsecard' : 'expandcard'
                    "
                  >
                    <div class="card border-0 card-scrollbar bg-transparent">
                      <div
                        class="card-body p-3 text-black-contrast text-sm text-dark-bold"
                      >
                        <div class="row">
                          <div class="col-1 text-right">Birthday:</div>
                          <div class="col-2">
                            {{ clientData?.client_instructions }}
                          </div>
                        </div>

                        <div class="row my-2">
                          <div class="col-1 text-right">Language:</div>
                          <div class="col-3">
                            <!-- {{ clientData?.invoice_instructions }} -->
                            English
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-1 text-right">Account Code:</div>
                          <div class="col-3">
                            {{ clientData?.general_notes }}
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-1 text-right">Tags:</div>
                          <div class="col-3">
                            {{ clientData?.tags }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div
                    class="accordian-tab card-header title-space spear-table-header-background"
                  >
                    <h6 class="m-0">Notes</h6>
                    <div>
                      <button class="btn">
                        <em
                          (click)="instructionNotesInfo = !instructionNotesInfo"
                          class="fa fa-sort-desc"
                          [ngClass]="instructionNotesInfo ? '' : 'antirotate'"
                        ></em>
                      </button>
                    </div>
                  </div>
                  <div
                    [ngClass]="
                      instructionNotesInfo ? 'collapsecard' : 'expandcard'
                    "
                  >
                    <div class="card border-0 card-scrollbar bg-transparent">
                      <div
                        class="card-body p-3 text-black-contrast text-sm text-dark-bold"
                      >
                        <div class="row">
                          <div class="col-1 text-right">General Notes:</div>
                          <div class="col-2">
                            {{ clientData?.client_instructions }}
                          </div>
                        </div>

                        <div class="row my-2">
                          <div class="col-1 text-right">Admin Notes:</div>
                          <div class="col-3">
                            {{ clientData?.invoice_instructions }}
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-1 text-right">Case Policies:</div>
                          <div class="col-3">
                            {{ clientData?.general_notes }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="activeTab == 'Permissions'">
            <div class="search-spear-background p-2">
              <div class="row my-3">
                <div class="col-12">
                  <div
                    class="accordian-tab card-header title-space spear-table-header-background"
                  >
                    <h6 class="m-0">Permissions / Client User</h6>
                    <div>
                      <button class="btn">
                        <em
                          (click)="generalInfo = !generalInfo"
                          class="fa fa-sort-desc"
                          [ngClass]="generalInfo ? '' : 'antirotate'"
                        ></em>
                      </button>
                    </div>
                  </div>
                  <div [ngClass]="generalInfo ? 'collapsecard' : 'expandcard'">
                    <div id="user-permissions">
                      <div
                        *ngFor="let permissionGroup of permissions"
                        class="list"
                      >
                        <table>
                          <thead>
                            <tr>
                              <th colspan="2">
                                {{ permissionGroup.name }} Permissions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let permission of permissionGroup.data">
                              <td class="center check">
                                <span class="checkbox">
                                  <input
                                    type="checkbox"
                                    [(ngModel)]="permission.checked"
                                  />
                                </span>
                              </td>
                              <td
                                class="text-sm"
                                (click)="toggleCheckbox(permission)"
                              >
                                {{ permission.permission }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="activeTab == 'Notifications'">
            <div class="search-spear-background p-2">
              <div class="row my-3">
                <div class="col-12">
                  <div
                    class="accordian-tab card-header title-space spear-table-header-background"
                  >
                    <h6 class="m-0">Notifications</h6>
                    <div>
                      <button class="btn">
                        <em
                          (click)="generalInfo = !generalInfo"
                          class="fa fa-sort-desc"
                          [ngClass]="generalInfo ? '' : 'antirotate'"
                        ></em>
                      </button>
                    </div>
                  </div>
                  <div [ngClass]="generalInfo ? 'collapsecard' : 'expandcard'">
                    <div class="card border-0 card-scrollbar">
                      <div
                        class="card-body p-3 text-black-contrast text-sm text-dark-bold"
                      >
                        <table class="table">
                          <thead>
                            <tr>
                              <th class="border-0" scope="col">Name</th>
                              <th class="border-0" scope="col">Title</th>
                              <th class="border-0" scope="col">Location</th>
                              <th class="border-0" scope="col">Email</th>
                              <th class="border-0" scope="col">Last Case</th>
                            </tr>
                          </thead>
                          <ng-container
                            *ngIf="
                              clientData?.contacts?.length;
                              else noContactFound
                            "
                          >
                            <tbody>
                              <tr
                                class="bg-white"
                                *ngFor="let contact of clientData?.contacts"
                              >
                                <td>
                                  {{
                                    contact?.first_name +
                                      " " +
                                      contact?.middle_name +
                                      " " +
                                      contact?.last_name
                                  }}
                                </td>
                                <td>{{ contact?.title }}</td>
                                <td>{{ contact?.location?.location_name }}</td>
                                <td>{{ contact?.email }}</td>
                                <td></td>
                              </tr>
                            </tbody>
                          </ng-container>
                          <ng-template #noContactFound>
                            <tbody>
                              <tr class="bg-white">
                                <td colspan="5" class="text-center">
                                  Data not available
                                </td>
                              </tr>
                            </tbody>
                          </ng-template>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="activeTab == 'Notes'">
            <div class="search-spear-background p-2">
              <div class="row my-3">
                <div class="col-12">
                  <div
                    class="accordian-tab card-header title-space spear-table-header-background"
                  >
                    <h6 class="m-0">Notes</h6>
                    <div>
                      <button
                        class="btn viewBtn btn-primary text-white btn-sm mr-2"
                      >
                        New Note
                      </button>
                      <button class="btn">
                        <em
                          (click)="generalInfo = !generalInfo"
                          class="fa fa-sort-desc"
                          [ngClass]="generalInfo ? '' : 'antirotate'"
                        ></em>
                      </button>
                    </div>
                  </div>
                  <div [ngClass]="generalInfo ? 'collapsecard' : 'expandcard'">
                    <div class="card border-0 card-scrollbar">
                      <div
                        class="card-body p-3 text-black-contrast text-sm text-dark-bold"
                      >
                        <table class="table">
                          <thead>
                            <tr>
                              <th class="border-0" scope="col">Date</th>
                              <th class="border-0" scope="col">Note</th>
                              <th class="border-0" scope="col">Attachment</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="bg-white">
                              <td colspan="3" class="text-center">
                                Data not available
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="activeTab == 'History'">
            <div class="search-spear-background p-2">
              <div class="row my-3">
                <div class="col-12">
                  <div
                    class="accordian-tab card-header title-space spear-table-header-background"
                  >
                    <h6 class="m-0">History</h6>
                    <div>
                      <button class="btn">
                        <em
                          (click)="generalInfo = !generalInfo"
                          class="fa fa-sort-desc"
                          [ngClass]="generalInfo ? '' : 'antirotate'"
                        ></em>
                      </button>
                    </div>
                  </div>
                  <div [ngClass]="generalInfo ? 'collapsecard' : 'expandcard'">
                    <div class="card border-0 card-scrollbar">
                      <div
                        class="card-body p-3 text-black-contrast text-sm text-dark-bold"
                      >
                        <table class="table">
                          <thead>
                            <tr>
                              <th class="border-0" scope="col">Date</th>
                              <th class="border-0" scope="col">Action</th>
                              <th class="border-0" scope="col">Category</th>
                              <th class="border-0" scope="col">Details</th>
                              <th class="border-0" scope="col">Action By</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="bg-white">
                              <td colspan="5" class="text-center">
                                Data not available
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #newCaseModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Add New Case</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="newCaseForm">
      <div class="form-group">
        <label for="clientSelect">Select Client:</label>
        <select
          class="form-control"
          id="clientSelect"
          formControlName="selectedClient"
        >
          <option
            *ngFor="let client of clients"
            [value]="client.value"
            [disabled]="client.disabled"
          >
            {{ client.label }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="clientSelect">Location:</label>
        <select
          class="form-control"
          id="locationSelect"
          formControlName="selectedLocation"
        >
          <option
            *ngFor="let location of locations"
            [value]="location.value"
            [disabled]="location.disabled"
          >
            {{ location.label }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="primaryContactSelect">Primary Contact:</label>
        <select
          class="form-control"
          id="primaryContactSelect"
          formControlName="primaryContact"
        >
          <optgroup *ngFor="let group of selectOptions" [label]="group.label">
            <option *ngFor="let option of group.options" [value]="option.value">
              {{ option.text }}
            </option>
          </optgroup>
        </select>
      </div>
      <div class="form-group">
        <label>Case Type:</label>
        <select name="caseType" class="form-control" formControlName="caseType">
          <option
            *ngFor="let caseTypes of caseType"
            [value]="caseTypes.value"
            [disabled]="caseTypes.disabled"
          >
            {{ caseTypes.label }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label>Assign myself as:</label>
        <select
          name="assignMyself"
          class="form-control"
          id="assignMyself"
          formControlName="selectedAssignMyself"
        >
          <option
            *ngFor="let assignMyself of assignMyself"
            [value]="assignMyself.value"
            [disabled]="assignMyself.disabled"
          >
            {{ assignMyself.label }}
          </option>
        </select>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #newNoteModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Add New Note</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      [formGroup]="clientLocationForm"
      (ngSubmit)="saveClientLocation()"
    ></form>
  </div>
</ng-template>

<ng-template #newContactModal let-modal>
  <div class="modal-header">
    <h6>New Contact</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body create-contact">
    <form [formGroup]="clientContactForm">
      <div class="row">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500 required" for="Name">Name</span>
            <div class="row mt-2">
              <div class="col-4">
                <label for="first_name" class="text-sm">First Name:</label>
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  class="form-control"
                  formControlName="first_name"
                />
              </div>

              <div class="col-4">
                <label for="middle_name" class="text-sm">Middle Name:</label>
                <input
                  type="text"
                  name="middle_name"
                  id="middle_name"
                  class="form-control"
                  formControlName="middle_name"
                />
              </div>

              <div class="col-4">
                <label for="last_name" class="text-sm">Last Name:</label>
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  class="form-control"
                  formControlName="last_name"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div>
            <label for="contact_title" class="text-sm">Title:</label>
            <input
              type="text"
              name="contact_title"
              id="contact_title"
              class="form-control"
              formControlName="title"
            />
          </div>
          <div>
            <div class="row">
              <div class="col-6">
                <label for="contact_role" class="text-sm">Role:</label>
                <select
                  type="text"
                  name="contact_role"
                  id="contact_role"
                  class="form-control"
                  formControlName="role"
                >
                  <option value="client user">Client User</option>
                </select>
              </div>

              <div class="col-6">
                <label for="contact_location" class="text-sm"
                  >Contact Location:</label
                >
                <select
                  type="text"
                  name="contact_location"
                  id="contact_location"
                  class="form-control"
                  formControlName="location"
                >
                  <ng-container *ngFor="let location of clientData?.locations">
                    <option [value]="location?.id ? location?.id : 1">
                      {{ location?.location_name }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <span class="mr-2 text-sm">Access Level:</span>
                <input
                  type="radio"
                  id="contact_disabled"
                  name="access_level"
                  value="Disabled"
                  formControlName="access_level"
                />
                <label for="contact_disabled" class="mx-2 text-sm"
                  >Disabled</label
                >

                <input
                  type="radio"
                  id="limited_access"
                  name="access_level"
                  value="Limited Access"
                  formControlName="access_level"
                />
                <label for="limited_access" class="mx-2 text-sm"
                  >Limited Access</label
                >

                <input
                  type="radio"
                  id="contact_full_location_access"
                  name="access_level"
                  value="Full Location Access"
                  formControlName="access_level"
                />
                <label for="contact_full_location_access" class="mx-2 text-sm"
                  >Full Location Access</label
                >

                <input
                  type="radio"
                  id="contact_full_access"
                  name="access_level"
                  value="Full Access"
                  formControlName="access_level"
                />
                <label for="contact_full_access" class="mx-2 text-sm"
                  >Full Access</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mb-4">
            <label for="email" class="text-sm">Email:</label>
            <input
              type="text"
              name="email"
              id="email"
              class="form-control"
              formControlName="email"
            />
          </div>
          <hr />
          <div>
            <span class="text-dark fw-500" for="address">Address</span>
            <div class="row mt-2">
              <div class="col-6">
                <label for="location" class="text-sm">Location:</label>
                <input
                  type="text"
                  name="location"
                  id="location"
                  class="form-control"
                  formControlName="location"
                />
              </div>

              <div class="col-6">
                <label for="country" class="text-sm">Country:</label>
                <input
                  type="text"
                  name="country"
                  id="country"
                  class="form-control"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label for="address_1" class="text-sm">Address 1:</label>
                <input
                  type="text"
                  name="address_1"
                  id="address_1"
                  class="form-control"
                />
              </div>

              <div class="col-4">
                <label for="address_2" class="text-sm">Address 2:</label>
                <input
                  type="text"
                  name="address_2"
                  id="address_2"
                  class="form-control"
                />
              </div>

              <div class="col-4">
                <label for="address_3" class="text-sm">Address 3:</label>
                <input
                  type="text"
                  name="address_3"
                  id="address_3"
                  class="form-control"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label for="city" class="text-sm">City:</label>
                <input type="text" name="city" id="city" class="form-control" />
              </div>
              <div class="col-4">
                <label for="state" class="text-sm">State:</label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  class="form-control"
                />
              </div>
              <div class="col-4">
                <label for="zip" class="text-sm">Zip/Postal Code:</label>
                <input type="text" name="zip" id="zip" class="form-control" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500" for="Phone">Phone</span>
            <div class="row mt-2">
              <div class="col-4">
                <label for="office_phone" class="text-sm">Office Phone:</label>
                <input
                  type="text"
                  name="office_phone"
                  id="office_phone"
                  class="form-control"
                  formControlName="office_phone"
                />
              </div>

              <div class="col-4">
                <label for="mobile_phone" class="text-sm">Mobile Phone:</label>
                <input
                  type="text"
                  name="mobile_phone"
                  id="mobile_phone"
                  class="form-control"
                  formControlName="mobile_phone"
                />
              </div>

              <div class="col-4">
                <label for="home_phone" class="text-sm">Home Phone:</label>
                <input
                  type="text"
                  name="home_phone"
                  id="home_phone"
                  class="form-control"
                  formControlName="home_phone"
                />
              </div>

              <div class="col-4">
                <label for="fax" class="text-sm">Fax:</label>
                <input
                  type="text"
                  name="fax"
                  id="fax"
                  class="form-control"
                  formControlName="fax"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500" for="address"
              >Other Information</span
            >

            <div class="row mt-2">
              <div class="col-3">
                <label for="birthday" class="text-sm">Birthday:</label>
                <input
                  type="text"
                  name="birthday"
                  id="birthday"
                  class="form-control"
                  [formCtrl]="clientContactForm.get('birthday')"
                  jDate
                />
              </div>

              <div class="col-3">
                <label for="language" class="text-sm">Language:</label>
                <select
                  type="text"
                  name="language"
                  id="language"
                  class="form-control"
                  formControlName="language"
                >
                  <option value="english">English</option>
                </select>
              </div>

              <div class="col-3">
                <label for="account_code" class="text-sm">Account Code:</label>
                <input
                  type="text"
                  name="account_code"
                  id="account_code"
                  class="form-control"
                  formControlName="account_code"
                />
              </div>

              <div class="col-3">
                <label for="salesperson" class="text-sm">Salesperson:</label>
                <select
                  type="text"
                  name="salesperson"
                  id="salesperson"
                  class="form-control"
                  formControlName="salesperson"
                >
                  <option value="none">None</option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <label for="case_manager" class="text-sm">Case Manager:</label>
                <input
                  type="text"
                  name="case_manager"
                  id="case_manager"
                  class="form-control"
                  formControlName="case_manager"
                />
              </div>

              <div class="col-6">
                <label for="tags" class="text-sm">Tags:</label>
                <input
                  type="text"
                  name="tags"
                  id="tags"
                  class="form-control"
                  formControlName="tags"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500"> Notes</span>
            <div class="row mt-2">
              <div class="col-4">
                <label for="general_notes" class="text-sm"
                  >General Notes:</label
                >
                <textarea
                  type="text"
                  name="general_notes"
                  id="general_notes"
                  class="border-grey w-100"
                  formControlName="general_notes"
                ></textarea>
              </div>

              <div class="col-4">
                <label for="admin_policies" class="text-sm"
                  >Admin Policies:</label
                >
                <textarea
                  type="text"
                  name="admin_policies"
                  id="admin_policies"
                  class="border-grey w-100"
                  formControlName="admin_notes"
                ></textarea>
              </div>

              <div class="col-4">
                <label for="case_policies" class="text-sm"
                  >Case Policies:</label
                >
                <textarea
                  type="text"
                  name="case_policies"
                  id="case_policies"
                  class="border-grey w-100"
                  formControlName="case_policies"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500" for="address"> Custom Fields</span>
            <div class="row mt-2">
              <div class="col-3">
                <label for="general_notes" class="text-sm"
                  >Contact Address:</label
                >
                <select class="form-control">
                  <option value="united state">United State</option>
                </select>
              </div>

              <div class="col-3">
                <label for="Address" class="text-sm">Address1:</label>
                <input
                  type="text"
                  name="Address"
                  id="Address"
                  class="form-control"
                />
              </div>

              <div class="col-3">
                <label for="update_policies" class="text-sm">Address2:</label>
                <input
                  type="text"
                  name="Address2"
                  id="Address2"
                  class="form-control"
                />
              </div>

              <div class="col-3">
                <label for="Address3" class="text-sm">Address3:</label>
                <input
                  type="text"
                  name="address3"
                  id="address3"
                  class="form-control"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label for="City" class="text-sm">City:</label>
                <input type="text" name="city" id="city" class="form-control" />
              </div>
              <div class="col-3">
                <label for="state" class="text-sm">State:</label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  class="form-control"
                />
              </div>
              <div class="col-3">
                <label for="zipcode" class="text-sm">Zip Code:</label>
                <input
                  type="text"
                  name="zipcode"
                  id="zipcode"
                  class="form-control"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label for="contact" class="text-sm">Successive Contact:</label>
                <input
                  type="text"
                  name="successive_contact"
                  id="successive_contact"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right mt-4">
        <button
          class="btn btn-secondary btn-sm text-white mr-1"
          (click)="modalService.dismissAll()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn spear-button-background btn-sm text-white"
          (click)="saveClientContact()"
          [disabled]="isClientContactFormSubmitted || clientContactForm.invalid"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #editContactModal let-modal>
  <div class="modal-header">
    <h6>Edit Contact</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body create-contact">
    <form [formGroup]="clientContactForm">
      <div class="row">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500 required" for="Name">Name</span>
            <div class="row mt-2">
              <div class="col-4">
                <label for="first_name" class="text-sm">First Name:</label>
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  class="form-control"
                  formControlName="first_name"
                />
              </div>

              <div class="col-4">
                <label for="middle_name" class="text-sm">Middle Name:</label>
                <input
                  type="text"
                  name="middle_name"
                  id="middle_name"
                  class="form-control"
                  formControlName="middle_name"
                />
              </div>

              <div class="col-4">
                <label for="last_name" class="text-sm">Last Name:</label>
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  class="form-control"
                  formControlName="last_name"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-12">
          <div>
            <label for="contact_title" class="text-sm">Title:</label>
            <input
              type="text"
              name="contact_title"
              id="contact_title"
              class="form-control"
              formControlName="title"
            />
          </div>
          <div>
            <div class="row">
              <div class="col-6">
                <label for="contact_role" class="text-sm">Role:</label>
                <select
                  type="text"
                  name="contact_role"
                  id="contact_role"
                  class="form-control"
                  formControlName="role"
                >
                  <option value="client user">Client User</option>
                </select>
              </div>

              <div class="col-6">
                <label for="contact_location" class="text-sm"
                  >Contact Location:</label
                >
                <select
                  type="text"
                  name="contact_location"
                  id="contact_location"
                  class="form-control"
                  formControlName="location"
                >
                  <ng-container *ngFor="let location of clientData?.locations">
                    <option [value]="location?.id ? location?.id : 1">
                      {{ location?.location_name }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <span class="mr-2">Access Level:</span>
                <input
                  type="radio"
                  id="contact_disabled"
                  name="access_level"
                  value="Disabled"
                  formControlName="access_level"
                />
                <label for="contact_disabled" class="mx-2 text-sm"
                  >Disabled</label
                >

                <input
                  type="radio"
                  id="limited_access"
                  name="access_level"
                  value="Limited Access"
                  formControlName="access_level"
                />
                <label for="limited_access" class="mx-2 text-sm"
                  >Limited Access</label
                >

                <input
                  type="radio"
                  id="contact_full_location_access"
                  name="access_level"
                  value="Full Location Access"
                  formControlName="access_level"
                />
                <label for="contact_full_location_access" class="mx-2 text-sm"
                  >Full Location Access</label
                >

                <input
                  type="radio"
                  id="contact_full_access"
                  name="access_level"
                  value="Full Access"
                  formControlName="access_level"
                />
                <label for="contact_full_access" class="mx-2 text-sm"
                  >Full Access</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div>
            <label for="email" class="text-sm">Email:</label>
            <input
              type="text"
              name="email"
              id="email"
              class="form-control"
              formControlName="email"
            />
          </div>

          <hr />
          <div>
            <span class="text-dark fw-500" for="address">Address</span>
            <div class="row mt-2">
              <div class="col-6">
                <label for="location" class="text-sm">Location:</label>
                <input
                  type="text"
                  name="location"
                  id="location"
                  class="form-control"
                  formControlName="location"
                />
              </div>

              <div class="col-6">
                <label for="country" class="text-sm">Country:</label>
                <input
                  type="text"
                  name="country"
                  id="country"
                  class="form-control"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label for="address_1" class="text-sm">Address 1:</label>
                <input
                  type="text"
                  name="address_1"
                  id="address_1"
                  class="form-control"
                />
              </div>

              <div class="col-4">
                <label for="address_2" class="text-sm">Address 2:</label>
                <input
                  type="text"
                  name="address_2"
                  id="address_2"
                  class="form-control"
                />
              </div>

              <div class="col-4">
                <label for="address_3" class="text-sm">Address 3:</label>
                <input
                  type="text"
                  name="address_3"
                  id="address_3"
                  class="form-control"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label for="city" class="text-sm">City:</label>
                <input type="text" name="city" id="city" class="form-control" />
              </div>
              <div class="col-4">
                <label for="state" class="text-sm">State:</label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  class="form-control"
                />
              </div>
              <div class="col-4">
                <label for="zip" class="text-sm">Zip/Postal Code:</label>
                <input type="text" name="zip" id="zip" class="form-control" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500" for="Phone">Phone</span>
            <div class="row mt-2">
              <div class="col-4">
                <label for="office_phone" class="text-sm">Office Phone:</label>
                <input
                  type="text"
                  name="office_phone"
                  id="office_phone"
                  class="form-control"
                  formControlName="office_phone"
                />
              </div>

              <div class="col-4">
                <label for="mobile_phone" class="text-sm">Mobile Phone:</label>
                <input
                  type="text"
                  name="mobile_phone"
                  id="mobile_phone"
                  class="form-control"
                  formControlName="mobile_phone"
                />
              </div>

              <div class="col-4">
                <label for="home_phone" class="text-sm">Home Phone:</label>
                <input
                  type="text"
                  name="home_phone"
                  id="home_phone"
                  class="form-control"
                  formControlName="home_phone"
                />
              </div>

              <div class="col-12">
                <label for="fax" class="text-sm">Fax:</label>
                <input
                  type="text"
                  name="fax"
                  id="fax"
                  class="form-control"
                  formControlName="fax"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500" for="address"
              >Other Information</span
            >

            <div class="row mt-2">
              <div class="col-3">
                <label for="birthday" class="text-sm">Birthday:</label>
                <input
                  type="text"
                  name="birthday"
                  id="birthday"
                  class="form-control"
                  [formCtrl]="clientContactForm.get('birthday')"
                  jDate
                />
              </div>

              <div class="col-3">
                <label for="language" class="text-sm">Language:</label>
                <select
                  type="text"
                  name="language"
                  id="language"
                  class="form-control"
                  formControlName="language"
                >
                  <option value="english">English</option>
                </select>
              </div>

              <div class="col-3">
                <label for="account_code" class="text-sm">Account Code:</label>
                <input
                  type="text"
                  name="account_code"
                  id="account_code"
                  class="form-control"
                  formControlName="account_code"
                />
              </div>

              <div class="col-3">
                <label for="salesperson" class="text-sm">Salesperson:</label>
                <select
                  type="text"
                  name="salesperson"
                  id="salesperson"
                  class="form-control"
                  formControlName="salesperson"
                >
                  <option value="none">None</option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <label for="case_manager" class="text-sm">Case Manager:</label>
                <input
                  type="text"
                  name="case_manager"
                  id="case_manager"
                  class="form-control"
                  formControlName="case_manager"
                />
              </div>

              <div class="col-6">
                <label for="tags" class="text-sm">Tags:</label>
                <input
                  type="text"
                  name="tags"
                  id="tags"
                  class="form-control"
                  formControlName="tags"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500"> Notes</span>
            <div class="row mt-2">
              <div class="col-4">
                <label for="general_notes" class="text-sm"
                  >General Notes:</label
                >
                <textarea
                  type="text"
                  name="general_notes"
                  id="general_notes"
                  class="border-grey w-100"
                  formControlName="general_notes"
                ></textarea>
              </div>

              <div class="col-4">
                <label for="admin_policies" class="text-sm"
                  >Admin Policies:</label
                >
                <textarea
                  type="text"
                  name="admin_policies"
                  id="admin_policies"
                  class="border-grey w-100"
                  formControlName="admin_notes"
                ></textarea>
              </div>

              <div class="col-4">
                <label for="case_policies" class="text-sm"
                  >Case Policies:</label
                >
                <textarea
                  type="text"
                  name="case_policies"
                  id="case_policies"
                  class="border-grey w-100"
                  formControlName="case_policies"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500" for="address"> Custom Fields</span>
            <div class="row mt-2">
              <div class="col-3">
                <label for="general_notes" class="text-sm"
                  >Contact Address:</label
                >
                <select class="form-control">
                  <option value="united state">United State</option>
                </select>
              </div>

              <div class="col-3">
                <label for="Address" class="text-sm">Address1:</label>
                <input
                  type="text"
                  name="Address"
                  id="Address"
                  class="form-control"
                />
              </div>

              <div class="col-3">
                <label for="update_policies" class="text-sm">Address2:</label>
                <input
                  type="text"
                  name="Address2"
                  id="Address2"
                  class="form-control"
                />
              </div>

              <div class="col-3">
                <label for="Address3" class="text-sm">Address3:</label>
                <input
                  type="text"
                  name="address3"
                  id="address3"
                  class="form-control"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <label for="City" class="text-sm">City:</label>
                <input type="text" name="city" id="city" class="form-control" />
              </div>
              <div class="col-3">
                <label for="state" class="text-sm">State:</label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  class="form-control"
                />
              </div>
              <div class="col-3">
                <label for="zipcode" class="text-sm">Zip Code:</label>
                <input
                  type="text"
                  name="zipcode"
                  id="zipcode"
                  class="form-control"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label for="contact" class="text-sm">Successive contact:</label>
                <input
                  type="text"
                  name="successive_contact"
                  id="successive_contact"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right mt-4">
        <button
          class="btn btn-secondary btn-sm text-white mr-1"
          (click)="modalService.dismissAll()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn spear-button-background btn-sm text-white"
          (click)="saveClientContact()"
          [disabled]="isClientContactFormSubmitted || clientContactForm.invalid"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #deleteContactModal let-modal>
  <div class="modal-header">
    <h6>Delete Contact</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to delete this contact?</p>
  </div>
  <div class="text-right mb-2 mr-1">
    <button
      type="button"
      class="btn btn-secondary btn-sm text-white mr-1"
      (click)="modalService.dismissAll()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn spear-button-background btn-sm text-white"
      (click)="confirmDelete()"
    >
      Delete
    </button>
  </div>
</ng-template>
