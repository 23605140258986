import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private router: Router,
              private authService: AuthService) { }

ngOnInit() {
   this.authService.clearStorage();
    this.authService.notifyToLoadCurrentUserProfile();
    //this.router.navigate(['/login']);
    window.open('login', '_self');
}

}
