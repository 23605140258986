import { Component, OnInit, Input } from '@angular/core';
import { ManageUsersService } from 'src/app/services/manage.users.service';
import { ToastrService } from 'ngx-toastr';
import { AppUtils } from 'src/app/utilities/app.utils';
import { UserInfo } from 'src/app/models/manage-users/user.info';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { isNullOrUndefined } from 'util';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-add-or-edit-user',
  templateUrl: './add-or-edit-user.component.html',
  styleUrls: ['./add-or-edit-user.component.css']
})
export class AddOrEditUserComponent implements OnInit {
  @BlockUI('show-loader') blockUI: NgBlockUI;
  @Input() public userId: string;

  roles: any[];
  isEditOperation: boolean;
  private userInfo: UserInfo = {
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    roleId: undefined,
    userId: '',
    status: '',
   isActive: false,
   createdOn: null,
   updatedOn: null

  };
  constructor(private ngbActiveModal: NgbActiveModal,
              private manageUserService: ManageUsersService,
              private appUtils: AppUtils,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.loadRoles();
    if (this.userId != null || this.userId !== undefined) {
      this.isEditOperation = true;
      this.loadUserInfo();
    }
  }

  loadUserInfo(): void{
    this.blockUI.start();
    this.manageUserService.getUserInfoById(this.userId).subscribe(data => {
      this.userInfo = data as UserInfo;
      this.blockUI.stop();
    });
  }

  get activeModal(): any {
    return this.ngbActiveModal;
  }
  loadRoles(): void{
    this.manageUserService.getRoles().subscribe(data => {
     this.roles = data as {roleId: string, name: string}[];
    }, error => {
      this.appUtils.ProcessErrorResponse(this.toastr, error);
    });
  }

  AddOrUpdate(): void
  {
    this.blockUI.start();
    if (this.userId == null || this.userId === undefined){
      this.addUser();
    } else {
        this.updateUser();
    }

  }

  private addUser(): void {
    const model = {firstName: this.userInfo.firstName,
                lastName: this.userInfo.lastName,
                email: this.userInfo.email,
                roleId: this.userInfo.roleId};

    this.manageUserService.createUser(model).subscribe(data => {
        this.ngbActiveModal.close();
        this.manageUserService.notifyToLoadUsers();
        this.toastr.success(data);
        this.blockUI.stop();
       }, error => {
         this.appUtils.ProcessErrorResponse(this.toastr, error);
         this.blockUI.stop();
       });
  }

  private updateUser(): void{
    const model = {userId: this.userId, firstName: this.userInfo.firstName,
      lastName: this.userInfo.lastName,
      email: this.userInfo.email,
      roleId: this.userInfo.roleId};

    this.manageUserService.editUser(model).subscribe(data => {
          this.manageUserService.notifyToLoadUsers();
          this.ngbActiveModal.close();
          this.toastr.success(data);
          this.blockUI.stop();
}, error => {
this.appUtils.ProcessErrorResponse(this.toastr, error);
this.blockUI.stop();
});
  }


}
