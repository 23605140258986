import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { ProvidertablesService } from 'src/app/services/providertables.service';

@Component({
  selector: 'app-entity',
  templateUrl: './entity.component.html',
  styleUrls: ['./entity.component.css']
})
export class EntityComponent implements OnInit {

  constructor(
    private _NgbActiveModal: NgbActiveModal, 
    private toastr: ToastrService,
    private http: HttpClient,
  ) { }

  @Output() evt: EventEmitter<boolean> = new EventEmitter();

  entityChoice: string;

  ngOnInit(): void {}

  get activeModal() {
    return this._NgbActiveModal;
  }

  confirmed(){
    this.activeModal.dismiss();
    this.evt.emit(true);
  }

}
