<!-- Modal -->
<div *blockUI="'show-loader'">
    <div class="modal-header">
        <h4 class="modal-title">Alert Detail</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="flex justify-content-center w-full">   
            {{lang}} 
        </div>
    </div>
</div>
