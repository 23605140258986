import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProviderService } from 'src/app/services/provider.service';
import { AppUtils } from 'src/app/utilities/app.utils';

@Component({
  selector: 'app-smart-merge',
  templateUrl: './smart-merge.component.html',
  styleUrls: ['./smart-merge.component.css']
})
export class SmartMergeComponent implements OnInit {

  page = 1;
  pageSize = 6;

  //sample data created for setting
  items = [
    { id: 1, title: 'Global', Company: 'Successive', NPI: 123123, routerlnk: '/providers/manual-merge' },
    { id: 2, title: 'Users and Roles', Company: 'Abc Company', NPI: 59917, routerlnk: '/providers/manual-merge' },
    { id: 3, title: 'Are baba', Company: 'Successive', NPI: 123123, routerlnk: '/providers/manual-merge' },
    { id: 4, title: 'Sample data 1', Company: 'Abc Company', NPI: 59917, routerlnk: '/providers/manual-merge' },
    { id: 5, title: 'Sample data 2', Company: 'Successive', NPI: 123123, routerlnk: '/providers/manual-merge' },
    { id: 6, title: 'Sample data 3', Company: 'Abc Company', NPI: 59917, routerlnk: '/providers/manual-merge' },
    { id: 7, title: 'Sample data 4', Company: 'Successive', NPI: 123123, routerlnk: '/providers/manual-merge' },
    { id: 8, title: 'Sample data 5', Company: 'Abc Company', NPI: 59917, routerlnk: '/providers/manual-merge' },
    { id: 9, title: 'Sample data 6', Company: 'Successive', NPI: 123123, routerlnk: '/providers/manual-merge' },
    { id: 10, title: 'Sample data 7', Company: 'Abc Company', NPI: 59917, routerlnk: '/providers/manual-merge' },
    { id: 11, title: 'Sample data 8', Company: 'Successive', NPI: 123123, routerlnk: '/providers/manual-merge' },
    { id: 12, title: 'Sample data 9', Company: 'Abc Company', NPI: 59917, routerlnk: '/providers/manual-merge' }
  ];

  ngOnInit() { }



}
