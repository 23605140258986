import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import {
  ClaimTable,
  GetLiens,
  GetTimeSpentResponse,
  GetTimeSpentResponseDetail,
  Invoice,
  LimosInvoiceData,
  LimosTable,
  NoteTable,
} from 'src/app/models/limos/limos.models';
import { LimosService } from 'src/app/services/limos.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'time-management',
  templateUrl: './time-management.component.html',
  styleUrls: ['./time-management.component.css'],
})
export class TimeManagementComponent implements OnInit, OnDestroy {
  public adjNumber!: number;
  public limosStatus = new FormControl();
  public selectedLienType: string = 'Select a lien type';
  public selectedAssignedType: string = 'Select a assigned type';
  public limos_status: boolean = false;
  @BlockUI('team-list') teamBlockUI: NgBlockUI;
  @BlockUI('manage-users-blockUI') public blockUI: NgBlockUI;

  @Input() claimantTable = [];
  public form: FormGroup;
  public emptyForm: boolean = false;
  public adminValueChangeSubscription!: Subscription;
  public formValueChangeSubscription!: Subscription;
  public showTimePostForm: boolean = false;
  @Input() public claimTable: ClaimTable;
  public limosTable: LimosTable;
  private limosId: number;
  public showInvoiceButton: boolean = false;
  public serverDateFormat = 'yyyy-MM-dd';
  public todayDate: Date = new Date();
  public noteTable: NoteTable[] = [];
  @Input() activeTab: string;
  editor = ClassicEditor;
  @Input() invoices: Invoice[] = [];
  limosInvoiceData: LimosInvoiceData;
  public timeTable: GetTimeSpentResponseDetail[] = [];
  private rowidTime: string;
  private timeState = {
    log_date: null,
    provider: null,
    time_type: null,
    note: null,
    time_spent: null,
  };
  public deleTimeEntry = '';
  public claimNumber: string;
  public deleClaimantEntry = '';

  constructor(
    private router: Router,
    private http: HttpClient,
    private datePipe: DatePipe,
    private limosService: LimosService,
    private addModal: NgbModal,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.limosId = this.activatedRoute.snapshot.params['limos_id'] || '';
    this.populateTimeManagementTable();
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      provider: new FormControl('', [Validators.required]),
      time_type: new FormControl('', [Validators.required]),
      note: new FormControl('', [Validators.required]),
      time_spent: new FormControl('', [Validators.required]),
      date: new FormControl(
        this.datePipe.transform(this.todayDate, 'MM/dd/yyyy'),
        [Validators.required]
      ),
    });

    this.formValueChangeSubscription = this.form.valueChanges.subscribe(() => {
      this.emptyForm = Object.keys(this.form.controls).every(
        (formKey) => this.form.controls[formKey].value
      );
    });
  }

  visTimePostForm(): void {
    this.showTimePostForm = true;
  }
  onInvoiceListReceived(invoices: Invoice[]) {
    this.invoices = invoices;
  }

  saveTimePostForm(): void {
    const controls = this.form.controls;
    let reqBody = {
      provider_name_business: '',
      time_type: '',
      note: '',
      time_spent: 0,
      log_date: '',
    };
    if (this.emptyForm) {
      if (controls.provider.value) {
        reqBody.provider_name_business = controls.provider.value;
      }
      if (controls.time_type.value) {
        reqBody.time_type = controls.time_type.value;
      }
      if (controls.note.value) {
        reqBody.note = controls.note.value;
      }
      if (controls.date.value) {
        reqBody.log_date = this.datePipe.transform(
          controls.date.value,
          this.serverDateFormat
        );
      }
      if (controls.time_spent.value) {
        reqBody.time_spent = Math.round(controls.time_spent.value * 3600);
      }
      this.http
        .post(
          `${environment.fireStoreApiUrl}/api/v1/liens/time-spent/`,
          reqBody
        )
        .subscribe((data) => {
          this.showTimePostForm = false;
          this.emptyForm = false;
          this.form.reset();
          this.form
            .get('date')
            .setValue(this.datePipe.transform(this.todayDate, 'MM/dd/yyyy'));
          this.populateTimeManagementTable();
        });
    }
  }

  hideTimePostForm() {
    this.form.reset();
    this.form
      .get('date')
      .setValue(this.datePipe.transform(this.todayDate, 'MM/dd/yyyy'));
    this.showTimePostForm = false;
  }

  populateTimeManagementTable(): void {
    this.teamBlockUI.start();

    this.http
      .get<GetTimeSpentResponse>(
        `${environment.fireStoreApiUrl}/api/v1/liens/time-spent/all/`
      )
      .subscribe((data) => {
        if (data) {
          this.timeTable = data.details;
        }
        this.teamBlockUI.stop();
      });
  }

  deleteTime(): void {
    this.http
      .delete(
        `${environment.fireStoreApiUrl}/api/v1/liens/time-spent/${this.deleTimeEntry}`
      )
      .subscribe((data) => {
        this.http
          .get<GetTimeSpentResponse>(
            `${environment.fireStoreApiUrl}/api/v1/liens/time-spent/all/`
          )
          .subscribe((data) => {
            if (data) {
              this.timeTable = data.details;
            }
          });
      });
    this.addModal.dismissAll();
  }

  deleteClaimant(): void {
    this.http
      .delete(
        `${environment.fireStoreApiUrl}/api/v1/liens/lien-claimant/delete/${this.deleClaimantEntry}`
      )
      .subscribe((data) => {
        this.http
          .get<GetLiens>(
            `${environment.fireStoreApiUrl}/api/v1/liens/${this.claimNumber}/${this.limosId}/`
          )
          .subscribe((claimData) => {
            if (claimData.details.lien_claimants) {
              this.blockUI.start();
              this.claimantTable = [];
              for (
                let i = 0;
                i < claimData.details.lien_claimants.length;
                i++
              ) {
                let sav;
                if (
                  claimData.details.lien_claimants[i].lien_resolved_amount &&
                  claimData.details.lien_claimants[i].lien_requested_amount
                ) {
                  let num =
                    (1 -
                      claimData.details.lien_claimants[i].lien_resolved_amount /
                        claimData.details.lien_claimants[i]
                          .lien_requested_amount) *
                    100;
                  sav = Math.round(num * 100) / 100;
                }
                this.claimantTable.push({
                  provider_name_business:
                    claimData.details.lien_claimants[i].provider_name_business,
                  tax_id_number:
                    claimData.details.lien_claimants[i].tax_id_number,
                  lien_type: claimData.details.lien_claimants[i].lien_type,
                  provider_npi_number:
                    claimData.details.lien_claimants[i].provider_npi_number,
                  lien_request_date:
                    claimData.details.lien_claimants[i].lien_request_date,
                  lien_created:
                    claimData.details.lien_claimants[i].lien_created,
                  lien_amount: claimData.details.lien_claimants[i].lien_amount,
                  lien_requested_amount:
                    claimData.details.lien_claimants[i].lien_requested_amount,
                  authority_requested:
                    claimData.details.lien_claimants[i].authority_requested,
                  assigned: claimData.details.lien_claimants[i].assigned,
                  case_status: claimData.details.liens_profile.case_status,
                  lien_resolved_date:
                    claimData.details.lien_claimants[i].lien_resolved_date,
                  lien_resolved_amount:
                    claimData.details.lien_claimants[i].lien_resolved_amount,
                  id: claimData.details.lien_claimants[i].id,
                  saving: sav || null,
                });
              }
            }
            this.blockUI.stop();
          });
      });
    this.addModal.dismissAll();
  }

  public showInvoiceBtn(): boolean {
    return (
      this.timeTable?.length && this.timeTable.some((time) => time?.checked)
    );
  }

  public isCheckAllTimeMgmt(): boolean {
    return (
      this.timeTable?.length ===
      this.timeTable.filter((time) => time?.checked).length
    );
  }

  public checkAllTimeMgmt(event) {
    if (this.timeTable?.length) {
      this.timeTable.map((time) => (time.checked = event.target.checked));
    }
  }

  public convertToInvoice() {
    const limosInVoiceData: LimosInvoiceData = {
      profile_id: this.limosId,
      case_number: this.activatedRoute.snapshot.params['limos_id'] || '',
      created_on: new Date().toLocaleDateString('en-US'),
      due_on: new Date(
        Date.now() + 30 * 24 * 60 * 60 * 1000
      ).toLocaleDateString('en-US'),
      billing_to: 'SEDGWICK',
      billing_location: 'PO Box 14779 Lexington, KY 40512',
      billing_name: this.claimTable?.bill_review_adjuster_name,
      billing_email: this.limosTable?.email_1,
      billing_contact: this.limosTable?.phone_1,
      claim_number: this.claimTable?.claimNo,
      claimant_name: this.claimTable?.claimant_name,
      time_spents: this.timeTable.filter((spent) => spent?.checked),
      claimantTableData: this.claimantTable,
    };

    this.limosService.setlimosInvoiceData(limosInVoiceData);
    this.router.navigate(['/limos/invoice']);
  }

  makeEditableTime(id: string) {
    this.rowidTime = '';
    this.rowidTime = id;
    document.getElementById(`editTime-${id}`).hidden = true;
    document.getElementById(`deleteTime-${id}`).hidden = true;
    document.getElementById(`saveTime-${id}`).hidden = false;
    document.getElementById(`cancelTime-${id}`).hidden = false;
    document.getElementById(`log_date-${id}`).removeAttribute('disabled');
    document.getElementById(`provider_name-${id}`).removeAttribute('disabled');
    document.getElementById(`time_type-${id}`).removeAttribute('disabled');
    document.getElementById(`note-${id}`).removeAttribute('disabled');
    document.getElementById(`time_spent-${id}`).removeAttribute('disabled');
    this.timeState.log_date = (<HTMLInputElement>(
      document.getElementById(`log_date-${id}`)
    )).value
      ? (<HTMLInputElement>document.getElementById(`log_date-${id}`)).value
      : null;
    this.timeState.provider = (<HTMLInputElement>(
      document.getElementById(`provider_name-${id}`)
    )).value
      ? (<HTMLInputElement>document.getElementById(`provider_name-${id}`)).value
      : null;
    this.timeState.time_type = (<HTMLInputElement>(
      document.getElementById(`time_type-${id}`)
    )).value
      ? (<HTMLInputElement>document.getElementById(`time_type-${id}`)).value
      : null;
    this.timeState.note = (<HTMLInputElement>(
      document.getElementById(`note-${id}`)
    )).value
      ? (<HTMLInputElement>document.getElementById(`note-${id}`)).value
      : null;
    this.timeState.time_spent = (<HTMLInputElement>(
      document.getElementById(`time_spent-${id}`)
    )).value
      ? (<HTMLInputElement>document.getElementById(`time_spent-${id}`)).value
      : null;
    let editBut = document.querySelectorAll('.editTime');
    for (let i = 0; i < editBut.length; i++) {
      editBut[i].setAttribute('disabled', 'disabled');
    }
  }

  saveEditableTime(id: string) {
    document
      .getElementById(`log_date-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`provider_name-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`time_type-${id}`)
      .setAttribute('disabled', 'disabled');
    document.getElementById(`note-${id}`).setAttribute('disabled', 'disabled');
    document
      .getElementById(`time_spent-${id}`)
      .setAttribute('disabled', 'disabled');
    let rawString = (<HTMLInputElement>(
      document.getElementById(`time_spent-${id}`)
    )).value;
    let num = Number(rawString.replace(' Hrs', '')) * 3600;
    let reqBody = {
      log_date: this.toServerDateFormat(`log_date-${id}`),
      provider_name_business: (<HTMLInputElement>(
        document.getElementById(`provider_name-${id}`)
      )).value,
      time_type: (<HTMLInputElement>document.getElementById(`time_type-${id}`))
        .value,
      note: (<HTMLInputElement>document.getElementById(`note-${id}`)).value,
      time_spent: Math.round(num),
    };
    if (!this.deepEqual(reqBody, this.timeState)) {
      this.http
        .patch(
          `${environment.fireStoreApiUrl}/api/v1/liens/time-spent/editable/${id}`,
          reqBody
        )
        .subscribe();
    }

    document.getElementById(`editTime-${id}`).hidden = false;
    document.getElementById(`saveTime-${id}`).hidden = true;
    document.getElementById(`cancelTime-${id}`).hidden = true;
    document.getElementById(`deleteTime-${id}`).hidden = false;

    let editBut = document.querySelectorAll('.editTime');
    for (let i = 0; i < editBut.length; i++) {
      editBut[i].removeAttribute('disabled');
    }
  }

  private toServerDateFormat(dateEleId: string) {
    const date = (document.getElementById(dateEleId) as HTMLInputElement).value;
    return date ? this.datePipe.transform(date, this.serverDateFormat) : null;
  }

  cancelEditableTime(id: string) {
    (<HTMLInputElement>document.getElementById(`log_date-${id}`)).value =
      this.timeState.log_date;
    (<HTMLInputElement>document.getElementById(`provider_name-${id}`)).value =
      this.timeState.provider;
    (<HTMLInputElement>document.getElementById(`time_type-${id}`)).value =
      this.timeState.time_type;
    (<HTMLInputElement>document.getElementById(`note-${id}`)).value =
      this.timeState.note;
    (<HTMLInputElement>document.getElementById(`time_spent-${id}`)).value =
      this.timeState.time_spent;

    document
      .getElementById(`log_date-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`provider_name-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`time_type-${id}`)
      .setAttribute('disabled', 'disabled');
    document.getElementById(`note-${id}`).setAttribute('disabled', 'disabled');
    document
      .getElementById(`time_spent-${id}`)
      .setAttribute('disabled', 'disabled');
    document.getElementById(`editTime-${id}`).hidden = false;
    document.getElementById(`saveTime-${id}`).hidden = true;
    document.getElementById(`cancelTime-${id}`).hidden = true;
    document.getElementById(`deleteTime-${id}`).hidden = false;

    let editBut = document.querySelectorAll('.editTime');
    for (let i = 0; i < editBut.length; i++) {
      editBut[i].removeAttribute('disabled');
    }
  }
  openDeleteTimeEntryModal(content: TemplateRef<number>, id: string) {
    this.addModal.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size: 'sm',
      centered: true,
    });
    this.deleTimeEntry = id;
  }

  deepEqual(obj1, obj2) {
    // Check if both parameters are objects
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      return obj1 === obj2;
    }

    // Get the keys of the objects
    const keys1 = Object.keys(obj1 || {});
    const keys2 = Object.keys(obj2 || {});

    // Check if the number of keys is the same
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Iterate over the keys and compare the properties
    for (let key of keys1) {
      if (!obj2.hasOwnProperty(key) || !this.deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  ngOnDestroy(): void {
    this.formValueChangeSubscription.unsubscribe();
  }
}
