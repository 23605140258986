import { DatePipe } from '@angular/common';
import { Constants } from './constants';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'date' })
export class DateFormatPipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
      return super.transform(value, Constants.DATE_FMT);
    }
}
@Pipe({ name: 'dateTime' })
export class DateTimeFormatPipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
      return super.transform(value, Constants.DATE_TIME_FMT);
    }
  }
