import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordComponent } from '../components/shared/Modals/change-password/change-password.component';
import { AddOrEditUserComponent } from '../components/manage-users/add-or-edit-user/add-or-edit-user.component';
import { FindExportAddressComponent } from '../components/reports/find-export-address/find-export-address.component';
import { AffiliationComponent } from '../components/providers/affiliation/affiliation.component';
import { MergeComponent } from '../components/providers/merge/merge.component';
import { ConfirmationComponent } from '../components/shared/Modals/confirmation/confirmation.component';
import { AddFinancialInterestModalComponent } from '../components/shared/Modals/add-financial-interest-modal/add-financial-interest-modal.component';
import { AddotherassociatedproviderComponent } from '../components/shared/Modals/addotherassociatedprovider/addotherassociatedprovider.component';
import { OpenalertlangComponent } from '../components/shared/Modals/openalertlang/openalertlang.component';
import { DissociateFinancialInterestComponent } from '../components/shared/Modals/dissociate-financial-interest/dissociate-financial-interest.component';
import { DissociateOtherAssociateComponent } from '../components/shared/Modals/dissociate-other-associate/dissociate-other-associate.component';
import { MergeSelectionNotAllowedComponent } from '../components/shared/Modals/merge-selection-not-allowed/merge-selection-not-allowed.component';
import { AssociateComponent } from '../components/shared/Modals/associate/associate.component';
import { AddFinancialOtherAssociationModalComponent } from '../components/shared/Modals/add-financial-other-association-modal/add-financial-other-association-modal.component';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs-compat';

@Injectable()
export class ModalService {
    private _refreshFinancialOtherAssociation = new BehaviorSubject<boolean>(false);
    constructor(private ngbModal: NgbModal) { }

    openModalForChangePassword(): void {
        this.ngbModal.open(ChangePasswordComponent, {
          windowClass: 'modal-job-scrollable'
        });
    }

    openModalForAddOrEditUser(userId?: string): void {
      const modalRef = this.ngbModal.open(AddOrEditUserComponent,
         {ariaLabelledBy: 'modal-basic-title', windowClass: 'groupModal', size: 'lg', centered: true});
      if (userId !== null || userId !== undefined) {
          modalRef.componentInstance.userId = userId;
      }

  }
  openModalForFindOrExport(): void {
    this.ngbModal.open(FindExportAddressComponent, {
      ariaLabelledBy: 'modal-basic-title', windowClass: 'groupModal', size: 'lg', centered: true
    });
  }
  openModalForAffiliate(): void {
    this.ngbModal.open(AffiliationComponent, {
      ariaLabelledBy: 'modal-basic-title', windowClass: 'groupModal', size: 'lg', centered: true
    });
  }
  openModalForMerge(): void {
    this.ngbModal.open(MergeComponent, {
      ariaLabelledBy: 'modal-basic-title', windowClass: 'groupModal', size: 'lg', centered: true
    });
  }
  openModalForConfirmation(uID: string, deleteFrom: string): void{
    const confirmationModal = this.ngbModal.open(ConfirmationComponent, {
      ariaDescribedBy: 'modal-basic-title', windowClass: 'groupModal', size: 'sm', centered: true
    });
    confirmationModal.componentInstance.uuid = uID;
    confirmationModal.componentInstance.deleteFrom = deleteFrom;
  }

  openModalForDeleteDocument(uID: string, spearId: string[]): void{
    const confirmationModal = this.ngbModal.open(ConfirmationComponent, {
      ariaDescribedBy: 'modal-basic-title', windowClass: 'groupModal', size: 'sm', centered: true
    });
    confirmationModal.componentInstance.uuid = uID;
    confirmationModal.componentInstance.spearId = spearId;
  }

  openAddFinancialInterestModal(uID: string):void{
    const addFinancialInterestModal = this.ngbModal.open(AddFinancialInterestModalComponent, {
      ariaDescribedBy: 'modal-basic-title', windowClass: 'groupModal', size: 'lg', centered: true
    });
    addFinancialInterestModal.componentInstance.uuid = uID;
  }

  openAddFinancialOtherAssociationModal(associationType:string):void{
    const addFinancialOtherAssociationModal = this.ngbModal.open(AddFinancialOtherAssociationModalComponent, {
      ariaDescribedBy: 'modal-basic-title', size: 'lg', centered: true
    });
    addFinancialOtherAssociationModal.componentInstance.entityAssociationType = associationType;
  }
  openDisccociateFinancialInterestModal(id: string):void{
    const dissociateFinancialInterestModal = this.ngbModal.open(DissociateFinancialInterestComponent, {
      ariaDescribedBy: 'modal-basic-title', windowClass: 'groupModal', size: 'sm', centered: true
    });
    dissociateFinancialInterestModal.componentInstance.id = id;
  }

  openAddOtherassociatedprovider(uID: string):void{
    const addOtherassociatedprovider = this.ngbModal.open(AddotherassociatedproviderComponent, {
      ariaDescribedBy: 'modal-basic-title', windowClass: 'groupModal', size: 'lg', centered: true
    });
    addOtherassociatedprovider.componentInstance.uuid = uID;
  }

  openDisccociateOtherAssociate(id: string):void{
    const dissociateOtherAssociateComponent = this.ngbModal.open(DissociateOtherAssociateComponent, {
      ariaDescribedBy: 'modal-basic-title', windowClass: 'groupModal', size: 'sm', centered: true
    });
    dissociateOtherAssociateComponent.componentInstance.id = id;
  }

  openAlertLang(lang: string):void{
    const addOtherassociatedprovider = this.ngbModal.open(OpenalertlangComponent, {
      ariaDescribedBy: 'modal-basic-title', windowClass: 'groupModal', size: 'sm', centered: true
    });
    addOtherassociatedprovider.componentInstance.lang = lang;
  }

  mergeAlert():void{
    const mergeAlert = this.ngbModal.open(MergeSelectionNotAllowedComponent, {
      ariaDescribedBy: 'modal-basic-title', windowClass: 'groupModal', size: 'sm', centered: true
    });
  }

  getRefreshFinancialOtherAssociation(): Observable<boolean> {
    return this._refreshFinancialOtherAssociation.asObservable();
  }

  setRefreshFinancialOtherAssociation(value: boolean) {
    this._refreshFinancialOtherAssociation.next(value);
  }

}
