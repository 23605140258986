import { Component, OnInit, EventEmitter, Output } from '@angular/core';
//import { SelectprofileService } from '../selectprofile.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-selectallconfirmation',
  templateUrl: './selectallconfirmation.component.html',
  styleUrls: ['./selectallconfirmation.component.css']
})
export class SelectallconfirmationComponent implements OnInit {

  constructor(
    private _NgbActiveModal: NgbActiveModal,
    //private selectProfile: SelectprofileService
  ) { }

  confirm: boolean;
  deselect: boolean;

  @Output() confirmSelectAll: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
  }
  get activeModal() {
    return this._NgbActiveModal;
  }
  selectedAll(){
    this.confirm = true;
    this.confirmSelectAll.emit(this.confirm);
    this.activeModal.dismiss();  
  }
  noselectAll(){
    this.confirm = false;
    this.confirmSelectAll.emit(this.confirm);
    this.activeModal.dismiss();
  }

}
