import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  BillReviewAndPaymentResponse,
  CreateInvoiceReq,
  Invoice,
  InvoicePaginationData,
  LimosDataResponse,
  LimosInvoiceData,
  LimosReportCSVExportResponse,
  LimosReportDataResponse,
  SimilarClaimRes,
  TaskStatus,
  TimeSpentReq,
  UpdateLimosResponseDetails,
  UpdateLimosStatusReq,
} from '../models/limos/limos.models';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LimosService {
  private _limosInvoiceData = new BehaviorSubject<LimosInvoiceData>(null);
  constructor(private http: HttpClient) {}

  getLimosReport(
    offSet: number,
    limit: number,
    export_to_csv: boolean
  ): Observable<
    LimosDataResponse<LimosReportDataResponse | LimosReportCSVExportResponse>
  > {
    return this.http.get<
      LimosDataResponse<LimosReportDataResponse | LimosReportCSVExportResponse>
    >(
      `https://spear-django-uq3cjixc7q-uw.a.run.app/api/v1/liens/get_limos_data_list/?limit=${limit}&offset=${offSet}&export_limos_data_to_csv=${export_to_csv}`
    );
  }

  checkLimosReportTaskStatus(
    taskId: string
  ): Observable<LimosDataResponse<TaskStatus>> {
    return this.http.get<LimosDataResponse<TaskStatus>>(
      `https://spear-django-uq3cjixc7q-uw.a.run.app/api/v1/liens/export/status/${taskId}`
    );
  }

  updateLimosStatus(
    id: string,
    claimNumber: string,
    reqBody: UpdateLimosStatusReq
  ): Observable<LimosDataResponse<UpdateLimosResponseDetails>> {
    return this.http.put<LimosDataResponse<UpdateLimosResponseDetails>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/editable/${id}/${claimNumber}`,
      reqBody
    );
  }

  get limosInvoiceData(): Observable<LimosInvoiceData> {
    const limos_invoice_data = localStorage.getItem('limos_invoice_data')
      ? localStorage.getItem('limos_invoice_data')
      : null;
    if (!this._limosInvoiceData.value && limos_invoice_data) {
      return of(JSON.parse(limos_invoice_data));
    }
    return this._limosInvoiceData.asObservable();
  }

  setlimosInvoiceData(value: LimosInvoiceData) {
    localStorage.removeItem('limos_invoice_data');
    localStorage.setItem('limos_invoice_data', JSON.stringify(value));
    this._limosInvoiceData.next(value);
  }

  getBillReviewAndPaymentData(
    claimNo: string,
    tin: string,
    limit: number,
    offSet: number
  ): Observable<LimosDataResponse<BillReviewAndPaymentResponse>> {
    return this.http.get<LimosDataResponse<BillReviewAndPaymentResponse>>(
      `https://spear-django-uq3cjixc7q-uw.a.run.app/api/v1/liens/payments-data/${claimNo}/${tin}/?limit=${limit}&offset=${offSet}`
    );
  }

  getSimilarClaims(
    tin: string,
    limit: number,
    offSet: number
  ): Observable<LimosDataResponse<SimilarClaimRes>> {
    return this.http.get<LimosDataResponse<SimilarClaimRes>>(
      `${environment.fireStoreApiUrl}/api/v2/liens/similar-claims/${tin}/?limit=${limit}&offset=${offSet}`
    );
  }

  getSimilarClaimTaskId(
    tin: string,
    limit: number,
    offSet: number
  ): Observable<LimosDataResponse<LimosReportCSVExportResponse>> {
    return this.http.get<LimosDataResponse<LimosReportCSVExportResponse>>(
      `${environment.fireStoreApiUrl}/api/v2/liens/similar-claims/${tin}/?limit=${limit}&offset=${offSet}&export_data_to_csv_file=true`
    );
  }

  saveTimeSpent(
    reqBody: TimeSpentReq
  ): Observable<LimosDataResponse<UpdateLimosResponseDetails>> {
    return this.http.post<LimosDataResponse<UpdateLimosResponseDetails>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/time-spent/`,
      reqBody
    );
  }

  updateTimeSpent(
    id: number,
    reqBody: TimeSpentReq
  ): Observable<LimosDataResponse<UpdateLimosResponseDetails>> {
    return this.http.patch<LimosDataResponse<UpdateLimosResponseDetails>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/time-spent/editable/${id}`,
      reqBody
    );
  }

  createInvoice(
    reqBody: CreateInvoiceReq
  ): Observable<LimosDataResponse<UpdateLimosResponseDetails>> {
    return this.http.post<LimosDataResponse<UpdateLimosResponseDetails>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/invoice/create/`,
      reqBody
    );
  }

  updateInvoice(
    id: number,
    reqBody: CreateInvoiceReq
  ): Observable<LimosDataResponse<UpdateLimosResponseDetails>> {
    return this.http.put<LimosDataResponse<UpdateLimosResponseDetails>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/invoice/update/${id}/`,
      reqBody
    );
  }

  getInvoiceList(
    limit: number,
    offSet: number
  ): Observable<LimosDataResponse<InvoicePaginationData>> {
    return this.http.get<LimosDataResponse<InvoicePaginationData>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/invoice/get-all/?limit=${limit}&offset=${offSet}`
    );
  }

  getInvoiceDetails(id: string): Observable<Invoice> {
    return this.http
      .get<LimosDataResponse<Invoice>>(
        `https://spear-django-uq3cjixc7q-uw.a.run.app/api/v1/liens/invoice/get/${id}/`
      )
      .pipe(
        map((res: LimosDataResponse<Invoice>): Invoice => res.details ?? null)
      );
  }
}
