import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FindExportAddressModel } from 'src/app/models/FindExportAddressModel';

@Component({
  selector: 'app-find-export-address',
  templateUrl: './find-export-address.component.html',
  styleUrls: ['./find-export-address.component.css']
})
export class FindExportAddressComponent implements OnInit {

 findExportAddressModel: FindExportAddressModel = {
    street: '',
    city: '',
    state: '',
    zipcode: ''
  };

  get activeModal(): any {
    return this.ngbActiveModal;
  }

  constructor(private ngbActiveModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
