import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ManageUsersService {

private loadUsersSubject = new Subject<any>();

    constructor(private http: HttpClient) {
            this.loadUsersSubject = new Subject<any>();
     }

getUsers(): Observable<any> {
    return this.http.get(environment.apiUrl + 'users/get-users');
}

activateOrInactivateUser(userId: string, isActive: boolean): Observable<any>{
    return this.http.get(environment.apiUrl + 'users/enable-disable?userId=' + userId + '&isEnabled=' + isActive);
}

getRoles(): Observable<any>{
    return this.http.get(environment.apiUrl + 'users/roles');
}

createUser(model: {firstName: string, lastName: string, email: string, roleId: string}): Observable<any>{
return this.http.post(environment.apiUrl + 'users/create', model);
}
editUser(model: {userId: string, firstName: string, lastName: string, email: string, roleId: string}): Observable<any>{
    return this.http.post(environment.apiUrl + 'users/edit', model);
}

createPassword(model: {userId: string, token: string, password: string, confirmPassword: string}): Observable<any> {
return this.http.post(environment.apiUrl + 'users/create-password', model);
}

getUserInfoById(userId: string): Observable<any> {
    return this.http.get(environment.apiUrl + 'users/get-user-by-id?userId=' + userId);
}

notifyToLoadUsers(): void{
    this.loadUsersSubject.next();
}

listenToloadUsers(): Observable<any> {
   return this.loadUsersSubject.asObservable();
}
}
