<div class="row">
  <div class="col-12">
    <div class="card">
      <div
        class="card-header limos-table-header-background d-flex justify-content-between"
      >
        <h6>Resources</h6>
        <div class="d-flex justify-content-end gap-3 w-50">
          <button type="button" class="btn btn-sm btn-warning text-white">
            Validate
          </button>
          <button type="button" class="btn btn-sm btn-info text-white">
            Move
          </button>
          <button type="button" class="btn btn-sm btn-dark text-white">
            Edit
          </button>
          <button type="button" class="btn btn-sm btn-danger text-white">
            Delete
          </button>
          <button
            type="button"
            class="btn btn-sm btn-success text-white"
            (click)="openPopup(createFolder)"
          >
            Create Folder
          </button>
          <button
            type="button"
            class="btn btn-sm btn-secondary text-white"
            (click)="openPopup(uploadFiles)"
          >
            Upload Files
          </button>
        </div>
      </div>
      <div class="card-body">
        <input
          #resourceFilesInput
          type="file"
          class="d-none"
          (change)="onResourceFolderSelected($event)"
          multiple
        />
        <div *ngIf="uploadProgress > 0">
          Upload Progress: {{ uploadProgress }}%
        </div>

        <div class="table">
          <table
            class="table providerTable limos-table-header-background small-text table-row"
          >
            <caption>
              Data Table
            </caption>
            <thead>
              <tr>
                <th scope="col">File Name</th>
                <th scope="col">Access Group</th>
                <th scope="col">Validated</th>
                <th scope="col">Reference No.</th>
                <th scope="col">Notes</th>
                <th scope="col">Size</th>
                <th scope="col">Created At</th>
                <th scope="col">Uploaded By</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <!-- <tbody *ngIf="selectedResourceFiles?.length">
              <tr
                class="bg-white"
                *ngFor="let file of selectedResourceFiles; let ind = index"
              >
                <td>{{ file.name }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{ file.formattedSize }}</td>
                <td></td>
                <td></td>
                <td>
                  <button
                    class="btn btn-sm text-danger"
                    title="Remove"
                    (click)="deleteResourceFile(ind)"
                  >
                    <em class="fa fa-remove"></em>
                  </button>
                </td>
              </tr>
            </tbody> -->
            <tbody>
              <tr>
                <td colspan="9" class="text-center">Data not available</td>
              </tr>
            </tbody>
            <tbody>
              <tr class="bg-white"></tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #uploadFiles let-modal>
  <div class="modal-header">
    <h6>Upload Files</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body upload-files">
    <div class="row mb-2">
      <div class="col-6">
        <div>
          <span class="required" for="tin_number">Access Group</span>
          <select name="access_group" id="access_group" class="form-control">
            <option value="">Select</option>
            <option value="Admin">Admin</option>
            <option value="Internal">Internal</option>
            <option value="Investigative">Investigative</option>
            <option value="Public">Public</option>
            <option value="Vendor Management">Vendor Management</option>
            <option value="Vendors">Vendors</option>
          </select>
        </div>
      </div>
      <div class="col-6"></div>
    </div>

    <div class="row mb-2">
      <div class="col-12">
        <div>
          <div class="text-dark fw-500 mb-1" for="noteFiles">
            Files
            <span class="text-sm mr-1 text-gray">(Max File Size: 5G)</span>
            <button
              class="btn btn-sm btn-primary rounded"
              (click)="filesInput.click()"
              title="Add Files"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
          <input
            type="file"
            #filesInput
            class="d-none"
            name="files"
            id="files"
            multiple
            accept=".pdf,.xls,.xlsx.jpeg,.png,.jpg"
            (change)="onResourceFolderSelected($event)"
          />
        </div>
        <div *ngIf="selectedResourceFiles?.length; else noFilesSelected">
          <ol class="px-3">
            <li
              *ngFor="let file of selectedResourceFiles; let i = index"
              class="text-black text-sm"
            >
              <span class="text-dark fw-500">{{ file.name }}</span> ({{
                file.formattedSize
              }})
              <button
                class="btn btn-sm text-danger"
                title="Remove"
                (click)="selectedResourceFiles.splice(i, 1)"
              >
                <em class="fa fa-remove"></em>
              </button>
            </li>
          </ol>
        </div>
        <ng-template #noFilesSelected>
          <span class="text-sm">No Files selected.</span>
        </ng-template>
      </div>
    </div>

    <div class="text-right mt-4">
      <button
        class="btn btn-secondary btn-sm text-white mr-1"
        (click)="modalService.dismissAll()"
      >
        Cancel
      </button>
      <button class="btn limos-button-background btn-sm text-white">
        Save
      </button>
    </div>
  </div>
</ng-template>

<ng-template #createFolder let-modal>
  <div class="modal-header">
    <h6>Create Folder</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body create-folder">
    <div class="row mb-2">
      <div class="col-6">
        <span class="required" for="display_name">Display Name</span>
        <input
          type="text"
          id="display_name"
          name="display_name"
          class="form-control"
        />
      </div>
      <div class="col-6">
        <div>
          <span class="required" for="tin_number">Access Group</span>
          <select name="access_group" id="access_group" class="form-control">
            <option value="">Select</option>
            <option value="Admin">Admin</option>
            <option value="Internal">Internal</option>
            <option value="Investigative">Investigative</option>
            <option value="Public">Public</option>
            <option value="Vendor Management">Vendor Management</option>
            <option value="Vendors">Vendors</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <span for="notes">Notes</span>
        <textarea
          name="notes"
          id="notes"
          rows="3"
          class="w-100 border-grey"
        ></textarea>
      </div>
    </div>

    <div class="text-right mt-4">
      <button
        class="btn btn-secondary btn-sm text-white mr-1"
        (click)="modalService.dismissAll()"
      >
        Cancel
      </button>
      <button class="btn limos-button-background btn-sm text-white">
        Save
      </button>
    </div>
  </div>
</ng-template>
