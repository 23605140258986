<div class="row">
  <div class="col-12">
    <div class="card">
      <div
        class="card-header spear-table-header-background d-flex justify-content-between"
      >
        <h6>Department Columns</h6>
        <div>
          <button
            class="btn viewBtn spear-button-background text-white btn-sm"
            (click)="openPopup(createDepartmentColumn, 'md')"
          >
            Add Department Column
          </button>
        </div>
      </div>
      <div class="card-body" *blockUI="'department-list-ui'">
        <table class="table spear-table-header-background">
          <caption>
            Department Columns
          </caption>
          <thead>
            <tr>
              <th class="border-0" scope="col"></th>
              <th class="border-0" scope="col">Name</th>
              <th class="border-0" scope="col">Tag</th>
              <th class="border-0" scope="col">Notes</th>
              <th class="border-0" scope="col">Action</th>
            </tr>
          </thead>
          <tbody
            *ngIf="departmentColumns?.length"
            cdkDropList
            (cdkDropListDropped)="drop($event)"
          >
            <tr
              class="bg-white"
              *ngFor="let department of departmentColumns"
              cdkDrag
            >
              <td>
                <span class="drag-handle" cdkDragHandle>
                  <i class="fa fa-arrows-alt text-muted" aria-hidden="true"></i>
                </span>
              </td>
              <td>{{ department.name }}</td>
              <td>{{ department.tag }}</td>
              <td>{{ department.notes }}</td>
              <td>
                <button
                  class="btn btn-sm text-primary"
                  title="Edit"
                  (click)="
                    editDepartmentColumn(createDepartmentColumn, department)
                  "
                >
                  <i class="fa fa-pencil"></i>
                </button>
                <button
                  (click)="deleteDepartmentColumn(department.id)"
                  class="btn btn-sm text-danger"
                  title="Remove"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!departmentColumns?.length">
            <tr class="bg-white">
              <td colspan="5" class="text-center">Data not available</td>
            </tr>
          </tbody>
        </table>
        <div
          class="px-0 py-0 paginationWrapper d-flex"
          *ngIf="!(!departmentColumns || departmentColumns.length === 0)"
        >
          <ngb-pagination
            [(collectionSize)]="departmentTotal"
            [(page)]="departmentPage"
            [pageSize]="departmentLimit"
            [maxSize]="10"
            [boundaryLinks]="true"
            (pageChange)="onPageChangedepartments($event)"
          >
          </ngb-pagination>
          <div class="selected d-flex align-items-center">
            <select
              (change)="onSizeChangedepartments(departmentLimit)"
              class="custom-select table-select h-100"
              [(ngModel)]="departmentLimit"
            >
              <option [ngValue]="20">20</option>
              <option [ngValue]="50">50</option>
              <option [ngValue]="75">75</option>
              <option [ngValue]="100">100</option>
            </select>
            <p class="mb-0 ml-2 text-nowrap">
              Showing
              {{ departmentPage * departmentLimit - departmentLimit + 1 }}
              -
              {{
                departmentPage * departmentLimit > departmentTotal
                  ? departmentTotal
                  : departmentPage * departmentLimit
              }}
              of {{ departmentTotal }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #createDepartmentColumn let-modal>
  <div class="modal-header">
    <h6>
      {{ editDepartmentColumnData?.id ? "Update" : "Add" }} Department Column
    </h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="addmodalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body create-department text-sm">
    <form [formGroup]="departmentColumnForm">
      <div class="row mb-2">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500 required" for="department_name"
              >Name</span
            >
            <input
              type="text"
              name="department_name"
              id="department_name"
              class="form-control"
              formControlName="name"
            />
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500" for="tpa">Tag</span>
            <input
              type="text"
              name="tag"
              id="tag"
              class="form-control"
              formControlName="tag"
            />
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500" for="general_notes">Notes</span>
            <textarea
              type="text"
              name="notes"
              id="notes"
              class="w-100 border-grey"
              rows="5"
              formControlName="notes"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="mt-4 d-flex justify-content-end">
        <div>
          <button
            type="button"
            class="btn btn-secondary btn-sm text-white mr-1"
            (click)="addmodalService.dismissAll()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn limos-button-background btn-sm text-white"
            (click)="onSubmitdepartmentColumnForm()"
            [disabled]="
              departmentColumnForm.invalid || isdepartmentColumnFormSubmitted
            "
          >
            {{ editDepartmentColumnData?.id ? "Update" : "Add" }}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
