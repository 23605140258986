import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-openalertlang',
  templateUrl: './openalertlang.component.html',
  styleUrls: ['./openalertlang.component.css']
})
export class OpenalertlangComponent implements OnInit {

  constructor(private _NgbActiveModal: NgbActiveModal) { }

  lang: string;

  ngOnInit(): void {
  }

  get activeModal() {
    return this._NgbActiveModal;
  }

}
