import { RecordCount } from './../models/provider/records.count';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { root } from 'rxjs/internal/util/root';
import { environment } from 'src/environments/environment';
import { ProviderFilterModel } from '../models/provider/provider.filter.model';
import { ProviderPagedResult } from '../models/provider/provider.paged.result';
import { ProviderAddressDetail } from '../models/provider/provider.address.model';
import { ProviderSearchReponseMOdel } from '../models/provider/provider.melie.search.reponse.model';
import { Profile, MergeProfile, ProviderProfileModel } from '../models/provider/provider.profile.model';
import { MergeRequestModel } from '../models/merge/merge.request.model';

@Injectable({
    providedIn: 'root'
})
export class ProviderService {
    constructor(private http: HttpClient) { }
    get ShowSearchBar(): boolean {
        return this.showSearchBar;
    }
    set ShowSearchBar(showSearchBar: boolean) {
        this.showSearchBar = showSearchBar;
    }

    // buttons for provider detail screen
    get ShowButtonsInProvider(): boolean {
        return this.showButtonsInProvider;
    }
    set ShowButtonsInProvider(showButtonsInProvider: boolean) {
        this.showButtonsInProvider = showButtonsInProvider;
    }

    private mergeIds: string[];

    public searchTextInfo = new Subject<any>();
    public resetSerachBar = new Subject();

    private showSearchBar: boolean;
    private showButtonsInProvider: boolean;

    public setMergeIds = (ids: string[]) => this.mergeIds = ids;
    public getMergeIds = () => this.mergeIds;

    notifyToSearchRecords =
    (searchText: string, serachedCriteria: string[]) =>
    this.searchTextInfo.next({ searchedText: searchText, searchedCriteria: serachedCriteria })

    listenToSearchRecords(): Observable<any> {
        return this.searchTextInfo.asObservable();
    }

    notifyToResetSearchBar = () => this.resetSerachBar.next();

    listenToResetSearchBar = () => this.resetSerachBar.asObservable();



    getProviders(model: ProviderFilterModel): Observable<ProviderPagedResult> {
        return this.http.get<ProviderPagedResult>(environment.fireStoreApiUrl + '/api/v2/navbar/info/');
    }

    getAllProfile(): Observable<any> {
        return this.http.get<any>(environment.fireStoreApiUrl + '/api/v2/profile/all/');
    } 
    
    getAllSource(): Observable<any> {
        return this.http.get<any>(environment.fireStoreApiUrl + '/api/v1/profile/data_sources/');
    } 

    getProvidersFromFireStore(query: string, pageIndex: number, limit: number): Observable<any> {
        if (!query){
            query = '';
        }
        return this.http.get<ProviderSearchReponseMOdel>(`${environment.fireStoreApiUrl}/api/v2/profile/search/?limit=${limit}&offset=${pageIndex}&query=${query}`);
    }

    getProvidersFromFireStoreCSV(query: string, pageIndex: number, limit: number, export_all_to_csv: boolean): Observable<any> {
        if (!query){
            query = '';
        }
        return this.http.get<ProviderSearchReponseMOdel>(`${environment.fireStoreApiUrl}/api/v1/profile/search/?limit=${limit}&offset=${pageIndex}&query=${query}&export_all_to_csv=${export_all_to_csv}`);
    }

    getProvidersFilteredFromFireStore(pageIndex: number, limit: number, isfilteron: boolean, filterQuery: string, export_all_to_csv: boolean): Observable<any> {
        return this.http.get<ProviderSearchReponseMOdel>(`${environment.fireStoreApiUrl}/api/v2/profile/search/?limit=${limit}&offset=${pageIndex}&is_filter_on=${isfilteron}&${filterQuery}&export_all_to_csv=${export_all_to_csv}`);
    }

    getProvidersFilteredFromFireStoreCSV(query: string, pageIndex: number, limit: number, isfilteron: boolean, filterQuery: string, export_all_to_csv: boolean): Observable<any> {
        if (!query){
            query = '';
        }
        return this.http.get<ProviderSearchReponseMOdel>(`${environment.fireStoreApiUrl}/api/v1/profile/search/?limit=${limit}&offset=${pageIndex}&query=${query}&is_filter_on=${isfilteron}&${filterQuery}&export_all_to_csv=${export_all_to_csv}`);
    }

    getProvidersFromFireStoreModal(query: string): Observable<any> {
        if (!query){
            query = '';
        }
        return this.http.get<ProviderSearchReponseMOdel>(`${environment.fireStoreApiUrl}/api/v1/profile/search/?limit=20&offset=&query=${query}`);
    }

    getProvidersFromFireStore2(pageIndex: number, limitN: number): Observable<any> {
        return this.http.get<ProviderSearchReponseMOdel>(`${environment.fireStoreApiUrl}/api/v2/profile/all/?limit=${limitN}&offset=${pageIndex}`);
    }

    getProviderProfile(id: string): Observable<Profile> {
        return this.http.get<Profile>(`${environment.fireStoreApiUrl}/api/v1/profile/${id}/`);
    }

    getProviderProfileForMerge(id: string): Observable<MergeProfile> {
        return this.http.get<MergeProfile>(`${environment.fireStoreApiUrl}/api/v1/profile/${id}/`);
    }

    getProviderAddresses(entityId: string): Observable<Array<ProviderAddressDetail>> {
        return this.http.get<Array<ProviderAddressDetail>>(environment.apiUrl + 'provider/get-provider-addresses/?entityId=' + entityId);
    }
    getRecordsCount(): Observable<RecordCount> {
        return this.http.get<RecordCount>(environment.apiUrl + 'provider/GetNewProvidersCount');
    }

    getSprCount(rec: string): Observable<any> {
        return this.http.get<any>(`${environment.fireStoreApiUrl}/api/v1/profile/count/${rec}/`);
    }

    mergeProfile(model: MergeRequestModel): Observable<any> {
        return this.http.post<any>(`${environment.fireStoreApiUrl}/api/v1/merge/`, model);
    }    

    getSuggestedList(limit: number, offset: number, address: string, entity_type: number, title_or_position: string): Observable<any> {
        return this.http.get<any>(`${environment.fireStoreApiUrl}/api/v1/suggestions/?limit=${limit}&offset=${offset}&business_address=${address}&entity_type_code=${entity_type}&title_or_position=${title_or_position}`);
    }
}
