export class ProviderFilterModel {

    constructor(searchText: string, searchCriteria: string[], page: number, pageSize: number,
        orderBy: string, isDescending: boolean, omitEmptyNames: boolean,
        showDublicateName: boolean, showNewRecords: boolean, providerType: number) {
        this.searchText = searchText;
        this.page = page;
        this.pageSize = pageSize;
        this.orderBy = orderBy;
        this.isDescending = isDescending;
        this.omitEmptyNames = omitEmptyNames;
        this.showDublicateName = showDublicateName;
        this.showNewRecords = showNewRecords;
        this.providerType = providerType;
        this.searchCriteria = searchCriteria;
    }

    public searchText: string;
    public searchCriteria: string[]
    public page: number;
    public pageSize: number;
    public orderBy: string;
    public isDescending: boolean;
    public omitEmptyNames: boolean;
    public showDublicateName: boolean;
    public showNewRecords: boolean;
    public providerType: number;
}