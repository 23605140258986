import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Profile, ProviderProfileModel } from 'src/app/models/provider/provider.profile.model';
import { ProviderService } from 'src/app/services/provider.service';
import { AppUtils } from 'src/app/utilities/app.utils';

@Component({
  selector: 'app-provider-list-detail',
  templateUrl: './provider-list-detail.component.html',
  styleUrls: ['./provider-list-detail.component.css']
})
export class ProviderListDetailComponent implements OnInit {
  todayDate: Date = new Date();
  profileModel: any;
  profileId: string;

  @BlockUI() blockUI: NgBlockUI;
  constructor(private activeRoute: ActivatedRoute,
    private providerService: ProviderService,
    private appUtils: AppUtils,
    private toastr: ToastrService
  ) {
    this.profileModel = new Profile();
    //this.profileModel.profile = new Profile();
    this.activeRoute.params.subscribe((param) => {
      this.blockUI.start();
      this.providerService.getProviderProfile(param.id).subscribe((response: Profile) => {
        this.profileId = param.id;
        this.profileModel = response;
        const name = response.details.provider_name_person.split(' ').filter(x => x);
        this.profileModel.details.firstname = name[0];
        this.profileModel.details.middlename = name.length > 2 ? name[1] : '';
        this.profileModel.details.lastname = name.length > 2 ? name[2] : name[1];
        this.blockUI.stop();
      }
      // error => {
      //   this.appUtils.ProcessErrorResponse(this.toastr, error);
      //   this.blockUI.stop();
      // }
      );

    })

  }

  row = [
    {
      Notes: '',
      CreatedBy: ''
    },
    {
      Notes: '',
      CreatedBy: ''
    },
    {
      Notes: '',
      CreatedBy: '',
    }
  ];
  // tslint:disable-next-line: typedef
  addTable() {
    const obj = {
      Notes: '',
      CreatedBy: ''
    };
    this.row.push(obj);
  }
  // tslint:disable-next-line: typedef
  deleteRow(x) {
    // tslint:disable-next-line: prefer-const
    let delBtn = confirm(' Do you want to delete ?');
    // tslint:disable-next-line: triple-equals
    if (delBtn == true) {
      this.row.splice(x, 1);
    }
  }

  ngOnInit(): void {
  }

}
