import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-associate',
  templateUrl: './associate.component.html',
  styleUrls: ['./associate.component.css'],
})
export class AssociateComponent implements OnInit {
  constructor(
    private _NgbActiveModal: NgbActiveModal,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private toastr: ToastrService,
  ) {

  }

  private subsciptions: Subscription[] = [];
  private formValueChangeSubscription: Subscription;
  private hasEntityId: boolean;
  private selectedAssociates: any;
  private entityId: number;

  public selectedEntity: string = 'Make a selection';
  public showTable: boolean = false;
  public isMatter = false;
  public isEntity = false;
  public isNewEntity = false;
  public isNewMatter = false;
  public isCreated = false;
  public entitiesList = [
    {
      id: 0,
      name: 'Create New',
    },
  ];
  public doAssociate = false;
  public entityform: FormGroup;
  public emptyForm: boolean = false;
  public providersTable = [];
  public isProvidersTable: boolean = false;
  public entityAssociationType = '';

  @Output() evt: EventEmitter<boolean> = new EventEmitter();

  ngOnInit(): void {
    this.entityform = this.formBuilder.group({
      selectEntityName: new FormControl(),
      newEntityName: new FormControl()
    });

    this.formValueChangeSubscription = this.entityform.valueChanges.subscribe(() => {
      let allAssociationsValid = true; // Initialize as true

      if (this.isNewEntity) {
        if (
          this.entityform.controls.selectEntityName.value &&
          this.entityform.controls.newEntityName.value
        ) {
          // Check if any associationType controls have empty values
          this.providersTable.forEach((row) => {
            const control = this.entityform.get(`associationType-${row.id}`);
            if (control && control.value === '') {
              allAssociationsValid = false;
            }
          });

          // Set doAssociate based on the result of the check
          this.doAssociate = allAssociationsValid;
        }
      } else if (this.isCreated) {
        if (this.entityform.controls.selectEntityName.value) {
          // Check if any associationType controls have empty values
          this.providersTable.forEach((row) => {
            const control = this.entityform.get(`associationType-${row.id}`);
            if (control && control.value === '') {
              allAssociationsValid = false;
            }
          });

          // Set doAssociate based on the result of the check
          this.doAssociate = allAssociationsValid;
        }
      }
    });

    this.selectedAssociates.forEach((x) => {
      this.http
        .get<any>(
          `https://spear-django-uq3cjixc7q-uw.a.run.app/api/v1/profile/${x.spear_id}`
        )
        .subscribe((resp) => {
          if (resp) {
            const prov = resp.details;
            let offc_name =
              (prov?.official_first_name ? prov?.official_first_name : '') +
              (prov?.official_first_name && prov?.official_middle_name
                ? ' '
                : '') +
              (prov?.official_middle_name
                ? ` ${prov?.official_middle_name}`
                : '') +
              (prov?.official_middle_name && prov?.official_last_name
                ? ' '
                : '') +
              (prov?.official_last_name ? ` ${prov?.official_last_name}` : '');
            let name = prov?.provider_name_person
              ? prov?.provider_name_person
              : offc_name
              ? offc_name
              : '';
            let business = prov?.provider_name_business
              ? prov?.provider_name_business
              : '';
            if (prov) {
              const bod = {
                name: name,
                business: business,
                type: prov.association_type,
                note: prov.note,
                id: x.id,
              };
              this.providersTable.push(bod);
              this.entityform.addControl(`associationType-${x.id}`, this.formBuilder.control(this.entityAssociationType));
              this.entityform.addControl(`entityNote-${x.id}`, this.formBuilder.control(''));
            }
          }
        });
    });

    if(this.entityAssociationType){
      this.associateBy({target:{value:'entity'}});
      this.entityId = this.selectedAssociates[0].entityId[0].id;
      this.entityform.get('selectEntityName').setValue(this.entityId);
      this.selectEntity({target:{value:this.entityId}});
    }
  }

  get activeModal() {
    return this._NgbActiveModal;
  }

  associateBy(e: any) {
    if (e.target.value == 'entity') {
      this.http
        .get<any>(
          'https://spear-django-uq3cjixc7q-uw.a.run.app/api/v1/associations/entities/all/'
        )
        .subscribe((list) => {
          if (list) {
            for (let i = 0; i < list.details.length; i++) {
              let ent = {
                id: 0,
                name: '',
                associations: [],
              };
              ent.id = list.details[i].id;
              ent.name = list.details[i].entity_name;
              ent.associations = list.details[i].associations;
              this.entitiesList.push(ent);
            }
          }
          this.isEntity = true;
          this.isMatter = false;
        });
    } else if (e.target.value == 'matter') {
      this.isEntity = false;
      this.isMatter = true;
    }
  }
  selectEntity(e: any) {
    if (e.target.value == 0) {
      this.isNewEntity = true;
      setTimeout(()=>{
        this.showTable = true;
      }, 500);
    } else if (e.target.value == 'Make a selection') {
      this.isNewEntity = false;
      this.isCreated = false;
    } else {
      this.isNewEntity = false;
      this.isCreated = true;
      this.hasEntityId = true;
      this.entityId = e.target.value;
      setTimeout(()=>{
        this.showTable = true;
      }, 500);
    }
  }

  onSubmit() {
    if (this.isNewEntity) {
      let reqBody = {
        entity_name: this.entityform.controls.newEntityName.value,
        spear_profile_ids: this.selectedAssociates.map((profile) => profile.id),
      };
      this.http
        .post<any>(
          'https://spear-django-uq3cjixc7q-uw.a.run.app/api/v1/associations/entities/create/',
          reqBody
        )
        .subscribe((data) => {
          this.entityId = data.details.id;
          let entId = Number(this.entityId);
          let reqBody2 = {
            associations: [],
          };
          this.selectedAssociates.forEach((el) => {
            const assotypeValue = this.entityform.controls[`associationType-${el.id}`].value;
            const entityValue = this.entityform.controls[`entityNote-${el.id}`].value;
            const body = {
              spear_profile: el.id,
              association_type: assotypeValue,
              note: entityValue,
              entity_id: entId,
            };
            reqBody2.associations.push(body);
          });
          this.http
            .post<any>(
              'https://spear-django-uq3cjixc7q-uw.a.run.app/api/v1/associations/create/',
              reqBody2
            )
            .subscribe((data) => {
              this.evt.emit(true);
              this._NgbActiveModal.close();
              if(this.entityAssociationType){
                this.modalService.setRefreshFinancialOtherAssociation(true);
                this.toastr.success(data?.details?.message);
              }
            });
        });
    } else {
      let reqBody2 = {
        associations: [],
      };
      this.selectedAssociates.forEach((el) => {
        const assotypeValue = this.entityform.controls[`associationType-${el.id}`].value;
        const entityValue = this.entityform.controls[`entityNote-${el.id}`].value;
        const body = {
          spear_profile: el.id,
          association_type: assotypeValue,
          note: entityValue,
          entity_id: this.entityform.controls.selectEntityName.value,
        };
        reqBody2.associations.push(body);
      });
      this.http
        .post<any>(
          'https://spear-django-uq3cjixc7q-uw.a.run.app/api/v1/associations/create/',
          reqBody2
        )
        .subscribe((data) => {
          this.evt.emit(true);
          this._NgbActiveModal.close();
          if(this.entityAssociationType){
            this.modalService.setRefreshFinancialOtherAssociation(true);
            this.toastr.success(data?.details?.message);
          }
        });
    }
  }

  selectMatter(e: any) {
    if (e.target.value == 'newMatter') {
      this.isNewMatter = true;
    } else {
      this.isNewMatter = false;
    }
  }

  ngOnDestroy(): void {
    this.subsciptions.forEach((x) => x.unsubscribe());
  }
}
