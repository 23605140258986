<!-- <app-predashboard-overlay [ngStyle]="{'display': isUserAuthenticated ? 'block' : 'none' }" [ngClass]="'overlayScreen'"
    *ngIf="ifDashboard" (closeDashboardEvent)="addItem($event)"></app-predashboard-overlay> -->

<section [ngClass]="{'bodywrapper': isUserAuthenticated}">
    <div *ngIf="isUserAuthenticated">
        <app-top-bar></app-top-bar>
        <app-side-bar></app-side-bar>
    </div>
    <div>
        <div class="main">
            <block-ui>
                <router-outlet></router-outlet>
            </block-ui>
        </div>
    </div>
</section>

