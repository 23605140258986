import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  DepartmentColumn,
  DepartmentCreateResponse,
  DepartmentDataResponse,
  DepartmentPaginatedData,
  ReorderReq,
} from 'src/app/models/department/department.interface';
import { DepartmentService } from 'src/app/services/department.service';

@Component({
  selector: 'app-department-name',
  templateUrl: './department-column.component.html',
  styleUrls: ['./department-column.component.css'],
})
export class DepartmentColumnComponent implements OnInit {
  departmentColumnForm: FormGroup;
  isdepartmentColumnFormSubmitted = false;
  departmentLimit = 20;
  departmentPage = 1;
  departmentTotal: number;
  departmentColumns: DepartmentColumn[];
  @BlockUI('department-list-ui') departmentListBlockUI: NgBlockUI;
  private unsubscribe$ = new Subject<void>();
  editDepartmentColumnData: DepartmentColumn;

  constructor(
    private addmodalService: NgbModal,
    private fb: FormBuilder,
    private departmentService: DepartmentService,
    private toastr: ToastrService
  ) {
    this.initdepartmentColumnForm();
  }

  ngOnInit() {
    this.getDepartmentColumns();
  }

  openPopup(content, size = 'md') {
    this.addmodalService.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size,
      centered: true,
    });
  }

  initdepartmentColumnForm() {
    this.departmentColumnForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      tag: new FormControl(''),
      notes: new FormControl(''),
    });
  }

  onSubmitdepartmentColumnForm() {
    if (this.departmentColumnForm.invalid) {
      return;
    }

    this.isdepartmentColumnFormSubmitted = true;

    if (this.editDepartmentColumnData?.id) {
      this.updateDepartmentColumn();
    } else {
      this.saveDepartmentColumn();
    }
  }

  saveDepartmentColumn() {
    this.departmentService
      .createDepartmentColumn(this.departmentColumnForm.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: DepartmentDataResponse<DepartmentCreateResponse>) => {
          this.toastr.success(res?.details?.message);
          this.addmodalService.dismissAll();
          this.departmentColumnForm.reset();
          this.isdepartmentColumnFormSubmitted = false;
          this.getDepartmentColumns();
        },
        (error) => {
          this.toastr.success(error?.errors[0]);
        }
      );
  }

  updateDepartmentColumn() {
    this.departmentService
      .updateDepartmentColumn(
        this.editDepartmentColumnData.id,
        this.departmentColumnForm.value
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: DepartmentDataResponse<DepartmentCreateResponse>) => {
          this.toastr.success(res?.details?.message);
          this.addmodalService.dismissAll();
          this.departmentColumnForm.reset();
          this.isdepartmentColumnFormSubmitted = false;
          this.getDepartmentColumns();
          this.editDepartmentColumnData = null;
        },
        (error) => {
          this.toastr.success(error?.errors[0]);
        }
      );
  }

  getDepartmentColumns() {
    this.departmentListBlockUI.start();
    this.departmentService
      .getDepartmentColumns(this.departmentLimit, this.departmentPage)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (
          resp: DepartmentDataResponse<
            DepartmentPaginatedData<DepartmentColumn>
          >
        ) => {
          const { hits, offset, estimatedTotalHits } = resp.details;
          this.departmentColumns = hits;
          this.departmentTotal = estimatedTotalHits;
          this.departmentListBlockUI.stop();
        },
        (error) => {
          this.departmentListBlockUI.stop();
        }
      );
  }

  onPageChangedepartments(event: any) {
    this.departmentPage = event;
    this.getDepartmentColumns();
  }

  onSizeChangedepartments(limit: number) {
    this.departmentLimit = limit;
    this.departmentPage = 1;
    this.getDepartmentColumns();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  editDepartmentColumn(createDepartment, departmentColumn: DepartmentColumn) {
    this.openPopup(createDepartment, 'md');
    const { name, tag, notes } = departmentColumn;
    this.editDepartmentColumnData = departmentColumn;
    this.departmentColumnForm = new FormGroup({
      name: new FormControl(name),
      tag: new FormControl(tag),
      notes: new FormControl(notes),
    });
  }

  deleteDepartmentColumn(id: number) {
    if (confirm('Are you sure you want to delete this department column?')) {
      this.departmentService.deleteDepartmentColumn(id).subscribe(
        (res: DepartmentDataResponse<DepartmentCreateResponse>) => {
          this.toastr.success(res?.details?.message);
          this.getDepartmentColumns();
        },
        ({ error }: HttpErrorResponse) => {
          this.toastr.error(error?.errors[0]);
        }
      );
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.departmentColumns,
      event.previousIndex,
      event.currentIndex
    );

    this.reOrderRows();
  }

  reOrderRows() {
    const req: ReorderReq = {
      order_list: this.departmentColumns.map((name) => name.id),
    };
    this.departmentService.reOrderRow(req, 'columns').subscribe(
      (res: DepartmentDataResponse<DepartmentCreateResponse>) => {
        this.toastr.success(res?.details?.message);
        this.getDepartmentColumns();
      },
      ({ error }: HttpErrorResponse) => {
        this.toastr.error(error?.errors[0]);
      }
    );
  }
}
