import { CdkDrag } from '@angular/cdk/drag-drop';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UserInfo } from 'src/app/models/manage-users/user.info';
import { Profile } from 'src/app/models/provider/provider.profile.model';
import { AccountService } from 'src/app/services/account.service';
import { ManageUsersService } from 'src/app/services/manage.users.service';
import { ModalService } from 'src/app/services/modal.service';
import { ProviderService } from 'src/app/services/provider.service';
import { ProvidertablesService } from 'src/app/services/providertables.service';
import { AppUtils } from 'src/app/utilities/app.utils';
import { environment } from 'src/environments/environment';
import { EntityComponent } from '../shared/Modals/entity/entity.component';
import { GetDownloadResponse, GetEntitiesResponse, UpdateEditable, ArmorPutBody, GetPayCode, GetPayCodeDetail, GetArmor, GetArmorDetailsList, ArmorCreate, UpdateArmor, GetFileData, OtherAssociation, FinancialAssociation, PoCode, UpdateArmorResponse } from 'src/app/models/provider/provider-detail-model';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-entities-detail',
  templateUrl: './entities-detail.component.html',
  styleUrls: ['./entities-detail.component.css'],
})
export class EntitiesDetailComponent implements OnInit, OnDestroy {
  apiURL = environment.fireStoreApiUrl;
  editStatus = true;
  activeBadge = false;
  draggablesArray: CdkDrag<any[]>[];
  left: CdkDrag<any>[];
  right: CdkDrag<any>[];
  addUrl = environment.fireStoreApiUrl + '/api/v1/preference/add/';
  getApiUrl = environment.fireStoreApiUrl + '/api/v1/preference/';
  showGuidance: boolean;
  isMerged: boolean;
  isEdited: boolean;
  mergedAt: string;
  userNow;
  dragData: any = {};
  entityPostData: any = {};
  widgets: boolean = true;
  selectedFile: File = null;
  selectedDocument: File = null;
  isSuperAdmin: boolean = false;
  profileDataReady: boolean = false;
  tipName: string;
  tipAddress: string;
  tipEntity: string;
  tipProfile: any;
  licensesObj: LicensesObj = [];
  WhitePap: any[] = [];
  ClearRep: any[] = [];
  Document: any[] = [];
  spearIDS: string[] = [];
  FinancialInterest: any[] = [];
  NpiTable: any[] = [];
  TinTable: any[] = [];
  suggestedTableFinancial: any[] = [];
  suggestedTableOtherAssociated: any[] = [];
  suggestedAvailable: boolean;
  OtherAssociated: any[] = [];
  MergedFrom: any[] = [];
  entity_type: string;
  businesses: string[] = [];
  isPerson: boolean;
  isBusiness: boolean;
  isEntity: boolean;
  isRecord: boolean;
  isClearReport: boolean;
  isWhiteReport: boolean;
  isDocuments: boolean;

  @ViewChildren(CdkDrag) draggables: QueryList<CdkDrag>;
  @ViewChild('leftList') leftChild;
  @ViewChild('rightList') rightChild;

  userLeft: string[] = [];
  userRight: string[] = [];

  @BlockUI('manage-users-blockUI') blockUI: NgBlockUI;
  closeResult = '';
  users: UserInfo[];
  subsciptions: Subscription[] = [];
  signupForm: FormGroup;
  todayDate: Date = new Date();
  profileId: string;
  profileType: string;
  profileModel: any;
  private subscription: Subscription;
  public selectedSpearID: string = '';
  public armorForm: FormGroup;
  public armorFormCorrections: FormGroup;
  public armorFormObjection: FormGroup;
  public armorFormAdjuster: FormGroup;
  public armorFormStatic: FormGroup;
  public po_codes = [];
  private source_type!: string;
  public armorSet: GetPayCodeDetail[] = [];
  public armorResopone: GetArmorDetailsList[] = [];
  public isArmor: boolean = false;
  private goodPaycode = [250];
  armorReqBody!: ArmorPutBody;
  public isPayCodeEditable: boolean = false;
  public isCorrectionsEditable: boolean = false;
  public isObjectionEditable: boolean = false;
  public isAdjusterEditable: boolean = false;

  //for collapse section
  tinStatus: boolean;
  ownStatus: boolean;
  NPIStatus: boolean;
  licStatus: boolean;
  assoStatus: boolean;
  secreState: boolean;
  fnpStatus: boolean;
  irsStatus: boolean;
  armoStatus: boolean;
  FinaStatus: boolean;
  AssociateStatus: Boolean;
  DocumentsStatus: Boolean;
  TopReferringStatus: Boolean;

  adrs: any;
  showPersonDetails: boolean = true;
  showBusinessDetails: boolean;
  showEntityDetails: boolean;
  currentActiveTab: string | number = '';
  entityAddre = {
    type: 'Entity',
    name: '',
    address: '',
  };
  ent_no = 0;
  page = 1;
  pageSize = 1;
  collectionSize = 1;
  payCodes = [
    {
      id: 0,
      code: 0,
      category: '',
      title: '',
      description: '',
      date: 0,
      status: '',
      authority: '',
      statecode: '',
      objecttion_language: '',
      narrative_reference: '',
      pay_code_rule: '',
      suspended_date: '',
    },
  ];
  armorData: any;
  randNum: number;
  associations_other: OtherAssociation[] = [];
  associations_financial: FinancialAssociation[] = [];
  selectedProfile: number;
  association_type_dis: string;
  entityId: number;
  source_types: string[] = [];
  add_armor_spear_id: string = '';
  deleteArmorId: number;
  editArmorData: GetArmorDetailsList;
  activeTab: string = 'Dashboard';
  pageTabs = [
    'Dashboard',
    'Notes',
    'Documents',
    '24 J ASSOCIATIONS',
    '25/32 ASSOCIATIONS ADDRESS HISTORY',
    '25/33 ASSOCIATIONS BILLING INFORMATION',
    'CPT BY %',
    'REFERRING PHYSICIANS',
    'RECORD IDENTIFIERS'
  ];

  constructor(
    private addmodalService: NgbModal,
    private manageUsersService: ManageUsersService,
    public datepipe: DatePipe,
    private toastr: ToastrService,
    private modalService: ModalService,
    private http: HttpClient,
    private currentUser: AccountService,
    private activeRoute: ActivatedRoute,
    private providerService: ProviderService,
    private router: Router,
    private providertable: ProvidertablesService,
    private formBuilder: FormBuilder
  ) {

    this.activeRoute.params.subscribe((param) => {
      this.blockUI.start();
      this.entityId = param.id;

      this.modalService.getRefreshFinancialOtherAssociation().subscribe(data =>{
        this.http
          .get<GetEntitiesResponse>(
            `${this.apiURL}/api/v1/associations/entities/get/${this.entityId}/`
          )
          .pipe(tap(() => {
            this.associations_financial = [];
            this.associations_other = [];
          }))
          .subscribe(async (resp) => {
            if (resp) {
              this.profileDataReady = true;
              this.profileModel = resp;
              for (
                let i = 0;
                i < this.profileModel.details.associations.length;
                i++
              ) {
                this.spearIDS.push(this.profileModel.details?.associations[i]?.spear_profile?.spear_id);
                this.source_type = this.profileModel?.details?.associations[i]?.spear_profile?.spear_id_prefix;
                if(!this.source_types.includes(this.source_type)){
                  this.source_types.push(this.source_type);
                  this._preloadArmor(this.source_type);
                }
                const prov_adrs =
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_street_address_1
                    ? `<div>${this.profileModel.details?.associations[i]?.spear_profile?.provider_street_address_1}</div>`
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_street_address_2
                    ? `<div>${this.profileModel.details?.associations[i]?.spear_profile?.provider_street_address_2}</div>`
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_city
                    ? `<div>${this.profileModel.details?.associations[i]?.spear_profile?.provider_city}</div>`
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_state
                    ? `${this.profileModel.details?.associations[i]?.spear_profile?.provider_state} `
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_zip
                    ? this.profileModel.details?.associations[i]?.spear_profile
                      ?.provider_zip
                    : '');
                const bus_adrs =
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_street_address_1_practice
                    ? `<div>${this.profileModel.details?.associations[i]?.spear_profile?.provider_street_address_1_practice}</div>`
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_street_address_2_practice
                    ? `<div>${this.profileModel.details?.associations[i]?.spear_profile?.provider_street_address_2_practice}</div>`
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_city_practice
                    ? `<div>${this.profileModel.details?.associations[i]?.spear_profile?.provider_city_practice}</div>`
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_state_practice
                    ? `${this.profileModel.details?.associations[i]?.spear_profile?.provider_state_practice} `
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_zip_practice
                    ? this.profileModel.details?.associations[i]?.spear_profile
                      ?.provider_zip_practice
                    : '');
                const adrs = bus_adrs ? bus_adrs : prov_adrs ? prov_adrs : '';
                const prov_nam =
                  this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_name_person;
                const bus_nam =
                  this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_name_business;
                const nam = bus_nam ? bus_nam : prov_nam ? prov_nam : '';
                const tin =
                  this.profileModel.details?.associations[i]?.spear_profile
                    ?.tax_id_number;
                const npi =
                  this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_npi_number;
                const prov_phone =
                  this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_phone;
                const bus_phone =
                  this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_phone_practice;
                const phone_raw = bus_phone
                  ? bus_phone
                  : prov_phone
                    ? prov_phone
                    : '';
                const cleanedNumber = phone_raw.toString().replace(/\D/g, '');
                const phone = `(${cleanedNumber.substr(
                  0,
                  3
                )}) ${cleanedNumber.substr(3, 3)}-${cleanedNumber.substr(6)}`;
                const type =
                  this.profileModel.details?.associations[i]?.association_type;
                if (type === 'Other') {
                  this.associations_other.push({
                    name: nam,
                    address: adrs,
                    note: this.profileModel.details?.associations[i]?.note,
                    spear_id:
                      this.profileModel.details?.associations[i]?.spear_profile
                        ?.spear_id,
                    id: this.profileModel.details?.associations[i]?.spear_profile
                    ?.id
                  });
                } else if (type === 'Financial') {
                  this.associations_financial.push({
                    name: nam,
                    tin: (tin ? tin : 'Not Listed'),
                    npi: (npi ? npi : 'Not Listed'),
                    address: adrs,
                    phone: (phone_raw !== '' ? phone : 'Not Listed'),
                    spear_id:
                      this.profileModel.details?.associations[i]?.spear_profile
                        ?.spear_id,
                    id: this.profileModel.details?.associations[i]?.spear_profile
                    ?.id
                  });
                }
              }
            }
            if (this.spearIDS.length > 0) {
              this._populateArmorPageFields();
              const sprIDs = this.spearIDS.join(',');
              this.http.get<GetFileData>(this.apiURL + `/api/v1/file/all/Document/?spear_id_list=${sprIDs}`).subscribe(
                (data) => {
                  if (data.details && Object.keys(data.details).length > 0) {
                    for (let i = 0; i <= data.details.length - 1; i++) {
                      this.Document.push({
                        filename: data.details[i].file_name,
                        uploaddt: data.details[i].created_at,
                        id: data.details[i].id,
                      });
                    }
                    this.isDocuments = true;
                  } else {
                    this.Document = [];
                    this.isDocuments = false;
                  }
                }, (error) => {
                  this.Document = [];
                  this.isDocuments = false;
                  console.error('Error fetching documents:', error);
                }
              );
            }
            // Create an object to store counts for each NPI
            const npiCounts = {};
            const uniqueNPIs = new Set();

            // Helper function to handle the asynchronous call
            const getProvidersFiltered = async (rend_npi) => {
              const response = await this.providerService.getProvidersFilteredFromFireStore(1, 20, true, `&npi=${rend_npi}`, false).toPromise();
              if (response.details.hits.length > 0) {
                const provider = response.details.hits[0];
                // Check if provider NPI is not null or empty and entity type code is 2
                if (provider.provider_npi_number && provider.entity_type_code === 2) {
                  return true;
                }
              }
              return false;
            };

            for (const el of this.associations_financial) {
              // Check if NPI is not 'Not Listed'
              if (el.npi !== 'Not Listed' && !uniqueNPIs.has(el.npi)) {
                uniqueNPIs.add(el.npi);

                // Find the association in the profile details based on NPI
                const association = this.profileModel.details.associations.find((x) => x.spear_profile.provider_npi_number === el.npi);

                // Initialize count for the current NPI if it doesn't exist
                if (!npiCounts[el.npi]) {
                  npiCounts[el.npi] = 0;
                }

                // Check if the entity type code is 1
                if (association.spear_profile.entity_type_code === 1) {
                  const rend_npi = association.spear_profile.bill_review_provider_rendering_npi;

                  if (rend_npi) {
                    try {
                      const isValidProvider = await getProvidersFiltered(rend_npi);

                      if (isValidProvider) {
                        // Increment count for the current NPI
                        npiCounts[el.npi] += 1;
                      }
                    } catch (error) {
                      console.error('Error fetching provider details:', error);
                    }
                  }
                } else {
                  // If entity type code is not 1, increment count for the current NPI
                  npiCounts[el.npi] += 1;
                }

                // Add information to NpiTable array
                this.NpiTable.push({
                  npi: association.spear_profile.provider_npi_number,
                  status: association.spear_profile.npi_active ? 'Active' : 'Inactive',
                  box32: npiCounts[el.npi],
                });
              }
            }

            const tinAssociation = this.profileModel.details.associations.find((x): boolean => x.spear_profile.tax_id_number !== null || x.spear_profile.tax_id_number !== '');
            if (tinAssociation.spear_profile.tax_id_number !== null) {
              this.TinTable.push({
                tin: tinAssociation.spear_profile.tax_id_number
              });
            }

          });
      });

    });
  }

  ngOnInit(): void {
    this.randNum = this.getRand();
    this.currentUser.getCurrentUserProfile().subscribe((data: any) => {
      this.userNow = data.details.name;
      if (this.userNow == 'investigator') {
        this.isSuperAdmin = true;
      }
    });

    if (this.profileDataReady) {
    }

    this.providertable.onLoadPopulateTable();

    this.FinancialInterest = this.providertable.FinancialInterest;
    this.OtherAssociated = this.providertable.OtherAssociated;
    this.armorFormStatic = this.formBuilder.group({
      armor_effective_date_of_action: new FormControl(''),
      armor_po_code: new FormControl(0),
      armor_pay_code_status: new FormControl(''),
      armor_pay_code_rule: new FormControl('')
    });
    this.armorForm = this.formBuilder.group({
      armor_po_code: new FormControl(0),
      armor_br_code: new FormControl(''),
      armor_state_code: new FormControl(''),
      armor_adj_alert_sent: new FormControl(''),
      armor_alert_date: new FormControl(''),
      armor_additional_pay_code: new FormControl(''),
      armor_effective_obj_from: new FormControl(''),
      armor_effective_obj_to: new FormControl(''),
      armor_notice_issued: new FormControl(''),
      armor_suspension_date: new FormControl('')
    });
    this.armorFormCorrections = this.formBuilder.group({
      armor_corrections: new FormControl('')
    });
    this.armorFormObjection = this.formBuilder.group({
      armor_objections: new FormControl('')
    });
    this.armorFormAdjuster = this.formBuilder.group({
      armor_adjuster: new FormControl('')
    });
  }

  ngOnDestroy(): void {
    this.subsciptions.forEach((x) => x.unsubscribe());
    this.providerService.ShowButtonsInProvider = false;
  }

  private loadUsers(): void {
    this.blockUI.start();
    this.manageUsersService.getUsers().subscribe((data) => {
      this.users = data as UserInfo[];
      this.blockUI.stop();
    });
  }
  openModal(): void {
    this.modalService.openModalForAddOrEditUser();
  }

  edit(userId: string): void {
    this.modalService.openModalForAddOrEditUser(userId);
  }
  activateOrInactive(userId: string, isActive: boolean): void {
    this.blockUI.start();
    this.manageUsersService
      .activateOrInactivateUser(userId, !isActive)
      .subscribe((data) => {
        this.loadUsers();
        this.blockUI.stop();
      });
  }

  closeEdit(): void {
    this.editStatus = !this.editStatus;
  }

  backtoMerge(): void {
    this.router.navigate(['/merge']);
  }

  searchMerge(npiCode: string): void {
    this.router.navigate(['/merge', { npi: npiCode }]);
  }

  togglePanel(rec: string): void {
    if (rec === 'Tin') this.tinStatus = !this.tinStatus;
    if (rec === 'Own') this.ownStatus = !this.ownStatus;
    if (rec === 'NPI') this.NPIStatus = !this.NPIStatus;
    if (rec === 'LIC') this.licStatus = !this.licStatus;
    if (rec === 'Asso') this.assoStatus = !this.assoStatus;
    if (rec === 'Secre') this.secreState = !this.secreState;
    if (rec === 'Irs') this.irsStatus = !this.irsStatus;
    if (rec === 'Fnp') this.fnpStatus = !this.fnpStatus;
    if (rec === 'Armo') this.armoStatus = !this.armoStatus;
    if (rec === 'Fina') this.FinaStatus = !this.FinaStatus;
    if (rec === 'Associate') this.AssociateStatus = !this.AssociateStatus;
    if (rec === 'Documents') this.DocumentsStatus = !this.DocumentsStatus;
    if (rec === 'TopReferring')
      this.TopReferringStatus = !this.TopReferringStatus;
  }

  getPageSymbol(current: number) {
    return ['A', 'B', 'C', 'D', 'E', 'F', 'G'][current - 1];
  }

  getRand() {
    return Math.floor(Math.random() * (9 - 0) + 1);
  }
  payCodeforAromor(paycode: number) {
    // this.payCodes.forEach((element, index) => {
    //   if (element.code === paycode) {
    //     this.page = index + 1;
    //   }
    // });
  }

  deleteConfirmation(uID: any, deleteFrom: any): void {
    this.modalService.openModalForConfirmation(uID, deleteFrom);

    this.providertable.configObservable.subscribe((data) => {
      if (deleteFrom == 'Clear') {
        this.ClearRep = this.providertable.ClearRep;
        if (this.ClearRep.length > 0) {
          this.isClearReport = true;
        } else {
          this.isClearReport = false;
        }
      } else if (deleteFrom == 'White') {
        this.WhitePap = this.providertable.WhitePap;
        if (this.WhitePap.length > 0) {
          this.isWhiteReport = true;
        } else {
          this.isWhiteReport = false;
        }
      }
    });
  }

  deleteFinancial(id: any): void {
    this.modalService.openDisccociateFinancialInterestModal(id);
    this.providertable.configObservable.subscribe((data) => {
      this.FinancialInterest = [];
      this.FinancialInterest = this.providertable.FinancialInterest;
      let adrs = `${this.profileModel.details?.provider_street_address_1}, ${this.profileModel.details?.provider_street_address_2}, ${this.profileModel.details?.provider_city}, ${this.profileModel.details?.provider_state} ${this.profileModel.details?.provider_zip}`;
      this.providerService
        .getSuggestedList(
          5,
          0,
          adrs,
          this.profileModel.details.entity_type_code,
          this.profileModel.details.title_or_position
        )
        .subscribe((data) => {
          if (data || data != null) {
            for (let i = 0; i <= data.details.hits.length - 1; i++) {
              if (
                data.details.hits[i].spear_id !=
                this.profileModel.details.spear_id
              ) {
                this.suggestedTableFinancial = [];
                const result = this.FinancialInterest.find(
                  ({ firestore }) => firestore == data.details.hits[i].spear_id
                );
                if (result == undefined) {
                  this.suggestedTableFinancial.push({
                    name: data.details.hits[i].provider_name_person,
                    npi: data.details.hits[i].provider_npi_number,
                    code: data.details.hits[i].po_code,
                    id: data.details.hits[i].spear_id,
                  });
                }
              }
            }
          }
        });
    });
  }

  deleteAssociate(id: any): void {
    this.modalService.openDisccociateOtherAssociate(id);
    this.providertable.configObservable.subscribe((data) => {
      this.OtherAssociated = [];
      this.OtherAssociated = this.providertable.OtherAssociated;
      let adrs = `${this.profileModel.details?.provider_street_address_1}, ${this.profileModel.details?.provider_street_address_2}, ${this.profileModel.details?.provider_city}, ${this.profileModel.details?.provider_state} ${this.profileModel.details?.provider_zip}`;
      this.providerService
        .getSuggestedList(
          5,
          0,
          adrs,
          this.profileModel.details.entity_type_code,
          this.profileModel.details.title_or_position
        )
        .subscribe((data) => {
          if (data || data != null) {
            for (let i = 0; i <= data.details.hits.length - 1; i++) {
              if (
                data.details.hits[i].spear_id !=
                this.profileModel.details.spear_id
              ) {
                this.suggestedTableOtherAssociated = [];
                const result = this.OtherAssociated.find(
                  ({ firestore }) => firestore == data.details.hits[i].spear_id
                );
                if (result == undefined) {
                  this.suggestedTableOtherAssociated.push({
                    name: data.details.hits[i].provider_name_person,
                    npi: data.details.hits[i].provider_npi_number,
                    code: data.details.hits[i].po_code,
                    id: data.details.hits[i].spear_id,
                  });
                }
              }
            }
          }
        });
    });
  }

  chooseEntity(entityChoice: any) {
    const confirmationModal = this.addmodalService.open(EntityComponent, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size: 'sm',
      centered: true,
    });
    confirmationModal.componentInstance.entityChoice = entityChoice;
    confirmationModal.componentInstance.evt.subscribe(($event) => {
      if ($event) {
        if (entityChoice == 'Person') {
          this.entityPostData.entity_type_code = 1;
          let postURL = `${environment.fireStoreApiUrl}/api/v1/profile/entity/${this.profileId}/`;
          this.http.patch(postURL, this.entityPostData).subscribe(
            (data) => {
              window.location.reload();
            },
            (error) => { }
          );
        } else if (entityChoice == 'Business') {
          this.entityPostData.entity_type_code = 2;
          let postURL = `${environment.fireStoreApiUrl}/api/v1/profile/entity/${this.profileId}/`;
          this.http.patch(postURL, this.entityPostData).subscribe(
            (data) => {
              window.location.reload();
            },
            (error) => { }
          );
        }
      }
    });
  }

  download(userUID: any) {
    this.http
      .get<GetDownloadResponse>(`${environment.fireStoreApiUrl}/api/v1/file/download/${userUID}`)
      .subscribe((data: any) => {
        const a = document.createElement('a');
        a.href = data.details.signed_url;
        a.click();
      });
  }

  openAddFinancialInterestModal(uID: string): void {
    this.modalService.openAddFinancialInterestModal(uID);
    this.providertable.configObservable.subscribe((data) => {
      this.FinancialInterest = this.providertable.FinancialInterest;
    });
  }

  openAddAssociationModal(associationType: string): void {
    this.modalService.openAddFinancialOtherAssociationModal(associationType);
  }

  openAlertLang(lang: string): void {
    this.modalService.openAlertLang(lang);
  }

  showId(id: string) {
    this.tipName = '';
    this.tipAddress = '';
    this.tipEntity = '';
    this.providerService
      .getProviderProfile(id)
      .subscribe((response: Profile) => {
        this.tipProfile = response;
        this.tipName = this.tipProfile.details.provider_name_person;

        if (this.tipProfile.details.entity_type_code == 1) {
          this.tipEntity = 'Person';
        } else if (this.tipProfile.details.entity_type_code == 2) {
          this.tipEntity = 'Business';
        } else if (
          this.tipProfile.details.entity_type_code == 0 ||
          this.tipProfile.details.entity_type_code == '' ||
          this.tipProfile.details.entity_type_code == null
        ) {
          this.tipEntity = 'Entity';
        }
        this.tipAddress = `${this.tipProfile.details?.provider_street_address_1}, ${this.tipProfile.details?.provider_street_address_2}, ${this.tipProfile.details?.provider_city}, ${this.tipProfile.details?.provider_state}, ${this.tipProfile.details?.provider_state}, ${this.tipProfile.details?.provider_zip}`;
      });
  }

  addFinancialSuggested(selectedFirestore: string) {
    const result = this.FinancialInterest.find(
      ({ firestore }) => firestore == selectedFirestore
    );
    if (result == undefined) {
      this.providertable.uploadFinancialInterest(selectedFirestore);
      this.providertable.configObservable.subscribe((data) => {
        this.FinancialInterest = this.providertable.FinancialInterest;
      });
      let refreshSuggested = this.suggestedTableFinancial.filter(
        (item) => item.id !== selectedFirestore
      );
      this.suggestedTableFinancial = refreshSuggested;
    }
  }
  public updateFNP() {
    const fnpVal = (<HTMLTextAreaElement>document.getElementById('fnp_status'))
      .value;
    const reqBody = {
      fnp_status: fnpVal,
    };
    this.http
      .put<UpdateEditable>(
        `${environment.fireStoreApiUrl}/api/v1/profile/update_editable_fields/${this.profileId}/`,
        reqBody
      )
      .subscribe((resp): void => {
        this.toastr.success('FNP status has been updated successfully');
      });
  }

  editArmorDetails(armor:GetArmorDetailsList){
    this.editArmorData = armor;
    this.populatePrevNextArmor(armor.po_code);
  }
  populatePrevNextArmor(pocode: number): void {
    const selectedArmor: GetArmorDetailsList = this.armorResopone.find(ax => ax.po_code === pocode);
    this.armorForm.get('armor_po_code').setValue(selectedArmor?.po_code);
    this.armorFormStatic.get('armor_po_code').setValue(selectedArmor?.po_code);
    this.armorForm.get('armor_state_code').setValue(selectedArmor?.state_code);
    this.armorFormStatic.get('armor_pay_code_status').setValue(selectedArmor?.pay_code_status);
    this.armorFormStatic.get('armor_pay_code_rule').setValue(selectedArmor?.pay_code_rule);
    selectedArmor.armor_objections = selectedArmor?.state_bill_objection_language;
    if (this.profileModel?.details?.spear_id_prefix === 'MBC') {
      this.armorFormStatic.get('armor_pay_code_status').setValue(selectedArmor?.license_status_description_short);
      selectedArmor.armor_objections = selectedArmor?.license_status_description_long;
    }
    this.armorFormObjection.get('armor_objections').setValue(selectedArmor.armor_objections);
    this.armorFormCorrections.get('armor_corrections').setValue(selectedArmor?.correction_needed);
    this.armorFormAdjuster.get('armor_adjuster').setValue(selectedArmor?.adjuster_poa);
  }

  public selectPayCode(e: any) {
    const code = Number(e.target.value);
    this._populateSelectedPayCode(code);
  }
  private _populateSelectedPayCode(pocode: number): void {
    const po_code_item = this.po_codes.find((poCode: PoCode): boolean => poCode.po_code == pocode);
    const selectedArmor: GetPayCodeDetail = this.armorSet.find(ax => ax.po_code === po_code_item.po_code && ax.spear_id_prefix == po_code_item.source_type);
    this.armorForm.get('armor_po_code').setValue(selectedArmor?.po_code);
    this.armorFormStatic.get('armor_po_code').setValue(selectedArmor?.po_code);
    this.armorForm.get('armor_state_code').setValue(selectedArmor?.state_code);
    this.armorFormStatic.get('armor_pay_code_status').setValue(selectedArmor?.pay_code_status);
    this.armorFormStatic.get('armor_pay_code_rule').setValue(selectedArmor?.pay_code_rule);
    this.armorFormObjection.get('armor_objections').setValue(selectedArmor?.state_bill_objection_language);
    if (this.profileModel?.details?.spear_id_prefix === 'MBC') {
      this.armorFormStatic.get('armor_pay_code_status').setValue(selectedArmor?.license_status_description_short);
      this.armorFormObjection.get('armor_objections').setValue(selectedArmor?.license_status_description_long);
    }
  }

  private _preloadArmor(data_source: string): void {
    if (data_source) {
      this.http.get<GetPayCode>(this.apiURL + `/api/v1/profile/get_pay_code_list/?spear_id_prefix=${data_source}`).subscribe((resp): void => {
        if (resp) {
          resp.details.forEach((element): void => {
            this.po_codes.push({ po_code: element.po_code, source_type: element.spear_id_prefix });
          });
          this.armorSet.push(...resp.details);
        }
      });
    }
  }


  private _populateArmorPageFields(editArmor = false): void {
    const sprIDs = this.spearIDS.join(',');
    this.http.get<GetArmor>(`${this.apiURL}/api/v1/profile/armor/all/?limit=100&offset=1&spear_id_list=${sprIDs}`)
    .pipe(tap((resp) => {
      if (resp.details.hits?.length) {
        resp.details.hits = resp.details.hits.sort((a, b) => a.id - b.id);
      }
      return resp;
    }))
    .subscribe((resp): void => {
      if (resp) {
        this.armorResopone = resp.details.hits;
        if(editArmor){
          const editArmorData = this.armorResopone.find(ax => ax.id === this.editArmorData.id);
          this.editArmorDetails(editArmorData);
        }
        // if (this.armorResopone.length > 0) {
        //   this.collectionSize = this.armorResopone.length;
        //   this.armorFormStatic.get('armor_po_code').setValue(this.armorResopone[this.page - 1]?.po_code);
        //   this.armorForm.get('armor_po_code').setValue(this.armorResopone[this.page - 1]?.po_code);
        //   this.armorForm.get('armor_state_code').setValue(this.armorResopone[this.page - 1]?.state_code);
        //   this.armorFormStatic.get('armor_pay_code_status').setValue(this.armorResopone[this.page - 1]?.pay_code_status);
        //   this.armorFormStatic.get('armor_pay_code_rule').setValue(this.armorResopone[this.page - 1]?.pay_code_rule);
        //   this.armorFormObjection.get('armor_objections').setValue(this.armorResopone[this.page - 1]?.state_bill_objection_language);
        //   if (this.profileModel?.details?.spear_id_prefix === 'MBC') {
        //     this.armorFormStatic.get('armor_pay_code_status').setValue(this.armorResopone[this.page - 1]?.license_status_description_short);
        //     this.armorFormObjection.get('armor_objections').setValue(this.armorResopone[this.page - 1]?.license_status_description_long);
        //   }
        //   this.armorFormCorrections.get('armor_corrections').setValue(this.armorResopone[this.page - 1]?.correction_needed);
        //   this.armorFormAdjuster.get('armor_adjuster').setValue(this.armorResopone[this.page - 1]?.adjuster_poa);
        // }
      }
    });
  }

  public addArmor(): void {
    this.profileId = this.add_armor_spear_id;
    const toAddPayCode: number = Number((<HTMLSelectElement>(document.getElementById('pay_code_list'))).value);
    const po_code_item = this.po_codes.find((poCode: PoCode): boolean => poCode.po_code == toAddPayCode);
    const selectedArmor: GetPayCodeDetail = this.armorSet.find(ax => ax.po_code === po_code_item.po_code && ax.spear_id_prefix == po_code_item.source_type);
    let reqBody;
    reqBody = {
      po_code: toAddPayCode,
      pay_code_status: selectedArmor?.pay_code_status,
      pay_code_rule: selectedArmor?.pay_code_rule,
      state_code: selectedArmor?.state_code,
      state_bill_objection_language: selectedArmor?.state_bill_objection_language
    }
    if (this.profileModel?.details?.spear_id_prefix === 'MBC') {
      reqBody = {
        po_code: toAddPayCode,
        license_status_description_short: selectedArmor?.license_status_description_short,
        pay_code_rule: selectedArmor?.pay_code_rule,
        state_code: selectedArmor?.state_code,
        license_status_description_long: selectedArmor?.license_status_description_long
      }
    }
    this.http.post<ArmorCreate>(`${environment.fireStoreApiUrl}/api/v1/profile/armor/create/${this.profileId}/`, reqBody).subscribe((resp): void => {
      if (resp) {
        this._populateArmorPageFields();
        this.addmodalService.dismissAll();
        this.page = this.armorResopone.length;
      }
    });
  }

  public removeArmor(): void {
    this.http.delete(`${environment.fireStoreApiUrl}/api/v1/profile/armor/delete/${this.deleteArmorId}/`).subscribe((): void => {
      this._populateArmorPageFields();
      this.addmodalService.dismissAll();
      this.page = this.armorResopone.length;
      this.deleteArmorId = null;
    });
  }

  public closeAddArmor(): void {
    this.addmodalService.dismissAll();
  }

  public updatePayCode(): void {
    this.armorReqBody = {};
    this.armorForm.patchValue({
      armor_po_code: this.armorForm.get('armor_po_code')?.value,
      armor_state_code: this.armorForm.get('armor_state_code')?.value
    });
    this.armorFormStatic.patchValue({
      armor_pay_code_status: this.armorFormStatic.get('armor_pay_code_status')?.value,
      armor_pay_code_rule: this.armorFormStatic.get('armor_pay_code_rule')?.value
    });
    this.armorFormCorrections.patchValue({
      armor_corrections: this.armorFormCorrections.get('armor_corrections')?.value
    });
    this.armorFormObjection.patchValue({
      armor_objections: this.armorFormObjection.get('armor_objections')?.value
    });
    this.armorFormAdjuster.patchValue({
      armor_adjuster: this.armorFormAdjuster.get('armor_adjuster')?.value
    });

    this.armorReqBody = {
      po_code: this.armorForm.get('armor_po_code')?.value,
      pay_code_status: this.armorFormStatic.get('armor_pay_code_status')?.value,
      pay_code_rule: this.armorFormStatic.get('armor_pay_code_rule')?.value,
      state_code: this.armorForm.get('armor_state_code')?.value,
      state_bill_objection_language: this.armorFormObjection.get('armor_objections')?.value,
      correction_needed: this.armorFormCorrections.get('armor_corrections')?.value,
      adjuster_poa: this.armorFormAdjuster.get('armor_adjuster')?.value
    };

    if (this.profileModel?.details?.spear_id_prefix === 'MBC') {
      this.armorReqBody = {
        po_code: this.armorForm.get('armor_po_code')?.value,
        license_status_description_short: this.armorFormStatic.get('armor_pay_code_status')?.value,
        pay_code_rule: this.armorFormStatic.get('armor_pay_code_rule')?.value,
        state_code: this.armorForm.get('armor_state_code')?.value,
        license_status_description_long: this.armorFormObjection.get('armor_objections')?.value,
        correction_needed: this.armorFormCorrections.get('armor_corrections')?.value,
        adjuster_poa: this.armorFormAdjuster.get('armor_adjuster')?.value
      };
    }
    this.http.put<UpdateArmorResponse>(`${environment.fireStoreApiUrl}/api/v1/profile/armor/update/${this.editArmorData.id}/`,
      this.armorReqBody).subscribe((data): void => {
        this._populateArmorPageFields(true);
        this.isPayCodeEditable = false;
        this.toastr.success(data?.details?.message);
      });
  }
  public updateCorrections(): void {
    this.updatePayCode();
    this.isCorrectionsEditable = false;
  }
  public updateObjection(): void {
    this.updatePayCode();
    this.isObjectionEditable = false;
  }
  public updateAdjuster(): void {
    this.updatePayCode();
    this.isAdjusterEditable = false;
  }
  public enableArmorForm(): void {
    this.isPayCodeEditable = true;
  }

  public enableCorrectionsForm(): void {
    this.isCorrectionsEditable = true;
  }

  public enableObjectionForm(): void {
    this.isObjectionEditable = true;
  }

  public enableAdjusterForm(): void {
    this.isAdjusterEditable = true;
  }

  public onPageChange(page: number): void {
    this.populatePrevNextArmor(this.armorResopone[page - 1].po_code);
  }

  public openAddArmor(content) {
    this.add_armor_spear_id = '';
    this.addmodalService.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size: 'sm',
      centered: true,
    });
  }

  public openDeleteArmor(content,id:number) {
    this.deleteArmorId = id;
    this.addmodalService.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size: 'sm',
      centered: true,
    });
  }

  public deleteDocument(uid: string, spearId: string[]): void {
    this.modalService.openModalForDeleteDocument(uid, spearId);
    this.subscription = this.providertable.documentSubject.subscribe(
      (document: any) => {
        if (document.length > 0) {
          this.Document = document;
          this.isDocuments = true;
        } else {
          this.isDocuments = false;
        }
      }
    );
  }

  public onFileSelectedDocument(event) {
    this.selectedDocument = <File>event.target.files[0];
    const fileData = new FormData();
    fileData.append("file", this.selectedDocument, this.selectedDocument.name);
    fileData.append("spear_id", this.selectedSpearID);
    const sprdID = this.spearIDS.join(',');
    this.http.post(this.apiURL + "/api/v1/file/?file_type=Document", fileData).subscribe(data => {

      this.http.get<any>(this.apiURL + `/api/v1/file/all/Document/?spear_id_list=${sprdID}`).subscribe(data => {
        this.Document = [];
        for (let i = 0; i <= data.details.length - 1; i++) {
          this.Document.push(
            {
              filename: data.details[i].file_name,
              uploaddt: data.details[i].created_at,
              id: data.details[i].id
            }
          );
        }
        this.toastr.success('The file has been uploaded successfully');
        this.isDocuments = true;
      });
      this.addmodalService.dismissAll();
    });
  }

  public openUploadDocument(content) {
    this.addmodalService.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size: 'sm',
      centered: true,
    });
  }

  public onSpearIDChange(selectedValue: string) {
    this.selectedSpearID = selectedValue;
  }

  public openDisassociate(content, selectedAssociates: number, association: string) {
    this.addmodalService.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size: 'sm',
      centered: true,
    });
    this.selectedProfile = selectedAssociates;
    this.association_type_dis = association;
  }

  public disAssociate() {
    if (this.association_type_dis === 'financial') {
      this.http.delete<any>(`${this.apiURL}/api/v1/associations/delete/${this.selectedProfile}/${this.entityId}`).subscribe((resp) => {
        this.toastr.success('The disassociation has been been successfull');
        this.addmodalService.dismissAll();
        this.associations_financial = [];
        this.http
          .get<GetEntitiesResponse>(
            `${this.apiURL}/api/v1/associations/entities/get/${this.entityId}/`
          )
          .subscribe(async (resp) => {
            if (resp) {
              this.profileDataReady = true;
              this.profileModel = resp;
              for (
                let i = 0;
                i < this.profileModel.details.associations.length;
                i++
              ) {
                this.spearIDS.push(this.profileModel.details?.associations[i]?.spear_profile?.spear_id);
                const prov_adrs =
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_street_address_1
                    ? `<div>${this.profileModel.details?.associations[i]?.spear_profile?.provider_street_address_1}</div>`
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_street_address_2
                    ? `<div>${this.profileModel.details?.associations[i]?.spear_profile?.provider_street_address_2}</div>`
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_city
                    ? `<div>${this.profileModel.details?.associations[i]?.spear_profile?.provider_city}</div>`
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_state
                    ? `${this.profileModel.details?.associations[i]?.spear_profile?.provider_state} `
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_zip
                    ? this.profileModel.details?.associations[i]?.spear_profile
                      ?.provider_zip
                    : '');
                const bus_adrs =
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_street_address_1_practice
                    ? `<div>${this.profileModel.details?.associations[i]?.spear_profile?.provider_street_address_1_practice}</div>`
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_street_address_2_practice
                    ? `<div>${this.profileModel.details?.associations[i]?.spear_profile?.provider_street_address_2_practice}</div>`
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_city_practice
                    ? `<div>${this.profileModel.details?.associations[i]?.spear_profile?.provider_city_practice}</div>`
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_state_practice
                    ? `${this.profileModel.details?.associations[i]?.spear_profile?.provider_state_practice} `
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_zip_practice
                    ? this.profileModel.details?.associations[i]?.spear_profile
                      ?.provider_zip_practice
                    : '');
                const adrs = bus_adrs ? bus_adrs : prov_adrs ? prov_adrs : '';
                const prov_nam =
                  this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_name_person;
                const bus_nam =
                  this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_name_business;
                const nam = bus_nam ? bus_nam : prov_nam ? prov_nam : '';
                const tin =
                  this.profileModel.details?.associations[i]?.spear_profile
                    ?.tax_id_number;
                const npi =
                  this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_npi_number;
                const prov_phone =
                  this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_phone;
                const bus_phone =
                  this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_phone_practice;
                const phone_raw = bus_phone
                  ? bus_phone
                  : prov_phone
                    ? prov_phone
                    : '';
                const cleanedNumber = phone_raw.toString().replace(/\D/g, '');
                const phone = `(${cleanedNumber.substr(
                  0,
                  3
                )}) ${cleanedNumber.substr(3, 3)}-${cleanedNumber.substr(6)}`;
                const type =
                  this.profileModel.details?.associations[i]?.association_type;
                if (type === 'Financial') {
                  this.associations_financial.push({
                    name: nam,
                  tin: (tin ? tin : 'Not Listed'),
                  npi: (npi ? npi : 'Not Listed'),
                  address: adrs,
                  phone: (phone_raw !== '' ? phone : 'Not Listed'),
                  spear_id:
                    this.profileModel.details?.associations[i]?.spear_profile
                      ?.spear_id,
                  id: this.profileModel.details?.associations[i]?.spear_profile
                  ?.id
                  });
                }
              }
            }
          });
      });
    } else if (this.association_type_dis === 'other') {
      this.http.delete<any>(`${this.apiURL}/api/v1/associations/delete/${this.selectedProfile}/${this.entityId}`).subscribe((resp) => {
        this.toastr.success('The disassociation has been been successfull');
        this.addmodalService.dismissAll();
        this.associations_other = [];
        this.http
          .get<GetEntitiesResponse>(
            `${this.apiURL}/api/v1/associations/entities/get/${this.entityId}/`
          )
          .subscribe(async (resp) => {
            if (resp) {
              this.profileDataReady = true;
              this.profileModel = resp;
              for (
                let i = 0;
                i < this.profileModel.details.associations.length;
                i++
              ) {
                this.spearIDS.push(this.profileModel.details?.associations[i]?.spear_profile?.spear_id);
                const prov_adrs =
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_street_address_1
                    ? `<div>${this.profileModel.details?.associations[i]?.spear_profile?.provider_street_address_1}</div>`
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_street_address_2
                    ? `<div>${this.profileModel.details?.associations[i]?.spear_profile?.provider_street_address_2}</div>`
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_city
                    ? `<div>${this.profileModel.details?.associations[i]?.spear_profile?.provider_city}</div>`
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_state
                    ? `${this.profileModel.details?.associations[i]?.spear_profile?.provider_state} `
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_zip
                    ? this.profileModel.details?.associations[i]?.spear_profile
                      ?.provider_zip
                    : '');
                const bus_adrs =
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_street_address_1_practice
                    ? `<div>${this.profileModel.details?.associations[i]?.spear_profile?.provider_street_address_1_practice}</div>`
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_street_address_2_practice
                    ? `<div>${this.profileModel.details?.associations[i]?.spear_profile?.provider_street_address_2_practice}</div>`
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_city_practice
                    ? `<div>${this.profileModel.details?.associations[i]?.spear_profile?.provider_city_practice}</div>`
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_state_practice
                    ? `${this.profileModel.details?.associations[i]?.spear_profile?.provider_state_practice} `
                    : '') +
                  (this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_zip_practice
                    ? this.profileModel.details?.associations[i]?.spear_profile
                      ?.provider_zip_practice
                    : '');
                const adrs = bus_adrs ? bus_adrs : prov_adrs ? prov_adrs : '';
                const prov_nam =
                  this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_name_person;
                const bus_nam =
                  this.profileModel.details?.associations[i]?.spear_profile
                    ?.provider_name_business;
                const nam = bus_nam ? bus_nam : prov_nam ? prov_nam : '';
                const type =
                  this.profileModel.details?.associations[i]?.association_type;
                if (type === 'Other') {
                  this.associations_other.push({
                    name: nam,
                    address: adrs,
                    note: this.profileModel.details?.associations[i]?.note,
                    spear_id:
                      this.profileModel.details?.associations[i]?.spear_profile
                        ?.spear_id,
                        id: this.profileModel.details?.associations[i]?.spear_profile
                        ?.id
                  });
                }
              }
            }
          });
      });
    }
  }

  public closeDisassociate(): void {
    this.addmodalService.dismissAll();
  }

}

type LicensesObj = Array<{
  number: number;
  state: string;
  status: string;
  type: string;
}>;
