<div class="container-fluid">
    <div class="row no-gutter">
        <div class="col-md-12">
            <div class="login d-flex align-items-center py-5 full-height">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 mx-auto">
                            <div class="text-center mb-5">
                                <img src="assets/images/full-logo.png" alt="">
                            </div>
                            <div class="card flex-fill border-0 px-4 py-5 mb-0" *blockUI="'frm-resetPassword'">
                                <form name="form" (ngSubmit)="f.form.valid && resetPassword()" #f="ngForm" novalidate>
                                    <label class="loginLabel" for="password">New Password</label>
                                    <input name="password" type="password" #password="ngModel" class="form-control mb-3"
                                        [(ngModel)]="resetPasswordModel.password" required>
                                    <div class="alert alert-danger p-2" *ngIf="password.invalid && password.touched">
                                        Password is required</div>

                                    <label class="loginLabel" for="confirmPassword">Confirm Password</label>
                                    <input name="confirmPassword" type="password" ngValidateEqual="password"
                                        #confirmPassword="ngModel" class="form-control mb-3"
                                        [(ngModel)]="resetPasswordModel.confirmPassword" required>
                                    <div *ngIf="(confirmPassword.touched) && confirmPassword.invalid">
                                        <p class="alert alert-danger p-2"
                                            *ngIf="confirmPassword.hasError('notEqual') && password.valid">
                                            Passwords Don't Match
                                        </p>
                                    </div>
                                    <button type="submit" class="btn primary-bg text-white w-100 btnlogin mt-3"
                                        [disabled]="f.invalid">Submit</button>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>