export class ProfileReviewModel {
   isSelected: boolean;
   displayName: string;
   value: any;
   restorevalue: any;
   showrestorevalue: boolean;
   isDisabled: boolean;
   itemSelected: boolean;
   showField: boolean;
   enabledField: boolean;
   isBothVisible: boolean;
   isBothEnabled: boolean;

   constructor(isSelected: boolean, displayName: string, value: any) {
      this.isSelected = isSelected;
      this.displayName = displayName;
      this.value = value;
      this.restorevalue = value;
      this.isDisabled = false;
      this.showrestorevalue = false;
      this.itemSelected = false;
      this.showField = true;
      this.enabledField = true;
      this.isBothEnabled = false;
      this.isBothVisible = false;
   }
}