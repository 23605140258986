import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-export-all-csv',
  templateUrl: './export-all-csv.component.html',
  styleUrls: ['./export-all-csv.component.css']
})
export class ExportAllCsvComponent implements OnInit {

  ngOnInit(): void {
  }

}
