<div class="row">
  <div class="col-12">
    <div class="card">
      <div
        class="card-header spear-table-header-background d-flex justify-content-between"
      >
        <h6>Contacts</h6>
        <div>
          <button
            class="btn viewBtn spear-button-background text-white btn-sm"
            (click)="openPopup(createClient, 'xl')"
          >
            Add Contact fsfd
          </button>
        </div>
      </div>
      <div class="card-body" *blockUI="'client-list-ui'">
        <table class="table spear-table-header-background">
          <caption>
            Contacts
          </caption>
          <thead>
            <tr>
              <th class="border-0" scope="col">Name</th>
              <th class="border-0" scope="col">Title</th>
              <th class="border-0" scope="col">Client</th>
              <th class="border-0" scope="col">Email</th>
              <th class="border-0" scope="col">Office Phone</th>
              <th class="border-0" scope="col">Cell Phone</th>
              <th class="border-0" scope="col">Location Address</th>
            </tr>
          </thead>
          <!-- <tbody *ngIf="clients?.length">
            <ng-container *ngFor="let client of clients">
              <tr class="bg-white">
                <td>
                  <a
                    [routerLink]="['/client', client.id]"
                    target="_blank"
                    class="cursor-pointer primary-text font-weight-semibold"
                    >{{ client.client_name }}</a
                  >
                </td>
                <td>{{ client.address.location_name }}</td>
                <td>{{ client.contacts }}</td>
                <td>{{ client.affiliates }}</td>
                <td>{{ client.program_name }}</td>
                <td>{{ client.tpa }}</td>
                <td>{{ client.mga }}</td>
              </tr>
            </ng-container>
          </tbody> -->
          <tbody *ngIf="true">
            <tr class="bg-white">
              <td colspan="7" class="text-center">Data not available</td>
            </tr>
          </tbody>
        </table>
        <div
          class="px-0 py-0 paginationWrapper d-flex"
          *ngIf="!(!clients || clients.length === 0)"
        >
          <ngb-pagination
            [(collectionSize)]="clientTotal"
            [(page)]="clientPage"
            [pageSize]="clientLimit"
            [maxSize]="10"
            [boundaryLinks]="true"
            (pageChange)="onPageChangeclients($event)"
          >
          </ngb-pagination>
          <div class="selected d-flex align-items-center">
            <select
              (change)="onSizeChangeclients(clientLimit)"
              class="custom-select table-select h-100"
              [(ngModel)]="clientLimit"
            >
              <option [ngValue]="20">20</option>
              <option [ngValue]="50">50</option>
              <option [ngValue]="75">75</option>
              <option [ngValue]="100">100</option>
            </select>
            <p class="mb-0 ml-2 text-nowrap">
              Showing {{ clientPage * clientLimit - clientLimit + 1 }}
              -
              {{
                clientPage * clientLimit > clientTotal
                  ? clientTotal
                  : clientPage * clientLimit
              }}
              of {{ clientTotal }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #createClient let-modal>
  <div class="modal-header">
    <h6>Add Contact</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="addmodalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body create-client">
    <form [formGroup]="clientForm" (ngSubmit)="saveClientContact()">
      <div class="row">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500 required" for="address">Name</span>
            <div class="row mt-2">
              <div class="col-4">
                <label for="first_name" class="text-sm">First Name:</label>
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  class="form-control"
                  formControlName="first_name"
                />
              </div>

              <div class="col-4">
                <label for="middle_name" class="text-sm">Middle Name:</label>
                <input
                  type="text"
                  name="middle_name"
                  id="middle_name"
                  class="form-control"
                  formControlName="middle_name"
                />
              </div>

              <div class="col-4">
                <label for="last_name" class="text-sm">Last Name:</label>
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  class="form-control"
                  formControlName="last_name"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div>
            <label for="contact_title" class="text-sm">Title:</label>
            <input
              type="text"
              name="contact_title"
              id="contact_title"
              class="form-control"
              formControlName="title"
            />
          </div>
          <div>
            <div class="row">
              <div class="col-6">
                <label for="contact_role" class="text-sm">Role:</label>
                <select
                  type="text"
                  name="contact_role"
                  id="contact_role"
                  class="form-control"
                  formControlName="role"
                >
                  <option value="client user">Client User</option>
                </select>
              </div>

              <div class="col-6">
                <label for="contact_location" class="text-sm"
                  >Contact Location:</label
                >
                <select
                  type="text"
                  name="contact_location"
                  id="contact_location"
                  class="form-control"
                  formControlName="location"
                >
                  <!-- <ng-container *ngFor="let location of clientData?.locations">
                    <option [value]="location?.id ? location?.id : 1">
                      {{ location?.location_name }}
                    </option>
                  </ng-container> -->
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <span class="mr-2 text-sm">Access Level:</span>
                <input
                  type="radio"
                  id="contact_disabled"
                  name="access_level"
                  value="Disabled"
                  formControlName="access_level"
                />
                <label for="contact_disabled" class="mx-2 text-sm"
                  >Disabled</label
                >

                <input
                  type="radio"
                  id="limited_access"
                  name="access_level"
                  value="Limited Access"
                  formControlName="access_level"
                />
                <label for="limited_access" class="mx-2 text-sm"
                  >Limited Access</label
                >

                <input
                  type="radio"
                  id="contact_full_location_access"
                  name="access_level"
                  value="Full Location Access"
                  formControlName="access_level"
                />
                <label for="contact_full_location_access" class="mx-2 text-sm"
                  >Full Location Access</label
                >

                <input
                  type="radio"
                  id="contact_full_access"
                  name="access_level"
                  value="Full Access"
                  formControlName="access_level"
                />
                <label for="contact_full_access" class="mx-2 text-sm"
                  >Full Access</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mb-4">
            <label for="email" class="text-sm">Email:</label>
            <input
              type="text"
              name="email"
              id="email"
              class="form-control"
              formControlName="email"
            />
          </div>
          <hr />
          <div>
            <span class="text-dark fw-500" for="address">Address</span>
            <div class="row mt-2">
              <div class="col-6">
                <label for="location" class="text-sm">Location:</label>
                <input
                  type="text"
                  name="location"
                  id="location"
                  class="form-control"
                  formControlName="location"
                />
              </div>

              <div class="col-6">
                <label for="country" class="text-sm">Country:</label>
                <input
                  type="text"
                  name="country"
                  id="country"
                  class="form-control"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label for="address_1" class="text-sm">Address 1:</label>
                <input
                  type="text"
                  name="address_1"
                  id="address_1"
                  class="form-control"
                />
              </div>

              <div class="col-4">
                <label for="address_2" class="text-sm">Address 2:</label>
                <input
                  type="text"
                  name="address_2"
                  id="address_2"
                  class="form-control"
                />
              </div>

              <div class="col-4">
                <label for="address_3" class="text-sm">Address 3:</label>
                <input
                  type="text"
                  name="address_3"
                  id="address_3"
                  class="form-control"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label for="city" class="text-sm">City:</label>
                <input type="text" name="city" id="city" class="form-control" />
              </div>
              <div class="col-4">
                <label for="state" class="text-sm">State:</label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  class="form-control"
                />
              </div>
              <div class="col-4">
                <label for="zip" class="text-sm">Zip/Postal Code:</label>
                <input type="text" name="zip" id="zip" class="form-control" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500" for="address">Phone</span>
            <div class="row mt-2">
              <div class="col-4">
                <label for="office_phone" class="text-sm">Office Phone:</label>
                <input
                  type="text"
                  name="office_phone"
                  id="office_phone"
                  class="form-control"
                  formControlName="office_phone"
                />
              </div>

              <div class="col-4">
                <label for="mobile_phone" class="text-sm">Mobile Phone:</label>
                <input
                  type="text"
                  name="mobile_phone"
                  id="mobile_phone"
                  class="form-control"
                  formControlName="mobile_phone"
                />
              </div>

              <div class="col-4">
                <label for="home_phone" class="text-sm">Home Phone:</label>
                <input
                  type="text"
                  name="home_phone"
                  id="home_phone"
                  class="form-control"
                  formControlName="home_phone"
                />
              </div>

              <div class="col-4">
                <label for="fax" class="text-sm">Fax:</label>
                <input
                  type="text"
                  name="fax"
                  id="fax"
                  class="form-control"
                  formControlName="fax"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500" for="Information"
              >Other Information</span
            >
            <div class="row">
              <div class="col-3">
                <label for="birthday" class="text-sm">Birthday:</label>
                <input
                  type="text"
                  name="birthday"
                  id="birthday"
                  class="form-control"
                  formControlName="birthday"
                  jDate
                />
              </div>

              <div class="col-3">
                <label for="language" class="text-sm">Language:</label>
                <select
                  type="text"
                  name="language"
                  id="language"
                  class="form-control"
                  formControlName="language"
                >
                  <option value="english">English</option>
                </select>
              </div>

              <div class="col-3">
                <label for="account_code" class="text-sm">Account Code:</label>
                <input
                  type="text"
                  name="account_code"
                  id="account_code"
                  class="form-control"
                  formControlName="account_code"
                />
              </div>

              <div class="col-3">
                <label for="salesperson" class="text-sm">Salesperson:</label>
                <select
                  type="text"
                  name="salesperson"
                  id="salesperson"
                  class="form-control"
                  formControlName="salesperson"
                >
                  <option value="none">None</option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <label for="case_manager" class="text-sm">Case Manager:</label>
                <input
                  type="text"
                  name="case_manager"
                  id="case_manager"
                  class="form-control"
                  formControlName="case_manager"
                />
              </div>

              <div class="col-6">
                <label for="tags" class="text-sm">Tags:</label>
                <input
                  type="text"
                  name="tags"
                  id="tags"
                  class="form-control"
                  formControlName="tags"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500"> Notes</span>
            <div class="row mt-2">
              <div class="col-4">
                <label for="general_notes" class="text-sm"
                  >General Notes:</label
                >
                <textarea
                  type="text"
                  name="general_notes"
                  id="general_notes"
                  class="border-grey w-100"
                  formControlName="general_notes"
                ></textarea>
              </div>

              <div class="col-4">
                <label for="admin_policies" class="text-sm"
                  >Admin Policies:</label
                >
                <textarea
                  type="text"
                  name="admin_policies"
                  id="admin_policies"
                  class="border-grey w-100"
                  formControlName="admin_notes"
                ></textarea>
              </div>

              <div class="col-4">
                <label for="case_policies" class="text-sm"
                  >Case Policies:</label
                >
                <textarea
                  type="text"
                  name="case_policies"
                  id="case_policies"
                  class="border-grey w-100"
                  formControlName="case_policies"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500" for="address"> Custom Fields</span>
            <div class="row mt-2">
              <div class="col-3">
                <label for="general_notes" class="text-sm"
                  >Contact Address:</label
                >
                <select class="form-control">
                  <option value="united state">United State</option>
                </select>
              </div>

              <div class="col-3">
                <label for="Address" class="text-sm">Address1:</label>
                <input
                  type="text"
                  name="Address"
                  id="Address"
                  class="form-control"
                />
              </div>

              <div class="col-3">
                <label for="update_policies" class="text-sm">Address2:</label>
                <input
                  type="text"
                  name="Address2"
                  id="Address2"
                  class="form-control"
                />
              </div>

              <div class="col-3">
                <label for="Address3" class="text-sm">Address3:</label>
                <input
                  type="text"
                  name="address3"
                  id="address3"
                  class="form-control"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label for="City" class="text-sm">City:</label>
                <input type="text" name="city" id="city" class="form-control" />
              </div>
              <div class="col-4">
                <label for="state" class="text-sm">State:</label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  class="form-control"
                />
              </div>
              <div class="col-4">
                <label for="zipcode" class="text-sm">Zip Code:</label>
                <input
                  type="text"
                  name="zipcode"
                  id="zipcode"
                  class="form-control"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label for="contact" class="text-sm">Successive contact:</label>
                <input
                  type="text"
                  name="successive_contact"
                  id="successive_contact"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right mt-4">
        <button
          class="btn btn-secondary btn-sm text-white mr-1"
          (click)="modalService.dismissAll()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn spear-button-background btn-sm text-white"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</ng-template>
