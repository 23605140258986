import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private toastr: ToastrService, private router: Router) {}

    @BlockUI('manage-users-blockUI') blockUI: NgBlockUI;

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const token = this.authService.getToken();
        if (token && !request.url.includes(environment.fireStoreApiUrl)) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }
        if (request.url.includes(environment.fireStoreApiUrl)) {
            if(!request.url.includes("v1/auth/google")){
                request = this.addBasicAuthenticationHeader(request);
            }            
        }
        return next.handle(request).pipe(
            retry(1),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 400) {
                    if (error instanceof Array) {
                        error.forEach(item => {
                            this.toastr.error(item);
                        });
                    } else {
                        this.toastr.error(error.status.toString());
                    }
                    return throwError(error);
                }
                else if (error.status === 403) {
                    this.toastr.error('Unauthorized request');
                    this.router.navigate(['']);
                }
                else if (error.status === 404) {
                    // this.toastr.error('Some of the requested data not available.');
                    // this.blockUI.stop();
                    return throwError(error);
                }
                else {
                    this.toastr.error('Internal server error.');
                    this.blockUI.stop();
                    return throwError(error);                    
                }
            }));
    }
    private addBasicAuthenticationHeader(request: HttpRequest<any>): HttpRequest<any> {
        const token = this.authService.getToken();
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

    // private getEncryptedCredentials(): string {
    //     //return btoa(`${environment.apiCredentail.username}:${environment.apiCredentail.password}`);
    //     //return btoa(`${environment.token}`);
    // }
}
