import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Client } from '../models/client/client.interface';
import {
  AllocateUnAllocateSpearProfileReq,
  BillReviewAndPaymentResponse,
  CreateInvoiceReq,
  CreateNoteReq,
  CreateRes,
  CreateTimeLogReq,
  Diary,
  GetTimeSpentResponseDetail,
  Invoice,
  InvoicePaginationData,
  LimosDataResponse,
  LimosInvoiceData,
  LimosNoteFile,
  LimosReportCSVExportResponse,
  LimosReportDataResponse,
  PaginationData,
  SimilarClaimRes,
  TaskStatus,
  TimeSpentReq,
  UpdateLimosResponseDetails,
  UpdateLimosStatusReq,
} from '../models/limos/limos.models';
import { GetDownloadResponse } from '../models/provider/provider-detail-model';

@Injectable({
  providedIn: 'root',
})
export class LimosService {
  private _limosInvoiceData = new BehaviorSubject<LimosInvoiceData>(null);
  constructor(private http: HttpClient) {}

  getLimosReport(
    offSet: number,
    limit: number,
    export_to_csv: boolean
  ): Observable<
    LimosDataResponse<LimosReportDataResponse | LimosReportCSVExportResponse>
  > {
    return this.http.get<
      LimosDataResponse<LimosReportDataResponse | LimosReportCSVExportResponse>
    >(
      `https://spear-django-uq3cjixc7q-uw.a.run.app/api/v1/liens/get_limos_data_list/?limit=${limit}&offset=${offSet}&export_limos_data_to_csv=${export_to_csv}`
    );
  }

  checkLimosReportTaskStatus(
    taskId: string
  ): Observable<LimosDataResponse<TaskStatus>> {
    return this.http.get<LimosDataResponse<TaskStatus>>(
      `https://spear-django-uq3cjixc7q-uw.a.run.app/api/v1/liens/export/status/${taskId}`
    );
  }

  updateLimosStatus(
    id: string,
    claimNumber: string,
    reqBody: UpdateLimosStatusReq
  ): Observable<LimosDataResponse<UpdateLimosResponseDetails>> {
    return this.http.put<LimosDataResponse<UpdateLimosResponseDetails>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/editable/${id}/${claimNumber}`,
      reqBody
    );
  }

  get limosInvoiceData(): Observable<LimosInvoiceData> {
    const limos_invoice_data = localStorage.getItem('limos_invoice_data')
      ? localStorage.getItem('limos_invoice_data')
      : null;
    if (!this._limosInvoiceData.value && limos_invoice_data) {
      return of(JSON.parse(limos_invoice_data));
    }
    return this._limosInvoiceData.asObservable();
  }

  setlimosInvoiceData(value: LimosInvoiceData) {
    localStorage.removeItem('limos_invoice_data');
    localStorage.setItem('limos_invoice_data', JSON.stringify(value));
    this._limosInvoiceData.next(value);
  }

  getBillReviewAndPaymentData(
    claimNo: string,
    tin: string,
    limit: number,
    offSet: number
  ): Observable<LimosDataResponse<BillReviewAndPaymentResponse>> {
    return this.http.get<LimosDataResponse<BillReviewAndPaymentResponse>>(
      `https://spear-django-uq3cjixc7q-uw.a.run.app/api/v1/liens/payments-data/${claimNo}/${tin}/?limit=${limit}&offset=${offSet}`
    );
  }

  getSimilarClaims(
    tin: string,
    limit: number,
    offSet: number
  ): Observable<LimosDataResponse<SimilarClaimRes>> {
    return this.http.get<LimosDataResponse<SimilarClaimRes>>(
      `${environment.fireStoreApiUrl}/api/v2/liens/similar-claims/${tin}/?limit=${limit}&offset=${offSet}`
    );
  }

  getSimilarClaimTaskId(
    tin: string,
    limit: number,
    offSet: number
  ): Observable<LimosDataResponse<LimosReportCSVExportResponse>> {
    return this.http.get<LimosDataResponse<LimosReportCSVExportResponse>>(
      `${environment.fireStoreApiUrl}/api/v2/liens/similar-claims/${tin}/?limit=${limit}&offset=${offSet}&export_data_to_csv_file=true`
    );
  }

  saveTimeSpent(
    reqBody: TimeSpentReq
  ): Observable<LimosDataResponse<UpdateLimosResponseDetails>> {
    return this.http.post<LimosDataResponse<UpdateLimosResponseDetails>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/time-spent/`,
      reqBody
    );
  }

  updateTimeSpent(
    id: number,
    reqBody: TimeSpentReq
  ): Observable<LimosDataResponse<UpdateLimosResponseDetails>> {
    return this.http.patch<LimosDataResponse<UpdateLimosResponseDetails>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/time-spent/editable/${id}`,
      reqBody
    );
  }

  createInvoice(
    reqBody: CreateInvoiceReq
  ): Observable<LimosDataResponse<UpdateLimosResponseDetails>> {
    return this.http.post<LimosDataResponse<UpdateLimosResponseDetails>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/invoice/create/`,
      reqBody
    );
  }

  updateInvoice(
    id: number,
    reqBody: CreateInvoiceReq
  ): Observable<LimosDataResponse<UpdateLimosResponseDetails>> {
    return this.http.put<LimosDataResponse<UpdateLimosResponseDetails>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/invoice/update/${id}/`,
      reqBody
    );
  }

  getInvoiceList(
    limit: number,
    offSet: number,
    orderBy = 'id',
    orderDirection = 'asc',
    filterBy = ''
  ): Observable<LimosDataResponse<InvoicePaginationData>> {
    return this.http.get<LimosDataResponse<InvoicePaginationData>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/invoice/get-all/${
        filterBy ? filterBy + '/' : ''
      }?limit=${limit}&offset=${offSet}&order_by=${orderBy}&order_direction=${orderDirection}`
    );
  }

  getInvoiceDetails(id: string): Observable<Invoice> {
    return this.http
      .get<LimosDataResponse<Invoice>>(
        `${environment.fireStoreApiUrl}/api/v1/liens/invoice/get/${id}/`
      )
      .pipe(
        map((res: LimosDataResponse<Invoice>): Invoice => res.details ?? null)
      );
  }

  createNotepad(reqBody: FormData): Observable<LimosDataResponse<CreateRes>> {
    return this.http.post<LimosDataResponse<CreateRes>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/notes/create/`,
      reqBody
    );
  }

  getNotes(
    limit: number,
    offSet: number
  ): Observable<LimosDataResponse<PaginationData<CreateNoteReq>>> {
    return this.http.get<LimosDataResponse<PaginationData<CreateNoteReq>>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/notes/get-all/?limit=${limit}&offset=${offSet}`
    );
  }

  createDiary(reqBody: Diary): Observable<LimosDataResponse<CreateRes>> {
    return this.http.post<LimosDataResponse<CreateRes>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/diaries/create/`,
      reqBody
    );
  }

  getDiaries(
    limit: number,
    offSet: number,
    user?: number
  ): Observable<LimosDataResponse<PaginationData<Diary>>> {
    let url = `${environment.fireStoreApiUrl}/api/v1/liens/diaries/get-all/?limit=${limit}&offset=${offSet}`;
    if (user) {
      url = `${environment.fireStoreApiUrl}/api/v1/liens/diaries/get-all/?limit=${limit}&offset=${offSet}&user=${user}`;
    }
    return this.http.get<LimosDataResponse<PaginationData<Diary>>>(url);
  }

  createTimeLog(
    reqBody: CreateTimeLogReq
  ): Observable<LimosDataResponse<CreateRes>> {
    return this.http.post<LimosDataResponse<CreateRes>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/time-spent/`,
      reqBody
    );
  }

  getLimosFileDetails(noteIds: number[]): Observable<LimosNoteFile[]> {
    const limos_notes_id_list = noteIds.join(',');
    return this.http
      .get<LimosDataResponse<LimosNoteFile[]>>(
        `${environment.fireStoreApiUrl}/api/v1/file/limos/notes/all/?limos_notes_id_list=${limos_notes_id_list}`
      )
      .pipe(
        map(
          (res: LimosDataResponse<LimosNoteFile[]>): LimosNoteFile[] =>
            res.details ?? []
        )
      );
  }

  downloadFile(id: number) {
    return this.http.get<GetDownloadResponse>(
      `${environment.fireStoreApiUrl}/api/v1/file/download/${id}`
    );
  }

  searchClient(text: string, limit = 20, offSet = 1): Observable<Client[]> {
    return this.http
      .get<LimosDataResponse<PaginationData<Client>>>(
        `${environment.fireStoreApiUrl}/api/v1/client/search/?q=${text}&limit=${limit}&offset=${offSet}`
      )
      .pipe(map((res) => (res?.details?.hits ? res.details.hits : [])));
  }

  updateNote(
    id: number,
    reqBody: CreateNoteReq
  ): Observable<LimosDataResponse<CreateRes>> {
    return this.http.put<LimosDataResponse<CreateRes>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/notes/update/${id}/`,
      reqBody
    );
  }

  deleteNote(id: number): Observable<LimosDataResponse<CreateRes>> {
    return this.http.delete<LimosDataResponse<CreateRes>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/notes/delete/${id}/`
    );
  }

  updateDiary(
    id: number,
    reqBody: Diary
  ): Observable<LimosDataResponse<CreateRes>> {
    return this.http.put<LimosDataResponse<CreateRes>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/diaries/update/${id}/`,
      reqBody
    );
  }

  deleteDiary(id: number): Observable<LimosDataResponse<CreateRes>> {
    return this.http.delete<LimosDataResponse<CreateRes>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/diaries/delete/${id}/`
    );
  }

  allocateUnallocateSpearProfile(
    reqBody: AllocateUnAllocateSpearProfileReq
  ): Observable<LimosDataResponse<CreateRes>> {
    return this.http.post<LimosDataResponse<CreateRes>>(
      `${environment.fireStoreApiUrl}/api/v2/profiles/allocation/`,
      reqBody
    );
  }

  geTimeSpentLogs(
    limit: number,
    offSet: number,
    isInvoice = false
  ): Observable<LimosDataResponse<PaginationData<GetTimeSpentResponseDetail>>> {
    let url = `${environment.fireStoreApiUrl}/api/v1/liens/time-spent/all/?limit=${limit}&offset=${offSet}`;
    if (isInvoice) {
      url = `${environment.fireStoreApiUrl}/api/v1/liens/time-spent/is-invoiced/all/?limit=${limit}&offset=${offSet}`;
    }
    return this.http.get<
      LimosDataResponse<PaginationData<GetTimeSpentResponseDetail>>
    >(url);
  }
}
