import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { ProfileReviewModel } from 'src/app/models/merge/merge.match.review.model';
import {
  CaseDetails,
  ClaimDetails,
  CourtDetails,
  DwcDetails,
  EmployerDetails,
  InjuryDetails,
  InsuranceDetails,
  LawFirmsInformation,
  LicenseSuspensionDetails,
  LienDetails,
  MedicalLicenseDetails,
  MergeRequestModel,
  ProfileLookups,
  ProviderBusinessDetails,
  ProviderContactDetails,
  ProviderDetails,
  ProviderNpiDetails,
  SuspallProviderNumbers,
  UncategorizedDetails,
  WcabDetails
} from 'src/app/models/merge/merge.request.model';
import { MergeProfile } from 'src/app/models/provider/provider.profile.model';
import { ModalService } from 'src/app/services/modal.service';
import { ProviderService } from 'src/app/services/provider.service';
import { AppUtils } from 'src/app/utilities/app.utils';
import { SelectallconfirmationComponent } from './selectallconfirmation/selectallconfirmation.component';

@Component({
  selector: 'app-smart-merge-matches',
  templateUrl: './smart-merge-matches.component.html',
  styleUrls: ['./smart-merge-matches.component.css'],
})
export class SmartMergeMatchesComponent implements OnInit {
  @ViewChild('firstProfileBlock', { static: false, read: ElementRef }) firstProfileTemplateVar: ElementRef;
  @ViewChild('secondProfileBlock', { static: false, read: ElementRef }) secondProfileTemplateVar: ElementRef;

  constructor(
    private route: Router,
    private providerService: ProviderService,
    private toastr: ToastrService,
    private appUtils: AppUtils,
    private ngbModal: NgbModal
  ) {
    this.isMasterSelFirst = false;
    this.isMasterSelSecond = false;
  }

  @BlockUI('provider-screen') blockUI: NgBlockUI;
  disable = true;
  disable1 = true;
  mergeIds = [];

  initialFirstProfile: ProfileReviewModel[] = [];
  initialSecondProfile: ProfileReviewModel[] = [];

  firstProfile: ProfileReviewModel[] = [];
  secondProfile: ProfileReviewModel[] = [];
  finalMergedProfile: any;

  firstProfileName: string;
  secondProfileName: string;

  firstProfileID: string;
  secondProfileID: string;

  firstProfileChecked: any;
  secondProfileChecked: any;
  isMasterSelSecond: boolean;
  isMasterSelFirst: boolean;

  chk1 = false;

  @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
    const result = confirm('Changes you made may not be saved.');
    if (result) { }
    event.returnValue = false; // stay on same page
  }

  ngOnInit(): void {
    this.getProfilesData();
  }

  private getProfilesData(): void {
    const mergeIds = this.providerService.getMergeIds();
    this.mergeIds = mergeIds;
    if (!mergeIds || mergeIds.length === 0) {
      this.appUtils.ProcessCustomResponse(
        this.toastr,
        true,
        'Please select providers for merge'
      );
      this.route.navigate(['/providers']);
      return;
    }
    this.blockUI.start();
    forkJoin([
      this.providerService.getProviderProfileForMerge(mergeIds[0]),
      this.providerService.getProviderProfileForMerge(mergeIds[1]),
    ]).subscribe(
      ([first, second]) => {
        this.firstProfileID = mergeIds[0];
        this.secondProfileID = mergeIds[1];
        this.firstProfileName = (first?.details?.provider_name_person ? first?.details?.provider_name_person : first?.details?.provider_name_business ? first?.details?.provider_name_business : null);
        this.secondProfileName = (second?.details?.provider_name_person ? second?.details?.provider_name_person : second?.details?.provider_name_business ? second?.details?.provider_name_business : null);
        // if (first?.details?.provider_name_person == null || first?.details?.provider_name_person == '') {
        //   this.firstProfileName = this.firstProfileID;
        // }
        // else {
        //   this.firstProfileName = `${first?.details?.provider_name_person}`;
        // }

        // if (second?.details?.provider_name_person == null || second?.details?.provider_name_person == '') {
        //   this.secondProfileName = this.secondProfileID
        // }
        // else {
        //   this.secondProfileName = `${second?.details?.provider_name_person}`;
        // }

        this.firstProfile = this.getDisplayRecords(first);
        this.secondProfile = this.getDisplayRecords(second);

        this.initialFirstProfile = JSON.parse(
          JSON.stringify(this.firstProfile)
        );
        this.initialSecondProfile = JSON.parse(
          JSON.stringify(this.secondProfile)
        );
        this.blockUI.stop();

        this.firstProfile.forEach(z => {

          if (!z.value || (typeof z.value === 'object' && (Object.keys(z.value).length === 0 || Object.keys(z.value).every(key => z.value[key] == '')))) {
            const secondProfile = this.secondProfile.find(
              (x) => x.displayName === z.displayName
            );
            if (!secondProfile.value || (typeof secondProfile.value === 'object' && (Object.keys(secondProfile.value).length === 0 || Object.keys(secondProfile.value).every(key => secondProfile.value[key] == '')))) {
              z.showField = false;
              secondProfile.showField = false;
            }
            else{
              if(z.displayName === '')
                z.isSelected = false;
                z.isDisabled = true;
                secondProfile.itemSelected = true;
                secondProfile.isDisabled = true;
                z.isBothEnabled = false;
            }
          }
          else {
            const secondProfile = this.secondProfile.find(
              (x) => x.displayName === z.displayName
            );
            if (z.value !== secondProfile.value) {
              z.isBothEnabled = true;
              if (!secondProfile.value || secondProfile.value == '-') {
                z.itemSelected = true;
                z.isDisabled = true;
                secondProfile.isSelected = false;
                secondProfile.isDisabled = true;
                z.isBothEnabled = false;
              }
              // else if (!z.value || z.value == '-') {
              //   z.isSelected = false;
              //   z.isDisabled = true;
              //   secondProfile.itemSelected = true;
              //   secondProfile.isDisabled = true;
              //   z.isBothEnabled = false;
              // }
            }
            else {
              //(z.displayName !== 'provider_street_address_1' || z.displayName !== 'provider_street_address_2' || z.displayName !== 'provider_city' || z.displayName !== 'provider_city' ||)
              z.isDisabled = true;
              z.itemSelected = true;
              secondProfile.isSelected = false;
              secondProfile.isDisabled = true;
              z.isBothEnabled = false;
            }
          }
          if(z.displayName == 'business_contact' || z.displayName == 'provider_npi_number' || z.displayName == 'provider_phone_number_person' || z.displayName == 'title_or_position' || z.displayName == 'provider_address' || z.displayName == 'practice_address' || z.displayName == 'medical_licence'){
            z.isBothVisible = true;
          }
        });
      },
      (error) => {
        this.appUtils.ProcessErrorResponse(this.toastr, error);
        this.blockUI.stop();
      }
    );
    this.addressBloc();
    this.stateBloc();
  }

  showme(eventx: HTMLInputElement): void {
    console.log(`checkbox is ${eventx.value}`);
  }

  oho($event): void {
    console.log($event);
  }

  resetFirstProfile(): void {
    const displayNamesToBeDisabled = [
      ...this.firstProfile
        .filter((x) => x.isDisabled)
        .map((x) => x.displayName),
    ];
    this.firstProfile = JSON.parse(JSON.stringify(this.initialFirstProfile));

    this.firstProfile
      .filter((x) => displayNamesToBeDisabled.includes(x.displayName))
      .forEach((x) => (x.isDisabled = true));

    this.secondProfile
      .filter((x) => x.isDisabled)
      .forEach((x) => (x.isDisabled = false));
  }
  resetSecondProfile(): void {
    const displayNamesToBeDisabled = [
      ...this.secondProfile
        .filter((x) => x.isDisabled)
        .map((x) => x.displayName),
    ];
    this.secondProfile = JSON.parse(JSON.stringify(this.initialSecondProfile));
    this.secondProfile
      .filter((x) => displayNamesToBeDisabled.includes(x.displayName))
      .forEach((x) => (x.isDisabled = true));

    this.firstProfile
      .filter((x) => x.isDisabled)
      .forEach((x) => (x.isDisabled = false));
  }

  onSelectionChange(profileClicked: string, displayName: string, event): void {
    let firstProfile;
    let secondProfile;
    if (profileClicked == 'firstProfile') {
      firstProfile = this.firstProfile.find(
        (x) => x.displayName === displayName
      );
      secondProfile = this.secondProfile.find(
        (x) => x.displayName === displayName
      );
    }

    if (profileClicked == 'secondProfile') {
      secondProfile = this.secondProfile.find(
        (x) => x.displayName === displayName
      );
      firstProfile = this.firstProfile.find(
        (x) => x.displayName === displayName
      );
    }

    if (profileClicked == 'firstProfile' && event.target.checked == true) {
      firstProfile.itemSelected = true;
      secondProfile.isDisabled = true;
      displayName == "provider_address" ? this.isSelectedProviderAddress('first', true) : null;
      displayName == "practice_address" ? this.isSelectedPracticeAddress('first', true) : null;
      displayName == "medical_licence" ? this.isSelectedLicence('first', true) : null;
    }
    else if (profileClicked == 'firstProfile' && event.target.checked == false) {
      firstProfile.itemSelected = false;
      secondProfile.isDisabled = false;
      displayName == "provider_address" ? this.isSelectedProviderAddress('first', false) : null;
      displayName == "practice_address" ? this.isSelectedPracticeAddress('first', false) : null;
      displayName == "medical_licence" ? this.isSelectedLicence('first', false) : null;
    }

    if (profileClicked == 'secondProfile' && event.target.checked == true) {
      secondProfile.itemSelected = true;
      firstProfile.isDisabled = true;
      displayName == "provider_address" ? this.isSelectedProviderAddress('second', true) : null;
      displayName == "practice_address" ? this.isSelectedPracticeAddress('second', true) : null;
      displayName == "medical_licence" ? this.isSelectedLicence('second', true) : null;
    }
    else if (profileClicked == 'secondProfile' && event.target.checked == false) {
      secondProfile.itemSelected = false;
      firstProfile.isDisabled = false;
      displayName == "provider_address" ? this.isSelectedProviderAddress('second', false) : null;
      displayName == "practice_address" ? this.isSelectedPracticeAddress('second', false) : null;
      displayName == "medical_licence" ? this.isSelectedLicence('second', false) : null;
    }
  }

  isSelectedProviderAddress(profile_: any, select: boolean) {
    let selectedProfile;
    if (profile_ === 'first') {
      selectedProfile = this.firstProfile;
    }
    else if (profile_ === 'second') {
      selectedProfile = this.secondProfile;
    }
    let provider_street_address_1 = selectedProfile.find(
      (x) => x.displayName === 'provider_street_address_1'
    );
    provider_street_address_1.isSelected = select;
    let provider_street_address_2 = selectedProfile.find(
      (x) => x.displayName === 'provider_street_address_2'
    );
    provider_street_address_2.isSelected = select;
    let provider_city = selectedProfile.find(
      (x) => x.displayName === 'provider_city'
    );
    provider_city.isSelected = select;
    let provider_state = selectedProfile.find(
      (x) => x.displayName === 'provider_state'
    );
    provider_state.isSelected = select;
    let provider_zip = selectedProfile.find(
      (x) => x.displayName === 'provider_zip'
    );
    provider_zip.isSelected = select;
    let provider_phone = selectedProfile.find(
      (x) => x.displayName === 'provider_phone'
    );
    provider_phone.isSelected = select;
    let provider_fax = selectedProfile.find(
      (x) => x.displayName === 'provider_fax'
    );
    provider_fax.isSelected = select;
  }

  isSelectedPracticeAddress(profile_: any, select: boolean) {
    let selectedProfile;
    if (profile_ == 'first') {
      selectedProfile = this.firstProfile;
    }
    else if (profile_ == 'second') {
      selectedProfile = this.secondProfile;
    }
    let provider_street_address_1_practice = selectedProfile.find(
      (x) => x.displayName === 'provider_street_address_1_practice'
    );
    provider_street_address_1_practice.isSelected = select;
    let provider_street_address_2_practice = selectedProfile.find(
      (x) => x.displayName === 'provider_street_address_2_practice'
    );
    provider_street_address_2_practice.isSelected = select;
    let provider_city_practice = selectedProfile.find(
      (x) => x.displayName === 'provider_city_practice'
    );
    provider_city_practice.isSelected = select;
    let provider_state_practice = selectedProfile.find(
      (x) => x.displayName === 'provider_state_practice'
    );
    provider_state_practice.isSelected = select;
    let provider_zip_practice = selectedProfile.find(
      (x) => x.displayName === 'provider_zip_practice'
    );
    provider_zip_practice.isSelected = select;
    let provider_phone_practice = selectedProfile.find(
      (x) => x.displayName === 'provider_phone_practice'
    );
    provider_phone_practice.isSelected = select;
    let provider_fax_practice = selectedProfile.find(
      (x) => x.displayName === 'provider_fax_practice'
    );
    provider_fax_practice.isSelected = select;
  }

  isSelectedLicence(profile_: any, select: boolean) {
    let selectedProfile;
    if (profile_ === 'first') {
      selectedProfile = this.firstProfile;
    }
    else if (profile_ === 'second') {
      selectedProfile = this.secondProfile;
    }
    let medical_licence_numbers = selectedProfile.find(
      (x) => x.displayName === 'medical_licence_numbers'
    );
    medical_licence_numbers.isSelected = select;
    let medical_licence_states = selectedProfile.find(
      (x) => x.displayName === 'medical_licence_states'
    );
    medical_licence_states.isSelected = select;
  }

  private getDisplayRecords(model: MergeProfile) {

    let filteredModel: MergeProfile = {
      details: {
        entity_type_code: 0,
        is_merged: false,
        case_status: '',
        case_number: '',
        employer: '',
        insurance_company: '',
        injured_worker_full_name: '',
        injured_worker_first_name: '',
        injured_worker_middle_name_1: '',
        injured_worker_middle_name_2: '',
        injured_worker_last_name: '',
        injured_worker_dob: '',
        injured_worker_doi: '',
        law_firms: [],
        court_case_name: '',
        court_case_number: '',
        wcab_next_hearing_date: '',
        wcab_next_hearing_location: '',
        wcab_next_hearing_type: '',
        wcab_judge: '',
        claim_administrator_1: '',
        claim_administrator_2: '',
        dwc_office: '',
        dwc_proceeding_status: '',
        lien_amount: 0,
        lien_paid: '',
        lien_paid_date: '',
        lien_status_updated: '',
        lien_most_recent_event: '',
        lien_most_recent_event_description: '',
        lien_disposition: '',
        lien_disposition_date: '',
        lien_created: '',
        lien_updated: '',
        suspended_date: '',
        suspension_duration: '',
        medical_licence_numbers: undefined,
        medical_licence_states: undefined,
        license_status: '',
        provider_name_entity: '',
        category_code: '',
        provider_name_person: '',
        provider_alias_person: '',
        title_or_position: '',
        official_first_name: '',
        official_middle_name: '',
        official_last_name: '',
        provider_phone_number_person: '',
        gender: '',
        suspall_provider_number_1: '',
        suspall_provider_number_2: '',
        suspall_provider_number_3: '',
        suspall_provider_number_4: '',
        suspall_provider_number_5: '',
        suspall_provider_number_6: '',
        suspall_provider_number_7: '',
        suspall_provider_number_8: '',
        suspall_provider_number_9: '',
        suspall_provider_number_10: '',
        suspall_provider_number_11: '',
        provider_entity_address_street: '',
        provider_entity_address_city: '',
        provider_entity_address_state: '',
        provider_entity_address_zip: '',
        provider_street_address_1: '',
        provider_street_address_2: '',
        provider_city: '',
        provider_state: '',
        provider_zip: '',
        provider_phone: '',
        provider_fax: '',
        provider_street_address_1_practice: '',
        provider_street_address_2_practice: '',
        provider_city_practice: '',
        provider_state_practice: '',
        provider_zip_practice: '',
        provider_phone_practice: '',
        provider_fax_practice: '',
        provider_npi_number: '',
        npi_first_listed_date: '',
        npi_active: false,
        npi_deactivation_date: '',
        npi_reactivation_date: '',
        business_owner: '',
        provider_name_business: '',
        provider_alias_business: '',
        provider_category: '',
        business_contact: '',
        consolidation: '',
        po_code: 0,
        pay_code_status: '',
        authority: '',
        pay_code_rule: '',
        state_code: '',
        state_bill_objection_language: '',
        narrative_reference: '',
        lastname: '',
        middlename: '',
        firstname: '',
        provider_address: '',
        practice_address: '',
        medical_licence: []
      }
    };

    for (const key of Object.keys(filteredModel.details)) {
      if (model.details.hasOwnProperty(key) && model.details[key] === filteredModel.details[key]) {
        filteredModel.details[key] = model.details[key];
      }
    }

    let arrDisplayFields: ProfileReviewModel[] = [];
    const modelKeys = Object.keys(filteredModel.details);
    modelKeys.sort().forEach(mkey => {
      let val = model.details[mkey];
      if (mkey == 'entity_type_code') {
        if (model.details[mkey] == 0) {
          val = "Record";
        }
        else if (model.details[mkey] == 1) {
          val = "Person";
        }
        else if (model.details[mkey] == 2) {
          val = "Business";
        }
      }
      if (mkey == 'npi_active') {
        if (model.details[mkey] == true) {
          val = "Active";
        }
        else if (model.details[mkey] == false) {
          val = "Inactive";
        }
      }
      if (mkey == 'provider_phone_number_person') {
        if (model.details[mkey]) {
          const cleanedNumber = model.details[mkey].toString().replace(/\D/g, '');
          val = `(${cleanedNumber.substr(0, 3)}) ${cleanedNumber.substr(3, 3)}-${cleanedNumber.substr(6)}`;
        }
      }
      if (mkey == 'firstname') {
        if (model.details['provider_name_person'] == null || model.details['provider_name_person'] == '') {
          val = '';
        }
        else {
          const name = model.details['provider_name_person'].split(' ');
          val = name[0];
        }
      }
      if (mkey == 'middlename') {
        if (model.details['provider_name_person'] == null || model.details['provider_name_person'] == '') {
          val = '';
        }
        else {
          const name = model.details['provider_name_person'].split(' ');
          if (name.length === 2) {
            val = '';
          }
          if (name.length >= 3) {
            val = name[1];
          }
        }
      }
      if (mkey == 'lastname') {
        if (model.details['provider_name_person'] == null || model.details['provider_name_person'] == '') {
          val = '';
        }
        else {
          const name = model.details['provider_name_person'].split(' ');
          if (name.length === 2) {
            val = name[1];
          }
          if (name.length >= 3) {
            const lastname = [];
            name.forEach((value, index) => {
              if (index >= 2) {
                lastname.push(value);
              }
            });
            val = lastname.join(' ');
          }
        }
      }
      if (mkey == 'provider_address') {
        let prov_tel, prov_fax, init_prov_tel, init_prov_fax;
        if (model.details['provider_phone']) {
          init_prov_tel = model.details['provider_phone'].toString().replace(/\D/g, '');
          prov_tel = `(${init_prov_tel.substr(0, 3)}) ${init_prov_tel.substr(3, 3)}-${init_prov_tel.substr(6)}`;
        }
        if (model.details['provider_fax']) {
          init_prov_fax = model.details['provider_fax'].toString().replace(/\D/g, '');
          prov_fax = `(${init_prov_fax.substr(0, 3)}) ${init_prov_fax.substr(3, 3)}-${init_prov_fax.substr(6)}`;
        }

        val = (model.details['provider_street_address_1'] ? `${model.details['provider_street_address_1']}<br>` : '') + (model.details['provider_street_address_2'] ? `${model.details['provider_street_address_2']}<br>` : '') + (model.details['provider_city'] ? `${model.details['provider_city']}<br>` : '') + (model.details['provider_state'] ? `${model.details['provider_state']}<br>` : '') + (model.details['provider_zip'] ? `${model.details['provider_zip']}<br>` : '') + (model.details['provider_phone'] ? `P: ${prov_tel}<br>` : '') + (model.details['provider_fax'] ? `F: ${prov_fax}` : '');
      }
      if (mkey == 'practice_address') {
        let prac_tel, prac_fax, init_prac_tel, init_prac_fax;
        if (model.details['provider_phone_practice']) {
          init_prac_tel = model.details['provider_phone_practice'].toString().replace(/\D/g, '');
          prac_tel = `(${init_prac_tel.substr(0, 3)}) ${init_prac_tel.substr(3, 3)}-${init_prac_tel.substr(6)}`;
        }
        if (model.details['provider_fax_practice']) {
          init_prac_fax = model.details['provider_fax_practice'].toString().replace(/\D/g, '');
          prac_fax = `(${init_prac_fax.substr(0, 3)}) ${init_prac_fax.substr(3, 3)}-${init_prac_fax.substr(6)}`;
        }

        val = (model.details['provider_street_address_1_practice'] ? `${model.details['provider_street_address_1_practice']}<br>` : '') + (model.details['provider_street_address_2_practice'] ? `${model.details['provider_street_address_2_practice']}<br>` : '') + (model.details['provider_street_address_2'] ? `${model.details['provider_street_address_2']}<br>` : '') + (model.details['provider_city_practice'] ? `${model.details['provider_city_practice']}<br>` : '') + (model.details['provider_state_practice'] ? `${model.details['provider_state_practice']}<br>` : '') + (model.details['provider_zip_practice'] ? `${model.details['provider_zip_practice']}<br>` : '') + (model.details['provider_phone_practice'] ? `P: ${prac_tel}<br>` : '') + (model.details['provider_fax_practice'] ? `F: ${prac_fax}` : '');
      }
      if (mkey == 'medical_licence') {
        let numbers: any;
        let states: any;
        if (Object.keys(model.details['medical_licence_numbers']).length > 0) {
          numbers = Object.values(model.details['medical_licence_numbers']);
        }
        else{
          numbers = [];
        }
        if (Object.keys(model.details['medical_licence_states']).length > 0) {
          states = Object.values(model.details['medical_licence_states']);
        }
        else{
          states = [];
        }
        if (states.length > 0) {
          let content = '';
          for (let i = 0; i < states.length; i++) {
            content += `<p>${states[i]}: ${numbers[i]}</p>`;
          }
          val = content;
        }
        else{
          val = '-'
        }

      }

      arrDisplayFields.push(new ProfileReviewModel(
        false,
        mkey,
        val
      ))
    });
    return arrDisplayFields;
  }

  onMerge(): void {
    let mergedProfileFirst = this.firstProfile.filter((x) => x.isSelected);
    let mergedProfileSecond = this.secondProfile.filter((x) => x.isSelected);
    this.finalMergedProfile = [...mergedProfileFirst, ...mergedProfileSecond];
    this.blockUI.start();
    const mergeIds = this.providerService.getMergeIds();
    const model = new MergeRequestModel();
    let case_details: CaseDetails = new CaseDetails();
    let employer_details: EmployerDetails = new EmployerDetails();
    let insurance_details: InsuranceDetails = new InsuranceDetails();
    let law_firms_information: LawFirmsInformation = new LawFirmsInformation();
    let court_details: CourtDetails = new CourtDetails();
    let wcab_details: WcabDetails = new WcabDetails();
    let claim_details: ClaimDetails = new ClaimDetails();
    let dwc_details: DwcDetails = new DwcDetails();
    let lien_details: LienDetails = new LienDetails();
    let license_suspension_details: LicenseSuspensionDetails = new LicenseSuspensionDetails();
    let medical_license_details: MedicalLicenseDetails = new MedicalLicenseDetails();
    let provider_details: ProviderDetails = new ProviderDetails();
    let uncategorized_details: UncategorizedDetails = new UncategorizedDetails();
    let profile_lookups: ProfileLookups = new ProfileLookups();
    let injury_details: InjuryDetails = new InjuryDetails();
    let suspall_provider_numbers: SuspallProviderNumbers = new SuspallProviderNumbers();
    let provider_contact_details: ProviderContactDetails = new ProviderContactDetails();
    let provider_npi_details: ProviderNpiDetails = new ProviderNpiDetails();
    let provider_business_details: ProviderBusinessDetails = new ProviderBusinessDetails();

    // setting up the merge data       

    Object.keys(case_details).forEach(mkey => {
      case_details[mkey] = this.finalMergedProfile.find((x) => x.displayName === mkey)?.value ?? null;
    });

    Object.keys(employer_details).forEach(mkey => {
      employer_details[mkey] = this.finalMergedProfile.find((x) => x.displayName === mkey)?.value ?? null;
    });

    Object.keys(insurance_details).forEach(mkey => {
      insurance_details[mkey] = this.finalMergedProfile.find((x) => x.displayName === mkey)?.value ?? null;
    });

    Object.keys(injury_details).forEach(mkey => {
      injury_details[mkey] = this.finalMergedProfile.find((x) => x.displayName === mkey)?.value ?? null;
    });

    Object.keys(claim_details).forEach(mkey => {
      claim_details[mkey] = this.finalMergedProfile.find((x) => x.displayName === mkey)?.value ?? null;
    });

    Object.keys(court_details).forEach(mkey => {
      court_details[mkey] = this.finalMergedProfile.find((x) => x.displayName === mkey)?.value ?? null;
    });

    Object.keys(dwc_details).forEach(mkey => {
      dwc_details[mkey] = this.finalMergedProfile.find((x) => x.displayName === mkey)?.value ?? null;
    });

    Object.keys(law_firms_information).forEach(mkey => {
      law_firms_information[mkey] = this.finalMergedProfile.find((x) => x.displayName === mkey)?.value ?? null;
    });

    Object.keys(lien_details).forEach(mkey => {
      lien_details[mkey] = this.finalMergedProfile.find((x) => x.displayName === mkey)?.value ?? null;
    });

    Object.keys(wcab_details).forEach(mkey => {
      wcab_details[mkey] = this.finalMergedProfile.find((x) => x.displayName === mkey)?.value ?? null;
    });

    Object.keys(license_suspension_details).forEach(mkey => {
      license_suspension_details[mkey] = this.finalMergedProfile.find((x) => x.displayName === mkey)?.value ?? null;
    });

    Object.keys(medical_license_details).forEach(mkey => {
      medical_license_details[mkey] = this.finalMergedProfile.find((x) => x.displayName === mkey)?.value ?? null;
    });

    Object.keys(provider_details).forEach(mkey => {
      provider_details[mkey] = this.finalMergedProfile.find((x) => x.displayName === mkey)?.value ?? null;
    });

    Object.keys(suspall_provider_numbers).forEach(mkey => {
      suspall_provider_numbers[mkey] = this.finalMergedProfile.find((x) => x.displayName === mkey)?.value ?? null;
    });

    Object.keys(provider_contact_details).forEach(mkey => {
      provider_contact_details[mkey] = this.finalMergedProfile.find((x) => x.displayName === mkey)?.value ?? null;
    });

    Object.keys(provider_npi_details).forEach(mkey => {
      provider_npi_details[mkey] = this.finalMergedProfile.find((x) => x.displayName === mkey)?.value ?? null;
    });

    Object.keys(provider_business_details).forEach(mkey => {
      provider_business_details[mkey] = this.finalMergedProfile.find((x) => x.displayName === mkey)?.value ?? null;
    });

    Object.keys(uncategorized_details).forEach(mkey => {
      uncategorized_details[mkey] = this.finalMergedProfile.find((x) => x.displayName === mkey)?.value ?? null;
    });

    Object.keys(profile_lookups).forEach(mkey => {
      profile_lookups[mkey] = this.finalMergedProfile.find((x) => x.displayName === mkey)?.value ?? null;
    });

    provider_details.provider_name_person =

      (this.finalMergedProfile.find((x) => x.displayName === 'firstname')?.value ?? '' + ' ')
        .concat((this.finalMergedProfile.find((x) => x.displayName === 'middlename')?.value ?? '' + ' ')
          .concat(this.finalMergedProfile.find((x) => x.displayName === 'lastname')?.value ?? ''));

    model.first_id = mergeIds[0];
    model.second_id = mergeIds[1];
    model.entity_type_code = 0;

    const entyType = this.finalMergedProfile.find((x) => x.displayName === 'entity_type_code');
    if (entyType && entyType.value >= 0) {
      model.entity_type_code = +entyType.value;
    }

    model.data_source = this.finalMergedProfile.find((x) => x.displayName === 'data_source')?.value ?? '';
    model.date_record_modified_at_source = this.finalMergedProfile.find((x) => x.displayName === 'date_record_modified_at_source')?.value ?? null;
    model.date_record_created_at_source = this.finalMergedProfile.find((x) => x.displayName === 'date_record_created_at_source')?.value ?? null;
    model.row_hash = this.finalMergedProfile.find((x) => x.displayName === 'row_hash')?.value ?? '';
    model.is_edited = this.finalMergedProfile.find((x) => x.displayName === 'is_edited')?.value ?? '';
    model.case_details = case_details;
    model.employer_details = employer_details;
    model.insurance_details = insurance_details;
    model.insurance_details.injury_details = injury_details;
    model.insurance_details.claim_details = claim_details;
    model.insurance_details.court_details = court_details;
    model.insurance_details.dwc_details = dwc_details;
    model.insurance_details.law_firms_information = law_firms_information;
    model.insurance_details.lien_details = lien_details;
    model.insurance_details.wcab_details = wcab_details;
    model.license_suspension_details = license_suspension_details;
    model.medical_license_details = medical_license_details;
    model.provider_details = provider_details;
    model.provider_details.suspall_provider_numbers = suspall_provider_numbers;
    model.provider_details.provider_contact_details = provider_contact_details;
    model.provider_details.provider_npi_details = provider_npi_details;
    model.provider_details.provider_business_details = provider_business_details;
    model.uncategorized_details = uncategorized_details;
    model.insurance_details.injury_details = injury_details;
    model.profile_lookups = profile_lookups;

    this.providerService.mergeProfile(model).subscribe(
      (res) => {
        this.toastr.success('Profiles have been successfully merged');
        this.route.navigate(['/provider-detail', res.details.spear_id]);
        this.blockUI.stop();
      },
      (error) => {
        this.appUtils.ProcessErrorResponse(this.toastr, error);
        this.blockUI.stop();
      }
    );
  }


  transform(value: string, strToReplace: string, replacementStr: string): string {
    if (!value || !strToReplace || !replacementStr) {
      return value;
    }
    return value.replace(new RegExp(strToReplace, 'g'), replacementStr);
  }

  addressBloc() {
    setTimeout(() => {
      const div1 = document.getElementById("prov_addrs_bloc_1");
      const div2 = document.getElementById("prov_addrs_bloc_2");
      const div3 = document.getElementById("prac_addrs_bloc_1");
      const div4 = document.getElementById("prac_addrs_bloc_2");
      const height1 = div1.clientHeight;
      const height2 = div2.clientHeight;
      const height3 = div3.clientHeight;
      const height4 = div4.clientHeight;
      const greaterHeight1 = Math.max(height1, height2);
      const greaterHeight2 = Math.max(height3, height4);
      div1.style.height = greaterHeight1 + "px";
      div2.style.height = greaterHeight1 + "px";
      div3.style.height = greaterHeight2 + "px";
      div4.style.height = greaterHeight2 + "px";
    }, 2000);
  }

  stateBloc() {
    setTimeout(() => {
      const div1 = document.getElementById("medical-licences_1");
      const div2 = document.getElementById("medical-licences_2");
      const height1 = div1.clientHeight;
      const height2 = div2.clientHeight;
      const greaterHeight = Math.max(height1, height2);
      div1.style.height = greaterHeight + "px";
      div2.style.height = greaterHeight + "px";
    }, 2000);
  }

  checkUncheckAllFirstProfile() {
    for (var i = 0; i < this.firstProfile.length; i++) {
      this.firstProfile[i].isSelected = this.isMasterSelFirst;
      this.firstProfile[i].isDisabled = false;
    }
    this.getCheckedItemListFirstProfile();
  }

  checkUncheckAllSecondProfile() {
    for (var i = 0; i < this.secondProfile.length; i++) {
      this.secondProfile[i].isSelected = this.isMasterSelSecond;
      this.secondProfile[i].isDisabled = false;
    }

    this.firstProfile.forEach(z => {

      const firstProfile = this.firstProfile.find(
        (x) => x.displayName === z.displayName
      );
      const secondProfile = this.secondProfile.find(
        (x) => x.displayName === z.displayName
      );
      if (this.isMasterSelSecond) {
        firstProfile.value = secondProfile.value;
        firstProfile.itemSelected = true;
        secondProfile.itemSelected = true;
        if (firstProfile.restorevalue) {
          firstProfile.showrestorevalue = true;
          secondProfile.showrestorevalue = true;
        }
      }
      else {
        firstProfile.value = firstProfile.restorevalue;
        firstProfile.showrestorevalue = false;
        secondProfile.showrestorevalue = false;
        firstProfile.itemSelected = false;
        secondProfile.itemSelected = false;
      }
    });

    this.getCheckedItemListSecondProfile();

    if (this.isMasterSelSecond) {
      this.firstProfile.forEach((profile) => { profile.itemSelected = true });
      this.secondProfile.forEach((profile) => { profile.itemSelected = true });
    }
    else {
      this.firstProfile.forEach((profile) => { profile.itemSelected = false });
      this.secondProfile.forEach((profile) => { profile.itemSelected = false });
    }

  }

  isAllSelectedFirst() {
    this.isMasterSelFirst = this.firstProfile.every(function (item: any) {
      return item.isSelected == true;
    });
    this.getCheckedItemListFirstProfile();
  }

  isAllSelectedSecond() {
    this.isMasterSelSecond = this.secondProfile.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemListSecondProfile();
  }

  getCheckedItemListFirstProfile() {
    this.firstProfileChecked = [];
    for (var i = 0; i < this.firstProfile.length; i++) {
      if (this.firstProfile[i].isSelected)
        this.firstProfileChecked.push(this.firstProfile[i]);
    }
    this.firstProfileChecked = JSON.stringify(this.firstProfileChecked);
    this.isMasterSelSecond = false;
    this.secondProfile.forEach((profile) => { profile.isSelected = this.isMasterSelSecond; profile.isDisabled = this.isMasterSelFirst; });

  }
  getCheckedItemListSecondProfile() {

    this.firstProfileChecked = [];
    for (var i = 0; i < this.firstProfile.length; i++) {
      if (this.firstProfile[i].isSelected)
        this.firstProfileChecked.push(this.firstProfile[i]);
    }
    this.firstProfileChecked = JSON.stringify(this.firstProfileChecked);
    this.isMasterSelFirst = false;

  }
  checkAllProfile(profile: string, event) {
    const confirmationModal = this.ngbModal.open(SelectallconfirmationComponent, {
      ariaDescribedBy: 'modal-basic-title', windowClass: 'groupModal', size: 'sm', centered: true
    });
    confirmationModal.componentInstance.profile = profile;
    confirmationModal.componentInstance.checkedStatus = event.target.checked;
    if (this.isMasterSelFirst || this.isMasterSelSecond) {
      confirmationModal.componentInstance.deselect = true;
    }
    else if (!this.isMasterSelFirst || !this.isMasterSelSecond) {
      confirmationModal.componentInstance.deselect = false;
    }
    confirmationModal.componentInstance.confirmSelectAll.subscribe(($event) => {
      if ($event == true && profile == "First") {
        this.checkUncheckAllFirstProfile();
      }
      else if ($event == false && profile == "First") {
        //this.isAllSelectedFirst();
      }
      else if ($event == true && profile == "Second") {
        this.checkUncheckAllSecondProfile();
      }
      else if ($event == false && profile == "Second") {
        // this.isAllSelectedSecond();
      }
    });

  }
  onSelectionBoth(profile: string, display: string, event: any) { }

  validateSelections(): boolean {
    if (!this.firstProfileTemplateVar || !this.firstProfileTemplateVar.nativeElement || !this.firstProfileTemplateVar.nativeElement.children || this.firstProfileTemplateVar.nativeElement.children.length == 0) {
      return;
    }

    let totalProperties = Object.values(this.firstProfileTemplateVar.nativeElement.children).filter((ch: any) => !ch.hidden)?.length;

    let firstProfileSelectionLength = Object.values(this.firstProfileTemplateVar.nativeElement.children).filter((fch: any) => fch.classList?.contains('selected'))?.length;
    let secondProfileSelectionLength = Object.values(this.secondProfileTemplateVar.nativeElement.children).filter((sch: any) => sch.classList?.contains('selected'))?.length;

    return (firstProfileSelectionLength + secondProfileSelectionLength) != totalProperties;
  }
}
