<!-- Modal -->
<div *blockUI="'show-loader'">
    <div class="modal-body">
        <div class="flex text-center flex-col w-full">
            <p>More than two selections are not allowed</p>
            <div class="flex">
                <button class="btn primary-bg text-white btnlogin" (click)="activeModal.dismiss('Cross click')">OK</button>
            </div>
            
        </div>
    </div>
</div>