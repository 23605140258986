import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatclaimant'
})
export class FormatclaimantPipe implements PipeTransform {

  transform(name: string): string {
    if (name) {
      return name.split(',').join(', ');
    }
    return '';
  }

}
