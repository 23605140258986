import { RecordCount } from './../../models/provider/records.count';
import { ToastrService } from 'ngx-toastr';
import { AppUtils } from './../../utilities/app.utils';
import { ProviderService } from './../../services/provider.service';
import { ProviderPagedResult } from './../../models/provider/provider.paged.result';
import { Component, OnInit } from '@angular/core';
import { ProviderFilterModel } from 'src/app/models/provider/provider.filter.model';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ProviderListHeadersEnum } from 'src/app/utilities/enums';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  todayDate: Date = new Date();

  providers: ProviderPagedResult;
  recordsCount: RecordCount;
  providerfilterModel: ProviderFilterModel;
  searchText: string;
  searchCriteria: string[];
  page: number;
  pageSize: number;
  orderBy: string;
  isDescending: boolean;
  omitEmptyNames: boolean;
  showDublicateName: boolean;
  showNewRecords: boolean;
  providerType: number;
  SPRcount: any;
  caseDtls = [
    {
      tab: 'User Assigned Diaries',
      columns: [
        'Case Number',
        'Name of Case',
        'Diary Type',
        'Diary Note',
        'Due Date',
      ],
    },
    {
      tab: 'All Diaries',
      columns: [
        'Case Number',
        'Name of Case',
        'Diary Type',
        'Diary Note',
        'Due Date',
      ],
    },
    {
      tab: 'Assigned Cases',
      columns: [
        'Case Number',
        'Name of Case',
        'Case Status',
        'Client',
        'Last Updated',
      ],
    },
  ];

  caseDtlsColumns = this.caseDtls[0].columns;

  @BlockUI('dashboard-screen') blockUI: NgBlockUI;

  constructor(
    private providersService: ProviderService,
    private appUtils: AppUtils,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {
    this.searchText = '';
    this.searchCriteria = [];
    this.page = 1;
    this.pageSize = 7;
    // this.orderBy = ProviderListHeadersEnum.createdDate as any;
    this.isDescending = false;
    this.omitEmptyNames = false;
    this.showDublicateName = false;
    this.showNewRecords = true;
    this.providerType = 1;
  }

  ngOnInit(): void {
    this.providers = new ProviderPagedResult();
    this.recordsCount = new RecordCount();
    this.providerfilterModel = new ProviderFilterModel(
      this.searchText,
      this.searchCriteria,
      this.page,
      this.pageSize,
      this.orderBy,
      this.isDescending,
      this.omitEmptyNames,
      this.showDublicateName,
      this.showNewRecords,
      this.providerType
    );
    //this.getRecordsCount();
    this.getProvidersPagedList();
    this.getSPRCount();
  }

  getProvidersPagedList = (): void => {
    this.blockUI.start();
    this.providersService.getProviders(this.providerfilterModel).subscribe(
      (data) => {
        this.providers = data;
        this.blockUI.stop();
      },
      (error) => {
        this.appUtils.ProcessErrorResponse(this.toastr, error);
        this.blockUI.stop();
      }
    );
  };
  getRecordsCount = () => {
    this.blockUI.start();
    this.providersService.getRecordsCount().subscribe(
      (data) => {
        this.recordsCount = data;
      },
      (error) => {
        this.appUtils.ProcessErrorResponse(this.toastr, error);
        this.blockUI.stop();
      }
    );
  };

  getSPRCount = () => {
    this.blockUI.start();
    this.providersService.getSprCount('SPR').subscribe(
      (data) => {
        this.SPRcount = data;
        this.blockUI.stop();
      },
      (error) => {
        this.appUtils.ProcessErrorResponse(this.toastr, error);
        this.blockUI.stop();
      }
    );
  };

  openModal(content, size = 'md') {
    this.modalService.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size,
      centered: true,
    });
  }

  setCaseDtlsColumns(tab:string){
    this.caseDtlsColumns = this.caseDtls.find(dtl=> dtl.tab == tab).columns;
  }
}

