import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProviderService } from './provider.service';

@Injectable({
  providedIn: 'root'
})
export class ProvidertablesService {

  WhitePap: any[] = [];
  ClearRep: any[] = [];
  Document: any[] = [];
  FinancialInterest: any[] = [];
  OtherAssociated: any[] = [];
  selectedFile: File = null;
  apiURL = environment.fireStoreApiUrl;
  public configObservable = new Subject<any>();
  isClearReport: boolean;
  isWhiteReport: boolean;
  isDocuments: boolean;
  documentSubject: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient, private toastr: ToastrService, private providers: ProviderService) { }

  onLoadPopulateTable() {

    this.WhitePap = [];
    this.FinancialInterest = [];
    this.ClearRep = [];
    this.Document = [];

  }

  uploadWhitePaper(event) {
    this.selectedFile = <File>event.target.files[0];
    const fileData = new FormData();
    fileData.append("file", this.selectedFile, this.selectedFile.name);
    this.http.post(this.apiURL + "/api/v1/file/?file_type=WhitePaper", fileData).subscribe(data => {


      this.http.get<any>(this.apiURL + "/api/v1/file/all/WhitePaper/").subscribe(data => {
        this.WhitePap = [];
        for (let i = 0; i <= data.details.length - 1; i++) {
          this.WhitePap.push(
            {
              filename: data.details[i].file_name,
              uploaddt: data.details[i].created_at,
              uuid: data.details[i].uuid
            }
          );
        }
        this.emitConfig(this.WhitePap);
        this.toastr.success('The file has been uploaded successfully');
      });
    });
  }

  uploadClearReport(event) {
    this.selectedFile = <File>event.target.files[0];
    const fileData = new FormData();
    fileData.append("file", this.selectedFile, this.selectedFile.name);
    this.http.post(this.apiURL + "/api/v1/file/?file_type=ClearReport", fileData).subscribe(data => {

      this.http.get<any>(this.apiURL + "/api/v1/file/all/ClearReport").subscribe(data => {
        this.ClearRep = [];
        for (let i = 0; i <= data.details.length - 1; i++) {
          this.ClearRep.push(
            {
              filename: data.details[i].file_name,
              uploaddt: data.details[i].created_at,
              id: data.details[i].id
            }
          );
        }
        this.emitConfig(this.ClearRep);
        this.toastr.success('The file has been uploaded successfully');
      });
    });
  }

  uploadDocument(event, spearId: string[]) {
    this.selectedFile = <File>event.target.files[0];
    const fileData = new FormData();
    const sprdID = spearId.join(',');
    fileData.append("file", this.selectedFile, this.selectedFile.name);
    fileData.append("spear_id", sprdID);
    this.http.post(this.apiURL + "/api/v1/file/?file_type=Document", fileData).subscribe(data => {

      this.http.get<any>(this.apiURL + `/api/v1/file/all/Document/?spear_id_list=${sprdID}`).subscribe(data => {
        this.Document = [];
        for (let i = 0; i <= data.details.length - 1; i++) {
          this.Document.push(
            {
              filename: data.details[i].file_name,
              uploaddt: data.details[i].created_at,
              id: data.details[i].id
            }
          );
        }
        this.emitConfig(this.Document);
        this.toastr.success('The file has been uploaded successfully');
      });
    });
  }

  deleteFile(deleteFrom: string, id: string) {
    if (deleteFrom == "Clear") {
      this.http.delete(`${this.apiURL}/api/v1/file/${id}`)
        .subscribe(() => {
          this.http.get<any>(`${this.apiURL}/api/v1/file/all/ClearReport/`).subscribe(data => {
            this.ClearRep = [];
            for (let i = 0; i <= data.details.length - 1; i++) {
              this.ClearRep.push(
                {
                  filename: data.details[i].file_name,
                  uploaddt: data.details[i].created_at,
                  id: data.details[i].id,
                }
              );
            }
            this.emitConfig(this.ClearRep);
          }, error => {
            this.ClearRep = [];
            this.emitConfig(this.ClearRep);
          });
          this.toastr.success('The file has been deleted successfully');
        });
    } else if (deleteFrom == "White") {
      this.http.delete(`${this.apiURL}/api/v1/file/${id}/`)
        .subscribe((data) => {
          this.http.get<any>(`${this.apiURL}/api/v1/file/all/WhitePaper/`).subscribe(data => {
            this.WhitePap = [];
            for (let i = 0; i <= data.details.length - 1; i++) {
              this.WhitePap.push(
                {
                  filename: data.details[i].file_name,
                  uploaddt: data.details[i].created_at,
                  id: data.details[i].id
                }
              );
            }
            this.emitConfig(this.WhitePap);
          }, error => {
            this.WhitePap = [];
            this.emitConfig(this.WhitePap);
          });
          this.toastr.success('The file has been deleted successfully');
        });
    }
  }

  deleteDocument(spearId: string[], id: string): void {
    const sprdID = spearId.join(',');
    this.http.delete(`${this.apiURL}/api/v1/file/${id}/`)
        .subscribe((data) => {
          this.http.get<any>(this.apiURL + `/api/v1/file/all/Document/?spear_id_list=${sprdID}`).subscribe(data => {
            this.Document = [];
            for (let i = 0; i <= data.details.length - 1; i++) {
              this.Document.push(
                {
                  filename: data.details[i].file_name,
                  uploaddt: data.details[i].created_at,
                  id: data.details[i].id
                }
              );
            }
            this.documentSubject.next(this.Document);
          }, error => {
            this.Document = [];
            this.documentSubject.next(this.Document);
          });
          this.toastr.success('The file has been deleted successfully');
        });
  }

  uploadFinancialInterest(selectedFirestore: string) {
    this.providers.getProviderProfile(selectedFirestore).subscribe((response: any) => {
      let postProvider: any = { financial_interest_details: {} };
      postProvider.financial_interest_details.firestore = response.details.spear_id;
      postProvider.financial_interest_details.mpn = '';
      postProvider.financial_interest_details.tin = '';
      postProvider.financial_interest_details.alerts = response.details.po_code;
      postProvider.financial_interest_details.alertlang = response.details.narrative_reference;
      postProvider.financial_interest_details.name = response.details.provider_name_person;
      postProvider.financial_interest_details.npi = response.details.provider_npi_number;
      postProvider.financial_interest_details.street = response.details.provider_street_address1;
      postProvider.financial_interest_details.city = response.details.provider_city;
      postProvider.financial_interest_details.state = response.details.provider_state;
      postProvider.financial_interest_details.zip = response.details.provider_zip;
      this.http.post(`${environment.fireStoreApiUrl}/api/v1/financial/`, postProvider).subscribe(data => {
        this.FinancialInterest = [];
        this.http.get<any>(`${environment.fireStoreApiUrl}/api/v1/financial/all/`).subscribe(data => {
          this.FinancialInterest = [];
          for (let i = 0; i <= data.details.length - 1; i++) {
            this.FinancialInterest.push(
              {
                name: data.details[i].financial_interest_details.name,
                firestore: data.details[i].financial_interest_details.firestore,
                tin: data.details[i].financial_interest_details.tin,
                npi: data.details[i].financial_interest_details.npi,
                mpn: data.details[i].financial_interest_details.mpn,
                alerts: data.details[i].financial_interest_details.alerts,
                alertlang: data.details[i].financial_interest_details.alertlang,
                street: data.details[i].financial_interest_details.street,
                city: data.details[i].financial_interest_details.city,
                state: data.details[i].financial_interest_details.state,
                zip: data.details[i].financial_interest_details.zip,
                uuid: data.details[i].financial_interest_details.uuid,
                id: data.details[i].id
              }
            );
          }
          this.emitConfig(this.FinancialInterest);
        }, error => {
          this.FinancialInterest = [];
          this.emitConfig(this.FinancialInterest);
        });
        this.toastr.success("The entity has been successfully added");
      });
    });
  }

  dissociateFinancial(id: any): void {

    this.http.delete(`${environment.fireStoreApiUrl}/api/v1/financial/${id}/`)
      .subscribe((data) => {
        this.toastr.success('The record has been deleted successfully');
        this.http.get<any>(`${environment.fireStoreApiUrl}/api/v1/financial/all/`).subscribe(data => {
          this.FinancialInterest = [];
          for (let i = 0; i <= data.details.length - 1; i++) {
            this.FinancialInterest.push(
              {
                name: data.details[i].financial_interest_details.name,
                firestore: data.details[i].financial_interest_details.firestore,
                tin: data.details[i].financial_interest_details.tin,
                npi: data.details[i].financial_interest_details.npi,
                mpn: data.details[i].financial_interest_details.mpn,
                alerts: data.details[i].financial_interest_details.alerts,
                alertlang: data.details[i].financial_interest_details.alertlang,
                street: data.details[i].financial_interest_details.street,
                city: data.details[i].financial_interest_details.city,
                state: data.details[i].financial_interest_details.state,
                zip: data.details[i].financial_interest_details.zip,
                uuid: data.details[i].financial_interest_details.uuid,
                id: data.details[i].id
              }
            );
          }
          this.emitConfig(this.FinancialInterest);
        }, error => {
          this.FinancialInterest = [];
          this.emitConfig(this.FinancialInterest);
        });
      });
  }

  uploadOtherAssociate(selectedFirestore: string) {
    const result = this.OtherAssociated.find(({ id }) => id == selectedFirestore);
    if(!result){
      this.providers.getProviderProfile(selectedFirestore).subscribe((response: any) => {
        let postProvider: any = { provider_details: {} };
        postProvider.provider_details.firestore = response.details.spear_id;
        postProvider.provider_details.name = response.details.provider_name_person;
        postProvider.provider_details.street = response.details.provider_street_address_1;
        postProvider.provider_details.city = response.details.provider_city;
        postProvider.provider_details.state = response.details.provider_state;
        postProvider.provider_details.zip = response.details.provider_zip;
        this.http.post(environment.fireStoreApiUrl + '/api/v1/provider/', postProvider).subscribe(data => {
          this.OtherAssociated = [];
          this.http.get<any>(this.apiURL + "/api/v1/provider/all/").subscribe(data => {
            for (let i = 0; i <= data.details.length - 1; i++) {
              this.OtherAssociated.push(
                {
                  name: data.details[i].provider_details.name,
                  firestore: data.details[i].provider_details.firestore,
                  street: data.details[i].provider_details.street,
                  city: data.details[i].provider_details.city,
                  state: data.details[i].provider_details.state,
                  zip: data.details[i].provider_details.zip,
                  id: data.details[i].id
                }
              );
            }
          });
          this.emitConfig(this.OtherAssociated);
          //this.toastr.success("The entity has been successfully added");
        });
      });
    }
  }

  dissociateOtherAssociate(id: string) {
    this.http.delete(environment.fireStoreApiUrl + '/api/v1/provider/' + id)
      .subscribe((data) => {
        this.toastr.success('The record has been deleted successfully');
        this.http.get<any>(environment.fireStoreApiUrl + "/api/v1/provider/all/").subscribe(data => {
          this.OtherAssociated = [];
          for (let i = 0; i <= data.details.length - 1; i++) {
            this.OtherAssociated.push(
              {
                name: data.details[i].provider_details.name,
                firestore: data.details[i].provider_details.firestore,
                street: data.details[i].provider_details.street,
                city: data.details[i].provider_details.city,
                state: data.details[i].provider_details.state,
                zip: data.details[i].provider_details.zip,
                id: data.details[i].id
              }
            );
          }
          this.emitConfig(this.OtherAssociated);
        });
      });
  }


  emitConfig(val) {
    this.configObservable.next(val);
  }

}
