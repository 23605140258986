import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UserType } from 'src/app/utilities/enums';
@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {

  constructor(private authService: AuthService) {
  }

  get userRole(): string {
    //console.log(this.authService.getUserRole())
    return this.authService.getUserRole();
    
  }

  get userType(): typeof UserType {
    return UserType;
  }
  ngOnInit(): void {
    // alert(this.role);
  }


  showOverlay(): void {
    localStorage.setItem('predashboard', 'true');
    location.reload();
  }
}
