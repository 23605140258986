import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-merge-selection-not-allowed',
  templateUrl: './merge-selection-not-allowed.component.html',
  styleUrls: ['./merge-selection-not-allowed.component.css']
})
export class MergeSelectionNotAllowedComponent implements OnInit {

  constructor(private _NgbActiveModal: NgbActiveModal) { }

  get activeModal() {
    return this._NgbActiveModal;
  }

  ngOnInit(): void {
  }

}
