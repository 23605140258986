<div class="container-fluid p-0 dashboard-wrapper">
  <div class="row">
    <div class="col-md-12">
      <div class="w-100">
        <div class="row mb-xl-3 justify-content-between">
          <div class="col-auto">
            <h3 class="page-title">Dashboard</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-8">
            <div class="card flex-fill border-0 bg-grey">
              <div class="card-header rounded px-4 pb-0 bg-white border-0">
                <div class="row mb-2">
                  <div class="col-2"></div>
                  <div class="col-3">
                    <select name="case" id="case" class="form-control p-1">
                      <option value="" disabled selected>Select</option>
                      <option value="" disabled>Case Name</option>
                      <option value="" disabled>Case Number</option>
                      <option value="" disabled>Client Reference #</option>
                      <option value="" disabled>Insured</option>
                      <option value="" disabled>Policy #</option>
                    </select>
                  </div>
                  <div class="col-5 d-flex">
                    <input
                      type="text"
                      class="form-control mr-2"
                      placeholder="Enter text"
                    />
                    <button type="button" class="btn btn-sm btn-primary">
                      Search
                    </button>
                  </div>
                  <div class="col-2 text-right">
                    <button
                      type="button"
                      class="btn btn-sm btn-info p-1 w-100 h-100"
                      (click)="openModal(createNewCase, 'xl')"
                    >
                      New Case
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body search-spear-background">
                <div class="table-row">
                  <table class="table spear-search-table-header-background">
                    <thead>
                      <tr>
                        <th class="border-top-0" scope="col">PROGRESSIONS</th>
                        <th class="border-top-0" scope="col">SIU</th>
                        <th class="border-top-0" scope="col">LIMOS</th>
                        <th class="border-top-0" scope="col">SV</th>
                        <th class="border-top-0" scope="col">ST</th>
                        <th class="border-top-0" scope="col">BG</th>
                        <th class="border-top-0" scope="col">SM</th>
                        <th class="border-top-0" scope="col">MC</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span class="text-black-contrast">UNASSIGNED</span>
                        </td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                      </tr>
                      <tr>
                        <td><span class="text-black-contrast">OPEN</span></td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                      </tr>
                      <tr>
                        <td>
                          <span class="text-black-contrast">EDITING</span>
                        </td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                      </tr>
                      <tr>
                        <td><span class="text-black-contrast">QC</span></td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                      </tr>
                      <tr>
                        <td>
                          <span class="text-black-contrast">DISTRIBUTION</span>
                        </td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                      </tr>
                      <tr>
                        <td><span class="text-black-contrast">CLOSED</span></td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                        <td>#</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="card flex-fill border-0">
              <div class="card-header">
                <h5>Recent Views</h5>
              </div>
              <div
                class="card-body rounded px-4 pt-3 pb-0 bg-white search-spear-background"
              >
                <div class="table-row">
                  <table class="table spear-search-table-header-background">
                    <thead>
                      <tr>
                        <th class="border-top-0" scope="col">Case Number</th>
                        <th class="border-top-0" scope="col">Name Of Case</th>
                        <th class="border-top-0" scope="col">Case Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="3" class="text-center">
                          Data not available
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-8" *blockUI="'diaries-list-ui'">
            <div class="card flex-fill border-0 mt-3">
              <div class="card-header">
                <ul class="nav nav-pills">
                  <li class="nav-item">
                    <a
                      class="nav-link active text-sm font-weight-bold text-dark"
                      data-toggle="pill"
                      href="#home"
                      (click)="setCaseDtlsColumns('Diaries')"
                      >Diaries</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link text-sm font-weight-bold text-dark"
                      data-toggle="pill"
                      href="#menu2"
                      (click)="setCaseDtlsColumns('Assigned Cases')"
                      >Assigned Cases</a
                    >
                  </li>
                </ul>
              </div>
              <div
                class="card-body rounded px-4 pt-3 pb-0 bg-white search-spear-background"
              >
                <div class="text-right">
                  <input type="checkbox" name="all_diaries" id="all_diaries" />
                  <label
                    for="all_diaries"
                    class="text-sm text-black-contrast pl-1"
                    >All Diaries</label
                  >
                </div>
                <div class="table-row">
                  <table class="table spear-search-table-header-background">
                    <thead>
                      <tr>
                        <th
                          *ngFor="let column of caseDtlsColumns"
                          class="border-top-0"
                          scope="col"
                        >
                          {{ column }}
                        </th>
                      </tr>
                    </thead>
                    <ng-container>
                      <tbody
                        *ngIf="selectedCaseTab == 'Diaries' && diaries?.length"
                      >
                        <tr class="bg-white" *ngFor="let diary of diaries">
                          <td>{{ diary.case_number }}</td>
                          <td>{{ diary.case_name }}</td>
                          <td>{{ diary.type }}</td>
                          <td>{{ diary.note }}</td>
                          <td>
                            {{ diary.due_date | date : clientDateFormat }}
                          </td>
                        </tr>
                      </tbody>
                    </ng-container>

                    <tbody *ngIf="selectedCaseTab == 'Assigned Cases'"></tbody>
                    <tbody *ngIf="!diaries?.length">
                      <tr>
                        <td
                          [attr.colspan]="caseDtlsColumns.length"
                          class="text-center"
                        >
                          Data not available
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    class="px-0 py-2 paginationWrapper spearPaginationWrapper d-flex"
                    *ngIf="selectedCaseTab == 'Diaries'"
                  >
                    <ngb-pagination
                      [(collectionSize)]="diaryTotal"
                      [(page)]="diaryPage"
                      [pageSize]="diaryLimit"
                      [maxSize]="10"
                      [boundaryLinks]="true"
                      (pageChange)="onPageChangeDiaries($event)"
                    >
                    </ngb-pagination>

                    <div class="selected d-flex align-items-center">
                      <select
                        (change)="onSizeChangeDiaries($event.target.value)"
                        class="custom-select table-select h-100"
                        [(ngModel)]="diaryLimit"
                      >
                        <option [value]="30">30</option>
                        <option [value]="50">50</option>
                        <option [value]="75">75</option>
                        <option [value]="100">100</option>
                      </select>
                      <p class="mb-0 ml-2 text-nowrap">
                        Showing
                        {{ diaryPage * diaryLimit - diaryLimit + 1 }} -
                        {{
                          diaryPage * diaryLimit > diaryTotal
                            ? diaryTotal
                            : diaryPage * diaryLimit
                        }}
                        of {{ diaryTotal }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="card flex-fill border-0">
              <div class="card-header">
                <h5>Progression Dates</h5>
              </div>
              <div
                class="card-body rounded px-4 pt-3 pb-0 bg-white search-spear-background"
              >
                <div class="table-row">
                  <table class="table spear-search-table-header-background">
                    <thead>
                      <tr>
                        <th class="border-top-0" scope="col">Due Today</th>
                        <th class="border-top-0" scope="col">Due Tomorrow</th>
                        <th class="border-top-0" scope="col">Due This Week</th>
                        <th class="border-top-0" scope="col">Past Due</th>
                        <th class="border-top-0" scope="col">No Due Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="5" class="text-center">
                          Data not available
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #createNewCase let-modal>
  <div class="modal-header">
    <h6>New Case</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body create-new-case text-sm">
    <div class="row mb-2">
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="caseCreatedOn"
            >Created On</span
          >
          <input
            type="text"
            name="caseCreatedOn"
            id="caseCreatedOn"
            class="form-control"
            [value]="todayDate | date : 'MM/dd/yyyy'"
            jDate
          />
        </div>
      </div>
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="caseDueDate">Due Date</span>
          <input
            type="text"
            name="caseDueDate"
            id="caseDueDate"
            class="form-control"
            jDate
          />
        </div>
      </div>
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_client">Client</span>
          <select name="case_client" id="case_client" class="form-control">
            <option value="">Select</option>
          </select>
        </div>
      </div>
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_program">Program</span>
          <select name="case_program" id="case_program" class="form-control">
            <option value="">Select</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_tpa">TPA</span>
          <select name="case_tpa" id="case_tpa" class="form-control">
            <option value="">Select</option>
          </select>
        </div>
      </div>
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_mga">MGA</span>
          <select name="case_mga" id="case_mga" class="form-control">
            <option value="">Select</option>
          </select>
        </div>
      </div>

      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_contact"
            >Case Contact</span
          >
          <select name="case_contact" id="case_contact" class="form-control">
            <option value="">Select</option>
          </select>
        </div>
      </div>
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_client_contact"
            >Client Contact</span
          >
          <select
            name="case_client_contact"
            id="case_client_contact"
            class="form-control"
          >
            <option value="">Select</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_type">Case Type</span>
          <select name="case_type" id="case_type" class="form-control">
            <option value="">Select</option>
          </select>
        </div>
      </div>
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_status"
            >Client Status</span
          >
          <select name="case_status" id="case_status" class="form-control">
            <option value="">Select</option>
          </select>
        </div>
      </div>

      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_client_reference_no"
            >Client Reference #</span
          >
          <input
            type="text"
            name="case_client_reference_no"
            id="case_client_reference_no"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_name">Case Name</span>
          <input
            type="text"
            name="case_name"
            id="case_name"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_insured">Insured</span>
          <input
            type="text"
            name="case_insured"
            id="case_insured"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_location"
            >Case Location</span
          >
          <input
            type="text"
            name="case_location"
            id="case_location"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_claim_status"
            >Claim Status</span
          >
          <input
            type="text"
            name="case_claim_status"
            id="case_claim_status"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-3">
        <div>
          <span class="text-dark fw-500 mr-1" for="case_adj_no">ADJ #</span>
          <input
            type="text"
            name="case_adj_no"
            id="case_adj_no"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div>
      <div class="mt-4 d-flex justify-content-end">
        <button
          type="button"
          class="btn btn-secondary btn-sm text-white mr-1"
          (click)="modalService.dismissAll()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn limos-button-background btn-sm text-white"
          [routerLink]="['/cases']"
          (click)="modalService.dismissAll()"
        >
          Save & Continue
        </button>
      </div>
    </div>
  </div>
</ng-template>
