import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-column-sort',
  templateUrl: './table-column-sort.component.html',
  styleUrls: ['./table-column-sort.component.css'],
})
export class TableColumnSortComponent {
  @Input() columnName: string;
  @Input() orderBy: string = '';
  @Input() orderDirection: string = 'asc';
}
