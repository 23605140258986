import { Component, EventEmitter, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { ProvidertablesService } from 'src/app/services/providertables.service';
import { ProviderService } from 'src/app/services/provider.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SearchstateService } from 'src/app/services/searchstate.service';
import { Hit, ProviderSearchReponseMOdel } from 'src/app/models/provider/provider.melie.search.reponse.model';
import { environment } from 'src/environments/environment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AssociateComponent } from '../associate/associate.component';
export type SortColumn = keyof ProviderSearchReponseMOdel | '';
export type SortDirection = 'asc' | 'desc' | '';
export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}

@Component({
  selector: 'app-add-financial-other-association-modal',
  templateUrl: './add-financial-other-association-modal.component.html',
  styleUrls: ['./add-financial-other-association-modal.component.css']
})
export class AddFinancialOtherAssociationModalComponent implements OnInit {

  public model: any;
  form: FormGroup;
  phonePattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  page: number = 1;
  isSearched: boolean;
  pageIndex: number;
  filteredQuery = [];
  emptyForm: boolean = false;
  emptyFormMessage: boolean;
  filteredText: string;
  selectedProviders: any[];
  hideme: any[];
  providers: any[];
  isFilterOn: boolean = true;
  limit: number;
  isClaimNumberSearched: any;
  providerSize: number;
  isSpear = true;
  sortData: Array<Hit>;
  activeSortObj: SortEvent;
  dateTimeConfig: { dateInputFormat: string; };
  searchText: string;
  searchCriteria: any[];
  @BlockUI('manage-users-blockUI') blockUI: NgBlockUI;
  selectedAssociates = [];
  entityAssociationType:string;

  constructor(
    private _NgbActiveModal: NgbActiveModal,
    private toastr: ToastrService,
    private http: HttpClient,
    private providertable: ProvidertablesService,
    private providerService: ProviderService,
    private formBuilder: FormBuilder,
    private stateService: SearchstateService,
    private ngbModal: NgbModal,
  ) {
    this.dateTimeConfig = { dateInputFormat: environment.dateTimeFormat.date.toUpperCase() };
    this.providers = new Array<Hit>();
    this.page = 1;
    this.pageIndex = 1;
    this.limit = 50;
    this.searchText = '';
    this.filteredText = '';
    this.searchCriteria = [];
  }

  ngOnInit(): void {
    this.initForm();
  }

  get activeModal() {
    return this._NgbActiveModal;
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      provider_individual: new FormControl(),
      tin: new FormControl(),
      streetaddress: new FormControl(),
      city: new FormControl(),
      state: new FormControl(),
      npi: new FormControl(),
      phone: ['', [Validators.pattern(this.phonePattern)]],
    });
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.page = 1;
      this.onSubmit();
    }
  }

  onFieldReset(fld: string): void {
    let ctrl = this.form.get(fld);
    ctrl.reset();
  }

  onReset(): void {
    this.form.reset();
    this.isSearched = false;
  }

  isAllChecked = (): boolean => {
    if (!this.providers || this.providers.length === 0) {
      return false;
    }
    return this.providers.every((all) => all.isSelected);
  };
  isAnyChecked = (): boolean => {
    if (this.selectedProviders.length < 1) {
      return false;
    } else {
      return true;
    }
  };
  isAnyRowChecked= (): boolean => {
   return this.selectedAssociates.length != 1;
  };
  isAnyTwoChecked = (): boolean => {
    if (this.selectedProviders.length < 2) {
      return false;
    } else {
      return true;
    }
  };

  CheckAllOptions(checked: boolean): void {
    this.providers.forEach((x) => (x.isSelected = checked));
    this.selectedProviders = [];
    this.providers.forEach((provider) => {
      this.selectedProviders.push(provider.spear_id);
    });
    if (!checked) {
      this.selectedProviders = [];
    }
  }

  Checked(spear_id: string, entity: any[], providerId: number, event:any): void {
    if (this.selectedProviders.includes(spear_id)) {
      this.selectedProviders.splice(
        this.selectedProviders.findIndex((x) => x === spear_id),
        1
      );
    } else {
      this.selectedProviders.push(spear_id);
    }
    if(event.target.checked == true){
      this.selectedAssociates.push({entityId: entity, id: providerId, spear_id: spear_id});
    }
    else if(event.target.checked == false){
      this.selectedAssociates.pop();
    }
  }

  onPageChange(event: any) {
    this.pageIndex = event;
    this.getSearchFilteredProviders();
  }

  onSizeChange(limit: number) {
    this.pageIndex = 1;
    this.limit = limit;
    this.getSearchFilteredProviders();
    this.page = 1;
  }

  onSubmit(): void {
    const controls = this.form.controls;
    this.pageIndex = 1;
    this.filteredQuery = [];
    if (controls.provider_individual.value) {
      this.filteredQuery.push(
        `provider_individual=${controls.provider_individual.value}`
      );
    }
    if (controls.phone.value) {
      this.filteredQuery.push(
        `phone=${controls.phone.value}`
      );
    }
    if (controls.npi.value) {
      this.filteredQuery.push(
        `npi=${controls.npi.value}`
      );
    }
    if (controls.tin.value) {
      this.filteredQuery.push(
        `tin=${controls.tin.value}`
      );
    }
    if (controls.city.value) {
      this.filteredQuery.push(
        `city=${controls.city.value}`
      );
    }
    if (controls.streetaddress.value) {
      this.filteredQuery.push(
        `street_address=${controls.streetaddress.value}`
      );
    }
    if (controls.state.value) {
      this.filteredQuery.push(
        `state=${controls.state.value}`
      );
    }

    this.filteredText = this.filteredQuery.join('&');

    this.isSearched = true;

    this.emptyFormMessage = false;

    this.getSearchFilteredProviders();
  }

  getSearchFilteredProviders(): void {

    this.blockUI.start();
    this.selectedProviders = [];
    this.hideme = [];
    this.providers = [];
    this.providerService.getProvidersFilteredFromFireStore(this.pageIndex, this.limit, this.isFilterOn, this.filteredText, false).subscribe(
      (response) => {
        if (response.details.hits.length > 0) {
          response.details.hits.forEach(adrs => {
            if (
              adrs.provider_street_address_1 == null &&
              adrs.provider_street_address_2 == null &&
              adrs.provider_city == null &&
              adrs.provider_state == null &&
              adrs.provider_zip == null
            ) {
              adrs.provider_full_address = '&#8212;';
            }
            else {
              adrs.provider_full_address = (adrs.provider_street_address_1 ? `${adrs.provider_street_address_1}, ` : '') + (adrs.provider_street_address_2 ? `${adrs.provider_street_address_2}, ` : '') + (adrs.provider_city ? `${adrs.provider_city}, ` : '') + (adrs.provider_state ? `${adrs.provider_state} ` : '') + (adrs.provider_zip ? adrs.provider_zip : '');
            }
          });
          response.details.hits.forEach(offcialName => {
            if (
              offcialName.official_first_name == null &&
              offcialName.official_middle_name == null &&
              offcialName.official_last_name == null
            ) {
              offcialName.official_name = '';
            }
            else {
              offcialName.official_name = (offcialName.official_first_name ? offcialName.official_first_name : '') + (offcialName.official_first_name && offcialName.official_middle_name ? ' ' : '') + (offcialName.official_middle_name ? ` ${offcialName.official_middle_name}` : '') + (offcialName.official_middle_name && offcialName.official_last_name ? ' ' : '') + (offcialName.official_last_name ? ` ${offcialName.official_last_name}` : '');
            }
          });

          if (this.isClaimNumberSearched) {
            let filteredData = response.details.hits.sort((a, b) => {
              const dateA = new Date(a.bill_review_br_complete_date);
              const dateB = new Date(b.bill_review_br_complete_date);
              return dateA.getTime() - dateB.getTime();
            });
            let x = [];
            x.push(filteredData[filteredData.length - 1]);
            this.providers = x;
            this.providerSize = 1;
            this.isClaimNumberSearched = false;
            this.stateService.setSearchedState(this.providers);
            this.stateService.setSearchedItemState(this.filteredQuery);
            if (this.isSpear) {
              this.stateService.setSearchedByState('byspear');
            }
            else {
              this.stateService.setSearchedByState('bylimos');
            }
            this.stateService.setProviderSizeState(this.providerSize);
          }
          else {
            this.providers = response.details.hits;
            this.providerSize = response.details.estimatedTotalHits;
            this.stateService.setSearchedState(response.details.hits);
            if (this.isSpear) {
              this.stateService.setSearchedByState('byspear');
            }
            else {
              this.stateService.setSearchedByState('bylimos');
            }
            this.stateService.setSearchedItemState(this.filteredQuery);
            this.stateService.setProviderSizeState(this.providerSize);
          }

          this.sortData = this.providers;
          this.blockUI.stop();
        }
        else {
          this.providers = [];
          this.blockUI.stop();
        }
      }
    );
  }

  doAssociate(selectedAssociates: any) {
    const openAssociate = this.ngbModal.open(AssociateComponent, {
      ariaDescribedBy: 'modal-basic-title', size: 'lg', centered: true
    });
    openAssociate.componentInstance.selectedAssociates = selectedAssociates;
    openAssociate.componentInstance.entityAssociationType = this.entityAssociationType;
    openAssociate.componentInstance.evt.subscribe(($event) => {
      if($event){
        this.onSubmit();
        this.selectedAssociates = [];
        this.entityAssociationType = '';
      }
    });
    this.activeModal.close();
  }

}
