import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
})
export class ResourcesComponent implements OnInit {
  private files: File[] = [];
  uploadProgress: number = 0;
  public selectedResourceFiles: any[];

  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  onFolderSelected(event: any) {
    this.files = Array.from(event.target.files);
    console.log(event.target.files);
  }

  uploadFiles() {
    console.log(this.files);
    if (this.files.length === 0) {
      console.log('No files selected');
      return;
    }

    // const formData = new FormData();
    // this.files.forEach((file, index) => {
    //   formData.append(`file${index}`, file, file.webkitRelativePath);
    // });

    console.log(this.files);

    // this.http.post('your-upload-api-endpoint', formData, {
    //   reportProgress: true,
    //   observe: 'events'
    // }).subscribe({
    //   next: (event: any) => {
    //     if (event.type === 4) { // 4 is for upload progress events
    //       this.uploadProgress = Math.round(100 * event.loaded / event.total);
    //     }
    //   },
    //   error: (err) => {
    //     console.error('Upload failed:', err);
    //   },
    //   complete: () => {
    //     console.log('Upload completed');
    //     this.uploadProgress = 0;
    //   }
    // });
  }

  onResourceFolderSelected(event: any) {
    this.selectedResourceFiles = Array.from(event.target.files).filter((file) =>
      this.isValidFileType(file)
    );
    this.selectedResourceFiles.map((file) => {
      file.formattedSize = this.formatFileSize(file.size);
      return file;
    });
    console.log(this.selectedResourceFiles);
  }

  isValidFileType(file: any): boolean {
    const allowedTypes = [
      'image/png',
      'image/jpeg',
      'image/jpg',
      'application/pdf',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    return allowedTypes.includes(file.type);
  }

  formatFileSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    if (bytes === 0) return '0 Bytes';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const size = bytes / Math.pow(1024, i);
    return `${size.toFixed(2)} ${sizes[i]}`;
  }

  deleteResourceFile(index: number) {
    if (confirm('Are you sure you want to delete this resource?')) {
      this.selectedResourceFiles.splice(index, 1);
    }
  }

  public openPopup(content, size = 'md') {
    this.modalService.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size,
      centered: true,
    });
  }
}
