<div >
    <div class="modal-header px-0">
        <h4 class="modal-title" id="modal-basic-title border-0">
            <span>Find/Export NPI by Address</span>
        </h4>
        <button type="button" class="btn d-block ml-auto btnclose text-gray rounded-circle p-0" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>    
    <form name="form" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
        <div class="modal-body px-0 py-4">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group mb-4">
                        <label class="loginLabel" for="street">Street</label>
                        <input type="text" name="street" #street="ngModel" class="form-control" [(ngModel)]="findExportAddressModel.street" required>
                        <div class="alert alert-danger p-2" *ngIf="street.invalid && street.touched">
                            Please enter Street name</div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group mb-4">                            
                        <label class="loginLabel" for="city">City</label>
                        <input type="text" name="city" #city="ngModel" class="form-control" [(ngModel)]="findExportAddressModel.city" required>
                        <div class="alert alert-danger p-2" *ngIf="city.invalid && city.touched">
                            Please enter city name</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group mb-4">                            
                        <label class="loginLabel" for="state">State</label>
                        <input type="text" name="state" #state="ngModel" class="form-control" [(ngModel)]="findExportAddressModel.state" required>
                        <div class="alert alert-danger p-2" *ngIf="state.invalid && state.touched">
                            Please enter state name</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group mb-4">                            
                        <label class="loginLabel" for="zipcode">Zip Code</label>
                        <input type="text" name="zipcode" #zipcode="ngModel" class="form-control" [(ngModel)]="findExportAddressModel.zipcode" required>
                        <div class="alert alert-danger p-2" *ngIf="zipcode.invalid && zipcode.touched">
                            Please enter zip code</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0 p-0 mb-4">
            <button type="submit" [disabled]="f.invalid" class="rounded-0 btn text-white primary-bg mb-0">Export Data</button>
        </div>
    </form>
</div>