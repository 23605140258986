<div class="container-fluid p-0">
  <div class="row">
    <div class="col-md-12">
      <div class="w-100">
        <div class="row mb-xl-3 justify-content-between">
          <div class="col-auto">
            <h3 class="page-title">Cases</h3>
          </div>
        </div>
        <div class="card flex-fill border-0">
          <div class="card-header rounded px-4 pb-0 bg-white">
            <div class="row mb-2">
              <div class="col-2">Status</div>
              <div class="col-3">Filter</div>
              <div class="col-2">
              </div>
              <div class="col-3 text-right">
                Advanced
              </div>
              <div class="col-2 position-relative">
                <div class="text-right">
                  <button type="button" class="btn btn-sm btn-info p-1 mr-1" (click)="showCaseColCustomizeSettings = !showCaseColCustomizeSettings">Customize List</button>
                </div>
                <div *ngIf="showCaseColCustomizeSettings" class="bg-white border shadow m-1 p-2 position-absolute w-75 column-customize-dropdown">
                  <ul class="p-2">
                    <li *ngFor="let column of caseColumns;let i = index">
                      <input type="checkbox" name="{{column.name}}" id="{{column.name + i}}" [(ngModel)]="column.selected" class="mr-1">
                      <label for="{{ column.name+i }}">{{ column.name }}</label>
                    </li>
                  </ul>
                  <hr>
                  <div class="w-100 text-center mt-3">
                    <button type="button" class="btn btn-sm btn-close" (click)="showCaseColCustomizeSettings = !showCaseColCustomizeSettings">Cancel</button>
                    <button type="button" class="btn btn-sm btn-primary" (click)="setCustomColumns()">Apply</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-row">
              <table class="table spear-search-table-header-background">
                <thead>
                  <tr>
                    <ng-container *ngFor="let column of caseColumns;let i = index">
                      <th class="border-top-0" scope="col" *ngIf="column.selected">{{ column.name }}</th>
                    </ng-container>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <ng-container *ngFor="let column of caseColumns;let i = index">
                      <td *ngIf="column.selected">Not Listed</td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
