<div class="container-fluid p-0">
    <div class="row">
        <div class="col-md-12">
            <div class="w-100">
                <div class="row">
                    <div class="col-12 primary-tab">
                        <!-- <h3 class="page-title d-inline-block mr-4">
                            Search by
                        </h3>
                        <select (change)="searchBy($event)" [(ngModel)]="searchByInVal"
                            class="form-control d-inline-block w-auto">
                            <option value="byspear">Spear</option>
                            <option value="bylimos">LIMOS</option>
                        </select> -->

                        <ul class="nav nav-tabs">
                            <li class="nav-item text-black-contrast" *ngFor="let tab of pageTabs">
                                <button class="active nav-link text-uppercase" [ngClass]="{'search-spear-background': activeTab == tab &&tab == 'SPEAR', 'active search-limos-background': activeTab == tab && tab == 'LIMOS'}"
                                    (click)="activeTab = tab; searchBy(tab)">{{ tab }}</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="activeTab == 'SPEAR'">
        <div class="row">
            <div class="col-md-12">
                <div class="card border-0">
                    <div class="card-body search-spear-background">
                        <!-- <div class="col-md-12 text-center filter-panel">
                            <button class="bg-transparent" *ngIf="!isFilterOn" (click)="filterOn()">
                                <em class="fa fas fa-chevron-down mr-1"></em>More Search Options
                            </button>
                            <button class="bg-transparent" *ngIf="isFilterOn" (click)="filterOff()">
                                <em class="fa fas fa-chevron-up mr-1"></em>Close Search Options
                            </button>
                        </div> -->
                        <form class="new-case-form" name="form" [formGroup]="form" (keydown)="keyDownFunction($event)">
                            <div class="row">
                                <!-- <div class="col-md-12">
                                    <div class="row align-items-center">
                                        <div class="col-md-1">Search</div>
                                        <div class="col-md-11 d-flex align-items-center">
                                            <input type="text" formControlName="searchAll"
                                                placeholder="Search company, individual, name, alias name, etc."
                                                class="form-control">
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-md-12" *ngIf="isFilterOn">
                                    <div class="row">
                                        <div class="col-md-6 pr-4">
                                            <div class="row">
                                              <div class="col-md-4">Last Name </div>
                                              <div class="col-md-8 d-flex"><input type="text"
                                                      formControlName="official_last_name" class="form-control"
                                                      placeholder="Enter Last Name">
                                                  <button type="button"
                                                      class="btn bg-voilet spear-button-background text-white p-2 ml-2"
                                                      (click)="onFieldReset('official_last_name')"><em
                                                          class="fa fas fa-refresh"></em></button>
                                              </div>
                                            </div>

                                            <div class="row mt-1">
                                                <div class="col-md-4">First Name</div>
                                                <div class="col-md-8 d-flex"><input type="text"
                                                        formControlName="official_first_name" class="form-control"
                                                        placeholder="Enter First Name">
                                                    <button type="button"
                                                        class="btn bg-voilet spear-button-background text-white p-2 ml-2"
                                                        (click)="onFieldReset('official_first_name')"><em
                                                            class="fa fas fa-refresh"></em></button>
                                                </div>
                                            </div>

                                            <div class="row mt-1">
                                              <div class="col-md-4">Middle Name </div>
                                              <div class="col-md-8 d-flex"><input type="text"
                                                      formControlName="official_middle_name" class="form-control"
                                                      placeholder="Enter Middle Name">
                                                  <button type="button"
                                                      class="btn bg-voilet spear-button-background text-white p-2 ml-2"
                                                      (click)="onFieldReset('official_middle_name')"><em
                                                          class="fa fas fa-refresh"></em></button>
                                              </div>
                                            </div>

                                            <div class="row mt-1">
                                              <div class="col-md-4">Suffix </div>
                                              <div class="col-md-8 d-flex"><input type="text"
                                                      formControlName="name_suffix" class="form-control"
                                                      placeholder="Enter Suffix">
                                                  <button type="button"
                                                      class="btn bg-voilet spear-button-background text-white p-2 ml-2"
                                                      (click)="onFieldReset('name_suffix')"><em
                                                          class="fa fas fa-refresh"></em></button>
                                              </div>
                                            </div>

                                            <div class="row mt-1">
                                                <div class="col-md-4">Phone</div>
                                                <div class="col-md-8">
                                                    <div class="d-flex">
                                                        <input class="form-control" formControlName="phone" type="text"
                                                            maxlength="14" placeholder="(xxx) xxx-xxxx" />
                                                        <button type="button"
                                                            class="btn bg-voilet spear-button-background text-white p-2 ml-2"
                                                            (click)="onFieldReset('phone')"><em
                                                                class="fa fas fa-refresh"></em></button>
                                                    </div>
                                                    <div *ngIf="form.get('phone').hasError('pattern')">
                                                        Please enter a valid phone number
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-md-4">NPI</div>
                                                <div class="col-md-8 d-flex"><input type="text" appAcceptNumber
                                                        appCustomMaxLength max=10 formControlName="npi"
                                                        class="form-control" placeholder="Enter Provider NPI">
                                                    <button type="button"
                                                        class="btn bg-voilet spear-button-background text-white p-2 ml-2"
                                                        (click)="onFieldReset('npi')"><em
                                                            class="fa fas fa-refresh"></em></button>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-md-4">TIN</div>
                                                <div class="col-md-8 d-flex"><input type="text" appAcceptNumber
                                                        appCustomMaxLength max=10 formControlName="tin"
                                                        class="form-control" placeholder="Enter Provider TIN">
                                                    <button type="button"
                                                        class="btn bg-voilet spear-button-background text-white p-2 ml-2"
                                                        (click)="onFieldReset('tin')"><em
                                                            class="fa fas fa-refresh"></em></button>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-md-4">Data Source</div>
                                                <div class="col-md-8">
                                                    <select class="form-control" formControlName="source">
                                                        <option value="">Select Source</option>
                                                        <option value="EAMS">EAMS</option>
                                                        <option value="SUSPALL">SUSPALL</option>
                                                        <option value="NPI">NPI</option>
                                                        <option value="SPEAR">SPEAR</option>
                                                        <option value="MBC">MBC</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-md-4">Provider Type</div>
                                                <div class="col-md-8">
                                                    <select class="form-control" formControlName="provider_type">
                                                        <option value="">Select Provider Type</option>
                                                        <option value="0">Record</option>
                                                        <option value="1">Person</option>
                                                        <option value="2">Business</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-md-4">Claims Admin</div>
                                                <div class="col-md-8 d-flex">
                                                    <input type="text" max=10 formControlName="claims_admin"
                                                        class="form-control" placeholder="Enter Claims Admin">
                                                    <button type="button"
                                                        class="btn bg-voilet spear-button-background text-white p-2 ml-2"
                                                        (click)="onFieldReset('claims_admin')"><em
                                                            class="fa fas fa-refresh"></em></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 pl-4">
                                            <div class="row">
                                                <div class="col-md-4">City</div>
                                                <div class="col-md-8 d-flex"><input type="text" formControlName="city"
                                                        class="form-control" placeholder="Enter Provider City">
                                                    <button type="button"
                                                        class="btn bg-voilet spear-button-background text-white p-2 ml-2"
                                                        (click)="onFieldReset('city')"><em
                                                            class="fa fas fa-refresh"></em></button>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-md-4">Street Address</div>
                                                <div class="col-md-8 d-flex"><input type="text"
                                                        formControlName="streetaddress" class="form-control"
                                                        placeholder="Enter Provider Street Name">
                                                    <button type="button"
                                                        class="btn bg-voilet spear-button-background text-white p-2 ml-2"
                                                        (click)="onFieldReset('streetaddress')"><em
                                                            class="fa fas fa-refresh"></em></button>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-md-4">State</div>
                                                <div class="col-md-8">
                                                    <select class="form-control" formControlName="state">
                                                        <option value="AL">Alabama</option>
                                                        <option value="AK">Alaska</option>
                                                        <option value="AZ">Arizona</option>
                                                        <option value="AR">Arkansas</option>
                                                        <option value="CA">California</option>
                                                        <option value="CO">Colorado</option>
                                                        <option value="CT">Connecticut</option>
                                                        <option value="DE">Delaware</option>
                                                        <option value="DC">District Of Columbia</option>
                                                        <option value="FL">Florida</option>
                                                        <option value="GA">Georgia</option>
                                                        <option value="HI">Hawaii</option>
                                                        <option value="ID">Idaho</option>
                                                        <option value="IL">Illinois</option>
                                                        <option value="IN">Indiana</option>
                                                        <option value="IA">Iowa</option>
                                                        <option value="KS">Kansas</option>
                                                        <option value="KY">Kentucky</option>
                                                        <option value="LA">Louisiana</option>
                                                        <option value="ME">Maine</option>
                                                        <option value="MD">Maryland</option>
                                                        <option value="MA">Massachusetts</option>
                                                        <option value="MI">Michigan</option>
                                                        <option value="MN">Minnesota</option>
                                                        <option value="MS">Mississippi</option>
                                                        <option value="MO">Missouri</option>
                                                        <option value="MT">Montana</option>
                                                        <option value="NE">Nebraska</option>
                                                        <option value="NV">Nevada</option>
                                                        <option value="NH">New Hampshire</option>
                                                        <option value="NJ">New Jersey</option>
                                                        <option value="NM">New Mexico</option>
                                                        <option value="NY">New York</option>
                                                        <option value="NC">North Carolina</option>
                                                        <option value="ND">North Dakota</option>
                                                        <option value="OH">Ohio</option>
                                                        <option value="OK">Oklahoma</option>
                                                        <option value="OR">Oregon</option>
                                                        <option value="PA">Pennsylvania</option>
                                                        <option value="RI">Rhode Island</option>
                                                        <option value="SC">South Carolina</option>
                                                        <option value="SD">South Dakota</option>
                                                        <option value="TN">Tennessee</option>
                                                        <option value="TX">Texas</option>
                                                        <option value="UT">Utah</option>
                                                        <option value="VT">Vermont</option>
                                                        <option value="VA">Virginia</option>
                                                        <option value="WA">Washington</option>
                                                        <option value="WV">West Virginia</option>
                                                        <option value="WI">Wisconsin</option>
                                                        <option value="WY">Wyoming</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-md-4">ADJ Number</div>
                                                <div class="col-md-8 d-flex">
                                                    <input type="text" max=10 formControlName="adj" class="form-control"
                                                        placeholder="Enter ADJ Number">
                                                    <button type="button"
                                                        class="btn bg-voilet spear-button-background text-white p-2 ml-2"
                                                        (click)="onFieldReset('adj')"><em
                                                            class="fa fas fa-refresh"></em></button>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-md-4">Insured</div>
                                                <div class="col-md-8 d-flex">
                                                    <input type="text" max=10 formControlName="insured"
                                                        class="form-control" placeholder="Enter Insured">
                                                    <button type="button"
                                                        class="btn bg-voilet spear-button-background text-white p-2 ml-2"
                                                        (click)="onFieldReset('insured')"><em
                                                            class="fa fas fa-refresh"></em></button>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-md-4">Insurance Company</div>
                                                <div class="col-md-8 d-flex">
                                                    <input type="text" max=10 formControlName="insurance_company"
                                                        class="form-control" placeholder="Enter Insurance Company Name">
                                                    <button type="button"
                                                        class="btn bg-voilet spear-button-background text-white p-2 ml-2"
                                                        (click)="onFieldReset('insurance_company')"><em
                                                            class="fa fas fa-refresh"></em></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="row justify-content-end mt-1">
                            <div class="col-md-auto align-items-end">
                                <button class="btn bg-voilet spear-button-background text-white  px-3 py-2 pr-3 pl-3"
                                    (click)="onReset()"><em class="fa fas fa-refresh mr-3"></em>Reset</button>
                                <button
                                    class="btn bg-voilet spear-button-background text-white  ml-2 px-3 py-2 pr-3 pl-3"
                                    (click)="onSubmit('false')"><em class="fa fas fa-search mr-3"></em> Search</button>
                            </div>
                        </div>
                        <div *ngIf="emptyFormMessage">
                            <h6 class="text-center">Please put or select value of any field</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="isSearched">
            <div class="col-md-12">
                <div class="card mb-0 border-0">
                    <div class="card-body">
                        <div class="container-fluid p-0" *blockUI="'manage-users-blockUI'">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="w-100">
                                        <div class="row mb-2 mb-xl-3 justify-content-between align-items-center">
                                            <div class="col-auto">
                                                <h3 class="page-title">Search result list</h3>
                                            </div>
                                            <div>
                                                <!-- <button [disabled]="!isAnyRowChecked()" (click)="doAssociate(selectedAssociates)"
                                                  class="btn btn-secondary-outline bg-white text-voilet p-2 mr-2  btn-sm btn-merge">Associate</button> -->
                                                <button disabled (click)="doAssociate(selectedAssociates)"
                                                  class="btn btn-secondary-outline bg-white text-voilet p-2 mr-2  btn-sm btn-merge">Associate</button>
                                                <button [disabled]="!isAnyTwoChecked()"
                                                    class="btn btn-secondary-outline bg-white text-voilet p-2 mr-2  btn-sm btn-merge"
                                                    (click)="applyMerge()">Merge</button>
                                                <button
                                                    class="btn btn-secondary-outline search-button-outline bg-white text-voilet p-2 mr-2  btn-sm"
                                                    (click)="downloadCSV()">Download CSV
                                                </button>
                                                <button
                                                    class="btn btn-secondary-outline search-button-outline bg-white text-voilet p-2 mr-3  btn-sm"
                                                    (click)="onSubmit('true')">Export All To CSV
                                                </button>
                                            </div>
                                        </div>
                                        <div class="table-row">
                                            <table
                                                class="table providerTable spear-search-table-header-background table-row cnstr-record product-tbl">
                                                <caption>Data Table</caption>
                                                <thead>
                                                    <tr>
                                                        <th class="border-0" scope="col">
                                                            <div class="custom-control custom-checkbox checkbox-custom">
                                                                <input type="checkbox"
                                                                    class="custom-control-input spear-custom-control-input"
                                                                    id="customCheck"
                                                                    (change)="CheckAllOptions($event.target.checked)"
                                                                    [checked]="isAllChecked()">
                                                                <label
                                                                    class="custom-control-label control-custom-label cursor-pointer"
                                                                    for="customCheck"></label>
                                                            </div>
                                                        </th>
                                                        <th class="border-0" scope="col" sortable="provider_name_person"
                                                            (sort)="onSort($event)">Name</th>
                                                        <th class="border-0" scope="col"
                                                            sortable="provider_name_business" (sort)="onSort($event)">
                                                            Business</th>
                                                        <th class="border-0" scope="col" sortable=""
                                                            (sort)="onSort($event)" width="150">Entities</th>
                                                        <th class="border-0" scope="col" sortable="provider_npi_number"
                                                            (sort)="onSort($event)">NPI</th>
                                                        <th class="border-0" scope="col" sortable=""
                                                            (sort)="onSort($event)">TIN</th>
                                                        <th class="border-0" scope="col" sortable="tax_id_number"
                                                            (sort)="onSort($event)">Type</th>
                                                        <th class="border-0" scope="col" sortable="spear_id_prefix"
                                                            (sort)="onSort($event)">Source</th>
                                                        <th class="border-0" scope="col"
                                                            sortable="provider_full_address" (sort)="onSort($event)">
                                                            Address</th>
                                                    </tr>
                                                </thead>
                                                <!-- for empty tr-->
                                                <tr class="bg-white" *ngIf="!providers || providers.length === 0">
                                                    <td colspan="12" class="text-center">No Records Found</td>
                                                </tr>
                                                <tbody *ngFor="let provider of providers; index as i">
                                                    <!-- for filled tr -->
                                                    <tr class="bg-white">
                                                        <td class="primary-text font-weight-semibold">
                                                            <div
                                                                class="custom-control custom-checkbox checkbox-custom align-items-center">
                                                                <input type="checkbox"
                                                                    class="custom-control-input spear-custom-control-input"
                                                                    id="customCheck1_{{i}}"
                                                                    [(ngModel)]="provider.isSelected"
                                                                    [value]="provider.value"
                                                                    (change)="Checked(provider?.spear_id, provider?.entities, provider?.id, $event)">
                                                                <label
                                                                    class="custom-control-label control-custom-label cursor-pointer"
                                                                    for="customCheck1_{{i}}"></label>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <span
                                                                *ngIf="!provider.provider_name_person && !provider.official_name">No
                                                                Name Available</span>
                                                            <a *ngIf="provider.provider_name_person || provider.official_name"
                                                                href="/profile-report/{{provider.spear_id}}/1"
                                                                target="_blank"
                                                                [innerHTML]="(provider.provider_name_person || provider.official_name)"
                                                                class="cursor-pointer primary-text font-weight-semibold"></a>
                                                        </td>
                                                        <td>
                                                            <span *ngIf="!provider.provider_name_business">No Name
                                                                Available</span>
                                                            <a *ngIf="provider.provider_name_business"
                                                                href="/profile-report/{{provider.spear_id}}/2"
                                                                target="_blank"
                                                                [innerHTML]="provider.provider_name_business"
                                                                class="cursor-pointer primary-text font-weight-semibold"></a>
                                                        </td>
                                                        <td>
                                                            <div *ngFor="let ent of provider.entities; let i = index;">
                                                                <a href="/entities-report/{{ent.id}}" target="_blank"
                                                                    class="cursor-pointer primary-text font-weight-semibold">{{ent.entity_name}}</a>
                                                            </div>
                                                        </td>
                                                        <td
                                                            [innerHTML]="(provider.provider_npi_number ? provider.provider_npi_number : '&#8212;')">
                                                        </td>
                                                        <td class="table-max-width"
                                                            [innerHTML]="(provider.tax_id_number ? provider.tax_id_number : '&#8212;')">
                                                        </td>
                                                        <td class="table-max-width">
                                                            <span *ngIf="provider.entity_type_code == 1">Person</span>
                                                            <span *ngIf="provider.entity_type_code == 2">Business</span>
                                                            <span
                                                                *ngIf="provider.entity_type_code == 0 || provider.entity_type_code == '' || provider.entity_type_code == null">Record</span>
                                                        </td>
                                                        <td
                                                            [innerHTML]="(provider.spear_id_prefix ? provider.spear_id_prefix : '&#8212;')">
                                                        </td>
                                                        <td class="table-max-width"
                                                            [innerHTML]="(provider.provider_full_address ? provider.provider_full_address : '&#8212;')">
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="d-flex px-0 py-0 paginationWrapper"
                                                *ngIf="!(!providers || providers.length === 0)">
                                                <ngb-pagination [(collectionSize)]="providerSize" [(page)]="page"
                                                    [pageSize]="limit" [maxSize]="10" [boundaryLinks]="true"
                                                    (pageChange)="onPageChange($event)">
                                                </ngb-pagination>
                                                <div class="selected d-flex align-items-center">
                                                    <select (change)="onSizeChange(limit)"
                                                        class="custom-select table-select" [(ngModel)]="limit">
                                                        <option [ngValue]="20">20</option>
                                                        <option [ngValue]="50">50</option>
                                                        <option [ngValue]="75">75</option>
                                                        <option [ngValue]="100">100</option>
                                                    </select>
                                                    <p class="mb-0 ml-2">Showing {{ ((pageIndex * limit) - limit) + 1 }}
                                                        -
                                                        {{ pageIndex * limit > providerSize ? providerSize : pageIndex *
                                                        limit }} of {{ providerSize }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="activeTab == 'LIMOS'">
        <div class="row">
            <div class="col-md-12">
                <div class="card border-0">
                    <div class="card-body search-limos-background">
                        <!-- <div class="col-md-12 text-center filter-panel">
                            <button class="bg-transparent" *ngIf="!isFilterOn" (click)="filterOn()">
                                <em class="fa fas fa-chevron-down mr-1"></em>More Search Options
                            </button>
                            <button class="bg-transparent" *ngIf="isFilterOn" (click)="filterOff()">
                                <em class="fa fas fa-chevron-up mr-1"></em>Close Search Options
                            </button>
                        </div> -->
                        <form class="new-case-form" name="form" [formGroup]="form" (keydown)="keyDownFunction($event)">
                            <div class="row">
                                <!-- <div class="col-md-12">
                                    <div class="row align-items-center">
                                        <div class="col-md-1">Search</div>
                                        <div class="col-md-11 d-flex align-items-center">
                                            <input type="text" formControlName="searchAll"
                                                placeholder="Search company, individual, name, alias name, etc."
                                                class="form-control">
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-md-12" *ngIf="isFilterOn">
                                    <div class="row">
                                          <div class="col-md-6 pr-4">
                                            <div class="row">
                                              <div class="col-md-4">Last Name</div>
                                              <div class="col-md-8 d-flex"><input type="text"
                                                      formControlName="official_last_name" class="form-control"
                                                      placeholder="Enter Last Name">
                                                  <button type="button"
                                                      class="btn bg-voilet limos-button-background text-white p-2 ml-2"
                                                      (click)="onFieldReset('official_last_name')"><em
                                                          class="fa fas fa-refresh"></em></button>
                                              </div>
                                            </div>

                                            <div class="row mt-1">
                                                <div class="col-md-4">First Name</div>
                                                <div class="col-md-8 d-flex"><input type="text"
                                                        formControlName="official_first_name" class="form-control"
                                                        placeholder="Enter First Name">
                                                    <button type="button"
                                                        class="btn bg-voilet limos-button-background text-white p-2 ml-2"
                                                        (click)="onFieldReset('official_first_name')"><em
                                                            class="fa fas fa-refresh"></em></button>
                                                </div>
                                            </div>

                                            <div class="row mt-1">
                                              <div class="col-md-4">Middle Name</div>
                                              <div class="col-md-8 d-flex"><input type="text"
                                                      formControlName="official_middle_name" class="form-control"
                                                      placeholder="Enter Middle Name">
                                                  <button type="button"
                                                      class="btn bg-voilet limos-button-background text-white p-2 ml-2"
                                                      (click)="onFieldReset('official_middle_name')"><em
                                                          class="fa fas fa-refresh"></em></button>
                                              </div>
                                            </div>

                                            <div class="row mt-1">
                                              <div class="col-md-4"> Suffix</div>
                                              <div class="col-md-8 d-flex"><input type="text"
                                                      formControlName="name_suffix" class="form-control"
                                                      placeholder="Enter Suffix">
                                                  <button type="button"
                                                      class="btn bg-voilet limos-button-background text-white p-2 ml-2"
                                                      (click)="onFieldReset('name_suffix')"><em
                                                          class="fa fas fa-refresh"></em></button>
                                              </div>
                                            </div>

                                            <div class="row mt-1">
                                                <div class="col-md-4">Phone</div>
                                                <div class="col-md-8">
                                                    <div class="d-flex">
                                                        <input class="form-control" formControlName="phone" type="text"
                                                            maxlength="14" placeholder="(xxx) xxx-xxxx" />
                                                        <button type="button"
                                                            class="btn bg-voilet limos-button-background text-white p-2 ml-2"
                                                            (click)="onFieldReset('phone')"><em
                                                                class="fa fas fa-refresh"></em></button>
                                                    </div>
                                                    <div *ngIf="form.get('phone').hasError('pattern')">
                                                        Please enter a valid phone number
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-md-4">NPI</div>
                                                <div class="col-md-8 d-flex"><input type="text" appAcceptNumber
                                                        appCustomMaxLength max=10 formControlName="npi"
                                                        class="form-control" placeholder="Enter Provider NPI">
                                                    <button type="button"
                                                        class="btn bg-voilet limos-button-background text-white p-2 ml-2"
                                                        (click)="onFieldReset('npi')"><em
                                                            class="fa fas fa-refresh"></em></button>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-md-4">Data Source</div>
                                                <div class="col-md-8">
                                                    BRS
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-md-4">Provider Type</div>
                                                <div class="col-md-8">
                                                    <select class="form-control" formControlName="provider_type">
                                                        <option value="">Select Provider Type</option>
                                                        <option value="0">Entity</option>
                                                        <option value="1">Person</option>
                                                        <option value="2">Business</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-md-4">Claim Number</div>
                                                <div class="col-md-8 d-flex"><input type="text" max=10
                                                        formControlName="claim_number" class="form-control"
                                                        placeholder="Enter Claim Number">
                                                    <button type="button"
                                                        class="btn bg-voilet limos-button-background text-white p-2 ml-2"
                                                        (click)="onFieldReset('claim_number')"><em
                                                            class="fa fas fa-refresh"></em></button>
                                                </div>
                                            </div>

                                            <div class="row mt-1">
                                              <div class="col-md-4">Claimant Last Name</div>
                                              <div class="col-md-8 d-flex"><input type="text" max=10
                                                      formControlName="claimant_last_name" class="form-control"
                                                      placeholder="Enter Claimant Last Name">
                                                  <button type="button"
                                                      class="btn bg-voilet limos-button-background text-white p-2 ml-2"
                                                      (click)="onFieldReset('claimant_last_name')"><em
                                                          class="fa fas fa-refresh"></em></button>
                                              </div>
                                            </div>

                                            <div class="row mt-1">
                                                <div class="col-md-4">Claimant First Name</div>
                                                <div class="col-md-8 d-flex"><input type="text" max=10
                                                        formControlName="claimant_first_name" class="form-control"
                                                        placeholder="Enter Claimant First Name">
                                                    <button type="button"
                                                        class="btn bg-voilet limos-button-background text-white p-2 ml-2"
                                                        (click)="onFieldReset('claimant_first_name')"><em
                                                            class="fa fas fa-refresh"></em></button>
                                                </div>
                                            </div>

                                            <div class="row mt-1">
                                              <div class="col-md-4">Claimant Middle Name</div>
                                              <div class="col-md-8 d-flex"><input type="text" max=10
                                                      formControlName="claimant_middle_name" class="form-control"
                                                      placeholder="Enter Claimant Middle Name">
                                                  <button type="button"
                                                      class="btn bg-voilet limos-button-background text-white p-2 ml-2"
                                                      (click)="onFieldReset('claimant_middle_name')"><em
                                                          class="fa fas fa-refresh"></em></button>
                                              </div>
                                            </div>

                                            <div class="row mt-1">
                                              <div class="col-md-4">Claimant Suffix</div>
                                              <div class="col-md-8 d-flex"><input type="text" max=10
                                                      formControlName="claimant_name_suffix" class="form-control"
                                                      placeholder="Enter Claimant Suffix">
                                                  <button type="button"
                                                      class="btn bg-voilet limos-button-background text-white p-2 ml-2"
                                                      (click)="onFieldReset('claimant_name_suffix')"><em
                                                          class="fa fas fa-refresh"></em></button>
                                              </div>
                                            </div>

                                            <div class="row mt-1">
                                                <div class="col-md-4">Adjuster</div>
                                                <div class="col-md-8 d-flex"><input type="text" max=10
                                                        formControlName="adjuster" class="form-control"
                                                        placeholder="Enter Adjuster">
                                                    <button type="button"
                                                        class="btn bg-voilet limos-button-background text-white p-2 ml-2"
                                                        (click)="onFieldReset('adjuster')"><em
                                                            class="fa fas fa-refresh"></em></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 pl-4">
                                            <div class="row">
                                                <div class="col-md-4">City</div>
                                                <div class="col-md-8 d-flex"><input type="text" formControlName="city"
                                                        class="form-control" placeholder="Enter Provider City">
                                                    <button type="button"
                                                        class="btn bg-voilet limos-button-background text-white p-2 ml-2"
                                                        (click)="onFieldReset('city')"><em
                                                            class="fa fas fa-refresh"></em></button>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-md-4">Street Address</div>
                                                <div class="col-md-8 d-flex"><input type="text"
                                                        formControlName="streetaddress" class="form-control"
                                                        placeholder="Enter Provider Street Name">
                                                    <button type="button"
                                                        class="btn bg-voilet limos-button-background text-white p-2 ml-2"
                                                        (click)="onFieldReset('streetaddress')"><em
                                                            class="fa fas fa-refresh"></em></button>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-md-4">State</div>
                                                <div class="col-md-8">
                                                    <select class="form-control" formControlName="state">
                                                        <option value="AL">Alabama</option>
                                                        <option value="AK">Alaska</option>
                                                        <option value="AZ">Arizona</option>
                                                        <option value="AR">Arkansas</option>
                                                        <option value="CA">California</option>
                                                        <option value="CO">Colorado</option>
                                                        <option value="CT">Connecticut</option>
                                                        <option value="DE">Delaware</option>
                                                        <option value="DC">District Of Columbia</option>
                                                        <option value="FL">Florida</option>
                                                        <option value="GA">Georgia</option>
                                                        <option value="HI">Hawaii</option>
                                                        <option value="ID">Idaho</option>
                                                        <option value="IL">Illinois</option>
                                                        <option value="IN">Indiana</option>
                                                        <option value="IA">Iowa</option>
                                                        <option value="KS">Kansas</option>
                                                        <option value="KY">Kentucky</option>
                                                        <option value="LA">Louisiana</option>
                                                        <option value="ME">Maine</option>
                                                        <option value="MD">Maryland</option>
                                                        <option value="MA">Massachusetts</option>
                                                        <option value="MI">Michigan</option>
                                                        <option value="MN">Minnesota</option>
                                                        <option value="MS">Mississippi</option>
                                                        <option value="MO">Missouri</option>
                                                        <option value="MT">Montana</option>
                                                        <option value="NE">Nebraska</option>
                                                        <option value="NV">Nevada</option>
                                                        <option value="NH">New Hampshire</option>
                                                        <option value="NJ">New Jersey</option>
                                                        <option value="NM">New Mexico</option>
                                                        <option value="NY">New York</option>
                                                        <option value="NC">North Carolina</option>
                                                        <option value="ND">North Dakota</option>
                                                        <option value="OH">Ohio</option>
                                                        <option value="OK">Oklahoma</option>
                                                        <option value="OR">Oregon</option>
                                                        <option value="PA">Pennsylvania</option>
                                                        <option value="RI">Rhode Island</option>
                                                        <option value="SC">South Carolina</option>
                                                        <option value="SD">South Dakota</option>
                                                        <option value="TN">Tennessee</option>
                                                        <option value="TX">Texas</option>
                                                        <option value="UT">Utah</option>
                                                        <option value="VT">Vermont</option>
                                                        <option value="VA">Virginia</option>
                                                        <option value="WA">Washington</option>
                                                        <option value="WV">West Virginia</option>
                                                        <option value="WI">Wisconsin</option>
                                                        <option value="WY">Wyoming</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-md-4">ADJ Number</div>
                                                <div class="col-md-8 d-flex">
                                                    <input type="text" max=10 formControlName="adj" class="form-control"
                                                        placeholder="Enter ADJ Number">
                                                    <button type="button"
                                                        class="btn bg-voilet limos-button-background text-white p-2 ml-2"
                                                        (click)="onFieldReset('adj')"><em
                                                            class="fa fas fa-refresh"></em></button>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-md-4">Insured</div>
                                                <div class="col-md-8 d-flex">
                                                    <input type="text" max=10 formControlName="insured"
                                                        class="form-control" placeholder="Enter Insured">
                                                    <button type="button"
                                                        class="btn bg-voilet limos-button-background text-white p-2 ml-2"
                                                        (click)="onFieldReset('insured')"><em
                                                            class="fa fas fa-refresh"></em></button>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-md-4">Insurance Company</div>
                                                <div class="col-md-8 d-flex">
                                                    <input type="text" max=10 formControlName="insurance_company"
                                                        class="form-control" placeholder="Enter Insurance Company Name">
                                                    <button type="button"
                                                        class="btn bg-voilet limos-button-background text-white p-2 ml-2"
                                                        (click)="onFieldReset('insurance_company')"><em
                                                            class="fa fas fa-refresh"></em></button>
                                                </div>
                                            </div>
                                            <div class="row mt-1">
                                                <div class="col-md-4">Claims Admin</div>
                                                <div class="col-md-8 d-flex">
                                                    <input type="text" max=10 formControlName="claims_admin"
                                                        class="form-control" placeholder="Enter Claims Admin">
                                                    <button type="button"
                                                        class="btn bg-voilet limos-button-background text-white p-2 ml-2"
                                                        (click)="onFieldReset('claims_admin')"><em
                                                            class="fa fas fa-refresh"></em></button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="row justify-content-end mt-1">
                            <div class="col-md-auto align-items-end">
                                <button class="btn bg-voilet limos-button-background text-white  px-3 py-2 pr-3 pl-3"
                                    (click)="onReset()"><em class="fa fas fa-refresh mr-3"></em>Reset</button>
                                <button
                                    class="btn bg-voilet limos-button-background text-white  ml-2 px-3 py-2 pr-3 pl-3"
                                    (click)="onSubmit('false')"><em class="fa fas fa-search mr-3"></em> Search</button>
                            </div>
                        </div>
                        <div *ngIf="emptyFormMessage">
                            <h6 class="text-center">Please put or select value of any field</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="isSearched">
            <div class="col-md-12">
                <div class="card mt-2 border-0">
                    <div class="card-body">
                        <div class="container-fluid p-0" *blockUI="'manage-users-blockUI'">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="w-100">
                                        <div class="row mb-xl-3 justify-content-between align-items-center">
                                            <div class="col-auto">
                                                <h3 class="page-title">Search result list</h3>
                                            </div>
                                            <div>
                                                <button
                                                    class="btn btn-secondary-outline bg-white text-voilet p-2 mr-2  btn-sm"
                                                    (click)="downloadCSV()">Download CSV
                                                </button>
                                                <button
                                                    class="btn btn-secondary-outline bg-white text-voilet p-2 mr-3  btn-sm"
                                                    (click)="onSubmit('true')">Export All To CSV
                                                </button>
                                            </div>
                                        </div>
                                        <div class="table-row">
                                            <table
                                                class="table providerTable limos-table-header-background table-row cnstr-record product-tbl">
                                                <caption>Data Table</caption>
                                                <thead>
                                                    <tr>
                                                        <th class="border-0" scope="col" sortable="wc_claim_number"
                                                            (sort)="onSort($event)">Claim Number</th>
                                                        <th class="border-0" scope="col"
                                                            sortable="injured_worker_last_first"
                                                            (sort)="onSort($event)">
                                                            Claimant Name</th>
                                                        <th class="border-0" scope="col" sortable="spear_id_prefix"
                                                            (sort)="onSort($event)">
                                                            Source</th>
                                                        <!-- <th class="border-0" scope="col"
                                                            sortable="bill_review_client_name" (sort)="onSort($event)">
                                                            Client</th> -->
                                                        <th class="border-0" scope="col"
                                                            sortable="provider_name_business" (sort)="onSort($event)">
                                                            Provider Name</th>
                                                        <th class="border-0" scope="col"
                                                            sortable="bill_review_carrier_name" (sort)="onSort($event)">
                                                            Carrier</th>
                                                    </tr>
                                                </thead>
                                                <!-- for empty tr-->
                                                <!-- <tr class="bg-white" *ngIf="!providers || providers.length === 0">
                                                    <td colspan="12" class="text-center">No Records Found</td>
                                                </tr> -->
                                                <tbody *ngIf="!providers || providers.length<1">
                                                    <!-- for filled tr -->
                                                    <!-- for empty tr-->
                                                    <tr class="bg-white">
                                                        <td colspan="12" class="text-center">No Records Found</td>
                                                    </tr>
                                                <tbody *ngIf="providers.length>0">
                                                    <tr class="bg-white" *ngFor="let provider of providers; index as i">
                                                        <td class="cursor-pointer primary-text font-weight-semibold"
                                                            (click)="toLiens(provider?.wc_claim_number,provider?.id)">
                                                            <span
                                                                [innerHTML]="(provider?.wc_claim_number ? provider?.wc_claim_number : '&#8212;')"></span>
                                                        </td>
                                                        <td class="table-max-width"
                                                            [innerHTML]="(provider?.injured_worker_last_first ? provider?.injured_worker_last_first : '&#8212;') | formatclaimant">
                                                        </td>
                                                        <td class="table-max-width"
                                                            [innerHTML]="(provider?.spear_id_prefix ? provider?.spear_id_prefix : '&#8212;')">
                                                        </td>
                                                        <!-- <td class="table-max-width"
                                                            [innerHTML]="(provider?.bill_review_client_name ? provider?.bill_review_client_name : '&#8212;')">
                                                        </td> -->
                                                        <td class="table-max-width"
                                                            [innerHTML]="(provider?.provider_name_business ? provider?.provider_name_business : '&#8212;')">
                                                        </td>
                                                        <td class="table-max-width"
                                                            [innerHTML]="(provider?.bill_review_carrier_name ? provider?.bill_review_carrier_name : '&#8212;')">
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="d-flex px-0 py-0 paginationWrapper"
                                                *ngIf="!(!providers || providers.length === 0)">
                                                <ngb-pagination [(collectionSize)]="providerSize" [(page)]="page"
                                                    [pageSize]="limit" [maxSize]="10" [boundaryLinks]="true"
                                                    (pageChange)="onPageChange($event)">
                                                </ngb-pagination>
                                                <div class="selected d-flex align-items-center">
                                                    <select (change)="onSizeChange(limit)"
                                                        class="custom-select table-select" style="width: auto"
                                                        [(ngModel)]="limit">
                                                        <option [ngValue]="10">10</option>
                                                        <option [ngValue]="15">15</option>
                                                        <option [ngValue]="20">20</option>
                                                        <option [ngValue]="50">50</option>
                                                        <option [ngValue]="100">100</option>
                                                    </select>
                                                    <p class="mb-0 ml-2">Showing {{ ((pageIndex * limit) - limit) + 1 }}
                                                        -
                                                        {{ pageIndex * limit > providerSize ? providerSize : pageIndex *
                                                        limit }} of {{ providerSize }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
