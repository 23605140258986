export class Template {
    templateId: number;
    templateName: string;
    fields: string[];

    constructor() {
        this.templateName = '';
        this.templateId = 0;
        this.fields = [];
    }
}
