import { ToastrService } from 'ngx-toastr';
import { AppUtils } from './../../utilities/app.utils';
import { Component, EventEmitter, OnInit, Output  } from '@angular/core';
import { AddTemplate } from 'src/app/models/provider-filter/addTemplate';
import { ManageFields } from 'src/app/models/provider-filter/manageFields';
import { ManageTemplatesService } from 'src/app/services/manage.template.service';
import { Field } from 'src/app/models/provider-filter/field';
import { Template } from 'src/app/models/provider-filter/template';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.css']
})
export class RightSidebarComponent implements OnInit {
  searchText: string;
  isDisabled = true;
  setClasses = new Set();
  classSet =  new Set();
  addTemplate: AddTemplate;
  manageFields: ManageFields;
  fields: any[];
  @Output() closeRightSideBar = new EventEmitter();
  @Output() getTemplates = new EventEmitter();

  constructor(private manageTemplateService: ManageTemplatesService, private appUtils: AppUtils,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.addTemplate = new AddTemplate();
    this.manageFields = new ManageFields();
    this.searchText = '';
  }

  // tslint:disable-next-line: typedef
  select(fieldName: string) {
    if (this.addTemplate.fields.includes(fieldName)){
      this.addTemplate.fields.splice(this.addTemplate.fields.findIndex(x => x === fieldName), 1);
    }
    else {
    this.addTemplate.fields.push(fieldName);
    }
    this.isDisabled = false;
  }

  // tslint:disable-next-line: typedef
  openFilterModal() {
    this.setClasses.add('show');
  }

  // tslint:disable-next-line: typedef
  closeFilterModal() {
    this.manageTemplateService.SelectedTemplate = new Template();
    this.manageTemplateService.SelectedTemplate.fields = this.addTemplate.fields;
    this.manageTemplateService.SelectedTemplate.templateName = 'Default View';
    this.manage();
    this.isDisabled = true;
    //this.resetAddTemplate();
    this.closeRightSideBar.emit(null);
    this.setClasses.delete('show');
  }

  // tslint:disable-next-line: typedef
  openTemplate(){
    this.addTemplate.templateName = '';
    this.classSet.add('show');
  }

  // tslint:disable-next-line: typedef
  closeTemplate(){
    //this.resetAddTemplate();
    this.classSet.delete('show');
  }

  saveTemplate() {
    if (!(this.addTemplate.templateName.trim() === '' )) {
      this.manageTemplateService.addTemplate(this.addTemplate).subscribe(data => {
          this.appUtils.ProcessCustomResponse(this.toastr, !data.success, data.message);
          if (data.success) {
            let selectedTempalte = new Template();
            selectedTempalte.templateName = this.addTemplate.templateName;
            selectedTempalte.fields = this.addTemplate.fields;
            this.applyTemplate(selectedTempalte);
            this.isDisabled = true;
            this.closeRightSideBar.emit(null);
            this.setClasses.delete('show');
            this.classSet.delete('show');
            this.closeRightSideBar.emit(null);
            this.getTemplates.emit(null);
          }
        },
        error => {
          this.appUtils.ProcessErrorResponse(this.toastr, error);
        });
    }
    else {
      this.appUtils.ProcessCustomResponse(this.toastr, true, 'Please enter template name');
    }
  }

  manage(): void {
    this.fields = this.manageTemplateService.Fields;
    if (this.fields === undefined) {
      return null;
    }
    this.manageFields.allFields = [];
    this.fields.forEach(field => {
      this.manageFields.allFields.push(new Field(false, field.name));
    });
    this.manageTemplateService.SelectedTemplate.fields.forEach(field => {
      this.manageFields.allFields[this.fields.findIndex(x => x.name === field)].selected = true;
    });
    this.addTemplate.fields = Object.assign([], this.manageTemplateService.SelectedTemplate.fields);

  }
  resetAddTemplate() {
    this.addTemplate.fields = [];
    this.addTemplate.templateName = '';
  }
  applyTemplate(selectedTempalte: Template) : void {
    this.addTemplate = new AddTemplate();
    this.addTemplate.fields = selectedTempalte.fields;

    this.manageTemplateService.SelectedTemplate = new Template();
    this.manageTemplateService.SelectedTemplate.fields = selectedTempalte.fields;
    this.manageTemplateService.SelectedTemplate.templateName = selectedTempalte.templateName;
    this.manage();
  }
}