import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { CurrentUserProfile } from '../models/account/current.user.profile';
@Injectable()
export class AuthService {

    private userAuthenticationSubject = new Subject<boolean>();

    private userRole: string;

    private UserInfoSubject = new Subject<any>();

    private userInfo = new Subject<any>();

    notifyToSetUserInfo = (info: any) => this.userInfo.next(info);

    listenToSetUserInfo = (): Observable<any> => this.userInfo.asObservable();

    //setRememberMe = (value: boolean) => localStorage.setItem('rememberMe', value.toString());

    //isRememberMe = () => localStorage.getItem('rememberMe') === 'true';

    notifyUserAuthenticationStatus = (status: boolean) => this.userAuthenticationSubject.next(status);

    notifyToLoadCurrentUserProfile = () => this.UserInfoSubject.next();

    listenToLoadCurrentUserProfile = (): Observable<any> => this.UserInfoSubject.asObservable();
    listenToUserAuthenticationStatus = (): Observable<boolean> => this.userAuthenticationSubject.asObservable();

    storeToken(data: string): void {
        // if (this.isRememberMe()) {
        //     localStorage.setItem('AuthToken', data);
        // } else {
        //     sessionStorage.setItem('AuthToken', data);
        // }
        localStorage.setItem('AuthToken', data);
    }
    isAuthenticated(): boolean {
        if (this.getToken() != null) {
            return true;
        }
        return false
    }

    getToken(): string {
        // if (this.isRememberMe()) {
        //     return localStorage.getItem('AuthToken');
        // } else {
        //     return sessionStorage.getItem('AuthToken');
        // }
        return localStorage.getItem('AuthToken');
    }

    clearStorage(): void {
        localStorage.clear();
        sessionStorage.clear();
    }
    setUserRole = (value: string) => this.userRole = value;
    getUserRole = () => this.userRole;

}
