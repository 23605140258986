<i
  class="fa fa-sort-up"
  *ngIf="orderBy == columnName && orderDirection == 'asc'"
></i>
<i
  class="fa fa-sort-down"
  *ngIf="orderBy == columnName && orderDirection == 'desc'"
></i>
<ng-container *ngIf="orderBy != columnName">
  <i class="fa fa-sort-up"></i>
  <i class="fa fa-sort-down"></i>
</ng-container>
