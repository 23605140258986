export class Profile {
    //address1: string;
    //paycode?: any;
    //address2?: any;
    //city: string;
    // lastname: string;
    // middlename: string;
    // firstname: string;
    //state: string;
    //zipcode: string;

    details: {
        spear_id: string;
        spear_id_prefix: string;
        row_hash: string;
        date_record_created_at_source: string;
        date_record_modified_at_source: string;
        data_source: string;
        entity_type_code: number;
        is_merged: boolean;
        case_status: string;
        case_number: string;
        employer: string;
        insurance_company: string;
        injured_worker_full_name: string;
        injured_worker_first_name: string;
        injured_worker_middle_name_1: string;
        injured_worker_middle_name_2: string;
        injured_worker_last_name: string;
        injured_worker_dob: string;
        injured_worker_doi: string;
        law_firms: string[];
        court_case_name: string;
        court_case_number: string;
        wcab_next_hearing_date: string;
        wcab_next_hearing_location: string;
        wcab_next_hearing_type: string;
        wcab_judge: string;
        claim_administrator_1: string;
        claim_administrator_2: string;
        dwc_office: string;
        dwc_proceeding_status: string;
        lien_amount: number;
        lien_paid: string;
        lien_paid_date: string;
        lien_status_updated: string;
        lien_most_recent_event: string;
        lien_most_recent_event_description: string;
        lien_disposition: string;
        lien_disposition_date: string;
        lien_created: string;
        lien_updated: string;
        suspended_date: string;
        suspension_duration: string;
        medical_licence_numbers: {};
        medical_licence_states: {};
        license_status: string;
        provider_name_entity: string;
        category_code: string;
        provider_name_person: string;
        provider_alias_person: string;
        title_or_position: string;
        official_first_name: string;
        official_middle_name: string;
        official_last_name: string;
        provider_phone_number_person: string;
        gender: string;
        suspall_provider_number_1: string;
        suspall_provider_number_2: string;
        suspall_provider_number_3: string;
        suspall_provider_number_4: string;
        suspall_provider_number_5: string;
        suspall_provider_number_6: string;
        suspall_provider_number_7: string;
        suspall_provider_number_8: string;
        suspall_provider_number_9: string;
        suspall_provider_number_10: string;
        suspall_provider_number_11: string;
        provider_entity_address_street: string;
        provider_entity_address_city: string;
        provider_entity_address_state: string;
        provider_entity_address_zip: string;
        provider_street_address_1: string;
        provider_street_address_2: string;
        provider_city: string;
        provider_state: string;
        provider_zip: string;
        provider_phone: string;
        provider_fax: string;
        provider_street_address_1_practice: string;
        provider_street_address_2_practice: string;
        provider_city_practice: string;
        provider_state_practice: string;
        provider_zip_practice: string;
        provider_phone_practice: string;
        provider_fax_practice: string;
        provider_npi_number: string;
        npi_first_listed_date: string;
        npi_active: boolean;
        npi_deactivation_date: string;
        npi_reactivation_date: string;
        business_owner: string;
        provider_name_business: string;
        provider_alias_business: string;
        provider_category: string;
        business_contact: string;
        consolidation: string;
        po_code: number;
        pay_code_status: string;
        authority: string;
        pay_code_rule: string;
        state_code: string;
        state_bill_objection_language: string;
        narrative_reference: string;
        lastname: string;
        middlename: string;
        firstname: string;
    }
}

export class MergeProfile {
    details: {
        entity_type_code: number;
        is_merged: boolean;
        case_status: string;
        case_number: string;
        employer: string;
        insurance_company: string;
        injured_worker_full_name: string;
        injured_worker_first_name: string;
        injured_worker_middle_name_1: string;
        injured_worker_middle_name_2: string;
        injured_worker_last_name: string;
        injured_worker_dob: string;
        injured_worker_doi: string;
        law_firms: string[];
        court_case_name: string;
        court_case_number: string;
        wcab_next_hearing_date: string;
        wcab_next_hearing_location: string;
        wcab_next_hearing_type: string;
        wcab_judge: string;
        claim_administrator_1: string;
        claim_administrator_2: string;
        dwc_office: string;
        dwc_proceeding_status: string;
        lien_amount: number;
        lien_paid: string;
        lien_paid_date: string;
        lien_status_updated: string;
        lien_most_recent_event: string;
        lien_most_recent_event_description: string;
        lien_disposition: string;
        lien_disposition_date: string;
        lien_created: string;
        lien_updated: string;
        suspended_date: string;
        suspension_duration: string;
        medical_licence_numbers: {};
        medical_licence_states: {};
        license_status: string;
        provider_name_entity: string;
        category_code: string;
        provider_name_person: string;
        provider_alias_person: string;
        title_or_position: string;
        official_first_name: string;
        official_middle_name: string;
        official_last_name: string;
        provider_phone_number_person: string;
        gender: string;
        suspall_provider_number_1: string;
        suspall_provider_number_2: string;
        suspall_provider_number_3: string;
        suspall_provider_number_4: string;
        suspall_provider_number_5: string;
        suspall_provider_number_6: string;
        suspall_provider_number_7: string;
        suspall_provider_number_8: string;
        suspall_provider_number_9: string;
        suspall_provider_number_10: string;
        suspall_provider_number_11: string;
        provider_entity_address_street: string;
        provider_entity_address_city: string;
        provider_entity_address_state: string;
        provider_entity_address_zip: string;
        provider_street_address_1: string;
        provider_street_address_2: string;
        provider_city: string;
        provider_state: string;
        provider_zip: string;
        provider_phone: string;
        provider_fax: string;
        provider_street_address_1_practice: string;
        provider_street_address_2_practice: string;
        provider_city_practice: string;
        provider_state_practice: string;
        provider_zip_practice: string;
        provider_phone_practice: string;
        provider_fax_practice: string;
        provider_npi_number: string;
        npi_first_listed_date: string;
        npi_active: boolean;
        npi_deactivation_date: string;
        npi_reactivation_date: string;
        business_owner: string;
        provider_name_business: string;
        provider_alias_business: string;
        provider_category: string;
        business_contact: string;
        consolidation: string;
        po_code: number;
        pay_code_status: string;
        authority: string;
        pay_code_rule: string;
        state_code: string;
        state_bill_objection_language: string;
        narrative_reference: string;
        lastname: string;
        middlename: string;
        firstname: string;
        provider_address: string;
        practice_address: string;
        medical_licence: string[];
    }
}


export class ProviderProfileModel {
    profile: Profile;
    mergeProfile: MergeProfile;
}
