<div class="">
    <div class="modal-header px-0">
        <h4 class="modal-title" id="modal-basic-title border-0">Merge</h4>
        <button type="button" class="btn d-block ml-auto btnclose text-gray rounded-circle p-0" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>    
    <form>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-6 pl-0">
                    <div class="form-group mb-4">
                        <label class="loginLabel" for="select">Select Target Providers</label>
                        <select class="custom-select custom-select-lg userSelect">
                            <option value="undefined">Select</option>
                            <option value="provider one">Provider One</option>
                            <option value="provider two">Provider two</option>
                            <option value="provider three">Provider three</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6 pr-0">
                    <div class="form-group mb-4">
                        <label class="loginLabel" for="select">Select Reason</label>
                        <select class="custom-select custom-select-lg userSelect">
                            <option value="undefined">Select</option>
                            <option value="reason one">Reason One</option>
                            <option value="reason two">Reason two</option>
                            <option value="reason three">Reason three</option>
                        </select>
                    </div>
                </div>
                <div class="table-row w-100">
                    <table class="table providerTable">
                        <caption>Data Table</caption>
                        <thead>
                            <tr>
                                <th class="border-0 pl-0" scope="col">Provider Name</th>
                                <th class="border-0" scope="col">Provider ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white border-0 ">
                                <td class="primary-text font-weight-semibold pl-0">Montgomery </td>
                                <td>100,215,500</td>
                            </tr>
                            <tr class="bg-white border-0 ">
                                <td class="primary-text font-weight-semibold pl-0">Montgomery Inc</td>
                                <td>100,215,700</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0 p-0 mb-4">
            <button type="button" class="rounded-0 btn btn-secondary-outline bg-white text-voilet mb-0" (click)="activeModal.close('Save click')">Cancel</button>
            <button type="submit" class="rounded-0 btn text-white primary-bg mb-0" [disabled]="true">Save</button>
        </div>
    </form>
</div>