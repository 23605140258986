import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';
import { Field } from 'src/app/models/provider-filter/field';
import { ManageFields } from 'src/app/models/provider-filter/manageFields';
import { Template } from 'src/app/models/provider-filter/template';
import { UpdateTemplate } from 'src/app/models/provider-filter/updateTemplate';
import { UserTemplatesResponse } from 'src/app/models/provider-filter/userTemplatesResponse';
import { ManageTemplatesService } from 'src/app/services/manage.template.service';
import { AppUtils } from 'src/app/utilities/app.utils';
import { JsxFlags } from 'typescript';

@Component({
  selector: 'app-filter-templates',
  templateUrl: './filter-templates.component.html',
  styleUrls: ['./filter-templates.component.css']
})
export class FilterTemplatesComponent implements OnInit {
  fields: any[];
  manageFields: ManageFields;
  show = false;
  updatedFieldList: UpdateTemplate;
  classSet = new Set();
  userTemplates: UserTemplatesResponse;
  selectedItem: Template;
  searchText: string;
  template: Template;
  updatedTemplate: UpdateTemplate;
  @Output() closeManageModel = new EventEmitter();
  @Output() getTemplates = new EventEmitter();
  @Output() refreshTemplates = new EventEmitter();

  constructor(private manageTemplateService: ManageTemplatesService,
    private appUtils: AppUtils,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.fields = [];
    this.manageFields = new ManageFields();
    this.manageFields.allFields.push(new Field(false, ''));
    this.updatedFieldList = new UpdateTemplate();
    this.userTemplates = new UserTemplatesResponse();
    this.selectedItem = new Template();
    this.template = new Template();
    this.updatedTemplate = new UpdateTemplate();
    this.updatedFieldList.fields = [];
    this.searchText = '';
    this.getTemplate();
  }
  // tslint:disable-next-line: typedef
  tempChecked(fieldName): void {
    if (this.updatedFieldList.fields.includes(fieldName)) {
      this.updatedFieldList.fields.splice(this.updatedFieldList.fields.findIndex(x => x === fieldName), 1);
    }
    else {
      this.updatedFieldList.fields.push(fieldName);
    }
    this.Checked();
    this.updatedTemplate = Object.assign({}, this.updatedFieldList);
  }

  deleteTemplate = (): void => {
    this.manageTemplateService.deleteTemplate(this.selectedItem).subscribe(data => {
      this.appUtils.ProcessCustomResponse(this.toastr, !data.success, data.message);
      this.getTemplate();
      this.getAllTemplate();
      this.getTemplates.emit();
    });
  }

  manage = (template: Template): void => {
    this.template = new Template();
    this.template.fields = Object.assign([], template.fields);
    this.template.templateId = template.templateId;
    this.template.templateName = template.templateName;
    if (template === undefined) {
      this.appUtils.ProcessCustomResponse(this.toastr, true, 'No saved template available');
      return null;
    }
    this.manageFields.allFields = [];
    this.fields = this.manageTemplateService.Fields;
    this.fields.forEach(field => {
      this.manageFields.allFields.push(new Field(false, field.name));
    });
    this.template.fields.forEach(field => {
      this.manageFields.allFields[this.fields.findIndex(x => x.name === field)].selected = true;
    });
    this.updatedFieldList = new UpdateTemplate();
    this.updatedFieldList.fields = [];
    this.updatedFieldList =  Object.assign({}, this.selectedItem);
    this.updatedTemplate = Object.assign({},  this.selectedItem);
  }

  updateTemplate = () => {
    this.manageTemplateService.updateTemplate(this.updatedTemplate).subscribe(
      data => {
        this.appUtils.ProcessCustomResponse(this.toastr, !data.success, data.message);
        this.getTemplate();
        this.refreshTemplates.emit();
      },
      error => {
        this.appUtils.ProcessErrorResponse(this.toastr, error);
      });
    this.closeModel();
    this.show = false;

  }
  active = (template: Template) => {
    this.selectedItem = new Template();
    this.selectedItem.templateName = template.templateName;
    this.selectedItem.templateId = template.templateId;
    this.selectedItem.fields = Object.assign([], template.fields);
    this.manage(this.selectedItem);
    this.show = false;
  }
  Checked = () => {
    if (!this.show) {
      this.show = !this.show;
    }
  }

  closeModel = () => {
    this.show = false;
    this.closeManageModel.emit();
  }

  getTemplate = () => {
    this.manageTemplateService.getTemplates().subscribe(data => {
      this.userTemplates = JSON.parse(JSON.stringify(data));
      if (!this.userTemplates.success) {
        this.appUtils.ProcessCustomResponse(this.toastr, !this.userTemplates.success, this.userTemplates.message);
      }
      if (data.responseData.length !== 0) {
        this.selectedItem = JSON.parse(JSON.stringify(data.responseData[0]));
        this.updatedTemplate = JSON.parse(JSON.stringify(data.responseData[0]));
      }
      else {
        this.closeModel();
      }
    },
      error => {
        this.appUtils.ProcessErrorResponse(this.toastr, error);
      });
  }
  openTemplate = () => {
    this.classSet.add('show');
  }
  closeTemplate = () => {
    this.updatedTemplate = JSON.parse(JSON.stringify(this.selectedItem));
    this.classSet.delete('show');
  }
  saveRenameTemplate = () => {
    this.updatedTemplate.fields = [];
    if (this.updatedTemplate.templateName !== '') {
      this.manageTemplateService.updateTemplate(this.updatedTemplate).subscribe(
        data => {
          this.appUtils.ProcessCustomResponse(this.toastr, !data.success, data.message);
          this.getAllTemplate();
          if (data.success) {
            this.selectedItem.templateName = this.updatedTemplate.templateName;
            this.closeTemplate();
            this.show = false;
          }
        },
        error => {
          this.appUtils.ProcessErrorResponse(this.toastr, error);
        });
    }
    else {
      this.appUtils.ProcessCustomResponse(this.toastr, true, 'Template name is required');
    }
  }
  getAllTemplate = () => {
    this.manageTemplateService.getTemplates().subscribe(data => {
      this.userTemplates = JSON.parse(JSON.stringify(data));
      if (!this.userTemplates.success) {
        this.appUtils.ProcessCustomResponse(this.toastr, !this.userTemplates.success, this.userTemplates.message);
      }
      if (data.responseData.length !== 0) {
        this.active(this.selectedItem);
      }
      else {
        this.closeModel();
      }
    },
      error => {
        this.appUtils.ProcessErrorResponse(this.toastr, error);
      });
  }
// searching for template in edit modal
  searching = (text: string) => {
    let flag = false;
    if (text !== '') {
      this.userTemplates.responseData.forEach(temp => {
        if (!flag && temp.templateName.includes(text)) {
          this.selectedItem = temp;
          this.manage(this.selectedItem);
          flag = true;
        }
      });
    }
    else {
      this.selectedItem = Object.assign([], this.userTemplates.responseData[0]);
      this.manage(this.selectedItem);
      flag = true;
    }
    if (!flag) {
      this.manage(new Template());
      flag = false;
    }
  }

  showFields = (): boolean => {
    if ( this.template.fields.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }
}
