export class MergeRequestModel {
  case_details: CaseDetails;
  employer_details: EmployerDetails;
  insurance_details: InsuranceDetails;
  license_suspension_details: LicenseSuspensionDetails;
  medical_license_details: MedicalLicenseDetails;
  provider_details: ProviderDetails;
  uncategorized_details: UncategorizedDetails;
  profile_lookups: ProfileLookups;
  editable_fields: EditableFields;
  br_strata: BrStrata;
  mbc: Mbc;
  first_id: string;
  second_id: string;
  is_edited: boolean;
  row_hash: string;
  date_record_created_at_source: string;
  date_record_modified_at_source: string;
  data_source: string;
  entity_type_code: number;
  license_id_number: string;
}

export class CaseDetails {
  case_status: string;
  case_number: string;
}

export class EmployerDetails {
  employer: string;
}

export class InsuranceDetails {
  injury_details: InjuryDetails;
  law_firms_information: LawFirmsInformation;
  court_details: CourtDetails;
  wcab_details: WcabDetails;
  claim_details: ClaimDetails;
  dwc_details: DwcDetails;
  lien_details: LienDetails;
  insurance_company: string;
}

export class InjuryDetails {
  injured_worker_last_first: string;
  injured_worker_full_name: string;
  injured_worker_first_name: string;
  injured_worker_middle_name_1: string;
  injured_worker_middle_name_2: string;
  injured_worker_last_name: string;
  injured_worker_dob: string;
  injured_worker_doi: string;
}

export class LawFirmsInformation {
  law_firms: string[];
}

export class CourtDetails {
  court_case_name: string;
  court_case_number: string;
}

export class WcabDetails {
  wcab_next_hearing_date: string;
  wcab_next_hearing_location: string;
  wcab_next_hearing_type: string;
  wcab_judge: string;
}

export class ClaimDetails {
  claim_administrator_1: string;
  claim_administrator_2: string;
}

export class DwcDetails {
  dwc_office: string;
  dwc_proceeding_status: string;
}

export class LienDetails {
  lien_amount: number;
  lien_paid: string;
  lien_paid_date: string;
  lien_status_updated: string;
  lien_most_recent_event: string;
  lien_most_recent_event_description: string;
  lien_disposition: string;
  lien_disposition_date: string;
  limos_status: string;
  lien_created: string;
  lien_updated: string;
}

export class LicenseSuspensionDetails {
  suspended_date: string;
  suspension_duration: string;
}

export class MedicalLicenseDetails {
  medical_licence_numbers: MedicalLicenceNumbers;
  medical_licence_states: MedicalLicenceStates;
  license_status: string;
  license_issue_date: string;
  license_expiration_date: string;
  license_type: string;
}

export class MedicalLicenceNumbers {}

export class MedicalLicenceStates {}

export class ProviderDetails {
  suspall_provider_numbers: SuspallProviderNumbers;
  provider_contact_details: ProviderContactDetails;
  provider_npi_details: ProviderNpiDetails;
  provider_business_details: ProviderBusinessDetails;
  provider_name_entity: string;
  category_code: number;
  provider_name_person: string;
  provider_alias_person: string;
  title_or_position: string;
  official_first_name: string;
  official_middle_name: string;
  official_last_name: string;
  provider_phone_number_person: string;
  gender: string;
}

export class SuspallProviderNumbers {
  suspall_provider_number_1: string;
  suspall_provider_number_2: string;
  suspall_provider_number_3: string;
  suspall_provider_number_4: string;
  suspall_provider_number_5: string;
  suspall_provider_number_6: string;
  suspall_provider_number_7: string;
  suspall_provider_number_8: string;
  suspall_provider_number_9: string;
  suspall_provider_number_10: string;
  suspall_provider_number_11: string;
}

export class ProviderContactDetails {
  provider_entity_address_street: string;
  provider_entity_address_city: string;
  provider_entity_address_state: string;
  provider_entity_address_zip: string;
  provider_street_address_1: string;
  provider_street_address_2: string;
  provider_city: string;
  provider_state: string;
  provider_zip: string;
  provider_phone: string;
  provider_fax: string;
  provider_street_address_1_practice: string;
  provider_street_address_2_practice: string;
  provider_city_practice: string;
  provider_state_practice: string;
  provider_zip_practice: string;
  provider_phone_practice: string;
  provider_fax_practice: string;
  full_address_string_note: string;
}

export class ProviderNpiDetails {
  provider_npi_number: number;
  npi_first_listed_date: string;
  npi_active: boolean;
  npi_deactivation_date: string;
  npi_reactivation_date: string;
}

export class ProviderBusinessDetails {
  business_owner: string;
  provider_name_business: string;
  provider_alias_business: string;
  provider_category: string;
  business_contact: string;
}

export class UncategorizedDetails {
  consolidation: string;
}

export class ProfileLookups {
  po_code: number;
  pay_code_status: string;
  authority: string;
  pay_code_rule: string;
  state_code: string;
  state_bill_objection_language: string;
  narrative_reference: string;
  primary_status_code: number;
  license_status_description_short: string;
  license_status_description_long: string;
}

export class EditableFields {
  tax_id_number: string;
  lien_type: string;
  lien_request_date: string;
  lien_created: string;
  lien_requested_amount: number;
  lien_resolved_date: string;
  lien_resolved_amount: number;
}

export class BrStrata {
  bill_review_ap: BillReviewAp;
  bill_review_insurer: BillReviewInsurer;
  bill_review_claim: BillReviewClaim;
  bill_review_client: BillReviewClient;
  bill_review_control: BillReviewControl;
  bill_review_br: BillReviewBr;
  bill_review_service: BillReviewService;
  bill_review_billing_type: string;
  bill_review_bill_entry_date: string;
  bill_review_bill_icd_diagnosis_01: string;
  bill_review_bill_icd_diagnosis_02: string;
  bill_review_bill_icd_diagnosis_03: string;
  bill_review_bill_icd_diagnosis_04: string;
  bill_review_bill_icd_procedure_01: string;
  bill_review_bill_icd_procedure_02: string;
  bill_review_jurisdiction_state: string;
  bill_review_medicare_number: string;
  bill_review_bill_ocr_dcn: string;
  bill_review_patient_account_number: string;
  bill_review_physician_rendering_license_number: string;
  bill_review_physician_rendering_name: string;
  bill_review_physician_rendering_npi: number;
  bill_review_provider_billing_npi: number;
  bill_review_provider_referring_npi: number;
  bill_review_provider_rendering_npi: number;
  bill_review_provider_specialty: string;
  bill_review_reimbursement_method: string;
  bill_review_method: string;
  bill_review_bill_status: string;
  bill_review_UB_form_bill_type: string;
  bill_review_payment_transfer_date: string;
  bill_review_policy_number: string;
  bill_review_ppo_osr: string;
  bill_review_quantity: number;
  bill_review_total_charges: number;
  bill_review_billed_to_ppo_osr: number;
  bill_review_fs_allowance: number;
  bill_review_total_br_allowance: number;
  bill_review_ppo_osr_allowance: number;
  bill_review_total_allowance: number;
}

export class BillReviewAp {
  bill_review_ap_check_number: number;
  bill_review_ap_paid_date: string;
}

export class BillReviewInsurer {
  bill_review_carrier_code: string;
  bill_review_carrier_name: string;
  bill_review_carrier_tx_id: string;
}

export class BillReviewClaim {
  jurisdiction: string;
  bill_review_adjuster_name: string;
  wc_claim_type: string;
  wc_claim_client_status: string;
  bill_review_claim_nonsubscriber: string;
  bill_review_claim_number_jurisdiction: string;
  wc_claim_number: string;
  wc_claim_status: string;
}

export class BillReviewClient {
  bill_review_client_id: string;
  bill_review_client_name: string;
}

export class BillReviewControl {
  bill_review_control_number: string;
  bill_review_control_number_original: string;
}

export class BillReviewBr {
  bill_review_br_process_date: string;
  bill_review_br_complete_date: string;
  bill_review_br_received_date: string;
}

export class BillReviewService {
  bill_review_service_days_supply: string;
  bill_review_service_drug_category: string;
  bill_review_service_drug_compound_indicator: string;
  bill_review_service_duplicate_status: string;
  bill_review_service_is_refill: string;
  bill_review_service_line_number: number;
  bill_review_service_modifier_1: string;
  bill_review_place_of_service: string;
  bill_review_prescriber_name: string;
  bill_review_prescriber_npi: number;
  bill_review_service_prescription_number: number;
  bill_review_type_of_service: string;
  bill_review_service_class: string;
  bill_review_service_code_original: string;
  bill_review_service_code_description: string;
  bill_review_service_code_value: string;
  bill_review_service_count: number;
  bill_review_service_date: string;
  bill_review_service_from_date: string;
  bill_review_service_group: string;
  bill_review_service_through_date: string;
}

export class Mbc {
  felony_court: string;
  misdemeanor_court: string;
  description_of_action: string;
  effective_date_of_action: string;
  docket: string;
  sentence: string;
}

export class MergeData {
  spear_id_firestore: string = '';
  spear_id_prefix: string = '';
  row_hash: string = '';
  date_record_created_at_firestore: string = '';
  date_record_modified_at_source: string = '';
  date_record_created_at_source: string = '';
  data_source: string = '';
  entity_type_code: number;
  provider_name_entity: string = '';
  category_code: string = '';
  provider_name_person: string = '';
  provider_alias_person: string = '';
  title_or_position: string = '';
  provider_phone_number_person: string = '';
  suspall_provider_number_1: string = '';
  suspall_provider_number_2: string = '';
  suspall_provider_number_3: string = '';
  suspall_provider_number_4: string = '';
  suspall_provider_number_5: string = '';
  suspall_provider_number_6: string = '';
  suspall_provider_number_7: string = '';
  suspall_provider_number_8: string = '';
  suspall_provider_number_9: string = '';
  suspall_provider_number_10: string = '';
  suspall_provider_number_11: string = '';
  gender: string = '';
  medical_license_number: any = {};
  medical_license_state: any = {};
  license_status: string = '';
  provider_npi_number: string = '';
  npi_first_listed_date: string = '';
  npi_active: string = '';
  npi_deactivation_date: string = '';
  npi_reactivation_date: string = '';
  provider_name_business: string = '';
  provider_alias_business: string = '';
  provider_category: string = '';
  business_owner: string = '';
  business_contact: string = '';
  suspended_date: string = '';
  suspension_duration: string = '';
  provider_entity_address_street: string = '';
  provider_entity_address_city: string = '';
  provider_entity_address_state: string = '';
  provider_entity_address_zip: string = '';
  provider_street_address1: string = '';
  provider_street_address2: string = '';
  provider_city: string = '';
  provider_state: string = '';
  provider_zip: string = '';
  provider_phone: string = '';
  provider_fax: string = '';
  provider_street_address1_practice: string = '';
  provider_street_address2_practice: string = '';
  provider_city_practice: string = '';
  provider_state_practice: string = '';
  provider_zip_practice: string = '';
  provider_phone_practice: string = '';
  provider_fax_practice: string = '';
  case_status: string = '';
  case_number: string = '';
  lien_amount: number;
  employer: string = '';
  insurance_company: string = '';
  injured_worker_full_name: string = '';
  injured_worker_first_name: string = '';
  injured_worker_middle_name1: string = '';
  injured_worker_middle_name2: string = '';
  injured_worker_last_name: string = '';
  injured_worker_dob: string = '';
  injured_worker_doi: string = '';
  dwc_office: string = '';
  law_firm1: string = '';
  law_firm2: string = '';
  law_firm3: string = '';
  law_firm4: string = '';
  law_firm5: string = '';
  law_firm6: string = '';
  law_firm7: string = '';
  law_firm8: string = '';
  law_firm9: string = '';
  law_firm10: string = '';
  law_firm11: string = '';
  law_firm12: string = '';
  law_firm13: string = '';
  law_firm14: string = '';
  law_firm15: string = '';
  law_firm16: string = '';
  law_firm17: string = '';
  law_firm18: string = '';
  law_firm19: string = '';
  law_firm20: string = '';
  lien_created: string = '';
  lien_updated: string = '';
  lien_paid: string = '';
  lien_paid_date: string = '';
  lien_status_updated: string = '';
  consolidation: string = '';
  dwc_proceeding_status: string = '';
  lien_most_recent_event: string = '';
  lien_most_recent_event_description: string = '';
  wcab_next_hearing_date: string = '';
  wcab_next_hearing_location: string = '';
  wcab_next_hearing_type: string = '';
  wcab_judge: string = '';
  lien_disposition: string = '';
  lien_disposition_date: string = '';
  claim_administrator1: string = '';
  claim_administrator2: string = '';
  court_case_name: string = '';
  court_case_number: string = '';
  po_code: number;
  pay_code_status: string = '';
  authority: string = '';
  pay_code_rule: string = '';
  state_code: string = '';
  state_bill_objection_language: string = '';
  narrative_reference: string = '';
  merge_count: number;
  merge: string[];
  first_id: string = '';
  second_id: string = '';
}

export class SearchData {
  spear_id_firestore: string = '';
  spear_id_prefix: string = '';
  row_hash: string = '';
  date_record_created_at_firestore: string = '';
  date_record_modified_at_source: string = '';
  date_record_created_at_source: string = '';
  data_source: string = '';
  entity_type_code: number;
  provider_name_entity: string = '';
  category_code: string = '';
  provider_name_person: string = '';
  provider_alias_person: string = '';
  title_or_position: string = '';
  provider_phone_number_person: string = '';
  suspall_provider_number_1: string = '';
  suspall_provider_number_2: string = '';
  suspall_provider_number_3: string = '';
  suspall_provider_number_4: string = '';
  suspall_provider_number_5: string = '';
  suspall_provider_number_6: string = '';
  suspall_provider_number_7: string = '';
  suspall_provider_number_8: string = '';
  suspall_provider_number_9: string = '';
  suspall_provider_number_10: string = '';
  suspall_provider_number_11: string = '';
  gender: string = '';
  medical_license_number: any = {};
  medical_license_state: any = {};
  license_status: string = '';
  provider_npi_number: string = '';
  npi_first_listed_date: string = '';
  npi_active: string = '';
  npi_deactivation_date: string = '';
  npi_reactivation_date: string = '';
  provider_name_business: string = '';
  provider_alias_business: string = '';
  provider_category: string = '';
  business_owner: string = '';
  business_contact: string = '';
  suspended_date: string = '';
  suspension_duration: string = '';
  provider_entity_address_street: string = '';
  provider_entity_address_city: string = '';
  provider_entity_address_state: string = '';
  provider_entity_address_zip: string = '';
  provider_street_address1: string = '';
  provider_street_address2: string = '';
  provider_city: string = '';
  provider_state: string = '';
  provider_zip: string = '';
  provider_phone: string = '';
  provider_fax: string = '';
  provider_street_address1_practice: string = '';
  provider_street_address2_practice: string = '';
  provider_city_practice: string = '';
  provider_state_practice: string = '';
  provider_zip_practice: string = '';
  provider_phone_practice: string = '';
  provider_fax_practice: string = '';
  case_status: string = '';
  case_number: string = '';
  lien_amount: number;
  employer: string = '';
  insurance_company: string = '';
  injured_worker_full_name: string = '';
  injured_worker_first_name: string = '';
  injured_worker_middle_name1: string = '';
  injured_worker_middle_name2: string = '';
  injured_worker_last_name: string = '';
  injured_worker_dob: string = '';
  injured_worker_doi: string = '';
  dwc_office: string = '';
  law_firm1: string = '';
  law_firm2: string = '';
  law_firm3: string = '';
  law_firm4: string = '';
  law_firm5: string = '';
  law_firm6: string = '';
  law_firm7: string = '';
  law_firm8: string = '';
  law_firm9: string = '';
  law_firm10: string = '';
  law_firm11: string = '';
  law_firm12: string = '';
  law_firm13: string = '';
  law_firm14: string = '';
  law_firm15: string = '';
  law_firm16: string = '';
  law_firm17: string = '';
  law_firm18: string = '';
  law_firm19: string = '';
  law_firm20: string = '';
  lien_created: string = '';
  lien_updated: string = '';
  lien_paid: string = '';
  lien_paid_date: string = '';
  lien_status_updated: string = '';
  consolidation: string = '';
  dwc_proceeding_status: string = '';
  lien_most_recent_event: string = '';
  lien_most_recent_event_description: string = '';
  wcab_next_hearing_date: string = '';
  wcab_next_hearing_location: string = '';
  wcab_next_hearing_type: string = '';
  wcab_judge: string = '';
  lien_disposition: string = '';
  lien_disposition_date: string = '';
  claim_administrator1: string = '';
  claim_administrator2: string = '';
  court_case_name: string = '';
  court_case_number: string = '';
  po_code: number;
  pay_code_status: string = '';
  authority: string = '';
  pay_code_rule: string = '';
  state_code: string = '';
  state_bill_objection_language: string = '';
  narrative_reference: string = '';
  merge_count: number;
  merge: string[];
}
