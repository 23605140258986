<form class="h-100">
    <div class="modal-dialog modal-dialog-centered modal-lg-dialog">
      <div class="modal-content border-0 rounder-3 p-4">
        <div class="modal-header pt-0 px-0 mb-5">
          <h4 class="modal-title">Manage Columns</h4>
          <button class="btn d-block ml-auto btnclose text-gray rounded-circle p-0" (click)="closeModel()">
            <em class="fa fa-close"></em>
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="container px-0">
            <div class="row">
              <div class="col-md-2 mb-3 tab-border">
                <perfect-scrollbar class="manage-listScroll">
                  <ul class="nav nav-pills flex-column" id="myTab" role="tablist"
                    *ngFor="let i = index; let item of userTemplates.responseData | filterPipe: searchText: 'templateName';">
                    <p *ngIf="userTemplates.responseData.length === 0 ">No record found</p>
                    <li class="nav-item">
                      <a class="nav-link " [ngClass]="{'active': selectedItem.templateId === item.templateId}"
                        aria-selected="true" id="Template-one_{{i}}" data-toggle="tab" href="#Template{{i}}"
                        (click)="active(item)" role="tab" aria-controls="Template1"
                        aria-selected="true">{{item.templateName}}<em class="fa fa-long-arrow-right text-white ml-3"></em></a>
                    </li>
                  </ul>
                </perfect-scrollbar>
              </div>
              <div class="col-md-10" id="manageTemplateContent">
                <div class="row mb-2 mb-xl-3 justify-content-between align-items-center">
                  <div class="col-auto">
                    <h3 class="content-title">Tables Included</h3>
                  </div>
                  <div class="col-auto btnGrp pr-0">
                    <form class="form-inline d-sm-inline-block col-md-6">
                      <div class="input-group input-group-navbar">
                        <div class="input-group-append">
                          <button type="button" class="btn rounded-left search-btn bg-transparent"><em
                              class="fa fa-search"></em></button>
                        </div>
                        <input type="text" placeholder="Search Info.." aria-label="Search" placeholder="Search here..."
                          class="form-control pl-0 border-0 bg-transparent" [(ngModel)]="searchText"
                          [ngModelOptions]="{standalone: true}" (input)="searching($event.target.value)">
                      </div>
                    </form>
                    <button class="btn btn-secondary-outline bg-white text-voilet p-2 rounded-0" data-toggle="modal"
                      data-target="renameTemplate" (click)=openTemplate()><em class="fa fa-pencil mr-2"></em>Rename</button>
                    <button class="btn btn--primary-outline bg-white primary-text p-2 ml-3 rounded-0"
                      (click)="deleteTemplate()"><em class="fa fa-trash mr-2"></em>Delete Template</button>
                  </div>
                </div>
                <div class="tab-content tab-height pt-4 mb-4" id="myTabContent">
                  <perfect-scrollbar>
                    <p  *ngIf="!showFields()">No record found</p>
                    <div class="tab-pane fade show active" id="Template1" role="tabpanel" aria-labelledby="Template1"  *ngIf="showFields()">
                      <div class="form-check form-check-inline row" style="display: -webkit-box;">
                        <div class="col-3 mb-3"
                          *ngFor="let i = index;let item of manageFields.allFields">
                          <div class="custom-control custom-checkbox checkbox-width">
                            <input type="checkbox" class="custom-control-input" id="customCheck_{{i}}"
                              [checked]="item.selected" (click)="tempChecked(item.field)">
                            <label class="custom-control-label control-custom-primary"
                              for="customCheck_{{i}}">{{item.field}}</label>
                          </div>
  
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="Template2" role="tabpanel" aria-labelledby="Template2">
                    </div>
                    <div class="tab-pane fade" id="Template3" role="tabpanel" aria-labelledby="Template3">
                    </div>
                    <div class="tab-pane fade" id="Template4" role="tabpanel" aria-labelledby="Template4">
                    </div>
                  </perfect-scrollbar>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 p-0" *ngIf="show">
          <button type="button" class="rounded-0 btn btn-secondary-outline bg-white text-voilet mb-0 mr-3"
            data-dismiss="modal" (click)="closeModel()">Cancel</button>
          <button type="button" class="rounded-0 btn text-white primary-bg mb-0" (click)="updateTemplate()">Save</button>
        </div>
      </div>
    </div>
  </form>
  
  <!-- rename template modal -->
  <div [ngClass]="classSet" class="modal fade" id="renameTemplate" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <form class="h-100" #renameTemplateForm='ngForm' (ngSubmit)="saveRenameTemplate()">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0 rounder-3 p-5">
          <div class="modal-header px-0 mb-5">
            <h4 class="modal-title">Rename Template</h4>
            <a class="btn d-block ml-auto btnclose text-gray rounded-circle p-0" (click)="closeTemplate()">
              <em class="fa fa-close"></em>
            </a>
          </div>
          <div class="modal-body p-0">
            <label class="subtitle-modal p0">Template Name</label>
            <input class="form-control mb-5 template-control" [(ngModel)]="updatedTemplate.templateName" type="text"
              placeholder="Template Name" [ngModelOptions]="{standalone: true}" #templateName>
          </div>
          <div class="modal-footer border-0 p-0">
            <button type="button" class="rounded-0 btn btn-secondary-outline bg-white text-voilet mb-0"
              data-dismiss="modal" (click)="closeTemplate()">Cancel</button>
            <button type="submit" class="rounded-0 btn text-white primary-bg mb-0">Save</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  