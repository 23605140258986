<div class="">
    <div class="modal-header px-0">
        <h4 class="modal-title" id="modal-basic-title border-0">Affiliations/Exclusion</h4>
        <button type="button" class="btn d-block ml-auto btnclose text-gray rounded-circle p-0" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>    
    <form>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12 px-0">
                    <div class="form-group mb-4">
                        <label class="loginLabel" for="select">Select Providers</label>
                        <tag-input class="tagChips" [ngModel]="['hardocoded-item']" [ngModelOptions]="{standalone: true}" [placeholder]='"Enter Provider ID or Name"' [secondaryPlaceholder]='"Enter Provider ID or Name"'>
                            <tag-input-dropdown
                              [autocompleteItems]="items"
                              [showDropdownIfEmpty]="true"
                              [dynamicUpdate]="false"
                            >
                            </tag-input-dropdown>
                        </tag-input>
                    </div>
                    <div class="form-group mb-4">
                        <label class="loginLabel" for="notes">Notes</label><br>
                        <textarea class="text-form-control" id="notes" name="notes" rows="4" cols="50"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0 p-0 mb-4">
            <button type="button" class="rounded-0 btn btn-secondary-outline bg-white text-voilet mb-0" (click)="activeModal.close('Save click')">Cancel</button>
            <button type="submit" class="rounded-0 btn text-white primary-bg mb-0" [disabled]="true">Next</button>
        </div>
    </form>
</div>