import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  Invoice,
  InvoicePaginationData,
  LimosDataResponse,
} from 'src/app/models/limos/limos.models';
import { LimosService } from 'src/app/services/limos.service';
import { AppUtils } from 'src/app/utilities/app.utils';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css'],
})
export class InvoiceListComponent implements OnInit, OnDestroy {
  invoicePage = 1;
  invoiceLimit = 30;
  invoiceTotal: number;
  invoices: Invoice[] = [];
  @Output() invoiceData = new EventEmitter<Invoice[]>();
  @BlockUI('invoice-list')
  invoiceListBlockUI: NgBlockUI;
  private unsubscribe$ = new Subject<void>();

  constructor(private limosService: LimosService, private appUtils: AppUtils) {}

  ngOnInit() {
    this.getInvoices();
  }

  getInvoices() {
    this.invoiceListBlockUI.start();
    this.limosService
      .getInvoiceList(this.invoiceLimit, this.invoicePage)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp: LimosDataResponse<InvoicePaginationData>) => {
          const { hits, estimatedTotalHits } = resp.details;
          this.invoices = hits.map((invoice) => {
            return {
              ...invoice,
              total:
                typeof invoice.total === 'string'
                  ? parseFloat(invoice.total.replace(/[^0-9.-]+/g, ''))
                  : invoice.total,
            };
          });

          this.invoiceTotal = estimatedTotalHits;
          this.invoiceData.emit(this.invoices);

          this.invoiceListBlockUI.stop();
        },
        (error) => {
          console.error('Error fetching invoices:', error);
          this.invoiceListBlockUI.stop();
        }
      );
  }

  onPageChangeinvoices(event: any) {
    this.invoicePage = event;
    this.getInvoices();
  }

  onSizeChangeinvoices(limit: number) {
    this.invoiceLimit = limit;
    this.invoicePage = 1;
    this.getInvoices();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
