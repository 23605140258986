<!-- Modal -->
<div>
    <div class="modal-header p-0 d-flex align-items-center">
        <h4 class="modal-title" #unmergeHeader>Confirm Unmerge</h4>
        <h4 class="modal-title" #nounmergeHeader>Unmerge not allowed</h4>
        <button type="button" class="close py-0" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="flex text-center flex-col w-full">
            <p>You cannot unmerge {{pid}}</p>
            <!-- <p #unmergeDesc>Are you sure you want to unmerge {{pid}}? The following items will be unmerged...</p>
            <ul #unmergeItems>
                <li *ngFor="let unmergeChild of unmergeChildren">{{unmergeChild}}</li>
            </ul>
            <p #nounmergeDesc>Unmerge is not possible for {{pid}}.</p> -->
            <div class="flex">
                <!-- <button class="btn primary-bg text-white btnlogin mr-3" #unmergeYesBtn (click)="unmergeComp()">Yes</button>
                <button class="btn bg-orange text-white btnlogin" #unmergeNoBtn (click)="activeModal.dismiss('Cross click')">No</button> -->
                <button class="btn bg-orange text-white btnlogin" (click)="activeModal.dismiss('Cross click')">OK</button>
            </div>            
        </div>
    </div>
</div>