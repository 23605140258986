import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { LimosDataResponse, LimosReport, LimosReportCSVExportResponse, LimosReportDataResponse, TaskStatus } from 'src/app/models/limos/limos.models';
import { LimosService } from 'src/app/services/limos.service';
import { ExportAllCsvComponent } from '../../shared/Modals/export-all-csv/export-all-csv.component';

@Component({
  selector: 'app-limos-report',
  templateUrl: './limos-report.component.html',
  styleUrls: ['./limos-report.component.scss']
})
export class LimosReportComponent implements OnInit {
  limosReport: LimosReport[] = [];
  @BlockUI('limos-report-blockUI') blockUI: NgBlockUI;
  pageLimit = 20;
  pageOffset = 1;
  totalHits: number;
  constructor(
    private limosService: LimosService,
    private ngbModal: NgbModal,
  ) { }

  ngOnInit() {
    this.getLimosReportData();
  }

  getLimosReportData(export_to_csv = false) {
    if (!export_to_csv) {
      this.blockUI.start();
      this.limosReport = [];
    }
    this.limosService.getLimosReport(this.pageOffset, this.pageLimit, export_to_csv).subscribe((limosResponse: LimosDataResponse<LimosReportDataResponse & LimosReportCSVExportResponse>) => {
      if (export_to_csv) {
        this.openExportCSV();
        this.checkLimosReportExportStatus(limosResponse?.details?.task_id);
        return;
      }

      const { liens_profiles, limos_data } = limosResponse.details;
      this.totalHits = liens_profiles?.estimatedTotalHits;
      if (liens_profiles?.hits?.length) {
        liens_profiles.hits.map(profile => {
          const limosData = limos_data.find(limosData => limosData.wc_claim_number == profile.wc_claim_number);
          if (limosData) {
            const { id, provider_name_business, wc_claim_number, limos_status } = profile;
            const { lien_hearing_date, lien_hearing_time, wcab_venue, wcab_judge } = limosData;
            this.limosReport.push({
              id,
              provider_name_business,
              wc_claim_number,
              lien_hearing_date,
              lien_hearing_time,
              wcab_venue,
              wcab_judge,
              limos_status
            });
          }
          return profile;
        });
        this.blockUI.stop();
      }
    });
  }

  onPageChange(pageNumber: number) {
    this.pageOffset = pageNumber;
    this.getLimosReportData();
  }

  onSizeChange(limit: number) {
    this.pageLimit = limit;
    this.onPageChange(1);
  }

  openExportCSV(): void {
    this.ngbModal.open(ExportAllCsvComponent, {
      ariaDescribedBy: 'modal-basic-title', windowClass: 'groupModal', size: 'sm', centered: true
    });
  }

  checkLimosReportExportStatus(taskId: string) {
    if (taskId) {
      const interVal = setInterval(() => {
        this.limosService.checkLimosReportTaskStatus(taskId).subscribe((x: LimosDataResponse<TaskStatus>) => {
          if (x.details.status == 'SUCCESS') {
            clearInterval(interVal);
            this.ngbModal.dismissAll();
            const a = document.createElement("a");
            a.href = x.details.result.signed_url;
            a.download = "record.csv";
            a.click();
          }
        });
      }, 5000);
    }
  }
}

