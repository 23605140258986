import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AffiliateModel } from 'src/app/models/AffiliateModel';

export interface AutoCompleteModel {
  value: any;
  display: string;
}

@Component({
  selector: 'app-affiliation',
  templateUrl: './affiliation.component.html',
  styleUrls: ['./affiliation.component.css']
})
export class AffiliationComponent implements OnInit {

  public items = [
    {display: 'Pizza', value: 1},
    {display: 'Pasta', value: 2},
    {display: 'Parmesan', value: 3},
  ];

  affiliateModel: AffiliateModel = {
    select: '',
    notes: ''
  };

  constructor(private ngbActiveModal: NgbActiveModal) { }

  get activeModal(): any {
    return this.ngbActiveModal;
  }

  ngOnInit(): void {
  }
}
