import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dollarCurrency'
})
export class DollarCurrencyPipe implements PipeTransform {
  transform(value: number | string) {

    if (value) {
      return `$${value}`;
    }

    return value;
  }
}
