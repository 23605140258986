import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  CreateInvoiceReq,
  DuplicateInvoice,
  Invoice,
  LimosDataResponse,
  LimosInvoiceData,
  UpdateLimosResponseDetails,
} from 'src/app/models/limos/limos.models';
import { LimosService } from 'src/app/services/limos.service';
import * as html2pdf from 'html2pdf.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AccountService } from 'src/app/services/account.service';
@Component({
  selector: 'app-limos-invoice-pdf',
  templateUrl: './limos-invoice-pdf.component.html',
  styleUrls: ['./limos-invoice-pdf.component.css'],
})
export class LimosInvoicePdfComponent implements OnInit, OnDestroy {
  limosInvoiceData: LimosInvoiceData = null;
  isFileDownloading = false;
  showBillingEmailInput = false;
  duplicateInvoiceCount: number;
  duplicateInvoices: DuplicateInvoice[];
  paginatedDuplicateInvoices: DuplicateInvoice[];
  duplicateInvoicePage = 1;
  duplicateInvoiceLimit = 20;
  public serverDateFormat = 'yyyy-MM-dd';
  private unsubscribe$ = new Subject<void>();

  constructor(
    private limosService: LimosService,
    private modalService: NgbModal,
    private router: Router,
    private cd: ChangeDetectorRef,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private accountService: AccountService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.setInvoiceData();
  }

  setInvoiceData() {
    this.route.params
      .pipe(
        switchMap((params: ParamMap) => {
          const id = params['id'] ?? null;
          return id
            ? this.limosService.getInvoiceDetails(id).pipe(
                map((invoice: Invoice) => ({
                  type: 'invoiceData',
                  data: invoice,
                }))
              )
            : this.limosService.limosInvoiceData.pipe(
                take(1),
                map((data: LimosInvoiceData) => ({ type: 'limosData', data }))
              );
        })
      )
      .subscribe((result) => {
        if (result.type === 'limosData') {
          this.limosInvoiceData = result.data as LimosInvoiceData;
        } else {
          const {
            id,
            invoice_number,
            created_on,
            due_on,
            bill_to,
            location,
            contact,
            billing_email,
            limos_case_number,
            limos_claimant_id,
            claim_number,
            claimant_name,
            provider_name,
            line_items,
          } = result.data as Invoice;
          this.limosInvoiceData = {
            invoice_id: id,
            profile_id: Number(limos_case_number),
            invoice_number,
            case_number: null,
            created_on,
            due_on,
            billing_to: bill_to,
            billing_location: location,
            billing_name: 'JOSHUA GAGNE',
            billing_email,
            billing_contact: contact,
            claim_number,
            claimant_name,
            provider_name,
            time_spents: line_items,
          };
          this.limosService.setlimosInvoiceData(this.limosInvoiceData);
        }
      });
  }

  get finalTotalRate(): number {
    const total = this.limosInvoiceData.time_spents?.reduce(
      (acc, curr) => acc + (curr.total ?? 0),
      0
    );
    return isNaN(total) ? 0 : total;
  }

  exportToPdf() {
    this.isFileDownloading = true;
    var element = document.getElementById('invoicePage');
    var opt = {
      filename: `${this.limosInvoiceData.invoice_number}.pdf`,
      margin: 0.5,
      image: { type: 'jpeg', quality: 1.0 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };
    html2pdf
      .default(element, opt)
      .then((result) => {
        this.isFileDownloading = false;
        this.cd.detectChanges();
      })
      .catch((err) => {
        this.isFileDownloading = false;
      });
  }

  openModal(content, size = 'md') {
    this.modalService.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size,
      centered: true,
    });
  }

  deleteInvoiceData() {
    this.modalService.dismissAll();
    this.router.navigate([
      '/limos',
      this.limosInvoiceData.claim_number,
      this.limosInvoiceData.profile_id,
    ]);
    this.limosService.setlimosInvoiceData(null);
  }

  setDuplicateInvoice(content) {
    this.modalService.dismissAll();
    this.openModal(content, 'lg');

    this.duplicateInvoices = [];
    for (let index = 0; index < this.duplicateInvoiceCount; index++) {
      this.duplicateInvoices.push({
        claimNumber: '',
        claimantName: '',
        providerName: '',
      });
    }
    this.getDuplicateInvoicePaginatedData();
  }

  onPageChangeDuplicateInvoice(event: any) {
    this.duplicateInvoicePage = event;
    this.getDuplicateInvoicePaginatedData();
  }

  onSizeChangeDuplicateInvoice(limit: number) {
    this.duplicateInvoiceLimit = limit;
    this.duplicateInvoicePage = 1;
    this.getDuplicateInvoicePaginatedData();
  }

  getDuplicateInvoicePaginatedData() {
    this.paginatedDuplicateInvoices = this.duplicateInvoices.filter(
      (invoice, index) =>
        index >= this.duplicateInvoiceLimit * (this.duplicateInvoicePage - 1) &&
        index <
          this.duplicateInvoiceLimit * (this.duplicateInvoicePage - 1) +
            this.duplicateInvoiceLimit
    );
  }

  submitDuplicateInvoiceData() {
    if (this.paginatedDuplicateInvoices?.length) {
      this.paginatedDuplicateInvoices.forEach(
        ({ claimNumber, claimantName, providerName }) => {
          this.saveInvoice(claimNumber, claimantName, providerName);
        }
      );
    }
  }

  saveInvoice(
    claim_number: string,
    claimant_name: string,
    provider_name: string
  ) {
    const {
      created_on,
      due_on,
      billing_to,
      billing_location,
      billing_contact,
      billing_email,
      profile_id,
      time_spents,
    } = this.limosInvoiceData;

    let line_items = [];
    if (time_spents?.length) {
      line_items = time_spents.map((entry) => {
        let time_spent = entry.time_spent.toString().split('.');
        let hours = parseInt(time_spent[0], 10) * 3600;
        let minutes = time_spent[1]
          ? parseInt(time_spent[1].slice(0, 2), 10) * 60
          : 0;

        return {
          ...entry,
          id: null,
          user: this.accountService.userInfo.details.id,
          time_spent: hours + minutes,
        };
      });
    }

    const req: CreateInvoiceReq = {
      created_on: this.datePipe.transform(created_on, this.serverDateFormat),
      due_on: this.datePipe.transform(due_on, this.serverDateFormat),
      bill_to: billing_to,
      location: billing_location,
      contact: billing_contact,
      billing_email: billing_email,
      limos_case_number: profile_id,
      claim_number,
      claimant_name,
      provider_name,
      line_items,
    };

    this.limosService
      .createInvoice(req)
      .pipe(takeUntil(this.unsubscribe$), take(1))
      .subscribe(
        (res: LimosDataResponse<UpdateLimosResponseDetails>) => {
          this.toastr.success(res?.details?.message);
          this.modalService.dismissAll();
        },
        ({ error }: HttpErrorResponse) => {
          this.toastr.error(error?.errors[0]);
        }
      );
  }

  checkDuplicateInvoiceDisable(): boolean {
    return this.paginatedDuplicateInvoices.some(
      (invoice) => invoice.claimNumber == '' || invoice.claimantName == ''
    )
      ? true
      : false;
  }

  handlePaste(event: ClipboardEvent, rowIndex: number) {
    const clipboardData = event.clipboardData;

    if (clipboardData) {
      const pastedData = clipboardData.getData('text');
      // Split pasted data into rows and columns
      const rows = pastedData.split('\n').map((row) => row.split('\t'));
      rows.forEach((rowData, index) => {
        const currentRowIndex = rowIndex + index;

        if (currentRowIndex < this.duplicateInvoices.length) {
          this.duplicateInvoices[currentRowIndex].claimNumber =
            rowData[0]?.trim() || '';
          this.duplicateInvoices[currentRowIndex].claimantName =
            rowData[1]?.trim() || '';
          this.duplicateInvoices[currentRowIndex].providerName =
            rowData[2]?.trim() || '';
        }
      });
      this.getDuplicateInvoicePaginatedData();
    }

    event.preventDefault();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
