<div class="row">
  <div class="col-12">
    <div class="card">
      <div
        _ngcontent-rps-c192=""
        class="card-header spear-table-header-background d-flex justify-content-between"
      >
        <h6>Invoices</h6>
        <div>
          <button
            class="btn viewBtn spear-button-background text-white btn-sm"
            [routerLink]="['/limos/invoice']"
            [queryParams]="{ type: 'new' }"
          >
            New Invoice
          </button>
        </div>
      </div>
      <div class="card-body" *blockUI="'invoice-list-ui'">
        <div _ngcontent-bmk-c200="" class="row">
          <div class="col-md-12">
            <div class="w-100">
              <div class="row">
                <div class="col-12 primary-tab">
                  <ul class="nav nav-tabs">
                    <li class="nav-item text-black-contrast">
                      <button
                        [ngClass]="{
                          'bg-white': activeTab === 'invoices',
                          'text-uppercase': true
                        }"
                        class="nav-link"
                        (click)="selectTab('invoices')"
                      >
                        INVOICE
                      </button>
                    </li>
                    <li class="nav-item text-black-contrast">
                      <button
                        [ngClass]="{
                          'bg-white': activeTab === 'cases',
                          'text-uppercase': true,
                          active: activeTab === 'cases'
                        }"
                        class="nav-link"
                        (click)="selectTab('cases')"
                      >
                        By Case
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="invoices-list bg-white" *ngIf="activeTab === 'invoices'">
          <app-invoice-list
            [invoiceLimit]="invoiceLimit"
            (invoiceSelected)="handleInvoiceSelected($event)"
          >
          </app-invoice-list>
        </div>

        <div class="invoices-list bg-white" *ngIf="activeTab === 'cases'">
          <div class="d-flex gap-1">
            <div>
              <button class="btn viewBtn btn-secondary text-white btn-sm">
                Excel/CVS
              </button>
            </div>
          </div>
          <table
            class="table spear-table-header-background search-spear-background"
          >
            <caption>
              Cases
            </caption>
            <thead>
              <tr>
                <th class="border-0" scope="col">
                  Case Number.
                  <i class="fa fa-sort-up"></i>
                  <i class="fa fa-sort-down"></i>
                </th>
                <th class="border-0" scope="col">
                  Case Status
                  <i class="fa fa-sort-up"></i>
                  <i class="fa fa-sort-down"></i>
                </th>
                <th class="border-0" scope="col">
                  Client Case Number
                  <i class="fa fa-sort-up"></i>
                  <i class="fa fa-sort-down"></i>
                </th>
                <th class="border-0" scope="col">
                  Case Type
                  <i class="fa fa-sort-up"></i>
                  <i class="fa fa-sort-down"></i>
                </th>
                <th class="border-0" scope="col">
                  TPA/Administrator
                  <i class="fa fa-sort-up"></i>
                  <i class="fa fa-sort-down"></i>
                </th>
                <th class="border-0" scope="col">
                  Client
                  <i class="fa fa-sort-up"></i>
                  <i class="fa fa-sort-down"></i>
                </th>
                <th class="border-0" scope="col">
                  Subject
                  <i class="fa fa-sort-up"></i>
                  <i class="fa fa-sort-down"></i>
                </th>

                <th class="border-0" scope="col">
                  Case Total
                  <i class="fa fa-sort-up"></i>
                  <i class="fa fa-sort-down"></i>
                </th>
                <th class="border-0" scope="col">
                  Billed To Date
                  <i class="fa fa-sort-up"></i>
                  <i class="fa fa-sort-down"></i>
                </th>
                <th class="border-0" scope="col">
                  Difference
                  <i class="fa fa-sort-up"></i>
                  <i class="fa fa-sort-down"></i>
                </th>
                <th class="border-0" scope="col">
                  Last Invoiced
                  <i class="fa fa-sort-up"></i>
                  <i class="fa fa-sort-down"></i>
                </th>
              </tr>
            </thead>
            <tbody *ngIf="cases?.length">
              <tr class="bg-white" *ngFor="let caseItem of cases">
                <td>{{ caseItem.case_number }}</td>
                <td>{{ caseItem.case_status }}</td>
                <td>{{ caseItem.client_case_number }}</td>
                <td>{{ caseItem.case_type }}</td>
                <td>{{ caseItem.administrator }}</td>
                <td>{{ caseItem.client }}</td>
                <td>{{ caseItem.subject }}</td>
                <td>{{ caseItem.total }}</td>
                <td>{{ caseItem.bill_date }}</td>
                <td>{{ caseItem.difrrence }}</td>
                <td>{{ caseItem.last_invoice }}</td>
              </tr>
            </tbody>
            <ng-template *ngIf="!cases?.length">
              <tbody>
                <tr class="bg-white">
                  <td colspan="6" class="text-center">Data not available</td>
                </tr>
              </tbody>
            </ng-template>
          </table>
          <div
            class="px-0 py-0 spearPaginationWrapper paginationWrapper d-flex"
            *ngIf="activeTab === 'cases'"
          >
            <ngb-pagination
              [(collectionSize)]="caseTotal"
              [(page)]="casePage"
              [pageSize]="caseLimit"
              [maxSize]="10"
              [boundaryLinks]="true"
              (pageChange)="onPageChangeCases($event)"
            >
            </ngb-pagination>

            <div class="selected d-flex align-items-center">
              <select
                (change)="onSizeChangeCases($event.target.value)"
                class="custom-select table-select h-100"
                [(ngModel)]="caseLimit"
              >
                <option [value]="30">30</option>
                <option [value]="50">50</option>
                <option [value]="75">75</option>
                <option [value]="100">100</option>
              </select>
              <p class="mb-0 ml-2 text-nowrap">
                Showing {{ casePage * caseLimit - caseLimit + 1 }} -
                {{
                  casePage * caseLimit > caseTotal
                    ? caseTotal
                    : casePage * caseLimit
                }}
                of {{ caseTotal }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
