import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ScrubImportModel } from 'src/app/models/scrub/scrub-import-model';
import { FileService } from 'src/app/services/file.service';
@Component({
  selector: 'app-scrub',
  templateUrl: './scrub.component.html',
  styleUrls: ['./scrub.component.css'],
})
export class ScrubComponent implements OnInit, OnDestroy {
  form: FormGroup;
  listOfSouces: any;
  importedScrubData: ScrubImportModel[] = [];
  headers: string[];
  subscriptions: Subscription[];

  modalstatus: boolean;
  fileName: string;
  fileSize: string;

  @BlockUI('file-input-loader') blockUI: NgBlockUI;
  convertedFileSize: number;

  constructor(
    public fb: FormBuilder,
    private fileService: FileService,
    private toasterService: ToastrService
  ) {
    this.headers = Object.getOwnPropertyNames(new ScrubImportModel());
    this.subscriptions = [];
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription: Subscription) =>
        subscription.unsubscribe()
      );
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      upload_data: new FormControl(),
      fileSource: new FormControl(),
    });
  }
  get f() {
    return this.form.controls;
  }

  onFileChange(event): void {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const file = event.target.files[0];
      this.form.patchValue({
        fileSource: file,
      });

      const isAlreadyImported = this.checkifAlreadyImported(file);
      if (isAlreadyImported) {
        this.modalstatus = true;
        return;
      }
      // else doesnt exist process the file
      this.blockUI.start();
      this.processFile(file);
      this.form.controls.upload_data.setValue(null);
    }
  }
  onProceedModelPopup(): void {
    this.modalstatus = false;
    this.processFile(this.form.controls.fileSource.value);
    this.form.controls.upload_data.setValue(null);
  }
  onCancelModelPopup(): void {
    this.modalstatus = false;
    this.form.controls.upload_data.setValue(null);
  }

  checkifAlreadyImported(file): boolean {
    if (this.fileSize === file.size && this.fileName === file.name) {
      return true;
    }
    return false;
  }

  processXML(file): void {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      const subscription = this.fileService
        .importFromXMLFile(reader.result as string)
        .subscribe((result) => {
          this.importedScrubData = result.dataset.record.map((item) => {
            const obj = {};
            for (const header of this.headers) {
              obj[header] = item[header][0];
            }
            return obj as ScrubImportModel;
          });
          this.toasterService.success('File uploaded successfully');
          this.blockUI.stop();
        });
      this.subscriptions.push(subscription);
    };

    reader.onerror = () => {
      this.toasterService.error('error is occured while reading file!');
      this.blockUI.stop();
    };
  }

  processCSV(file): void {
    const reader = new FileReader();
    reader.readAsText(file);

    reader.onload = () => {
      const records = this.fileService.importFromCSVFile(
        reader.result as string
      );
      const importedData = records.slice(1, -1);
      this.importedScrubData = importedData.map((item) => {
        const arr = item.split(',');
        const obj = {};
        for (let i = 0; i < this.headers.length; i++) {
          const props = this.headers[i];
          obj[props] = arr[i];
        }
        return obj as ScrubImportModel;
      });
      this.toasterService.success('File uploaded successfully');
      this.blockUI.stop();
    };

    reader.onerror = () => {
      this.toasterService.error('error is occured while reading file!');
      this.blockUI.stop();
    };
  }

  processExcel(file): void {
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const data = this.fileService.importFromExcelFile(bstr) as any[];
      const importedData = data.slice(1, -1);
      this.importedScrubData = importedData.map((arr) => {
        const obj = {};
        for (const [i, header] of this.headers.entries()) {
          obj[header] = arr[i];
        }
        return obj as ScrubImportModel;
      });
      this.toasterService.success('File uploaded successfully');
      this.blockUI.stop();
    };
    reader.readAsBinaryString(file);
    reader.onerror = () => {
      this.toasterService.error('error is occured while reading file!');
      this.blockUI.stop();
    };
  }
  processFile(file): void {
    this.fileName = file.name;
    this.fileSize = file.size;
    this.convertedFileSize = Math.round(file.size / 1024);
    if (this.IsValidCSVFile(this.fileName)) {
      this.processCSV(file);
    }
    if (this.IsValidExcelFile(this.fileName)) {
      this.processExcel(file);
    }
    if (this.IsValidXMLFile(this.fileName)) {
      this.processXML(file);
    }
  }
  onSubmit(): void { }

  printComponent(cmpName): void {
    const printContents = document.getElementById(cmpName).innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
  }

  getFileExtension(filename: string): string {
    return filename.split('.').pop();
  }

  IsValidCSVFile(fileName): boolean {
    if (this.getFileExtension(fileName) === 'csv') {
      return true;
    }
    return false;
  }

  IsValidExcelFile(fileName): boolean {
    if (
      this.getFileExtension(fileName) === 'xlsx' ||
      this.getFileExtension(fileName) === 'xls'
    ) {
      return true;
    }
    return false;
  }

  IsValidXMLFile(fileName): boolean {
    if (this.getFileExtension(fileName) === 'xml') {
      return true;
    }
    return false;
  }
}
