import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { ProvidertablesService } from 'src/app/services/providertables.service';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { ProviderService } from 'src/app/services/provider.service';


@Component({
  selector: 'app-addotherassociatedprovider',
  templateUrl: './addotherassociatedprovider.component.html',
  styleUrls: ['./addotherassociatedprovider.component.css']
})
export class AddotherassociatedproviderComponent implements OnInit {

  public model: any;

  constructor(
    private _NgbActiveModal: NgbActiveModal,
    private toastr: ToastrService,
    private http: HttpClient,
    private providertable: ProvidertablesService,
    private providerService: ProviderService
  ) { }

  uuid: string;
  address: any = {};
  showInfo: boolean = false;
  name: string;
  firestore: string;
  profileModel: any;
  hasData: boolean = false;

  ngOnInit(): void {
  }

  get activeModal() {
    return this._NgbActiveModal;
  }

  search: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(term => term.length < 2 ? []
        :
        this.providerService.getProvidersFromFireStoreModal(term).pipe(
          map(response => response.details.hits),
          tap(response => { return of(response) }),
          catchError(() => { return of([]) })
        )
      )
    );

    formatter = (x: any) => x?.provider_name_person || x?.spear_id;

    selectUser(selectedUser: any){
      this.name = selectedUser.provider_name_person;
      this.address.provider_street_address1 = selectedUser.provider_street_address1;
      this.address.provider_city = selectedUser.provider_city;
      this.address.provider_state = selectedUser.provider_state;
      this.address.provider_zip = selectedUser.provider_zip;
      this.firestore = selectedUser.spear_id;
      this.showInfo = true;
      const matchData = this.providertable.OtherAssociated.filter((obj) => {
        return obj.firestore == selectedUser.spear_id;
      });
      if(matchData.length > 0){
        this.hasData = true;
      }
      else{
        this.hasData = false;
      }
    }
    addOtherAssociated(selectedFirestore: string){      
      this.providertable.uploadOtherAssociate(selectedFirestore);
      this.activeModal.dismiss();
    }

}
