import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ForgotPasswordModel } from 'src/app/models/account/forgot.password.model';
import { AccountService } from 'src/app/services/account.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AppUtils } from 'src/app/utilities/app.utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  @BlockUI('frm-forgotPassword') blockUI: NgBlockUI;
  forgotPasswordModel: ForgotPasswordModel = {
    email: ''
  };
  isResetPasswordLinkSent: boolean;
  constructor(public router: Router,
              private accountService: AccountService,
              private appUtils: AppUtils,
              private toastr: ToastrService) { }

  ngOnInit(): void {
  }
  // tslint:disable-next-line: typedef
  navTo() {
    this.router.navigate(['/login']);
  }

  forgotPassword(): void {
    if (!this.forgotPasswordModel.email || this.forgotPasswordModel.email.length === 0) {
      return;
    }
    this.blockUI.start();
    this.accountService.SendResetPasswordLink(this.forgotPasswordModel).subscribe(
      data => {
        this.isResetPasswordLinkSent = true;
        this.blockUI.stop();
      },
      // error => {
      //   this.blockUI.stop();
      //   this.appUtils.ProcessErrorResponse(this.toastr, error);
      // }
      );
  }
}
