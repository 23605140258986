<div class="container-fluid p-0" *blockUI="'provider-screen'">
    <div class="pt-4">
        <h3 class="page-title">Unmerge</h3>
    </div>
    
    <div class="mt-3 d-flex">
        <form class="form-inline">
            <div class="input-group input-group-navbar">
                <input type="text" class="form-control search-control pl-0 border-0" placeholder="Search provider id"
                    aria-label="Search" name="searchText" [(ngModel)]="searchText" (keydown.enter)="searchProviders()">
                <button class="btn rounded-right search-btn" type="button" (click)="searchProviders()">
                    <em class="fa fa-search"></em>
                </button>
            </div>
        </form>
        <div class="d-flex ml-4" *ngIf="navOn">
            <button class="btn bg-voilet text-white px-3 py-2" (click)="prevSearch()" [disabled]="!prevEnabled"><em class="fa fa-arrow-left mr-2" aria-hidden="true"></em>Previous Search</button>
            <button class="btn bg-voilet text-white px-3 py-2 ml-2" (click)="nextSearch()" [disabled]="!nextEnabled">Next Search<em class="fa fa-arrow-right ml-2" aria-hidden="true"></em></button>
        </div>
    </div>
    
    <div class="position-relative d-flex justify-content-center flex-column">
        <div class="flex mt-4 text-center" *ngIf="notAvailable">
            The data does not exist.
        </div>
        
        <div #widget class="widget-box">
            
        </div>
        <div class="loader-container" *ngIf="treeLoader">
            Loading...
        </div>
        <div class="unmerge-detail row" *ngIf="showUnmergeDetail">
            <div class="col-5">
                <div class="detail">
                    <div *ngFor="let item of unmergeFirstChild | keyvalue">
                        <strong>{{item.key| replace : '_' : ' '}}:</strong> {{item.value}}
                    </div>
                </div>
                
            </div>
            <div class="col-2">
                <div class="buttons">
                    <button class="btn bg-voilet text-white px-2 py-1 mb-3" (click)="unmergeNow()"><em class="fa fa-chain-broken mr-2"></em>Unmerge</button>
                    <button class="btn bg-voilet text-white px-2 py-1" (click)="unmergeCancel()"><em class="fa fa-refresh mr-2"></em>Cancel</button>
                </div>
            </div>
            
            <div class="col-5">
                <div class="detail">
                    <div *ngFor="let item of unmergeSecondChild | keyvalue">
                        <strong>{{item.key| replace : '_' : ' '}}:</strong> {{item.value}}
                    </div>
                </div>                
            </div>
        </div>
    </div>
</div>
