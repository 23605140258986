<div class="container-fluid p-0" *blockUI="'provider-screen'">
    <div class="row">
        <div class="col-md-12">
            <div class="w-100 pt-4">
                <div class="row mb-2 mb-xl-3 justify-content-between align-items-center">
                    <div class="col-auto">                        
                        <h3 class="page-title">Smart Merge Matches</h3>
                        <h5 class="da-grey">Ingrid Ballou</h5>
                    </div>

                </div>

                <div class="row">
                    <div class="col-md-12">
                            
                        <div class="card border-0 mb-2 flex-fill">
                            <div class="card-header da-light-red border-0 card-padding rounded px-4 pt-4 pb-0 bg-white">
                                
                                <h4 class="card-header-title da-color-red">
                                    Ingrid  Ballou
                                </h4>
                            </div>

                            <div class="card-body">
                                <div class="row da-card-merge-body">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-6  mb-2">
                                                <div class="da-card-label">
                                                    Company
                                                </div>
                                            </div>
                                            <div class="col-md-6  mb-2">
                                                ....
                                            </div>

                                            <div class="col-md-6  mb-2">
                                                <div class="da-card-label">
                                                    NPI
                                                </div>
                                            </div>
                                            <div class="col-md-6  mb-2">
                                                ....
                                            </div>

                                            <div class="col-md-6  mb-2">
                                                <div class="da-card-label">
                                                    NPI
                                                </div>
                                            </div>
                                            <div class="col-md-6  mb-2">
                                                ....
                                            </div>

                                            <div class="col-md-6  mb-2">
                                                <div class="da-card-label">
                                                    Affiliation
                                                </div>
                                            </div>
                                            <div class="col-md-6  mb-2">
                                                ....
                                            </div>


                                            <div class="col-md-6  mb-2">
                                                <div class="da-card-label">
                                                    License
                                                </div>
                                            </div>
                                            <div class="col-md-6  mb-2">
                                                ....
                                            </div>

                                            
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-6 mb-2">
                                                <div class="da-card-label">
                                                    Provider ID
                                                </div>
                                            </div>
                                            <div class="col-md-6  mb-2">
                                                ....
                                            </div>

                                            <div class="col-md-6  mb-2">
                                                <div class="da-card-label">
                                                    Provider Type
                                                </div>
                                            </div>
                                            <div class="col-md-6  mb-2">
                                                ....
                                            </div>

                                            <div class="col-md-6  mb-2">
                                                <div class="da-card-label">
                                                    Address
                                                </div>
                                            </div>
                                            <div class="col-md-6  mb-2">
                                                ....
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>


                <div class="row mt-4">
                    <div class="col-md-12">
                        <div class="row justify-content-between mb-3">
                            <div class="col-md-auto">
                                Records to match
                            </div>

                            <div class="col-md-auto">
                                <button class="btn-primary bg-voilet btn"><em class="fa fa-filter"></em></button>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">

                                <div class="row">
                                    <div class="col-md-4 mb-4" *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize; index as i">
                                        <div class="card border-0 mb-2 flex-fill active">
                                            <div class="card-header da-card-merge-record-header border-0 card-padding rounded px-4 pt-4 pb-0 bg-white">{{item.title}}</div>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-6 mb-2"> <div class="da-card-label">
                                                        Company
                                                    </div></div>
                                                    <div class="col-md-6 mb-2">{{item.Company}}</div>
                                                    <div class="col-md-6 mb-2"> <div class="da-card-label">
                                                        NPI
                                                    </div></div>
                                                    <div class="col-md-6 mb-2">{{item.NPI}}</div>
                                                    <div class="col-md-6 mb-2"> <div class="da-card-label">
                                                        Affiliation
                                                    </div></div>
                                                    <div class="col-md-6 mb-2">...</div>

                                                    <div class="col-md-6 mb-2"> <div class="da-card-label">
                                                        License
                                                    </div></div>
                                                    <div class="col-md-6 mb-2">...</div>

                                                    <div class="col-md-6 mb-2"> <div class="da-card-label">
                                                        EIN
                                                    </div></div>
                                                    <div class="col-md-6 mb-2">...</div>
                                                </div>

                                                <div class="row mt-4">
                                                    <div class="col-md-auto ">
                                                        <button routerLink="{{item.routerlnk}}" class="btn bg-voilet text-white px-3 py-2 ">Review Match</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="row justify-content-between px-0 py-2 paginationWrapper">

                                    <div class="col-md-auto">
                                        <ngb-pagination [collectionSize]="items.length" [pageSize]="pageSize" [(page)]="page" [boundaryLinks]="true"></ngb-pagination>
                                    </div>
                                    <div class="col-md-auto">
                                        <div class="selected d-flex align-items-center">
                                       
                                            <p class="mb-0 ml-2">Showing {{ (page-1)*pageSize+1 }} - {{ page*pageSize }}
                                                of {{ items.length }}</p>
                                        </div>
                                    </div>

                                </div>
                              

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>