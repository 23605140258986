import { Component, OnInit, OnDestroy } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  Invoice,
  InvoicePaginationData,
  LimosDataResponse,
} from 'src/app/models/limos/limos.models';
import { LimosService } from 'src/app/services/limos.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css'],
})
export class InvoiceComponent implements OnInit, OnDestroy {
  caseLimit = 30;
  casePage = 1;
  caseTotal: number;

  invoices: Invoice[];
  cases: Invoice[] = [];
  @BlockUI('invoice-list-ui') invoiceListBlockUI: NgBlockUI;
  private unsubscribe$ = new Subject<void>();
  selectedTab: string = 'invoices';
  activeTab: string = 'invoices';

  constructor(private limosService: LimosService) {}

  ngOnInit() {
    this.getCases();
  }

  selectTab(tab: string): void {
    this.activeTab = tab;
    this.selectedTab = tab;

    if (tab === 'cases') {
      this.getCases();
    }
  }

  getCases() {
    this.invoiceListBlockUI.start();
    this.limosService
      .getInvoiceList(this.caseLimit, this.casePage)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp: LimosDataResponse<InvoicePaginationData>) => {
          const { hits, estimatedTotalHits } = resp.details;
          this.cases = hits;
          this.caseTotal = estimatedTotalHits;
          this.invoiceListBlockUI.stop();
        },
        (error) => {
          console.error('Error fetching cases:', error);
          this.invoiceListBlockUI.stop();
        }
      );
  }

  onPageChangeCases(event: any) {
    this.casePage = event;
    this.getCases();
  }

  onSizeChangeCases(limit: number) {
    this.caseLimit = limit;
    this.casePage = 1;
    this.getCases();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
