import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  Client,
  ClientCreateResponse,
  ClientDataResponse,
} from 'src/app/models/client/client.interface';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.css'],
})
export class ClientDetailComponent implements OnInit, OnDestroy {
  newDropdownMenu = false;
  editDropdownMenu = false;
  activeTab = 'Overview';
  pageTabs = [
    'Overview',
    'Locations',
    'Contacts',
    'Price List',
    'Invoice Rules',
    'Notes',
    'History',
  ];
  generalInfo = false;
  addressInfo = false;
  instructionNotesInfo = false;
  private unsubscribe$ = new Subject<void>();
  clientData: Client;
  clientContactForm: FormGroup;
  clientLocationForm: FormGroup;
  serverDateFormat = 'yyyy-MM-dd';
  isClientContactFormSubmitted: boolean;
  isClientLocationFormSubmitted: boolean;
  clientForm: FormGroup;
  isClientFormSubmitted: boolean;

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private clientService: ClientService,
    private fb: FormBuilder,
    private _datePipe: DatePipe,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.initClientContactForm();
    this.initClientLocationForm();
  }

  ngOnInit() {
    this.route.params
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((params: Params) => this.fetchClientDetails(params.id))
      )
      .subscribe();
  }

  fetchClientDetails(clientId: number) {
    return this.clientService.getClientDetail(clientId).pipe(
      takeUntil(this.unsubscribe$),
      tap(({ details }: ClientDataResponse<Client>) => {
        this.clientData = details;
      })
    );
  }

  public openPopup(content, size = 'md') {
    this.modalService.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size,
      centered: true,
    });
  }

  initClientContactForm() {
    this.clientContactForm = this.fb.group({
      first_name: new FormControl('', [Validators.required]),
      middle_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      title: new FormControl(''),
      role: new FormControl(''),
      access_level: new FormControl(''),
      email: new FormControl(''),
      office_phone: new FormControl(''),
      mobile_phone: new FormControl(''),
      home_phone: new FormControl(''),
      fax: new FormControl(''),
      birthday: new FormControl(''),
      language: new FormControl(''),
      account_code: new FormControl(''),
      salesperson: new FormControl(''),
      case_manager: new FormControl(''),
      tags: new FormControl(''),
      general_notes: new FormControl(''),
      admin_notes: new FormControl(''),
      case_policies: new FormControl(''),
      location: new FormControl(''),
      address: this.fb.group({
        location_name: new FormControl(''),
        address_1: new FormControl(''),
        address_2: new FormControl(''),
        address_3: new FormControl(''),
        city: new FormControl(''),
        state: new FormControl(''),
        country: new FormControl(''),
        zip: new FormControl(''),
      }),
    });
  }

  initClientLocationForm() {
    this.clientLocationForm = this.fb.group({
      location_name: new FormControl('', [Validators.required]),
      is_primary: new FormControl(false),
      primary_phone: new FormControl(''),
      secondary_phone: new FormControl(''),
      fax: new FormControl(''),
      invoice_policies: new FormControl(''),
      general_notes: new FormControl(''),
      case_policies: new FormControl(''),
      update_policies: new FormControl(''),
      address: this.fb.group({
        address_1: new FormControl(''),
        address_2: new FormControl(''),
        address_3: new FormControl(''),
        city: new FormControl(''),
        state: new FormControl(''),
        country: new FormControl(''),
        zip: new FormControl(''),
      }),
    });
  }

  saveClientContact() {
    if (this.clientContactForm.invalid && !this.clientData.id) {
      return;
    }

    let birthdate = this.clientContactForm.get('birthday').value;
    if (birthdate) {
      this.clientContactForm
        .get('birthday')
        .setValue(this._datePipe.transform(birthdate, this.serverDateFormat));
    }

    this.isClientContactFormSubmitted = true;

    this.clientService
      .createClientContact(this.clientData.id, this.clientContactForm.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: ClientDataResponse<ClientCreateResponse>) => {
          this.toastr.success(res?.details?.message);
          this.modalService.dismissAll();
          this.clientContactForm.reset();
          this.isClientContactFormSubmitted = false;
          this.clientService.getClientDetail(this.clientData.id);
        },
        (error: ClientDataResponse<ClientCreateResponse>) => {
          this.toastr.success(error?.errors[0]);
        }
      );
  }

  saveClientLocation() {
    if (this.clientLocationForm.invalid && !this.clientData.id) {
      return;
    }

    this.isClientLocationFormSubmitted = true;

    this.clientService
      .createClientLocation(this.clientData.id, this.clientLocationForm.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: ClientDataResponse<ClientCreateResponse>) => {
          this.toastr.success(res?.details?.message);
          this.modalService.dismissAll();
          this.clientLocationForm.reset();
          this.isClientLocationFormSubmitted = false;
          this.clientService.getClientDetail(this.clientData.id);
        },
        (error: ClientDataResponse<ClientCreateResponse>) => {
          this.toastr.success(error?.errors[0]);
        }
      );
  }

  deleteClient() {
    this.clientService
      .deleteClient(this.clientData.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: ClientDataResponse<ClientCreateResponse>) => {
          this.toastr.success(res?.details?.message);
          this.router.navigate(['clients']);
        },
        (error: ClientDataResponse<ClientCreateResponse>) => {
          this.toastr.success(error?.errors[0]);
        }
      );
  }

  editClientProfile() {
    this.newDropdownMenu = false;
    this.editDropdownMenu = !this.editDropdownMenu;
    this.initClientForm();
  }

  initClientForm() {
    const {
      client_name,
      program_name,
      tpa,
      mga,
      address,
      email,
      main_phone,
      fax,
      client_instructions,
      invoice_instructions,
      general_notes,
    } = this.clientData;
    const {
      location_name,
      country,
      address_1,
      address_2,
      address_3,
      city,
      state,
      zip,
    } = address;
    this.clientForm = this.fb.group({
      client_name: new FormControl(client_name, [Validators.required]),
      program_name: new FormControl(program_name),
      tpa: new FormControl(tpa),
      mga: new FormControl(mga),
      email: new FormControl(email),
      main_phone: new FormControl(main_phone),
      fax: new FormControl(fax),
      client_instructions: new FormControl(client_instructions),
      invoice_instructions: new FormControl(invoice_instructions),
      general_notes: new FormControl(general_notes),
      address: this.fb.group({
        location_name: new FormControl(location_name, [Validators.required]),
        address_1: new FormControl(address_1, [Validators.required]),
        address_2: new FormControl(address_2),
        address_3: new FormControl(address_3),
        city: new FormControl(city, [Validators.required]),
        state: new FormControl(state, [Validators.required]),
        country: new FormControl(country, [Validators.required]),
        zip: new FormControl(zip, [Validators.required]),
      }),
    });
  }

  onUpdateClientForm() {
    if (this.clientForm.invalid) {
      return;
    }

    this.isClientFormSubmitted = true;

    this.clientService
      .updateClient(this.clientData.id, this.clientForm.value)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((res: ClientDataResponse<ClientCreateResponse>) => {
          this.toastr.success(res?.details?.message);
        }),
        switchMap(() => this.fetchClientDetails(this.clientData.id))
      )
      .subscribe(
        (res: ClientDataResponse<Client>) => {
          this.modalService.dismissAll();
          this.clientForm.reset();
          this.isClientFormSubmitted = false;
        },
        (error: ClientDataResponse<ClientCreateResponse>) => {
          this.toastr.success(error?.errors[0]);
        }
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
