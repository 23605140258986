import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

declare var $: any;

@Directive({
  selector: '[UsPhoneNoMask]'
})
export class UsPhoneNoMaskDirective {
  @Input() formCtrl: FormControl;

  constructor(
    private el: ElementRef,
  ) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initialValue = this.el.nativeElement.value;
    let formattedValue = initialValue.replace(/\D/g, '');

    if (formattedValue.length > 10) {
      formattedValue = formattedValue.substring(0, 10);
    }

    if (formattedValue.length >= 7) {
      formattedValue = `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(3, 6)}-${formattedValue.slice(6)}`;
    } else if (formattedValue.length > 3) {
      formattedValue = `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(3)}`;
    }


    if(this.formCtrl){
      this.formCtrl.setValue(formattedValue);
    }

    this.el.nativeElement.value = formattedValue;
  }

}
