import { AppModuleDeclarations } from './Modules/app-module.declarations';
import { AppModuleImports } from './Modules/app-module.imports';
import { NgModule } from '@angular/core';
import { AppComponent } from './components/app.component';
import { AccountService } from './services/account.service';
import { AuthService } from './services/auth.service';
import { AppUtils } from './utilities/app.utils';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './utilities/jwt.interceptor';
import { AuthGuard } from './utilities/auth.guard';
import { ModalService } from './services/modal.service';
import { ProviderService } from './services/provider.service';
import { ManageUsersService } from './services/manage.users.service';
import { DatePipe } from '@angular/common';
import { ManageTemplatesService } from './services/manage.template.service';
import { SmartMergeComponent } from './components/providers/smart-merge/smart-merge.component';
import { SmartMergeMatchesComponent } from './components/providers/smart-merge-matches/smart-merge-matches.component';
import { SearchComponent } from './components/search/search.component';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { AcceptNumberDirective } from './directives/accept-number.directive';
import { CustomMaxLengthDirective } from './directives/custom-length.directive';

import { PredashboardOverlayComponent } from './components/predashboard-overlay/predashboard-overlay.component';
import { ProviderDetailComponent } from './components/provider-detail/provider-detail.component';
import { ScrubComponent } from './components/scrub/scrub.component';
import { HighlightPipe } from './pipes/highlight.pipe';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from './components/provider-detail/dateformatter';
import { SortPipe } from './pipes/sort.pipe';
import { SearchstateService } from './services/searchstate.service';
import { FormatclaimantPipe } from './pipes/formatclaimant.pipe';
import { EntitiesListComponent } from './components/entities-list/entities-list.component';
import { EntitiesDetailComponent } from './components/entities-detail/entities-detail.component';
import { UnderMaintenanceComponent } from './components/maintenance/under-maintenance/under-maintenance.component';
import { JqueryDatepickerDirective } from './directives/jquery-ui-datepicker.directive';
import { DollarCurrencyPipe } from './directives/dollar-currency.pipe';
import { JqueryTimepickerDirective } from './directives/jquery-timepicker.directive';
import { DecimalPointPipe } from './directives/decimal-point.pipe';
import { UsPhoneNoMaskDirective } from './directives/us-phone-no-mask.directive';
import { EntitiesDetailNewComponent } from './components/entities-detail-new/entities-detail-new.component';
import { AutoResizeDirective } from './directives/auto-resize.directive';
import { InvoiceListComponent } from './components/invoice-list/invoice-list.component';
import { ClientDateFormatPipe } from './directives/client-date-format.pipe';
import { TimeManagementComponent } from './components/limos/team-management/time-management.component';

@NgModule({
  declarations: [
    AppModuleDeclarations,
    AppComponent,
    SmartMergeComponent,
    SmartMergeMatchesComponent,
    SearchComponent,
    PhoneMaskDirective,
    AcceptNumberDirective,
    CustomMaxLengthDirective,
    PredashboardOverlayComponent,
    ProviderDetailComponent,
    ScrubComponent,
    HighlightPipe,
    SortPipe,
    FormatclaimantPipe,
    EntitiesListComponent,
    EntitiesDetailComponent,
    EntitiesDetailNewComponent,
    UnderMaintenanceComponent,
    JqueryDatepickerDirective,
    DollarCurrencyPipe,
    JqueryTimepickerDirective,
    DecimalPointPipe,
    UsPhoneNoMaskDirective,
    AutoResizeDirective,
    InvoiceListComponent,
    TimeManagementComponent,
    ClientDateFormatPipe,
  ],
  imports: [AppModuleImports],
  providers: [
    AccountService,
    AuthService,
    AppUtils,
    ModalService,
    ManageUsersService,
    DatePipe,
    PhoneMaskDirective,
    AcceptNumberDirective,
    CustomMaxLengthDirective,
    ProviderService,
    ManageTemplatesService,
    SearchstateService,
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    AuthGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
