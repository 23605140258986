<!-- Modal -->
<div *blockUI="'show-loader'">
    <div class="modal-header">
        <h4 class="modal-title">Associate</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="d-flex flex-row" *ngIf="!entityAssociationType">
            <label for="associateWith" class="mr-4">Associate With</label>
            <select (change)="associateBy($event)" id="associateWith" class="form-control w-auto">
                <option value="">Select</option>
                <option value="entity">Entities</option>
                <option value="matter">Matter</option>
            </select>
        </div>
        <form name="entityform" [formGroup]="entityform" name="formEntity" *ngIf="isEntity" class="mt-1">
            <div class="mb-1">
                <select class="form-control" (change)="selectEntity($event)" *ngIf="!entityAssociationType"  [(ngModel)]="selectedEntity" formControlName="selectEntityName">
                    <option value="Make a selection">Make a selection</option>
                    <option *ngFor="let ent of entitiesList" value="{{ent.id}}">{{ent.name}}</option>
                </select>
            </div>
            <div class="mb-4" *ngIf="isNewEntity">
                <label for="entityName">Entities Name</label>
                <input class="form-control" id="entityName" formControlName="newEntityName" placeholder="Enter New Name">
            </div>
            <div class="table-row" *ngIf="isCreated || isNewEntity">
                <table class="table providerTable table-row cnstr-record product-tbl" *ngIf="showTable">
                    <caption>Data Table</caption>
                    <thead>
                        <tr>
                            <th class="border-0" scope="col">Name</th>
                            <th class="border-0" scope="col">Business</th>
                            <th class="border-0" scope="col" *ngIf="!entityAssociationType">Relationship</th>
                            <th class="border-0" scope="col">Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="bg-white" *ngFor="let row of providersTable">
                            <td>{{row.name}}</td>
                            <td>{{row.business}}</td>
                            <td *ngIf="!entityAssociationType">
                                <select class="form-control" formControlName="associationType-{{row.id}}">
                                    <option value="">Select Association Type for All</option>
                                    <option value="Financial">Financial</option>
                                    <option value="Other">Other</option>
                                </select>
                            </td>
                            <td>
                                <textarea class="form-control mb-3" formControlName="entityNote-{{row.id}}"></textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <button type="submit" class="btn btn-primary text-white" (click)="onSubmit()" [disabled]="!doAssociate">Submit</button>
        </form>
        <form name="formMatter" *ngIf="isMatter" class="mt-4">
            <div class="mb-4">
                <select class="form-control" (change)="selectMatter($event)">
                    <option>Select Matter</option>
                    <option>Matter 1</option>
                    <option>Matter 2</option>
                    <option>Matter 3</option>
                    <option value="newMatter">Other</option>
                </select>
            </div>
            <div class="mb-4" *ngIf="isNewMatter">
                <label for="matterName">Matter Name</label>
                <input class="form-control mb-3" id="matterName" placeholder="Enter New Name">
            </div>
            <div class="mb-4">
                <select class="form-control">
                    <option value="">Select Association Type</option>
                    <option value="financial">Financial</option>
                    <option value="other">Other</option>
                </select>
            </div>
            <div class="mb-4">
                <label for="matterNote">Note</label>
                <textarea class="form-control mb-3" id="matterNote"></textarea>
            </div>
            <button type="submit" class="btn btn-primary text-white">Submit</button>
        </form>

    </div>
</div>
