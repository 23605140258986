<div *blockUI="'show-loader'">
<div class="modal-header px-0">
    <h4 class="modal-title" id="modal-basic-title border-0">
        <span *ngIf="!isEditOperation">Add New User</span>
        <span *ngIf="isEditOperation">Edit User</span>
        </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    </div>    
        <form name="form" (ngSubmit)="f.form.valid && AddOrUpdate()" #f="ngForm" novalidate>
            <div class="modal-body">
            <div class="row">
                <div class="col-md-6 pl-0">
                    <div class="form-group mb-4">
                        <label class="loginLabel" for="firstname">First Name</label>
                        <input type="text" name="firstName" #firstName="ngModel" [(ngModel)]="userInfo.firstName" class="form-control" required>
                        <div class="alert alert-danger p-2" *ngIf="firstName.invalid && firstName.touched">
                            Please enter first name</div>
                    </div>
                </div>
                <div class="col-md-6 pr-0">
                    <div class="form-group mb-4">                            
                        <label class="loginLabel" for="lastName">Last Name</label>
                        <input type="text" name="lastName" #lastName="ngModel" [(ngModel)]="userInfo.lastName" class="form-control" required>
                        <div class="alert alert-danger p-2" *ngIf="lastName.invalid && lastName.touched">
                            Please enter last name</div>
                    </div>
                </div>
                <div class="col-md-6 pl-0">
                    <div class="form-group mb-4">
                        <label class="loginLabel" for="email">Email</label>
                        <input type="email" name="email" #email="ngModel" [(ngModel)]="userInfo.email" class="form-control" [disabled]="isEditOperation" email required>
                        <div class="alert alert-danger p-2" *ngIf="email.invalid && email.touched">
                            <span *ngIf="email.errors?.required">Please enter email</span>
                            <span *ngIf="email.errors?.email">Please enter a valid email</span>
                        </div>
                </div>
            </div>
                <div class="col-md-6 pr-0">
                    <div class="form-group mb-4">
                        <label class="loginLabel" for="roles">User Roles</label>
                        <select [(ngModel)]="userInfo.roleId"  name="roleId" #roleId="ngModel" class="custom-select custom-select-lg userSelect" required> 
                            <option disabled value="undefined">Select</option>
                            <option *ngFor="let role of roles" [value]="role.roleId">{{role.name}}</option> 
                        </select>
                        <div class="alert alert-danger p-2" *ngIf="roleId.invalid && roleId.touched">
                            Please select role</div>
                    </div>
                </div>
            </div>
    </div>
    <div class="modal-footer border-0 p-0 mb-4">
        <button type="button" class="rounded-0 btn btn-secondary-outline bg-white text-voilet mb-0" (click)="activeModal.close('Save click')">Cancel</button>
        <button type="submit" class="rounded-0 btn text-white primary-bg mb-0" *ngIf="!isEditOperation" [disabled]="f.invalid">Create</button>
        <button type="submit" class="rounded-0 btn text-white primary-bg mb-0" *ngIf="isEditOperation">Save</button>
    </div>
</form>
</div>