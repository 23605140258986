import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[autoResize]'
})
export class AutoResizeDirective {
  @Input() maxHeight: number;

  constructor(private elementRef: ElementRef) {}

  @HostListener('input')
  onInput(): void {
    this.resize();
  }

  ngOnInit(): void {
    this.resize();  // Initial resize to fit any pre-filled content
  }

  private resize(): void {
    const textarea = this.elementRef.nativeElement;
    textarea.style.height = 'auto';
    const newHeight = textarea.scrollHeight;

    if (newHeight > this.maxHeight) {
      textarea.style.height = `${this.maxHeight}px`;
      textarea.style.overflowY = 'scroll';
    } else {
      textarea.style.height = `${newHeight}px`;
      textarea.style.overflowY = 'hidden';
    }
  }
}
