import { ShortNumberPipe } from './../components/shared/pipes/short.number.pipe';
import { AppComponent } from '../components/app.component';
import { FooterComponent } from '../components/shared/footer/footer.component';
import { TopBarComponent } from '../components/shared/top-bar/top-bar.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { SideBarComponent } from '../components/shared/side-bar/side-bar.component';
import { ProvidersComponent } from '../components/providers/providers.component';
import { ProviderListDetailComponent } from '../components/provider-list-detail/provider-list-detail.component';
import { RightSidebarComponent } from '../components/right-sidebar/right-sidebar.component';
import { LoginComponent } from '../components/account/login/login.component';
import { ForgotPasswordComponent } from '../components/account/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from '../components/shared/Modals/change-password/change-password.component';
import { ResetPasswordComponent } from '../components/account/reset-password/reset-password.component';
import { GroupListComponent } from '../components/group-list/group-list.component';
import { NgbdSortableHeader } from '../directives/ngbd-sortable-header.directive';
import { ManageUsersComponent } from '../components/manage-users/manage-users.component';
import { DateFormatPipe, DateTimeFormatPipe } from '../utilities/app.pipes';
import { AddOrEditUserComponent } from '../components/manage-users/add-or-edit-user/add-or-edit-user.component';
import { CreatePasswordComponent } from '../components/manage-users/create-password/create-password.component';
import { FilterPipe } from '../pipes/filter.pipe';
import { FilterTemplatesComponent } from '../components/providers/filter-templates/filter-templates.component';
import { ReportsComponent } from '../components/reports/reports.component';
import { FindExportAddressComponent } from '../components/reports/find-export-address/find-export-address.component';
import { AffiliationComponent } from '../components/providers/affiliation/affiliation.component';
import { MergeComponent } from '../components/providers/merge/merge.component';
import { ConfirmationComponent } from '../components/shared/Modals/confirmation/confirmation.component';
import { ReplaceUnderscore } from '../components/unmerge/replaceUnderscore';
import { UnmergeComponent } from '../components/unmerge/unmerge.component';
import { EntityComponent } from '../components/shared/Modals/entity/entity.component';
import { SelectallconfirmationComponent } from '../components/providers/smart-merge-matches/selectallconfirmation/selectallconfirmation.component';
import { AddotherassociatedproviderComponent } from '../components/shared/Modals/addotherassociatedprovider/addotherassociatedprovider.component';
import { AddFinancialInterestModalComponent } from '../components/shared/Modals/add-financial-interest-modal/add-financial-interest-modal.component';
import { PageNotFoundComponent } from '../components/page-not-found/page-not-found.component';
import { OpenalertlangComponent } from '../components/shared/Modals/openalertlang/openalertlang.component';
import { DissociateFinancialInterestComponent } from '../components/shared/Modals/dissociate-financial-interest/dissociate-financial-interest.component';
import { DissociateOtherAssociateComponent } from '../components/shared/Modals/dissociate-other-associate/dissociate-other-associate.component';
import { MergeSelectionNotAllowedComponent } from '../components/shared/Modals/merge-selection-not-allowed/merge-selection-not-allowed.component';
import { ExportAllCsvComponent } from '../components/shared/Modals/export-all-csv/export-all-csv.component';
import { LimosComponent } from '../components/limos/limos.component';
import { ClaimantSummaryComponent } from '../components/limos/claimant-summary/claimant-summary.component';
import { AssociateComponent } from '../components/shared/Modals/associate/associate.component';
import { AddFinancialOtherAssociationModalComponent } from '../components/shared/Modals/add-financial-other-association-modal/add-financial-other-association-modal.component';
import { LimosReportComponent } from '../components/limos/limos-report/limos-report.component';
import { LimosInvoiceComponent } from '../components/limos/limos-invoice/limos-invoice.component';
import { LimosInvoicePdfComponent } from '../components/limos/limos-invoice-pdf/limos-invoice-pdf.component';
import { CaseComponent } from '../components/case/case.component';
import { ResourcesComponent } from '../components/resources/resources.component';
import { ClientComponent } from '../components/client/client.component';
import { ClientDetailComponent } from '../components/client/client-detail/client-detail.component';
import { InvoiceComponent } from '../components/invoice/invoice.component';
import { ContactComponent } from '../components/contact/contact.component';
import { ContactDetailComponent } from '../components/contact/contact-detail/contact-detail.component';

export const AppModuleDeclarations = [
  AppComponent,
  FooterComponent,
  TopBarComponent,
  DashboardComponent,
  SideBarComponent,
  ProvidersComponent,
  ProviderListDetailComponent,
  RightSidebarComponent,
  LoginComponent,
  ChangePasswordComponent,
  ForgotPasswordComponent,
  ResetPasswordComponent,
  GroupListComponent,
  NgbdSortableHeader,
  ManageUsersComponent,
  DateFormatPipe,
  DateTimeFormatPipe,
  AddOrEditUserComponent,
  CreatePasswordComponent,
  FilterPipe,
  FilterTemplatesComponent,
  ReportsComponent,
  ShortNumberPipe,
  FindExportAddressComponent,
  AffiliationComponent,
  MergeComponent,
  ConfirmationComponent,
  UnmergeComponent,
  ReplaceUnderscore,
  EntityComponent,
  SelectallconfirmationComponent,
  AddFinancialInterestModalComponent,
  AddFinancialOtherAssociationModalComponent,
  AddotherassociatedproviderComponent,
  PageNotFoundComponent,
  OpenalertlangComponent,
  DissociateFinancialInterestComponent,
  DissociateOtherAssociateComponent,
  MergeSelectionNotAllowedComponent,
  ExportAllCsvComponent,
  LimosComponent,
  ClaimantSummaryComponent,
  AssociateComponent,
  LimosReportComponent,
  LimosInvoiceComponent,
  LimosInvoicePdfComponent,
  CaseComponent,
  ResourcesComponent,
  ClientComponent,
  ClientDetailComponent,
  InvoiceComponent,
  ContactComponent,
  ContactDetailComponent,
];
