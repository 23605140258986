<!-- Modal -->
<div *blockUI="'show-loader'">
    <div class="modal-header">
        <h4 class="modal-title">Change Password</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form name="form" (ngSubmit)="f.form.valid && changePassword()" #f="ngForm" novalidate>
            <label class="loginLabel" for="currentPassword">Current Password</label>
            <input name ="currentPassword" type="password" #currentPassword="ngModel" class="form-control mb-3" [(ngModel)]="changePasswordModel.currentPassword" required>
            <div class="alert alert-danger p-2" *ngIf="currentPassword.invalid && currentPassword.touched">Current Password is required</div>
            
            <label class="loginLabel" for="newPassword">New Password</label>
            <input name ="newPassword" type="password"  #newPassword="ngModel" class="form-control mb-3" [(ngModel)]="changePasswordModel.newPassword" minlength="6" required>
            <div class="alert alert-danger p-2" *ngIf="newPassword.errors?.required && newPassword.touched">New Password is required</div>
            <div class="alert alert-danger p-2" *ngIf="newPassword.errors?.minlength && newPassword.touched">Password should be more than or equal 6 character</div>

            <label class="loginLabel" for="confirmPassword">Confirm Password</label>
            <input name ="confirm" type="password" ngValidateEqual="newPassword" #confirm="ngModel" class="form-control mb-3" [(ngModel)]="changePasswordModel.confirm" required>
            <div *ngIf="(confirm.touched) && confirm.invalid">
                <p class="alert alert-danger p-2" *ngIf="confirm.hasError('notEqual') && newPassword.valid">
                Passwords Don't Match
                </p>
            </div>
            <button type="submit" class="btn primary-bg text-white w-100 btnlogin my-4" [disabled]="f.invalid">Submit</button>

        </form>
    </div>
</div>