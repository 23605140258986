import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CreateDepartmentColumnReq,
  CreateDepartmentNameReq,
  CreateProgressionReq,
  DepartmentColumn,
  DepartmentCreateResponse,
  DepartmentDataResponse,
  DepartmentName,
  DepartmentPaginatedData,
  Progression,
  ReorderReq,
} from '../models/department/department.interface';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService {
  constructor(private http: HttpClient) {}

  createDepartmentName(
    reqBody: CreateDepartmentNameReq
  ): Observable<DepartmentDataResponse<DepartmentCreateResponse>> {
    return this.http.post<DepartmentDataResponse<DepartmentCreateResponse>>(
      `${environment.fireStoreApiUrl}/api/v1/department/names/create/`,
      reqBody
    );
  }

  getDepartmentNames(
    limit: number,
    offSet: number
  ): Observable<
    DepartmentDataResponse<DepartmentPaginatedData<DepartmentName>>
  > {
    return this.http.get<
      DepartmentDataResponse<DepartmentPaginatedData<DepartmentName>>
    >(
      `${environment.fireStoreApiUrl}/api/v1/department/names/get-all/?limit=${limit}&offset=${offSet}`
    );
  }

  getDepartmentNameDetail(
    id: number
  ): Observable<DepartmentDataResponse<DepartmentName>> {
    return this.http.get<DepartmentDataResponse<DepartmentName>>(
      `${environment.fireStoreApiUrl}/api/v1/department/names/get/${id}`
    );
  }

  deleteDepartmentName(
    id: number
  ): Observable<DepartmentDataResponse<DepartmentCreateResponse>> {
    return this.http.delete<DepartmentDataResponse<DepartmentCreateResponse>>(
      `${environment.fireStoreApiUrl}/api/v1/department/names/delete/${id}`
    );
  }

  updateDepartmentName(
    id: number,
    reqBody: CreateDepartmentNameReq
  ): Observable<DepartmentDataResponse<DepartmentCreateResponse>> {
    return this.http.put<DepartmentDataResponse<DepartmentCreateResponse>>(
      `${environment.fireStoreApiUrl}/api/v1/department/names/update/${id}/`,
      reqBody
    );
  }

  createDepartmentColumn(
    reqBody: CreateDepartmentColumnReq
  ): Observable<DepartmentDataResponse<DepartmentCreateResponse>> {
    return this.http.post<DepartmentDataResponse<DepartmentCreateResponse>>(
      `${environment.fireStoreApiUrl}/api/v1/department/columns/create/`,
      reqBody
    );
  }

  getDepartmentColumns(
    limit: number,
    offSet: number
  ): Observable<
    DepartmentDataResponse<DepartmentPaginatedData<DepartmentColumn>>
  > {
    return this.http.get<
      DepartmentDataResponse<DepartmentPaginatedData<DepartmentColumn>>
    >(
      `${environment.fireStoreApiUrl}/api/v1/department/columns/get-all/?limit=${limit}&offset=${offSet}`
    );
  }

  getDepartmentColumnDetail(
    id: number
  ): Observable<DepartmentDataResponse<DepartmentColumn>> {
    return this.http.get<DepartmentDataResponse<DepartmentColumn>>(
      `${environment.fireStoreApiUrl}/api/v1/department/columns/get/${id}`
    );
  }

  deleteDepartmentColumn(
    id: number
  ): Observable<DepartmentDataResponse<DepartmentCreateResponse>> {
    return this.http.delete<DepartmentDataResponse<DepartmentCreateResponse>>(
      `${environment.fireStoreApiUrl}/api/v1/department/columns/delete/${id}`
    );
  }

  updateDepartmentColumn(
    id: number,
    reqBody: CreateDepartmentNameReq
  ): Observable<DepartmentDataResponse<DepartmentCreateResponse>> {
    return this.http.put<DepartmentDataResponse<DepartmentCreateResponse>>(
      `${environment.fireStoreApiUrl}/api/v1/department/columns/update/${id}/`,
      reqBody
    );
  }

  reOrderRow(
    reqBody: ReorderReq,
    type: string
  ): Observable<DepartmentDataResponse<DepartmentCreateResponse>> {
    let endPoint = `${environment.fireStoreApiUrl}/api/v1/department/${type}/update/order_id/`;
    if (type == 'progression') {
      endPoint = `${environment.fireStoreApiUrl}/api/v1/liens/${type}/update/order_id/`;
    }
    return this.http.put<DepartmentDataResponse<DepartmentCreateResponse>>(
      endPoint,
      reqBody
    );
  }

  createDepartmentProgression(
    reqBody: CreateProgressionReq
  ): Observable<DepartmentDataResponse<DepartmentCreateResponse>> {
    return this.http.post<DepartmentDataResponse<DepartmentCreateResponse>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/progression/create/`,
      reqBody
    );
  }

  updateDepartmentProgression(
    id: number,
    reqBody: CreateDepartmentNameReq
  ): Observable<DepartmentDataResponse<DepartmentCreateResponse>> {
    return this.http.put<DepartmentDataResponse<DepartmentCreateResponse>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/progression/update/${id}/`,
      reqBody
    );
  }

  getDepartmentProgressions(
    limit: number,
    offSet: number,
    departmentId?: number
  ): Observable<DepartmentDataResponse<DepartmentPaginatedData<Progression>>> {
    return this.http.get<
      DepartmentDataResponse<DepartmentPaginatedData<Progression>>
    >(
      `${
        environment.fireStoreApiUrl
      }/api/v1/liens/progression/get-all/?limit=${limit}&offset=${offSet}${
        departmentId ? '&department_id=' + departmentId : ''
      }`
    );
  }

  deleteDepartmentProgression(
    id: number
  ): Observable<DepartmentDataResponse<DepartmentCreateResponse>> {
    return this.http.delete<DepartmentDataResponse<DepartmentCreateResponse>>(
      `${environment.fireStoreApiUrl}/api/v1/liens/progression/delete/${id}`
    );
  }
}
