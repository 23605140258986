import { Response } from 'src/app/models/responseModel';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { UserTemplatesResponse } from '../models/provider-filter/userTemplatesResponse';
import { Template } from '../models/provider-filter/template';
import { UpdateTemplate } from '../models/provider-filter/updateTemplate';
import { AddTemplate } from '../models/provider-filter/addTemplate';
@Injectable({
    providedIn: 'root'
})
export class ManageTemplatesService {

    public selectedTemplate: Template;
    get SelectedTemplate(): Template {
        return this.selectedTemplate;
    }
    set SelectedTemplate(selectedTemplate: Template) {
        this.selectedTemplate = selectedTemplate;
    }

    private fields: any[];
    get Fields(): any[] {
        return this.fields;
    }
    set Fields(fields: any[]) {
        this.fields = fields;
    }

    constructor(private httpClient: HttpClient) {

    }

    getFields = (): Observable<Response> => {
        return this.httpClient.get<Response>(`${environment.apiUrl}providerstemplate/GetFields`);
    }
    getTemplates = (): Observable<UserTemplatesResponse> => {
        return this.httpClient.get<UserTemplatesResponse>(`${environment.apiUrl}providerstemplate/GetUserTemplate`);
    }
    deleteTemplate = (template: Template): Observable<Response> => {
        return this.httpClient.get<Response>(`${environment.apiUrl}providerstemplate/DeleteUserTemplate?templateId=` + template.templateId);
    }
    addTemplate = (templateModel: AddTemplate): Observable<Response> => {
        return this.httpClient.post<Response>(`${environment.apiUrl}providerstemplate/SaveUserTemplate`, templateModel);
    }
    updateTemplate = (template: UpdateTemplate): Observable<Response> => {
        return this.httpClient.post<Response>(`${environment.apiUrl}providerstemplate/UpdateUserTemplate`, template);
    }
}

