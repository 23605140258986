import { DatePipe, Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  ClientDataResponse,
  ClientCreateResponse,
} from 'src/app/models/client/client.interface';
import {
  CreateInvoiceReq,
  GetTimeSpentResponseDetail,
  Invoice,
  LimosDataResponse,
  LimosInvoiceData,
  UpdateLimosResponseDetails,
} from 'src/app/models/limos/limos.models';
import { LimosService } from 'src/app/services/limos.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-limos-invoice',
  templateUrl: './limos-invoice.component.html',
  styleUrls: ['./limos-invoice.component.css'],
})
export class LimosInvoiceComponent implements OnInit {
  termsCollapse = false;
  publicNotesCollapse = false;
  internalNotesCollapse = false;
  limosInvoiceData: LimosInvoiceData;
  public serverDateFormat = 'yyyy-MM-dd';
  public clientDateFormat = 'MM/dd/yyyy';
  private unsubscribe$ = new Subject<void>();

  public timeTypes = [
    'Initial Lien Review',
    'Lien Negotiation',
    'Client Correspondence',
    'D/A Correspondence',
    'Other',
  ].sort();
  invoiceType: string;

  constructor(
    private limosService: LimosService,
    private location: Location,
    private router: Router,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private accountService: AccountService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.fetchInvoiceData();
  }

  fetchInvoiceData() {
    this.route.queryParams
      .pipe(
        switchMap((queryParam: ParamMap) => {
          this.invoiceType = queryParam['type'] ?? null;
          return this.limosService.limosInvoiceData;
        })
      )
      .subscribe((result: LimosInvoiceData) => {
        if (this.invoiceType === 'new') {
          this.limosInvoiceData = {
            invoice_id: null,
            profile_id: null,
            case_number: '',
            created_on: new Date().toLocaleDateString('en-US'),
            due_on: '',
            billing_to: '',
            billing_location: '',
            billing_name: '',
            billing_email: '',
            claim_number: '',
            claimant_name: '',
            billing_contact: '',
            time_spents: [],
            claimantTableData: [],
            provider_name: '',
          };
        } else {
          result.time_spents = result.time_spents.map((timeSpent) => {
            return {
              ...timeSpent,
              log_date: this.datePipe.transform(
                timeSpent.log_date,
                this.clientDateFormat
              ),
            };
          });
          this.limosInvoiceData = result;
        }
      });
  }

  get providerNames(): string {
    return this.limosInvoiceData.time_spents
      .map((item) => item.provider_name_business)
      .join(',');
  }

  addLienItem() {
    this.limosInvoiceData.time_spents.push({
      id: null,
      user: '',
      provider_name_business: '',
      time_type: '',
      note: '',
      time_spent: '',
      log_date: '',
      rate: null,
      total: null,
      checked: false,
    });
  }

  cancelInvoice() {
    this.location.back();
  }

  onChangeRate(timeSpent: GetTimeSpentResponseDetail) {
    const totalSpent = timeSpent.rate * +timeSpent.time_spent.split(' ')[0];
    timeSpent.total = isNaN(totalSpent) ? 0 : totalSpent;
  }

  get finalTotalRate(): number {
    const total = this.limosInvoiceData.time_spents?.reduce(
      (acc, curr) => acc + (curr.total ?? 0),
      0
    );
    return isNaN(total) ? 0 : total;
  }

  saveInvoice() {
    const {
      invoice_id,
      created_on,
      due_on,
      billing_to,
      billing_location,
      billing_contact,
      billing_email,
      profile_id,
      claim_number,
      claimant_name,
      provider_name,
      time_spents,
    } = this.limosInvoiceData;

    let line_items = [];
    if (time_spents?.length) {
      line_items = time_spents.map((entry) => {
        let time_spent = entry.time_spent.toString().split('.');
        let hours = parseInt(time_spent[0], 10) * 3600;
        let minutes = time_spent[1]
          ? parseInt(time_spent[1].slice(0, 2), 10) * 60
          : 0;

        return {
          ...entry,
          id: null,
          user: this.accountService.userInfo.details.id,
          time_spent: hours + minutes,
          log_date: this.datePipe.transform(
            entry.log_date,
            this.serverDateFormat
          ),
        };
      });
    }

    const req: CreateInvoiceReq = {
      created_on: this.datePipe.transform(created_on, this.serverDateFormat),
      due_on: this.datePipe.transform(due_on, this.serverDateFormat),
      bill_to: billing_to,
      location: billing_location,
      contact: billing_contact,
      billing_email: billing_email,
      limos_case_number: profile_id,
      claim_number,
      claimant_name,
      provider_name,
      line_items,
    };

    if (invoice_id) {
      this.limosService
        .updateInvoice(invoice_id, req)
        .pipe(takeUntil(this.unsubscribe$), take(1))
        .subscribe(
          (res: LimosDataResponse<UpdateLimosResponseDetails>) => {
            this.toastr.success(res?.details?.message);
            this.router.navigate(['/invoice-pdf', res.details.invoice.id]);
          },
          ({ error }: HttpErrorResponse) => {
            this.toastr.error(error?.errors[0]);
          }
        );
      return;
    }

    this.limosService
      .createInvoice(req)
      .pipe(takeUntil(this.unsubscribe$), take(1))
      .subscribe(
        (res: LimosDataResponse<UpdateLimosResponseDetails>) => {
          this.toastr.success(res?.details?.message);
          this.limosService.setlimosInvoiceData(this.limosInvoiceData);
          this.router.navigate(['/invoice-pdf', res.details.invoice.id]);
        },
        ({ error }: HttpErrorResponse) => {
          this.toastr.error(error?.errors[0]);
        }
      );
  }

  submitInvoiceData(index: number) {
    const {
      id,
      log_date,
      note,
      provider_name_business,
      time_spent,
      time_type,
      rate,
      total,
    } = this.limosInvoiceData.time_spents[index];

    if (id) {
      this.limosService
        .updateTimeSpent(id, {
          log_date: this.datePipe.transform(log_date, this.serverDateFormat),
          note,
          provider_name_business,
          time_spent: Math.round(Number(time_spent.replace(' Hrs', '')) * 3600),
          time_type,
          rate,
          total,
        })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res: LimosDataResponse<UpdateLimosResponseDetails>) => {
            this.toastr.success(res?.details?.message);
          },
          ({ error }: HttpErrorResponse) => {
            this.toastr.error(error?.errors[0]);
          }
        );
    } else {
      this.limosService
        .saveTimeSpent({
          log_date: this.datePipe.transform(log_date, this.serverDateFormat),
          note,
          provider_name_business,
          time_spent: Math.round(Number(time_spent.replace(' Hrs', '')) * 3600),
          time_type,
          rate,
          total,
        })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res: LimosDataResponse<UpdateLimosResponseDetails>) => {
            this.toastr.success(res?.details?.message);
          },
          ({ error }: HttpErrorResponse) => {
            this.toastr.error(error?.errors[0]);
          }
        );
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.limosInvoiceData.time_spents,
      event.previousIndex,
      event.currentIndex
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
