<!-- Modal -->
<div *blockUI="'show-loader'">
    <div class="modal-header">
        <h4 class="modal-title">Add Financial Interest</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body add-financial-interest">
        <div class="flex flex-col w-full">
            <div class="input-group input-group-navbar">
                <input id="typeahead-basic" type="text" class="form-control" [(ngModel)]="model" [ngbTypeahead]="search" [resultTemplate]="rt" placeholder="Search company, individual, name, alias name, etc." [inputFormatter]="formatter"/>
                <button type="button" class="btn rounded-left search-btn"><em class="fa fa-search"></em></button>
            </div>

            <ng-template #rt let-r="result" let-t="term">
                <!-- {{r | json}} -->
                <!-- <ngb-highlight [result]="r?.provider_name_person || r?.spear_id" [term]="t" (click)="selectUser(r)"></ngb-highlight> -->
                <div [term]="t" (click)="selectUser(r)">{{r?.provider_name_person || r?.spear_id}}{{(r.provider_street_address_1 || r.provider_street_address_2 || r.provider_city || r.provider_state || r.provider_zip ? ', ' : '')}} {{(r.provider_street_address_1 ? r.provider_street_address_1: '') + (r.provider_street_address_1 && r.provider_street_address_2 ? ', ' : '') + (r.provider_street_address_2 ? r.provider_street_address_2: '') + (r.provider_street_address_2 && r.provider_city ? ', ' : '') + (r.provider_city ? r.provider_city: '') + (r.provider_city && r.provider_state ? ', ' : '') + (r.provider_state ? r.provider_state: '') + (r.provider_state && r.provider_zip ? ', ' : '') + (r.provider_zip ? r.provider_zip: '')}}</div>
              </ng-template>

            <div class="detail" *ngIf="showInfo" [ngClass] = "{'disabled' : hasData}">
                <div>
                    <strong>Provider Name Person: </strong>{{name || 'Not Available'}}
                </div>
                <div>
                    <strong>Provider Entity Address: </strong>{{address.provider_street_address1}}<span *ngIf="address.provider_street_address1 && address.provider_city">, </span>{{address.provider_city}}<span *ngIf="address.provider_city && address.provider_state">, </span>{{address.provider_state}}<span *ngIf="address.provider_state && address.provider_zip">&nbsp;&nbsp;</span>{{address.provider_zip}}
                    <span *ngIf="!address.provider_street_address1 && !address.provider_city && !address.provider_state && !address.provider_zip">Not Available</span>
                </div>
                <div>
                    <strong>Spear Id Firestore: </strong>{{firestore}}
                </div>
            </div>
            <div class="hasdata-label">The selected profile is already has been added, please search again.</div>

            <div class="flex text-center" *ngIf="showInfo">
                <button class="btn primary-bg text-white btnlogin mr-3" (click)="addFinancial(firestore)" [disabled]="hasData">Add</button>
                <button class="btn bg-orange text-white btnlogin" (click)="activeModal.dismiss('Cross click')">Cancel</button>
            </div>

        </div>
    </div>
</div>
