import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-unmerge',
  templateUrl: './unmerge.component.html',
  styleUrls: ['./unmerge.component.css']
})

export class UnmergeModalComponent implements OnInit, AfterViewInit {

  @ViewChild("unmergeHeader") unmergeHeader: ElementRef;
  @ViewChild("nounmergeHeader") nounmergeHeader: ElementRef;
  @ViewChild("unmergeDesc") unmergeDesc: ElementRef;
  @ViewChild("unmergeItems") unmergeItems: ElementRef;
  @ViewChild("nounmergeDesc") nounmergeDesc: ElementRef;
  @ViewChild("unmergeYesBtn") unmergeYesBtn: ElementRef;
  @ViewChild("unmergeNoBtn") unmergeNoBtn: ElementRef;
  @ViewChild("nounmergeBtn") nounmergeBtn: ElementRef;

  pid: string;
  noUnmerge: boolean = false;
  unmergeChildren: any [] = [];

  unmergeData: any = {};

  @Output() refreshTree: EventEmitter<any> = new EventEmitter();

  refreshTreeBoolean: boolean = false;

  constructor(
    private _NgbActiveModal: NgbActiveModal, 
    private toastr: ToastrService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    
  }
  ngAfterViewInit(): void{
    if(!this.noUnmerge){
      this.nounmergeHeader.nativeElement.style.display = "none";  
      this.nounmergeDesc.nativeElement.style.display = "none";
      this.nounmergeBtn.nativeElement.style.display = "none";
    }
    else{
      this.unmergeHeader.nativeElement.style.display = "none";
      this.unmergeDesc.nativeElement.style.display = "none";
      this.unmergeItems.nativeElement.style.display = "none";
      this.unmergeYesBtn.nativeElement.style.display = "none";
      this.unmergeNoBtn.nativeElement.style.display = "none";
    }
  }

  get activeModal() {
    return this._NgbActiveModal;
  }

  unmergeComp(){
    this.unmergeData.spear_id_firestore = this.pid;
    this.http.post('https://api.argusspear.com/unmerge', this.unmergeData).subscribe((data)=>{
      this.activeModal.dismiss();
      this.toastr.success('The document has been unmerged successfully'); 
      this.refreshTreeBoolean = true;
      this.refreshTree.emit(this.refreshTreeBoolean);      
    });

    // this.refreshTreeBoolean = true;
    //   this.refreshTree.emit(this.refreshTreeBoolean);
    //   this.activeModal.dismiss();
  }

}
