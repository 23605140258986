<div class="row profileReport block-ui__element" *ngIf="profileModel">
  <!-- *blockUI="'manage-users-blockUI'" -->
  <div class="col-12 primary-tab" *ngIf="profileDataReady">
    <ul class="nav nav-tabs">
      <li class="nav-item" *ngFor="let tab of pageTabs">
        <button
          class="nav-link text-uppercase"
          [ngClass]="{ 'active search-spear-background': activeTab == tab }"
          (click)="activeTab = tab"
        >
          {{ tab }}
        </button>
      </li>
    </ul>
  </div>

  <div class="col-12 profileReportBody search-spear-background">
    <div class="row" *ngIf="activeTab == 'Dashboard'">
      <div class="col-12">
        <div
          class="d-flex justify-content-between align-items-center pl-2 pb-3 business-info"
        >
          <div class="d-flex flex-row">
            <div class="d-flex mr-5">
              <div><strong>Provider:</strong></div>
              <div class="text-center ml-2">
                {{ profileModel.details?.entity_name || "No Name Available" }}
              </div>
            </div>
            <div class="d-flex mr-5">
              <div><strong>Provider Category Desc.:</strong></div>
              <div class="text-center ml-2">Not Listed</div>
            </div>
          </div>
          <div class="d-flex flex-row">
            <button
              class="btn btn-sm spear-button-background text-white text-uppercase"
            >
              Spear
            </button>
            <button class="btn btn-sm limos-button-background text-white ml-2">
              LIMOS
            </button>
            <button class="btn btn-sm adonis-button-background text-white ml-2">
              ADONIS
            </button>
            <button class="btn btn-sm urania-button-background text-white ml-2">
              URANIA
            </button>
            <button class="btn btn-sm midas-button-background text-white ml-2">
              MIDAS
            </button>
            <button
              routerLink="/reports"
              class="btn btn-sm achilles-button-background text-white ml-2"
            >
              ACHILLES
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12" *ngIf="activeTab == 'Dashboard'">
        <div
          class="d-flex justify-content-between align-items-center pl-2 pb-3 business-info"
        >
          <div class="d-flex flex-row">
            <div class="d-flex flex-column mr-4">
              <div><strong>Total Open Claims</strong></div>
              <div class="text-center">&#8212;</div>
            </div>
            <div class="d-flex flex-column mr-4">
              <div><strong>Total Closed Claims</strong></div>
              <div class="text-center">&#8212;</div>
            </div>
            <div class="d-flex flex-column mr-4">
              <div><strong>Total Claims Billed</strong></div>
              <div class="text-center">&#8212;</div>
            </div>
            <div class="d-flex flex-column mr-4">
              <div><strong>Total Claims Paid</strong></div>
              <div class="text-center">&#8212;</div>
            </div>
            <div class="d-flex flex-column mr-4">
              <div><strong>Open Claims Billed</strong></div>
              <div class="text-center">&#8212;</div>
            </div>
            <div class="d-flex flex-column mr-4">
              <div><strong>Open Claims Paid</strong></div>
              <div class="text-center">&#8212;</div>
            </div>
            <div class="d-flex flex-column">
              <div><strong>Open Claims Billed No Pay</strong></div>
              <div class="text-center">&#8212;</div>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="activeTab == 'Dashboard'">
        <div class="col-md-3">
          <div class="row mb-1">
            <div class="col-12">
              <div
                class="accordian-tab card-header spear-table-header-background title-space"
              >
                <h6 class="m-0">Licenses</h6>
                <div>
                  <button
                    class="btn viewBtn spear-button-background text-white mr-2"
                    (click)="openPopup(createLicense)"
                  >
                    Add
                  </button>
                  <button class="btn">
                    <em
                      (click)="togglePanel('LIC')"
                      class="fa fa-sort-desc"
                      [ngClass]="licStatus ? '' : 'antirotate'"
                    ></em>
                  </button>
                </div>
              </div>
              <div [ngClass]="licStatus ? 'collapsecard' : 'expandcard'">
                <div class="card border-0 card-scrollbar">
                  <div class="card-body p-0">
                    <table class="table providerTable">
                      <caption>
                        Licenses
                      </caption>
                      <thead>
                        <tr>
                          <th class="border-0" scope="col">Number</th>
                          <th class="border-0" scope="col">Type</th>
                          <th class="border-0" scope="col">State</th>
                          <th class="border-0" scope="col">Status</th>
                          <th class="border-0" scope="col">Secondary Status</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="licenses.length">
                        <tr
                          class="bg-white paycode__click"
                          *ngFor="let lic of licenses"
                        >
                          <td
                            class="primary-text text-uppercase font-weight-semibold"
                          >
                            {{ lic.number }}
                          </td>
                          <td>{{ lic.type || "Not Listed" }}</td>
                          <td>{{ lic.state || "Not Listed" }}</td>
                          <td>{{ lic.status || "Not Listed" }}</td>
                          <td>—</td>
                        </tr>
                      </tbody>
                      <tbody *ngIf="!licenses.length">
                        <tr class="bg-white">
                          <td colspan="5" class="text-center">
                            Data not available
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-12">
              <div
                class="accordian-tab card-header spear-table-header-background title-space"
              >
                <h6 class="m-0">IRS Match</h6>
                <div>
                  <button
                    class="btn viewBtn spear-button-background text-white mr-2"
                    (click)="openPopup(createIRSMatch)"
                  >
                    Add
                  </button>
                  <button class="btn">
                    <em
                      (click)="togglePanel('Irs')"
                      class="fa fa-sort-desc"
                      [ngClass]="irsStatus ? '' : 'antirotate'"
                    ></em>
                  </button>
                </div>
              </div>
              <div [ngClass]="irsStatus ? 'collapsecard' : 'expandcard'">
                <div class="card border-0 card-scrollbar">
                  <div class="card-body p-0">
                    <table class="table providerTable">
                      <caption>
                        IRS Match
                      </caption>
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">TIN</th>
                          <th scope="col">IRS Desc.</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="false">
                        <tr class="bg-white">
                          <td class="primary-text font-weight-semibold"></td>
                          <td class="primary-text font-weight-semibold"></td>
                          <td class="primary-text font-weight-semibold"></td>
                        </tr>
                      </tbody>
                      <tbody *ngIf="true">
                        <tr class="bg-white">
                          <td class="text-center" colspan="3">
                            Data not available
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-12">
              <div
                class="accordian-tab card-header spear-table-header-background title-space"
              >
                <h6 class="m-0">FNP Status</h6>
                <div>
                  <button
                    class="btn viewBtn spear-button-background text-white mr-2"
                    (click)="openPopup(createFNPStatus)"
                  >
                    Add
                  </button>
                  <button class="btn">
                    <em
                      (click)="togglePanel('Fnp')"
                      class="fa fa-sort-desc"
                      [ngClass]="fnpStatus ? '' : 'antirotate'"
                    ></em>
                  </button>
                </div>
              </div>
              <div [ngClass]="fnpStatus ? 'collapsecard' : 'expandcard'">
                <div class="card border-0 card-scrollbar">
                  <div class="card-body p-0">
                    <table class="table providerTable">
                      <caption>
                        Associated MPNs
                      </caption>
                      <thead>
                        <tr>
                          <th scope="col">Entity</th>
                          <th scope="col">State</th>
                          <th scope="col">License Status</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="false">
                        <tr class="bg-white">
                          <td class="primary-text font-weight-semibold"></td>
                          <td class="primary-text font-weight-semibold"></td>
                          <td class="primary-text font-weight-semibold"></td>
                        </tr>
                      </tbody>
                      <tbody *ngIf="true">
                        <tr class="bg-white">
                          <td class="text-center" colspan="3">
                            Data not available
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-12">
              <div
                class="accordian-tab card-header spear-table-header-background title-space"
              >
                <h6 class="m-0">Secretary of States</h6>
                <div>
                  <button
                    class="btn viewBtn spear-button-background text-white mr-2"
                    (click)="openPopup(createSecretaryOfStates)"
                  >
                    Add
                  </button>
                  <button class="btn">
                    <em
                      (click)="togglePanel('Secre')"
                      class="fa fa-sort-desc"
                      [ngClass]="secreState ? '' : 'antirotate'"
                    ></em>
                  </button>
                </div>
              </div>
              <div [ngClass]="secreState ? 'collapsecard' : 'expandcard'">
                <div class="card border-0 card-scrollbar">
                  <div class="card-body p-0">
                    <table class="table providerTable">
                      <caption>
                        Secretary of States
                      </caption>
                      <thead>
                        <tr>
                          <th scope="col">Entity</th>
                          <th scope="col">State</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="false">
                        <tr class="bg-white">
                          <td class="primary-text font-weight-semibold"></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                      <tbody *ngIf="true">
                        <tr class="bg-white">
                          <td class="text-center" colspan="3">
                            Data not available
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div
                class="accordian-tab card-header spear-table-header-background title-space"
              >
                <h6 class="m-0">Associated MPN's</h6>
                <div>
                  <button
                    class="btn viewBtn spear-button-background text-white mr-2"
                    (click)="openPopup(createAssociatedMpns)"
                  >
                    Add
                  </button>
                  <button class="btn">
                    <em
                      (click)="togglePanel('Asso')"
                      class="fa fa-sort-desc"
                      [ngClass]="assoStatus ? '' : 'antirotate'"
                    ></em>
                  </button>
                </div>
              </div>
              <div [ngClass]="assoStatus ? 'collapsecard' : 'expandcard'">
                <div class="card border-0 card-scrollbar">
                  <div class="card-body p-0">
                    <table class="table providerTable">
                      <caption>
                        Associated MPN's
                      </caption>
                      <thead>
                        <tr>
                          <th scope="col">Entity</th>
                          <th scope="col">MPN Name</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="false">
                        <tr class="bg-white">
                          <td class="primary-text font-weight-semibold"></td>
                          <td class="primary-text font-weight-semibold"></td>
                        </tr>
                      </tbody>
                      <tbody *ngIf="true">
                        <tr class="bg-white">
                          <td class="text-center" colspan="2">
                            Data not available
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="loader-container">Loading...</div>

          <div class="row">
            <div class="col-12 col-md-6">
              <div
                class="accordian-tab card-header spear-table-header-background"
              >
                <h6>Investigations: (NPI/TIN Selection)</h6>
                <div>
                  <button
                    class="btn viewBtn spear-button-background text-white mr-2"
                    (click)="handleArmorInvestigationAction('edit')"
                  >
                    Edit
                  </button>
                  <button
                    class="btn viewBtn spear-button-background text-white mr-2"
                    (click)="handleArmorInvestigationAction('save')"
                  >
                    Save
                  </button>
                </div>
              </div>
              <div class="card-body pt-0 mb-2">
                <div class="row mt-1">
                  <div class="col-6 text-dark-bold">Status:</div>
                  <div class="col-6 d-flex"></div>
                </div>

                <div class="row mt-1">
                  <div class="col-6">Active SIU Investigation</div>
                  <div class="col-6 d-flex">
                    <input
                      type="text"
                      class="form-control armor-entities-investigation-action"
                      jDate
                      disabled
                    />
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-6">Case Number</div>
                  <div class="col-6 d-flex">
                    <a
                      href="javascript:void(0)"
                      class="primary-text font-weight-semibold"
                      >#12345</a
                    >
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-6">Client Alert Sent</div>
                  <div class="col-6 d-flex">
                    <input
                      type="text"
                      class="form-control armor-entities-investigation-action"
                      jDate
                      disabled
                    />
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-6">FD-1 Sent</div>
                  <div class="col-6 d-flex">
                    <input
                      type="text"
                      class="form-control armor-entities-investigation-action"
                      jDate
                      disabled
                    />
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-6">Bill Review Flag Effective</div>
                  <div class="col-6 d-flex">
                    <input
                      type="text"
                      class="form-control armor-entities-investigation-action"
                      jDate
                      disabled
                    />
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-6">Active LE Investigation</div>
                  <div class="input-group col-6 d-flex">
                    <select
                      class="armor-entities-investigation-action form-control"
                      disabled
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-6">LE Case Status</div>
                  <div class="col-6">
                    <input
                      type="text"
                      class="form-control armor-entities-investigation-action"
                      value="Not Listed"
                      disabled
                    />
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-6">Bulk Settlement</div>
                  <div class="col-6">
                    <select
                      class="armor-entities-investigation-action form-control"
                      disabled
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-6">Lien Consolidation</div>
                  <div class="col-6 d-flex">
                    <select
                      class="armor-entities-investigation-action form-control"
                      disabled
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>
              </div>

              <div id="armor">
                <!-- <div class="accordian-tab card-header spear-table-header-background title-space">
                                    <h6></h6>
                                    <div class="d-flex">
                                      <button
                                        class="btn viewBtn spear-button-background text-white mr-2"
                                        (click)="openAddArmor(createArmor)">Add</button>
                                      <button
                                      class="btn viewBtn spear-button-background text-white mr-2"
                                      >Edit</button>
                                      <button
                                      class="btn viewBtn spear-button-background text-white mr-2"
                                      >Save</button>
                                      <button class="btn">
                                          <em (click)="togglePanel('Armo')" class="fa fa-sort-desc"
                                              [ngClass]="armoStatus ? '' : 'antirotate'"></em>
                                      </button>
                                    </div>
                                </div> -->
                <div [ngClass]="armoStatus ? 'collapsecard' : 'expandcard'">
                  <div class="row table-space">
                    <div class="col-12">
                      <div
                        class="card border-0 card-scrollbar card-scrolbar-armor"
                        [ngClass]="armoStatus ? 'collapsecard' : 'expandcard'"
                      >
                        <div class="table-row">
                          <div>
                            <table class="table providerTable">
                              <caption>
                                Financial Interests
                              </caption>
                              <thead>
                                <tr>
                                  <th class="border-0" scope="col">Pay Code</th>
                                  <th class="border-0" scope="col">
                                    Pay Code Status
                                  </th>
                                  <th class="border-0" scope="col">Flagged</th>
                                  <th class="border-0" scope="col">Date</th>
                                  <th class="border-0" scope="col"></th>
                                </tr>
                              </thead>
                              <tbody *ngIf="armorResopone.length > 0">
                                <tr
                                  class="bg-white"
                                  *ngFor="let row of armorResopone"
                                >
                                  <td
                                    class="link"
                                    (click)="editArmorDetails(row)"
                                  >
                                    {{ row.po_code }}
                                  </td>
                                  <td
                                    class="link"
                                    (click)="editArmorDetails(row)"
                                  >
                                    {{ row.pay_code_status }}
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td class="d-flex">
                                    <button
                                      class="btn small-btn"
                                      (click)="
                                        openDeleteArmor(deleteArmor, row.id)
                                      "
                                    >
                                      <em
                                        class="fa fa-trash"
                                        aria-hidden="true"
                                      ></em>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody *ngIf="armorResopone.length < 1">
                                <tr class="bg-white">
                                  <td colspan="6" class="text-center">
                                    Data not available
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <ng-container *ngIf="editArmorData && false">
                          <div
                            class="accordian-tab card-header spear-table-header-background mt-1"
                          >
                            <h6>Armor Investigative Actions</h6>
                          </div>
                          <div class="card-body pt-0">
                            <form name="armorForm" [formGroup]="armorForm">
                              <div class="row mt-1">
                                <div class="col-6">Pay Code</div>
                                <div class="col-6 d-flex">
                                  <select
                                    class="form-control"
                                    formControlName="armor_po_code"
                                    (change)="selectPayCode($event)"
                                    [ngClass]="!editArmorData ? 'disabled' : ''"
                                  >
                                    <option
                                      value="{{ opt.po_code }}"
                                      *ngFor="let opt of po_codes"
                                      [selected]="
                                        opt.po_code ===
                                        armorForm.get('armor_po_code').value
                                      "
                                    >
                                      {{ opt.po_code }}
                                    </option>
                                  </select>
                                  <button
                                    title="Save"
                                    class="btn small-btn"
                                    (click)="updatePayCode()"
                                    *ngIf="
                                      armorForm.get('armor_po_code').dirty &&
                                      editArmorData.po_code !=
                                        armorForm.get('armor_po_code').value
                                    "
                                  >
                                    <em
                                      class="fa fa-save"
                                      aria-hidden="true"
                                    ></em>
                                  </button>
                                </div>
                              </div>
                              <div class="row mt-1">
                                <div class="col-6">Cross Walk BR Code</div>
                                <div class="col-6 d-flex">
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="armor_br_code"
                                  />
                                  <button
                                    title="Save"
                                    class="btn small-btn"
                                    (click)="updatePayCode()"
                                    *ngIf="
                                      armorForm.get('armor_br_code').dirty &&
                                      editArmorData.armor_br_code !=
                                        armorForm.get('armor_br_code').value
                                    "
                                  >
                                    <em
                                      class="fa fa-save"
                                      aria-hidden="true"
                                    ></em>
                                  </button>
                                </div>
                              </div>
                              <div class="row mt-1">
                                <div class="col-6">State Code</div>
                                <div class="col-6 d-flex">
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="armor_state_code"
                                    [value]="profileModel.details?.state_code"
                                  />
                                  <button
                                    title="Save"
                                    class="btn small-btn"
                                    (click)="updatePayCode()"
                                    *ngIf="
                                      armorForm.get('armor_state_code').dirty &&
                                      editArmorData?.state_code !=
                                        armorForm.get('armor_state_code').value
                                    "
                                  >
                                    <em
                                      class="fa fa-save"
                                      aria-hidden="true"
                                    ></em>
                                  </button>
                                </div>
                              </div>
                              <div class="row mt-1">
                                <div class="col-6">Adjuster Alert Sent</div>
                                <div class="col-6">
                                  <select
                                    class="form-control"
                                    formControlName="armor_adj_alert_sent"
                                  >
                                    <option>Yes</option>
                                    <option>No</option>
                                  </select>
                                </div>
                              </div>
                              <div class="row mt-1">
                                <div class="col-6">Alert Date</div>
                                <div class="input-group col-6 d-flex">
                                  <input
                                    class="form-control"
                                    type="text"
                                    formControlName="armor_alert_date"
                                    [formCtrl]="
                                      armorForm.get('armor_alert_date')
                                    "
                                    jDate
                                  />
                                  <button
                                    title="Save"
                                    class="btn small-btn"
                                    (click)="updatePayCode()"
                                    *ngIf="
                                      armorForm.get('armor_alert_date').dirty &&
                                      editArmorData?.armor_alert_date !=
                                        armorForm.get('armor_alert_date').value
                                    "
                                  >
                                    <em
                                      class="fa fa-save"
                                      aria-hidden="true"
                                    ></em>
                                  </button>
                                </div>
                              </div>
                              <div class="row mt-1">
                                <div class="col-6">Add?</div>
                                <div class="col-6">
                                  <input type="text" class="form-control" />
                                </div>
                              </div>
                              <div class="row mt-1">
                                <div class="col-6">Additional Pay Code</div>
                                <div class="col-6 d-flex">
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="armor_additional_pay_code"
                                  />
                                  <button
                                    title="Save"
                                    class="btn small-btn"
                                    (click)="updatePayCode()"
                                    *ngIf="
                                      armorForm.get('armor_additional_pay_code')
                                        .dirty &&
                                      editArmorData?.armor_additional_pay_code !=
                                        armorForm.get(
                                          'armor_additional_pay_code'
                                        ).value
                                    "
                                  >
                                    <em
                                      class="fa fa-save"
                                      aria-hidden="true"
                                    ></em>
                                  </button>
                                </div>
                              </div>
                              <div class="row mt-1">
                                <div class="col-6">
                                  Effective Objection Date From
                                </div>
                                <div class="input-group col-6 d-flex">
                                  <input
                                    class="form-control"
                                    type="text"
                                    formControlName="armor_effective_obj_from"
                                    [formCtrl]="
                                      armorForm.get('armor_effective_obj_from')
                                    "
                                    jDate
                                  />
                                  <button
                                    title="Save"
                                    class="btn small-btn"
                                    (click)="updatePayCode()"
                                    *ngIf="
                                      armorForm.get('armor_effective_obj_from')
                                        .dirty &&
                                      editArmorData?.armor_effective_obj_from !=
                                        armorForm.get(
                                          'armor_effective_obj_from'
                                        ).value
                                    "
                                  >
                                    <em
                                      class="fa fa-save"
                                      aria-hidden="true"
                                    ></em>
                                  </button>
                                </div>
                              </div>
                              <div class="row mt-1">
                                <div class="col-6">
                                  Effective Objection Date To
                                </div>
                                <div class="input-group col-6 d-flex">
                                  <input
                                    class="form-control"
                                    type="text"
                                    formControlName="armor_effective_obj_to"
                                    [formCtrl]="
                                      armorForm.get('armor_effective_obj_to')
                                    "
                                    jDate
                                  />
                                  <button
                                    title="Save"
                                    class="btn small-btn"
                                    (click)="updatePayCode()"
                                    *ngIf="
                                      armorForm.get('armor_effective_obj_to')
                                        .dirty &&
                                      editArmorData?.armor_effective_obj_to !=
                                        armorForm.get('armor_effective_obj_to')
                                          .value
                                    "
                                  >
                                    <em
                                      class="fa fa-save"
                                      aria-hidden="true"
                                    ></em>
                                  </button>
                                </div>
                              </div>
                              <div class="row mt-1">
                                <div class="col-6">1244/1160 Notice Issued</div>
                                <div class="col-6 d-flex">
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="armor_notice_issued"
                                  />
                                  <button
                                    title="Save"
                                    class="btn small-btn"
                                    (click)="updatePayCode()"
                                    *ngIf="
                                      armorForm.get('armor_notice_issued')
                                        .dirty &&
                                      editArmorData?.armor_notice_issued !=
                                        armorForm.get('armor_notice_issued')
                                          .value
                                    "
                                  >
                                    <em
                                      class="fa fa-save"
                                      aria-hidden="true"
                                    ></em>
                                  </button>
                                </div>
                              </div>
                              <div class="row mt-1">
                                <div class="col-6">
                                  1244/1160 Suspension Date
                                </div>
                                <div class="input-group col-6 d-flex">
                                  <input
                                    class="form-control"
                                    type="text"
                                    formControlName="armor_suspension_date"
                                    [formCtrl]="
                                      armorForm.get('armor_suspension_date')
                                    "
                                    jDate
                                  />
                                  <button
                                    title="Save"
                                    class="btn small-btn"
                                    (click)="updatePayCode()"
                                    *ngIf="
                                      armorForm.get('armor_suspension_date')
                                        .dirty &&
                                      editArmorData?.armor_suspension_date !=
                                        armorForm.get('armor_suspension_date')
                                          .value
                                    "
                                  >
                                    <em
                                      class="fa fa-save"
                                      aria-hidden="true"
                                    ></em>
                                  </button>
                                </div>
                              </div>
                            </form>

                            <div class="row mt-1">
                              <div class="col-6">Corrections Needed</div>
                              <div
                                class="col-6"
                                [ngClass]="{
                                  'd-flex':
                                    armorFormCorrections.get(
                                      'armor_corrections'
                                    ).dirty &&
                                    editArmorData?.correction_needed !=
                                      armorFormCorrections.get(
                                        'armor_corrections'
                                      ).value
                                }"
                              >
                                <form
                                  name="armorFormCorrections"
                                  [formGroup]="armorFormCorrections"
                                >
                                  <textarea
                                    class="form-control"
                                    formControlName="armor_corrections"
                                    [ngClass]="!editArmorData ? 'disabled' : ''"
                                  ></textarea>
                                </form>
                                <button
                                  title="Save"
                                  class="btn small-btn"
                                  (click)="updateCorrections()"
                                  *ngIf="
                                    armorFormCorrections.get(
                                      'armor_corrections'
                                    ).dirty &&
                                    editArmorData?.correction_needed !=
                                      armorFormCorrections.get(
                                        'armor_corrections'
                                      ).value
                                  "
                                >
                                  <em
                                    class="fa fa-save"
                                    aria-hidden="true"
                                  ></em>
                                </button>
                              </div>
                            </div>

                            <div class="row mt-1">
                              <div class="col-6">
                                SIU BR Bill Objection Language
                              </div>
                              <div
                                class="col-6"
                                [ngClass]="{
                                  'd-flex':
                                    armorFormObjection.get('armor_objections')
                                      .dirty &&
                                    editArmorData?.armor_objections !=
                                      armorFormObjection.get('armor_objections')
                                        .value
                                }"
                              >
                                <form
                                  name="armorFormObjection"
                                  [formGroup]="armorFormObjection"
                                >
                                  <textarea
                                    class="form-control"
                                    formControlName="armor_objections"
                                    [ngClass]="!editArmorData ? 'disabled' : ''"
                                  ></textarea>
                                </form>
                                <button
                                  title="Save"
                                  class="btn small-btn"
                                  (click)="updateObjection()"
                                  *ngIf="
                                    armorFormObjection.get('armor_objections')
                                      .dirty &&
                                    editArmorData?.armor_objections !=
                                      armorFormObjection.get('armor_objections')
                                        .value
                                  "
                                >
                                  <em
                                    class="fa fa-save"
                                    aria-hidden="true"
                                  ></em>
                                </button>
                              </div>
                            </div>

                            <div class="row mt-1">
                              <div class="col-6">Adjuster POA</div>
                              <div
                                class="col-6"
                                [ngClass]="{
                                  'd-flex':
                                    armorFormAdjuster.get('armor_adjuster')
                                      .dirty &&
                                    editArmorData?.adjuster_poa !=
                                      armorFormAdjuster.get('armor_adjuster')
                                        .value
                                }"
                              >
                                <form
                                  name="armorFormAdjuster"
                                  [formGroup]="armorFormAdjuster"
                                >
                                  <textarea
                                    class="form-control"
                                    formControlName="armor_adjuster"
                                    [ngClass]="!editArmorData ? 'disabled' : ''"
                                  ></textarea>
                                </form>

                                <button
                                  title="Save"
                                  class="btn small-btn"
                                  (click)="updateAdjuster()"
                                  *ngIf="
                                    armorFormAdjuster.get('armor_adjuster')
                                      .dirty &&
                                    editArmorData?.adjuster_poa !=
                                      armorFormAdjuster.get('armor_adjuster')
                                        .value
                                  "
                                >
                                  <em
                                    class="fa fa-save"
                                    aria-hidden="true"
                                  ></em>
                                </button>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-3 mb-2">
                <div class="accordian-tab">
                  <span class="text-sm text-dark-bold">Adjuster/LIMOS POA</span>
                  <!-- <button class="btn viewBtn spear-button-background text-white" (click)="updateAdjuster()">
                                  Save
                                </button> -->
                </div>
                <div class="card-body">
                  <form
                    name="armorFormAdjuster"
                    [formGroup]="armorFormAdjuster"
                  >
                    <textarea
                      class="form-control"
                      formControlName="armor_adjuster"
                    ></textarea>
                  </form>
                </div>
              </div>

              <div class="mt-2">
                <div class="accordian-tab">
                  <span class="text-sm text-dark-bold">Corrections Needed</span>
                  <!-- <button class="btn viewBtn spear-button-background text-white" (click)="updateCorrections()">
                                  Save
                                </button> -->
                </div>
                <div class="card-body">
                  <form
                    name="armorFormCorrections"
                    [formGroup]="armorFormCorrections"
                  >
                    <textarea
                      class="form-control"
                      formControlName="armor_corrections"
                    ></textarea>
                  </form>
                </div>
              </div>
              <div class="mt-2">
                <div class="accordian-tab">
                  <span class="text-sm text-dark-bold"
                    >SIU BR Bill Objection Language</span
                  >
                  <!-- <button class="btn viewBtn spear-button-background text-white" (click)="updateObjection()">
                                  Save
                                </button> -->
                </div>
                <div class="card-body">
                  <form
                    name="armorFormObjection"
                    [formGroup]="armorFormObjection"
                  >
                    <textarea
                      class="form-control"
                      formControlName="armor_objections"
                    ></textarea>
                  </form>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div id="npi-overview" class="mb-2">
                <div
                  class="accordian-tab card-header spear-table-header-background title-space"
                >
                  <h6 class="m-0">NPI Overview</h6>
                  <div>
                    <button
                      class="btn viewBtn spear-button-background text-white mr-2"
                      (click)="openPopup(createNpiOverview)"
                    >
                      Add
                    </button>
                    <button
                      class="btn viewBtn spear-button-background text-white mr-2"
                    >
                      Edit
                    </button>
                    <button
                      class="btn viewBtn spear-button-background text-white mr-2"
                    >
                      Save
                    </button>
                    <button class="btn">
                      <em
                        (click)="togglePanel('NPI')"
                        class="fa fa-sort-desc"
                        [ngClass]="NPIStatus ? '' : 'antirotate'"
                      ></em>
                    </button>
                  </div>
                </div>
                <div [ngClass]="NPIStatus ? 'collapsecard' : 'expandcard'">
                  <div class="card border-0 card-scrollbar">
                    <div class="card-body p-0">
                      <table class="table providerTable">
                        <caption>
                          NPI
                        </caption>
                        <thead>
                          <tr>
                            <th class="border-0" scope="col">NPI #</th>
                            <th class="border-0" scope="col">Type</th>
                            <th class="border-0" scope="col">Name</th>
                            <th class="border-0" scope="col">Auth Name</th>
                            <th class="border-0" scope="col">NPI Status</th>
                          </tr>
                        </thead>
                        <tbody *ngIf="recordIdentifierAllocatedData?.length">
                          <ng-container
                            *ngFor="
                              let row of recordIdentifierAllocatedData;
                              let i = index
                            "
                          >
                            <tr
                              class="bg-white"
                              *ngIf="
                                i == 0 ||
                                (i &&
                                  row.npi !==
                                    recordIdentifierAllocatedData[i - 1].npi)
                              "
                            >
                              <td class="primary-text font-weight-semibold">
                                {{ row.npi }}
                              </td>
                              <td>
                                {{
                                  row.entity_type_code == 1
                                    ? "Individual"
                                    : "Entity"
                                }}
                              </td>
                              <td>{{ row.provider_name_business }}</td>
                              <td>
                                {{
                                  row.first + " " + row.middle + " " + row.last
                                }}
                              </td>
                              <td>{{ row.status }}</td>
                            </tr>
                          </ng-container>
                        </tbody>
                        <tbody *ngIf="!recordIdentifierAllocatedData?.length">
                          <tr class="bg-white">
                            <td colspan="5" class="text-center">
                              Data not available
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div id="tin-overview" class="mb-2">
                <div
                  class="accordian-tab card-header spear-table-header-background title-space"
                >
                  <h6 class="m-0">TIN's</h6>
                  <div>
                    <button
                      class="btn viewBtn spear-button-background text-white mr-2"
                      (click)="openPopup(createTin)"
                    >
                      Add
                    </button>
                    <button
                      class="btn viewBtn spear-button-background text-white mr-2"
                    >
                      Edit
                    </button>
                    <button
                      class="btn viewBtn spear-button-background text-white mr-2"
                    >
                      Save
                    </button>
                    <button class="btn">
                      <em
                        (click)="togglePanel('Tin')"
                        class="fa fa-sort-desc"
                        [ngClass]="tinStatus ? '' : 'antirotate'"
                      ></em>
                    </button>
                  </div>
                </div>
                <div [ngClass]="tinStatus ? 'collapsecard' : 'expandcard'">
                  <div class="card border-0 card-scrollbar">
                    <div class="card-body p-0">
                      <table class="table providerTable">
                        <caption>
                          TIN
                        </caption>
                        <thead>
                          <tr>
                            <th class="border-0" scope="col">Number</th>
                            <th class="border-0" scope="col">Name</th>
                            <th class="border-0" scope="col">
                              Box 32 NPI Name
                            </th>
                            <th class="border-0" scope="col">Last Billed</th>
                          </tr>
                        </thead>
                        <tbody *ngIf="TinTable.length > 0">
                          <tr class="bg-white" *ngFor="let row of TinTable">
                            <td class="primary-text font-weight-semibold">
                              {{ row.tin }}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                        <tbody *ngIf="TinTable.length < 1">
                          <tr class="bg-white">
                            <td colspan="4" class="text-center">
                              Data not available
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div id="financialinterests" class="mb-1">
                <!-- [cdkDragDisabled]="FinaStatus" -->
                <div
                  class="accordian-tab card-header spear-table-header-background title-space"
                >
                  <h6>Financial Interests</h6>
                  <div class="d-flex">
                    <button
                      class="btn viewBtn spear-button-background text-white mr-2"
                      (click)="openAddAssociationModal('Financial')"
                    >
                      Add
                    </button>
                    <button
                      class="btn viewBtn spear-button-background text-white mr-2"
                    >
                      Edit
                    </button>
                    <button
                      class="btn viewBtn spear-button-background text-white mr-2"
                    >
                      Save
                    </button>
                    <button class="btn">
                      <em
                        (click)="togglePanel('Fina')"
                        class="fa fa-sort-desc"
                        [ngClass]="FinaStatus ? '' : 'antirotate'"
                      ></em>
                    </button>
                  </div>
                </div>
                <div [ngClass]="FinaStatus ? 'collapsecard' : 'expandcard'">
                  <div class="table-row">
                    <div>
                      <table class="table providerTable">
                        <caption>
                          Financial Interests
                        </caption>
                        <thead>
                          <tr>
                            <th class="border-0" scope="col">Name</th>
                            <th class="border-0" scope="col">TIN</th>
                            <th class="border-0" scope="col">NPI</th>
                            <th class="border-0" scope="col">Address</th>
                          </tr>
                        </thead>
                        <tbody *ngIf="recordIdentifierAllocatedData.length > 0">
                          <ng-container
                            *ngFor="
                              let group of financialInterestData;
                              let i = index
                            "
                          >
                            <ng-container
                              *ngFor="let row of group.items; let j = index"
                            >
                              <tr class="bg-white">
                                <td
                                  *ngIf="j == 0"
                                  [attr.rowspan]="group.items.length"
                                >
                                  <a
                                    class="primary-text font-weight-semibold"
                                    href="/profile-report/{{ row.spear_id }}/2"
                                    target="_blank"
                                    >{{ row.provider_name_business }}</a
                                  >
                                </td>
                                <td>{{ row.tin }}</td>
                                <td>{{ row.npi }}</td>
                                <td
                                  *ngIf="j == 0"
                                  [attr.rowspan]="group.items.length"
                                  [innerHTML]="row.address"
                                ></td>
                              </tr>
                            </ng-container>
                          </ng-container>
                        </tbody>
                        <tbody *ngIf="!recordIdentifierAllocatedData.length">
                          <tr class="bg-white">
                            <td colspan="6" class="text-center">
                              Data not available
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="activeTab == 'Notes'">
        <div class="col-12">
          <div
            class="mb-1 font-weight-bold spear-table-header-background text-dark py-2 d-flex align-items-center justify-content-between px-2"
          >
            Notepad

            <button
              class="btn btn-sm spear-button-background rounded text-white px-2 py-1 pr-2 pl-2 ml-2"
              (click)="openAddNoteModal(createNote, 'lg')"
              type="button"
            >
              Add Note
            </button>
          </div>

          <div class="scrolling-box mb-1">
            <div class="table">
              <table
                class="table small-text providerTable table-row cnstr-record product-tbl"
              >
                <caption>
                  Data Table
                </caption>
                <thead>
                  <tr>
                    <th class="border-0" scope="col">DATE</th>
                    <th class="border-0" scope="col">NOTE TYPE</th>
                    <th class="border-0" scope="col">NOTE</th>
                    <th class="border-0" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="4" align="center">Data not available</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="activeTab == 'Documents'">
        <div class="col-12 col-md-6">
          <div id="documents">
            <div
              class="accordian-tab card-header spear-table-header-background title-space"
            >
              <h6>Documents</h6>
              <div class="d-flex">
                <button
                  (click)="openUploadDocument(uploadDocument)"
                  class="btn viewBtn spear-button-background text-white mr-2"
                >
                  Add
                </button>
                <button class="btn">
                  <em
                    (click)="togglePanel('Documents')"
                    class="fa fa-sort-desc"
                    [ngClass]="DocumentsStatus ? '' : 'antirotate'"
                  ></em>
                </button>
              </div>
            </div>
            <div [ngClass]="DocumentsStatus ? 'collapsecard' : 'expandcard'">
              <div class="table-row">
                <table class="table providerTable">
                  <caption>
                    Documents
                  </caption>
                  <thead>
                    <tr>
                      <th class="border-0" scope="col">File Name</th>
                      <th class="border-0" scope="col">Uploaded On</th>
                      <th class="border-0" scope="col"></th>
                      <th class="border-0" scope="col"></th>
                    </tr>
                  </thead>
                  <tbody *ngIf="isDocuments">
                    <tr class="bg-white" *ngFor="let file of Document">
                      <td class="primary-text font-weight-semibold">
                        {{ file.filename }}
                      </td>
                      <td>
                        {{ file.uploaddt | date : "MM/dd/yyyy" }}{{ file.id }}
                      </td>
                      <td>
                        <button (click)="download(file.id)">
                          <em
                            class="fa fa-arrow-circle-down"
                            aria-hidden="true"
                          ></em>
                        </button>
                      </td>
                      <td>
                        <button (click)="deleteDocument(file.id, spearIDS)">
                          <em class="fa fa-trash" aria-hidden="true"></em>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="!isDocuments">
                    <tr class="bg-white">
                      <td colspan="4" class="text-center">
                        Data not available
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="activeTab == 'RECORD IDENTIFIERS'">
        <div class="col-12 mb-2">
          <div id="record-identifiers">
            <nav>
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button
                  class="nav-link bg-transparent border-0 shadow-none"
                  [ngClass]="{
                    'active show spear-table-header-background':
                      activeRecordIdentifierTab == 'Allocated'
                  }"
                  id="nav-home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-allocated"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                  (click)="activeRecordIdentifierTab = 'Allocated'"
                >
                  <b>Allocated</b>
                </button>
                <button
                  class="nav-link bg-transparent border-0 shadow-none"
                  [ngClass]="{
                    'active show spear-table-header-background':
                      activeRecordIdentifierTab == 'Unallocated'
                  }"
                  id="nav-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-unallocated"
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                  (click)="activeRecordIdentifierTab = 'Unallocated'"
                >
                  <b>Unallocated</b>
                </button>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane"
                [ngClass]="{ active: activeRecordIdentifierTab == 'Allocated' }"
                id="nav-allocated"
                role="tabpanel"
              >
                <div
                  class="accordian-tab card-header spear-table-header-background title-space py-3"
                >
                  <h6></h6>
                </div>
                <div class="expandcard">
                  <div
                    class="table-row"
                    *blockUI="'record-identifier-block-ui'"
                  >
                    <table class="table providerTable">
                      <thead>
                        <tr>
                          <th class="border-0" scope="col">Record Source</th>
                          <th class="border-0" scope="col">Business Name</th>
                          <th class="border-0" scope="col">First</th>
                          <th class="border-0" scope="col">Middle</th>
                          <th class="border-0" scope="col">Last</th>
                          <th class="border-0" scope="col">NPI</th>
                          <th class="border-0" scope="col">Tax ID</th>
                          <th class="border-0" scope="col">Address</th>
                          <th class="border-0" scope="col">Suite</th>
                          <th class="border-0" scope="col">City</th>
                          <th class="border-0" scope="col">State</th>
                          <th class="border-0" scope="col">ZIP</th>
                          <th class="border-0" scope="col">Provider Phone</th>
                          <th class="border-0" scope="col">Practice Phone</th>
                          <th class="border-0" scope="col">FAX</th>
                          <th class="border-0" scope="col">Address Type</th>
                          <th class="border-0" scope="col">License #</th>
                          <th class="border-0" scope="col">License State</th>
                          <th class="border-0" scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="bg-white"
                          *ngIf="!recordIdentifierAllocatedData.length"
                        >
                          <td colspan="21" class="text-center">
                            Data not available
                          </td>
                        </tr>
                        <ng-container
                          *ngIf="recordIdentifierAllocatedData.length"
                        >
                          <tr
                            class="bg-white"
                            *ngFor="let entry of recordIdentifierAllocatedData"
                          >
                            <td>{{ entry.source }}</td>
                            <td>{{ entry.provider_name_business }}</td>
                            <td>{{ entry.first }}</td>
                            <td>{{ entry.middle }}</td>
                            <td>{{ entry.last }}</td>
                            <td>{{ entry.npi }}</td>
                            <td>{{ entry.tax_id_number }}</td>
                            <td>{{ entry.address }}</td>
                            <td>{{ entry.suite }}</td>
                            <td>{{ entry.city }}</td>
                            <td>{{ entry.state }}</td>
                            <td>{{ entry.zip }}</td>
                            <td>{{ entry.phone }}</td>
                            <td>{{ entry.phone_practice }}</td>
                            <td>{{ entry.fax }}</td>
                            <td class="text-capitalize">
                              {{ entry.address_type }}
                            </td>
                            <td>{{ entry.license_id_number }}</td>
                            <td>{{ entry.license_state }}</td>
                            <td class="text-capitalize">{{ entry.status }}</td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane"
                [ngClass]="{
                  active: activeRecordIdentifierTab == 'Unallocated'
                }"
                id="nav-unallocated"
                role="tabpanel"
              >
                <div
                  class="accordian-tab card-header spear-table-header-background title-space py-2"
                >
                  <div class="text-right w-100">
                    <button
                      class="btn btn-sm spear-button-background viewBtn text-white rounded"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <div class="expandcard">
                  <div
                    class="table-row"
                    *blockUI="'record-identifier-block-ui'"
                  >
                    <table class="table providerTable">
                      <thead>
                        <tr>
                          <th class="border-0" scope="col">Record Source</th>
                          <th class="border-0" scope="col">Business Name</th>
                          <th class="border-0" scope="col">First</th>
                          <th class="border-0" scope="col">Middle</th>
                          <th class="border-0" scope="col">Last</th>
                          <th class="border-0" scope="col">NPI</th>
                          <th class="border-0" scope="col">NPI Lookup</th>
                          <th class="border-0" scope="col">Tax ID</th>
                          <th class="border-0" scope="col">Tax ID Lookup</th>
                          <th class="border-0" scope="col">Address</th>
                          <th class="border-0" scope="col">Suite</th>
                          <th class="border-0" scope="col">City</th>
                          <th class="border-0" scope="col">State</th>
                          <th class="border-0" scope="col">ZIP</th>
                          <th class="border-0" scope="col">Provider Phone</th>
                          <th class="border-0" scope="col">Practice Phone</th>
                          <th class="border-0" scope="col">FAX</th>
                          <th class="border-0" scope="col">Address Type</th>
                          <th class="border-0" scope="col">License #</th>
                          <th class="border-0" scope="col">License State</th>
                          <th class="border-0" scope="col">
                            Medical Board Lookup
                          </th>
                          <th class="border-0" scope="col">Calculation</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="bg-white"
                          *ngIf="!recordIdentifierUnallocatedData.length"
                        >
                          <td colspan="22" class="text-center">
                            Data not available
                          </td>
                        </tr>
                        <ng-container
                          *ngIf="recordIdentifierUnallocatedData.length"
                        >
                          <tr
                            class="bg-white"
                            *ngFor="
                              let entry of recordIdentifierUnallocatedData
                            "
                          >
                            <td>{{ entry.source }}</td>
                            <td>{{ entry.provider_name_business }}</td>
                            <td>{{ entry.first }}</td>
                            <td>{{ entry.middle }}</td>
                            <td>{{ entry.last }}</td>
                            <td>{{ entry.npi }}</td>
                            <td></td>
                            <td>{{ entry.tax_id_number }}</td>
                            <td></td>
                            <td>{{ entry.address }}</td>
                            <td>{{ entry.suite }}</td>
                            <td>{{ entry.city }}</td>
                            <td>{{ entry.state }}</td>
                            <td>{{ entry.zip }}</td>
                            <td>{{ entry.phone }}</td>
                            <td>{{ entry.phone_practice }}</td>
                            <td>{{ entry.fax }}</td>
                            <td class="text-capitalize">
                              {{ entry.address_type }}
                            </td>
                            <td>{{ entry.license_id_number }}</td>
                            <td>{{ entry.license_state }}</td>
                            <td></td>
                            <td></td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<ng-template #createArmor let-modal>
  <div class="modal-body">
    <p class="text-center">Please select the spear id you want to associate</p>
    <select
      class="form-control"
      id="add_armor_spear_id"
      [(ngModel)]="add_armor_spear_id"
    >
      <option *ngFor="let opt of spearIDS" value="{{ opt }}">{{ opt }}</option>
    </select>
    <p>Please select provider from the search result list</p>
    <div class="d-flex flex-row align-items-center mb-2">
      <select
        class="form-control mr-4 mb-0"
        id="pay_code_list"
        [disabled]="!add_armor_spear_id"
      >
        <option value="{{ opt.po_code }}" *ngFor="let opt of po_codes">
          {{ opt.po_code }}
        </option>
      </select>
      <button
        class="btn spear-button-background text-white"
        (click)="addArmor()"
        [disabled]="!add_armor_spear_id"
      >
        Add
      </button>
    </div>
    <div class="text-center">
      <button
        class="btn spear-button-background text-white"
        (click)="closeAddArmor()"
      >
        Close
      </button>
    </div>
  </div>
</ng-template>
<ng-template #deleteArmor let-modal>
  <div class="modal-body">
    <p class="text-center">Are you sure you want to delete this record?</p>
    <div class="text-center">
      <button
        class="btn spear-button-background text-white mr-4"
        (click)="removeArmor()"
      >
        Yes
      </button>
      <button
        class="btn spear-button-background text-white"
        (click)="closeAddArmor()"
      >
        No
      </button>
    </div>
  </div>
</ng-template>
<ng-template #uploadDocument let-modal>
  <div class="modal-body">
    <div class="row mb-2">
      <div class="col-12">
        <div>
          <div class="text-dark fw-500 mb-1" for="noteFiles">
            Files
            <span class="text-sm mr-1 text-gray">(Max File Size: 5G)</span>
            <button
              class="btn btn-sm btn-primary rounded"
              (click)="openNotefilesInputDialog(notefilesInput)"
              title="Add Files"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
          <input
            type="file"
            #notefilesInput
            class="d-none"
            name="noteFiles"
            id="noteFiles"
            multiple
            accept=".pdf,.xls,.xlsx"
            (change)="onNoteFileSelected($event)"
          />
        </div>
        <div *ngIf="selectedNoteFiles?.length; else noFilesSelected">
          <ol class="px-3">
            <li
              *ngFor="let file of selectedNoteFiles; let i = index"
              class="text-black text-sm"
            >
              <span class="text-dark fw-500">{{ file.name }}</span> ({{
                file.formattedSize
              }})
              <button
                class="btn btn-sm text-danger"
                title="Remove"
                (click)="selectedNoteFiles.splice(i, 1)"
              >
                <em class="fa fa-remove"></em>
              </button>
            </li>
          </ol>
        </div>
        <ng-template #noFilesSelected>
          <span class="text-sm">No Files selected.</span>
        </ng-template>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-right">
        <div>
          <button
            type="button"
            class="btn btn-secondary btn-sm text-white mr-1"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn limos-button-background btn-sm text-white"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #disassociate let-modal>
  <div class="modal-body">
    <p class="text-center">Are you sure you want to disassociate?</p>
    <div class="text-center">
      <button
        class="btn spear-button-background text-white mr-4"
        (click)="disAssociate()"
      >
        Yes
      </button>
      <button
        class="btn spear-button-background text-white"
        (click)="closeDisassociate()"
      >
        No
      </button>
    </div>
  </div>
</ng-template>

<ng-template #createLicense let-modal>
  <div class="modal-header">
    <h6>Add License</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="addmodalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mb-2">
      <div class="col-6">
        <div>
          <span for="license_number">License Number</span>
          <input type="text" name="license_number" id="license_number" />
        </div>
      </div>
      <div class="col-6">
        <div>
          <span for="license_type">Type</span>
          <input type="text" name="license_type" id="license_type" />
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-6">
        <div>
          <span for="license_state">State</span>
          <input type="text" name="license_state" id="license_state" />
        </div>
      </div>
      <div class="col-6">
        <div>
          <span for="license_status">Status</span>
          <input type="text" name="license_status" id="license_status" />
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-6">
        <div>
          <span for="license_secondary_status">Secondary Status</span>
          <input
            type="text"
            name="license_secondary_status"
            id="license_secondary_status"
          />
        </div>
      </div>
    </div>
    <div class="text-right">
      <button
        class="btn btn-secondary btn-sm text-white mr-1"
        (click)="addmodalService.dismissAll()"
      >
        Cancel
      </button>
      <button class="btn spear-button-background btn-sm text-white">
        Save
      </button>
    </div>
  </div>
</ng-template>

<ng-template #createIRSMatch let-modal>
  <div class="modal-header">
    <h6>Add IRS Match</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="addmodalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mb-2">
      <div class="col-6">
        <div>
          <span for="irs_name">Name</span>
          <input type="text" name="irs_name" id="irs_name" />
        </div>
      </div>
      <div class="col-6">
        <div>
          <span for="irs_tin">TIN</span>
          <input type="text" name="irs_tin" id="irs_tin" />
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-6">
        <div>
          <span for="irs_description">IRS Description</span>
          <input type="text" name="irs_description" id="irs_description" />
        </div>
      </div>
    </div>

    <div class="text-right">
      <button
        class="btn btn-secondary btn-sm text-white mr-1"
        (click)="addmodalService.dismissAll()"
      >
        Cancel
      </button>
      <button class="btn spear-button-background btn-sm text-white">
        Save
      </button>
    </div>
  </div>
</ng-template>

<ng-template #createFNPStatus let-modal>
  <div class="modal-header">
    <h6>Add FNP Status</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="addmodalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mb-2">
      <div class="col-6">
        <div>
          <span for="fnp_entity">Entity</span>
          <input type="text" name="fnp_entity" id="fnp_entity" />
        </div>
      </div>
      <div class="col-6">
        <div>
          <span for="fnp_state">State</span>
          <input type="text" name="fnp_state" id="fnp_state" />
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-6">
        <div>
          <span for="fnp_license_status">License Status</span>
          <input
            type="text"
            name="fnp_license_status"
            id="fnp_license_status"
          />
        </div>
      </div>
    </div>

    <div class="text-right">
      <button
        class="btn btn-secondary btn-sm text-white mr-1"
        (click)="addmodalService.dismissAll()"
      >
        Cancel
      </button>
      <button class="btn spear-button-background btn-sm text-white">
        Save
      </button>
    </div>
  </div>
</ng-template>

<ng-template #createSecretaryOfStates let-modal>
  <div class="modal-header">
    <h6>Add Secretary Of States</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="addmodalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mb-2">
      <div class="col-6">
        <div>
          <span for="secretary_of_status_entity">Entity</span>
          <input
            type="text"
            name="secretary_of_status_entity"
            id="secretary_of_status_entity"
          />
        </div>
      </div>
      <div class="col-6">
        <div>
          <span for="secretary_of_status_state">State</span>
          <input
            type="text"
            name="secretary_of_status_state"
            id="secretary_of_status_state"
          />
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-6">
        <div>
          <span for="secretary_of_status_status">Status</span>
          <input
            type="text"
            name="secretary_of_status_status"
            id="secretary_of_status_status"
          />
        </div>
      </div>
    </div>

    <div class="text-right">
      <button
        class="btn btn-secondary btn-sm text-white mr-1"
        (click)="addmodalService.dismissAll()"
      >
        Cancel
      </button>
      <button class="btn spear-button-background btn-sm text-white">
        Save
      </button>
    </div>
  </div>
</ng-template>

<ng-template #createAssociatedMpns let-modal>
  <div class="modal-header">
    <h6>Add Associated MPN's</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="addmodalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mb-2">
      <div class="col-6">
        <div>
          <span for="associated_mpns_entity">Entity</span>
          <input
            type="text"
            name="associated_mpns_entity"
            id="associated_mpns_entity"
          />
        </div>
      </div>
      <div class="col-6">
        <div>
          <span for="associated_mpns_name">MPN Name</span>
          <input
            type="text"
            name="associated_mpns_name"
            id="associated_mpns_name"
          />
        </div>
      </div>
    </div>

    <div class="text-right mt-4">
      <button
        class="btn btn-secondary btn-sm text-white mr-1"
        (click)="addmodalService.dismissAll()"
      >
        Cancel
      </button>
      <button class="btn spear-button-background btn-sm text-white">
        Save
      </button>
    </div>
  </div>
</ng-template>

<ng-template #createNpiOverview let-modal>
  <div class="modal-header">
    <h6>Add NPI Overview</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="addmodalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mb-2">
      <div class="col-6">
        <div>
          <span for="npi_overview_number">NPI #</span>
          <input
            type="text"
            name="npi_overview_number"
            id="npi_overview_number"
          />
        </div>
      </div>
      <div class="col-6">
        <div>
          <span for="npi_overview_type">Type</span>
          <input type="text" name="npi_overview_type" id="npi_overview_type" />
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-6">
        <div>
          <span for="npi_overview_name">Name</span>
          <input type="text" name="npi_overview_name" id="npi_overview_name" />
        </div>
      </div>
      <div class="col-6">
        <div>
          <span for="npi_auth_name">Auth Name</span>
          <input type="text" name="npi_auth_name" id="npi_auth_name" />
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-6">
        <div>
          <span for="npi_overview_status">NPI Status</span>
          <input
            type="text"
            name="npi_overview_status"
            id="npi_overview_status"
          />
        </div>
      </div>
    </div>

    <div class="text-right mt-4">
      <button
        class="btn btn-secondary btn-sm text-white mr-1"
        (click)="addmodalService.dismissAll()"
      >
        Cancel
      </button>
      <button class="btn spear-button-background btn-sm text-white">
        Save
      </button>
    </div>
  </div>
</ng-template>

<ng-template #createTin let-modal>
  <div class="modal-header">
    <h6>Add Tin</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="addmodalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mb-2">
      <div class="col-6">
        <div>
          <span for="tin_number">Number</span>
          <input type="text" name="tin_number" id="tin_number" />
        </div>
      </div>
      <div class="col-6">
        <div>
          <span for="tin_name">Name</span>
          <input type="text" name="tin_name" id="tin_name" />
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-6">
        <div>
          <span for="npi_overview_name">Name</span>
          <input type="text" name="npi_overview_name" id="npi_overview_name" />
        </div>
      </div>
      <div class="col-6">
        <div>
          <span for="tin_box_32_npi_name">Box 32 NPI Name</span>
          <input
            type="text"
            name="tin_box_32_npi_name"
            id="tin_box_32_npi_name"
          />
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-6">
        <div>
          <span for="tin_last_billed">Last Billed</span>
          <input type="text" name="tin_last_billed" id="tin_last_billed" />
        </div>
      </div>
    </div>

    <div class="text-right mt-4">
      <button
        class="btn btn-secondary btn-sm text-white mr-1"
        (click)="addmodalService.dismissAll()"
      >
        Cancel
      </button>
      <button class="btn spear-button-background btn-sm text-white">
        Save
      </button>
    </div>
  </div>
</ng-template>

<ng-template #createNote let-modal>
  <div class="modal-header">
    <h6>Add Note</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="addmodalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body create-note text-sm">
    <div class="row mb-2">
      <div class="col-6">
        <div>
          <span class="text-dark fw-500 mr-1" for="access_group"
            >Access Group</span
          ><span class="text-danger text-sm">*</span>
          <select name="access_group" id="access_group" class="form-control">
            <option value="">Select</option>
            <option [value]="opt" *ngFor="let opt of accessGroups">
              {{ opt }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div>
          <span class="text-dark fw-500" for="subject">Subject</span>
          <input type="text" name="subject" id="subject" class="form-control" />
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-12">
        <div>
          <span class="text-dark fw-500 mr-1" for="noteTitle">Title</span
          ><span class="text-danger text-sm">*</span>
          <input
            type="text"
            name="noteTitle"
            id="noteTitle"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <div>
          <span class="text-dark fw-500 mr-1" for="noteBody">Body</span
          ><span class="text-danger text-sm">*</span>
          <ckeditor
            id="noteBody"
            [editor]="editor"
            [(ngModel)]="noteBodyeditor"
            [data]="noteBodyeditor"
          ></ckeditor>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <div>
          <span class="text-dark fw-500" for="noteTags">Tags</span>
          <span class="text-sm">(Press enter to add tags.)</span>
          <div class="border p-1 rounded">
            <ng-container *ngFor="let tag of selectedNoteTags; let i = index">
              <span class="bg-light-dark text-dark text-sm mr-1 p-1 rounded">
                {{ tag }}
                <em
                  title="Remove"
                  class="fa fa-remove text-danger cursor-pointer"
                  (click)="selectedNoteTags.splice(i, 1)"
                ></em>
              </span>
            </ng-container>
            <input
              type="text"
              name="noteTags"
              id="noteTags"
              class="border-0 text-sm"
              placeholder="Enter tags here"
              (keydown.enter)="addNoteTags($event)"
            />
          </div>
          <span class="text-sm"
            >A tag is a keyword or label that categorizes your update with
            other, similar updates.</span
          >
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-12">
        <div>
          <div class="text-dark fw-500 mb-1" for="noteFiles">
            Files
            <span class="text-sm mr-1 text-gray">(Max File Size: 5G)</span>
            <button
              class="btn btn-sm btn-primary rounded"
              (click)="openNotefilesInputDialog(notefilesInput)"
              title="Add Files"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
          <input
            type="file"
            #notefilesInput
            class="d-none"
            name="noteFiles"
            id="noteFiles"
            multiple
            accept=".pdf,.xls,.xlsx"
            (change)="onNoteFileSelected($event)"
          />
        </div>
        <div *ngIf="selectedNoteFiles?.length; else noFilesSelected">
          <ol class="px-3">
            <li
              *ngFor="let file of selectedNoteFiles; let i = index"
              class="text-black text-sm"
            >
              <span class="text-dark fw-500">{{ file.name }}</span> ({{
                file.formattedSize
              }})
              <button
                class="btn btn-sm text-danger"
                title="Remove"
                (click)="selectedNoteFiles.splice(i, 1)"
              >
                <em class="fa fa-remove"></em>
              </button>
            </li>
          </ol>
        </div>
        <ng-template #noFilesSelected>
          <span class="text-sm">No Files selected.</span>
        </ng-template>
      </div>
    </div>

    <div class="mt-4 d-flex justify-content-between">
      <div>
        <input
          type="checkbox"
          name="expense_entry"
          id="expense_entry"
          [(ngModel)]="createExpenseEntry"
        /><label for="expense_entry" class="text-dark fw-500 ml-1"
          >Create expense entry after save</label
        >
      </div>
      <div>
        <button
          type="button"
          class="btn btn-secondary btn-sm text-white mr-1"
          (click)="addmodalService.dismissAll()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn limos-button-background btn-sm text-white"
          (click)="createNotepad(createTimeSpent)"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #createTimeSpent let-modal>
  <div class="modal-header">
    <h6>Add Time Spent</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="addmodalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body create-time-spent">
    <div class="row mb-2">
      <div class="col-12">
        <div>
          <input
            type="text"
            name="time_spent"
            id="time_spent"
            class="form-control text-center"
            mask="00:00:00"
            placeholder="hh:mm:ss"
          />
        </div>
      </div>
    </div>

    <div class="mt-4 text-right">
      <div>
        <button
          type="button"
          class="btn btn-secondary btn-sm text-white mr-1"
          (click)="addmodalService.dismissAll()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn limos-button-background btn-sm text-white"
          (click)="createExpense()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</ng-template>
