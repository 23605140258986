<div class="container-fluid p-0">
    <div class="row">
        <div class="col-md-12 pr-0">
            <div class="card border-0">
                <div class="card-body search-spear-background">
                    <div class="mb-2 d-flex justify-content-between align-items-center">
                        <div>
                            <h3 class="page-title">Entities</h3>
                        </div>
                    </div>
                    <div class="table-row">
                        <table class="table providerTable mb-0 spear-search-table-header-background">
                            <caption>Investigation</caption>
                            <thead>
                                <tr>
                                    <th class="border-0" scope="col">Investigation</th>
                                    <th class="border-0" scope="col">Total Providers</th>
                                    <th class="border-0" scope="col">Created By</th>
                                    <th class="border-0" scope="col">Created</th>
                                    <th class="border-0" scope="col">Updated</th>
                                    <th class="border-0" scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="investigation_data.length>0">
                                <tr class="bg-white" *ngFor="let row of investigation_data" >
                                    <td>
                                        <div id="entityName-{{row.id}}" >
                                            <a href="/entities-report/{{row.id}}" target="_blank" class="cursor-pointer primary-text font-weight-semibold">{{row.entity_name}}</a>
                                        </div>
                                        <input id="entity_name-{{row.id}}" disabled="disabled" class="form-control d-none"
                                            value="{{row.entity_name}}" />
                                    </td>
                                    <td>{{row.associations.length || '&#8212;'}}</td>
                                    <td>{{row.created_by_name || '&#8212;'}}</td>
                                    <td>{{(row.created_at | date:'MM/dd/yyyy') || '&#8212;'}}</td>
                                    <td>{{(row.modified_at | date:'MM/dd/yyyy') || '&#8212;'}}</td>
                                    <td>
                                        <button id="editEntity-{{row.id}}" class="icon-button mr-2 editEntity spear-button-icon"
                                            (click)="makeEditableEntity(row.id)"><em class="fa fa-pencil"></em></button>
                                        <button id="saveEntity-{{row.id}}" class="icon-button mr-2 spear-button-icon"
                                            (click)="saveEditableEntity(row.id)" hidden><em
                                                class="fa fas fa-check"></em></button>
                                        <button id="cancelEntity-{{row.id}}" class="icon-button mr-2 spear-button-icon"
                                            (click)="cancelEditableEntity(row.id)" hidden><em
                                                class="fa fas fa-close"></em></button>
                                        <button id="deleteEntity-{{row.id}}" class="icon-button spear-button-icon" (click)="del(deletecontent, row.id)"><em
                                                class="fa fa-trash"></em></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="investigation_data.length<1">
                                <tr class="bg-white">
                                    <td align="center" colspan="6">
                                        Data Not Available
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Create Entities Modal -->
<ng-template #content let-modal>
    <div class="modal-header px-0 mb-5">
        <h4 class="modal-title" id="modal-basic-title">Create New Entities</h4>
        <button type="button" class="btn d-block ml-auto btnclose text-gray rounded-circle p-0" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&#215;</span>
        </button>
    </div>
    <div class="modal-body px-0">
        <form>
            <div class="form-group">
                <label class="subtitle-modal p0">Enter Name</label>
                <input class="form-control mb-3" id="entityName" type="text" value="" (click)="onClick()">
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 p-0 mb-4">
        <button type="button" class="rounded-0 btn text-white primary-bg mb-0 btnApply"
            (click)="onSubmit()">Submit</button>
    </div>
</ng-template>

<!--Create Provider Modal -->
<ng-template #content1 let-modal>
    <div class="modal-header px-0 mb-3">
        <h4 class="modal-title" id="modal-basic-title">Select Provider</h4>
        <button type="button" class="btn d-block ml-auto btnclose text-gray rounded-circle p-0" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">Entity</span>
        </button>
    </div>
    <div class="modal-body px-0">
        <form>
            <div class="form-group">
                <label class="subtitle-modal p0">Providers Name</label>
                <tag-input class="tagChips" [ngModel]="chipsInput" [placeholder]='"Start Typing Provider ID"'
                    [secondaryPlaceholder]='"Start Typing Provider ID"'></tag-input>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 p-0 mb-4">
        <button type="button" class="rounded-0 btn text-white primary-bg mb-0" (click)="modal.close('Save click')">Add
            to Group</button>
    </div>
</ng-template>

<!--Delete Group Modal -->
<ng-template #deletecontent let-modal>
    <div class="modal-header px-0 pt-4 border-0 text-center row">
        <div class="mb-3 col-md-12">
            <img src="assets/images/del-icon.png" alt="">
        </div>
        <div class="col-md-12">
            <h4 class="modal-title border-0" id="modal-basic-title">Please share the reason why you want to delete the
                entities.</h4>
        </div>
    </div>
    <div class="modal-body px-0 text-center">
        <form>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group mb-4 px-3">
                        <input type="text" id="firstname" class="form-control" placeholder="Type Reason">
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 p-0 mb-4 justify-content-center">
        <button type="button" class="rounded-0 btn btn-secondary-outline bg-white text-voilet mb-0"
            (click)="modal.close('Save click')">Cancel</button>
        <button type="button" class="rounded-0 btn text-white primary-bg mb-0 ml-3 mr-0"
            (click)="cnfdel(cnfdeletecontent)">Submit</button>
    </div>
</ng-template>


<!--Confirm Delete Group Modal -->
<ng-template #cnfdeletecontent let-modal>
    <div class="modal-header px-0 pt-4 border-0 text-center row">
        <div class="mb-3 col-md-12">
            <img src="assets/images/delete-cnfrm-icon.png" alt="">
        </div>
        <div class="col-md-12">
            <h4 class="modal-title border-0" id="modal-basic-title">Are you sure you want to delete this Entities?</h4>
        </div>
    </div>
    <div class="modal-body px-0 text-center">
        <div class="row">
            <div class="col-md-12">
                <p class="mb-4">This action cannot be undone.</p>
            </div>
        </div>
    </div>
    <div class="modal-footer border-0 p-0 mb-4 justify-content-center">
        <button type="button" class="rounded-0 btn btn-secondary-outline bg-white text-voilet mb-0"
            (click)="modal.close('Save click')">No, Cancel</button>
        <button type="button" class="rounded-0 btn text-white primary-bg mb-0 ml-3 mr-0" (click)="deleteEntity()">Yes,
            Delete</button>
    </div>
</ng-template>
