// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// ssl added 

export const environment = {
  production: false,
  //apiUrl: 'https://localhost:44341/',
  //apiUrl: 'http://api-dev-spear.aspcore.net/',
  apiUrl: 'https://api-qa-spear.aspcore.net/',
  meiliSearchInstanceUrl: 'https://search.argusspear.com/indexes/TestSpearSearch/search',  
  apiCredentail: {
    username: 'successive',
    password: 'swordfish1!',
  },  
  fireStoreApiUrl: 'https://spear-django-uq3cjixc7q-uw.a.run.app',
  // token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg5NzU3OTM5LCJqdGkiOiJlY2Q0Yzc2YmRiNmY0MTcwOTQyOGE1MmY3YzE4MDQwNyIsInVzZXJfaWQiOjN9.hK4ShPhHiOM_Cxz4GfcjTl2uSpzIsRwJXnJpAjm95Qw',
  paging: {
    defaultPageSize: 10,
    lengthMenu: [10, 15, 25, 50, 100],
  },
  googleClientId: '112767788198-l6mue54i0hvmc6ekit8a6jvqv7vus5vd.apps.googleusercontent.com',
  dateTimeFormat: {
    date: 'yyyy-MM-dd',
    dateTime: 'yyyy-MM-dd h:mm:ss a',
    dateTime_minutes: 'yyyy-MM-dd h:mm a',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
