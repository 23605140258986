import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordModel } from 'src/app/models/account/change.password.model';
import { AccountService } from 'src/app/services/account.service';
import { AppUtils } from 'src/app/utilities/app.utils';
import { ToastrService } from 'ngx-toastr';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent implements OnInit {
  @BlockUI('show-loader') blockUI: NgBlockUI;
   changePasswordModel: ChangePasswordModel = {
    currentPassword: null,
    newPassword: null,
    confirm: null};
  // tslint:disable-next-line: variable-name
  constructor(private _NgbActiveModal: NgbActiveModal, private accountService: AccountService,
              private appUtils: AppUtils,
              protected vcr: ViewContainerRef,
              private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  // tslint:disable-next-line: typedef
  get activeModal() {
    return this._NgbActiveModal;
  }

// tslint:disable-next-line: typedef
changePassword(){
  this.blockUI.start();
  this.accountService.changePassword(this.changePasswordModel).subscribe(
  data => {
    this.toastr.success('Password has been changed successfully');
    this._NgbActiveModal.dismiss();
    this.blockUI.stop();
  },
  error => {
      this.blockUI.stop();
      this.appUtils.ProcessErrorResponse(this.toastr, error);
  });
  }
}
