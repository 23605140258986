<div class="container-fluid p-0">
    <div class="row">
        <div class="col-md-12">
            <div class="w-100 pt-4">
                <div class="row mb-2 mb-xl-3">
                    <div class="col-auto w-100 d-sm-block">
                        <a class="cursor-pointer text-light-voilet mb-4" routerLink='/'><em class="fa fa-chevron-left mr-3"></em>Back to List</a>
                    </div>
                    <div class="col-auto d-sm-block">
                        <!-- <h3 class="page-title">100215578</h3> -->
                        <!-- <p>Here is the all info about the above ID. You can edit and update the info here.</p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <perfect-scrollbar class="full-page-scroll">
        <div class="row row-page-scroll">
            <div class="col-md-3 col-2-5">
                <div class="card border-0 p-3">
                    <div class="card-header bg-white p-0 pb-3">
                        <div class="d-flex justify-content-between align-items-center">
                            <h3 class="Uname mb-0">Profile</h3>
                        </div>
                    </div>
                    <div class="card-body row px-0">
                        <div class="col-md-12">
                            <span class="mb-0 detail-title">Spear Id</span>
                            <p class="text-black detail-subtitle text-uppercase">{{profileId}}</p>
                        </div>
                        <div class="col-md-6">
                            <span class="mb-0 detail-title">First Name</span>
                            <p class="text-black detail-subtitle">{{profileModel.details?.firstname || 'Record not available'}}</p>
                        </div>
                        <div class="col-md-6">
                            <span class="mb-0 detail-title">Middle Name</span>
                            <p class="text-black detail-subtitle">{{profileModel.details?.middlename || 'Record not available'}}</p>
                        </div>
                        <div class="col-md-12">
                            <span class="mb-0 detail-title">Last Name</span>
                            <p class="text-black detail-subtitle">{{profileModel.details?.lastname || 'Record not available'}}</p>
                        </div>
                        <div class="col-md-6">
                            <span class="mb-0 detail-title">Address 1</span>
                            <p class="text-black detail-subtitle">{{profileModel.details?.provider_street_address_1 || 'Record not available'}}</p>
                        </div>
                        <div class="col-md-6">
                            <span class="mb-0 detail-title">Address 2</span>
                            <p class="text-black detail-subtitle">{{profileModel.details?.provider_street_address_2 || 'Record not available'}}</p>
                        </div>
                        <div class="col-md-6">
                            <span class="mb-0 detail-title">City</span>
                            <p class="text-black detail-subtitle">{{profileModel.details?.provider_city || 'Record not available'}}</p>
                        </div>
                        <div class="col-md-6">
                            <span class="mb-0 detail-title">State</span>
                            <p class="text-black detail-subtitle">{{profileModel.details?.provider_state || 'Record not available'}}</p>
                        </div>
                        <div class="col-md-6">
                            <span class="mb-0 detail-title">Zip Code</span>
                            <p class="text-black detail-subtitle">{{profileModel.details?.provider_zip || 'Record not available'}}</p>
                        </div>
                        <div class="col-md-6">
                            <span class="mb-0 detail-title">Pay Code</span>
                            <p class="text-black detail-subtitle">{{profileModel.details?.po_code || 'Record not available'}}</p>
                        </div>
                        <div class="col-md-12">
                            <div class="custom-control custom-checkbox checkbox-width">
                                <input type="checkbox" class="custom-control-input" id="customCheck">
                                <label class="custom-control-label control-custom-primary" for="customCheck">Reviews</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card border-0 px-0 py-3">
                    <div class="col-md-12">
                        <span class="mb-0 detail-title">PO Code</span>
                        <!-- <p class="text-black detail-subtitle mb-0">PGHRT789</p>
                        <p class="text-black detail-subtitle mb-0">TYHRT7HJ7878</p>
                        <p class="text-black detail-subtitle mb-2">RGHRT7HJ7878_Admin_group_232</p> -->
                        <!-- <button class="btn p-0 primary-text btn-font">+ Add More</button> -->
                    </div>
                </div>
                <!-- <div class="card border-0 px-0 py-3">
                    <div class="col-md-12">
                        <span class="mb-0 detail-title">Sources</span>
                        <p class="text-black detail-subtitle mb-2">Null Pro, Null Admin, Null Various</p>
                        <button class="btn p-0 primary-text btn-font">+ Add IDs</button>
                    </div>
                </div> -->
                <div class="card border-0 px-0 py-3">
                    <div class="col-md-12">
                        <span class="mb-0 detail-title">Merge IDs</span>
                        <!-- <p class="text-black detail-subtitle mb-2">10001245,  45121215,  4548745, 4545786</p> -->
                        <!-- <button class="btn p-0 primary-text btn-font">+ Add IDs</button> -->
                    </div>
                </div>
            </div>
            <div class="col-md-9 col-10-5 px-0">
                <ngb-tabset [destroyOnHide]="false" class="detail-tabs">
                    <ngb-tab title="Details">
                    <ng-template ngbTabContent>
                        <div class="card pt-1 px-3 pb-3 border-0">
                            <p class="font-weight-bold mt-3 text-uppercase mb-0">Suspension data</p>
                            <table class="table  table-responsive">
                                <caption>Suspension data</caption>
                                <thead>
                                    <tr>

                                        <th class="pl-0" scope="col">Aliases</th>
                                        <th class="pl-0" scope="col">Full Name</th>
                                        <th class="pl-0" scope="col">Address</th>
                                        <th class="pl-0" scope="col">Duration</th>
                                        <th class="pl-0" scope="col">Professional Type</th>
                                        <th class="pl-0" scope="col">Provider Number</th>
                                        <th class="pl-0" scope="col">License Number</th>
                                        <th class="pl-0" scope="col">Date of Suspension</th>
                                        <th class="pl-0" scope="col">Run Date</th>
                                        <th class="pl-0" scope="col">Provider Contact</th>
                                        <th class="pl-0" scope="col">Paycode</th>
                                        <th class="pl-0" scope="col">File Name</th>
                                        <th class="pl-0" scope="col">Recipient</th>
                                        <th class="pl-0" scope="col">
                                            <div ngbDropdown class="text-right">
                                                <button class="btn bg-voilet text-white btnFilter no-dropdown-toggle" id="dropdownBasic1" ngbDropdownToggle><em class="fa fa-cog"></em></button>
                                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropDown-top dropDown-right dropDown-width border-0 px-2 btn-font">
                                                    <div ngbDropdownItem class="px-2">
                                                        <form>
                                                            <perfect-scrollbar [config]="config">
                                                                <div class="row">
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck1">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck1">First Name</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck2">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck2">Source</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck3">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck3">Middle Name</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck4">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck4">Prefix</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck5">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck5">Last Name</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck6">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck6">Aliases</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck7">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck7">Provider Type</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck8">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck8">Sources</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck9">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck9">Rev.</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck10">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck10">LIC PO</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </perfect-scrollbar>
                                                            <div class="col-md-12 mb-2 pt-3 px-0">
                                                                <button type="button" class="btn text-center btnApply px-4">Apply Filters</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let suspensionData of profileModel.details">
                                        <td class="pl-0 text-uppercase font-weight-semibold text-light-voilet">{{suspensionData.alias}}</td>
                                        <td class="pl-0">{{suspensionData.fullname}}</td>
                                        <td class="pl-0">{{suspensionData.address}}</td>
                                        <td class="pl-0">{{suspensionData.duration}}</td>
                                        <td class="pl-0">{{suspensionData.professionaltype}}</td>
                                        <td class="pl-0">{{suspensionData.providernumber?.join(', ')}}</td>
                                        <td class="pl-0">{{suspensionData.licensenumber?.join(', ')}}</td>
                                        <td class="pl-0">{{suspensionData.dateofsuspension}}</td>
                                        <td class="pl-0">{{suspensionData.rundate}}</td>
                                        <td class="pl-0">{{suspensionData.providercontact}}</td>
                                        <td class="pl-0">{{suspensionData.paycode}}</td>
                                        <td class="pl-0">{{suspensionData.filename}}</td>
                                        <td class="pl-0">{{suspensionData.recepient}}</td>
                                    </tr>
                                    <!-- <tr>
                                        <td class="pl-0 text-uppercase font-weight-semibold text-light-voilet">Frances Burns </td>
                                        <td class="pl-0">Vince</td>
                                        <td class="pl-0">Ashley</td>
                                        <td class="pl-0">Lawson </td>
                                        <td class="pl-0">Domestic</td>
                                        <td class="pl-0">
                                            <div class="custom-control custom-checkbox checkbox-width">
                                                <input type="checkbox" class="custom-control-input" id="customCheck12">
                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck12"></label>
                                            </div>
                                        </td>
                                        <td class="pl-0">NPI Full </td>
                                        <td class="pl-0"></td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>

                        <div class="card pt-1 px-3 pb-3 border-0">
                            <table class="table table-responsive">
                                <caption>Affiliations</caption>
                                <thead>
                                    <tr>
                                        <th class="pl-0 text-uppercase" scope="col">Affiliations<button class="btn px-2"><em class="fa fa-plus-circle primary-text"></em></button>
                                            <!-- <button class="btn px-0" style="padding-top: 3px;"><img src="assets/images/bar.png" alt="" width="14"></button> -->
                                        </th>
                                        <th class="pl-0" scope="col">Street</th>
                                        <th class="pl-0" scope="col">City</th>
                                        <th class="pl-0" scope="col">ZipCode</th>
                                        <th class="pl-0" scope="col">Address Type</th>
                                        <th class="pl-0" scope="col">NPI</th>
                                        <th class="pl-0" scope="col">License</th>
                                        <th class="pl-0" scope="col">Source</th>
                                        <th class="pl-0" scope="col">
                                            <div ngbDropdown class="text-right">
                                                <button class="btn bg-voilet text-white btnFilter no-dropdown-toggle" id="dropdownBasic1" ngbDropdownToggle><em class="fa fa-cog"></em></button>
                                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropDown-top dropDown-right dropDown-width border-0 px-2 btn-font">
                                                    <div ngbDropdownItem class="px-2">
                                                        <form>
                                                            <perfect-scrollbar [config]="config">
                                                                <div class="row">
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck13">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck13">First Name</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck14">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck14">Source</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck15">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck15">Middle Name</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck16">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck16">Prefix</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck17">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck17">Last Name</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck18">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck18">Aliases</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck19">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck19">Provider Type</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck20">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck20">Sources</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck21">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck21">Rev.</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck22">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck22">LIC PO</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </perfect-scrollbar>
                                                            <div class="col-md-12 mb-2 pt-3 px-0">
                                                                <button type="button" class="btn text-center btnApply px-4">Apply Filters</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- <tr>
                                        <td class="pl-0 text-uppercase font-weight-semibold text-light-voilet">201 S Anitar DR</td>
                                        <td class="pl-0">Suite 202</td>
                                        <td class="pl-0">New York</td>
                                        <td class="pl-0">CA 92861842 </td>
                                        <td class="pl-0">Practice</td>
                                        <td class="pl-0">1003265652</td>
                                        <td class="pl-0"></td>
                                        <td class="pl-0">NPI FULL <button class="btn"><em class="fa fa-trash primary-text"></em></button></td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>

                        <div class="card pt-1 px-3 pb-3 border-0">

                            <table class="table table-responsive">
                                <caption>Data Table</caption>
                                <thead>
                                    <tr>
                                        <th class="pl-0 text-uppercase" scope="col">NPI</th>
                                        <th class="pl-0" scope="col">Enumerate Date</th>
                                        <th class="pl-0" scope="col">Last Updated</th>
                                        <th class="pl-0" scope="col">Deactivate Date</th>
                                        <th class="pl-0" scope="col">Reactivate Date</th>
                                        <th class="pl-0" scope="col">Rev.</th>
                                        <th class="pl-0" scope="col">Source</th>
                                        <th class="pl-0" scope="col">PO Date</th>
                                        <th class="pl-0" scope="col">PO Code</th>
                                        <th class="pl-0" scope="col">
                                            <div ngbDropdown class="text-right">
                                                <button class="btn bg-voilet text-white btnFilter no-dropdown-toggle" id="dropdownBasic1" ngbDropdownToggle><em class="fa fa-cog"></em></button>
                                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropDown-top dropDown-right dropDown-width border-0 px-2 btn-font">
                                                    <div ngbDropdownItem class="px-2">
                                                        <form>
                                                            <perfect-scrollbar [config]="config">
                                                                <div class="row">
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck23">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck23">First Name</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck24">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck24">Source</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck25">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck25">Middle Name</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck26">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck26">Prefix</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck27">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck27">Last Name</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck28">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck28">Aliases</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck29">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck29">Provider Type</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck30">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck30">Sources</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck31">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck31">Rev.</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck32">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck32">LIC PO</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </perfect-scrollbar>
                                                            <div class="col-md-12 mb-2 pt-3 px-0">
                                                                <button type="button" class="btn text-center btnApply px-4">Apply Filters</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- <tr>
                                        <td class="pl-0 text-uppercase font-weight-semibold text-light-voilet">1003290032 </td>
                                        <td class="pl-0">07/20/15</td>
                                        <td class="pl-0">07/20/15</td>
                                        <td class="pl-0">07/20/15</td>
                                        <td class="pl-0"></td>
                                        <td class="pl-0">
                                            <div class="custom-control custom-checkbox checkbox-width">
                                                <input type="checkbox" class="custom-control-input" id="customCheck33">
                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck33"></label>
                                            </div>
                                        </td>
                                        <td class="pl-0">NPI Full </td>
                                        <td class="pl-0"></td>
                                        <td class="pl-0">254125</td>
                                        <td class="pl-0"></td>
                                    </tr>
                                    <tr>
                                        <td class="pl-0 text-uppercase font-weight-semibold text-light-voilet">1003290032</td>
                                        <td class="pl-0">07/20/15</td>
                                        <td class="pl-0">07/20/15</td>
                                        <td class="pl-0">07/20/15</td>
                                        <td class="pl-0"></td>
                                        <td class="pl-0">
                                            <div class="custom-control custom-checkbox checkbox-width">
                                                <input type="checkbox" class="custom-control-input" id="customCheck34">
                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck34"></label>
                                            </div>
                                        </td>
                                        <td class="pl-0">NPI Full </td>
                                        <td class="pl-0"></td>
                                        <td class="pl-0">254125</td>
                                        <td class="pl-0"></td>
                                    </tr>
                                    <tr>
                                        <td class="pl-0 text-uppercase font-weight-semibold text-light-voilet">1003290032</td>
                                        <td class="pl-0">07/20/15</td>
                                        <td class="pl-0">07/20/15</td>
                                        <td class="pl-0">07/20/15</td>
                                        <td class="pl-0"></td>
                                        <td class="pl-0">
                                            <div class="custom-control custom-checkbox checkbox-width">
                                                <input type="checkbox" class="custom-control-input" id="customCheck35">
                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck35"></label>
                                            </div>
                                        </td>
                                        <td class="pl-0">NPI Full </td>
                                        <td class="pl-0"></td>
                                        <td class="pl-0">254125</td>
                                        <td class="pl-0"></td>
                                    </tr>
                                    <button class="btn primary-text px-0 btnShowMore">Show More</button> -->
                                </tbody>
                            </table>
                        </div>

                        <div class="card pt-1 px-3 pb-3 border-0">
                            <table class="table table-responsive">
                                <caption>Data Table</caption>
                                <thead>
                                    <tr>
                                        <th class="pl-0 text-uppercase" scope="col">LICENSE</th>
                                        <th class="pl-0" scope="col">Original Licence</th>
                                        <th class="pl-0" scope="col">License Type</th>
                                        <th class="pl-0" scope="col">License Agency</th>
                                        <th class="pl-0" scope="col">State</th>
                                        <th class="pl-0" scope="col">Issue Date</th>
                                        <th class="pl-0" scope="col">Status</th>
                                        <th class="pl-0" scope="col">Source</th>
                                        <th class="pl-0" scope="col">PO Date</th>
                                        <th class="pl-0" scope="col">PO Code</th>
                                        <th class="pl-0" scope="col">
                                            <div ngbDropdown class="text-right">
                                                <button class="btn bg-voilet text-white btnFilter no-dropdown-toggle" id="dropdownBasic1" ngbDropdownToggle><em class="fa fa-cog"></em></button>
                                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropDown-top dropDown-right dropDown-width border-0 px-2 btn-font">
                                                    <div ngbDropdownItem class="px-2">
                                                        <form>
                                                            <perfect-scrollbar [config]="config">
                                                                <div class="row">
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck36">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck36">First Name</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck37">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck37">Source</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck38">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck38">Middle Name</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck39">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck39">Prefix</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck40">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck40">Last Name</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck41">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck41">Aliases</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck42">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck42">Provider Type</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck43">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck43">Sources</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck44">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck44">Rev.</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck45">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck45">LIC PO</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </perfect-scrollbar>
                                                            <div class="col-md-12 mb-2 pt-3 px-0">
                                                                <button type="button" class="btn text-center btnApply px-4">Apply Filters</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- <tr>
                                        <td class="pl-0 text-uppercase font-weight-semibold text-light-voilet">1003290032 </td>
                                        <td class="pl-0">GHSD1154</td>
                                        <td class="pl-0">GHSD1154</td>
                                        <td class="pl-0">GHJ75848</td>
                                        <td class="pl-0"></td>
                                        <td class="pl-0"></td>
                                        <td class="pl-0"></td>
                                        <td class="pl-0">NPI Full </td>
                                        <td class="pl-0"></td>
                                        <td class="pl-0">254125</td>
                                        <td class="pl-0"></td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>
                        <div class="card pt-1 px-3 pb-3 border-0">
                            <table class="table table-responsive">
                                <caption>Data Table</caption>
                                <thead>
                                    <tr>
                                        <th class="pl-0 text-uppercase" scope="col">EIN</th>
                                        <th class="pl-0" scope="col">Original Licence</th>
                                        <th class="pl-0" scope="col">License Type</th>
                                        <th class="pl-0" scope="col">License Agency</th>
                                        <th class="pl-0" scope="col">State</th>
                                        <th class="pl-0" scope="col">Issue Date</th>
                                        <th class="pl-0" scope="col">Status</th>
                                        <th class="pl-0" scope="col">Source</th>
                                        <th class="pl-0" scope="col">PO Date</th>
                                        <th class="pl-0" scope="col">PO Code</th>
                                        <th class="pl-0" scope="col">
                                            <div ngbDropdown class="text-right">
                                                <button class="btn bg-voilet text-white btnFilter no-dropdown-toggle" id="dropdownBasic1" ngbDropdownToggle><em class="fa fa-cog"></em></button>
                                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropDown-top dropDown-right dropDown-width border-0 px-2 btn-font">
                                                    <div ngbDropdownItem class="px-2">
                                                        <form>
                                                            <perfect-scrollbar [config]="config">
                                                                <div class="row">
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck46">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck46">First Name</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck47">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck47">Source</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck48">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck48">Middle Name</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck49">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck49">Prefix</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck50">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck50">Last Name</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck51">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck51">Aliases</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck52">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck52">Provider Type</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck53">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck53">Sources</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck54">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck54">Rev.</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <div class="custom-control custom-checkbox checkbox-width">
                                                                            <input type="checkbox" class="custom-control-input" id="customCheck55">
                                                                            <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck55">LIC PO</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </perfect-scrollbar>
                                                            <div class="col-md-12 mb-2 pt-3 px-0">
                                                                <button type="button" class="btn text-center btnApply px-4">Apply Filters</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- <tr>
                                        <td class="pl-0 text-uppercase font-weight-semibold text-light-voilet">1003290032 </td>
                                        <td class="pl-0">GHSD1154</td>
                                        <td class="pl-0">GHSD1154</td>
                                        <td class="pl-0">GHJ75848</td>
                                        <td class="pl-0"></td>
                                        <td class="pl-0"></td>
                                        <td class="pl-0"></td>
                                        <td class="pl-0">NPI Full </td>
                                        <td class="pl-0"></td>
                                        <td class="pl-0">254125</td>
                                        <td class="pl-0"></td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>
                    </ng-template>
                    </ngb-tab>
                    <ngb-tab title="Investigation Details">
                        <ng-template ngbTabContent>
                            <div class="card pt-1 px-3 pb-3 border-0">
                                <table class="table investigation-table">
                                    <caption>Investigation Details</caption>
                                    <thead>
                                        <tr>
                                            <th class="pl-0 text-uppercase font-weight-semibold" scope="col">Notes</th>
                                            <th class="pl-0 font-weight-semibold" scope="col">Created By</th>
                                            <th class="pl-0 font-weight-semibold" scope="col">Created On</th>
                                            <th class="pl-0 font-weight-semibold" scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr *ngFor="let item of row; let i = index">
                                            <td class="pl-0">The Standing Patrol for Emergency Assessment & Response (SPEAR) is an advisory unit activated for the purpose of rapidly providing relief.</td>
                                            <td class="pl-0">Chadwick Boseman</td>
                                            <td class="pl-0">{{ todayDate | date:'MM/dd/yyyy' }}</td>
                                            <td class="pl-0"><button class="btn primary-text" (click) = "deleteRow(i)"><em class="fa fa-trash"></em></button></td>
                                        </tr> -->

                                    </tbody>
                                </table>
                                <div class="pt-4 pb-2">
                                    <button class="btn bg-voilet text-white py-2 addBtn" (click)="addTable()"><em class="fa fa-plus-circle mr-2"></em> Add New</button>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab title="Provider History">
                        <ng-template ngbTabContent>
                            <div class="card pt-1 px-3 pb-3 border-0">
                                <table class="table table-responsive">
                                    <caption>Provider History</caption>
                                    <thead>
                                        <tr>
                                            <th class="pl-0 text-uppercase" scope="col">Aliases</th>
                                            <th class="pl-0" scope="col"><span class="primary-text d-block text-uppercase">Prefix </span>First</th>
                                            <th class="pl-0" scope="col">Middle</th>
                                            <th class="pl-0" scope="col">Last</th>
                                            <th class="pl-0" scope="col"><span class="primary-text d-block text-uppercase">Suffix </span>Provider Type</th>
                                            <th class="pl-0" scope="col">Rev.</th>
                                            <th class="pl-0" scope="col">Source</th>
                                            <th class="pl-0" scope="col">
                                                <div ngbDropdown class="text-right">
                                                    <button class="btn bg-voilet text-white btnFilter no-dropdown-toggle" id="dropdownBasic1" ngbDropdownToggle><em class="fa fa-cog"></em></button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropDown-top dropDown-right dropDown-width border-0 px-2 btn-font">
                                                        <div ngbDropdownItem class="px-2">
                                                            <form>
                                                                <perfect-scrollbar [config]="config">
                                                                    <div class="row">
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck56">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck56">First Name</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck57">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck57">Source</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck58">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck58">Middle Name</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck59">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck59">Prefix</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck60">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck60">Last Name</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck61">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck61">Aliases</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck62">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck62">Provider Type</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck63">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck63">Sources</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck64">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck64">Rev.</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck65">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck65">LIC PO</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </perfect-scrollbar>
                                                                <div class="col-md-12 mb-2 pt-3 px-0">
                                                                    <button type="button" class="btn text-center btnApply px-4">Apply Filters</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr>
                                            <td class="pl-0 text-uppercase font-weight-semibold text-light-voilet">Alan Butler </td>
                                            <td class="pl-0">Vince</td>
                                            <td class="pl-0">Ashley</td>
                                            <td class="pl-0">Lawson </td>
                                            <td class="pl-0">Domestic</td>
                                            <td class="pl-0">
                                                <div class="custom-control custom-checkbox checkbox-width">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck66">
                                                    <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck66"></label>
                                                </div>
                                            </td>
                                            <td class="pl-0">NPI Full </td>
                                            <td class="pl-0"></td>
                                        </tr>
                                        <tr>
                                            <td class="pl-0 text-uppercase font-weight-semibold text-light-voilet">Frances Burns </td>
                                            <td class="pl-0">Vince</td>
                                            <td class="pl-0">Ashley</td>
                                            <td class="pl-0">Lawson </td>
                                            <td class="pl-0">Domestic</td>
                                            <td class="pl-0">
                                                <div class="custom-control custom-checkbox checkbox-width">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck67">
                                                    <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck67"></label>
                                                </div>
                                            </td>
                                            <td class="pl-0">NPI Full </td>
                                            <td class="pl-0"></td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>

                            <div class="card pt-1 px-3 pb-3 border-0">
                                <table class="table table-responsive">
                                    <caption>Affiliations</caption>
                                    <thead>
                                        <tr>
                                            <th class="pl-0 text-uppercase" scope="col">Affiliations<button class="btn px-2"><em class="fa fa-plus-circle primary-text"></em></button>
                                                <!-- <button class="btn px-0" style="padding-top: 3px;"><img src="assets/images/bar.png" alt="" width="14"></button> -->
                                            </th>
                                            <th class="pl-0" scope="col">Street</th>
                                            <th class="pl-0" scope="col">City</th>
                                            <th class="pl-0" scope="col">ZipCode</th>
                                            <th class="pl-0" scope="col">Address Type</th>
                                            <th class="pl-0" scope="col">NPI</th>
                                            <th class="pl-0" scope="col">License</th>
                                            <th class="pl-0" scope="col">Source</th>
                                            <th class="pl-0" scope="col">
                                                <div ngbDropdown class="text-right">
                                                    <button class="btn bg-voilet text-white btnFilter no-dropdown-toggle" id="dropdownBasic1" ngbDropdownToggle><em class="fa fa-cog"></em></button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropDown-top dropDown-right dropDown-width border-0 px-2 btn-font">
                                                        <div ngbDropdownItem class="px-2">
                                                            <form>
                                                                <perfect-scrollbar [config]="config">
                                                                    <div class="row">
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck68">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck68">First Name</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck69">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck69">Source</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck70">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck90">Middle Name</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck71">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck71">Prefix</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck72">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck72">Last Name</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck73">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck73">Aliases</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck74">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck74">Provider Type</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck75">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck75">Sources</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck76">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck76">Rev.</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck77">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck77">LIC PO</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </perfect-scrollbar>
                                                                <div class="col-md-12 mb-2 pt-3 px-0">
                                                                    <button type="button" class="btn text-center btnApply px-4">Apply Filters</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr>
                                            <td class="pl-0 text-uppercase font-weight-semibold text-light-voilet">201 S Anitar DR</td>
                                            <td class="pl-0">Suite 202</td>
                                            <td class="pl-0">New York</td>
                                            <td class="pl-0">CA 92861842 </td>
                                            <td class="pl-0">Practice</td>
                                            <td class="pl-0">1003265652</td>
                                            <td class="pl-0"></td>
                                            <td class="pl-0">NPI FULL <button class="btn"><em class="fa fa-trash primary-text"></em></button></td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>

                            <div class="card pt-1 px-3 pb-3 border-0">

                                <table class="table table-responsive">
                                    <caption>Data Table</caption>
                                    <thead>
                                        <tr>
                                            <th class="pl-0 text-uppercase" scope="col">NPI</th>
                                            <th class="pl-0" scope="col">Enumerate Date</th>
                                            <th class="pl-0" scope="col">Last Updated</th>
                                            <th class="pl-0" scope="col">Deactivate Date</th>
                                            <th class="pl-0" scope="col">Reactivate Date</th>
                                            <th class="pl-0" scope="col">Rev.</th>
                                            <th class="pl-0" scope="col">Source</th>
                                            <th class="pl-0" scope="col">PO Date</th>
                                            <th class="pl-0" scope="col">PO Code</th>
                                            <th class="pl-0" scope="col">
                                                <div ngbDropdown class="text-right">
                                                    <button class="btn bg-voilet text-white btnFilter no-dropdown-toggle" id="dropdownBasic1" ngbDropdownToggle><em class="fa fa-cog"></em></button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropDown-top dropDown-right dropDown-width border-0 px-2 btn-font">
                                                        <div ngbDropdownItem class="px-2">
                                                            <form>
                                                                <perfect-scrollbar [config]="config">
                                                                    <div class="row">
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck78">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck78">First Name</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck79">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck79">Source</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck80">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck3">Middle Name</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck81">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck81">Prefix</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck82">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck82">Last Name</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck83">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck83">Aliases</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck84">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck84">Provider Type</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck85">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck85">Sources</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck86">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck86">Rev.</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck87">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck87">LIC PO</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </perfect-scrollbar>
                                                                <div class="col-md-12 mb-2 pt-3 px-0">
                                                                    <button type="button" class="btn text-center btnApply px-4">Apply Filters</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr>
                                            <td class="pl-0 text-uppercase font-weight-semibold text-light-voilet">1003290032 </td>
                                            <td class="pl-0">07/20/15</td>
                                            <td class="pl-0">07/20/15</td>
                                            <td class="pl-0">07/20/15</td>
                                            <td class="pl-0"></td>
                                            <td class="pl-0">
                                                <div class="custom-control custom-checkbox checkbox-width">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck88">
                                                    <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck88"></label>
                                                </div>
                                            </td>
                                            <td class="pl-0">NPI Full </td>
                                            <td class="pl-0"></td>
                                            <td class="pl-0">254125</td>
                                            <td class="pl-0"></td>
                                        </tr>
                                        <tr>
                                            <td class="pl-0 text-uppercase font-weight-semibold text-light-voilet">1003290032</td>
                                            <td class="pl-0">07/20/15</td>
                                            <td class="pl-0">07/20/15</td>
                                            <td class="pl-0">07/20/15</td>
                                            <td class="pl-0"></td>
                                            <td class="pl-0">
                                                <div class="custom-control custom-checkbox checkbox-width">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck89">
                                                    <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck89"></label>
                                                </div>
                                            </td>
                                            <td class="pl-0">NPI Full </td>
                                            <td class="pl-0"></td>
                                            <td class="pl-0">254125</td>
                                            <td class="pl-0"></td>
                                        </tr>
                                        <tr>
                                            <td class="pl-0 text-uppercase font-weight-semibold text-light-voilet">1003290032</td>
                                            <td class="pl-0">07/20/15</td>
                                            <td class="pl-0">07/20/15</td>
                                            <td class="pl-0">07/20/15</td>
                                            <td class="pl-0"></td>
                                            <td class="pl-0">
                                                <div class="custom-control custom-checkbox checkbox-width">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck90">
                                                    <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck90"></label>
                                                </div>
                                            </td>
                                            <td class="pl-0">NPI Full </td>
                                            <td class="pl-0"></td>
                                            <td class="pl-0">254125</td>
                                            <td class="pl-0"></td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab title="Affiliations/Exclusions">
                        <ng-template ngbTabContent>
                            <div class="card pt-1 px-3 pb-3 border-0">
                                <table class="table affiliationsTable table-responsive">
                                    <caption>Affiliations/Exclusions</caption>
                                    <thead>
                                        <tr>
                                            <th class="pl-0 text-uppercase" style="min-width: 200px;" scope="col">Affiliations<button class="btn p-0"><img class="ml-2" src="assets/images/bar.png" alt="bar" width="14"></button></th>
                                            <th class="pl-0" scope="col">Provider ID</th>
                                            <th class="pl-0" scope="col" style="min-width: 150px;">Notes</th>
                                            <th class="pl-0" scope="col">Reason Codes</th>
                                            <th class="pl-0" scope="col">EIN</th>
                                            <th class="pl-0" scope="col"><span class="d-block">EIN</span> Owner</th>
                                            <th class="pl-0" scope="col">Created On</th>
                                            <th class="pl-0" scope="col">Created By</th>
                                            <th class="pl-0" scope="col" style="min-width: 60px;">Action</th>
                                            <th class="pl-0" scope="col" style="min-width: 75px;">
                                                <div ngbDropdown class="text-right">
                                                    <button class="btn bg-voilet text-white btnFilter no-dropdown-toggle" id="dropdownBasic1" ngbDropdownToggle><em class="fa fa-cog"></em></button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropDown-top dropDown-right dropDown-width border-0 px-2 btn-font">
                                                        <div ngbDropdownItem class="px-2">
                                                            <form>
                                                                <perfect-scrollbar [config]="config">
                                                                    <div class="row">
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck91">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck91">First Name</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck92">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck92">Source</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck93">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck93">Middle Name</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck94">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck94">Prefix</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck95">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck95">Last Name</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck96">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck96">Aliases</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck97">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck97">Provider Type</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck98">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck98">Sources</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck99">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck99">Rev.</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck100">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck100">LIC PO</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </perfect-scrollbar>
                                                                <div class="col-md-12 mb-2 pt-3 px-0">
                                                                    <button type="button" class="btn text-center btnApply px-4">Apply Filters</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <perfect-scrollbar>
                                             <tr *ngFor="let item of row; let i = index">
                                                <td class="pl-0 text-uppercase font-weight-semibold text-light-voilet" style="min-width: 200px;">Landmark Medical Man</td>
                                                <td class="pl-0 primary-text font-weight-semibold">100215578 <img class="ml-2" src="assets/images/icon-file-copy.png" alt=""></td>
                                                <td class="pl-0" style="min-width: 150px;">Speak a voice memo.</td>
                                                <td class="pl-0">CA - PF2 </td>
                                                <td class="pl-0">2616666819</td>
                                                <td class="pl-0">Chris Evan</td>
                                                <td class="pl-0">25 Mar' 20</td>
                                                <td class="pl-0">Jane Mathew</td>
                                                <td class="pl-0" style="min-width: 60px;"><button class="btn" (click) = "deleteRow(i)"><em class="fa fa-trash primary-text"></em></button></td>
                                                <td class="pl-0" style="min-width: 75px;"></td>
                                            </tr>
                                            <tr>
                                                <td class="pl-0 text-uppercase font-weight-semibold text-light-voilet" style="min-width: 200px;">Holy Family Hospital</td>
                                                <td class="pl-0 primary-text font-weight-semibold">100215475 <img class="ml-2" src="assets/images/icon-file-copy.png" alt=""></td>
                                                <td class="pl-0" style="min-width: 150px;">Will call you shortly.</td>
                                                <td class="pl-0">CA - 014G </td>
                                                <td class="pl-0">2616666819</td>
                                                <td class="pl-0">Chris Evan</td>
                                                <td class="pl-0">25 Mar' 20</td>
                                                <td class="pl-0">Jane Mathew</td>
                                                <td class="pl-0" style="min-width: 60px;"><button class="btn" (click) = "deleteRow(i)"><em class="fa fa-trash primary-text"></em></button></td>
                                                <td class="pl-0" style="min-width: 75px;"></td>
                                            </tr>
                                            <tr>
                                                <td class="pl-0 text-uppercase font-weight-semibold text-light-voilet" style="min-width: 200px;">Landmark Medical Man</td>
                                                <td class="pl-0 primary-text font-weight-semibold">100215578 <img class="ml-2" src="assets/images/icon-file-copy.png" alt=""></td>
                                                <td class="pl-0" style="min-width: 150px;">Speak a voice memo.</td>
                                                <td class="pl-0">CA - PF2 </td>
                                                <td class="pl-0">2616666819</td>
                                                <td class="pl-0">Chris Evan</td>
                                                <td class="pl-0">25 Mar' 20</td>
                                                <td class="pl-0">Jane Mathew</td>
                                                <td class="pl-0" style="min-width: 60px;"><button class="btn" (click) = "deleteRow(i)"><em class="fa fa-trash primary-text"></em></button></td>
                                                <td class="pl-0" style="min-width: 75px;"></td>
                                            </tr>
                                            <tr>
                                                <td class="pl-0 text-uppercase font-weight-semibold text-light-voilet" style="min-width: 200px;">Holy Family Hospital</td>
                                                <td class="pl-0 primary-text font-weight-semibold">100215475 <img class="ml-2" src="assets/images/icon-file-copy.png" alt=""></td>
                                                <td class="pl-0" style="min-width: 150px;">Will call you shortly.</td>
                                                <td class="pl-0">CA - 014G </td>
                                                <td class="pl-0">2616666819</td>
                                                <td class="pl-0">Chris Evan</td>
                                                <td class="pl-0">25 Mar' 20</td>
                                                <td class="pl-0">Jane Mathew</td>
                                                <td class="pl-0" style="min-width: 60px;"><button class="btn" (click) = "deleteRow(i)"><em class="fa fa-trash primary-text"></em></button></td>
                                                <td class="pl-0" style="min-width: 75px;"></td>
                                            </tr>
                                        </perfect-scrollbar> -->
                                    </tbody>
                                </table>
                                <div class="pt-4 pb-2">
                                    <button class="btn bg-voilet text-white py-2 addBtn" (click)="addTable()"><em class="fa fa-plus-circle mr-2"></em> Add New</button> <button class="ml-3 btn bg-white text-voilet py-2 addBtn btn-secondary-outline"><img src="assets/images/blue-bar.png" alt="bar" width="14" class="mr-2"> Show List</button>
                                </div>
                            </div>

                            <div class="card pt-1 px-3 pb-3 border-0">
                                <table class="table affiliationsTable table-responsive">
                                    <caption>Data table</caption>
                                    <thead>
                                        <tr>
                                            <th class="pl-0 text-uppercase" style="min-width: 200px;" scope="col">Exclusions</th>
                                            <th class="pl-0" scope="col">Provider ID</th>
                                            <th class="pl-0" scope="col" style="min-width: 150px;">Notes</th>
                                            <th class="pl-0" scope="col">Reason Codes</th>
                                            <th class="pl-0" scope="col">EIN</th>
                                            <th class="pl-0" scope="col"><span class="d-block">EIN</span> Owner</th>
                                            <th class="pl-0" scope="col">Created On</th>
                                            <th class="pl-0" scope="col">Created By</th>
                                            <th class="pl-0" scope="col" style="min-width: 60px;">Action</th>
                                            <th class="pl-0" scope="col" style="min-width: 75px;">
                                                <div ngbDropdown class="text-right">
                                                    <button class="btn bg-voilet text-white btnFilter no-dropdown-toggle" id="dropdownBasic1" ngbDropdownToggle><em class="fa fa-cog"></em></button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropDown-top dropDown-right dropDown-width border-0 px-2 btn-font">
                                                        <div ngbDropdownItem class="px-2">
                                                            <form>
                                                                <perfect-scrollbar [config]="config">
                                                                    <div class="row">
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck101">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck101">First Name</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck102">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck102">Source</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck103">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck103">Middle Name</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck104">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck104">Prefix</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck105">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck105">Last Name</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck106">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck106">Aliases</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck107">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck107">Provider Type</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck108">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck108">Sources</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck109">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck109">Rev.</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck110">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck110">LIC PO</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </perfect-scrollbar>
                                                                <div class="col-md-12 mb-2 pt-3 px-0">
                                                                    <button type="button" class="btn text-center btnApply px-4">Apply Filters</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <perfect-scrollbar>
                                            <tr *ngFor="let item of row; let i = index">
                                                <td class="pl-0 text-uppercase font-weight-semibold text-light-voilet" style="min-width: 200px;">Landmark Medical Man</td>
                                                <td class="pl-0 primary-text font-weight-semibold">100215578 <img class="ml-2" src="assets/images/icon-file-copy.png" alt=""></td>
                                                <td class="pl-0" style="min-width: 150px;">Speak a voice memo.</td>
                                                <td class="pl-0">CA - PF2 </td>
                                                <td class="pl-0">2616666819</td>
                                                <td class="pl-0">Chris Evan</td>
                                                <td class="pl-0">25 Mar' 20</td>
                                                <td class="pl-0">Jane Mathew</td>
                                                <td class="pl-0" style="min-width: 60px;"><button class="btn" (click) = "deleteRow(i)"><em class="fa fa-trash primary-text"></em></button></td>
                                                <td class="pl-0" style="min-width: 75px;"></td>
                                            </tr>
                                            <tr>
                                                <td class="pl-0 text-uppercase font-weight-semibold text-light-voilet" style="min-width: 200px;">Holy Family Hospital</td>
                                                <td class="pl-0 primary-text font-weight-semibold">100215475 <img class="ml-2" src="assets/images/icon-file-copy.png" alt=""></td>
                                                <td class="pl-0" style="min-width: 150px;">Will call you shortly.</td>
                                                <td class="pl-0">CA - 014G </td>
                                                <td class="pl-0">2616666819</td>
                                                <td class="pl-0">Chris Evan</td>
                                                <td class="pl-0">25 Mar' 20</td>
                                                <td class="pl-0">Jane Mathew</td>
                                                <td class="pl-0" style="min-width: 60px;"><button class="btn" (click) = "deleteRow(i)"><em class="fa fa-trash primary-text"></em></button></td>
                                                <td class="pl-0" style="min-width: 75px;"></td>
                                            </tr>
                                            <tr>
                                                <td class="pl-0 text-uppercase font-weight-semibold text-light-voilet" style="min-width: 200px;">Landmark Medical Man</td>
                                                <td class="pl-0 primary-text font-weight-semibold">100215578 <img class="ml-2" src="assets/images/icon-file-copy.png" alt=""></td>
                                                <td class="pl-0" style="min-width: 150px;">Speak a voice memo.</td>
                                                <td class="pl-0">CA - PF2 </td>
                                                <td class="pl-0">2616666819</td>
                                                <td class="pl-0">Chris Evan</td>
                                                <td class="pl-0">25 Mar' 20</td>
                                                <td class="pl-0">Jane Mathew</td>
                                                <td class="pl-0" style="min-width: 60px;"><button class="btn" (click) = "deleteRow(i)"><em class="fa fa-trash primary-text"></em></button></td>
                                                <td class="pl-0" style="min-width: 75px;"></td>
                                            </tr>
                                            <tr>
                                                <td class="pl-0 text-uppercase font-weight-semibold text-light-voilet" style="min-width: 200px;">Holy Family Hospital</td>
                                                <td class="pl-0 primary-text font-weight-semibold">100215475 <img class="ml-2" src="assets/images/icon-file-copy.png" alt=""></td>
                                                <td class="pl-0" style="min-width: 150px;">Will call you shortly.</td>
                                                <td class="pl-0">CA - 014G </td>
                                                <td class="pl-0">2616666819</td>
                                                <td class="pl-0">Chris Evan</td>
                                                <td class="pl-0">25 Mar' 20</td>
                                                <td class="pl-0">Jane Mathew</td>
                                                <td class="pl-0" style="min-width: 60px;"><button class="btn" (click) = "deleteRow(i)"><em class="fa fa-trash primary-text"></em></button></td>
                                                <td class="pl-0" style="min-width: 75px;"></td>
                                            </tr>
                                        </perfect-scrollbar> -->
                                    </tbody>
                                </table>
                                <div class="pt-4 pb-2">
                                    <button class="btn bg-voilet text-white py-2 addBtn" (click)="addTable()"><em class="fa fa-plus-circle mr-2"></em> Add New</button> <button class="ml-3 btn bg-white text-voilet py-2 addBtn btn-secondary-outline"><img src="assets/images/blue-bar.png" alt="bar" width="14" class="mr-2"> Show List</button>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab title="Matching Providers">
                        <ng-template ngbTabContent>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card pt-1 px-3 pb-3 border-0">
                                        <table class="table table-responsive">
                                            <caption>Aliases</caption>
                                            <thead>
                                                <tr>
                                                    <th class="pl-0 font-weight-semibold text-uppercase" scope="col">Aliases</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- <tr>
                                                    <td class="pl-0">Landmark INC.</td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-0">BAL RAJAGOPALAN MD INC.</td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-0">Landmark INC.</td>
                                                </tr> -->
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="card pt-1 px-3 pb-3 border-0">
                                        <table class="table table-responsive">
                                            <caption>Same Aliases</caption>
                                            <thead>
                                                <tr>
                                                    <th class="pl-0 font-weight-semibold text-uppercase" scope="col">Same Aliases</th>
                                                    <!-- <th class="pl-0 font-weight-semibold text-uppercase" scope="col">Same Aliases <span class="ml-2 badges-num primary-bg text-white rounded-circle">4</span></th> -->
                                                    <th class="pl-0 font-weight-semibold" scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- <tr>
                                                    <td class="pl-0">Landmark INC.</td>
                                                    <td class="pl-0 primary-text">Create Affiliation</td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-0">BAL RAJAGOPALAN MD INC.</td>
                                                    <td class="pl-0 primary-text">Create Affiliation</td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-0">Landmark INC.</td>
                                                    <td class="pl-0 primary-text">Create Affiliation</td>
                                                </tr> -->
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="card pt-1 px-3 pb-3 border-0">
                                        <table class="table table-responsive">
                                            <caption>Address</caption>
                                            <thead>
                                                <tr>
                                                    <th class="pl-0 font-weight-semibold text-uppercase" scope="col">Address</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- <tr>
                                                    <td class="pl-0">15050, Columbus St Lake , CA </td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-0">15050, Columbus St Lake , CA </td>
                                                </tr> -->
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="card pt-1 px-3 pb-3 border-0">
                                        <table class="table table-responsive">
                                            <caption>Same Address</caption>
                                            <thead>
                                                <tr>
                                                    <th class="pl-0 font-weight-semibold text-uppercase" scope="col">Same Address</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- <tr>
                                                    <td class="pl-0">Landmark INC.</td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-0">BAL RAJAGOPALAN MD INC.</td>
                                                </tr> -->
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="card pt-1 px-3 pb-3 border-0">
                                        <table class="table table-responsive">
                                            <caption>Ein</caption>
                                            <thead>
                                                <tr>
                                                    <th class="pl-0 font-weight-semibold text-uppercase" scope="col">Ein</th>
                                                    <th class="pl-0 font-weight-semibold" scope="col"></th>
                                                    <th class="pl-0 font-weight-semibold" scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- <tr>
                                                    <td class="pl-0">950124586 </td>
                                                    <td class="pl-0">Registered Board of CA </td>
                                                    <td class="pl-0">Data03_400 </td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-0">950124586 </td>
                                                    <td class="pl-0">Registered Board of CA </td>
                                                    <td class="pl-0">Data03_400 </td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-0">950124586 </td>
                                                    <td class="pl-0">Registered Board of CA </td>
                                                    <td class="pl-0">Data03_400 </td>
                                                </tr> -->
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="card pt-1 px-3 pb-3 border-0">
                                        <table class="table table-responsive">
                                            <caption>Same EIN</caption>
                                            <thead>
                                                <tr>
                                                    <th class="pl-0 font-weight-semibold text-uppercase" scope="col">Same EIN</th>
                                                    <th class="pl-0 font-weight-semibold" scope="col"></th>
                                                    <th class="pl-0 font-weight-semibold" scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- <tr>
                                                    <td class="pl-0">950124586 </td>
                                                    <td class="pl-0">Registered Board of CA </td>
                                                    <td class="pl-0">Data03_400 </td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-0">950124586 </td>
                                                    <td class="pl-0">Registered Board of CA </td>
                                                    <td class="pl-0">Data03_400 </td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-0">950124586 </td>
                                                    <td class="pl-0">Registered Board of CA </td>
                                                    <td class="pl-0">Data03_400 </td>
                                                </tr> -->
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="card pt-1 px-3 pb-3 border-0">
                                        <table class="table table-responsive">
                                            <caption>DWC</caption>
                                            <thead>
                                                <tr>
                                                    <th class="pl-0 font-weight-semibold text-uppercase" scope="col">DWC</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- <tr>
                                                    <td class="pl-0">Landmark INC.</td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-0">BAL RAJAGOPALAN MD INC.</td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-0">Landmark INC.</td>
                                                </tr> -->
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="card pt-1 px-3 pb-3 border-0">
                                        <table class="table table-responsive">
                                            <caption>Same EAMS</caption>
                                            <thead>
                                                <tr>
                                                    <th class="pl-0 font-weight-semibold text-uppercase" scope="col">Same EAMS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- <tr>
                                                    <td class="pl-0">Landmark INC.</td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-0">BAL RAJAGOPALAN MD INC.</td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-0">Landmark INC.</td>
                                                </tr> -->
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="card pt-1 px-3 pb-3 border-0">
                                        <table class="table table-responsive">
                                            <caption>Phone</caption>
                                            <thead>
                                                <tr>
                                                    <th class="pl-0 font-weight-semibold text-uppercase" scope="col">Phone</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- <tr>
                                                    <td class="pl-0">Landmark INC.</td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-0">BAL RAJAGOPALAN MD INC.</td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-0">Landmark INC.</td>
                                                </tr> -->
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="card pt-1 px-3 pb-3 border-0">
                                        <table class="table table-responsive">
                                            <caption>Same Phone</caption>
                                            <thead>
                                                <tr>
                                                    <th class="pl-0 font-weight-semibold text-uppercase" scope="col">Same Phone</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- <tr>
                                                    <td class="pl-0">Landmark INC.</td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-0">BAL RAJAGOPALAN MD INC.</td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-0">Landmark INC.</td>
                                                </tr> -->
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab title="Groups">
                        <ng-template ngbTabContent>
                            <div class="card pt-1 px-3 pb-3 border-0">
                                <table class="table table-responsive">
                                    <caption>Group Detail</caption>
                                    <thead>
                                        <tr>
                                            <th class="pl-0 font-weight-semibold" scope="col" style="min-width: 285px;">Group Name</th>
                                            <th class="pl-0 font-weight-semibold" scope="col" style="min-width: 600px;">Notes</th>
                                            <th class="pl-0 font-weight-semibold" scope="col" style="min-width: 285px;">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr>
                                            <td class="pl-0 text-uppercase font-weight-semibold primary-text">Landmark INC.jpg</td>
                                            <td class="pl-0">07/02/2005</td>
                                            <td class="pl-0"><button class="btn px-0"><em class="btn-bg rounded-circle fa fa-pencil edit mr-2"></em></button><button class="btn primary-text"><em class="btn-bg rounded-circle fa fa-trash mr-2 primary-text rounded-circle trash-icon"></em></button></td>
                                        </tr>
                                        <tr>
                                            <td class="pl-0 text-uppercase font-weight-semibold primary-text">1003290032</td>
                                            <td class="pl-0">10/05/2015</td>
                                            <td class="pl-0"><button class="btn px-0"><em class="btn-bg rounded-circle fa fa-pencil edit mr-2"></em></button><button class="btn primary-text"><em class="btn-bg rounded-circle fa fa-trash mr-2 primary-text rounded-circle trash-icon"></em></button></td>
                                        </tr>
                                        <tr>
                                            <td class="pl-0 text-uppercase font-weight-semibold primary-text">1003290032</td>
                                            <td class="pl-0">20/01/2020</td>
                                            <td class="pl-0"><button class="btn px-0"><em class="btn-bg rounded-circle fa fa-pencil edit mr-2"></em></button><button class="btn primary-text"><em class="btn-bg rounded-circle fa fa-trash mr-2 primary-text rounded-circle trash-icon"></em></button></td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab title="Merge History">
                        <ng-template ngbTabContent>
                            <div class="card pt-1 px-3 pb-3 border-0">
                                <table class="table table-responsive">
                                    <caption>Provider Table</caption>
                                    <thead>
                                        <tr>
                                            <th class="pl-0 font-weight-semibold" scope="col">Provider Name</th>
                                            <th class="pl-0 font-weight-semibold" scope="col">Provider ID</th>
                                            <th class="pl-0 font-weight-semibold" scope="col">Reason</th>
                                            <th class="pl-0 font-weight-semibold" scope="col">Sources</th>
                                            <th class="pl-0 font-weight-semibold" scope="col">Merge By</th>
                                            <th class="pl-0 font-weight-semibold" scope="col">Created On</th>
                                            <th class="pl-0 font-weight-semibold" scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr>
                                            <td class="pl-0 text-uppercase font-weight-semibold primary-text">Landmark INC.jpg</td>
                                            <td class="pl-0">1003290032</td>
                                            <td class="pl-0">Other Support Documents</td>
                                            <td class="pl-0">DWC ERNLC</td>
                                            <td class="pl-0">Breynolds</td>
                                            <td class="pl-0">02/03/2020</td>
                                            <td class="pl-0"><button class="btn primary-text pl-0"><em class="fa fa-eye"></em> Activity Log</button></td>
                                        </tr>
                                        <tr>
                                            <td class="pl-0 text-uppercase font-weight-semibold primary-text">BAL RAJAGOPALAN MD.pdf</td>
                                            <td class="pl-0">1003290032</td>
                                            <td class="pl-0">Other Support Documents</td>
                                            <td class="pl-0">EIN Various</td>
                                            <td class="pl-0">Andrew Simon</td>
                                            <td class="pl-0">02/03/2020</td>
                                            <td class="pl-0"><button class="btn primary-text pl-0"><em class="fa fa-eye"></em> Activity Log</button></td>
                                        </tr>
                                        <tr>
                                            <td class="pl-0 text-uppercase font-weight-semibold primary-text">Landmark INC.jpg</td>
                                            <td class="pl-0">1003290032</td>
                                            <td class="pl-0">Other Support Documents</td>
                                            <td class="pl-0">DWC ERNLC</td>
                                            <td class="pl-0">Breynolds</td>
                                            <td class="pl-0">02/03/2020</td>
                                            <td class="pl-0"><button class="btn primary-text pl-0"><em class="fa fa-eye"></em> Activity Log</button></td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab title="Reason Codes">
                        <ng-template ngbTabContent>
                            <div class="card pt-1 px-3 pb-3 border-0">
                                <table class="table table-responsive">
                                    <caption>Reason Codes</caption>
                                    <thead>
                                        <tr>
                                            <th class="pl-0 font-weight-semibold" scope="col">Code</th>
                                            <th class="pl-0 font-weight-semibold" scope="col">Start Date </th>
                                            <th class="pl-0 font-weight-semibold" scope="col">End Date</th>
                                            <th class="pl-0 font-weight-semibold" scope="col">Type</th>
                                            <th class="pl-0 font-weight-semibold" scope="col">Id</th>
                                            <th class="pl-0 font-weight-semibold" scope="col">Sources</th>
                                            <th class="pl-0 font-weight-semibold" scope="col">Created On</th>
                                            <th class="pl-0 font-weight-semibold" scope="col">Notes</th>
                                            <th class="pl-0" scope="col">
                                                <div ngbDropdown class="text-right">
                                                    <button class="btn bg-voilet text-white btnFilter no-dropdown-toggle" id="dropdownBasic1" ngbDropdownToggle><em class="fa fa-cog"></em></button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropDown-top dropDown-right dropDown-width border-0 px-2 btn-font">
                                                        <div ngbDropdownItem class="px-2">
                                                            <form>
                                                                <perfect-scrollbar class="perfect-scrollbar" [config]="config">
                                                                    <div class="row">
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck111">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck111">First Name</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck112">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck112">Source</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck113">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck113">Middle Name</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck114">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck114">Prefix</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck115">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck115">Last Name</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck116">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck116">Aliases</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck117">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck117">Provider Type</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck118">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck118">Sources</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck119">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck119">Rev.</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <div class="custom-control custom-checkbox checkbox-width">
                                                                                <input type="checkbox" class="custom-control-input" id="customCheck120">
                                                                                <label class="custom-control-label control-custom-primary control-custom-label" for="customCheck120">LIC PO</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </perfect-scrollbar>
                                                                <div class="col-md-12 mb-2 pt-3 px-0">
                                                                    <button type="button" class="btn text-center btnApply px-4">Apply Filters</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr>
                                            <td class="pl-0">1003290032</td>
                                            <td class="pl-0">02/03/2020</td>
                                            <td class="pl-0">21/03/2020</td>
                                            <td class="pl-0">DWC ERNLC</td>
                                            <td class="pl-0"> Breynolds</td>
                                            <td class="pl-0">DWC ERNLC</td>
                                            <td class="pl-0">02/03/2020</td>
                                            <td class="pl-0">Other Support Documents</td>
                                            <td class="pl-0"></td>
                                        </tr>
                                        <tr>
                                            <td class="pl-0">1003290032</td>
                                            <td class="pl-0">02/03/2020</td>
                                            <td class="pl-0">21/03/2020</td>
                                            <td class="pl-0">EIN Various</td>
                                            <td class="pl-0"> Andrew Simon</td>
                                            <td class="pl-0">EIN Various</td>
                                            <td class="pl-0">02/03/2020</td>
                                            <td class="pl-0"></td>
                                            <td class="pl-0"></td>
                                        </tr>
                                        <tr>
                                            <td class="pl-0">1003290032</td>
                                            <td class="pl-0">02/03/2020</td>
                                            <td class="pl-0">21/03/2020</td>
                                            <td class="pl-0">DWC ERNLC</td>
                                            <td class="pl-0"> Breynolds</td>
                                            <td class="pl-0">DWC ERNLC</td>
                                            <td class="pl-0">02/03/2020</td>
                                            <td class="pl-0">Speak a voice memo.</td>
                                            <td class="pl-0"></td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab title="Owners">
                        <ng-template ngbTabContent>
                            <div class="card pt-1 px-3 pb-3 border-0">
                                <table class="table table-responsive">
                                    <caption>Owners</caption>
                                    <thead>
                                        <tr>
                                            <th class="pl-0 font-weight-semibold" scope="col">First Name</th>
                                            <th class="pl-0 font-weight-semibold" scope="col">Last Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr>
                                            <td class="pl-0">Markus Magnusson</td>
                                            <td class="pl-0">Mathewson</td>
                                        </tr>
                                        <tr>
                                            <td class="pl-0">Anastasia</td>
                                            <td class="pl-0">Gagnusson</td>
                                        </tr>
                                        <tr>
                                            <td class="pl-0">Gregory Muryn-Mukha</td>
                                            <td class="pl-0">Muryn-Mukha</td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab title="Documents">
                        <ng-template ngbTabContent>
                            <div class="card pt-1 px-3 pb-3 border-0">
                                <table class="table table-responsive">
                                    <caption>Documents</caption>
                                    <thead>
                                        <tr>
                                            <th class="pl-0 font-weight-semibold" scope="col">Document</th>
                                            <th class="pl-0 font-weight-semibold" scope="col">Uploaded by</th>
                                            <th class="pl-0 font-weight-semibold" scope="col">Uploaded On</th>
                                            <th class="pl-0 font-weight-semibold" scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr>
                                            <td class="pl-0 text-uppercase font-weight-semibold primary-text">Landmark INC.jpg</td>
                                            <td class="pl-0">Breynolds</td>
                                            <td class="pl-0">02/03/2020</td>
                                            <td class="pl-0"><button class="btn primary-text pl-0"><em class="fa fa-cloud-download"></em></button></td>
                                        </tr>
                                        <tr>
                                            <td class="pl-0 text-uppercase font-weight-semibold primary-text">BAL RAJAGOPALAN MD.pdf</td>
                                            <td class="pl-0">Andrew Simon</td>
                                            <td class="pl-0">02/03/2020</td>
                                            <td class="pl-0"><button class="btn primary-text pl-0"><em class="fa fa-cloud-download"></em></button></td>
                                        </tr>
                                        <tr>
                                            <td class="pl-0 text-uppercase font-weight-semibold primary-text">Landmark INC.jpg</td>
                                            <td class="pl-0">Breynolds</td>
                                            <td class="pl-0">02/03/2020</td>
                                            <td class="pl-0"><button class="btn primary-text pl-0"><em class="fa fa-cloud-download"></em></button></td>
                                        </tr> -->
                                    </tbody>
                                </table>
                                <div class="pt-4 pb-2">
                                    <button class="btn bg-voilet text-white py-2 addBtn"><em class="fa fa-plus-circle mr-2"></em> Add Document</button>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
            </div>
        </div>
    </perfect-scrollbar>
</div>
