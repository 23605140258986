export class Constants {
    static readonly DATE_FMT = 'MM/dd/yyyy';
    static readonly DATE_TIME_FMT = `${Constants.DATE_FMT} hh:mm:ss`;
  }

export class MergeReviewDisplayNameConstants {
    public static readonly firstName = 'First Name';
    public static readonly middleName = 'Middle Name';
    public static readonly lastName = 'Last Name';
    public static readonly address1 = 'Address 1';
    public static readonly address2 = 'Address 2';
    public static readonly paycode = 'Paycode';
    public static readonly city = 'City';
    public static readonly state = 'State';
    public static readonly zipCode = 'Zip Code';
}

export class ProviderSearchQueryConstants {
  public static readonly spearPrefix = ''
  public static readonly spearIdFirestore = 'spear_id';
  public static readonly providerNamePerson = 'provider_name_person';
  public static readonly providerNameBusiness = 'provider_name_business';
  public static readonly providerAliasPerson = 'provider_alias_person';
  public static readonly medicalLicenseNumbersAndState = 'medical_license_numbers_and_state';
  public static readonly providerState = 'provider_state';
  public static readonly providerNpiNumber = 'provider_npi_number';
  public static readonly providerStreetAddress1Practice = 'provider_street_address1_practice';
  public static readonly providerStreetAddress1 = 'provider_street_address_1';
  public static readonly providerCity = 'provider_city';
  public static readonly providerPhone = 'provider_phone';
}

