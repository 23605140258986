import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MergeModel } from 'src/app/models/MergeModel';

@Component({
  selector: 'app-merge',
  templateUrl: './merge.component.html',
  styleUrls: ['./merge.component.css']
})
export class MergeComponent implements OnInit {

  mergeModel: MergeModel = {
    provider: '',
    reason: ''
  };

  constructor(private ngbActiveModal: NgbActiveModal) { }

  get activeModal(): any {
    return this.ngbActiveModal;
  }

  ngOnInit(): void {
  }

}
