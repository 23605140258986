<div class="container-fluid p-0" *blockUI="'provider-screen'">
    <div class="row">
        <div class="col-md-12">
            <div class="w-100 pt-4">
                <div class="row mb-2 mb-xl-3 justify-content-between align-items-center">
                    <div class="col-auto">
                        <h3 class="page-title">Manual Merge</h3>
                        <!-- <h5 class="da-grey">Ingrid Ballou</h5> -->
                    </div>
                </div>
                <div class="d-flex smart-merge-row">
                    <div class="card border-0 mb-2 flex-fill mr-6">
                        <div class="card-header da-light-red border-0 card-padding rounded px-4 pt-4 pb-0 bg-white">
                            <div class="row justify-content-between">
                                <div class="col-md-auto">
                                    <h5 class="card-header-title da-color-red">
                                        {{firstProfileName || firstProfileID}}
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div class="card-body leftProfile">
                            <div class="da-card-merge-body" #firstProfileBlock>
                                <div *ngFor="let profile of firstProfile; let i =index;" class="content-align"
                                    [ngClass]="{'selected': profile.itemSelected === true || profile.enabledField === false}"
                                    [hidden]="!profile.showField || 
                                    profile.displayName == 'provider_street_address_1' ||
                                    profile.displayName == 'provider_street_address_2' ||
                                    profile.displayName == 'provider_city' ||
                                    profile.displayName == 'provider_state' ||
                                    profile.displayName == 'provider_zip' ||
                                    profile.displayName == 'provider_phone' ||
                                    profile.displayName == 'provider_fax' ||
                                    profile.displayName == 'provider_street_address_1_practice' ||
                                    profile.displayName == 'provider_street_address_2_practice' ||
                                    profile.displayName == 'provider_city_practice' ||
                                    profile.displayName == 'provider_state_practice' ||
                                    profile.displayName == 'provider_zip_practice' ||
                                    profile.displayName == 'provider_phone_practice' ||
                                    profile.displayName == 'provider_fax_practice' ||
                                    profile.displayName == 'medical_licence_numbers' ||
                                    profile.displayName == 'medical_licence_states'">
                                    <div class="position-relative">
                                        <input type="checkbox" id="firstProfile_{{i}}" [disabled]="profile.isDisabled"
                                            (change)="onSelectionChange('firstProfile', profile.displayName, $event)"
                                            [(ngModel)]="profile.isSelected">
                                        <label for="firstProfile_{{i}}" class="btn purple-bg text-white">
                                            <em class="fa fa-angle-double-left" aria-hidden="true"></em>
                                            <em class="fa fa-undo" aria-hidden="true"></em>
                                        </label>
                                        <label>{{profile.displayName| replace : '_' : '
                                            '}}
                                        </label>
                                        <input type="checkbox" id="bothProfile_{{i}}"
                                            (change)="onSelectionBoth('bothProfile', profile.displayName, $event)" [hidden]="!profile.isBothVisible" [disabled]="!profile.isBothEnabled">
                                        <label for="bothProfile_{{i}}" class="btn purple-bg text-white both">
                                            <em class="fa fa-plus" aria-hidden="true"></em>
                                        </label>
                                    </div>
                                    <div class="da-marge-show-val">
                                        <div class="position-relative"
                                            *ngIf="!['medical_license_number', 'medical_license_state', 'provider_address', 'practice_address', 'medical_licence'].includes(profile.displayName)">
                                            <input type="text" [(ngModel)]="profile.value" class="form-control"
                                                [disabled]="profile.enabledField">
                                            <!-- <button (click)="profile.enabledField ? profile.enabledField = false : profile.enabledField = true"
                                                class="btn text-voilet edit-btn">
                                                <em class="fa fa-pencil"></em> Edit
                                            </button> -->
                                        </div>
                                        <div class="position-relative"
                                            *ngIf="['provider_address'].includes(profile.displayName)">
                                            <div [innerHTML]="profile.value" id="prov_addrs_bloc_1"></div>
                                        </div>
                                        <div class="position-relative"
                                            *ngIf="['practice_address'].includes(profile.displayName)">
                                            <div [innerHTML]="profile.value" id="prac_addrs_bloc_1"></div>
                                        </div>
                                        <div class="position-relative"
                                            *ngIf="['medical_licence'].includes(profile.displayName)">
                                            <div [innerHTML]="profile.value" id="medical-licences_1"></div>
                                        </div>
                                        <!-- <span class="previously" *ngIf="profile.showrestorevalue && !['medical_license_number', 'medical_license_state'].includes(profile.displayName)">Previously: {{profile.restorevalue}}</span>
                                        <div class="previously" *ngIf="profile.showrestorevalue && ['medical_license_number', 'medical_license_state'].includes(profile.displayName)">Previously: 
                                            <div>&#123;<span *ngFor="let val of profile.restorevalue| keyvalue | orderBy: 'key'; let i = index;">{{val.key}}: {{val.value}} <span *ngIf="i<(profile.restorevalue| keyvalue).length-1">&#44; </span></span>&#125;</div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card border-0 mb-2 flex-fill ml-6">
                        <div class="card-header da-light-red border-0 card-padding rounded px-4 pt-4 pb-0 bg-white">
                            <div class="row justify-content-between">
                                <div class="col-md-auto">
                                    <h5 class="card-header-title da-color-red">
                                        {{secondProfileName || secondProfileID}}
                                    </h5>
                                    <!-- <div
                                            class="custom-control custom-checkbox checkbox-custom d-flex align-items-center">
                                            <input type="checkbox" class="custom-control-input" id="checkuncheckallSecond"
                                                (change)="checkAllProfile('Second')" [(ngModel)]="isMasterSelSecond">
                                            <label class="custom-control-label control-custom-label cursor-pointer"
                                                for="checkuncheckallSecond">Select All / Unselect All
                                            </label>
                                        </div> -->
                                </div>
                            </div>

                        </div>

                        <div class="card-body rightProfile">
                            <div class="da-card-merge-body" #secondProfileBlock>
                                <div *ngFor="let profile of secondProfile; let i=index;" class="content-align"
                                    [ngClass]="{'selected': profile.itemSelected=== true}" [hidden]="!profile.showField || 
                                    profile.displayName == 'provider_street_address_1' ||
                                    profile.displayName == 'provider_street_address_2' ||
                                    profile.displayName == 'provider_city' ||
                                    profile.displayName == 'provider_state' ||
                                    profile.displayName == 'provider_zip' ||
                                    profile.displayName == 'provider_phone' ||
                                    profile.displayName == 'provider_fax' ||
                                    profile.displayName == 'provider_street_address_1_practice' ||
                                    profile.displayName == 'provider_street_address_2_practice' ||
                                    profile.displayName == 'provider_city_practice' ||
                                    profile.displayName == 'provider_state_practice' ||
                                    profile.displayName == 'provider_zip_practice' ||
                                    profile.displayName == 'provider_phone_practice' ||
                                    profile.displayName == 'provider_fax_practice' ||
                                    profile.displayName == 'medical_licence_numbers' ||
                                    profile.displayName == 'medical_licence_states'">
                                    <div class="position-relative">
                                        <input type="checkbox" id="secondProfile_{{i}}" [disabled]="profile.isDisabled"
                                            (change)="onSelectionChange('secondProfile', profile.displayName, $event)"
                                            [(ngModel)]="profile.isSelected">
                                        <label for="secondProfile_{{i}}" class="btn purple-bg text-white">
                                            <em class="fa fa-angle-double-right" aria-hidden="true"></em>
                                            <em class="fa fa-undo" aria-hidden="true"></em>
                                        </label>
                                        <label>{{profile.displayName| replace : '_' : '
                                            '}}
                                        </label>
                                    </div>
                                    <div class="da-marge-show-val">
                                        <div class="position-relative"
                                            *ngIf="!['medical_license_number', 'medical_license_state', 'provider_address', 'practice_address', 'medical_licence'].includes(profile.displayName)">
                                            <input type="text" [(ngModel)]="profile.value" class="form-control"
                                                [disabled]="disable1">
                                            <span class="previously" *ngIf="profile.showrestorevalue"></span>
                                        </div>
                                        
                                        <div class="position-relative"
                                            *ngIf="['provider_address'].includes(profile.displayName)">
                                            <div [innerHTML]="profile.value" id="prov_addrs_bloc_2"></div>
                                        </div>
                                        <div class="position-relative"
                                        *ngIf="['practice_address'].includes(profile.displayName)">
                                        <div [innerHTML]="profile.value" id="prac_addrs_bloc_2"></div>
                                    </div>
                                    <div class="position-relative"
                                            *ngIf="['medical_licence'].includes(profile.displayName)">
                                            <div [innerHTML]="profile.value" id="medical-licences_2"></div>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-column align-items-center">
                <!-- <div class="position-relative">
                        <input type="checkbox" id="checkuncheckallFirst" (click)="checkAllProfile('Second', $event)"
                            [(ngModel)]="isMasterSelSecond">
                        <label for="checkuncheckallFirst" class="btn purple-bg text-white">
                            <em class="fa fa-undo" aria-hidden="true"></em> Clear All
                        </label>
                    </div> -->
                <button class="btn bg-voilet text-white px-3 py-2">
                    <em class="fa fa-undo"></em> Clear All
                </button>
                <div class="text-center mt-2">Post merge you will can modify data</div>
                <button class="btn bg-voilet text-white mt-2 px-3 py-2 ml-0" [disabled]="validateSelections()" (click)="onMerge()">
                    <em class="fa fa-compress"></em> Merge
                </button>
            </div>

        </div>
    </div>
</div>