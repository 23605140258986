import { Pipe, PipeTransform } from '@angular/core';
import { AppUtils } from '../utilities/app.utils';

@Pipe({
  name: 'clientDateFormat',
})
export class ClientDateFormatPipe implements PipeTransform {
  constructor(private appUtils: AppUtils) {}
  transform(value: string) {
    if (value) {
      return this.appUtils.formatDate(new Date(value));
    }

    return value;
  }
}
