<div class="container-fluid">
    <div class="row no-gutter">
        <div class="col-md-8 col-lg-6">
            <div class="login d-flex align-items-center py-5">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7 col-lg-6 mx-auto">
                            <div class="text-center mb-5">
                                <img src="assets/images/full-logo.png" alt="">
                            </div>
                            <div class="alert p-2 mb-0" *ngIf="isResetPasswordLinkSent">A reset password link is successfully sent to the email.</div>
                            <div *ngIf="!isResetPasswordLinkSent">
                            <div class="card flex-fill border-0 px-4 py-5" *blockUI="'frm-forgotPassword'">
                                <form name="form" (ngSubmit)="f.form.valid && forgotPassword()" #f="ngForm" novalidate>
                                    <label class="loginLabel" for="name">Email</label>
                                    <input name ="email" #email="ngModel"  type="email" class="form-control mb-3" [(ngModel)]="forgotPasswordModel.email" required>
                                    <div class="alert alert-danger p-2 mb-0" *ngIf="email.invalid && email.touched">E-mail is required</div>

                                    <button type="submit" class="btn text-white bg-voilet w-100 btnlogin my-4" [disabled]="f.invalid">Submit</button>
                                </form>
                            </div>
                        </div>
                        <a class="primary-text" routerLink="/login" (click)="navTo()"><em class="fa fa-arrow-left mr-2"></em>Return to log in</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-none d-md-flex col-md-4 col-lg-6 bg-image px-6">
            <div class="text-white d-flex flex-column justify-content-center align-items-start">
                <h1 class="loginTitle text-white font-weight-normal">Welcome to <strong>Spear</strong></h1>
                <p class="loginPara mb-5">I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click  or double click me to add your own content and make changes to the font. I’m a great place for you to tell a story and let your users know a little more about you.</p>
                <button class="btn btnRead">Read more...</button>
            </div> 
        </div>
    </div>
    </div>

