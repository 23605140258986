import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

declare var $: any;

@Directive({
  selector: '[jTime]'
})
export class JqueryTimepickerDirective implements OnInit, OnDestroy {
  private element: any;
  @Input() formCtrl: FormControl;

  constructor(
    private el: ElementRef,
  ) { }

  ngOnInit(): void {
    this.element = $(this.el.nativeElement);
    this.element.timepicker({
      timeFormat: 'h:mm p',
      interval: 1,
      dynamic: true,
      dropdown: true,
      scrollbar: true,
      ...(this.formCtrl && {
        onSelect: (value) => {
          this.formCtrl.setValue(value);
          this.formCtrl.markAsDirty();
        }
      }),
    });
    this.element.prop('readonly', true);
    this.element.prop('placeholder', 'h:mm am/pm');
    this.element.prop('type', 'text');
    this.element.addClass('j-time');
  }

  ngOnDestroy(): void {
    this.element.timepicker('destroy');
  }
}
