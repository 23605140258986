<!-- Modal -->
<div *blockUI="'show-loader'">
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="!deselect">Confirm Select All</h4>
        <h4 class="modal-title" *ngIf="deselect">Confirm Deselect All</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="flex text-center flex-col w-full">
            <p *ngIf="!deselect">Are you sure you want to select all fields of {{profile}} profile?</p>
            <p *ngIf="deselect">Are you sure you want to deselect all fields of {{profile}} profile?</p>
            <div class="flex">
                <button class="btn primary-bg text-white btnlogin mr-3" (click)="selectedAll()">Yes</button>
                <button class="btn bg-orange text-white btnlogin" (click)="noselectAll()">No</button>
            </div>
            
        </div>
    </div>
</div>
