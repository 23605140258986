import { Component, OnInit } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { ResetPasswordModel } from 'src/app/models/account/reset.password.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageUsersService } from 'src/app/services/manage.users.service';
import { AppUtils } from 'src/app/utilities/app.utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.css']
})
export class CreatePasswordComponent implements OnInit {

  @BlockUI('frm-createPassword') blockUI: NgBlockUI;
  createPasswordModel: ResetPasswordModel = {
    email: '',
    userId: '',
    password: '',
    confirmPassword: '',
    token: ''
  };
  constructor(private route: ActivatedRoute,
              private router: Router,
              private manageUserService: ManageUsersService,
              private appUtils: AppUtils,
              private toastr: ToastrService) {
    this.route.queryParams.subscribe(params => {
      this.createPasswordModel.userId = params.key;
      this.createPasswordModel.token = params.secret;
    });
  }

  ngOnInit(): void {
  }

  createPassword(): void {
    this.blockUI.start();
    this.manageUserService.createPassword(this.createPasswordModel).subscribe(data => {
      this.toastr.success(data as string);
      setTimeout (() => {
        this.router.navigate(['/login']);
        this.blockUI.stop();
     }, 1000);
    },
    error => {
      this.blockUI.stop();
      this.appUtils.ProcessErrorResponse(this.toastr, error);
    });
  }

}
