<div class="container-fluid">
    <div class="row no-gutter">

        <div class="d-none d-md-flex col-md-6 px-6">
            <div class="text-white d-flex flex-column justify-content-center align-items-start">
                <h1 class="loginTitle ml-5  font-weight-normal">Welcome to <br>
                    <strong>Argus First Inc.</strong>
                </h1>
            </div>
        </div>

        <div class="col-md-6">
            <div class="login d-flex align-items-center py-5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-device col-md-6 mx-auto">
                            <div class="text-center mb-3">
                                <img src="assets/images/icon-os-desktop-no_hexagons.png" width="120px" alt="icon">
                            </div>
                            <div class="card card-device-tab flex-fill border-0 px-4 py-5" *blockUI="'frm-login'">
                                <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
                                    <label class="loginLabel" for="userName">User Name</label>
                                    <input name="userName" type="text" #userName="ngModel" class="form-control mb-3"
                                        [(ngModel)]="loginModel.username" (ngModelChange)="valuechange()" required>
                                    <div class="alert alert-danger p-2" *ngIf="userName.invalid && userName.touched">
                                        Username is required</div>
                                    <label class="loginLabel" for="password">Password</label>
                                    <input name="password" type="password" #password="ngModel"
                                        [(ngModel)]="loginModel.password" class="form-control mb-3" (ngModelChange)="valuechange()" required
                                        minlength="6" >
                                    <div class="alert alert-danger p-2"
                                        *ngIf="password.errors?.required && password.touched">Password is required</div>
                                    <div class="alert alert-danger p-2"
                                        *ngIf="password.errors?.minlength && password.touched">Password should be more
                                        than or equal 6 character</div>
                                    <div class="alert alert-danger p-2" *ngIf="errMsg">Username and Password does not
                                        match</div>
                                    <button type="submit" class="btn primary-bg text-white w-100 btnlogin my-4"
                                        [disabled]="f.invalid">Log In</button>
                                    <div class="or-container">
                                        <div class="line-separator"></div>
                                        <div class="or-label">OR</div>
                                        <div class="line-separator"></div>
                                    </div>
                                </form>
                                <div class="glogin-container">
                                    <div id="g_id_onload"
                                        data-client_id="112767788198-l6mue54i0hvmc6ekit8a6jvqv7vus5vd.apps.googleusercontent.com"
                                        data-callback="handleCredentialResponse">
                                    </div>
                                    <div class="g_id_signin" data-type="standard"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p class="privacy-text">&#169; 2022 Argus First, Inc. All rights reserved. | <a class="text-voilet"
                    routerLink="">Privacy Policy</a></p>
        </div>
    </div>
</div>