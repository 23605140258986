import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchstateService {

  private searchedResult = [{}];
  private searchedItems = [''];
  private searchByState: string;
  private providerSize: string;

  constructor() { 
    this.searchedResult = [];
    this.searchedItems = [];
    this.searchByState = '';
    this.providerSize = '';
  }

  getSearchState(): Observable<any>{
    return of(this.searchedResult);
  }

  setSearchedState(newState: any): void {
    this.searchedResult = newState;
  }

  getSearchItemState(): Observable<any>{
    return of(this.searchedItems);
  }

  setSearchedItemState(newState: any): void {
    this.searchedItems = newState;
  }

  getSearchByState(): Observable<any>{
    return of(this.searchByState);
  }

  setSearchedByState(newState: any): void {
    this.searchByState = newState;
  }
  getProviderSizeState(): Observable<any>{
    return of(this.providerSize);
  }

  setProviderSizeState(newState: any): void {
    this.providerSize = newState;
  }
}
