import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.css']
})
export class CaseComponent implements OnInit {
  public showCaseColCustomizeSettings = false;
  public caseColumns = [
    { name: 'Case Name', selected: false },
    { name: 'Client Name', selected: false },
    { name: 'Reference No.', selected: false },
    { name: 'Insurance No.', selected: false },
    { name: 'Client Name', selected: false },
    { name: 'TPA', selected: false },
    { name: 'MGA', selected: false },
  ];
  constructor() { }

  ngOnInit() {
  }

  setCustomColumns(){
    console.log(this.caseColumns);
    this.showCaseColCustomizeSettings = false;
  }

}
