<div class="invoices-list bg-white" *blockUI="'invoice-list'">
  <table class="table spear-table-header-background search-spear-background">
    <caption>
      NPI
    </caption>
    <thead>
      <tr>
        <th class="border-0" scope="col">
          Invoice No.
          <i class="fa fa-sort-up"></i>
          <i class="fa fa-sort-down"></i>
        </th>
        <th class="border-0" scope="col">
          Case Number
          <i class="fa fa-sort-up"></i>
          <i class="fa fa-sort-down"></i>
        </th>
        <th class="border-0" scope="col">
          Status
          <i class="fa fa-sort-up"></i>
          <i class="fa fa-sort-down"></i>
        </th>
        <th class="border-0" scope="col">
          Client Case Number
          <i class="fa fa-sort-up"></i>
          <i class="fa fa-sort-down"></i>
        </th>
        <th class="border-0" scope="col">
          Client
          <i class="fa fa-sort-up"></i>
          <i class="fa fa-sort-down"></i>
        </th>
        <th class="border-0" scope="col">
          Subject Name
          <i class="fa fa-sort-up"></i>
          <i class="fa fa-sort-down"></i>
        </th>
        <th class="border-0" scope="col">
          Provider Name
          <i class="fa fa-sort-up"></i>
          <i class="fa fa-sort-down"></i>
        </th>
        <th class="border-0" scope="col">
          Invoice Total
          <i class="fa fa-sort-up"></i>
          <i class="fa fa-sort-down"></i>
        </th>
        <th class="border-0" scope="col">
          Total Paid
          <i class="fa fa-sort-up"></i>
          <i class="fa fa-sort-down"></i>
        </th>
        <th class="border-0" scope="col">
          Created On
          <i class="fa fa-sort-up"></i>
          <i class="fa fa-sort-down"></i>
        </th>
      </tr>
    </thead>

    <tbody *ngIf="invoices?.length">
      <ng-container *ngFor="let invoice of invoices">
        <tr class="bg-white">
          <td>
            <a
              [routerLink]="['/invoice-pdf', invoice.id]"
              target="_blank"
              class="cursor-pointer primary-text font-weight-semibold"
              >{{ invoice.invoice_number }}</a
            >
          </td>
          <td>
            <a
              [routerLink]="[
                '/limos',
                invoice.claim_number,
                invoice.limos_case_number
              ]"
              target="_blank"
              class="cursor-pointer primary-text font-weight-semibold"
              >{{ invoice.limos_case_number }}</a
            >
          </td>
          <td>{{ invoice.status }}</td>
          <td>{{ invoice.case_number }}</td>
          <td>{{ invoice.client }}</td>
          <td>{{ invoice.claimant_name }}</td>
          <td>{{ invoice.provider_name }}</td>

          <td>{{ invoice.invoice_total | currency }}</td>
          <td>{{ invoice.total | currency }}</td>
          <td>{{ invoice.created_on | clientDateFormat }}</td>
        </tr>
      </ng-container>
    </tbody>

    <ng-template *ngIf="!invoices?.length">
      <tbody>
        <tr class="bg-white">
          <td colspan="10" class="text-center">Data not available</td>
        </tr>
      </tbody>
    </ng-template>
  </table>
</div>
<div class="invoice-pagination">
  <div class="px-0 py-0 paginationWrapper d-flex" *ngIf="invoices?.length">
    <ngb-pagination
      [(collectionSize)]="invoiceTotal"
      [(page)]="invoicePage"
      [pageSize]="invoiceLimit"
      [maxSize]="10"
      [boundaryLinks]="true"
      (pageChange)="onPageChangeinvoices($event)"
    >
    </ngb-pagination>

    <div class="selected d-flex align-items-center">
      <select
        (change)="onSizeChangeinvoices($event.target.value)"
        class="custom-select table-select h-100"
        [(ngModel)]="invoiceLimit"
      >
        <option [value]="30">30</option>
        <option [value]="50">50</option>
        <option [value]="75">75</option>
        <option [value]="100">100</option>
      </select>

      <p class="mb-0 ml-2 text-nowrap">
        Showing {{ invoicePage * invoiceLimit - invoiceLimit + 1 }} -
        {{
          invoicePage * invoiceLimit > invoiceTotal
            ? invoiceTotal
            : invoicePage * invoiceLimit
        }}
        of {{ invoiceTotal }}
      </p>
    </div>
  </div>
</div>
