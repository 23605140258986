import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { ProvidertablesService } from 'src/app/services/providertables.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {

  constructor(
    private _NgbActiveModal: NgbActiveModal, 
    private toastr: ToastrService,
    private http: HttpClient,
    private providertable: ProvidertablesService
  ) { }

  uuid: string;
  deleteFrom: string;
  spearId: string[];
  WhitePap: any[] = [];
  ClearRep: any[] = [];

  ngOnInit(): void {}

  get activeModal() {
    return this._NgbActiveModal;
  }
  deleteFile(){
    this.providertable.deleteDocument(this.spearId, this.uuid);
    this.activeModal.dismiss();
  }

}
