import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of, OperatorFunction, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { CurrentUserProfile } from 'src/app/models/account/current.user.profile';
import { Client } from 'src/app/models/client/client.interface';
import {
  DepartmentColumn,
  DepartmentCreateResponse,
  DepartmentDataResponse,
  DepartmentName,
  DepartmentPaginatedData,
  Progression,
  ReorderReq,
} from 'src/app/models/department/department.interface';
import {
  Diary,
  LimosDataResponse,
  PaginationData,
} from 'src/app/models/limos/limos.models';
import { ProviderFilterModel } from 'src/app/models/provider/provider.filter.model';
import { AccountService } from 'src/app/services/account.service';
import { DepartmentService } from 'src/app/services/department.service';
import { LimosService } from 'src/app/services/limos.service';
import { ProviderPagedResult } from './../../models/provider/provider.paged.result';
import { RecordCount } from './../../models/provider/records.count';
import { ProviderService } from './../../services/provider.service';
import { AppUtils } from './../../utilities/app.utils';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  todayDate: Date = new Date();

  providers: ProviderPagedResult;
  recordsCount: RecordCount;
  providerfilterModel: ProviderFilterModel;
  searchText: string;
  searchCriteria: string[];
  page: number;
  pageSize: number;
  orderBy: string;
  isDescending: boolean;
  omitEmptyNames: boolean;
  showDublicateName: boolean;
  showNewRecords: boolean;
  providerType: number;
  SPRcount: any;
  caseDtls = [
    {
      tab: 'Diaries',
      columns: [
        'Case Number',
        'Name of Case',
        'Diary Type',
        'Diary Note',
        'Due Date',
      ],
    },
    {
      tab: 'Assigned Cases',
      columns: [
        'Case Number',
        'Name of Case',
        'Case Status',
        'Client',
        'Last Updated',
      ],
    },
  ];
  departmentLimit = 20;
  departmentPage = 1;
  departmentTotal: number;
  departmentNames: DepartmentName[];

  caseDtlsColumns = this.caseDtls[0].columns;
  private unsubscribe$ = new Subject<void>();
  @BlockUI('dashboard-screen') blockUI: NgBlockUI;
  @BlockUI('diaries-list-ui') public diariesBlockUI: NgBlockUI;

  diaryLimit = 30;
  diaryPage = 1;
  diaryTotal: number;
  diaries: Diary[] = [];
  selectedCaseTab: string;
  departmentColumns: DepartmentColumn[];
  departmentColumnTotal: number;
  @BlockUI('department-card-ui') departmentCardBlockUI: NgBlockUI;
  userProfile: CurrentUserProfile;
  activeDepartmentTab = 'Company';
  departmentTabs = [];
  ranks = [
    'unassigned cases',
    'open cases',
    'editing',
    'quality control',
    'case monitoring',
    'web preserve',
    'invoicing',
    'etc',
    'totals',
    'video processing',
    'review',
    'monitoring',
    'case setup',
    'level I',
    'level II',
    'level III',
    'D/R In Progress',
    'level IV',
    'level V',
    'level VI',
    'liens only',
    'provider review',
    'product active',
    'pending auth',
    'setup',
    'active cases',
    'follow up',
    'hold',
  ];
  progressionForm: FormGroup;
  isdepartmentProgressionFormSubmitted: boolean;
  progressions: Progression[];
  departmentProgressionTotal: number;
  editDepartmentProgressionData: Progression;
  departmentNameForm: FormGroup;
  editDepartmentNameData: DepartmentName;
  isdepartmentNameFormSubmitted: boolean;
  clientSearchLoader: boolean;
  clientSerchResult: Client[];
  caseForm: FormGroup;
  clientContacts = [];
  clientLocations = [];
  departmentTags: string[] = [];

  constructor(
    private providersService: ProviderService,
    private appUtils: AppUtils,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private limosService: LimosService,
    private departmentService: DepartmentService,
    private accountService: AccountService
  ) {
    this.searchText = '';
    this.searchCriteria = [];
    this.page = 1;
    this.pageSize = 7;
    // this.orderBy = ProviderListHeadersEnum.createdDate as any;
    this.isDescending = false;
    this.omitEmptyNames = false;
    this.showDublicateName = false;
    this.showNewRecords = true;
    this.providerType = 1;
  }

  ngOnInit(): void {
    this.getUserInfo();
    this.providers = new ProviderPagedResult();
    this.recordsCount = new RecordCount();
    this.providerfilterModel = new ProviderFilterModel(
      this.searchText,
      this.searchCriteria,
      this.page,
      this.pageSize,
      this.orderBy,
      this.isDescending,
      this.omitEmptyNames,
      this.showDublicateName,
      this.showNewRecords,
      this.providerType
    );
    //this.getRecordsCount();
    this.getProvidersPagedList();
    this.getSPRCount();
    this.setCaseDtlsColumns('Diaries');
    this.getDepartmentData();
    this.initProgressionForm();
    this.initCaseForm();
  }

  initProgressionForm() {
    this.progressionForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      color: new FormControl(''),
      department: new FormControl(null),
      notes: new FormControl(''),
      rank: new FormControl(''),
    });
  }

  initCaseForm() {
    this.caseForm = new FormGroup({
      created_on: new FormControl('', [Validators.required]),
      due_date: new FormControl(''),
      client_name: new FormControl('', [Validators.required]),
      client_location: new FormControl(''),
      program: new FormControl(''),
      tpa: new FormControl(''),
      mga: new FormControl(''),
      case_contact: new FormControl(''),
      client_contact: new FormControl(''),
      case_type: new FormControl(''),
      case_status: new FormControl(''),
      case_reference: new FormControl(''),
      case_name: new FormControl(''),
      case_insured: new FormControl(''),
      case_location: new FormControl(''),
      claim_status: new FormControl(''),
      adj_number: new FormControl(''),
    });
  }

  getUserInfo() {
    this.accountService.$userInfo
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe((userInfo: CurrentUserProfile) => {
        this.getDiaries(userInfo?.details?.id);
      });
  }

  getDepartmentData() {
    const $departmentNames = this.departmentService.getDepartmentNames(
      this.departmentLimit,
      this.departmentPage
    );

    const $departmentColumns = this.departmentService.getDepartmentColumns(
      this.departmentLimit,
      this.departmentPage
    );

    const $departmentProgressions =
      this.departmentService.getDepartmentProgressions(
        this.departmentLimit,
        this.departmentPage
      );

    this.departmentCardBlockUI.start();
    forkJoin([$departmentNames, $departmentColumns, $departmentProgressions])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          departmentNamesResp,
          departmentColumnsResp,
          departmentProgressionResp,
        ]: [
          DepartmentDataResponse<DepartmentPaginatedData<DepartmentName>>,
          DepartmentDataResponse<DepartmentPaginatedData<DepartmentColumn>>,
          DepartmentDataResponse<DepartmentPaginatedData<Progression>>
        ]) => {
          const {
            hits: departmentColumnHits,
            estimatedTotalHits: departmentColumnEstimatedTotalHits,
          } = departmentColumnsResp.details;
          this.departmentColumns = departmentColumnHits;
          this.departmentColumnTotal = departmentColumnEstimatedTotalHits;

          const {
            hits: departmentNameHits,
            estimatedTotalHits: departmentNameEstimatedTotalHits,
          } = departmentNamesResp.details;
          this.departmentNames = departmentNameHits;
          if (this.departmentNames.length > 0) {
            this.departmentNames.forEach((name) => {
              if (name.tag) {
                name.tag.split(',').forEach((tag) => {
                  this.departmentTags.push(tag);
                });
              }
            });
          }
          this.departmentTotal = departmentNameEstimatedTotalHits;

          const {
            hits: departmentProgressionHits,
            estimatedTotalHits: departmentProgressionEstimatedTotalHits,
          } = departmentProgressionResp.details;
          this.progressions = departmentProgressionHits;
          this.departmentProgressionTotal =
            departmentProgressionEstimatedTotalHits;
          this.departmentCardBlockUI.stop();
        },
        ({ error }: HttpErrorResponse) => {
          this.toastr.success(error?.errors[0]);
          this.departmentCardBlockUI.stop();
        }
      );
  }

  getDepartmentProgressions(departmentId = null) {
    this.departmentCardBlockUI.start();
    this.departmentService
      .getDepartmentProgressions(
        this.departmentLimit,
        this.departmentPage,
        departmentId
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (
          resp: DepartmentDataResponse<DepartmentPaginatedData<Progression>>
        ) => {
          const { hits, offset, estimatedTotalHits } = resp.details;
          this.progressions = hits;
          this.departmentTotal = estimatedTotalHits;
          this.departmentCardBlockUI.stop();
        },
        (error) => {
          this.departmentCardBlockUI.stop();
        }
      );
  }

  onPageChangedepartments(event: any) {
    this.departmentPage = event;
    this.getDepartmentData();
  }

  onSizeChangedepartments(limit: number) {
    this.departmentLimit = limit;
    this.departmentPage = 1;
    this.getDepartmentData();
  }

  getProvidersPagedList = (): void => {
    this.blockUI.start();
    this.providersService.getProviders(this.providerfilterModel).subscribe(
      (data) => {
        this.providers = data;
        this.blockUI.stop();
      },
      (error) => {
        this.appUtils.ProcessErrorResponse(this.toastr, error);
        this.blockUI.stop();
      }
    );
  };
  getRecordsCount = () => {
    this.blockUI.start();
    this.providersService.getRecordsCount().subscribe(
      (data) => {
        this.recordsCount = data;
      },
      (error) => {
        this.appUtils.ProcessErrorResponse(this.toastr, error);
        this.blockUI.stop();
      }
    );
  };

  getSPRCount = () => {
    this.blockUI.start();
    this.providersService.getSprCount('SPR').subscribe(
      (data) => {
        this.SPRcount = data;
        this.blockUI.stop();
      },
      (error) => {
        this.appUtils.ProcessErrorResponse(this.toastr, error);
        this.blockUI.stop();
      }
    );
  };

  openModal(content, size = 'md') {
    this.modalService.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size,
      centered: true,
    });
  }

  setCaseDtlsColumns(tab: string) {
    this.selectedCaseTab = tab;
    this.caseDtlsColumns = this.caseDtls.find((dtl) => dtl.tab == tab).columns;
  }

  getDiaries(userId = null) {
    this.diariesBlockUI.start();
    this.limosService
      .getDiaries(this.diaryLimit, this.diaryPage, userId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp: LimosDataResponse<PaginationData<Diary>>) => {
          const { hits, estimatedTotalHits } = resp.details;
          this.diaries = hits;
          this.diaryTotal = estimatedTotalHits;
          this.diariesBlockUI.stop();
        },
        (error) => {
          console.error('Error fetching cases:', error);
          this.diariesBlockUI.stop();
        }
      );
  }

  onPageChangeDiaries(event: any) {
    this.diaryPage = event;
    this.getDiaries();
  }

  onSizeChangeDiaries(limit: number) {
    this.diaryLimit = limit;
    this.diaryPage = 1;
    this.getDiaries();
  }

  checkAllDiaries(value: boolean) {
    if (value) {
      this.getDiaries();
    } else {
      this.getDiaries(this.accountService.userInfo?.details?.id);
    }
  }

  onSubmitdepartmentProgressionForm() {
    if (this.progressionForm.invalid) {
      return;
    }

    this.isdepartmentProgressionFormSubmitted = true;

    if (this.editDepartmentProgressionData?.id) {
      this.updateDepartmentProgression();
    } else {
      this.saveDepartmentProgression();
    }
  }

  saveDepartmentProgression() {
    this.departmentService
      .createDepartmentProgression(this.progressionForm.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: DepartmentDataResponse<DepartmentCreateResponse>) => {
          this.toastr.success(res?.details?.message);
          this.modalService.dismissAll();
          this.isdepartmentProgressionFormSubmitted = false;
          const { department } = this.progressionForm.value;
          this.getDepartmentProgressions(department);
          this.progressionForm.reset();
        },
        (error) => {
          this.toastr.success(error?.errors[0]);
        }
      );
  }

  updateDepartmentProgression() {
    this.departmentService
      .updateDepartmentProgression(
        this.editDepartmentProgressionData.id,
        this.progressionForm.value
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: DepartmentDataResponse<DepartmentCreateResponse>) => {
          this.toastr.success(res?.details?.message);
          const { department } = this.progressionForm.value;
          this.getDepartmentProgressions(department);
          this.modalService.dismissAll();
          this.progressionForm.reset();
          this.isdepartmentProgressionFormSubmitted = false;
          this.editDepartmentProgressionData = null;
        },
        (error) => {
          this.toastr.success(error?.errors[0]);
        }
      );
  }

  editProgression(
    progression: Progression,
    createNewProgression: TemplateRef<any>
  ) {
    this.openModal(createNewProgression, 'md');
    const { id, name, color, department, notes, rank } = progression;
    this.editDepartmentProgressionData = progression;
    this.progressionForm = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      color: new FormControl(color),
      department: new FormControl(department.id),
      notes: new FormControl(notes),
      rank: new FormControl(rank),
    });
  }

  deleteDepartmentProgression(progression: Progression) {
    if (confirm('Are you sure you want to delete this Progression?')) {
      this.departmentService
        .deleteDepartmentProgression(progression.id)
        .subscribe(
          (res: DepartmentDataResponse<DepartmentCreateResponse>) => {
            this.toastr.success(res?.details?.message);
            this.getDepartmentProgressions(progression.department.id);
          },
          ({ error }: HttpErrorResponse) => {
            this.toastr.error(error?.errors[0]);
          }
        );
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      (this.activeDepartmentTab == 'Company'
        ? this.departmentNames
        : this.progressions) as any[],
      event.previousIndex,
      event.currentIndex
    );

    this.reOrderRows();
  }

  reOrderRows() {
    const req: ReorderReq = {
      order_list:
        this.activeDepartmentTab == 'Company'
          ? this.departmentNames.map((name) => name.id)
          : this.progressions.map((progression) => progression.id),
    };
    this.departmentService
      .reOrderRow(
        req,
        this.activeDepartmentTab == 'Company' ? 'names' : 'progression'
      )
      .subscribe(
        (res: DepartmentDataResponse<DepartmentCreateResponse>) => {
          this.toastr.success(res?.details?.message);
          // this.getDepartmentNames();
        },
        ({ error }: HttpErrorResponse) => {
          this.toastr.error(error?.errors[0]);
        }
      );
  }

  editDepartmentName(createDepartment, departmentName: DepartmentName) {
    this.openModal(createDepartment, 'md');
    const { name, tag, notes } = departmentName;
    this.editDepartmentNameData = departmentName;
    this.departmentNameForm = new FormGroup({
      name: new FormControl(name),
      tag: new FormControl(tag),
      notes: new FormControl(notes),
    });
  }

  deleteDepartmentName(id: number) {
    if (confirm('Are you sure you want to delete this department?')) {
      this.departmentService.deleteDepartmentName(id).subscribe(
        (res: DepartmentDataResponse<DepartmentCreateResponse>) => {
          this.toastr.success(res?.details?.message);
          this.getDepartmentData();
        },
        ({ error }: HttpErrorResponse) => {
          this.toastr.error(error?.errors[0]);
        }
      );
    }
  }

  updateDepartmentName() {
    this.departmentService
      .updateDepartmentName(
        this.editDepartmentNameData.id,
        this.departmentNameForm.value
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: DepartmentDataResponse<DepartmentCreateResponse>) => {
          this.toastr.success(res?.details?.message);
          this.modalService.dismissAll();
          this.departmentNameForm.reset();
          this.isdepartmentNameFormSubmitted = false;
          this.getDepartmentData();
          this.editDepartmentNameData = null;
        },
        (error) => {
          this.toastr.success(error?.errors[0]);
        }
      );
  }

  setNewProgressionFormData(departmentId: number) {
    this.progressionForm.get('department').setValue(departmentId);
  }

  clientSearchFormatter = (client: Client) => client.client_name;

  searchClient: OperatorFunction<string, readonly any[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      tap((term) => {
        if (term.length <= 2) {
          this.clientSearchLoader = false;
        }
      }),
      debounceTime(1000),
      distinctUntilChanged(),
      tap((term) => {
        if (term.length > 2) {
          this.clientSearchLoader = true;
        }
      }),
      switchMap((term) =>
        term.length < 3
          ? []
          : this.limosService.searchClient(term).pipe(
              tap((result) => {
                if (result) {
                  this.clientSearchLoader = false;
                }
              }),
              map((results: Client[]) => {
                this.clientSerchResult = results;
                const startsWithResults = this.clientSerchResult.filter(
                  (result): any =>
                    result.client_name
                      .toLowerCase()
                      .startsWith(term.toLowerCase())
                );
                const otherResults = this.clientSerchResult.filter(
                  (result) =>
                    !result.client_name
                      .toLowerCase()
                      .startsWith(term.toLowerCase())
                );
                return [...startsWithResults, ...otherResults];
              }),
              catchError(() => {
                return of([]);
              })
            )
      )
    );

  onClientSelect(clientData: Client) {
    this.caseForm
      .get('client_location')
      .setValue(clientData?.address?.location_name);
    this.caseForm.get('program').setValue(clientData?.program_name);
    this.caseForm.get('tpa').setValue(clientData?.tpa);
    this.caseForm.get('mga').setValue(clientData?.mga);
    this.caseForm.get('client_contact').setValue(clientData?.main_phone);

    if (clientData?.locations?.length > 0) {
      clientData.locations.forEach((location) => {
        this.clientLocations.push(location?.location_name);
      });
    }

    if (clientData?.contacts?.length > 0) {
      clientData.contacts.forEach(({ first_name, middle_name, last_name }) => {
        this.clientContacts.push(`${first_name} ${middle_name} ${last_name}`);
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
