<div class="row profileReport block-ui__element" *ngIf="profileModel">
    <!-- *blockUI="'manage-users-blockUI'" -->
    <div class="col-12 primary-tab" *ngIf="profileDataReady">
        <ul class="nav nav-tabs">
            <li class="nav-item" *ngFor="let tab of pageTabs">
                <button class="nav-link text-uppercase" [ngClass]="{'active search-spear-background': activeTab == tab}"
                    (click)="activeTab = tab">{{ tab }}</button>
            </li>
        </ul>
    </div>

    <div class="col-12 profileReportBody search-spear-background">

        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-between align-items-center pl-2 pb-3 business-info">
                    <div class="d-flex flex-row">
                        <div class="d-flex flex-column mr-4">
                            <div><strong>Total Open/Delayed Claims</strong></div>
                            <div class="text-center">&#8212;</div>
                        </div>
                        <div class="d-flex flex-column mr-4">
                            <div><strong>Total Closed Claims</strong></div>
                            <div class="text-center">&#8212;</div>
                        </div>
                        <div class="d-flex flex-column mr-4">
                            <div><strong>Total Claims Billed</strong></div>
                            <div class="text-center">&#8212;</div>
                        </div>
                        <div class="d-flex flex-column mr-4">
                            <div><strong>Total Claims Paid</strong></div>
                            <div class="text-center">&#8212;</div>
                        </div>
                        <div class="d-flex flex-column mr-4">
                            <div><strong>Open Claims Billed</strong></div>
                            <div class="text-center">&#8212;</div>
                        </div>
                        <div class="d-flex flex-column mr-4">
                            <div><strong>Open Claims Paid</strong></div>
                            <div class="text-center">&#8212;</div>
                        </div>
                        <div class="d-flex flex-column">
                            <div><strong>Open Claims Billed No Pay</strong></div>
                            <div class="text-center">&#8212;</div>
                        </div>
                    </div>
                    <div class="d-flex flex-row">
                        <button class="btn btn-sm spear-button-background text-white text-uppercase">Spear</button>
                        <button class="btn btn-sm limos-button-background text-white ml-2">LIMOS</button>
                        <button class="btn btn-sm adonis-button-background text-white ml-2">ADONIS</button>
                        <button class="btn btn-sm urania-button-background text-white ml-2">URANIA</button>
                        <button class="btn btn-sm midas-button-background text-white ml-2">MIDAS</button>
                        <button routerLink="/reports" class="btn btn-sm achilles-button-background text-white ml-2">ACHILLES</button>
                    </div>
                </div>
                <div class="border-0 title-wrap">
                    <div class="spear-info">
                        <div class="row">
                            <div class="col-md-3 text-center">
                                <h4 class="page-title">Provider Profile - Entities</h4>
                            </div>
                            <div class="col-md-9">
                                <div class="row">
                                    <div class="col-md-6 text-center">
                                        <h5 class="page-title">
                                            {{profileModel.details?.entity_name || 'No Name
                                            Available'}}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="activeTab == 'Dashboard'">
                <div class="col-md-3">
                    <div class="row mb-1">
                        <div class="col-12">
                            <div class="accordian-tab card-header spear-table-header-background title-space">
                                <h6 class="m-0">TIN</h6>
                                <button class="btn"><em (click)="togglePanel('Tin')" class="fa fa-sort-desc"
                                        [ngClass]="tinStatus ? '' : 'antirotate'"></em></button>
                            </div>
                            <div [ngClass]="tinStatus ? 'collapsecard' : 'expandcard'">
                                <div class="card border-0 card-scrollbar">
                                    <div class="card-body p-0">
                                        <table class="table providerTable">
                                            <caption>TIN</caption>
                                            <thead>
                                                <tr>
                                                    <th class="border-0" scope="col">Number</th>
                                                    <th class="border-0" scope="col">Last Year Billed</th>
                                                    <th class="border-0" scope="col">Box 24J Associations</th>
                                                    <th class="border-0" scope="col">Box 32 Associations</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="TinTable.length>0">
                                                <tr class="bg-white" *ngFor="let row of TinTable">
                                                    <td class="primary-text font-weight-semibold">{{row.tin}}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="TinTable.length<1">
                                                <tr class="bg-white">
                                                    <td colspan="4" class="text-center">Data not available</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-12">
                            <div class="accordian-tab card-header spear-table-header-background title-space">
                                <h6 class="m-0">NPI</h6>
                                <button class="btn"><em (click)="togglePanel('NPI')" class="fa fa-sort-desc"
                                        [ngClass]="NPIStatus ? '' : 'antirotate'"></em></button>

                            </div>
                            <div [ngClass]="NPIStatus ? 'collapsecard' : 'expandcard'">
                                <div class="card border-0 card-scrollbar">
                                    <div class="card-body p-0">
                                        <table class="table providerTable">
                                            <caption>NPI</caption>
                                            <thead>
                                                <tr>
                                                    <th class="border-0" scope="col">Number</th>
                                                    <th class="border-0" scope="col">Last Year Billed</th>
                                                    <th class="border-0" scope="col">Status</th>
                                                    <th class="border-0" scope="col">Box 32 Violation</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="NpiTable.length>0">
                                                <tr class="bg-white" *ngFor="let row of NpiTable">
                                                    <td class="primary-text font-weight-semibold">{{row.npi}}</td>
                                                    <td></td>
                                                    <td>{{row.status}}</td>
                                                    <td>{{row.box32}}</td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="NpiTable.length<1">
                                                <tr class="bg-white">
                                                    <td colspan="4" class="text-center">Data not available</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-12">
                            <div class="accordian-tab card-header spear-table-header-background title-space">
                                <h6 class="m-0">Licenses</h6>
                                <button class="btn"><em (click)="togglePanel('LIC')" class="fa fa-sort-desc"
                                        [ngClass]="licStatus ? '' : 'antirotate'"></em></button>
                            </div>
                            <div [ngClass]="licStatus ? 'collapsecard' : 'expandcard'">
                                <div class="card border-0 card-scrollbar">
                                    <div class="card-body p-0">
                                        <table class="table providerTable">
                                            <caption>Licenses</caption>
                                            <thead>
                                                <tr>
                                                    <th class="border-0" scope="col">Number</th>
                                                    <th class="border-0" scope="col">Type</th>
                                                    <th class="border-0" scope="col">State</th>
                                                    <th class="border-0" scope="col">Status</th>
                                                    <th class="border-0" scope="col">Secondary Status</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="licensesObj.length>0">
                                                <tr class="bg-white paycode__click" *ngFor="let lic of licensesObj">
                                                    <td class="primary-text text-uppercase font-weight-semibold">
                                                        {{lic.number}} </td>
                                                    <td>{{lic.type || 'Not Listed'}}</td>
                                                    <td>{{lic.state || 'Not Listed'}}</td>
                                                    <td>{{lic.status || 'Not Listed' }}</td>
                                                    <td>—</td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="licensesObj.length<1">
                                                <tr class="bg-white">
                                                    <td colspan="5" class="text-center">Data not available</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-12">
                            <div class="accordian-tab card-header spear-table-header-background title-space">
                                <h6 class="m-0">IRS Match</h6>
                                <button class="btn "><em (click)="togglePanel('Irs')" class="fa fa-sort-desc"
                                        [ngClass]="irsStatus ? '' : 'antirotate'"></em></button>
                            </div>
                            <div [ngClass]="irsStatus ? 'collapsecard' : 'expandcard'">
                                <div class="card border-0  card-scrollbar">
                                    <div class="card-body p-0">
                                        <table class="table providerTable">
                                            <caption>IRS Match</caption>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">TIN</th>
                                                    <th scope="col">Match</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="false">
                                                <tr class="bg-white">
                                                    <td class="primary-text font-weight-semibold"></td>
                                                    <td class="primary-text font-weight-semibold"></td>
                                                    <td class="primary-text font-weight-semibold"></td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="true">
                                                <tr class="bg-white">
                                                    <td class="text-center" colspan="3">Data not available</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-12">
                            <div class="accordian-tab card-header spear-table-header-background title-space">
                                <h6 class="m-0">FNP Status</h6>
                                <button class="btn">
                                    <em (click)="togglePanel('Fnp')" class="fa fa-sort-desc"
                                        [ngClass]="fnpStatus ? '' : 'antirotate'"></em>
                                </button>
                            </div>
                            <div [ngClass]="fnpStatus ? 'collapsecard' : 'expandcard'">
                                <div class="card border-0 card-scrollbar">
                                    <div class="card-body p-0">
                                        <textarea class="form-control mt-1"
                                            id="fnp_status">{{profileModel.details?.fnp_status}}</textarea>
                                        <div class="text-right mt-1">
                                            <button class="btn viewBtn spear-button-background text-white small-btn"
                                                (click)="updateFNP()">Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-12">
                            <div class="accordian-tab card-header spear-table-header-background title-space">
                                <h6 class="m-0">Secretary of States</h6>
                                <button class="btn "><em (click)="togglePanel('Secre')" class="fa fa-sort-desc"
                                        [ngClass]="secreState ? '' : 'antirotate'"></em></button>
                            </div>
                            <div [ngClass]="secreState ? 'collapsecard' : 'expandcard'">
                                <div class="card border-0  card-scrollbar">
                                    <div class="card-body p-0">
                                        <table class="table providerTable">
                                            <caption>Secretary of States</caption>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Entity</th>
                                                    <th scope="col">State</th>
                                                    <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="false">
                                                <tr class="bg-white">
                                                    <td class="primary-text font-weight-semibold"></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="true">
                                                <tr class="bg-white">
                                                    <td class="text-center" colspan="3">Data not available</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="accordian-tab card-header spear-table-header-background title-space">
                                <h6 class="m-0">Associated MPNs</h6>
                                <button class="btn "><em (click)="togglePanel('Asso')" class="fa fa-sort-desc"
                                        [ngClass]="assoStatus ? '' : 'antirotate'"></em></button>
                            </div>
                            <div [ngClass]="assoStatus ? 'collapsecard' : 'expandcard'">
                                <div class="card border-0  card-scrollbar">
                                    <div class="card-body p-0">
                                        <table class="table providerTable">
                                            <caption>Associated MPNs</caption>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Entity</th>
                                                    <th scope="col">MPN Name</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="false">
                                                <tr class="bg-white">
                                                    <td class="primary-text font-weight-semibold"></td>
                                                    <td class="primary-text font-weight-semibold"></td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="true">
                                                <tr class="bg-white">
                                                    <td class="text-center" colspan="2">Data not available</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                  <div class="loader-container">Loading...</div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div id="armor">
                                <div class="accordian-tab card-header spear-table-header-background title-space">
                                    <h6>ARMOR</h6>
                                    <div class="d-flex">
                                      <button
                                        class="btn viewBtn spear-button-background text-white mr-2"
                                        (click)="openAddArmor(createArmor)">Add</button>
                                      <button class="btn">
                                          <em (click)="togglePanel('Armo')" class="fa fa-sort-desc"
                                              [ngClass]="armoStatus ? '' : 'antirotate'"></em>
                                      </button>
                                    </div>
                                </div>
                                <div [ngClass]="armoStatus ? 'collapsecard' : 'expandcard'">
                                    <div class="row table-space">
                                        <div class="col-12">
                                            <div class="card border-0 card-scrollbar card-scrolbar-armor"
                                                [ngClass]="armoStatus ? 'collapsecard' : 'expandcard'">
                                                <div class="table-row">
                                                    <div>
                                                        <table class="table providerTable">
                                                            <caption>Financial Interests</caption>
                                                            <thead>
                                                                <tr>
                                                                    <th class="border-0" scope="col">Pay Code</th>
                                                                    <th class="border-0" scope="col">Pay Code Status</th>
                                                                    <th class="border-0" scope="col">Flagged</th>
                                                                    <th class="border-0" scope="col">Flagged Date</th>
                                                                    <th class="border-0" scope="col"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody *ngIf="armorResopone.length > 0">
                                                                <tr class="bg-white" *ngFor="let row of armorResopone">
                                                                    <td class="link" (click)="editArmorDetails(row)">{{row.po_code}}</td>
                                                                    <td class="link" (click)="editArmorDetails(row)">{{row.pay_code_status}}</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td class="d-flex">
                                                                      <button class="btn small-btn"
                                                                      (click)="openDeleteArmor(deleteArmor,row.id)">
                                                                        <em class="fa fa-trash" aria-hidden="true"></em>
                                                                      </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody *ngIf="armorResopone.length < 1">
                                                                <tr class="bg-white">
                                                                    <td colspan="6" class="text-center">Data not available</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                <ng-container *ngIf="editArmorData">
                                                  <div
                                                      class="accordian-tab card-header spear-table-header-background mt-1">
                                                      <h6>Armor Investigative Actions</h6>
                                                  </div>
                                                  <div class="card-body pt-0">
                                                      <form name="armorForm" [formGroup]="armorForm">
                                                          <div class="row mt-1">
                                                              <div class="col-6">Pay Code</div>
                                                              <div class="col-6 d-flex">
                                                                  <select class="form-control"
                                                                      formControlName="armor_po_code"
                                                                      (change)="selectPayCode($event)"
                                                                      [ngClass]="!editArmorData ? 'disabled' : ''">
                                                                      <option value="{{opt.po_code}}" *ngFor="let opt of po_codes"
                                                                          [selected]="opt.po_code === armorForm.get('armor_po_code').value">
                                                                          {{opt.po_code}}</option>
                                                                  </select>
                                                                  <button title="Save" class="btn small-btn"
                                                                      (click)="updatePayCode()" *ngIf="armorForm.get('armor_po_code').dirty && editArmorData.po_code != armorForm.get('armor_po_code').value">
                                                                        <em class="fa fa-save" aria-hidden="true"></em>
                                                                  </button>
                                                              </div>
                                                          </div>
                                                          <div class="row mt-1">
                                                              <div class="col-6">
                                                                  Cross Walk BR Code
                                                              </div>
                                                              <div class="col-6 d-flex">
                                                                  <input type="text" class="form-control"
                                                                      formControlName="armor_br_code" />
                                                                  <button title="Save" class="btn small-btn"
                                                                      (click)="updatePayCode()" *ngIf="armorForm.get('armor_br_code').dirty && editArmorData.armor_br_code != armorForm.get('armor_br_code').value">
                                                                        <em class="fa fa-save" aria-hidden="true"></em>
                                                                  </button>
                                                              </div>
                                                          </div>
                                                          <div class="row mt-1">
                                                              <div class="col-6">State Code</div>
                                                              <div class="col-6 d-flex">
                                                                  <input type="text" class="form-control"
                                                                      formControlName="armor_state_code"
                                                                      [value]="profileModel.details?.state_code" />
                                                                  <button title="Save" class="btn small-btn"
                                                                  (click)="updatePayCode()" *ngIf="armorForm.get('armor_state_code').dirty && editArmorData?.state_code != armorForm.get('armor_state_code').value">
                                                                    <em class="fa fa-save" aria-hidden="true"></em>
                                                                  </button>
                                                              </div>
                                                          </div>
                                                          <div class="row mt-1">
                                                              <div class="col-6">
                                                                  Adjuster Alert Sent
                                                              </div>
                                                              <div class="col-6">
                                                                  <select class="form-control"
                                                                      formControlName="armor_adj_alert_sent">
                                                                      <option>Yes</option>
                                                                      <option>No</option>
                                                                  </select>
                                                              </div>
                                                          </div>
                                                          <div class="row mt-1">
                                                              <div class="col-6">Alert Date</div>
                                                              <div class="input-group col-6 d-flex">
                                                                <input class="form-control" type="text" formControlName="armor_alert_date"
                                                                  [formCtrl]="armorForm.get('armor_alert_date')" jDate />
                                                                <button title="Save" class="btn small-btn" (click)="updatePayCode()"
                                                                  *ngIf="armorForm.get('armor_alert_date').dirty && editArmorData?.armor_alert_date != armorForm.get('armor_alert_date').value">
                                                                  <em class="fa fa-save" aria-hidden="true"></em>
                                                                </button>
                                                              </div>
                                                          </div>
                                                          <div class="row mt-1">
                                                              <div class="col-6">
                                                                  Add?
                                                              </div>
                                                              <div class="col-6">
                                                                  <input type="text" class="form-control" />
                                                              </div>
                                                          </div>
                                                          <div class="row mt-1">
                                                              <div class="col-6">
                                                                  Additional Pay Code
                                                              </div>
                                                              <div class="col-6 d-flex">
                                                                <input type="text" class="form-control" formControlName="armor_additional_pay_code" />
                                                                <button title="Save" class="btn small-btn" (click)="updatePayCode()"
                                                                  *ngIf="armorForm.get('armor_additional_pay_code').dirty && editArmorData?.armor_additional_pay_code != armorForm.get('armor_additional_pay_code').value">
                                                                  <em class="fa fa-save" aria-hidden="true"></em>
                                                                </button>
                                                              </div>
                                                          </div>
                                                          <div class="row mt-1">
                                                              <div class="col-6">
                                                                  Effective Objection Date From
                                                              </div>
                                                              <div class="input-group col-6 d-flex">
                                                                <input class="form-control" type="text" formControlName="armor_effective_obj_from"
                                                                  [formCtrl]="armorForm.get('armor_effective_obj_from')" jDate />
                                                                <button title="Save" class="btn small-btn" (click)="updatePayCode()"
                                                                  *ngIf="armorForm.get('armor_effective_obj_from').dirty && editArmorData?.armor_effective_obj_from != armorForm.get('armor_effective_obj_from').value">
                                                                  <em class="fa fa-save" aria-hidden="true"></em>
                                                                </button>
                                                              </div>
                                                          </div>
                                                          <div class="row mt-1">
                                                              <div class="col-6">
                                                                  Effective Objection Date To
                                                              </div>
                                                              <div class="input-group col-6 d-flex">
                                                                <input class="form-control" type="text" formControlName="armor_effective_obj_to"
                                                                  [formCtrl]="armorForm.get('armor_effective_obj_to')" jDate />
                                                                <button title="Save" class="btn small-btn" (click)="updatePayCode()"
                                                                  *ngIf="armorForm.get('armor_effective_obj_to').dirty && editArmorData?.armor_effective_obj_to != armorForm.get('armor_effective_obj_to').value">
                                                                  <em class="fa fa-save" aria-hidden="true"></em>
                                                                </button>
                                                              </div>
                                                          </div>
                                                          <div class="row mt-1">
                                                              <div class="col-6">
                                                                  1244/1160 Notice Issued
                                                              </div>
                                                              <div class="col-6 d-flex">
                                                                <input type="text" class="form-control" formControlName="armor_notice_issued" />
                                                                <button title="Save" class="btn small-btn" (click)="updatePayCode()"
                                                                  *ngIf="armorForm.get('armor_notice_issued').dirty && editArmorData?.armor_notice_issued != armorForm.get('armor_notice_issued').value">
                                                                  <em class="fa fa-save" aria-hidden="true"></em>
                                                                </button>
                                                              </div>
                                                          </div>
                                                          <div class="row mt-1">
                                                              <div class="col-6">
                                                                  1244/1160 Suspension Date
                                                              </div>
                                                              <div class="input-group col-6 d-flex">
                                                                <input class="form-control" type="text" formControlName="armor_suspension_date"
                                                                  [formCtrl]="armorForm.get('armor_suspension_date')" jDate />
                                                                <button title="Save" class="btn small-btn" (click)="updatePayCode()"
                                                                  *ngIf="armorForm.get('armor_suspension_date').dirty && editArmorData?.armor_suspension_date != armorForm.get('armor_suspension_date').value">
                                                                  <em class="fa fa-save" aria-hidden="true"></em>
                                                                </button>
                                                              </div>
                                                          </div>
                                                      </form>

                                                      <div class="row mt-1">
                                                        <div class="col-6">
                                                          Corrections Needed
                                                        </div>
                                                        <div class="col-6"
                                                          [ngClass]="{'d-flex' : armorFormCorrections.get('armor_corrections').dirty && editArmorData?.correction_needed != armorFormCorrections.get('armor_corrections').value}">
                                                          <form name="armorFormCorrections" [formGroup]="armorFormCorrections">
                                                            <textarea class="form-control" formControlName="armor_corrections"
                                                              [ngClass]="!editArmorData ? 'disabled' : ''"></textarea>
                                                          </form>
                                                          <button title="Save" class="btn small-btn" (click)="updateCorrections()"
                                                            *ngIf="armorFormCorrections.get('armor_corrections').dirty && editArmorData?.correction_needed != armorFormCorrections.get('armor_corrections').value">
                                                            <em class="fa fa-save" aria-hidden="true"></em>
                                                          </button>
                                                        </div>
                                                      </div>

                                                      <div class="row mt-1">
                                                        <div class="col-6">
                                                          SIU BR Bill Objection Language
                                                        </div>
                                                        <div class="col-6"
                                                          [ngClass]="{'d-flex' : armorFormObjection.get('armor_objections').dirty && editArmorData?.armor_objections != armorFormObjection.get('armor_objections').value}">
                                                          <form name="armorFormObjection" [formGroup]="armorFormObjection">
                                                            <textarea class="form-control" formControlName="armor_objections"
                                                              [ngClass]="!editArmorData ? 'disabled' : ''"></textarea>
                                                          </form>
                                                          <button title="Save" class="btn small-btn" (click)="updateObjection()"
                                                            *ngIf="armorFormObjection.get('armor_objections').dirty && editArmorData?.armor_objections != armorFormObjection.get('armor_objections').value">
                                                            <em class="fa fa-save" aria-hidden="true"></em>
                                                          </button>
                                                        </div>
                                                      </div>

                                                      <div class="row mt-1">
                                                        <div class="col-6">
                                                          Adjuster POA
                                                        </div>
                                                        <div class="col-6"
                                                          [ngClass]="{'d-flex':armorFormAdjuster.get('armor_adjuster').dirty && editArmorData?.adjuster_poa != armorFormAdjuster.get('armor_adjuster').value}">
                                                          <form name="armorFormAdjuster" [formGroup]="armorFormAdjuster">
                                                            <textarea class="form-control" formControlName="armor_adjuster"
                                                              [ngClass]="!editArmorData ? 'disabled' : ''"></textarea>
                                                          </form>

                                                          <button title="Save" class="btn small-btn" (click)="updateAdjuster()"
                                                            *ngIf="armorFormAdjuster.get('armor_adjuster').dirty && editArmorData?.adjuster_poa != armorFormAdjuster.get('armor_adjuster').value">
                                                            <em class="fa fa-save" aria-hidden="true"></em>
                                                          </button>
                                                        </div>
                                                      </div>
                                                  </div>
                                                </ng-container>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div id="financialinterests" class="mb-1">
                                <!-- [cdkDragDisabled]="FinaStatus" -->
                                <div class="accordian-tab card-header spear-table-header-background title-space">
                                    <h6>Financial Interests</h6>
                                    <div class="d-flex">
                                        <button class="btn viewBtn spear-button-background text-white mr-2"
                                            (click)="openAddAssociationModal('Financial')">Add</button>
                                        <button class="btn "><em (click)="togglePanel('Fina')" class="fa fa-sort-desc"
                                                [ngClass]="FinaStatus ? '' : 'antirotate'"></em></button>
                                    </div>
                                </div>
                                <div [ngClass]="FinaStatus ? 'collapsecard' : 'expandcard'">
                                    <div class="table-row">
                                        <div>
                                            <table class="table providerTable">
                                                <caption>Financial Interests</caption>
                                                <thead>
                                                    <tr>
                                                        <th class="border-0" scope="col">Name</th>
                                                        <th class="border-0" scope="col">Address</th>
                                                        <th class="border-0" scope="col">TIN</th>
                                                        <th class="border-0" scope="col">NPI</th>
                                                        <th class="border-0" scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="associations_financial.length > 0">
                                                    <tr class="bg-white" *ngFor="let row of associations_financial">
                                                        <td>
                                                            <a class="primary-text  font-weight-semibold"
                                                                href="/profile-report/{{row.spear_id}}/2"
                                                                target="_blank">{{row.name}}</a>
                                                        </td>
                                                        <td [innerHTML]="row.address"></td>
                                                        <td>{{row.tin}}</td>
                                                        <td>{{row.npi}}</td>
                                                        <td><button class="btn"
                                                                (click)="openDisassociate(disassociate, row.id, 'financial')">
                                                                <em class="fa fa-ban"></em></button></td>
                                                    </tr>
                                                </tbody>
                                                <tbody *ngIf="associations_financial.length < 1">
                                                    <tr class="bg-white">
                                                        <td colspan="6" class="text-center">Data not available</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="topreferring" class="mb-1">
                                <div class="accordian-tab card-header spear-table-header-background title-space">
                                    <h6>Top Referring Specialists</h6>
                                    <button class="btn "><em (click)="togglePanel('TopReferring')"
                                            class="fa fa-sort-desc"
                                            [ngClass]="TopReferringStatus ? '' : 'antirotate'"></em></button>
                                </div>
                                <div [ngClass]="TopReferringStatus ? 'collapsecard' : 'expandcard'">
                                    <div class="table-row">
                                        <table class="table providerTable">
                                            <caption>Top Referring</caption>
                                            <thead>
                                                <tr>
                                                    <th class="border-0" scope="col">Specialists</th>
                                                    <th class="border-0" scope="col">Speciality</th>
                                                    <th class="border-0" scope="col">TIN</th>
                                                    <th class="border-0" scope="col">NPI</th>
                                                    <th class="border-0" scope="col">Top Addresses</th>
                                                    <th class="border-0" scope="col">Records</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="false">
                                                <tr class="bg-white">
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="true">
                                                <tr class="bg-white">
                                                    <td colspan="6" class="text-center">Data not available</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div id="associates" class="mb-1">
                                <div class="accordian-tab card-header spear-table-header-background title-space">
                                    <h6>Individuals/Associates</h6>
                                    <div class="d-flex">
                                        <button class="btn viewBtn spear-button-background text-white mr-2"
                                            (click)="openAddAssociationModal('Other')">Add</button>
                                        <button class="btn" (click)="togglePanel('Associate')"
                                            [ngClass]="AssociateStatus ? '' : 'antirotate'"><em
                                                class="fa fa-sort-desc"></em></button>
                                    </div>

                                </div>
                                <div [ngClass]="AssociateStatus ? 'collapsecard' : 'expandcard'">
                                    <div class="table-row">
                                        <table class="table providerTable">
                                            <caption>Associate</caption>
                                            <thead>
                                                <tr>
                                                    <th class="border-0" scope="col">Name</th>
                                                    <th class="border-0" scope="col">Address</th>
                                                    <th class="border-0" scope="col">Note</th>
                                                    <th class="border-0" scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="associations_other.length > 0">
                                                <tr class="bg-white" *ngFor="let row of associations_other">
                                                    <td>
                                                        <a class="primary-text  font-weight-semibold"
                                                            href="/profile-report/{{row.spear_id}}/2"
                                                            target="_blank">{{row.name}}</a>
                                                    </td>
                                                    <td>{{row.address}}</td>
                                                    <td>{{row.note}}</td>
                                                    <td><button class="btn"
                                                            (click)="openDisassociate(disassociate, row.id, 'other')">
                                                            <em class="fa fa-ban"></em></button></td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="associations_other.length < 1">
                                                <tr class="bg-white">
                                                    <td colspan="4" class="text-center">Data not available</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="activeTab == 'Notes'">
                <div class="col-12 col-md-6">
                    <div class="notes">
                        <span class="title">Notes details awaiting.</span>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="activeTab == 'Documents'">
                <div class="col-12 col-md-6">
                    <div id="documents">
                        <div class="accordian-tab card-header spear-table-header-background title-space">
                            <h6>Documents</h6>
                            <div class="d-flex">
                                <button (click)="openUploadDocument(uploadDocument)"
                                    class="btn viewBtn spear-button-background text-white mr-2">Add</button>
                                <button class="btn">
                                    <em (click)="togglePanel('Documents')" class="fa fa-sort-desc"
                                        [ngClass]="DocumentsStatus ? '' : 'antirotate'"></em>
                                </button>
                            </div>
                        </div>
                        <div [ngClass]="DocumentsStatus ? 'collapsecard' : 'expandcard'">
                            <div class="table-row">
                                <table class="table providerTable">
                                    <caption>
                                        Documents
                                    </caption>
                                    <thead>
                                        <tr>
                                            <th class="border-0" scope="col">File Name</th>
                                            <th class="border-0" scope="col">Uploaded On</th>
                                            <th class="border-0" scope="col"></th>
                                            <th class="border-0" scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="isDocuments">
                                        <tr class="bg-white" *ngFor="let file of Document">
                                            <td class="primary-text font-weight-semibold">
                                                {{ file.filename }}
                                            </td>
                                            <td>{{ file.uploaddt | date:'MM/dd/yyyy'  }}{{ file.id }}</td>
                                            <td>
                                                <button (click)="download(file.id)">
                                                    <em class="fa fa-arrow-circle-down" aria-hidden="true"></em>
                                                </button>
                                            </td>
                                            <td>
                                                <button (click)="deleteDocument(file.id, spearIDS)">
                                                    <em class="fa fa-trash" aria-hidden="true"></em>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="!isDocuments">
                                        <tr class="bg-white">
                                            <td colspan="4" class="text-center">
                                                Data not available
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>


    </div>
</div>
<ng-template #createArmor let-modal>
    <div class="modal-body">
        <p class="text-center">Please select the spear id you want to associate</p>
        <select class="form-control" id="add_armor_spear_id" [(ngModel)]="add_armor_spear_id">
            <option *ngFor="let opt of spearIDS" value="{{opt}}">{{opt}}</option>
        </select>
        <p>Please select provider from the search result list</p>
        <div class="d-flex flex-row align-items-center mb-2">
            <select class="form-control mr-4 mb-0" id="pay_code_list" [disabled]="!add_armor_spear_id">
                <option value="{{opt.po_code}}" *ngFor="let opt of po_codes">{{opt.po_code}}</option>
            </select>
            <button class="btn spear-button-background text-white" (click)="addArmor()" [disabled]="!add_armor_spear_id">
                Add
            </button>
        </div>
        <div class="text-center">
            <button class="btn spear-button-background text-white" (click)="closeAddArmor()">
                Close
            </button>
        </div>
    </div>
</ng-template>
<ng-template #deleteArmor let-modal>
    <div class="modal-body">
        <p class="text-center">Are you sure you want to delete this record?</p>
        <div class="text-center">
            <button class="btn spear-button-background text-white mr-4" (click)="removeArmor()">
                Yes
            </button>
            <button class="btn spear-button-background text-white" (click)="closeAddArmor()">
                No
            </button>
        </div>
    </div>
</ng-template>
<ng-template #uploadDocument let-modal>
    <div class="modal-body">
        <p class="text-center">Please select the spear id you want to associate</p>
        <select class="form-control" [(ngModel)]="selectedSpearID" (change)="onSpearIDChange($event.target.value)">
            <option *ngFor="let opt of spearIDS" value="{{opt}}">{{opt}}</option>
        </select>
        <div class="text-center">
            <div class="input-file">
                <input type="file" id="fileupload-documents" accept=".doc,.docx,.pdf"
                    (change)="onFileSelectedDocument($event)" />
            </div>
            <label type="button" for="fileupload-documents"
                class="btn spear-button-background text-white mr-2 mt-1">Select File</label>
            <button class="btn spear-button-background text-white" (click)="closeAddArmor()">
                Cancel
            </button>
        </div>
    </div>
</ng-template>
<ng-template #disassociate let-modal>
    <div class="modal-body">
        <p class="text-center">Are you sure you want to disassociate?</p>
        <div class="text-center">
            <button class="btn spear-button-background text-white mr-4" (click)="disAssociate()">
                Yes
            </button>
            <button class="btn spear-button-background text-white" (click)="closeDisassociate()">
                No
            </button>
        </div>
    </div>
</ng-template>
