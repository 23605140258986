import { Component, Input, Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AppUtils } from './../../utilities/app.utils';
import { ToastrService } from 'ngx-toastr';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Template } from 'src/app/models/provider-filter/template';
import { UserTemplatesResponse } from 'src/app/models/provider-filter/userTemplatesResponse';
import { ProviderFilterModel } from 'src/app/models/provider/provider.filter.model';
import { ProviderModel } from 'src/app/models/provider/provider.model';
import { ManageTemplatesService } from 'src/app/services/manage.template.service';
import { ProviderService } from 'src/app/services/provider.service';
import { RightSidebarComponent } from '../right-sidebar/right-sidebar.component';
import { FilterTemplatesComponent } from './filter-templates/filter-templates.component';
import { Response } from './../../models/responseModel';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/services/modal.service';
import { ProviderListHeadersEnum, SearchCriteria } from 'src/app/utilities/enums';
import { Hit, ProviderSearchReponseMOdel } from 'src/app/models/provider/provider.melie.search.reponse.model';
import { Router, ActivatedRoute } from '@angular/router';
import { JoyrideService } from 'ngx-joyride';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


export enum ProviderType {
  All = 1,
  Flagged = 2,
  Unflagged = 3
}

export type SortColumn = keyof ProviderSearchReponseMOdel | '';
export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = { 'asc': 'desc', 'desc': '', '': 'asc' };

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()'
  }
})

export class NgbdSortableHeader {

  @Input() sortable: SortColumn = '';
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.css']
})

export class ProvidersComponent implements OnInit, OnDestroy {


  @Output()
  resetSearchbar: EventEmitter<any> = new EventEmitter();

  delayOver: boolean = true;
  providers: Array<Hit>;
  tableSate: ProviderFilterModel;
  rowCount: number;
  page: number = 1;
  pageSize: number;
  pageIndex :number;
  providerSize: number = 1;
  maxSize : number = 5;
  searchText: string;
  searchCriteria: string[];
  sortColumn: string;
  isDescending: boolean;
  allRecordsFilter: boolean;
  omitEmptyNameFilter: boolean;
  duplicateNameFilter: boolean;
  newRecordFilter: boolean;
  providerType: ProviderType;
  providerTypeTabs: Array<{ id: number, title: string }> =
    [
      { id: ProviderType.All, title: "All Providers" }
    ];
  show = false;
  isDisabled = true;
  Bulkaction: any = ['Delete', 'Merge', 'Affiliate', 'Exclude'];
  todayDate: Date = new Date();
  collectionSize = 100;
  provider: ProviderModel[];
  setClasses = new Set();

  classSet = new Set();
  selectAll = false;
  selectedProviders: string[];
  addressInformation: any = [];
  hideme: Array<{ isOpen: boolean, isLoaded: boolean }> = [];
  index: any;
  walkthroughStatus: boolean;
  selectedCriteria: string[];

  apiUrl = environment.fireStoreApiUrl + '/api/v1/preference/add/';
  getApiUrl = environment.fireStoreApiUrl + '/api/v1/preference/';
  userPref: any = {};
  userLeft: string[] = [];
  userRight: string[] = [];
  showGuidance: boolean;

  sortData: Array<Hit>;

  providerEventSubscription;
  @BlockUI('provider-screen') blockUI: NgBlockUI;

  userTemplates: UserTemplatesResponse;
  response: Response;

  @ViewChild(FilterTemplatesComponent) private manageTeplate: FilterTemplatesComponent;
  @ViewChild(RightSidebarComponent) private rightSideBar: RightSidebarComponent;


  constructor(private modalService: ModalService,
    private route: Router,
    private actroute: ActivatedRoute,
    private providerService: ProviderService,
    private manageTemplatesService: ManageTemplatesService,
    private appUtils: AppUtils,
    private toastr: ToastrService,
    private eleRef: ElementRef,
    private http: HttpClient,
    private readonly joyrideService: JoyrideService) {
    this.providers = new Array<Hit>();
    this.pageSize = 650;
    this.page = 1;
    this.searchText = '';
    this.searchCriteria = [];
    this.isDescending = false;
    this.omitEmptyNameFilter = false;
    this.duplicateNameFilter = false;
    this.newRecordFilter = false;
    this.providerType = ProviderType.All;
    this.pageIndex = 0;

    this.getTemplate();

  }


  ngOnInit(): void {
    this.selectedCriteria = [SearchCriteria.Name.toString()];
    this.providerEventSubscription = this.providerService.listenToSearchRecords().
      subscribe((data) => {
        this.resetTableState();
        this.searchText = data.searchedText;
        this.searchCriteria = data.searchedCriteria;
        this.getProviders();
      })

    this.actroute.params.subscribe(params => {
      let getnpi = params['npi'];
      if (getnpi != null) {
        this.providerService.notifyToSearchRecords(getnpi, this.selectedCriteria);
      }
    });

    this.selectedProviders = [];
    this.provider = [];
    this.getProviders();
    this.userTemplates = new UserTemplatesResponse();
    this.response = new Response();
    this.providerService.ShowSearchBar = true;

    // save joyride in session for only one time walkthrough
    if (sessionStorage.getItem("joyride")) {
      this.walkthroughStatus = true;
    } else {

    }
  }

  ngOnDestroy(): void {
    this.providerEventSubscription.unsubscribe();
    this.providerService.ShowSearchBar = false;
  }

  get providerHeaderListEnum(): typeof ProviderListHeadersEnum {
    return ProviderListHeadersEnum;
  }

  getProviders(): void {
    this.blockUI.start();
    this.selectedProviders = [];
    this.hideme = [];
    this.providerService
      .getProvidersFromFireStore2(this.pageIndex, 5).subscribe((response) => {
        response.details.results.forEach(adrs => {
          if (
            adrs.provider_street_address_1 == null &&
            adrs.provider_city == null &&
            adrs.provider_state == null &&
            adrs.provider_zip == null
          ) {
            adrs.provider_full_address = '&#8212;';
          }
          else {
            adrs.provider_full_address = adrs.provider_street_address_1 + (adrs.provider_street_address_1 && adrs.provider_city ? ',<br>' : '') + adrs.provider_city + (adrs.provider_city && adrs.provider_state ? ', ' : '') + adrs.provider_state + (adrs.provider_state && adrs.provider_zip ? ' ' : '') + adrs.provider_zip;
          }

        });
        this.providers = response.details.results;
        this.pageSize = response.details.total;
        this.sortData = this.providers;

        this.providerSize = this.pageSize - this.pageSize % 5;
                              
        this.blockUI.stop();
        setTimeout(() => {
          this.beginWalkthrough();
        }, 500);
      }
      // , error => {
      //   this.appUtils.ProcessErrorResponse(this.toastr, error);
      //   this.blockUI.stop();
      // }
      );
    //earlier was taking data as per data model response: ProviderSearchReponseMOdel
  }

  refreshProviders(): void {
    this.getProviders();
  }

  applyAllRecordsFilter(): void {
    this.resetTableState();
    this.providerService.notifyToResetSearchBar();
    this.allRecordsFilter = true;
    this.getProviders();
  }

  applyOmitEmptyNameFilter(): void {
    this.resetTableState();
    this.omitEmptyNameFilter = true;
    this.getProviders();
  }

  applyDuplicateNameFilter(): void {
    this.resetTableState();
    this.duplicateNameFilter = true;
    this.isDescending = false;
    this.getProviders();
  }

  applyNewRecordFilter(): void {
    this.resetTableState();
    this.newRecordFilter = true;
    this.getProviders();
  }

  providerTypeFilter(id: number): void {
    this.resetTableState();
    this.providerType = id as ProviderType;
    this.getProviders();
  }

  beforeChange($event: NgbTabChangeEvent): void {
    let nextId: number = +$event.nextId.split('_')[1];
    this.resetTableState();
    this.providerTypeFilter(nextId);
  }

  resetTableState(): void {
    this.pageSize = 5;
    this.page = 1;
    this.searchText = "";
    this.searchCriteria = [];
    this.isDescending = false;
    this.omitEmptyNameFilter = false;
    this.duplicateNameFilter = false;
    this.newRecordFilter = false;
    this.allRecordsFilter = false;
  }
  loadAddressInfo(idx: any) {
    if (this.hideme[idx]) {
      this.hideme[idx].isOpen = !this.hideme[idx].isOpen;
      return;
    }
    this.hideme[idx] = { isOpen: true, isLoaded: true };
    this.index = idx;
  }

  applyTemplate(templateId: number): void {
    let selectedTempalte = this.userTemplates.responseData.find(x => x.templateId == templateId);
    if (selectedTempalte == null) {
      selectedTempalte = new Template();
      selectedTempalte.templateName = 'Default View';
      selectedTempalte.templateId = 0;
      selectedTempalte.fields = this.manageTemplatesService.Fields.map(x => x.name);
    }
    this.rightSideBar.applyTemplate(selectedTempalte);
  }

  // tslint:disable-next-line: typedef
  closeSidebar() {
    this.setClasses.delete('open');
  }

  applyMerge() {
    if (this.selectedProviders.length !== 2) {
      this.appUtils.ProcessCustomResponse(this.toastr, true, 'Please select two providers');
      return;
    }

    this.providerService.setMergeIds(this.selectedProviders);

    this.route.navigate(['/providers/manual-merge']);

  }


  openSidebar(): void {
    this.rightSideBar.addTemplate.fields = this.rightSideBar.manageFields.allFields.filter(x => x.selected).map(x => x.field);
    this.rightSideBar.manage();
    this.setClasses.add('open');
  }


  // tslint:disable-next-line: typedef
  CheckAllOptions(checked: boolean): void {
    this.providers.forEach(x => x.isSelected = checked);
    this.selectedProviders = [];
    this.providers.forEach(provider => {
      this.selectedProviders.push(provider.spear_id);
    });
    if (!checked) {
      this.selectedProviders = [];
    }
  }

  Checked(providerId: string, event): void {


    if (this.selectedProviders.includes(providerId)) {
      this.selectedProviders.splice(this.selectedProviders.findIndex(x => x === providerId), 1);
    }
    else {
      if (this.selectedProviders.length < 2) {
        this.selectedProviders.push(providerId);
      }
      else {
        event.target.checked = false;
        this.modalService.mergeAlert();
      }
    }

  }

  tempChecked() {
    this.show = !this.show;
  }
  // tslint:disable-next-line: typedef
  select() {
    this.isDisabled = !this.isDisabled;
  }

  openTemplate = () => {
    this.updateTemplateListAndManagelist();
    if (this.userTemplates.responseData.length === 0) {
      this.appUtils.ProcessCustomResponse(this.toastr, true, 'No saved template available');
      return null;
    }
    this.manageTemplatesService.Fields = this.response.responseData;
    this.manageTeplate.getTemplate();
    this.getTemplateAgain();
    this.classSet.add('show');
  }

  getTemplate = () => {
    this.manageTemplatesService.getTemplates().subscribe(data => {
      this.userTemplates = data;
      if (!this.userTemplates.success) {
        this.appUtils.ProcessCustomResponse(this.toastr, !this.userTemplates.success, this.userTemplates.message);
      }
      this.getFields();
    }
      // error => {
      //   this.appUtils.ProcessErrorResponse(this.toastr, error);
      // }
      );
  }

  getTemplateAgain = () => {
    this.manageTemplatesService.getTemplates().subscribe(data => {
      this.userTemplates = data;
      this.manageTeplate.manage(this.userTemplates.responseData[0]);
      if (!this.userTemplates.success) {
        this.appUtils.ProcessCustomResponse(this.toastr, !this.userTemplates.success, this.userTemplates.message);
      }
    }
      // error => {
      //   this.appUtils.ProcessErrorResponse(this.toastr, error);
      // }
      );
  }

  getFields = (): void => {
    this.manageTemplatesService.getFields()
      .subscribe(data => {
        this.response = data;
        if (!data.success) {
          this.appUtils.ProcessCustomResponse(this.toastr, data.success, data.message);
        }
        this.manageTemplatesService.Fields = this.response.responseData;
        this.setSelectedTemplate();
      }
        // error => {
        //   this.appUtils.ProcessErrorResponse(this.toastr, error);
        // }
        );
  }

  templateSelected = (template: Template) => {
    this.manageTemplatesService.SelectedTemplate = template;
    this.rightSideBar.manage();
  }

  closeTemplate = (): void => {
    this.classSet.delete('show');
  }

  copyToClipboard = (item) => {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
      this.appUtils.ProcessCustomResponse(this.toastr, false, 'Copied to clipboard successfully!');
    });
    document.execCommand('copy');
  }
  updateTemplateListAndManagelist = () => {
    this.manageTeplate.getTemplate();
    this.getTemplateAgain();
  }

  setSelectedTemplate = () => {
    this.manageTemplatesService.getTemplates().subscribe(data => {
      this.userTemplates = data;
      if (this.userTemplates.responseData.length == 0) {
        this.manageTemplatesService.SelectedTemplate = new Template();
      }

      let curTempleteName = this.manageTemplatesService?.selectedTemplate?.templateName;
      let curTemplate = this.userTemplates.responseData.find(x => x.templateName === curTempleteName);
      let selectedTempalte = new Template();
      if (curTemplate != null) {
        selectedTempalte.templateName = curTemplate.templateName;
        selectedTempalte.templateId = curTemplate.templateId;
        selectedTempalte.fields = curTemplate.fields;
      }
      else {
        selectedTempalte.templateName = 'Default View';
        selectedTempalte.templateId = 0;
        selectedTempalte.fields = this.manageTemplatesService.Fields.map(x => x.name);
      }

      this.rightSideBar.applyTemplate(selectedTempalte);

      if (!this.userTemplates.success) {
        this.appUtils.ProcessCustomResponse(this.toastr, !this.userTemplates.success, this.userTemplates.message);
      }
    }
      // error => {
      //   this.appUtils.ProcessErrorResponse(this.toastr, error);
      // }
      );
  }

  isFieldVisible(key: string): boolean {
    if (this.rightSideBar && this.rightSideBar.manageFields &&
      this.rightSideBar.manageFields.allFields.length > 0 &&
      this.rightSideBar.manageFields.allFields.find(x => x.field == key).selected) {
      return true;
    }
    return false;
  }

  openModalAffiliate(): void {
    this.modalService.openModalForAffiliate();
  }
  openModalMerge(): void {
    this.modalService.openModalForMerge();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  isAllChecked = (): boolean => {
    if (!this.providers || this.providers.length === 0) {
      return false;
    }
    return this.providers.every(all => all.isSelected);
  }
  isAnyChecked = (): boolean => {
    if (this.selectedProviders.length === 0) {
      return false;
    }
    else {
      return true;
    }
  }



  beginWalkthrough(): void {
    this.http.get<any>(this.getApiUrl).subscribe(data => {
      this.userLeft = data.leftDrag;
      this.userRight = data.rightDrag;
      this.showGuidance = data.stopmergeguide;
      if (this.showGuidance === false) {
        if (!this.walkthroughStatus) {
          this.joyrideService.startTour(
            { steps: ['firstStep', 'secondStep'] } // Your steps order
          );
        }
      }
    });

  }
  onDone(): void {
    this.delayOver = false;
    this.walkthroughStatus = false;
    sessionStorage.setItem("joyride", "true")
  }

  stopGuidance() {
    this.userPref.uerName = "Super Admin";
    this.userPref.leftDrag = this.userLeft;
    this.userPref.rightDrag = this.userRight;
    //this.userPref.id = "User1";
    this.userPref.stopmergeguide = true;
    this.http.post(this.apiUrl, this.userPref).subscribe();
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });


    if (direction === '' || column === '') {
      this.providers = this.sortData;
    } else {
      this.providers = [...this.sortData].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }

  onPageChange(event: any){
    this.pageIndex = (event - 1);
    this.getProviders();
  }

}

