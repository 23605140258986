<!-- Modal -->
<div *blockUI="'show-loader'">
    <div class="modal-header">
        <h4 class="modal-title">Add {{entityAssociationType}} Association</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body add-financial-association">
        <div class="flex flex-col w-full">
            <form class="new-case-form" name="form" [formGroup]="form" (keydown)="keyDownFunction($event)">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6 pr-4">
                                <div class="row">
                                    <div class="col-md-4">Name</div>
                                    <div class="col-md-8 d-flex"><input type="text"
                                            formControlName="provider_individual" class="form-control"
                                            placeholder="Enter Name">
                                        <button type="button"
                                            class="btn bg-voilet spear-button-background text-white p-2 ml-2"
                                            (click)="onFieldReset('provider_individual')"><em
                                                class="fa fas fa-refresh"></em></button>
                                    </div>
                                </div>
                                <div class="row mt-1" *ngIf="entityAssociationType == 'Financial'">
                                    <div class="col-md-4">Phone</div>
                                    <div class="col-md-8">
                                        <div class="d-flex">
                                            <input class="form-control" formControlName="phone" type="text"
                                                maxlength="14" placeholder="(xxx) xxx-xxxx" />
                                            <button type="button"
                                                class="btn bg-voilet spear-button-background text-white p-2 ml-2"
                                                (click)="onFieldReset('phone')"><em
                                                    class="fa fas fa-refresh"></em></button>
                                        </div>
                                        <div *ngIf="form.get('phone').hasError('pattern')">
                                            Please enter a valid phone number
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-1" *ngIf="entityAssociationType == 'Financial'">
                                    <div class="col-md-4">NPI</div>
                                    <div class="col-md-8 d-flex"><input type="text" appAcceptNumber
                                            appCustomMaxLength max=10 formControlName="npi"
                                            class="form-control" placeholder="Enter Provider NPI">
                                        <button type="button"
                                            class="btn bg-voilet spear-button-background text-white p-2 ml-2"
                                            (click)="onFieldReset('npi')"><em
                                                class="fa fas fa-refresh"></em></button>
                                    </div>
                                </div>
                                <div class="row mt-1" *ngIf="entityAssociationType == 'Financial'">
                                    <div class="col-md-4">TIN</div>
                                    <div class="col-md-8 d-flex"><input type="text" appAcceptNumber
                                            appCustomMaxLength max=10 formControlName="tin"
                                            class="form-control" placeholder="Enter Provider TIN">
                                        <button type="button"
                                            class="btn bg-voilet spear-button-background text-white p-2 ml-2"
                                            (click)="onFieldReset('tin')"><em
                                                class="fa fas fa-refresh"></em></button>
                                    </div>
                                </div>

                                <div class="row mt-1" *ngIf="entityAssociationType == 'Other'">
                                  <div class="col-md-4">City</div>
                                  <div class="col-md-8 d-flex"><input type="text" formControlName="city"
                                          class="form-control" placeholder="Enter Provider City">
                                      <button type="button"
                                          class="btn bg-voilet spear-button-background text-white p-2 ml-2"
                                          (click)="onFieldReset('city')"><em
                                              class="fa fas fa-refresh"></em></button>
                                  </div>
                              </div>
                            </div>
                            <div class="col-md-6 pl-4">
                                <div class="row" *ngIf="entityAssociationType == 'Financial'">
                                    <div class="col-md-4">City</div>
                                    <div class="col-md-8 d-flex"><input type="text" formControlName="city"
                                            class="form-control" placeholder="Enter Provider City">
                                        <button type="button"
                                            class="btn bg-voilet spear-button-background text-white p-2 ml-2"
                                            (click)="onFieldReset('city')"><em
                                                class="fa fas fa-refresh"></em></button>
                                    </div>
                                </div>
                                <div class="row" [ngClass]="{'mt-1': entityAssociationType == 'Financial'}">
                                    <div class="col-md-4">Street Address</div>
                                    <div class="col-md-8 d-flex"><input type="text"
                                            formControlName="streetaddress" class="form-control"
                                            placeholder="Enter Provider Street Name">
                                        <button type="button"
                                            class="btn bg-voilet spear-button-background text-white p-2 ml-2"
                                            (click)="onFieldReset('streetaddress')"><em
                                                class="fa fas fa-refresh"></em></button>
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-md-4">State</div>
                                    <div class="col-md-8">
                                        <select class="form-control" formControlName="state">
                                            <option value="AL">Alabama</option>
                                            <option value="AK">Alaska</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="CA">California</option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">Connecticut</option>
                                            <option value="DE">Delaware</option>
                                            <option value="DC">District Of Columbia</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">Louisiana</option>
                                            <option value="ME">Maine</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">Massachusetts</option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">Minnesota</option>
                                            <option value="MS">Mississippi</option>
                                            <option value="MO">Missouri</option>
                                            <option value="MT">Montana</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NH">New Hampshire</option>
                                            <option value="NJ">New Jersey</option>
                                            <option value="NM">New Mexico</option>
                                            <option value="NY">New York</option>
                                            <option value="NC">North Carolina</option>
                                            <option value="ND">North Dakota</option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">Pennsylvania</option>
                                            <option value="RI">Rhode Island</option>
                                            <option value="SC">South Carolina</option>
                                            <option value="SD">South Dakota</option>
                                            <option value="TN">Tennessee</option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">Washington</option>
                                            <option value="WV">West Virginia</option>
                                            <option value="WI">Wisconsin</option>
                                            <option value="WY">Wyoming</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="row justify-content-end mt-1 mb-1">
                <div class="col-md-auto align-items-end">
                    <button class="btn bg-secondary btn-sm text-white  px-3 py-2 pr-3 pl-3"
                        (click)="onReset()"><em class="fa fas fa-refresh mr-3"></em>Reset</button>
                    <button
                        class="btn btn-sm spear-button-background text-white  ml-2 px-3 py-2 pr-3 pl-3"
                        (click)="onSubmit()"><em class="fa fas fa-search mr-3"></em> Search</button>
                </div>
            </div>

            <div class="row" *ngIf="isSearched">
                <div class="col-md-12">
                    <div class="card mb-0 border-0">
                        <div class="card-body">
                            <div class="container-fluid p-0" *blockUI="'manage-users-blockUI'">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="w-100">
                                            <div class="row mb-1 mb-xl-3 justify-content-between align-items-center">
                                                <div class="col-auto">
                                                    <h3 class="page-title">Search result list</h3>
                                                </div>
                                                <div>
                                                    <button disabled
                                                        (click)="doAssociate(selectedAssociates)"
                                                        class="btn btn-secondary-outline bg-white text-voilet p-2  mr-3  btn-sm btn-merge">Associate</button>
                                                </div>
                                            </div>
                                            <div class="table-row">
                                                <table
                                                    class="table providerTable spear-search-table-header-background table-row cnstr-record product-tbl">
                                                    <caption>Data Table</caption>
                                                    <thead>
                                                        <tr>
                                                            <th class="border-0" scope="col">
                                                            </th>
                                                            <th class="border-0" scope="col" sortable="provider_name_person"
                                                                (sort)="onSort($event)">Name</th>
                                                            <th class="border-0" scope="col"
                                                                sortable="provider_name_business" (sort)="onSort($event)">
                                                                Business</th>
                                                            <th class="border-0" scope="col"
                                                                sortable="provider_full_address" (sort)="onSort($event)">
                                                                Address</th>
                                                        </tr>
                                                    </thead>
                                                    <!-- for empty tr-->
                                                    <tr class="bg-white" *ngIf="!providers || providers.length === 0">
                                                        <td colspan="12" class="text-center">No Records Found</td>
                                                    </tr>
                                                    <tbody *ngFor="let provider of providers; index as i">
                                                        <!-- for filled tr -->
                                                        <tr class="bg-white">
                                                            <td class="primary-text font-weight-semibold">
                                                                <div
                                                                    class="custom-control custom-checkbox checkbox-custom align-items-center">
                                                                    <input type="checkbox"
                                                                        class="custom-control-input spear-custom-control-input"
                                                                        id="customCheck1_{{i}}"
                                                                        [(ngModel)]="provider.isSelected"
                                                                        [value]="provider.value"
                                                                        (change)="Checked(provider?.spear_id, provider?.entities, provider?.id, $event)">
                                                                    <label
                                                                        class="custom-control-label control-custom-label cursor-pointer"
                                                                        for="customCheck1_{{i}}"></label>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <span
                                                                    *ngIf="!provider.provider_name_person && !provider.official_name">No
                                                                    Name Available</span>
                                                                <a *ngIf="provider.provider_name_person || provider.official_name"
                                                                    href="/profile-report/{{provider.spear_id}}/1"
                                                                    target="_blank"
                                                                    [innerHTML]="(provider.provider_name_person || provider.official_name)"
                                                                    class="cursor-pointer primary-text font-weight-semibold"></a>
                                                            </td>
                                                            <td>
                                                                <span *ngIf="!provider.provider_name_business">No Name
                                                                    Available</span>
                                                                <a *ngIf="provider.provider_name_business"
                                                                    href="/profile-report/{{provider.spear_id}}/2"
                                                                    target="_blank"
                                                                    [innerHTML]="provider.provider_name_business"
                                                                    class="cursor-pointer primary-text font-weight-semibold"></a>
                                                            </td>
                                                            <td class="table-max-width"
                                                                [innerHTML]="(provider.provider_full_address ? provider.provider_full_address : '&#8212;')">
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div class="px-0 py-0 paginationWrapper"
                                                    *ngIf="!(!providers || providers.length === 0)">
                                                    <ngb-pagination [(collectionSize)]="providerSize" [(page)]="page"
                                                        [pageSize]="limit" [maxSize]="10" [boundaryLinks]="true"
                                                        (pageChange)="onPageChange($event)">
                                                    </ngb-pagination>
                                                    <div class="selected d-flex align-items-center">
                                                        <select (change)="onSizeChange(limit)"
                                                            class="custom-select table-select" [(ngModel)]="limit">
                                                            <option [ngValue]="20">20</option>
                                                            <option [ngValue]="50">50</option>
                                                            <option [ngValue]="75">75</option>
                                                            <option [ngValue]="100">100</option>
                                                        </select>
                                                        <p class="mb-0 ml-2">Showing {{ ((pageIndex * limit) - limit) + 1 }}
                                                            -
                                                            {{ pageIndex * limit > providerSize ? providerSize : pageIndex *
                                                            limit }} of {{ providerSize }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
