<div class="container-fluid p-0">
    <div class="row">
        <div class="col-md-12">
            <div class="w-100 pt-4">
                <div class="row mb-2 mb-xl-3 justify-content-between align-items-start">
                    <div class="col-auto d-sm-block">
                        <h3 class="page-title">Reports</h3>
                        <!-- <p>Get any report exported into one file.</p> -->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="w-100 pt-4">
                        <div class="card flex-fill border-0 mb-2">
                            <div class="card-header border-0 card-padding rounded px-4 pt-4 pb-0 bg-white">
                                <h4 class="card-header-title">Export & Searches</h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-3" *ngFor="let button of buttons">
                                        <button class="btn btn-control border d-flex justify-content-between align-items-center w-100 mb-4" (click)="showPhase(button)" [class.active]= "activeButton === button">
                                            <span class="btn-text">{{button.name}}</span>
                                        </button>
                                        <span class="position-absolute cursor-pointer infoIcon" (click)="openModalx(true)"><em class="fa fa-info-circle"></em></span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-9">
                    <div class="w-100 pt-4">
                        <div class="card flex-fill border-0">
                            <div class="card-header border-0 card-padding rounded px-4 pt-4 pb-0 bg-white">
                                <h4 class="card-header-title">Shield</h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4">
                                        <button class="btn btn-control border d-flex justify-content-between align-items-center w-100 mb-4" disabled="disabled">
                                            <span class="btn-text">Export Providers with Empty Names</span>
                                            <span><em class="fa fa-info-circle"></em></span>
                                        </button>
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-control border d-flex justify-content-between align-items-center w-100 mb-4" disabled="disabled">
                                            <span class="btn-text">Export Providers with Empty Names</span>
                                            <span><em class="fa fa-info-circle"></em></span>
                                        </button>
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-control border d-flex justify-content-between align-items-center w-100 mb-4" disabled="disabled">
                                            <span class="btn-text">Export NPIs by Address</span>
                                            <span><em class="fa fa-info-circle"></em></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="w-100 pt-4">
                        <div class="card flex-fill border-0">
                            <div class="card-header border-0 card-padding rounded px-4 pt-4 pb-0 bg-white">
                                <h4 class="card-header-title">Analysis</h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <button class="btn btn-control border d-flex justify-content-between align-items-center w-100 mb-4">
                                            <span class="btn-text">Reason Code History List</span>
                                            <span ><em class="fa fa-info-circle"></em></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--  info Sidebar  Start Here-->

<div class="reportSidebar bg-white p-4" [ngClass]="show">
    <div class="reportSidebar-header border-bottom mb-3">
        <button type="button" class="btn d-block ml-auto btnclose text-gray rounded-circle p-0 text-white" (click)="hideClass()">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="reportSidebar-title" id="modal-basic-title border-0">
            Export NPIs by Address
        </h4>
    </div>
    <div class="reportSidebar-body py-3">
        <p class="mb-4">User can enter search criteria into one or more of the following fields:</p>
        <p>Street</p>
        <p>City</p>
        <p>State</p>
        <p class="mb-4">Zip</p>
        <p class="mb-4">Searches address table for matching records.</p>
        <p class="mb-4">Exports excel doc with the following columns:</p>
        <p>Address Type</p>
        <p>Provider Name</p>
        <p>Street 1</p>
        <p>Street 2</p>
        <p>City</p>
        <p>State</p>
        <p>Zip</p>
        <p>Source Type</p>
        <p>Source</p>
    </div>
</div>


<!--  info Sidebar  End Here-->

<!-- <div class="container-fluid p-0">
    <div class="row">
        <div class="col-md-12">
            <div class="w-100 pt-4">
                <div class="row mb-2 mb-xl-3 justify-content-between align-items-start">
                    <div class="col-auto w-100 d-sm-block">
                        <p class="cursor-pointer text-light-voilet mb-4"><em class="fa fa-chevron-left mr-3"></em>Back to List</p>
                    </div>
                    <div class="col-auto d-sm-block">
                        <h3 class="page-title">Revision Code History</h3>
                        <p>Here is the all info about the above ID. </p>
                    </div>
                    <div class="col-auto btnGrp">
                        <div ngbDropdown placement="bottom-right" class="d-inline-block">
                            <button class="btn btn-secondary-outline bg-white text-voilet px-3 py-2 rounded-0 no-dropdown-toggle" id="dropdownBasic" ngbDropdownToggle><em class="fa fa-file-archive-o mr-2"></em>Export</button>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic" class="dropDown-position px-3 btn-font">
                                <button ngbDropdownItem class="px-2">PDF</button>
                                <div class="dropdown-divider"></div>
                                <button ngbDropdownItem class="px-2">CSV</button>
                                <div class="dropdown-divider"></div>
                                <button ngbDropdownItem class="px-2">XLS</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="w-100 pt-4">
                        <div class="table-row">
                            <table class="table providerTable">
                                <thead>
                                    <tr>
                                        <th class="border-0" scope="col">
                                            <div class="custom-control custom-checkbox checkbox-custom sorting">
                                                <input type="checkbox" class="custom-control-input" id="customCheck">
                                                <label class="custom-control-label control-custom-label" for="customCheck">Provider ID</label>
                                            </div>
                                        </th>
                                        <th class="border-0 sorting" scope="col">Provider</th>
                                        <th class="border-0 sorting" scope="col">Type</th>
                                        <th class="border-0 sorting" scope="col">Code</th>
                                        <th class="border-0 sorting" scope="col">EIN</th>
                                        <th class="border-0 sorting" scope="col">Source</th>
                                        <th class="border-0 sorting" scope="col">Created</th>
                                        <th class="border-0 sorting" scope="col">Start</th>
                                        <th class="border-0 sorting" scope="col">End</th>
                                        <th class="border-0 sorting" scope="col">Notes</th>
                                    </tr>
                                </thead>
                                    <tbody>
                                        <tr class="bg-white" *ngFor="let provider of providers; index as i">
                                            <td class="primary-text font-weight-semibold">
                                                <div class="custom-control custom-checkbox checkbox-custom d-flex align-items-center">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck1_{{i}}">
                                                    <label class="custom-control-label control-custom-label cursor-pointer" for="customCheck1_{{i}}">{{ provider.id | number }} </label>
                                                    <img class="ml-2" src="assets/images/icon-file-copy.png" alt="">
                                                </div>
                                            </td>
                                            <td>{{ provider.name }}</td>
                                            <td>{{ provider.type }}</td>
                                            <td>{{ provider.state }}</td>
                                            <td>{{ provider.npi | number }}</td>
                                            <td>{{ provider.source }}</td>
                                            <td>{{ todayDate | date:'MM/dd/yyyy' }}</td>
                                            <td>{{ todayDate | date:'MM/dd/yyyy' }}</td>
                                            <td>{{ todayDate | date:'MM/dd/yyyy' }}</td>
                                            <td>{{ provider.notes }}</td>
                                        </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-between px-0 py-2 paginationWrapper">
                                <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshProviders()">
                                </ngb-pagination>
                                <div class="selected d-flex align-items-center">
                                    <select class="custom-select table-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshProviders()">
                                        <option [ngValue]="2">2 </option>
                                        <option [ngValue]="4">4 </option>
                                        <option [ngValue]="6">6 </option>
                                        <option [ngValue]="10">10 </option>
                                    </select>
                                    <p class="mb-0 ml-2">Showing 1-10 of 350</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
