import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild, Pipe, PipeTransform } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import fromCDN from "from-cdn";
import { UnmergeModalComponent } from './../shared/Modals/unmerge/unmerge.component';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-unmerge',
  templateUrl: './unmerge.component.html',
  styleUrls: ['./unmerge.component.css']
})
export class UnmergeComponent implements OnInit {
  @ViewChild("widget") container: ElementRef;

  ready: Promise<any>;
  repaintTree: boolean;
  treeLoader: boolean = false;
  notAvailable: boolean = false;
  tree: any[] = [];
  searchItem: any[] = [];
  searchIndex = 0;
  searchText: string;
  firstSearch: boolean = true;
  prevEnabled: boolean = false;
  nextEnabled: boolean = false;
  navOn: boolean = false;
  unmergeFirstChild: any[] = [];
  unmergeSecondChild: any[] = [];
  showUnmergeDetail: boolean = false;
  selectedForMerge: string;
  unmergeData: any = {};
  apiUrl = environment.fireStoreApiUrl;


  constructor(
    private http: HttpClient,
    private ngbModal: NgbModal,
    private toastr: ToastrService
  ) {
    this.ready = fromCDN([
      "//cdn.dhtmlx.com/diagram/2.1/diagram.js",
      "//cdn.dhtmlx.com/diagram/2.1/diagram.css"
    ]);
  }

  ngOnInit(): void { }
  searchProviders() {

    this.tree = [];
    this.container.nativeElement.innerHTML = "";
    this.repaintTree = false;
    this.treeLoader = true;
    this.showUnmergeDetail = false;
    if (this.searchItem.length < 1) {
      this.searchItem.push(this.searchText);
    }
    else {
      if (this.searchItem[this.searchItem.length - 1] != this.searchText) {
        this.searchItem.push(this.searchText);
      }
    }
    if (this.searchItem.length > 1) {
      this.navOn = true;
      this.prevEnabled = true;
      this.searchIndex++;
    }

    this.http.get<any>( this.apiUrl + '/unmerge/tree/' + this.searchText).subscribe((data: any) => {

      for (let i = 0; i <= data.unmerge_tree.length - 1; i++) {
        if (data.unmerge_tree[i].parent) {
          this.tree.push(
            {
              id: data.unmerge_tree[i].id,
              title: data.unmerge_tree[i].title,
              text: data.unmerge_tree[i].text,
              parent: data.unmerge_tree[i].parent
            }
          );
        }
        else {
          this.tree.push(
            {
              id: data.unmerge_tree[i].id,
              title: data.unmerge_tree[i].title,
              text: data.unmerge_tree[i].text
            }
          );
        }

      }

      if (data.unmerge_tree.length < 1) {
        this.notAvailable = true;
      }
      else {
        this.notAvailable = false;
      }

      var diagram = new dhx.Diagram(this.container.nativeElement, {
        type: "org",
        select: true,
        toolbar: [
          {
            id: "delete",
            content: "<i class='dxi dxi-link-variant-off'></i>"
          }
        ]
      });

      diagram.events.on("ShapeIconClick", (icon) => {
        const id = diagram.selection.getId();
        const item = diagram.selection.getItem();
        const unmergeItems = this.tree.filter((obj) => obj.parent == id).map((obj) => obj.text);
        this.selectedForMerge = item.text;

        if (item.title == "unmerge") {
          this.showUnmergeDetail = true;
          this.http.get<any>(this.apiUrl + '/profiles/' + unmergeItems[0]).subscribe(
            (data: any) => {
              //this.unmergeFirstChildId = unmergeItems[0];
              //this.unmergeFirstChildName = data.profile.provider_name_person;
              this.unmergeFirstChild = data.profile;
            },
            (error) => {
              //this.unmergeFirstChildId = unmergeItems[0];
              //this.unmergeFirstChildName = "Data not available";

            }
          );
          this.http.get<any>(this.apiUrl + '/profiles/' + unmergeItems[1]).subscribe(
            (data: any) => {
              // this.unmergeSecondChildId = unmergeItems[1];
              // this.unmergeSecondChildName = data.profile.provider_name_person;
              this.unmergeSecondChild = data.profile;
            },
            (error) => {
              // this.unmergeSecondChildId = unmergeItems[1]
              // this.unmergeSecondChildName = "Data not available";
            }
          );
        }
        else {
          const unmergeConfirmation = this.ngbModal.open(UnmergeModalComponent, {
            ariaDescribedBy: 'modal-basic-title', windowClass: 'groupModal', size: 'sm', centered: true
          });
          unmergeConfirmation.componentInstance.pid = item.text;
        }

        diagram.selection.remove(id);
        diagram.paint();
      });
      diagram.events.on("shapeDblClick", (id) => {
        this.tree = [];
        this.container.nativeElement.innerHTML = "";
        this.repaintTree = false;
        this.treeLoader = true;
        this.searchItem.push(diagram.data.getItem(id).text);
        this.searchText = diagram.data.getItem(id).text;
        this.searchItem.push(this.searchText);
        if (this.searchItem.length > 1) {
          this.navOn = true;
          this.prevEnabled = true;
          this.searchIndex++;
        }

        this.http.get(this.apiUrl + '/unmerge/tree/' + diagram.data.getItem(id).text).subscribe((data: any) => {

          for (let i = 0; i <= data.unmerge_tree.length - 1; i++) {
            if (data.unmerge_tree[i].parent) {
              this.tree.push(
                {
                  id: data.unmerge_tree[i].id,
                  title: data.unmerge_tree[i].title,
                  text: data.unmerge_tree[i].text,
                  parent: data.unmerge_tree[i].parent
                }
              );
            }
            else {
              this.tree.push(
                {
                  id: data.unmerge_tree[i].id,
                  title: data.unmerge_tree[i].title,
                  text: data.unmerge_tree[i].text
                }
              );
            }

          }
          if (data.unmerge_tree.length < 1) {
            this.notAvailable = true;
          } else {
            this.notAvailable = false;
          }
          var diagram = new dhx.Diagram(this.container.nativeElement, {
            type: "org",
            select: true,
            toolbar: [
              {
                id: "delete",
                content: "<i class='dxi dxi-link-variant-off'></i>"
              }
            ]
          });
          diagram.events.on("ShapeIconClick", (icon) => {
            const id = diagram.selection.getId();
            const item = diagram.selection.getItem();
            const unmergeItems = this.tree.filter((obj) => obj.parent == id).map((obj) => obj.text);
            this.selectedForMerge = item.text;
    
            if (item.title == "unmerge") {
              this.showUnmergeDetail = true;
              this.http.get<any>(this.apiUrl + '/profiles/' + unmergeItems[0]).subscribe(
                (data: any) => {
                  //this.unmergeFirstChildId = unmergeItems[0];
                  //this.unmergeFirstChildName = data.profile.provider_name_person;
                  this.unmergeFirstChild = data.profile;
                },
                (error) => {
                  //this.unmergeFirstChildId = unmergeItems[0];
                  //this.unmergeFirstChildName = "Data not available";
    
                }
              );
              this.http.get<any>(this.apiUrl + '/profiles/' + unmergeItems[1]).subscribe(
                (data: any) => {
                  // this.unmergeSecondChildId = unmergeItems[1];
                  // this.unmergeSecondChildName = data.profile.provider_name_person;
                  this.unmergeSecondChild = data.profile;
                },
                (error) => {
                  // this.unmergeSecondChildId = unmergeItems[1]
                  // this.unmergeSecondChildName = "Data not available";
                }
              );
            }
            else {
              const unmergeConfirmation = this.ngbModal.open(UnmergeModalComponent, {
                ariaDescribedBy: 'modal-basic-title', windowClass: 'groupModal', size: 'sm', centered: true
              });
              unmergeConfirmation.componentInstance.pid = item.text;
            }
    
            diagram.selection.remove(id);
            diagram.paint();
          });
          diagram.events.on("shapeDblClick", (id) => {
            this.tree = [];
            this.container.nativeElement.innerHTML = "";
            this.repaintTree = false;
            this.treeLoader = true;
            this.searchItem.push(diagram.data.getItem(id).text);
            this.searchText = diagram.data.getItem(id).text;
            this.searchItem.push(this.searchText);
            if (this.searchItem.length > 1) {
              this.navOn = true;
              this.prevEnabled = true;
              this.searchIndex++;
            }
    
            this.http.get(this.apiUrl + '/unmerge/tree/' + diagram.data.getItem(id).text).subscribe((data: any) => {
    
              for (let i = 0; i <= data.unmerge_tree.length - 1; i++) {
                if (data.unmerge_tree[i].parent) {
                  this.tree.push(
                    {
                      id: data.unmerge_tree[i].id,
                      title: data.unmerge_tree[i].title,
                      text: data.unmerge_tree[i].text,
                      parent: data.unmerge_tree[i].parent
                    }
                  );
                }
                else {
                  this.tree.push(
                    {
                      id: data.unmerge_tree[i].id,
                      title: data.unmerge_tree[i].title,
                      text: data.unmerge_tree[i].text
                    }
                  );
                }
    
              }
              if (data.unmerge_tree.length < 1) {
                this.notAvailable = true;
              } else {
                this.notAvailable = false;
              }
              var diagram = new dhx.Diagram(this.container.nativeElement, {
                type: "org",
                select: true,
                toolbar: [
                  {
                    id: "delete",
                    content: "<i class='dxi dxi-link-variant-off'></i>"
                  }
                ]
              });
              diagram.events.on("ShapeIconClick", (icon) => {
                const id = diagram.selection.getId();
                const item = diagram.selection.getItem();
                const unmergeItems = this.tree.filter((obj) => obj.parent == id).map((obj) => obj.text);
                this.selectedForMerge = item.text;
        
                if (item.title == "unmerge") {
                  this.showUnmergeDetail = true;
                  this.http.get<any>(this.apiUrl + '/profiles/' + unmergeItems[0]).subscribe(
                    (data: any) => {
                      //this.unmergeFirstChildId = unmergeItems[0];
                      //this.unmergeFirstChildName = data.profile.provider_name_person;
                      this.unmergeFirstChild = data.profile;
                    },
                    (error) => {
                      //this.unmergeFirstChildId = unmergeItems[0];
                      //this.unmergeFirstChildName = "Data not available";
        
                    }
                  );
                  this.http.get<any>(this.apiUrl + '/profiles/' + unmergeItems[1]).subscribe(
                    (data: any) => {
                      // this.unmergeSecondChildId = unmergeItems[1];
                      // this.unmergeSecondChildName = data.profile.provider_name_person;
                      this.unmergeSecondChild = data.profile;
                    },
                    (error) => {
                      // this.unmergeSecondChildId = unmergeItems[1]
                      // this.unmergeSecondChildName = "Data not available";
                    }
                  );
                }
                else {
                  const unmergeConfirmation = this.ngbModal.open(UnmergeModalComponent, {
                    ariaDescribedBy: 'modal-basic-title', windowClass: 'groupModal', size: 'sm', centered: true
                  });
                  unmergeConfirmation.componentInstance.pid = item.text;
                }
        
                diagram.selection.remove(id);
                diagram.paint();
              });
              diagram.data.parse(this.tree);
              this.treeLoader = false;
            });
          });
          diagram.data.parse(this.tree);
          this.treeLoader = false;
        });
      });

      diagram.data.parse(this.tree);
      this.treeLoader = false;
    });

  }
  prevSearch() {

    this.searchIndex--;
    this.showUnmergeDetail = false;
    this.renderChart();

    if (this.searchIndex < 1) {
      this.prevEnabled = false;
    }
    if (this.searchIndex < this.searchItem.length - 1) {
      this.nextEnabled = true;
    }
  }
  nextSearch() {
    this.searchIndex++;
    this.showUnmergeDetail = false;
    this.renderChart();
    if (this.searchIndex == this.searchItem.length - 1) {
      this.nextEnabled = false;
    }
    if (this.searchIndex != 0) {
      this.prevEnabled = true;
    }
  }
  renderChart() {
    let searchProvider = this.searchItem[this.searchIndex];
    this.searchText = searchProvider;
    this.tree = [];
    this.container.nativeElement.innerHTML = "";
    this.repaintTree = false;
    this.treeLoader = true;
    this.http.get<any>(this.apiUrl + '/unmerge/tree/' + searchProvider).subscribe((data: any) => {
      for (let i = 0; i <= data.unmerge_tree.length - 1; i++) {
        if (data.unmerge_tree[i].parent) {
          this.tree.push(
            {
              id: data.unmerge_tree[i].id,
              title: data.unmerge_tree[i].title,
              text: data.unmerge_tree[i].text,
              parent: data.unmerge_tree[i].parent
            }
          );
        }
        else {
          this.tree.push(
            {
              id: data.unmerge_tree[i].id,
              title: data.unmerge_tree[i].title,
              text: data.unmerge_tree[i].text
            }
          );
        }

      }

      if (data.unmerge_tree.length < 1) {
        this.notAvailable = true;
      }
      else {
        this.notAvailable = false;
      }

      var diagram = new dhx.Diagram(this.container.nativeElement, {
        type: "org",
        select: true,
        toolbar: [
          {
            id: "delete",
            content: "<i class='dxi dxi-link-variant-off'></i>"
          }
        ]
      });
      diagram.events.on("ShapeIconClick", (icon) => {
        const id = diagram.selection.getId();
        const item = diagram.selection.getItem();
        const unmergeItems = this.tree.filter((obj) => obj.parent == id).map((obj) => obj.text);
        this.selectedForMerge = item.text;

        if (item.title == "unmerge") {
          this.showUnmergeDetail = true;
          this.http.get<any>(this.apiUrl + '/profiles/' + unmergeItems[0]).subscribe(
            (data: any) => {
              this.unmergeFirstChild = data.profile;
            },
            (error) => {
              // this.unmergeFirstChildId = unmergeItems[0];
              // this.unmergeFirstChildName = "Data not available";
            }
          );
          this.http.get<any>(this.apiUrl + '/profiles/' + unmergeItems[1]).subscribe(
            (data: any) => {
              this.unmergeSecondChild = data.profile;
            },
            (error) => {
              // this.unmergeSecondChildId = unmergeItems[1]
              // this.unmergeSecondChildName = "Data not available";
            }
          );
          this.showUnmergeDetail = true;
        }
        else {
          const unmergeConfirmation = this.ngbModal.open(UnmergeModalComponent, {
            ariaDescribedBy: 'modal-basic-title', windowClass: 'groupModal', size: 'sm', centered: true
          });
          unmergeConfirmation.componentInstance.pid = item.text;
        }

        diagram.selection.remove(id);
        diagram.paint();
      });
      diagram.events.on("shapeDblClick", (id) => {
        this.tree = [];
        this.container.nativeElement.innerHTML = "";
        this.repaintTree = false;
        this.treeLoader = true;
        this.searchItem.push(diagram.data.getItem(id).text);
        this.searchText = diagram.data.getItem(id).text;
        this.searchItem.push(this.searchText);
        if (this.searchItem.length > 1) {
          this.navOn = true;
          this.prevEnabled = true;
          this.searchIndex++;
        }

        this.http.get(this.apiUrl + '/unmerge/tree/' + diagram.data.getItem(id).text).subscribe((data: any) => {

          for (let i = 0; i <= data.unmerge_tree.length - 1; i++) {
            if (data.unmerge_tree[i].parent) {
              this.tree.push(
                {
                  id: data.unmerge_tree[i].id,
                  title: data.unmerge_tree[i].title,
                  text: data.unmerge_tree[i].text,
                  parent: data.unmerge_tree[i].parent
                }
              );
            }
            else {
              this.tree.push(
                {
                  id: data.unmerge_tree[i].id,
                  title: data.unmerge_tree[i].title,
                  text: data.unmerge_tree[i].text
                }
              );
            }

          }
          if (data.unmerge_tree.length < 1) {
            this.notAvailable = true;
          } else {
            this.notAvailable = false;
          }
          var diagram = new dhx.Diagram(this.container.nativeElement, {
            type: "org",
            select: true,
            toolbar: [
              {
                id: "delete",
                content: "<i class='dxi dxi-link-variant-off'></i>"
              }
            ]
          });
          diagram.events.on("shapeDblClick", (id) => {
            this.tree = [];
            this.container.nativeElement.innerHTML = "";
            this.repaintTree = false;
            this.treeLoader = true;
            this.searchItem.push(diagram.data.getItem(id).text);
            this.searchText = diagram.data.getItem(id).text;
            this.searchItem.push(this.searchText);
            if (this.searchItem.length > 1) {
              this.navOn = true;
              this.prevEnabled = true;
              this.searchIndex++;
            }
    
            this.http.get(this.apiUrl + '/unmerge/tree/' + diagram.data.getItem(id).text).subscribe((data: any) => {
    
              for (let i = 0; i <= data.unmerge_tree.length - 1; i++) {
                if (data.unmerge_tree[i].parent) {
                  this.tree.push(
                    {
                      id: data.unmerge_tree[i].id,
                      title: data.unmerge_tree[i].title,
                      text: data.unmerge_tree[i].text,
                      parent: data.unmerge_tree[i].parent
                    }
                  );
                }
                else {
                  this.tree.push(
                    {
                      id: data.unmerge_tree[i].id,
                      title: data.unmerge_tree[i].title,
                      text: data.unmerge_tree[i].text
                    }
                  );
                }
    
              }
              if (data.unmerge_tree.length < 1) {
                this.notAvailable = true;
              } else {
                this.notAvailable = false;
              }
              var diagram = new dhx.Diagram(this.container.nativeElement, {
                type: "org",
                select: true,
                toolbar: [
                  {
                    id: "delete",
                    content: "<i class='dxi dxi-link-variant-off'></i>"
                  }
                ]
              });
              diagram.events.on("ShapeIconClick", (icon) => {
                const id = diagram.selection.getId();
                const item = diagram.selection.getItem();
                const unmergeItems = this.tree.filter((obj) => obj.parent == id).map((obj) => obj.text);
                this.selectedForMerge = item.text;
        
                if (item.title == "unmerge") {
                  this.showUnmergeDetail = true;
                  this.http.get<any>(this.apiUrl + '/profiles/' + unmergeItems[0]).subscribe(
                    (data: any) => {
                      //this.unmergeFirstChildId = unmergeItems[0];
                      //this.unmergeFirstChildName = data.profile.provider_name_person;
                      this.unmergeFirstChild = data.profile;
                    },
                    (error) => {
                      //this.unmergeFirstChildId = unmergeItems[0];
                      //this.unmergeFirstChildName = "Data not available";
        
                    }
                  );
                  this.http.get<any>(this.apiUrl + '/profiles/' + unmergeItems[1]).subscribe(
                    (data: any) => {
                      // this.unmergeSecondChildId = unmergeItems[1];
                      // this.unmergeSecondChildName = data.profile.provider_name_person;
                      this.unmergeSecondChild = data.profile;
                    },
                    (error) => {
                      // this.unmergeSecondChildId = unmergeItems[1]
                      // this.unmergeSecondChildName = "Data not available";
                    }
                  );
                }
                else {
                  const unmergeConfirmation = this.ngbModal.open(UnmergeModalComponent, {
                    ariaDescribedBy: 'modal-basic-title', windowClass: 'groupModal', size: 'sm', centered: true
                  });
                  unmergeConfirmation.componentInstance.pid = item.text;
                }
        
                diagram.selection.remove(id);
                diagram.paint();
              });
              diagram.data.parse(this.tree);
              this.treeLoader = false;
            });
          });
          diagram.events.on("ShapeIconClick", (icon) => {
            const id = diagram.selection.getId();
            const item = diagram.selection.getItem();
            const unmergeItems = this.tree.filter((obj) => obj.parent == id).map((obj) => obj.text);
            this.selectedForMerge = item.text;
    
            if (item.title == "unmerge") {
              this.showUnmergeDetail = true;
              this.http.get<any>(this.apiUrl + '/profiles/' + unmergeItems[0]).subscribe(
                (data: any) => {
                  //this.unmergeFirstChildId = unmergeItems[0];
                  //this.unmergeFirstChildName = data.profile.provider_name_person;
                  this.unmergeFirstChild = data.profile;
                },
                (error) => {
                  //this.unmergeFirstChildId = unmergeItems[0];
                  //this.unmergeFirstChildName = "Data not available";
    
                }
              );
              this.http.get<any>(this.apiUrl + '/profiles/' + unmergeItems[1]).subscribe(
                (data: any) => {
                  // this.unmergeSecondChildId = unmergeItems[1];
                  // this.unmergeSecondChildName = data.profile.provider_name_person;
                  this.unmergeSecondChild = data.profile;
                },
                (error) => {
                  // this.unmergeSecondChildId = unmergeItems[1]
                  // this.unmergeSecondChildName = "Data not available";
                }
              );
            }
            else {
              const unmergeConfirmation = this.ngbModal.open(UnmergeModalComponent, {
                ariaDescribedBy: 'modal-basic-title', windowClass: 'groupModal', size: 'sm', centered: true
              });
              unmergeConfirmation.componentInstance.pid = item.text;
            }
    
            diagram.selection.remove(id);
            diagram.paint();
          });
          diagram.data.parse(this.tree);
          this.treeLoader = false;
        });
      });
      diagram.data.parse(this.tree);
      this.treeLoader = false;
    });
  }
  unmergeNow() {
    this.unmergeData.spear_id_firestore = this.selectedForMerge;
    this.treeLoader = true;
    this.http.post(this.apiUrl + '/unmerge', this.unmergeData).subscribe((data) => {

      this.toastr.success('The document has been unmerged successfully');
      this.treeLoader = false;
      this.showUnmergeDetail = false;
      this.tree = [];
      this.container.nativeElement.innerHTML = "";
      this.treeLoader = true;
      this.http.get<any>(this.apiUrl + '/unmerge/tree/' + this.searchText).subscribe((data: any) => {
        for (let i = 0; i <= data.unmerge_tree.length - 1; i++) {
          if (data.unmerge_tree[i].parent) {
            this.tree.push(
              {
                id: data.unmerge_tree[i].id,
                title: data.unmerge_tree[i].title,
                text: data.unmerge_tree[i].text,
                parent: data.unmerge_tree[i].parent
              }
            );
          }
          else {
            this.tree.push(
              {
                id: data.unmerge_tree[i].id,
                title: data.unmerge_tree[i].title,
                text: data.unmerge_tree[i].text
              }
            );
          }

        }
        if (data.unmerge_tree.length < 1) {
          this.notAvailable = true;
        } else {
          this.notAvailable = false;
        }
        var diagram = new dhx.Diagram(this.container.nativeElement, {
          type: "org",
          select: true,
          toolbar: [
            {
              id: "delete",
              content: "<i class='dxi dxi-link-variant-off'></i>"
            }
          ]
        });
        diagram.events.on("ShapeIconClick", (icon) => {
          const id = diagram.selection.getId();
          const item = diagram.selection.getItem();
          const unmergeItems = this.tree.filter((obj) => obj.parent == id).map((obj) => obj.text);
          this.selectedForMerge = item.text;

          if (item.title == "unmerge") {
            this.showUnmergeDetail = true;
            this.http.get<any>(this.apiUrl + '/profiles/' + unmergeItems[0]).subscribe(
              (data: any) => {
                this.unmergeFirstChild = data.profile;
              },
              (error) => {
                // this.unmergeFirstChildId = unmergeItems[0];
                // this.unmergeFirstChildName = "Data not available";
              }
            );
            this.http.get<any>(this.apiUrl + '/profiles/' + unmergeItems[1]).subscribe(
              (data: any) => {
                this.unmergeSecondChild = data.profile;
              },
              (error) => {
                // this.unmergeSecondChildId = unmergeItems[1]
                // this.unmergeSecondChildName = "Data not available";
              }
            );
            this.showUnmergeDetail = true;
          }
          else {
            const unmergeConfirmation = this.ngbModal.open(UnmergeModalComponent, {
              ariaDescribedBy: 'modal-basic-title', windowClass: 'groupModal', size: 'sm', centered: true
            });
            unmergeConfirmation.componentInstance.pid = item.text;
          }
          diagram.selection.remove(id);
          diagram.paint();
        });
        diagram.events.on("shapeDblClick", (id) => {
          this.tree = [];
          this.container.nativeElement.innerHTML = "";
          this.repaintTree = false;
          this.treeLoader = true;
          this.searchItem.push(diagram.data.getItem(id).text);
          this.searchText = diagram.data.getItem(id).text;
          this.searchItem.push(this.searchText);
          if (this.searchItem.length > 1) {
            this.navOn = true;
            this.prevEnabled = true;
            this.searchIndex++;
          }

          this.http.get(this.apiUrl + '/unmerge/tree/' + diagram.data.getItem(id).text).subscribe((data: any) => {

            for (let i = 0; i <= data.unmerge_tree.length - 1; i++) {
              if (data.unmerge_tree[i].parent) {
                this.tree.push(
                  {
                    id: data.unmerge_tree[i].id,
                    title: data.unmerge_tree[i].title,
                    text: data.unmerge_tree[i].text,
                    parent: data.unmerge_tree[i].parent
                  }
                );
              }
              else {
                this.tree.push(
                  {
                    id: data.unmerge_tree[i].id,
                    title: data.unmerge_tree[i].title,
                    text: data.unmerge_tree[i].text
                  }
                );
              }

            }
            if (data.unmerge_tree.length < 1) {
              this.notAvailable = true;
            } else {
              this.notAvailable = false;
            }
            var diagram = new dhx.Diagram(this.container.nativeElement, {
              type: "org",
              select: true,
              toolbar: [
                {
                  id: "delete",
                  content: "<i class='dxi dxi-link-variant-off'></i>"
                }
              ]
            });
            diagram.events.on("ShapeIconClick", (icon) => {
              const id = diagram.selection.getId();
              const item = diagram.selection.getItem();
              const unmergeItems = this.tree.filter((obj) => obj.parent == id).map((obj) => obj.text);
              this.selectedForMerge = item.text;
      
              if (item.title == "unmerge") {
                this.showUnmergeDetail = true;
                this.http.get<any>(this.apiUrl + '/profiles/' + unmergeItems[0]).subscribe(
                  (data: any) => {
                    //this.unmergeFirstChildId = unmergeItems[0];
                    //this.unmergeFirstChildName = data.profile.provider_name_person;
                    this.unmergeFirstChild = data.profile;
                  },
                  (error) => {
                    //this.unmergeFirstChildId = unmergeItems[0];
                    //this.unmergeFirstChildName = "Data not available";
      
                  }
                );
                this.http.get<any>(this.apiUrl + '/profiles/' + unmergeItems[1]).subscribe(
                  (data: any) => {
                    // this.unmergeSecondChildId = unmergeItems[1];
                    // this.unmergeSecondChildName = data.profile.provider_name_person;
                    this.unmergeSecondChild = data.profile;
                  },
                  (error) => {
                    // this.unmergeSecondChildId = unmergeItems[1]
                    // this.unmergeSecondChildName = "Data not available";
                  }
                );
              }
              else {
                const unmergeConfirmation = this.ngbModal.open(UnmergeModalComponent, {
                  ariaDescribedBy: 'modal-basic-title', windowClass: 'groupModal', size: 'sm', centered: true
                });
                unmergeConfirmation.componentInstance.pid = item.text;
              }
      
              diagram.selection.remove(id);
              diagram.paint();
            });
            diagram.data.parse(this.tree);
            this.treeLoader = false;
          });
        });
        diagram.data.parse(this.tree);
        this.treeLoader = false;
      });
    });
  }
  unmergeCancel() {
    this.showUnmergeDetail = false;
  }

  transform(value: string, strToReplace: string, replacementStr: string): string {
    if (!value || !strToReplace || !replacementStr) {
      return value;
    }
    return value.replace(new RegExp(strToReplace, 'g'), replacementStr);
  }
}

