<div class="container-fluid p-0" *blockUI="'limos-report-blockUI'">
  <div class="row">
    <div class="col-md-12 pr-0">
      <div class="card border-0">
        <div class="card-body search-spear-background">
          <div class="mb-2 d-flex justify-content-between align-items-center">
            <div>
              <h3 class="page-title">LIMOS REPORT</h3>
            </div>
            <div>

              <button class="btn btn-secondary-outline bg-white text-voilet p-2  btn-sm" (click)="getLimosReportData(true)">Export All To CSV
              </button>
            </div>
          </div>
          <div class="table-row">
            <table class="table mb-0 spear-search-table-header-background">
              <caption>Limos Report</caption>
              <thead>
                <tr>
                  <th class="border-0" scope="col">File Status</th>
                  <th class="border-0" scope="col">Claim Number</th>
                  <th class="border-0" scope="col">Provider Name</th>
                  <th class="border-0" scope="col">Lien Hearing Date</th>
                  <th class="border-0" scope="col">Lien Hearing Time</th>
                  <th class="border-0" scope="col">WCAB Venue</th>
                  <th class="border-0" scope="col">WCAB Judge</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let report of limosReport">
                  <td class="border-0 text-capitalize" scope="col">{{ report.limos_status || 'Not Listed' }}</td>
                  <td class="border-0 font-weight-semibold" scope="col">
                    <a class="primary-text text-decoration-none" target="_blank" [routerLink]="['/limos',report.wc_claim_number,report.id]">
                      {{ report.wc_claim_number }}
                    </a>
                  </td>
                  <td class="border-0" scope="col">{{ report.provider_name_business || 'Not Listed' }}</td>
                  <td class="border-0" scope="col">{{ (report.lien_hearing_date | date:'MM/dd/yyyy') || 'Not Listed' }}</td>
                  <td class="border-0" scope="col">{{ report.lien_hearing_time || 'Not Listed' }}</td>
                  <td class="border-0" scope="col">{{ report.wcab_venue || 'Not Listed' }}</td>
                  <td class="border-0" scope="col">{{ report.wcab_judge || 'Not Listed' }}</td>
                </tr>
                <tr class="bg-white" *ngIf="!limosReport">
                  <td align="center" colspan="7">
                    Data Not Available
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex px-0 py-0 paginationWrapper" *ngIf="!(!limosReport || limosReport.length === 0)">
              <ngb-pagination [(collectionSize)]="totalHits" [(page)]="pageOffset" [pageSize]="pageLimit" [maxSize]="10"
                [boundaryLinks]="true" (pageChange)="onPageChange($event)">
              </ngb-pagination>
              <div class="selected d-flex align-items-center">
                <select (change)="onSizeChange(pageLimit)" class="custom-select table-select" style="width: auto" [(ngModel)]="pageLimit">
                  <option [ngValue]="20">20</option>
                  <option [ngValue]="50">50</option>
                  <option [ngValue]="75">75</option>
                  <option [ngValue]="100">100</option>
                </select>
                <p class="mb-0 ml-2">Showing {{ ((pageOffset * pageLimit) - pageLimit) + 1 }}
                  -
                  {{ pageOffset * pageLimit > totalHits ? totalHits : pageOffset *
                  pageLimit }} of {{ totalHits }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
