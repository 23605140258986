import { Component, OnInit } from '@angular/core';

import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.css']
})
export class GroupListComponent implements OnInit {

  chipsInput: string;

  isDisabled = true;
  closeResult = '';

  constructor(private modalService: NgbModal) { }

  open(content): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', windowClass: 'groupModal', centered: true})
    .result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  show(content1): void {
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title', windowClass: 'groupModal', size: 'lg', centered: true})
    .result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  del(deletecontent): void {
    this.modalService.open(deletecontent, {ariaLabelledBy: 'modal-basic-title', windowClass: 'groupModal delModal', centered: true})
    .result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  cnfdel(cnfdeletecontent): void {
    this.modalService.open(cnfdeletecontent, {ariaLabelledBy: 'modal-basic-title', windowClass: 'groupModal delModal', centered: true})
    .result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
  }
  onClick(): void{
    this.isDisabled = !this.isDisabled;
  }
}
