import { Pipe, PipeTransform } from '@angular/core';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';

@Pipe({
  name: 'filterPipe'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, filterString: string, property: string): any {
    const filterArr = [];

    if (!value || value.length === 0 || !filterString) {
      return value;
    }

    value.forEach(item => {
      if (item[property].toLowerCase().indexOf(filterString.toLowerCase()) > -1){
        filterArr.push(item);
      }
    });
    return filterArr;
  }

}
