<div class="row profileReport" *blockUI="'manage-users-blockUI'">
  <div class="col-12 primary-tab" *ngIf="profileDataReady">
    <ul class="nav nav-tabs">
      <li class="nav-item" *ngIf="isPerson">
        <button [ngClass]="{ active: currentActiveTab === 'Person' }" class="nav-link search-spear-background"
          (click)="currentActiveTab = 'Person'">
          Person
        </button>
        <!-- <a class="nav-link active" aria-current="page" href="#">Active</a> -->
      </li>
      <li class="nav-item" *ngIf="isBusiness">
        <button [ngClass]="{ active: currentActiveTab === 'Business' }" class="nav-link search-spear-background"
          (click)="currentActiveTab = 'Business'">
          Business
        </button>
      </li>
      <li class="nav-item" *ngIf="isEntity">
        <button [ngClass]="{ active: currentActiveTab === 'Entity' }" class="nav-link search-spear-background"
          (click)="currentActiveTab = 'Entity'">
          Entities
        </button>
      </li>
      <li class="nav-item" *ngIf="isRecord">
        <button [ngClass]="{ active: currentActiveTab === 'Record' }" class="nav-link search-spear-background"
          (click)="currentActiveTab = 'Record'">
          Record
        </button>
      </li>
      <li class="nav-item" *ngFor="let business of businesses; let i = index">
        <button [ngClass]="{ active: currentActiveTab === i }" class="nav-link" (click)="currentActiveTab = i">
          {{ business }}
        </button>
      </li>
    </ul>
  </div>

  <div class="col-12 profileReportBody search-spear-background">
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-between align-items-center pl-2 pb-3 business-info" *ngIf="isEntity">
          <div class="d-flex flex-row">
            <div class="d-flex flex-column mr-4">
              <div><strong>Total Open/Delayed Claims</strong></div>
              <div class="text-center static-data-highlighted">&#8212;</div>
            </div>
            <div class="d-flex flex-column mr-4">
              <div><strong>Total Closed Claims</strong></div>
              <div class="text-center static-data-highlighted">&#8212;</div>
            </div>
            <div class="d-flex flex-column mr-4">
              <div><strong>Total Claims Billed</strong></div>
              <div class="text-center static-data-highlighted">&#8212;</div>
            </div>
            <div class="d-flex flex-column mr-4">
              <div><strong>Total Claims Paid</strong></div>
              <div class="text-center static-data-highlighted">&#8212;</div>
            </div>
            <div class="d-flex flex-column mr-4">
              <div><strong>Open Claims Billed</strong></div>
              <div class="text-center static-data-highlighted">&#8212;</div>
            </div>
            <div class="d-flex flex-column mr-4">
              <div><strong>Open Claims Paid</strong></div>
              <div class="text-center static-data-highlighted">&#8212;</div>
            </div>
            <div class="d-flex flex-column">
              <div><strong>Open Claims Billed No Pay</strong></div>
              <div class="text-center static-data-highlighted">&#8212;</div>
            </div>
          </div>
          <div class="d-flex flex-row">
            <button class="btn btn-sm spear-button-background text-white">Spear</button>
            <button class="btn btn-sm spear-button-background text-white ml-2">LIMOS</button>
            <button class="btn btn-sm spear-button-background text-white ml-2">Adonis</button>
            <button class="btn btn-sm spear-button-background text-white ml-2">Urania</button>
            <button class="btn btn-sm spear-button-background text-white ml-2">Midas</button>
            <button routerLink="/reports" class="btn btn-sm spear-button-background text-white ml-2">
              Achilles
            </button>
          </div>
        </div>
        <div class="border-0 title-wrap mb-2">
          <div class="spear-info">
            <div class="row mt-1">
              <div class="col-md-3 text-center">
                <h4 class="page-title" *ngIf="isPerson">
                  Provider Profile - Person
                </h4>
                <h4 class="page-title" *ngIf="isBusiness">
                  Provider Profile - Business
                </h4>
                <h4 class="page-title" *ngIf="isEntity">
                  Provider Profile - Entities
                </h4>
                <h4 class="page-title" *ngIf="isRecord">
                  Provider Profile - Record
                </h4>
              </div>
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-6 text-center">
                    <h5 class="page-title" *ngIf="isPerson || isRecord">
                      {{
                      profileModel.details?.provider_name_person ||
                      ((profileModel.details?.official_first_name ? profileModel.details?.official_first_name : '') + "
                      " + (profileModel.details?.official_last_name ? profileModel.details?.official_last_name : ''))
                      }}
                      <span
                        *ngIf="!profileModel.details?.provider_name_person && !profileModel.details?.official_first_name && !profileModel.details?.official_last_name">No
                        name available</span>
                    </h5>
                    <h5 class="page-title" *ngIf="isBusiness">
                      {{
                      profileModel.details?.provider_name_business ||
                      profileModel.details?.provider_name_person ||
                      "No Name Available"
                      }}
                    </h5>
                    <h5 class="page-title" *ngIf="isEntity">
                      {{
                      profileModel.details?.entities[ent_no].entity_name ||
                      "No Name Available"
                      }}
                    </h5>
                  </div>
                  <!-- <div class="col-md-6" *ngIf="isEntity">
                                        <div class="h6 text-right">
                                            ID# {{profileId}}
                                        </div>
                                    </div> -->
                  <div class="col-md-6 text-right">
                    <h4 class="page-title">
                      Provider ID# <span *ngIf="profileId" class="ml-2">{{profileId}}</span>
                      <!-- <b class="ml-2">Merges:</b> -->
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="row mb-1">
          <div class="col-12">
            <div class="accordian-tab card-header spear-table-header-background title-space">
              <h6 class="m-0">TIN</h6>
              <button class="btn">
                <em (click)="togglePanel('Tin')" class="fa fa-sort-desc" [ngClass]="tinStatus ? '' : 'antirotate'"></em>
              </button>
            </div>
            <div [ngClass]="tinStatus ? 'collapsecard' : 'expandcard'">
              <div class="card border-0 card-scrollbar">
                <div class="card-body p-0">
                  <table class="table providerTable">
                    <caption>
                      TIN
                    </caption>
                    <thead>
                      <tr>
                        <th class="border-0" scope="col">Number</th>
                        <th class="border-0" scope="col">Pay Code</th>
                        <th class="border-0" scope="col">Date Last Used</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="profileModel?.details?.tax_id_number">
                      <tr class="bg-white">
                        <td class="primary-text font-weight-semibold">{{profileModel?.details?.tax_id_number}}</td>
                        <td>{{profileModel?.details?.po_code}}</td>
                        <td></td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="!profileModel?.details?.tax_id_number">
                      <tr class="bg-white">
                        <td colspan="3" class="text-center">
                          Data not available
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-12">
            <div class="accordian-tab card-header spear-table-header-background title-space">
              <h6 class="m-0">NPI'S</h6>
              <button class="btn"><em (click)="togglePanel('NPI')" class="fa fa-sort-desc"
                  [ngClass]="NPIStatus ? '' : 'antirotate'"></em></button>

            </div>
            <div [ngClass]="NPIStatus ? 'collapsecard' : 'expandcard'">
              <div class="card border-0 card-scrollbar">
                <div class="card-body p-0">
                  <table class="table providerTable">
                    <caption>NPI</caption>
                    <thead>
                      <tr>
                        <th class="border-0" scope="col">Number</th>
                        <th class="border-0" scope="col">Last Year Billed</th>
                        <th class="border-0" scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="profileModel?.details?.provider_npi_number">
                      <tr class="bg-white">
                        <td class="primary-text font-weight-semibold">{{profileModel?.details?.provider_npi_number}}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="!profileModel?.details?.provider_npi_number">
                      <tr class="bg-white">
                        <td colspan="4" class="text-center">Data not available</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-1" *ngIf="isMerged || isEdited">
          <div class="col-12">
            <h6>Profile History</h6>
            <table class="table providerTable">
              <caption>
                Profile History
              </caption>
              <thead>
                <tr>
                  <th class="border-0 sorting" scope="col">Status</th>
                  <th class="border-0 sorting" scope="col">IDs</th>
                </tr>
              </thead>
              <tbody>
                <tr class="bg-white">
                  <td class="primary-text text-uppercase font-weight-semibold">
                    <span *ngIf="isMerged">Merged</span><span *ngIf="isEdited">Edited</span>
                  </td>
                  <td>
                    <table class="w-100">
                      <caption>
                        Profile History
                      </caption>
                      <thead class="d-none">
                        <tr>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let ids of MergedFrom">
                          <td class="p-0 border-0" [ngbTooltip]="tipContent" (mouseenter)="showId(ids)">
                            {{ ids }}
                          </td>
                          <ng-template #tipContent>
                            <table class="tip-table">
                              <caption>
                                Tooltip Table
                              </caption>
                              <thead class="d-none">
                                <tr>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Provider Id: {{ ids }}</td>
                                </tr>
                                <tr>
                                  <td>Provider Name: {{ tipName }}</td>
                                </tr>
                                <tr>
                                  <td>Provider Address: {{ tipAddress }}</td>
                                </tr>
                                <tr>
                                  <td>Provider Entity: {{ tipEntity }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </ng-template>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr class="bg-white">
                  <td colspan="2">
                    <span *ngIf="isMerged" class="primary-text text-uppercase font-weight-semibold">Merged on:
                    </span><span *ngIf="isEdited" class="primary-text text-uppercase font-weight-semibold">Edited on:
                    </span>{{ mergedAt | date:'MM/dd/yyyy' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row mb-1" *ngIf="false">
          <div class="col-12">
            <div class="accordian-tab card-header spear-table-header-background title-space">
              <h6 class="m-0">NPI</h6>
              <button class="btn">
                <em (click)="togglePanel('NPI')" class="fa fa-sort-desc" [ngClass]="NPIStatus ? '' : 'antirotate'"></em>
              </button>
            </div>
            <div [ngClass]="NPIStatus ? 'collapsecard' : 'expandcard'">
              <div class="card border-0 card-scrollbar">
                <div class="card-body" *ngIf="profileModel.details?.provider_npi_number">
                  <div *ngIf="true">
                    <div class="row justify-content-between">
                      <div class="col-8">
                        {{ profileModel.details?.provider_npi_number }}
                      </div>
                      <div class="col-3">
                        <span class="badge badge-success" *ngIf="profileModel.details?.npi_active">Active</span>
                        <span *ngIf="!profileModel.details?.npi_active" class="badge badge-danger">Inactive</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-12">
            <div class="accordian-tab card-header spear-table-header-background title-space">
              <h6 class="m-0">Licenses</h6>
              <button class="btn"><em (click)="togglePanel('LIC')" class="fa fa-sort-desc"
                  [ngClass]="licStatus ? '' : 'antirotate'"></em></button>
            </div>
            <div [ngClass]="licStatus ? 'collapsecard' : 'expandcard'">
              <div class="card border-0 card-scrollbar">
                <div class="card-body p-0">
                  <table class="table providerTable">
                    <caption>Licenses</caption>
                    <thead>
                      <tr>
                        <th class="border-0" scope="col">Number</th>
                        <th class="border-0" scope="col">Type</th>
                        <th class="border-0" scope="col">State</th>
                        <th class="border-0" scope="col">Status</th>
                        <th class="border-0" scope="col">Secondary Status</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="licensesObj.length > 0">
                      <tr class="bg-white" *ngFor="let lic of licensesObj">
                        <td class="primary-text text-uppercase font-weight-semibold">
                          {{ lic.number }}
                        </td>
                        <td>{{ lic.type || '&#8212;' }}</td>
                        <td>{{ lic.state || '&#8212;' }}</td>
                        <td>
                          <span class="role">{{ lic.status || '&#8212;' }}</span>
                        </td>
                        <td>&#8212;</td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="licensesObj.length < 1">
                      <tr class="bg-white">
                        <td colspan="5" class="text-center">Data not available</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-12">
            <div class="accordian-tab card-header spear-table-header-background title-space">
              <h6 class="m-0">FNP Status</h6>
              <button class="btn">
                <em (click)="togglePanel('Fnp')" class="fa fa-sort-desc" [ngClass]="fnpStatus ? '' : 'antirotate'"></em>
              </button>
            </div>
            <div [ngClass]="fnpStatus ? 'collapsecard' : 'expandcard'">
              <div class="card border-0 card-scrollbar">
                <div class="card-body p-0">
                  <textarea class="form-control mt-1" id="fnp_status">{{profileModel.details?.fnp_status}}</textarea>
                  <div class="text-right mt-1">
                    <button class="btn viewBtn spear-button-background text-white small-btn"
                      (click)="updateFNP()">Update</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-12">
            <div class="accordian-tab card-header spear-table-header-background title-space">
              <h6 class="m-0">IRS Match</h6>
              <button class="btn "><em (click)="togglePanel('Irs')" class="fa fa-sort-desc"
                  [ngClass]="irsStatus ? '' : 'antirotate'"></em></button>
            </div>
            <div [ngClass]="irsStatus ? 'collapsecard' : 'expandcard'">
              <div class="card border-0  card-scrollbar">
                <div class="card-body p-0">
                  <table class="table providerTable">
                    <caption>IRS Match</caption>
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">TIN</th>
                        <th scope="col">Match</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="false">
                      <tr class="bg-white">
                        <td class="primary-text font-weight-semibold"></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="true">
                      <tr class="bg-white">
                        <td class="text-center" colspan="3">Data not available</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-12">
            <div class="accordian-tab card-header spear-table-header-background title-space">
              <h6 class="m-0">Secretary of States</h6>
              <button class="btn "><em (click)="togglePanel('Secre')" class="fa fa-sort-desc"
                  [ngClass]="secreState ? '' : 'antirotate'"></em></button>
            </div>
            <div [ngClass]="secreState ? 'collapsecard' : 'expandcard'">
              <div class="card border-0  card-scrollbar">
                <div class="card-body p-0">
                  <table class="table providerTable">
                    <caption>Secretary of States</caption>
                    <thead>
                      <tr>
                        <th scope="col">Entity</th>
                        <th scope="col">State</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="false">
                      <tr class="bg-white">
                        <td class="primary-text font-weight-semibold"></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="true">
                      <tr class="bg-white">
                        <td class="text-center" colspan="3">Data not available</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="accordian-tab card-header spear-table-header-background title-space">
              <h6 class="m-0">Associated MPNs</h6>
              <button class="btn "><em (click)="togglePanel('Asso')" class="fa fa-sort-desc"
                  [ngClass]="assoStatus ? '' : 'antirotate'"></em></button>
            </div>
            <div [ngClass]="assoStatus ? 'collapsecard' : 'expandcard'">
              <div class="card border-0  card-scrollbar">
                <div class="card-body p-0">
                  <table class="table providerTable">
                    <caption>Associated MPNs</caption>
                    <thead>
                      <tr>
                        <th scope="col">Entity</th>
                        <th scope="col">MPN Name</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="false">
                      <tr class="bg-white">
                        <td class="primary-text font-weight-semibold"></td>
                        <td class="primary-text font-weight-semibold"></td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="true">
                      <tr class="bg-white">
                        <td class="text-center" colspan="2">Data not available</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-9" [ngClass]="{ overlay: widgets }">
        <div class="loader-container"><span>Loading...</span></div>
        <div class="row" cdkDropListGroup>
          <div class="col-12 col-md-6" cdkDropList #leftList="cdkDropList" [cdkDropListData]="left"
            (cdkDropListDropped)="drop($event)">
            <div id="armor" class="mb-1" cdkDrag>
              <!-- [cdkDragDisabled]="armoStatus" -->
              <div class="accordian-tab card-header spear-table-header-background title-space" cdkDragHandle>
                <h6>ARMOR</h6>
                <div class="d-flex">
                  <button
                    class="btn viewBtn spear-button-background text-white mr-2"
                    (click)="openAddArmor(createArmor)">Add</button>
                  <button class="btn">
                      <em (click)="togglePanel('Armo')" class="fa fa-sort-desc"
                          [ngClass]="armoStatus ? '' : 'antirotate'"></em>
                  </button>
                </div>
              </div>
              <div [ngClass]="armoStatus ? 'collapsecard' : 'expandcard'">
                <div class="row table-space">
                  <div class="col-12">
                    <div class="card border-0 card-scrollbar card-scrolbar-armor"
                      [ngClass]="armoStatus ? 'collapsecard' : 'expandcard'">
                      <div class="table-row">
                          <div>
                              <table class="table providerTable">
                                  <caption>Financial Interests</caption>
                                  <thead>
                                      <tr>
                                          <th class="border-0" scope="col">Pay Code</th>
                                          <th class="border-0" scope="col">Pay Code Status</th>
                                          <th class="border-0" scope="col">Flagged</th>
                                          <th class="border-0" scope="col">Flagged Date</th>
                                          <th class="border-0" scope="col"></th>
                                      </tr>
                                  </thead>
                                  <tbody *ngIf="armorResopone.length > 0">
                                      <tr class="bg-white" *ngFor="let row of armorResopone">
                                          <td class="link" (click)="editArmorDetails(row)">{{row.po_code}}</td>
                                          <td class="link" (click)="editArmorDetails(row)">{{row.pay_code_status}}</td>
                                          <td></td>
                                          <td></td>
                                          <td class="d-flex">
                                            <button class="btn small-btn"
                                            (click)="openDeleteArmor(deleteArmor,row.id)">
                                              <em class="fa fa-trash" aria-hidden="true"></em>
                                            </button>
                                          </td>
                                      </tr>
                                  </tbody>
                                  <tbody *ngIf="armorResopone.length < 1">
                                      <tr class="bg-white">
                                          <td colspan="6" class="text-center">Data not available</td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                      </div>

                      <ng-container *ngIf="editArmorData">
                        <div class="accordian-tab card-header spear-table-header-background mt-1">
                          <h6>Armor Investigative Actions</h6>
                        </div>
                        <div class="card-body pt-0">
                          <form name="armorForm" [formGroup]="armorForm">
                            <div class="row mt-1">
                              <div class="col-6">Pay Code</div>
                              <div class="col-6 d-flex">
                                <select class="form-control" formControlName="armor_po_code" (change)="selectPayCode($event)"
                                  [ngClass]="!editArmorData ? 'disabled' : ''">
                                  <option value="{{opt}}" *ngFor="let opt of po_codes" [selected]="opt === armorForm.get('armor_po_code').value">
                                    {{opt}}</option>
                                </select>
                                <button title="Save" class="btn small-btn" (click)="updatePayCode()"
                                  *ngIf="armorForm.get('armor_po_code').dirty && editArmorData.po_code != armorForm.get('armor_po_code').value">
                                  <em class="fa fa-save" aria-hidden="true"></em>
                                </button>
                              </div>
                            </div>
                            <div class="row mt-1">
                              <div class="col-6">
                                Cross Walk BR Code
                              </div>
                              <div class="col-6 d-flex">
                                <input type="text" class="form-control" formControlName="armor_br_code" />
                                <button title="Save" class="btn small-btn" (click)="updatePayCode()"
                                  *ngIf="armorForm.get('armor_br_code').dirty && editArmorData.armor_br_code != armorForm.get('armor_br_code').value">
                                  <em class="fa fa-save" aria-hidden="true"></em>
                                </button>
                              </div>
                            </div>
                            <div class="row mt-1">
                              <div class="col-6">State Code</div>
                              <div class="col-6 d-flex">
                                <input type="text" class="form-control" formControlName="armor_state_code"
                                  [value]="profileModel.details?.state_code" />
                                <button title="Save" class="btn small-btn" (click)="updatePayCode()"
                                  *ngIf="armorForm.get('armor_state_code').dirty && editArmorData?.state_code != armorForm.get('armor_state_code').value">
                                  <em class="fa fa-save" aria-hidden="true"></em>
                                </button>
                              </div>
                            </div>
                            <div class="row mt-1">
                              <div class="col-6">
                                Adjuster Alert Sent
                              </div>
                              <div class="col-6">
                                <select class="form-control" formControlName="armor_adj_alert_sent">
                                  <option>Yes</option>
                                  <option>No</option>
                                </select>
                              </div>
                            </div>
                            <div class="row mt-1">
                              <div class="col-6">Alert Date</div>
                              <div class="input-group col-6">
                                <input class="form-control" type="text" formControlName="armor_alert_date"
                                  [formCtrl]="armorForm.get('armor_alert_date')" jDate />
                                <button title="Save" class="btn small-btn" (click)="updatePayCode()"
                                  *ngIf="armorForm.get('armor_alert_date').dirty && editArmorData?.armor_alert_date != armorForm.get('armor_alert_date').value">
                                  <em class="fa fa-save" aria-hidden="true"></em>
                                </button>
                              </div>
                            </div>
                            <div class="row mt-1">
                              <div class="col-6">
                                Add?
                              </div>
                              <div class="col-6">
                                <input type="text" class="form-control" />
                              </div>
                            </div>
                            <div class="row mt-1">
                              <div class="col-6">
                                Additional Pay Code
                              </div>
                              <div class="col-6 d-flex">
                                <input type="text" class="form-control" formControlName="armor_additional_pay_code" />
                                <button title="Save" class="btn small-btn" (click)="updatePayCode()"
                                  *ngIf="armorForm.get('armor_additional_pay_code').dirty && editArmorData?.armor_additional_pay_code != armorForm.get('armor_additional_pay_code').value">
                                  <em class="fa fa-save" aria-hidden="true"></em>
                                </button>
                              </div>
                            </div>
                            <div class="row mt-1">
                              <div class="col-6">
                                Effective Objection Date From
                              </div>
                              <div class="input-group col-6 d-flex">
                                <input class="form-control" type="text" formControlName="armor_effective_obj_from"
                                  [formCtrl]="armorForm.get('armor_effective_obj_from')" jDate />
                                <button title="Save" class="btn small-btn" (click)="updatePayCode()"
                                  *ngIf="armorForm.get('armor_effective_obj_from').dirty && editArmorData?.armor_effective_obj_from != armorForm.get('armor_effective_obj_from').value">
                                  <em class="fa fa-save" aria-hidden="true"></em>
                                </button>
                              </div>
                            </div>
                            <div class="row mt-1">
                              <div class="col-6">
                                Effective Objection Date To
                              </div>
                              <div class="input-group col-6 d-flex">
                                <input class="form-control" type="text" formControlName="armor_effective_obj_to"
                                  [formCtrl]="armorForm.get('armor_effective_obj_to')" jDate />
                                <button title="Save" class="btn small-btn" (click)="updatePayCode()"
                                  *ngIf="armorForm.get('armor_effective_obj_to').dirty && editArmorData?.armor_effective_obj_to != armorForm.get('armor_effective_obj_to').value">
                                  <em class="fa fa-save" aria-hidden="true"></em>
                                </button>
                              </div>
                            </div>
                            <div class="row mt-1">
                              <div class="col-6">
                                1244/1160 Notice Issued
                              </div>
                              <div class="col-6 d-flex">
                                <input type="text" class="form-control" formControlName="armor_notice_issued" />
                                <button title="Save" class="btn small-btn" (click)="updatePayCode()"
                                  *ngIf="armorForm.get('armor_notice_issued').dirty && editArmorData?.armor_notice_issued != armorForm.get('armor_notice_issued').value">
                                  <em class="fa fa-save" aria-hidden="true"></em>
                                </button>
                              </div>
                            </div>
                            <div class="row mt-1">
                              <div class="col-6">
                                1244/1160 Suspension Date
                              </div>
                              <div class="input-group col-6 d-flex">
                                <input class="form-control" type="text" formControlName="armor_suspension_date"
                                  [formCtrl]="armorForm.get('armor_suspension_date')" jDate />
                                <button title="Save" class="btn small-btn" (click)="updatePayCode()"
                                  *ngIf="armorForm.get('armor_suspension_date').dirty && editArmorData?.armor_suspension_date != armorForm.get('armor_suspension_date').value">
                                  <em class="fa fa-save" aria-hidden="true"></em>
                                </button>
                              </div>
                            </div>
                          </form>

                          <div class="row mt-1">
                            <div class="col-6">
                              Corrections Needed
                            </div>
                            <div class="col-6"
                              [ngClass]="{'d-flex' : armorFormCorrections.get('armor_corrections').dirty && editArmorData?.correction_needed != armorFormCorrections.get('armor_corrections').value}">
                              <form name="armorFormCorrections" [formGroup]="armorFormCorrections">
                                <textarea class="form-control" formControlName="armor_corrections"
                                  [ngClass]="!editArmorData ? 'disabled' : ''"></textarea>
                              </form>
                              <button title="Save" class="btn small-btn" (click)="updateCorrections()"
                                *ngIf="armorFormCorrections.get('armor_corrections').dirty && editArmorData?.correction_needed != armorFormCorrections.get('armor_corrections').value">
                                <em class="fa fa-save" aria-hidden="true"></em>
                              </button>
                            </div>
                          </div>

                          <div class="row mt-1">
                            <div class="col-6">
                              SIU BR Bill Objection Language
                            </div>
                            <div class="col-6"
                              [ngClass]="{'d-flex' : armorFormObjection.get('armor_objections').dirty && editArmorData?.armor_objections != armorFormObjection.get('armor_objections').value}">
                              <form name="armorFormObjection" [formGroup]="armorFormObjection">
                                <textarea class="form-control" formControlName="armor_objections"
                                  [ngClass]="!editArmorData ? 'disabled' : ''"></textarea>
                              </form>
                              <button title="Save" class="btn small-btn" (click)="updateObjection()"
                                *ngIf="armorFormObjection.get('armor_objections').dirty && editArmorData?.armor_objections != armorFormObjection.get('armor_objections').value">
                                <em class="fa fa-save" aria-hidden="true"></em>
                              </button>
                            </div>
                          </div>

                          <div class="row mt-1">
                            <div class="col-6">
                              Adjuster POA
                            </div>
                            <div class="col-6"
                              [ngClass]="{'d-flex':armorFormAdjuster.get('armor_adjuster').dirty && editArmorData?.adjuster_poa != armorFormAdjuster.get('armor_adjuster').value}">
                              <form name="armorFormAdjuster" [formGroup]="armorFormAdjuster">
                                <textarea class="form-control" formControlName="armor_adjuster"
                                  [ngClass]="!editArmorData ? 'disabled' : ''"></textarea>
                              </form>

                              <button title="Save" class="btn small-btn" (click)="updateAdjuster()"
                                *ngIf="armorFormAdjuster.get('armor_adjuster').dirty && editArmorData?.adjuster_poa != armorFormAdjuster.get('armor_adjuster').value">
                                <em class="fa fa-save" aria-hidden="true"></em>
                              </button>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="addresses" class="mb-1" cdkDrag>
              <!-- [cdkDragDisabled]="AddreStatus" -->
              <div class="accordian-tab card-header spear-table-header-background title-space" cdkDragHandle>
                <h6>Addresses</h6>
                <button class="btn">
                  <em (click)="togglePanel('Addre')" class="fa fa-sort-desc"
                    [ngClass]="AddreStatus ? '' : 'antirotate'"></em>
                </button>
              </div>
              <div [ngClass]="AddreStatus ? 'collapsecard' : 'expandcard'">
                <div>
                  <table class="table providerTable">
                    <caption>
                      Addresses
                    </caption>
                    <thead>
                      <tr>
                        <th class="border-0" scope="col">Type</th>
                        <th class="border-0" scope="col">Name</th>
                        <th class="border-0" scope="col">Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="bg-white">
                        <td class="primary-text font-weight-semibold">
                          {{ personAddre.type }}
                        </td>
                        <td>{{ personAddre.name }}</td>
                        <td><span *ngIf="personAddre.show">{{ personAddre.address ? 'Mailing: ' + personAddre.address : 'Address not
                            avialable' }}</span></td>
                      </tr>
                      <tr class="bg-white">
                        <td class="primary-text font-weight-semibold">
                          {{ practiceAddre.type }}
                        </td>
                        <td>{{ practiceAddre.name }}</td>
                        <td><span *ngIf="practiceAddre.show">{{ practiceAddre.address ? 'Practice: ' + practiceAddre.address :
                            personAddre.address ? 'Mailing: ' + personAddre.address : 'Address not avialable' }}</span></td>
                      </tr>
                      <tr class="bg-white">
                        <td class="primary-text font-weight-semibold">
                          {{ entityAddre.type }}
                        </td>
                        <td>{{ entityAddre.name }}</td>
                        <td><span *ngIf="entityAddre.show">{{ entityAddre.address ? 'Mailing: ' + entityAddre.address :
                            practiceAddre.address ? 'Practice: ' + practiceAddre.address : personAddre.address ? 'Mailing: ' + personAddre.address :
                            'Address not avialable' }}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div id="contactnumbers" class="mb-1" cdkDrag>
              <!-- [cdkDragDisabled]="ContNumStatus" -->
              <div class="accordian-tab card-header spear-table-header-background title-space" cdkDragHandle>
                <h6>Contact Numbers</h6>
                <button class="btn">
                  <em (click)="togglePanel('ContNum')" class="fa fa-sort-desc"
                    [ngClass]="ContNumStatus ? '' : 'antirotate'"></em>
                </button>
              </div>
              <div [ngClass]="ContNumStatus ? 'collapsecard' : 'expandcard'">
                <div class="table-row">
                  <table class="table providerTable">
                    <caption>
                      Contact Numbers
                      {{
                      ContNumStatus
                      }}
                    </caption>
                    <thead>
                      <tr>
                        <th class="border-0" scope="col">Type</th>
                        <th class="border-0" scope="col">Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="bg-white">
                        <td class="primary-text font-weight-semibold">
                          Provider Fax
                        </td>
                        <td *ngIf="profileDataReady">
                          {{ faxNumber ? faxNumber : "NA" }}
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="primary-text font-weight-semibold">
                          Provider Phone
                        </td>
                        <td *ngIf="profileDataReady">
                          {{ phoneNumber ? phoneNumber : "NA" }}
                        </td>
                      </tr>

                      <tr class="bg-white">
                        <td class="primary-text font-weight-semibold">
                          Provider Phone Practice
                        </td>
                        <td *ngIf="profileDataReady">
                          {{ phonePractice ? phonePractice : "NA" }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div id="clearreports" class="mb-1" cdkDrag>
              <!-- [cdkDragDisabled]="ClearStatus" -->
              <div class="accordian-tab card-header spear-table-header-background title-space" cdkDragHandle>
                <h6>Clear Reports</h6>
                <div class="d-flex">
                  <div class="input-file">
                    <input type="file" id="fileupload-clear-report" accept=".doc,.docx,.pdf"
                      (change)="onFileSelectedClearReport($event)" #fileUploadClearReport />
                    <label for="fileupload-clear-report" type="button"
                      class="btn viewBtn spear-button-background text-white mr-2">Add</label>
                  </div>

                  <button class="btn">
                    <em (click)="togglePanel('Clear')" class="fa fa-sort-desc"
                      [ngClass]="ClearStatus ? '' : 'antirotate'"></em>
                  </button>
                </div>
              </div>
              <div [ngClass]="ClearStatus ? 'collapsecard' : 'expandcard'">
                <div class="table-row">
                  <table class="table providerTable">
                    <caption>
                      Clear Reports
                    </caption>
                    <thead>
                      <tr>
                        <th class="border-0" scope="col">File Name</th>
                        <th class="border-0" scope="col">Uploaded On</th>
                        <th class="border-0" scope="col"></th>
                        <th class="border-0" *ngIf="!isSuperAdmin" scope="col"></th>
                      </tr>
                    </thead>
                    <tbody *ngIf="isClearReport">
                      <tr class="bg-white" *ngFor="let user of ClearRep">
                        <td class="primary-text font-weight-semibold">
                          {{ user.filename }}
                        </td>
                        <td>{{ user.uploaddt | date:'MM/dd/yyyy' }}</td>
                        <td>
                          <button *ngIf="user.id != 'NA'" (click)="download(user.id)">
                            <em class="fa fa-arrow-circle-down" aria-hidden="true"></em>
                          </button>
                        </td>
                        <td *ngIf="!isSuperAdmin">
                          <button *ngIf="user.id != 'NA'" (click)="deleteConfirmation(user.id, 'Clear')">
                            <em class="fa fa-trash" aria-hidden="true"></em>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="!isClearReport">
                      <tr class="bg-white">
                        <td colspan="4" class="text-center">
                          Data not available
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6" cdkDropList #rightList="cdkDropList" [cdkDropListData]="right"
            (cdkDropListDropped)="drop($event)">
            <div id="financialinterests" class="mb-1" cdkDrag>
              <!-- [cdkDragDisabled]="FinaStatus" -->
              <div class="accordian-tab card-header spear-table-header-background title-space" cdkDragHandle>
                <h6>Financial Interests</h6>
                <div class="d-flex">
                  <button class="btn viewBtn spear-button-background text-white mr-2"
                    (click)="openAddFinancialInterestModal(profileId)">
                    Add
                  </button>
                  <button class="btn viewBtn spear-button-background text-white mr-2">
                    Edit
                  </button>
                  <button class="btn viewBtn spear-button-background text-white mr-2">
                    Save
                  </button>
                  <button class="btn">
                    <em (click)="togglePanel('Fina')" class="fa fa-sort-desc"
                      [ngClass]="FinaStatus ? '' : 'antirotate'"></em>
                  </button>
                </div>
              </div>
              <div [ngClass]="FinaStatus ? 'collapsecard' : 'expandcard'">
                <div class="table-row">
                  <div *ngIf="isBusiness || isPerson">
                    <div class="table-row">
                      <table class="table providerTable">
                        <caption>
                          Financial Associated Interests
                        </caption>
                        <thead>
                          <tr>
                            <th class="border-0" scope="col" width="150">Entities</th>
                            <!-- <th class="border-0" scope="col">TIN</th>
                            <th class="border-0" scope="col">NPI</th>
                            <th class="border-0" scope="col">Address</th>
                            <th class="border-0" scope="col">Phone</th> -->
                          </tr>
                        </thead>
                        <tbody *ngIf="financialEntities.length > 0">
                          <tr class="bg-white" *ngFor="let ent of financialEntities">
                            <td>
                              <a class="primary-text text-uppercase font-weight-semibold"
                                href="entities-report/{{ ent.id }}" target="_blank">{{ ent.entity_name }}</a>
                            </td>
                            <!-- <td></td>
                            <td></td>
                            <td></td>
                            <td></td> -->
                          </tr>
                        </tbody>
                        <tbody *ngIf="financialEntities.length < 1">
                          <tr class="bg-white">
                            <td colspan="1" class="text-center">
                              Data not available
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="card-header spear-table-header-background subhead font-weight-bold text-dark d-none">
                      Suggested Financial Interests
                    </div>
                    <table class="table providerTable d-none">
                      <caption>
                        Suggested Financial Interests
                      </caption>
                      <thead>
                        <tr>
                          <th class="border-0" scope="col">Entity Name</th>
                          <th class="border-0" scope="col">NPI</th>
                          <th class="border-0" scope="col">Pay Code</th>
                          <th class="border-0" scope="col"></th>
                        </tr>
                      </thead>
                      <tbody *ngIf="suggestedTableFinancial.length > 0">
                        <tr class="bg-white" *ngFor="let user of suggestedTableFinancial">
                          <td class="primary-text text-uppercase font-weight-semibold">
                            {{ user?.name }} {{ user?.lastName }}
                          </td>
                          <td>{{ user.npi || "NA" }}</td>
                          <td>{{ user.code || "NA" }}</td>
                          <td>
                            <button class="btn viewBtn spear-button-background text-white"
                              (click)="addFinancialSuggested(user.id)">
                              Associate
                            </button>
                          </td>
                        </tr>
                      </tbody>
                      <tbody *ngIf="suggestedTableFinancial.length < 1">
                        <tr class="bg-white">
                          <td colspan="4" class="text-center">
                            Data not available
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="card-header spear-table-header-background subhead font-weight-bold text-dark d-none">
                      Selected Financial Interests
                    </div>
                    <table class="table providerTable financial d-none">
                      <caption>
                        Selected Financial Interests
                      </caption>
                      <thead>
                        <tr>
                          <th class="border-0" scope="col">Name</th>
                          <th class="border-0" scope="col">Alerts</th>
                          <th class="border-0" scope="col">Address</th>
                          <th class="border-0" scope="col"></th>
                        </tr>
                      </thead>
                      <tbody *ngIf="FinancialInterest.length > 0">
                        <tr class="bg-white" *ngFor="let user of FinancialInterest">
                          <td class="primary-text text-uppercase font-weight-semibold">
                            <div class="d-flex flex-column">
                              <a class="name" href="profile-report/{{ user.firestore }}">{{ user?.name ||
                                user?.firestore }}</a>
                              <span *ngIf="!user.tin">TIN: Not Available</span>
                              <a *ngIf="user?.tin">TIN: {{ user?.tin }}</a>
                              <span *ngIf="!user.npi">NPI: Not Available</span>
                              <button *ngIf="user?.npi" (click)="searchMerge(user?.npi)">
                                NPI: {{ user?.npi }}
                              </button>
                              <span>MPN: {{ user?.mpn || "Not Available" }}</span>
                            </div>
                          </td>
                          <td>
                            <button *ngIf="user.alerts" (click)="openAlertLang(user.alertlang)">
                              {{ user.alerts }}</button><span *ngIf="!user.alerts">NA</span>
                          </td>
                          <td>
                            {{ user?.street
                            }}<span *ngIf="user.street && user.city">,<br /></span>{{ user?.city
                            }}<span *ngIf="user.city && user.state">, </span>{{ user?.state
                            }}<span *ngIf="user.state && user.zip">&nbsp;&nbsp;</span>{{ user?.zip
                            }}<span *ngIf="
                                !user.street &&
                                !user.city &&
                                !user.state &&
                                !user.zip
                              ">Not Available</span>
                          </td>
                          <td>
                            <button class="btn viewBtn spear-button-background text-white"
                              (click)="deleteFinancial(user?.id)">
                              Dissociate
                            </button>
                          </td>
                        </tr>
                      </tbody>
                      <tbody *ngIf="FinancialInterest.length < 1">
                        <tr class="bg-white">
                          <td colspan="4" class="text-center">
                            Data not available
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div *ngIf="isEntity">
                    <table class="table providerTable">
                      <caption>
                        Financial Interests
                      </caption>
                      <thead>
                        <tr>
                          <th class="border-0" scope="col">Entity Name</th>
                          <th class="border-0" scope="col">TIN</th>
                          <th class="border-0" scope="col">NPI</th>
                          <th class="border-0" scope="col">Address</th>
                          <th class="border-0" scope="col">Phone</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="false">
                        <tr class="bg-white">
                          <td class="primary-text text-uppercase font-weight-semibold"></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                      <tbody *ngIf="true">
                        <tr class="bg-white">
                          <td colspan="5" class="text-center">
                            Data not available
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div id="topreferring" class="mb-1" cdkDrag>
              <div class="accordian-tab card-header spear-table-header-background title-space" cdkDragHandle>
                <h6>Top Referring Specialists</h6>
                <button class="btn">
                  <em (click)="togglePanel('TopReferring')" class="fa fa-sort-desc"
                    [ngClass]="TopReferringStatus ? '' : 'antirotate'"></em>
                </button>
              </div>
              <div [ngClass]="TopReferringStatus ? 'collapsecard' : 'expandcard'">
                <div class="table-row">
                  <table class="table providerTable">
                    <caption>
                      Top Referring
                    </caption>
                    <thead>
                      <tr>
                        <th class="border-0" scope="col">Specialists</th>
                        <th class="border-0" scope="col">Speciality</th>
                        <th class="border-0" scope="col">TIN</th>
                        <th class="border-0" scope="col">NPI</th>
                        <th class="border-0" scope="col">Top Addresses</th>
                        <th class="border-0" scope="col">Records</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="false">
                      <tr class="bg-white">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="true">
                      <tr class="bg-white">
                        <td colspan="6" class="text-center">
                          Data not available
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div id="otherassociatedproviders" class="mb-1" cdkDrag>
              <!-- [cdkDragDisabled]="AssocStatus" -->
              <div class="accordian-tab card-header spear-table-header-background title-space" cdkDragHandle>
                <h6>Other Associated Providers</h6>
                <div class="flex">
                  <button class="btn viewBtn spear-button-background text-white mr-2"
                    (click)="openAddOtherassociatedprovider(profileId)">
                    Add
                  </button>
                  <button class="btn">
                    <em (click)="togglePanel('Assoc')" class="fa fa-sort-desc"
                      [ngClass]="AssocStatus ? '' : 'antirotate'"></em>
                  </button>
                </div>
              </div>
              <div [ngClass]="AssocStatus ? 'collapsecard' : 'expandcard'">
                <div class="card-header spear-table-header-background subhead font-weight-bold text-dark d-none">
                  Suggested Other Associated Providers
                </div>
                <div class="table-row">
                  <table class="table providerTable">
                    <caption>
                      Other Associated Interests
                    </caption>
                    <thead>
                      <tr>
                        <th class="border-0" scope="col">Entity Name</th>
                        <!-- <th class="border-0" scope="col">NPI</th>
                        <th class="border-0" scope="col">Pay Code</th>
                        <th class="border-0" scope="col"></th> -->
                      </tr>
                    </thead>
                    <tbody *ngIf="otherEntities.length > 0">
                      <tr class="bg-white" *ngFor="let ent of otherEntities">
                        <td>
                          <a class="primary-text text-uppercase font-weight-semibold"
                            href="entities-report/{{ ent.id }}" target="_blank">{{ ent.entity_name }}</a>
                        </td>
                        <!-- <td></td>
                        <td></td>
                        <td><button class="btn viewBtn spear-button-background text-white"
                                                            (click)="addOtherAssociatedSuggested(user.id)">Associate</button>
                                                    </td> -->
                      </tr>
                    </tbody>
                    <tbody *ngIf="otherEntities.length < 1">
                      <tr class="bg-white">
                        <td colspan="1" class="text-center">
                          Data not available
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="table-row d-none">
                  <table class="table providerTable">
                    <caption>
                      Suggested Other Associated Interests
                    </caption>
                    <thead>
                      <tr>
                        <th class="border-0" scope="col">Entity Name</th>
                        <th class="border-0" scope="col">NPI</th>
                        <th class="border-0" scope="col">Pay Code</th>
                        <th class="border-0" scope="col"></th>
                      </tr>
                    </thead>
                    <tbody *ngIf="suggestedTableOtherAssociated.length > 0">
                      <tr class="bg-white" *ngFor="let user of suggestedTableOtherAssociated">
                        <td class="primary-text text-uppercase font-weight-semibold">
                          {{ user.name }} {{ user.lastName }}
                        </td>
                        <td>{{ user.npi || "NA" }}</td>
                        <td>{{ user.code || "NA" }}</td>
                        <td>
                          <button class="btn viewBtn spear-button-background text-white"
                            (click)="addOtherAssociatedSuggested(user.id)">
                            Associate
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="suggestedTableOtherAssociated.length < 1">
                      <tr class="bg-white">
                        <td colspan="4" class="text-center">
                          Data not available
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-header spear-table-header-background subhead font-weight-bold text-dark d-none">
                  Selected Other Associated Providers
                </div>
                <div class="table-row d-none">
                  <table class="table providerTable">
                    <caption>
                      Selected Other Associated Providers
                    </caption>
                    <thead>
                      <tr>
                        <th class="border-0" scope="col">Name</th>
                        <th class="border-0" scope="col">Address</th>
                        <th class="border-0" scope="col"></th>
                      </tr>
                    </thead>
                    <tbody *ngIf="OtherAssociated.length > 0">
                      <tr class="bg-white" *ngFor="let user of OtherAssociated">
                        <td class="primary-text text-uppercase font-weight-semibold">
                          <a class="name" href="profile-report/{{ user.firestore }}">{{ user?.name || user?.firestore
                            }}</a>
                        </td>
                        <td>
                          {{ user?.street
                          }}<span *ngIf="user.street && user.city">,<br /></span>{{ user?.city
                          }}<span *ngIf="user.city && user.state">, </span>{{ user?.state
                          }}<span *ngIf="user.state && user.zip">&nbsp;&nbsp;</span>{{ user?.zip
                          }}<span *ngIf="
                              !user.street &&
                              !user.city &&
                              !user.state &&
                              !user.zip
                            ">Not Available</span>
                        </td>
                        <td>
                          <button class="btn viewBtn spear-button-background text-white"
                            (click)="deleteAssociate(user.id)">
                            Dissociate
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="OtherAssociated.length < 1">
                      <tr class="bg-white">
                        <td colspan="4" class="text-center">
                          Data not available
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div id="whitepaper" class="mb-1" cdkDrag>
              <!-- [cdkDragDisabled]="WhiteStatus" -->
              <div class="accordian-tab card-header spear-table-header-background title-space" cdkDragHandle>
                <h6>White Paper</h6>
                <div class="d-flex">
                  <div class="input-file">
                    <input type="file" id="fileupload-whitepaper" accept=".doc,.docx,.pdf"
                      (change)="onFileSelectedWhitePaper($event)" />
                    <label type="button" for="fileupload-whitepaper"
                      class="btn viewBtn spear-button-background text-white mr-2">Add</label>
                  </div>
                  <button class="btn">
                    <em (click)="togglePanel('White')" class="fa fa-sort-desc"
                      [ngClass]="WhiteStatus ? '' : 'antirotate'"></em>
                  </button>
                </div>
              </div>
              <div [ngClass]="WhiteStatus ? 'collapsecard' : 'expandcard'">
                <div class="table-row">
                  <table class="table providerTable">
                    <caption>
                      Whitepaper
                    </caption>
                    <thead>
                      <tr>
                        <th class="border-0" scope="col">File Name</th>
                        <th class="border-0" scope="col">Uploaded On</th>
                        <th class="border-0" scope="col"></th>
                        <th class="border-0" *ngIf="!isSuperAdmin" scope="col"></th>
                      </tr>
                    </thead>
                    <tbody *ngIf="isWhiteReport">
                      <tr class="bg-white" *ngFor="let user of WhitePap">
                        <td class="primary-text font-weight-semibold">
                          {{ user.filename }}
                        </td>
                        <td>{{ user.uploaddt | date:'MM/dd/yyyy' }}{{ user.id }}</td>
                        <td>
                          <button (click)="download(user.id)">
                            <em class="fa fa-arrow-circle-down" aria-hidden="true"></em>
                          </button>
                        </td>
                        <td *ngIf="!isSuperAdmin">
                          <button (click)="deleteConfirmation(user.id, 'White')">
                            <em class="fa fa-trash" aria-hidden="true"></em>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="!isWhiteReport">
                      <tr class="bg-white">
                        <td colspan="4" class="text-center">
                          Data not available
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div id="associates" class="mb-1" cdkDrag>
              <div class="accordian-tab card-header spear-table-header-background title-space" cdkDragHandle>
                <h6>Individuals/Associates</h6>
                <div class="d-flex">
                  <button class="btn viewBtn spear-button-background text-white mr-2">
                    Add
                  </button>
                  <button class="btn viewBtn spear-button-background text-white mr-2">
                    Edit
                  </button>
                  <button class="btn viewBtn spear-button-background text-white mr-2">
                    Save
                  </button>
                  <button class="btn">
                    <em (click)="togglePanel('Associate')" class="fa fa-sort-desc"
                      [ngClass]="AssociateStatus ? '' : 'antirotate'"></em>
                  </button>
                </div>
              </div>
              <div [ngClass]="AssociateStatus ? 'collapsecard' : 'expandcard'">
                <div class="table-row">
                  <table class="table providerTable">
                    <caption>
                      Associate
                    </caption>
                    <thead>
                      <tr>
                        <th class="border-0" scope="col">Name</th>
                        <th class="border-0" scope="col">Type</th>
                        <th class="border-0" scope="col">Information/Notes</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="false">
                      <tr class="bg-white">
                        <td class="primary-text font-weight-semibold"></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="true">
                      <tr class="bg-white">
                        <td colspan="3" class="text-center">
                          Data not available
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div id="documents" class="mb-1" cdkDrag>
              <div class="accordian-tab card-header spear-table-header-background title-space" cdkDragHandle>
                <h6>Documents</h6>
                <div class="d-flex">
                  <div class="input-file">
                    <input type="file" id="fileupload-documents" accept=".doc,.docx,.pdf"
                      (change)="onFileSelectedDocument($event)" />
                  </div>
                  <label type="button" for="fileupload-documents"
                    class="btn viewBtn spear-button-background text-white mr-2">Add</label>
                  <button class="btn">
                    <em (click)="togglePanel('Documents')" class="fa fa-sort-desc"
                      [ngClass]="DocumentsStatus ? '' : 'antirotate'"></em>
                  </button>
                </div>
              </div>
              <div [ngClass]="DocumentsStatus ? 'collapsecard' : 'expandcard'">
                <div class="table-row">
                  <table class="table providerTable">
                    <caption>
                      Documents
                    </caption>
                    <thead>
                      <tr>
                        <th class="border-0" scope="col">File Name</th>
                        <th class="border-0" scope="col">Uploaded On</th>
                        <th class="border-0" scope="col"></th>
                        <th class="border-0" *ngIf="!isSuperAdmin" scope="col"></th>
                      </tr>
                    </thead>
                    <tbody *ngIf="isDocuments">
                      <tr class="bg-white" *ngFor="let file of Document">
                        <td class="primary-text font-weight-semibold">
                          {{ file.filename }}
                        </td>
                        <td>{{ file.uploaddt | date:'MM/dd/yyyy' }}{{ file.id }}</td>
                        <td>
                          <button (click)="download(file.id)">
                            <em class="fa fa-arrow-circle-down" aria-hidden="true"></em>
                          </button>
                        </td>
                        <td *ngIf="!isSuperAdmin">
                          <button (click)="deleteDocument(file.id, [profileId])">
                            <em class="fa fa-trash" aria-hidden="true"></em>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="!isDocuments">
                      <tr class="bg-white">
                        <td colspan="4" class="text-center">
                          Data not available
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--row-end-->
        <div class="container-fluid profile-report-edit" [ngClass]="editStatus ? 'closeEdit' : 'openEdit'">
          <div class="row">
            <div class="col-12">
              <div
                class="row justify-content-between card-header spear-table-header-background pt-2 pb-2 align-self-center align-items-center pl-0 pr-0">
                <div class="col-auto align-middle">
                  <h6 class="m-0">Associated Entities</h6>
                </div>
                <div class="col-auto">
                  <button class="btn border-0 btn-transparent" (click)="closeEdit()">
                    X
                  </button>
                </div>
              </div>

              <div class="row mt-3 justify-content-center text-center">
                <div class="col-md-6">Lorem Ipsum</div>
                <div class="col-md-6">Lorem Ipsum</div>
              </div>

              <div class="row mt-3 card-header spear-table-header-background-yellow pt-2 pb-2">
                <div class="col-md-6 text-center font-weight-bold">NPI</div>
                <div class="col-md-6 text-center font-weight-bold">EIN</div>
              </div>

              <div class="row mt-3 text-center justify-content-center">
                <div class="col-md-6">Lorem Ipsum</div>
                <div class="col-md-6">Lorem Ipsum</div>
              </div>

              <div class="row mt-3 card-header spear-table-header-background-yellow pt-2 pb-2">
                <div class="col-md-6 text-center font-weight-bold">MPN</div>
                <div class="col-md-6 text-center font-weight-bold">FLAGGED</div>
              </div>

              <div class="row mt-3 text-center justify-content-center">
                <div class="col-md-6">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                      value="option1" />
                    <label class="form-check-label" for="inlineRadio1">Yes</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                      value="option2" />
                    <label class="form-check-label" for="inlineRadio2">No</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio3"
                      value="option3" />
                    <label class="form-check-label" for="inlineRadio3">Yes</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio4"
                      value="option4" />
                    <label class="form-check-label" for="inlineRadio4">No</label>
                  </div>
                </div>
              </div>

              <div class="row mt-3 text-center justify-content-center">
                <div class="col-md-6">Lorem Ipsum</div>
                <div class="col-md-6">Lorem Ipsum</div>
              </div>

              <div
                class="row justify-content-between card-header spear-table-header-background pt-2 pb-2 align-self-center align-items-center mt-3 pl-0">
                <div class="col-auto align-middle">
                  <h6 class="m-0">Source Data</h6>
                </div>
              </div>

              <div class="source_data_wrap">
                <div class="row mt-3">
                  <div class="col-12 mb-2">Lorem Ipsum State</div>
                  <div class="col-12 mb-2">Lorem Ipsum State</div>
                  <div class="col-12 mb-2">Lorem Ipsum State</div>
                  <div class="col-12 mb-2">Lorem Ipsum State</div>
                  <div class="col-12 mb-2">Lorem Ipsum State</div>
                  <div class="col-12 mb-2">Lorem Ipsum State</div>
                </div>
              </div>

              <div
                class="row justify-content-between card-header spear-table-header-background pt-2 pb-2 align-self-center align-items-center mt-3 pl-0">
                <div class="col-auto align-middle">
                  <h6 class="m-0">Clear Report</h6>
                </div>
              </div>

              <div class="source_data_wrap">
                <div class="row mt-3">
                  <div class="col-12 mb-2">Lorem Ipsum State</div>
                  <div class="col-12 mb-2">Lorem Ipsum State</div>
                  <div class="col-12 mb-2">Lorem Ipsum State</div>
                  <div class="col-12 mb-2">Lorem Ipsum State</div>
                  <div class="col-12 mb-2">Lorem Ipsum State</div>
                  <div class="col-12 mb-2">Lorem Ipsum State</div>
                </div>
              </div>

              <div
                class="row justify-content-between card-header spear-table-header-background pt-2 pb-2 align-self-center align-items-center mt-3 pl-0">
                <div class="col-auto align-middle">
                  <h6 class="m-0">White Paper</h6>
                </div>
              </div>

              <div class="source_data_wrap">
                <div class="row mt-3">
                  <div class="col-12 mb-2">Lorem Ipsum State</div>
                  <div class="col-12 mb-2">Lorem Ipsum State</div>
                  <div class="col-12 mb-2">Lorem Ipsum State</div>
                  <div class="col-12 mb-2">Lorem Ipsum State</div>
                  <div class="col-12 mb-2">Lorem Ipsum State</div>
                  <div class="col-12 mb-2">Lorem Ipsum State</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="col-12 profileReportBody" [hidden]="!(currentActiveTab == 'Record')">
    <div class="d-flex flex-column justify-content-center align-items-center py-4">
      <h4 class="page-title">Please make a choice</h4>
      <div class="mt-3">
        <button class="btn viewBtn spear-button-background text-white mr-2" (click)="chooseEntity('Person')">
          Person
        </button>
        <button class="btn viewBtn spear-button-background text-white" (click)="chooseEntity('Business')">
          Business
        </button>
      </div>
    </div>
  </div> -->
  <div class="col-12 profileReportBody" *ngFor="let business of businesses; let i = index"
    [hidden]="!(currentActiveTab == i)">
    <h4 class="page-title">{{ business }}</h4>
  </div>
</div>
<ng-template #createArmor let-modal>
  <div class="modal-body">
    <p>Please select provider from the search result list</p>
    <div class="d-flex flex-row align-items-center mb-4">
      <select class="form-control mr-4 mb-0" id="pay_code_list">
        <option value="{{opt}}" *ngFor="let opt of po_codes">{{opt}}</option>
      </select>
      <button class="btn spear-button-background text-white" (click)="addArmor()">
        Add
      </button>
    </div>
    <div class="text-center">
      <button class="btn spear-button-background text-white" (click)="closeAddArmor()">
        Close
      </button>
    </div>
  </div>
</ng-template>
<ng-template #deleteArmor let-modal>
  <div class="modal-body">
    <p class="text-center">Are you sure you want to delete this record?</p>
    <div class="text-center">
      <button class="btn spear-button-background text-white mr-4" (click)="removeArmor()">
        Yes
      </button>
      <button class="btn spear-button-background text-white" (click)="closeAddArmor()">
        No
      </button>
    </div>
  </div>
</ng-template>
