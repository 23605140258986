import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  Client,
  ClientCreateResponse,
  ClientDataResponse,
} from 'src/app/models/client/client.interface';
import { ClientService } from 'src/app/services/client.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal'; // Adjust the import according to your modal library

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.css'],
})
export class ContactDetailComponent implements OnInit, OnDestroy {
  newDropdownMenu = false;
  editDropdownMenu = false;
  activeTab = 'Overview';
  pageTabs = ['Overview', 'Permissions', 'Notifications', 'Notes', 'History'];
  generalInfo = false;
  addressInfo = false;
  instructionNotesInfo = false;
  private unsubscribe$ = new Subject<void>();
  clientData: Client;
  clientContactForm: FormGroup;
  clientLocationForm: FormGroup;
  newCaseForm: FormGroup;
  serverDateFormat = 'yyyy-MM-dd';
  isClientContactFormSubmitted: boolean;
  isClientLocationFormSubmitted: boolean;
  clientForm: FormGroup;
  isClientFormSubmitted: boolean;
  modalRef?: BsModalRef;
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private clientService: ClientService,
    private fb: FormBuilder,
    private _datePipe: DatePipe,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.initClientContactForm();
    this.initClientLocationForm();
    this.initNewCaseForm();
  }

  ngOnInit() {
    this.clientForm.get('client')?.setValue('27');
    this.route.params
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((params: Params) => this.fetchClientDetails(params.id))
      )
      .subscribe();
  }

  fetchClientDetails(clientId: number) {
    return this.clientService.getClientDetail(clientId).pipe(
      takeUntil(this.unsubscribe$),
      tap(({ details }: ClientDataResponse<Client>) => {
        this.clientData = details;
      })
    );
  }

  public openPopup(content, size = 'xl') {
    this.modalService.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size,
      centered: true,
    });
  }

  public openDeletePopUP(content, size = 'mi') {
    this.modalService.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size,
      centered: true,
    });
  }

  initClientContactForm() {
    this.clientContactForm = this.fb.group({
      first_name: new FormControl('', [Validators.required]),
      middle_name: new FormControl(''),
      last_name: new FormControl('', [Validators.required]),
      title: new FormControl(''),
      role: new FormControl(''),
      access_level: new FormControl(''),
      email: new FormControl('', [Validators.email]),
      office_phone: new FormControl(''),
      mobile_phone: new FormControl(''),
      home_phone: new FormControl(''),
      fax: new FormControl(''),
      birthday: new FormControl(''),
      language: new FormControl(''),
      account_code: new FormControl(''),
      salesperson: new FormControl(''),
      case_manager: new FormControl(''),
      tags: new FormControl(''),
      general_notes: new FormControl(''),
      admin_notes: new FormControl(''),
      case_policies: new FormControl(''),
      location: new FormControl(''),
      address: this.fb.group({
        location_name: new FormControl(''),
        address_1: new FormControl(''),
        address_2: new FormControl(''),
        address_3: new FormControl(''),
        city: new FormControl(''),
        state: new FormControl(''),
        country: new FormControl(''),
        zip: new FormControl(''),
      }),
    });
  }

  initClientLocationForm() {
    this.clientLocationForm = this.fb.group({
      location_name: new FormControl('', [Validators.required]),
      is_primary: new FormControl(false),
      primary_phone: new FormControl(''),
      secondary_phone: new FormControl(''),
      fax: new FormControl(''),
      invoice_policies: new FormControl(''),
      general_notes: new FormControl(''),
      case_policies: new FormControl(''),
      update_policies: new FormControl(''),
      address: this.fb.group({
        address_1: new FormControl(''),
        address_2: new FormControl(''),
        address_3: new FormControl(''),
        city: new FormControl(''),
        state: new FormControl(''),
        country: new FormControl(''),
        zip: new FormControl(''),
      }),
    });
  }
  initNewCaseForm() {
    this.newCaseForm = this.fb.group({
      selectedClient: new FormControl('', [Validators.required]),
      selectedLocation: new FormControl('', [Validators.required]),
      primaryContact: new FormControl('', [Validators.required]),
      caseType: new FormControl('', [Validators.required]),
      selectedAssignMyself: new FormControl('', [Validators.required]),
    });
  }

  saveClientContact() {
    if (this.clientContactForm.invalid && !this.clientData.id) {
      return;
    }

    let birthdate = this.clientContactForm.get('birthday').value;
    if (birthdate) {
      this.clientContactForm
        .get('birthday')
        .setValue(this._datePipe.transform(birthdate, this.serverDateFormat));
    }

    this.isClientContactFormSubmitted = true;

    this.clientService
      .createClientContact(this.clientData.id, this.clientContactForm.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: ClientDataResponse<ClientCreateResponse>) => {
          this.toastr.success(res?.details?.message);
          this.modalService.dismissAll();
          this.clientContactForm.reset();
          this.isClientContactFormSubmitted = false;
          this.clientService.getClientDetail(this.clientData.id);
        },
        (error: ClientDataResponse<ClientCreateResponse>) => {
          this.toastr.success(error?.errors[0]);
        }
      );
  }

  saveClientLocation() {
    if (this.clientLocationForm.invalid && !this.clientData.id) {
      return;
    }

    this.isClientLocationFormSubmitted = true;

    this.clientService
      .createClientLocation(this.clientData.id, this.clientLocationForm.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: ClientDataResponse<ClientCreateResponse>) => {
          this.toastr.success(res?.details?.message);
          this.modalService.dismissAll();
          this.clientLocationForm.reset();
          this.isClientLocationFormSubmitted = false;
          this.clientService.getClientDetail(this.clientData.id);
        },
        (error: ClientDataResponse<ClientCreateResponse>) => {
          this.toastr.success(error?.errors[0]);
        }
      );
  }

  deleteClient() {
    this.clientService
      .deleteClient(this.clientData.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: ClientDataResponse<ClientCreateResponse>) => {
          this.toastr.success(res?.details?.message);
          this.router.navigate(['clients']);
        },
        (error: ClientDataResponse<ClientCreateResponse>) => {
          this.toastr.success(error?.errors[0]);
        }
      );
  }

  editClientProfile() {
    this.newDropdownMenu = false;
    this.editDropdownMenu = !this.editDropdownMenu;
    this.initClientForm();
  }

  initClientForm() {
    const {
      client_name,
      program_name,
      tpa,
      mga,
      address,
      email,
      main_phone,
      fax,
      client_instructions,
      invoice_instructions,
      general_notes,
    } = this.clientData;
    const {
      location_name,
      country,
      address_1,
      address_2,
      address_3,
      city,
      state,
      zip,
    } = address;
    this.clientForm = this.fb.group({
      client_name: new FormControl(client_name, [Validators.required]),
      program_name: new FormControl(program_name),
      tpa: new FormControl(tpa),
      mga: new FormControl(mga),
      email: new FormControl(email),
      main_phone: new FormControl(main_phone),
      fax: new FormControl(fax),
      client_instructions: new FormControl(client_instructions),
      invoice_instructions: new FormControl(invoice_instructions),
      general_notes: new FormControl(general_notes),
      address: this.fb.group({
        location_name: new FormControl(location_name, [Validators.required]),
        address_1: new FormControl(address_1, [Validators.required]),
        address_2: new FormControl(address_2),
        address_3: new FormControl(address_3),
        city: new FormControl(city, [Validators.required]),
        state: new FormControl(state, [Validators.required]),
        country: new FormControl(country, [Validators.required]),
        zip: new FormControl(zip, [Validators.required]),
      }),
    });
  }

  onUpdateClientForm() {
    if (this.clientForm.invalid) {
      return;
    }

    this.isClientFormSubmitted = true;

    this.clientService
      .updateClient(this.clientData.id, this.clientForm.value)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((res: ClientDataResponse<ClientCreateResponse>) => {
          this.toastr.success(res?.details?.message);
        }),
        switchMap(() => this.fetchClientDetails(this.clientData.id))
      )
      .subscribe(() => {
        this.clientForm.reset();
        this.isClientFormSubmitted = false;
        this.editDropdownMenu = false;
      });
  }

  permissions = [
    {
      name: 'Case',
      data: [
        { permission: 'Create/Reopen Cases', hasPermission: false },
        { permission: 'Edit Cases', hasPermission: false },
        { permission: 'Modify budget on cases', hasPermission: false },
        { permission: 'Modify due date on cases', hasPermission: false },
        { permission: 'View "Non Active" Cases', hasPermission: true },
        { permission: 'View budget on cases', hasPermission: false },
        { permission: 'View Cases', hasPermission: true },
        {
          permission: 'View client/contact info on cases',
          hasPermission: true,
        },
        { permission: 'View investigator info on cases', hasPermission: false },
        { permission: 'View manager info on cases', hasPermission: true },
        { permission: 'View Related Cases', hasPermission: false },
      ],
    },
    {
      name: 'Case Request',
      data: [
        { permission: 'Create/Reopen Cases', hasPermission: false },
        { permission: 'Edit Cases', hasPermission: false },
        { permission: 'Modify budget on cases', hasPermission: false },
        { permission: 'Modify due date on cases', hasPermission: false },
        { permission: 'View "Non Active" Cases', hasPermission: true },
        { permission: 'View budget on cases', hasPermission: false },
        { permission: 'View Cases', hasPermission: true },
        {
          permission: 'View client/contact info on cases',
          hasPermission: true,
        },
        { permission: 'View investigator info on cases', hasPermission: false },
        { permission: 'View manager info on cases', hasPermission: true },
        { permission: 'View Related Cases', hasPermission: false },
      ],
    },

    {
      name: 'Case Update',
      data: [
        { permission: 'Create/Reopen Cases', hasPermission: false },
        { permission: 'Edit Cases', hasPermission: false },
        { permission: 'Modify budget on cases', hasPermission: false },
        { permission: 'Modify due date on cases', hasPermission: false },
        { permission: 'View "Non Active" Cases', hasPermission: true },
        { permission: 'View budget on cases', hasPermission: false },
        { permission: 'View Cases', hasPermission: true },
        {
          permission: 'View client/contact info on cases',
          hasPermission: true,
        },
        { permission: 'View investigator info on cases', hasPermission: false },
        { permission: 'View manager info on cases', hasPermission: true },
        { permission: 'View Related Cases', hasPermission: false },
      ],
    },
    {
      name: 'Dashboard',
      data: [
        { permission: 'Customize Dashboard', hasPermission: false },
        { permission: 'View Dashboard', hasPermission: false },
      ],
    },

    {
      name: 'General Permissions',
      data: [
        { permission: 'Edit My Profile', hasPermission: false },
        { permission: 'View My Profile', hasPermission: false },
        { permission: 'View Redacted Field Data', hasPermission: false },
      ],
    },

    {
      name: 'Invoice Permissions',
      data: [{ permission: 'View Invoices & Retainers', hasPermission: false }],
    },
  ];

  clients = [
    { value: '', label: 'Select a Client', disabled: true },
    { value: '381', label: '1 AUDIO VISUAL' },
    { value: '582', label: 'A Randall & Quilter Group Company, Inc.' },
    { value: '23', label: 'AARLA - American All Risk Loss Administrators' },
    { value: '521', label: 'Accelerated Claims Services' },
    { value: '717', label: 'Accredited.' },
    { value: '27', label: 'ACM - American Claims Management', selected: true },
    { value: '1163', label: 'ACWA JPIA' },
    { value: '926', label: 'AdminSure Inc.' },
    { value: '431', label: 'Advantage Surveillance' },
  ];

  locations = [
    { value: '', label: 'Select a Location', disabled: true },
    { value: '381', label: '1 AUDIO VISUAL' },
    { value: '582', label: 'A Randall & Quilter Group Company, Inc.' },
    { value: '23', label: 'AARLA - American All Risk Loss Administrators' },
    { value: '521', label: 'Accelerated Claims Services' },
    { value: '717', label: 'Accredited.' },
    { value: '27', label: 'ACM - American Claims Management', selected: true },
    { value: '1163', label: 'ACWA JPIA' },
    { value: '926', label: 'AdminSure Inc.' },
    { value: '431', label: 'Advantage Surveillance' },
  ];

  selectOptions = [
    {
      label: 'AmTrust NA - AARLA (Fresno)',
      options: [
        { value: '3181', text: 'Budke, Sang' },
        { value: '3148', text: 'Cruz, Juanita' },
        { value: '2614', text: 'Gomez, Maria' },
        { value: '2616', text: 'Howell, Cassie' },
        { value: '2676', text: 'Jacobs, Brandi' },
        { value: '2891', text: 'Knox, Kristen' },
        { value: '3398', text: 'Miller, Marlee' },
        { value: '2747', text: 'Ojeda, Lizzette' },
        { value: '2784', text: 'Soares, Tamara' },
        { value: '3029', text: 'Tellefson, Lora' },
        { value: '2782', text: 'White, Andrew' },
      ],
    },
    {
      label: 'AmTrust NA - Atlanta WC',
      options: [{ value: '3355', text: 'Galabiz, Bernita' }],
    },
    {
      label: 'AmTrust NA - Buffalo',
      options: [
        { value: '2885', text: 'Dobosz, Gabrielle' },
        { value: '2817', text: 'Murphy, Elizabeth' },
      ],
    },
    {
      label: 'AmTrust NA - Chicago WC',
      options: [
        { value: '2974', text: 'Leondiris, James' },
        { value: '3081', text: "O'Brien, Craig" },
        { value: '2866', text: 'Tellefsen, Lora' },
        { value: '3228', text: 'Williams, Matthew' },
      ],
    },
    {
      label: 'AmTrust NA - Complex Claims',
      options: [
        { value: '2855', text: 'Cowley, Vicky' },
        { value: '3098', text: 'Evans, Trudy' },
        { value: '3129', text: 'Fillar, Andrea' },
        { value: '3345', text: 'Lam, Yan' },
        { value: '3070', text: 'Nicholson, Jennifer' },
        { value: '3260', text: 'Paris, Jamil' },
        { value: '2933', text: 'Rivera, Chester' },
        { value: '3035', text: 'Rockwell, Alicia' },
        { value: '3266', text: 'Wing, Scott' },
      ],
    },
    {
      label: 'AmTrust NA - Concord WC',
      options: [
        { value: '2723', text: 'Allen, Alarice' },
        { value: '3143', text: 'Allen, Brenda' },
        { value: '2729', text: 'Anderson, Mark' },
        { value: '2713', text: 'Ankton, Vincent' },
        { value: '3274', text: 'Arriola, Maria' },
        { value: '2720', text: 'Bolden, Pam' },
        { value: '3571', text: 'Boyle, Devin' },
        { value: '2902', text: 'Brown, Tamra' },
        { value: '2677', text: 'Castaneda, Gracia' },
        { value: '2928', text: 'Castillo, Kristina' },
        { value: '3338', text: 'Cerda, Michelle' },
        { value: '2738', text: 'Dawson, Nicole' },
        { value: '2718', text: 'Diaz, David' },
        { value: '2741', text: 'Eberwein, Sue' },
        { value: '3520', text: 'Eliares, Gian' },
        { value: '3397', text: 'Embers, Michelle' },
        { value: '2728', text: 'Evans, Brian' },
        { value: '2722', text: 'Farias, Kayla' },
        { value: '2637', text: 'Filice, Mark' },
        { value: '3213', text: 'Fulgham, Keia' },
        { value: '2635', text: 'Giantvalley, Mark' },
        { value: '3251', text: 'Grable, Gizelle' },
        { value: '2732', text: 'Green, Marlon' },
        { value: '3330', text: 'Jordan, Rachel' },
        { value: '2768', text: 'Kinsey, Gail' },
        { value: '2861', text: 'Lucas, Barbara' },
        { value: '3125', text: 'Marson, Achiles' },
        { value: '2618', text: 'Martin, Dana' },
        { value: '3328', text: 'Mauney, Marcus' },
        { value: '3420', text: 'Milstead, Connor' },
        { value: '3616', text: 'Miravet, Merry' },
        { value: '2694', text: 'Munroe, Victor' },
        { value: '2679', text: 'Murphey, Jennifer' },
        { value: '2981', text: 'Murphy, Jennifer' },
        { value: '2699', text: 'Nieva, Susanna' },
        { value: '2932', text: 'Olson, Dale' },
        { value: '2639', text: 'Opp, Rene' },
        { value: '2707', text: 'Padua, Cristie' },
        { value: '3549', text: 'Plagenza, Tiffany' },
        { value: '3038', text: 'Ruan, Becky' },
        { value: '2680', text: 'Saldano, Leslie' },
        { value: '2877', text: 'Santos, Imelda' },
        { value: '3221', text: 'Serafin, Kimberly' },
        { value: '2958', text: 'Sharma, Vinay' },
        { value: '2956', text: 'Van Note, Evelyn' },
        { value: '3539', text: 'Ventus, Gayle' },
        { value: '2622', text: 'Vicari, Lisa' },
        { value: '2923', text: 'Vigil, Daniel' },
        { value: '2706', text: 'Vinecour, Greg' },
        { value: '2673', text: 'Whitmore, Adriel' },
        { value: '2943', text: 'Wong, Doug' },
        { value: '3208', text: 'Xiong, Bee' },
        { value: '2675', text: 'Zaragoza, Salma' },
      ],
    },
    {
      label: 'AmTrust NA - CorePointe',
      options: [
        { value: '3002', text: 'DeLand, Jeff' },
        { value: '3001', text: 'Smith, Paige' },
      ],
    },
    {
      label: 'AmTrust NA - Covina WC',
      options: [
        { value: '3049', text: 'Africa, Manuel' },
        { value: '2863', text: 'Burrola, Rob' },
        { value: '2749', text: 'Lopez, Anna' },
        { value: '2854', text: 'Omole, Femi' },
      ],
    },
    {
      label: 'AmTrust NA - Dallas WC/CPP',
      options: [
        { value: '2879', text: 'Abazie, Abigail', selected: true },
        { value: '3239', text: 'Boyd, Sheila' },
        { value: '3588', text: 'Carter, Dwight' },
        { value: '2620', text: 'Duggan, Alexandra' },
        { value: '3315', text: 'Duper, Maurice' },
        { value: '3484', text: 'Ellis, Crystal' },
        { value: '2631', text: 'Farris, Audra' },
        { value: '2688', text: 'Garcia, Bobbi' },
        { value: '2840', text: 'Garcia-Meier, Sylvia' },
        { value: '2775', text: 'Henson, Sarah' },
        { value: '2917', text: 'Hunter, TaJuana' },
        { value: '2944', text: 'Jordan, Damian' },
        { value: '2727', text: 'McNaughton, Gaylen' },
        { value: '3057', text: 'Miller, Kara' },
        { value: '3276', text: 'Sitler, Mark' },
        { value: '3145', text: 'Smith, Kimberly' },
        { value: '3619', text: 'Stewart, Amy' },
        { value: '2692', text: 'Windsor, Olivia' },
      ],
    },
    {
      label: 'AmTrust NA - Home',
      options: [
        { value: '2920', text: 'Bowden, Jessie' },
        { value: '2717', text: 'Bowers, Tasha' },
        { value: '2945', text: 'Crews, Yvette' },
        { value: '3608', text: 'Douglas, Aimee' },
        { value: '2936', text: 'Elder, Russell' },
        { value: '2734', text: 'Fischer, Jennifer' },
        { value: '2934', text: 'Griffith, April' },
        { value: '2954', text: 'Hopkins, Andrew' },
        { value: '2845', text: 'James, Monique' },
        { value: '2621', text: 'Marin, Sam' },
        { value: '2619', text: 'Murray, Patrick' },
        { value: '2832', text: 'Parker, Meeka' },
        { value: '2630', text: 'Stewart, Kenneth' },
        { value: '2615', text: 'Thompson, Stephen' },
        { value: '2617', text: 'Turner, LaTasha' },
        { value: '2674', text: 'Warner, Erin' },
      ],
    },
    {
      label: 'AmTrust NA - Los Angeles WC',
      options: [
        { value: '3133', text: 'Baird, David' },
        { value: '2844', text: 'Calhoun, Rachael' },
        { value: '2790', text: 'Miller, Mike' },
        { value: '2757', text: 'Quinton, J' },
        { value: '3199', text: 'Rodriguez, Maribel' },
        { value: '2821', text: 'Santos, Jose' },
      ],
    },
    {
      label: 'AmTrust NA - NY Office',
      options: [
        { value: '2705', text: 'Castillo, R' },
        { value: '2808', text: 'Cox, S' },
        { value: '2693', text: 'Hedlund, J' },
        { value: '2860', text: 'Holt, J' },
        { value: '2746', text: 'Hudson, T' },
        { value: '2613', text: 'Hudson, Tom' },
        { value: '2636', text: 'Marques, C' },
        { value: '2987', text: 'Parras, S' },
        { value: '2755', text: 'Pettis, K' },
        { value: '2883', text: 'Sweeney, B' },
        { value: '3055', text: 'Urbano, K' },
        { value: '2800', text: 'Vera, D' },
        { value: '3144', text: 'Williams, J' },
      ],
    },
    {
      label: 'AmTrust NA - Orlando',
      options: [
        { value: '3580', text: 'Leewright, Mark' },
        { value: '2939', text: 'Bolejack, Alan' },
        { value: '2735', text: 'Rodriguez, Joe' },
      ],
    },
    {
      label: 'AmTrust NA - Regional WC',
      options: [
        { value: '2894', text: 'Adams, Craig' },
        { value: '3323', text: 'Cook, James' },
        { value: '3003', text: 'Ecker, Kevin' },
        { value: '2913', text: 'Ferguson, T' },
        { value: '3119', text: 'Friedman, Richard' },
        { value: '3118', text: 'Fry, Kenneth' },
        { value: '2895', text: 'Garnett, Danny' },
        { value: '3054', text: 'Gonzalez, Tony' },
        { value: '2764', text: 'Harris, Richard' },
        { value: '2678', text: 'Hess, C' },
        { value: '2892', text: 'Hurst, Patrick' },
        { value: '2731', text: 'Jernigan, C' },
        { value: '2847', text: 'Morris, K' },
        { value: '3090', text: "O'Leary, Bill" },
        { value: '2875', text: 'Parker, T' },
        { value: '3101', text: 'Reed, S' },
        { value: '2701', text: 'Richardson, E' },
        { value: '2942', text: 'Santos, F' },
        { value: '2904', text: 'Smith, B' },
        { value: '2610', text: 'Smith, James' },
        { value: '2778', text: 'Stewart, D' },
        { value: '2922', text: 'Sullivan, M' },
        { value: '2859', text: 'Sweeney, R' },
        { value: '2672', text: 'Taylor, C' },
        { value: '3024', text: 'Thompson, J' },
        { value: '3000', text: 'Toombs, J' },
        { value: '2711', text: 'Vance, K' },
        { value: '3280', text: 'Williams, T' },
      ],
    },
    {
      label: 'AmTrust NA - SCA',
      options: [
        { value: '2684', text: 'Levine, Daniel' },
        { value: '3240', text: 'White, Ben' },
      ],
    },
    {
      label: 'AmTrust NA - Specialty WC',
      options: [
        { value: '2666', text: 'Burke, Dawn' },
        { value: '3078', text: 'Fleming, Derek' },
        { value: '3352', text: 'Fleming, Kara' },
        { value: '3388', text: 'Kelly, William' },
        { value: '2791', text: 'Peavy, Gregory' },
        { value: '2849', text: 'Peavy, Jay' },
        { value: '2780', text: 'Spurrier, John' },
      ],
    },
  ];

  caseType = [
    { value: '', label: 'Select a Case Type', disabled: true },
    { value: '381', label: '1 AUDIO VISUAL' },
    { value: '582', label: 'A Randall & Quilter Group Company, Inc.' },
    { value: '23', label: 'AARLA - American All Risk Loss Administrators' },
    { value: '521', label: 'Accelerated Claims Services' },
    { value: '717', label: 'Accredited.' },
    { value: '27', label: 'ACM - American Claims Management', selected: true },
    { value: '1163', label: 'ACWA JPIA' },
    { value: '926', label: 'AdminSure Inc.' },
    { value: '431', label: 'Advantage Surveillance' },
  ];

  assignMyself = [
    { value: '', label: 'Select a Assign My', disabled: true },
    { value: '381', label: '1 AUDIO VISUAL' },
    { value: '582', label: 'A Randall & Quilter Group Company, Inc.' },
    { value: '23', label: 'AARLA - American All Risk Loss Administrators' },
    { value: '521', label: 'Accelerated Claims Services' },
    { value: '717', label: 'Accredited.' },
    { value: '27', label: 'ACM - American Claims Management', selected: true },
    { value: '1163', label: 'ACWA JPIA' },
    { value: '926', label: 'AdminSure Inc.' },
    { value: '431', label: 'Advantage Surveillance' },
  ];

  toggleCheckbox(permission: any) {
    permission.checked = !permission.checked;
  }

  confirmDelete() {
    console.log('Contact deleted');
    this.modalRef?.hide();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
