import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-predashboard-overlay',
  templateUrl: './predashboard-overlay.component.html',
  styleUrls: ['./predashboard-overlay.component.css']
})
export class PredashboardOverlayComponent implements OnInit {

  @Output() closeDashboardEvent = new EventEmitter<any>();

  ifOverlay: boolean;
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  closeDashboard(event: Event): void {
    this.closeDashboardEvent.emit(event);
  }

  showAnalytics(): void {

    this.ifOverlay = !this.ifOverlay;
  }

  gotoScrub(): void {
    this.closeDashboardEvent.emit(false);
    this.router.navigate(['/scrub']);
  }

  gotoSpear(): void{
    this.closeDashboardEvent.emit(false);
    this.router.navigate(['/']);
  }
}
