<div class="container-fluid p-0" *blockUI="'provider-screen'">
    <div class="row">
        <div class="col-md-12">
            <div class="w-100 pt-4">
                <div class="row mb-2 mb-xl-3 justify-content-between align-items-center">
                    <div class="col-auto">
                        <h3 class="page-title">Merge</h3>
                    </div>
                    <div class="col-auto btnGrp" *ngIf="!isAnyChecked()">
                        <button class="btn btn-secondary-outline bg-white text-voilet p-2 ml-2"
                            [ngClass]="{'active': allRecordsFilter}" (click)="applyAllRecordsFilter()">All
                            Records</button>
                        <button class="btn btn-secondary-outline bg-white text-voilet p-2 ml-2"
                            [ngClass]="{'active': newRecordFilter}" (click)="applyNewRecordFilter()">New Record</button>
                        <button class="btn bg-voilet text-white px-3 py-2 ml-2" (click)=openSidebar()><em
                                class="fa fa-cog"></em></button>
                    </div>
                </div>
                <div class="position-relative">
                    <!-- Tabs Start Here -->
                    <ngb-tabset [destroyOnHide]="false" class="tableTabs" (tabChange)="beforeChange($event)">
                        <ngb-tab *ngFor="let tab of providerTypeTabs" title="{{tab.title}}" id="tab_{{tab.id}}"
                            (click)="resetCheckBoxes()">
                            <ng-template ngbTabContent>
                                <div class="table-row cnstr-record product-tbl">
                                    <table class="table providerTable">
                                        <caption>Data Table</caption>
                                        <thead>
                                            <tr>
                                                <th class="border-0" scope="col">
                                                    <div class="custom-control custom-checkbox checkbox-custom">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="customCheck"
                                                            (change)="CheckAllOptions($event.target.checked)"
                                                            [checked]="isAllChecked()">
                                                        <label
                                                            class="custom-control-label control-custom-label cursor-pointer"
                                                            for="customCheck"></label>
                                                    </div>
                                                </th>
                                                <th class="border-0" scope="col" sortable="provider_name_person" (sort)="onSort($event)">Name</th>
                                                <th class="border-0" scope="col" sortable="provider_alias_person" (sort)="onSort($event)">Alias</th>
                                                <th class="border-0" scope="col" sortable="spear_id_prefix" (sort)="onSort($event)">Source</th>
                                                <th class="border-0" scope="col" sortable="provider_full_address" (sort)="onSort($event)">Practice Address</th>
                                                <th class="border-0" scope="col" sortable="entity_type_code" (sort)="onSort($event)">Provider Type</th>
                                                <th class="border-0" scope="col" sortable="provider_npi_number" (sort)="onSort($event)">NPI</th>
                                                <th class="border-0" scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <!-- for empty tr-->
                                        <tr class="bg-white" *ngIf="!providers || providers.length === 0">
                                            <td colspan="12" class="text-center">No Records Found</td>
                                        </tr>
                                        <tbody
                                            *ngFor="let provider of providers; index as i">
                                            <!-- for filled tr -->
                                            <tr class="bg-white">
                                                <td class="primary-text font-weight-semibold">
                                                    <div
                                                        class="custom-control custom-checkbox checkbox-custom align-items-center">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="customCheck1_{{i}}" [(ngModel)]="provider.isSelected"
                                                            [value]="provider.value" (click)="Checked(provider.spear_id, $event)"
                                                            joyrideStep="firstStep" (done)="onDone()"
                                                            title="Select to Merge" [stepContent]="customContentStep1">
                                                        <label
                                                            class="custom-control-label control-custom-label cursor-pointer"
                                                            for="customCheck1_{{i}}"></label>
                                                        <ng-template #customContentStep1>
                                                            <p>Click on more than one checkboxes to merge</p>
                                                            <div class="custom-control custom-checkbox checkbox-custom d-flex align-items-center">
                                                                <input type="checkbox" class="custom-control-input" id="stopGuidance1"
                                                                    (change)="stopGuidance('User1')" [(ngModel)]="isMasterSelFirst">
                                                                <label class="custom-control-label control-custom-label cursor-pointer"
                                                                    for="stopGuidance1">Do not show this message again
                                                                </label>
                                                            </div>
                                                        </ng-template>
                                                    </div>
                                                </td>

                                                <td class="cursor-pointer primary-text font-weight-semibold"
                                                    *ngIf="isFieldVisible('ProviderId')"> <span
                                                        [routerLink]="['/profile-report', provider.spear_id]"
                                                        [innerHTML]="(provider.provider_name_person ? provider.provider_name_person : '&#8212;')"></span>
                                                </td>
                                                <td class="table-max-width" [innerHTML] ="(provider.provider_alias_person ? provider.provider_alias_person : '&#8212;')"></td>
                                                <td [innerHTML]="(provider.spear_id_prefix ? provider.spear_id_prefix : '&#8212;')"></td>
                                                <td class="table-max-width" [innerHTML] = "(provider.provider_full_address ? provider.provider_full_address : '&#8212;')"></td>
                                                <td class="table-max-width">
                                                    <span *ngIf="provider.entity_type_code == 1.0">Person</span>
                                                    <span *ngIf="provider.entity_type_code == 2.0">Business</span>
                                                    <span *ngIf="provider.entity_type_code == 0 || provider.entity_type_code == '' || provider.entity_type_code == null">Entity</span>
                                                </td>
                                                <td [innerHTML] = "(provider.provider_npi_number ? provider.provider_npi_number : '&#8212;')"></td>
                                                <td>
                                                    <button class="bg-transparent align-middle border-0" placement="top"
                                                        ngbTooltip="Smart Merge" routerLink="/providers/smart-merge"><em
                                                            class="fa fa-compress"></em></button>

                                                    <button class="bg-transparent border-0" placement="top"
                                                        ngbTooltip="Affiliation" (click)="openModalAffiliate()"><img
                                                            src="assets/images/network.png" alt=""></button><button
                                                        class="bg-transparent border-0" placement="top"
                                                        ngbTooltip="Exclusion"><img src="assets/images/remove-user.png"
                                                            alt=""></button><button class="bg-transparent border-0"
                                                        placement="top" ngbTooltip="Providers with same license number"
                                                        (click)="openModalMerge()"><img
                                                            src="assets/images/link-group.png" alt=""></button>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="d-flex justify-content-between px-0 py-2 paginationWrapper"
                                        *ngIf="!(!providers || providers.length === 0)">
                                        <ngb-pagination [(collectionSize)]="providerSize" [(page)]="page"
                                            [pageSize]="providers.length" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"
                                            (pageChange)="onPageChange($event)">
                                        </ngb-pagination>
                                        <div class="selected d-flex align-items-center">
                                            <!-- <select class="custom-select table-select" style="width: auto"
                                                [(ngModel)]="pageSize">
                                                <option [ngValue]="10">10 </option>
                                                <option [ngValue]="20" *ngIf="providers.length>10">20 </option>
                                                <option [ngValue]="50" *ngIf="providers.length>30">50 </option>
                                                <option [ngValue]="100" *ngIf="providers.length>50">100 </option>
                                            </select> -->
                                            <p class="mb-0 ml-2">Showing {{ (((pageIndex + 1) * providers.length) - providers.length) + 1 }} - {{ (pageIndex + 1) * providers.length }}
                                                of {{ pageSize }}</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                    <div class="position-absolute right-0 top-0 d-flex">
                        <button *ngIf="isAnyChecked() || delayOver"
                            class="btn btn-secondary-outline bg-white text-voilet p-2 mr-2  btn-sm"
                            (click)="applyMerge()" joyrideStep="secondStep" (done)="onDone()" title="Click on Merge"
                            [stepContent]="customContentStep2">Merge
                        </button>
                        <ng-template #customContentStep2>
                            <p>Simply click on the Merge button to merge the selected profiles</p>
                            <div class="custom-control custom-checkbox checkbox-custom d-flex align-items-center">
                                <input type="checkbox" class="custom-control-input" id="stopGuidance2"
                                    (change)="stopGuidance()" [(ngModel)]="isMasterSelFirst">
                                <label class="custom-control-label control-custom-label cursor-pointer"
                                    for="stopGuidance2">Do not show this message again
                                </label>
                            </div>
                        </ng-template>
                        <div ngbDropdown>
                            <button class="btn btn--primary-outline primary-text btn-font" id="dropdownBasic1"
                                ngbDropdownToggle>{{manageTemplatesService?.SelectedTemplate?.templateName || 'Default
                                View'}}</button>
                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropDown-top px-0 btn-font">
                                <perfect-scrollbar class="viewScroll px-2">
                                    <div *ngFor="let t of userTemplates.responseData">
                                        <button ngbDropdownItem class="px-2"
                                            (click)="applyTemplate(t.templateId)">{{t.templateName}}</button>
                                        <div class="dropdown-divider"></div>
                                    </div>
                                    <button ngbDropdownItem class="px-2" (click)="applyTemplate(0)">Default
                                        View</button>
                                </perfect-scrollbar>
                            </div>
                        </div>
                        <button class="btn viewBtn bg-voilet text-white px-2 py-1 ml-2" data-target="manageTemplate"
                            (click)="openTemplate()"><em class="fa fa-pencil-square-o"></em> </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="right-sidebar" [ngClass]="setClasses">
    <button class="btn d-block ml-auto btnclose text-white rounded-circle p-0" (click)="closeSidebar()">
        <em class="fa fa-close"></em>
    </button>
    <app-right-sidebar (closeRightSideBar)="closeSidebar()" (getTemplates)="updateTemplateListAndManagelist()">
    </app-right-sidebar>
</div>

<!--Manage template Modal -->
<div [ngClass]="classSet" class="modal fade" id="manageTemplate" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <app-filter-templates (closeManageModel)="closeTemplate()" (refreshTemplates)="getTemplate()">
    </app-filter-templates>

</div>