import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  DepartmentCreateResponse,
  DepartmentDataResponse,
  DepartmentName,
  DepartmentPaginatedData,
  ReorderReq,
} from 'src/app/models/department/department.interface';
import { DepartmentService } from 'src/app/services/department.service';

@Component({
  selector: 'app-department-name',
  templateUrl: './department-name.component.html',
  styleUrls: ['./department-name.component.css'],
})
export class DepartmentNameComponent implements OnInit {
  departmentNameForm: FormGroup;
  isdepartmentNameFormSubmitted = false;
  departmentLimit = 20;
  departmentPage = 1;
  departmentTotal: number;
  departmentNames: DepartmentName[];
  @BlockUI('department-list-ui') departmentListBlockUI: NgBlockUI;
  private unsubscribe$ = new Subject<void>();
  editDepartmentNameData: DepartmentName;

  constructor(
    private addmodalService: NgbModal,
    private fb: FormBuilder,
    private departmentService: DepartmentService,
    private toastr: ToastrService
  ) {
    this.initdepartmentNameForm();
  }

  ngOnInit() {
    this.getDepartmentNames();
  }

  openPopup(content, size = 'md') {
    this.addmodalService.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size,
      centered: true,
    });
  }

  initdepartmentNameForm() {
    this.departmentNameForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      tag: new FormControl(''),
      notes: new FormControl(''),
    });
  }

  onSubmitdepartmentNameForm() {
    if (this.departmentNameForm.invalid) {
      return;
    }

    this.isdepartmentNameFormSubmitted = true;

    if (this.editDepartmentNameData?.id) {
      this.updateDepartmentName();
    } else {
      this.saveDepartmentName();
    }
  }

  saveDepartmentName() {
    this.departmentService
      .createDepartmentName(this.departmentNameForm.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: DepartmentDataResponse<DepartmentCreateResponse>) => {
          this.toastr.success(res?.details?.message);
          this.addmodalService.dismissAll();
          this.departmentNameForm.reset();
          this.isdepartmentNameFormSubmitted = false;
          this.getDepartmentNames();
        },
        (error) => {
          this.toastr.success(error?.errors[0]);
        }
      );
  }

  updateDepartmentName() {
    this.departmentService
      .updateDepartmentName(
        this.editDepartmentNameData.id,
        this.departmentNameForm.value
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: DepartmentDataResponse<DepartmentCreateResponse>) => {
          this.toastr.success(res?.details?.message);
          this.addmodalService.dismissAll();
          this.departmentNameForm.reset();
          this.isdepartmentNameFormSubmitted = false;
          this.getDepartmentNames();
          this.editDepartmentNameData = null;
        },
        (error) => {
          this.toastr.success(error?.errors[0]);
        }
      );
  }

  getDepartmentNames() {
    this.departmentListBlockUI.start();
    this.departmentService
      .getDepartmentNames(this.departmentLimit, this.departmentPage)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (
          resp: DepartmentDataResponse<DepartmentPaginatedData<DepartmentName>>
        ) => {
          const { hits, offset, estimatedTotalHits } = resp.details;
          this.departmentNames = hits;
          this.departmentTotal = estimatedTotalHits;
          this.departmentListBlockUI.stop();
        },
        (error) => {
          this.departmentListBlockUI.stop();
        }
      );
  }

  onPageChangedepartments(event: any) {
    this.departmentPage = event;
    this.getDepartmentNames();
  }

  onSizeChangedepartments(limit: number) {
    this.departmentLimit = limit;
    this.departmentPage = 1;
    this.getDepartmentNames();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  editDepartmentName(createDepartment, departmentName: DepartmentName) {
    this.openPopup(createDepartment, 'md');
    const { name, tag, notes } = departmentName;
    this.editDepartmentNameData = departmentName;
    this.departmentNameForm = new FormGroup({
      name: new FormControl(name),
      tag: new FormControl(tag),
      notes: new FormControl(notes),
    });
  }

  deleteDepartmentName(id: number) {
    if (confirm('Are you sure you want to delete this department name?')) {
      this.departmentService.deleteDepartmentName(id).subscribe(
        (res: DepartmentDataResponse<DepartmentCreateResponse>) => {
          this.toastr.success(res?.details?.message);
          this.getDepartmentNames();
        },
        ({ error }: HttpErrorResponse) => {
          this.toastr.error(error?.errors[0]);
        }
      );
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.departmentNames,
      event.previousIndex,
      event.currentIndex
    );
    this.reOrderRows();
  }

  reOrderRows() {
    const req: ReorderReq = {
      order_list: this.departmentNames.map((name) => name.id),
    };
    this.departmentService.reOrderRow(req, 'names').subscribe(
      (res: DepartmentDataResponse<DepartmentCreateResponse>) => {
        this.toastr.success(res?.details?.message);
        this.getDepartmentNames();
      },
      ({ error }: HttpErrorResponse) => {
        this.toastr.error(error?.errors[0]);
      }
    );
  }
}
