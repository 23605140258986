<div class="container-fluid p-0 invoice-wrapper">
  <div class="row">
    <div class="col-md-12">
      <div
        class="text-sm w-100 m-2"
        *ngIf="invoiceType !== 'new' && !limosInvoiceData.invoice_id"
      >
        <a
          class="text-gray"
          [routerLink]="[
            '/limos',
            limosInvoiceData.claim_number,
            limosInvoiceData.profile_id
          ]"
          ><u>{{ limosInvoiceData.profile_id }}</u></a
        >
        <i class="fa fa-angle-double-right mx-1"></i> Edit
      </div>
      <div class="card border-0">
        <div class="card-body search-limos-background mb-3">
          <h5>
            {{
              limosInvoiceData && limosInvoiceData.invoice_id ? "Edit" : "New"
            }}
            Invoice
          </h5>
          <div>
            <form>
              <div
                class="row mt-4 mb-1 d-flex align-items-center"
                *ngIf="limosInvoiceData && limosInvoiceData.invoice_number"
              >
                <div class="col-2 text-right text-dark-bold text-sm pr-0">
                  <span class="text-danger">*</span> Invoice Number:
                </div>
                <div class="col-10">
                  <input
                    type="text"
                    name="invoiceNumber"
                    class="form-control pl-1 w-25"
                    [disabled]="true"
                    [value]="limosInvoiceData.invoice_number"
                  />
                </div>
              </div>

              <div class="row mb-1 d-flex align-items-center">
                <div class="col-2 text-right text-dark-bold text-sm pr-0">
                  <span class="text-danger">*</span> Created On:
                </div>
                <div class="col-10">
                  <input
                    type="text"
                    name="createdOn"
                    class="form-control pl-1 w-25"
                    [(ngModel)]="limosInvoiceData.created_on"
                    jDate
                  />
                </div>
              </div>

              <div class="row mb-3 d-flex align-items-center">
                <div class="col-2 text-right text-dark-bold text-sm pr-0">
                  <span class="text-danger">*</span> Due On:
                </div>
                <div class="col-10">
                  <input
                    type="text"
                    name="dueOn"
                    class="form-control pl-1 w-25"
                    [(ngModel)]="limosInvoiceData.due_on"
                    jDate
                  />
                </div>
              </div>
              <hr />
              <div class="row mb-1 mt-3 d-flex align-items-center">
                <div class="col-2 text-right text-dark-bold text-sm pr-0">
                  <span class="text-danger">*</span> Bill To:
                </div>
                <div class="col-10">
                  <input
                    type="text"
                    name="billingTo"
                    class="form-control pl-1 w-25"
                    [(ngModel)]="limosInvoiceData.billing_to"
                    [ngbTypeahead]="searchClient"
                    [resultTemplate]="clientSearchTemplate"
                    [inputFormatter]="clientSearchFormatter"
                    (ngModelChange)="onClientSelect($event)"
                  />
                  <div
                    *ngIf="clientSearchLoader"
                    class="clientSearchLoader"
                  ></div>
                  <ng-template
                    #clientSearchTemplate
                    let-r="result"
                    let-t="term"
                  >
                    <div [term]="t">
                      {{ r.client_name }}
                    </div>
                  </ng-template>
                </div>
              </div>

              <div class="row mb-1 d-flex align-items-center">
                <div class="col-2 text-right text-dark-bold text-sm pr-0">
                  <span class="text-danger">*</span> Location:
                </div>
                <div class="col-10">
                  <input
                    type="text"
                    name="billingLocation"
                    class="form-control pl-1 w-25"
                    [(ngModel)]="limosInvoiceData.billing_location"
                  />
                </div>
              </div>

              <div class="row mb-1 d-flex align-items-center">
                <div class="col-2 text-right text-dark-bold text-sm pr-0">
                  <span class="text-danger">*</span> Address:
                </div>
                <div class="col-10">
                  <input
                    type="text"
                    name="billingAddress"
                    class="form-control pl-1 w-25"
                    [(ngModel)]="limosInvoiceData.billing_address"
                  />
                </div>
              </div>

              <div class="row mb-3 d-flex align-items-center">
                <div class="col-2 text-right text-dark-bold text-sm pr-0">
                  Contact:
                </div>
                <div class="col-10">
                  <input
                    type="text"
                    name="billingContact"
                    class="form-control pl-1 w-25"
                    [(ngModel)]="limosInvoiceData.billing_contact"
                  />
                </div>
              </div>

              <hr />

              <div class="row mb-3 mt-3 d-flex align-items-center">
                <div class="col-2 text-right text-dark-bold text-sm pr-0">
                  Billing Email:
                </div>
                <div class="col-10">
                  <input
                    type="text"
                    name="billingEmail"
                    class="form-control pl-1 w-25"
                    [(ngModel)]="limosInvoiceData.billing_email"
                  />
                </div>
              </div>

              <hr />

              <div class="row mb-3 mt-3 d-flex align-items-center">
                <div class="col-2 text-right text-dark-bold text-sm pr-0">
                  Case Number:
                </div>
                <div class="col-10">
                  <ng-container
                    *ngIf="invoiceType === 'new'; else caseLinkInput"
                  >
                    <input
                      type="number"
                      name="caseNumber"
                      class="form-control pl-1 w-25"
                      [(ngModel)]="limosInvoiceData.profile_id"
                    />
                  </ng-container>

                  <ng-template #caseLinkInput>
                    <a
                      [routerLink]="[
                        '/limos',
                        limosInvoiceData.claim_number,
                        limosInvoiceData.profile_id
                      ]"
                      ><u>{{ limosInvoiceData.profile_id }}</u></a
                    >
                  </ng-template>
                </div>
              </div>

              <hr />

              <div class="row mb-1 mt-3 d-flex align-items-center">
                <div class="col-2 text-right pr-0 text-sm text-dark-bold">
                  Claim Number:
                </div>
                <div class="col-10">
                  <input
                    type="number"
                    name="refNo1"
                    [(ngModel)]="limosInvoiceData.claim_number"
                    class="form-control pl-1 w-25"
                  />
                </div>
              </div>

              <div class="row mb-1 d-flex align-items-center">
                <div class="col-2 text-right pr-0 text-sm text-dark-bold">
                  Misc.:
                </div>
                <div class="col-10">
                  <input
                    type="text"
                    name="refNo1"
                    [(ngModel)]="limosInvoiceData.miscellaneous_note"
                    class="form-control pl-1 w-25"
                  />
                </div>
              </div>

              <div class="row mb-1 d-flex align-items-center">
                <div class="col-2 text-right text-dark-bold text-sm pr-0">
                  Claimant Name:
                </div>
                <div class="col-10">
                  <input
                    type="text"
                    name="refNo2"
                    [(ngModel)]="limosInvoiceData.claimant_name"
                    class="form-control pl-1 w-25"
                  />
                </div>
              </div>

              <div class="row mb-4 d-flex align-items-center">
                <div class="col-2 text-right text-dark-bold text-sm pr-0">
                  Provider Name:
                </div>
                <div class="col-10">
                  <input
                    name="provider_name"
                    id="provider_name"
                    class="form-control pl-1 w-25"
                    [(ngModel)]="limosInvoiceData.provider_name"
                  />
                </div>
              </div>

              <div class="row mt-3 mb-0">
                <div class="col-12">
                  <div class="table mb-0" id="lienResTable">
                    <table
                      class="table providerTable limos-table-header-background mb-0"
                    >
                      <thead>
                        <tr>
                          <th class="border-0" width="4%"></th>
                          <th class="border-0" width="8%">Date</th>
                          <th class="border-0" width="12%">Time Type</th>
                          <th class="border-0">User</th>
                          <th class="border-0" width="48%">Time Note</th>
                          <th class="border-0">Time Spent</th>
                          <th class="border-0">Rate</th>
                          <th class="border-0">Total</th>
                          <th class="border-0"></th>
                        </tr>
                      </thead>
                      <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                        <tr *ngIf="!limosInvoiceData.time_spents.length">
                          <td colspan="9" class="text-center bg-white">
                            Data not available
                          </td>
                        </tr>
                        <tr
                          *ngFor="
                            let row of limosInvoiceData.time_spents;
                            let ind = index
                          "
                          cdkDrag
                        >
                          <!-- Drag handle icon -->
                          <td>
                            <span class="drag-handle" cdkDragHandle>
                              <i
                                class="fa fa-arrows-alt text-muted"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </td>
                          <td>
                            <input
                              type="text"
                              name="timeSpentDate-{{ ind }}"
                              class="form-control text-cente text-smr"
                              [meta]="{ item: row, key: 'log_date' }"
                              [(ngModel)]="row.log_date"
                              jDate
                            />
                          </td>
                          <td>
                            <select
                              name="timeType-{{ ind }}"
                              class="form-control text-sm"
                              [(ngModel)]="row.time_type"
                            >
                              <option
                                value=""
                                [selected]="
                                  row.time_type === null || row.time_type === ''
                                "
                              ></option>
                              <option
                                value="{{ opt }}"
                                [selected]="opt == row.time_type"
                                *ngFor="let opt of timeTypes"
                              >
                                {{ opt }}
                              </option>
                            </select>
                          </td>
                          <td class="text-nowrap text-sm">
                            {{ row.user }}
                          </td>
                          <td>
                            <textarea
                              name="timeSpentDesc-{{ ind }}"
                              class="w-100 border rounded pl-1 text-sm"
                              [(ngModel)]="row.note"
                              rows="1"
                              autoResize
                              [maxHeight]="100"
                              >{{ row.note }}</textarea
                            >
                          </td>
                          <td>
                            <input
                              type="number"
                              name="timeSpentValue-{{ ind }}"
                              class="form-control text-center text-sm"
                              [(ngModel)]="row.time_spent"
                              (keyup)="onChangeRate(row)"
                            />
                          </td>
                          <td>
                            <div class="d-flex">
                              <span class="mt-1 text-sm">$</span
                              ><input
                                type="number"
                                name="timeSpentRate-{{ ind }}"
                                class="form-control text-center text-sm"
                                [(ngModel)]="row.rate"
                                (keyup)="onChangeRate(row)"
                              />
                            </div>
                          </td>
                          <td class="text-sm">
                            {{
                              (row.total | decimalPoint | dollarCurrency) ||
                                "$00.00"
                            }}
                          </td>
                          <td>
                            <div class="d-flex">
                              <button
                                class="btn btn-sm"
                                type="button"
                                (click)="
                                  limosInvoiceData.time_spents.splice(ind, 1)
                                "
                              >
                                <em class="fa fas fa-close text-danger"></em>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <hr class="m-0" />

              <div class="row mt-2">
                <div class="col-8">
                  <button
                    class="btn btn-sm btn-secondary"
                    (click)="addLienItem()"
                  >
                    <em class="fa fas fa-plus"></em> Add Line Item
                  </button>
                </div>
                <div class="col-4">
                  <div class="row" *ngIf="limosInvoiceData.time_spents.length">
                    <div class="col-8 text-right text-dark-bold">Total:</div>
                    <div class="col-4">
                      {{ finalTotalRate | decimalPoint | dollarCurrency }}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div
            class="bg-primary-light text-dark mt-5 py-2 px-2 mb-1 d-flex justify-content-end"
          >
            <div>
              <button
                class="btn btn-sm text-white limos-button-background"
                (click)="saveInvoice()"
              >
                {{ limosInvoiceData.invoice_id ? "Update" : "Save" }} Invoice
              </button>
              or
              <button class="btn btn-sm btn-light" (click)="cancelInvoice()">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
