export class RecordCount {
    constructor() {
        this.totalGroups = 0;
        this.newProviders = 0;
        this.totalProviders = 0;
    }
    totalProviders: number;
    newProviders: number;
    totalGroups: number;
}
