<div class="container-fluid p-0">
    <div class="row">
        <div class="col-md-12 pr-0">
            <div class="w-100 pt-4">
                <div class="row mb-2 mb-xl-3 justify-content-between align-items-center">
                    <div class="col-auto">
                        <h3 class="page-title">Active Investigations</h3>
                        <!-- <p>Here is the all info about the above ID.</p> -->
                    </div>
                    <div class="col-auto btnGrp d-flex pr-0">
                        <div class="input-group input-group-navbar search-bar">
                            <div class="input-group-append">
                                <button class="btn rounded-left search-btn bg-white" type="button" disabled="disabled">
                                    <em class="fa fa-search"></em>
                                </button>
                            </div>
                            <input type="text" class="form-control search-control pl-0 border-0 bg-white" placeholder="Search name...">
                        </div>
                        <div class="col-md-7 pl-0 ml-2">
                            <button class="btn btn-secondary-outline bg-white text-voilet p-2" disabled="disabled">Clean Up Matters</button>
                            <button class="btn btn-secondary-outline bg-white text-voilet p-2 ml-2" (click)="open(content)"><em class="fa fa-plus mr-2"></em>New Matter</button>
                            <button class="btn bg-voilet text-white px-3 py-2 ml-2" disabled="disabled"><em class="fa fa-cog"></em></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="table-row w-100 static-data">
        <table class="table providerTable">
            <caption>Investigation</caption>
            <thead>
                <tr>
                    <th class="border-0 sorting" scope="col">Investigation</th>
                    <th class="border-0 sorting" scope="col">Total Providers</th>
                    <th class="border-0 sorting" scope="col">Created By</th>
                    <th class="border-0 sorting" scope="col">Created</th>
                    <th class="border-0 sorting" scope="col">Updated</th>
                    <th class="border-0" scope="col">Actions</th>
                </tr>
            </thead>
                <tbody>
                    <tr class="active-table bg-white">
                        <td class="primary-text text-uppercase font-weight-semibold">Atlantic Recovery Services</td>
                        <td>25</td>
                        <td>Jane Montgomery . Inc</td>
                        <td>25/07/2018</td>
                        <td>15/05/2008</td>
                        <td class=""><button class="btn px-0" disabled="disabled"><em class="btn-bg rounded-circle fa fa-pencil edit mr-2"></em></button><button class="btn primary-text" (click)="del(deletecontent)"><em class="btn-bg rounded-circle fa fa-trash mr-2 primary-text rounded-circle trash-icon"></em></button></td>
                    </tr>
                    <tr class="bg-white">
                        <td class="primary-text text-uppercase font-weight-semibold">c.p.m</td>
                        <td>12</td>
                        <td>Chris Evans </td>
                        <td>15/05/2008</td>
                        <td>15/05/2008</td>
                        <td class=""><button class="btn px-0" disabled="disabled"><em class="btn-bg rounded-circle fa fa-pencil edit mr-2"></em></button><button class="btn primary-text" (click)="del(deletecontent)"><em class="btn-bg rounded-circle fa fa-trash mr-2 primary-text rounded-circle trash-icon"></em></button></td>
                    </tr>
                    <tr class="bg-white">
                        <td class="primary-text text-uppercase font-weight-semibold">phlb</td>
                        <td>16</td>
                        <td>Mark Ruffalo </td>
                        <td>12/06/2019</td>
                        <td>15/05/2008</td>
                        <td class=""><button class="btn px-0" disabled="disabled"><em class="btn-bg rounded-circle fa fa-pencil edit mr-2"></em></button><button class="btn primary-text" (click)="del(deletecontent)"><em class="btn-bg rounded-circle fa fa-trash mr-2 primary-text rounded-circle trash-icon"></em></button></td>
                    </tr>
                    <tr class="bg-white">
                        <td class="primary-text text-uppercase font-weight-semibold">Landmark</td>
                        <td>15</td>
                        <td>Chris Hemsworth </td>
                        <td>10/08/2020</td>
                        <td>15/05/2008</td>
                        <td class=""><button class="btn px-0" disabled="disabled"><em class="btn-bg rounded-circle fa fa-pencil edit mr-2"></em></button><button class="btn primary-text" (click)="del(deletecontent)"><em class="btn-bg rounded-circle fa fa-trash mr-2 primary-text rounded-circle trash-icon"></em></button></td>
                    </tr>
                    <tr class="bg-white">
                        <td class="primary-text text-uppercase font-weight-semibold">Tri City Reg Hospital</td>
                        <td>45</td>
                        <td>Jeremy Renner  </td>
                        <td>09/09/2020</td>
                        <td>15/05/2008</td>
                        <td class=""><button class="btn px-0" disabled="disabled"><em class="btn-bg rounded-circle fa fa-pencil edit mr-2"></em></button><button class="btn primary-text" (click)="del(deletecontent)"><em class="btn-bg rounded-circle fa fa-trash mr-2 primary-text rounded-circle trash-icon"></em></button></td>
                    </tr>
            </tbody>
        </table>
    </div>
</div>

<!--Create Group Modal -->
<ng-template #content let-modal>
    <div class="modal-header px-0 mb-5">
    <h4 class="modal-title" id="modal-basic-title">Create New Matter</h4>
    <button type="button" class="btn d-block ml-auto btnclose text-gray rounded-circle p-0" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body px-0">
        <form>
            <div class="form-group">
                <label class="subtitle-modal p0">Matter Name</label>
                <input class="form-control mb-3" type="text" value="" (click)="onClick()">
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 p-0 mb-4">
        <button type="button" class="rounded-0 btn text-white primary-bg mb-0 btnApply" [disabled]="isDisabled" (click)="show(content1)">Next</button>
    </div>
</ng-template>

<!--Create Provider Modal -->
<ng-template #content1 let-modal>
    <div class="modal-header px-0 mb-3">
    <h4 class="modal-title" id="modal-basic-title">Select Provider</h4>
    <button type="button" class="btn d-block ml-auto btnclose text-gray rounded-circle p-0" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body px-0">
        <form>
            <div class="form-group">
                <label class="subtitle-modal p0">Providers Name</label>
                <tag-input class="tagChips" [ngModel]="chipsInput" [placeholder]='"Start Typing Provider ID"' [secondaryPlaceholder]='"Start Typing Provider ID"'></tag-input>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 p-0 mb-4">
        <button type="button" class="rounded-0 btn text-white primary-bg mb-0" (click)="modal.close('Save click')">Add to Group</button>
    </div>
</ng-template>

<!--Delete Group Modal -->
<ng-template #deletecontent let-modal>
    <div class="modal-header px-0 pt-4 border-0 text-center row">
        <div class="mb-3 col-md-12">
            <img src="assets/images/del-icon.png" alt="">
        </div>
        <div class="col-md-12">
            <h4 class="modal-title border-0" id="modal-basic-title">Please share the reason why you want to delete group.</h4>
        </div>
    </div>
    <div class="modal-body px-0 text-center">
        <form >
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group mb-4 px-3">
                        <input type="text" id="firstname" class="form-control" placeholder="Type Reason">
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 p-0 mb-4 justify-content-center">
        <button type="button" class="rounded-0 btn btn-secondary-outline bg-white text-voilet mb-0" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" class="rounded-0 btn text-white primary-bg mb-0 ml-3 mr-0" (click)="cnfdel(cnfdeletecontent)">Submit</button>
    </div>
</ng-template>


<!--Confirm Delete Group Modal -->
<ng-template #cnfdeletecontent let-modal>
    <div class="modal-header px-0 pt-4 border-0 text-center row">
        <div class="mb-3 col-md-12">
            <img src="assets/images/delete-cnfrm-icon.png" alt="">
        </div>
        <div class="col-md-12">
            <h4 class="modal-title border-0" id="modal-basic-title">Are you sure you want to delete this group?</h4>
        </div>
    </div>
    <div class="modal-body px-0 text-center">
        <div class="row">
            <div class="col-md-12">
                <p class="mb-4">This action cannot be undone.</p>
            </div>
        </div>
    </div>
    <div class="modal-footer border-0 p-0 mb-4 justify-content-center">
        <button type="button" class="rounded-0 btn btn-secondary-outline bg-white text-voilet mb-0" (click)="modal.close('Save click')">No, Cancel</button>
        <button type="button" class="rounded-0 btn text-white primary-bg mb-0 ml-3 mr-0">Yes, Delete</button>
    </div>
</ng-template>