<!-- Modal -->
<div *blockUI="'show-loader'">
    <div class="modal-header">
        <h4 class="modal-title">Confirm Dissociation</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="flex text-center flex-col w-full">
            <p>Are you sure you want to dissociate this entity?</p>
            <div class="flex">
                <button class="btn primary-bg text-white btnlogin mr-3" (click)="dissociateFinancial(id)">Yes</button>
                <button class="btn bg-orange text-white btnlogin" (click)="activeModal.dismiss('Cross click')">No</button>
            </div>
            
        </div>
    </div>
</div>