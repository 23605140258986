export enum UserType {
   admin= 'admin',
   superAdmin = 'super admin',
   user= 'user',
   investigator = 'investigator'
  }

export enum ProviderListHeadersEnum {
   providerId = 1,
   name= 2,
   type= 3,
   npi= 4,
   license= 5,
   companyName = 6
  }
export enum SearchCriteria {
  Name= 1,
  NPI= 2,
  Address= 3,
  License= 4,
  Company= 5
  }

