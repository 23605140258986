import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  Invoice,
  InvoicePaginationData,
  LimosDataResponse,
} from 'src/app/models/limos/limos.models';
import { LimosService } from 'src/app/services/limos.service';
import { AppUtils } from 'src/app/utilities/app.utils';
import { SortDirection } from '../providers/providers.component';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css'],
})
export class InvoiceListComponent implements OnInit, OnDestroy {
  invoicePage = 1;
  invoiceLimit = 30;
  invoiceTotal: number;
  invoices: Invoice[] = [];
  @Output() invoiceData = new EventEmitter<Invoice[]>();
  @BlockUI('invoice-list')
  invoiceListBlockUI: NgBlockUI;
  private unsubscribe$ = new Subject<void>();
  invoiceListOrderBy = 'id';
  invoiceListOrderDirection: SortDirection = 'asc';

  constructor(
    private limosService: LimosService,
    private appUtils: AppUtils,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getInvoices();
  }

  getInvoices() {
    this.invoiceListBlockUI.start();

    const filterBy = this.activatedRoute.snapshot.params['limos_id'] || '';

    const $invoiceList = this.limosService.getInvoiceList(
      this.invoiceLimit,
      this.invoicePage,
      this.invoiceListOrderBy,
      this.invoiceListOrderDirection,
      filterBy
    );

    $invoiceList.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (resp: LimosDataResponse<InvoicePaginationData>) => {
        const { hits, estimatedTotalHits } = resp.details;
        this.invoices = hits.map((invoice) => {
          return {
            ...invoice,
            total:
              typeof invoice.total === 'string'
                ? parseFloat(invoice.total.replace(/[^0-9.-]+/g, ''))
                : invoice.total,
          };
        });

        this.invoiceTotal = estimatedTotalHits;
        this.invoiceData.emit(this.invoices);

        this.invoiceListBlockUI.stop();
      },
      (error) => {
        console.error('Error fetching invoices:', error);
        this.invoiceListBlockUI.stop();
      }
    );
  }

  onPageChangeinvoices(event: any) {
    this.invoicePage = event;
    this.getInvoices();
  }

  onSizeChangeinvoices(limit: number) {
    this.invoiceLimit = limit;
    this.invoicePage = 1;
    this.getInvoices();
  }

  sortColumn(column: string) {
    this.invoiceListOrderBy = column;
    this.invoiceListOrderDirection =
      this.invoiceListOrderDirection === 'asc' ? 'desc' : 'asc';
    this.getInvoices();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
