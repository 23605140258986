import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { ProvidertablesService } from 'src/app/services/providertables.service';

@Component({
  selector: 'app-dissociate-financial-interest',
  templateUrl: './dissociate-financial-interest.component.html',
  styleUrls: ['./dissociate-financial-interest.component.css']
})
export class DissociateFinancialInterestComponent implements OnInit {

  constructor(
    private _NgbActiveModal: NgbActiveModal, 
    private toastr: ToastrService,
    private http: HttpClient,
    private providertable: ProvidertablesService
  ) { }

  id: string;

  ngOnInit(): void {
  }

  get activeModal() {
    return this._NgbActiveModal;
  }

  dissociateFinancial(id: string){
    this.providertable.dissociateFinancial(id);
    this.activeModal.dismiss();
  }

}
