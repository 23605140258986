import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-under-maintenance',
  templateUrl: './under-maintenance.component.html',
  styleUrls: ['./under-maintenance.component.css']
})
export class UnderMaintenanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
