import { DatePipe, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Directive, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UserInfo } from 'src/app/models/manage-users/user.info';
import { ProviderFilterModel } from 'src/app/models/provider/provider.filter.model';
import { Hit, ProviderSearchReponseMOdel } from 'src/app/models/provider/provider.melie.search.reponse.model';
import { ProviderModel } from 'src/app/models/provider/provider.model';
import { ManageUsersService } from 'src/app/services/manage.users.service';
import { ModalService } from 'src/app/services/modal.service';
import { ProviderService } from 'src/app/services/provider.service';
import { SearchstateService } from 'src/app/services/searchstate.service';
import { AppUtils } from 'src/app/utilities/app.utils';
import { ProviderListHeadersEnum } from 'src/app/utilities/enums';
import { environment } from 'src/environments/environment';
import { ProviderType } from '../providers/providers.component';
import { AssociateComponent } from '../shared/Modals/associate/associate.component';
import { ExportAllCsvComponent } from '../shared/Modals/export-all-csv/export-all-csv.component';

export type SortColumn = keyof ProviderSearchReponseMOdel | '';
export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = { 'asc': 'desc', 'desc': '', '': 'asc' };

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()'
  }
})

export class NgbdSortableHeader {

  @Input() sortable: SortColumn = '';
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit, OnDestroy {
  isSpear: boolean = false;
  isLimos: boolean = false;
  @BlockUI('manage-users-blockUI') blockUI: NgBlockUI;
  closeResult = '';
  users: UserInfo[];
  subsciptions: Subscription[] = [];
  providers: Array<Hit>;
  tableSate: ProviderFilterModel;
  rowCount: number;
  page: number = 1;
  pageIndex: number;
  limit: number;
  providerSize: number;
  maxSize: number = 5;
  searchText: string;
  filteredText: string;
  searchCriteria: string[];
  sortColumn: string;
  isDescending: boolean;
  allRecordsFilter: boolean;
  omitEmptyNameFilter: boolean;
  duplicateNameFilter: boolean;
  newRecordFilter: boolean;
  isSearched: boolean;
  providerType: ProviderType;
  show = false;
  isDisabled = true;
  Bulkaction: any = ['Delete', 'Merge', 'Affiliate', 'Exclude'];
  todayDate: Date = new Date();
  collectionSize = 100;
  provider: ProviderModel[];
  setClasses = new Set();
  dateTimeConfig: any;
  classSet = new Set();
  selectAll = false;
  selectedProviders: string[];
  selectedAssociates = [];
  addressInformation: any = [];
  hideme: Array<{ isOpen: boolean; isLoaded: boolean }> = [];
  index: any;
  emptyForm: boolean = false;
  emptyFormMessage: boolean = false;
  isValidPhone: boolean = false;
  phonePattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  isFilterOn: boolean = true;
  interVal: any;
  stopInterval: boolean;
  response: Response;
  sortData: Array<Hit>;
  activeSortObj: SortEvent;
  form: FormGroup;
  public formValueChangeSubscription: Subscription;
  isClaimNumberSearched: boolean = false;
  topage: number = 100;
  filteredQuery = [];
  searchByInVal: any;
  activeTab = 'SPEAR';
  pageTabs = [
    'SPEAR',
    'LIMOS',
    'ACHILLES',
  ];


  constructor(
    private manageUsersService: ManageUsersService,
    private providerService: ProviderService,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    public appUtils: AppUtils,
    private toastr: ToastrService,
    private modalService: ModalService,
    private modalWindow: NgbModal,
    private route: Router,
    private http: HttpClient,
    private ngbModal: NgbModal,
    private stateService: SearchstateService,
    private location: Location
  ) {
    this.dateTimeConfig = { dateInputFormat: environment.dateTimeFormat.date.toUpperCase() };
    this.providers = new Array<Hit>();
    this.page = 1;
    this.pageIndex = 1;
    this.limit = 50;
    this.searchText = '';
    this.filteredText = '';
    this.searchCriteria = [];
    this.isDescending = false;
    this.omitEmptyNameFilter = false;
    this.duplicateNameFilter = false;
    this.newRecordFilter = false;
    this.providerType = ProviderType.All;
    this.stateService.getSearchByState().subscribe((data) => {
      if (data == 'byspear') {
        this.isSpear = true;
        this.isLimos = false;
        this.isSearched = true;
        this.searchByInVal = 'byspear';
      }
      else if (data == 'bylimos') {
        this.isSpear = false;
        this.isLimos = true;
        this.isSearched = true;
        this.emptyForm = true;
        this.searchByInVal = 'bylimos';
      }
    });
    this.stateService.getSearchState().subscribe((data) => {
      if (data) {
        this.isSearched = true;
        this.selectedProviders = [];
        this.providers = data;
      }
    });
    this.stateService.getProviderSizeState().subscribe((data) => {
      if (data) {
        this.providerSize = data;
      }
    });

  }
  get providerHeaderListEnum(): typeof ProviderListHeadersEnum {
    return ProviderListHeadersEnum;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      searchAll: new FormControl(),
      official_first_name: new FormControl(),
      official_middle_name: new FormControl(),
      official_last_name: new FormControl(),
      name_suffix: new FormControl(),
      tin: new FormControl(),
      streetaddress: new FormControl(),
      city: new FormControl(),
      state: new FormControl(),
      ssn: new FormControl(),
      npi: new FormControl(),
      source: new FormControl(),
      phone: ['', [Validators.pattern(this.phonePattern)]],
      birth_date: new FormControl(),
      adj: new FormControl(),
      insured: new FormControl(),
      insurance_company: new FormControl(),
      claims_admin: new FormControl(),
      provider_type: new FormControl(),
      claim_number: new FormControl(),
      claimant_first_name: new FormControl(),
      claimant_middle_name: new FormControl(),
      claimant_last_name: new FormControl(),
      claimant_name_suffix: new FormControl(),
      adjuster: new FormControl()
    });
    const subscription = this.manageUsersService
      .listenToloadUsers()
      .subscribe(() => {
        this.loadUsers();
      });
    this.stateService.getSearchItemState().subscribe((data) => {
      if (data) {
        this.emptyForm = true;
        data.forEach(x => {
          const controlName = x.substring(0, x.indexOf('='));
          const val = x.slice(x.indexOf('=') + 1);
          this.form.controls[controlName].setValue(val);
        });
      }
    });
    this.location.subscribe((ev: PopStateEvent) => {
      this.selectedProviders = [];
      this.selectedAssociates.forEach(item => {
        const matchingItem = this.providers.find(product => product.spear_id === item.spear_id);
        if (matchingItem) {
          matchingItem.isSelected = false;
        }
      });
    });

    this.searchBy('SPEAR');
  }

  validatorPhone() {
    let pattern = new RegExp("^(\([2-9]{1})([0-9]{2}\)) ([2-9]{1})([0-9]{2})([\s.-]?)([0-9]{4})$");
    if (pattern.test(this.form.get('phone').value)) {
    }
  }

  ngOnDestroy(): void {
    this.subsciptions.forEach((x) => x.unsubscribe());
    //this.formValueChangeSubscription.unsubscribe();
  }

  private loadUsers(): void {
    this.blockUI.start();
    this.manageUsersService.getUsers().subscribe(
      (data) => {
        this.users = data as UserInfo[];
        this.blockUI.stop();
      }
      // (error) => {
      //   this.blockUI.stop();
      //   this.appUtils.ProcessErrorResponse(this.toastr, error);
      // }
    );
  }
  openModal(): void {
    this.modalService.openModalForAddOrEditUser();
  }

  edit(userId: string): void {
    this.modalService.openModalForAddOrEditUser(userId);
  }
  openExportCSV(): void {
    this.ngbModal.open(ExportAllCsvComponent, {
      ariaDescribedBy: 'modal-basic-title', windowClass: 'groupModal', size: 'sm', centered: true
    });
  }
  closeExportCSV(): void {
    this.ngbModal.dismissAll();
  }
  applyMerge(): void {
    if (this.selectedProviders.length !== 2) {
      this.appUtils.ProcessCustomResponse(this.toastr, true, 'Please select two providers');
      return;
    }
    let ids = [];
    for(let i=0; i<=this.selectedAssociates.length-1;i++){
      if(this.selectedAssociates[i].id){
        ids.push(this.selectedAssociates[i].id)
      }
    }
    let valdidatePost = {spear_profile_ids: ids};

    this.http.post('https://spear-django-uq3cjixc7q-uw.a.run.app/api/v1/validate_merging/', valdidatePost).subscribe((resp: any)=>{
      if(resp.details.can_merged && !resp.details.can_automatic_merged){
        this.providerService.setMergeIds(this.selectedProviders);
        this.route.navigate(['/providers/manual-merge']);
      }
    });

  }
  activateOrInactive(userId: string, isActive: boolean): void {
    this.blockUI.start();
    this.manageUsersService
      .activateOrInactivateUser(userId, !isActive)
      .subscribe(
        (data) => {
          this.loadUsers();
          this.blockUI.stop();
        }
        // (error) => {
        //   this.blockUI.stop();
        //   this.appUtils.ProcessErrorResponse(this.toastr, error);
        // }
      );
  }
  CheckAllOptions(checked: boolean): void {
    this.providers.forEach((x) => (x.isSelected = checked));
    this.selectedProviders = [];
    this.providers.forEach((provider) => {
      this.selectedProviders.push(provider.spear_id);
    });
    if (!checked) {
      this.selectedProviders = [];
    }
  }
  Checked(spear_id: string, entity: any[], providerId: number, event:any): void {
    if (this.selectedProviders.includes(spear_id)) {
      this.selectedProviders.splice(
        this.selectedProviders.findIndex((x) => x === spear_id),
        1
      );
    } else {
      this.selectedProviders.push(spear_id);
    }
    if(event.target.checked == true){
      this.selectedAssociates.push({entityId: entity, id: providerId, spear_id: spear_id});
    }
    else if(event.target.checked == false){
      this.selectedAssociates.pop();
    }
  }
  tempChecked(): void {
    this.show = !this.show;
  }
  select(): void {
    this.isDisabled = !this.isDisabled;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onSubmit(isexport: string): void {
    const controls = this.form.controls;
    this.pageIndex = 1;
    this.filteredQuery = [];
    if (this.emptyForm) {
      if (controls.searchAll.value) {
        this.searchText = controls.searchAll.value;
      }
      if (controls.official_first_name.value) {
        this.filteredQuery.push(
          `official_first_name=${controls.official_first_name.value}`
        );
      }
      if (controls.official_middle_name.value) {
        this.filteredQuery.push(
          `official_middle_name=${controls.official_middle_name.value}`
        );
      }
      if (controls.official_last_name.value) {
        this.filteredQuery.push(
          `official_last_name=${controls.official_last_name.value}`
        );
      }
      if (controls.name_suffix.value) {
        this.filteredQuery.push(
          `name_suffix=${controls.name_suffix.value}`
        );
      }
      if (controls.phone.value) {
        this.filteredQuery.push(
          `phone=${controls.phone.value}`
        );
      }
      if (controls.npi.value) {
        this.filteredQuery.push(
          `npi=${controls.npi.value}`
        );
      }
      if (controls.tin.value) {
        this.filteredQuery.push(
          `tin=${controls.tin.value}`
        );
      }
      if (this.isLimos) {
        this.filteredQuery.push(
          `source=BRS`
        );
      }
      else {
        if (controls.source.value) {
          this.filteredQuery.push(
            `source=${controls.source.value}`
          );
        }
      }
      if (controls.city.value) {
        this.filteredQuery.push(
          `city=${controls.city.value}`
        );
      }
      if (controls.streetaddress.value) {
        this.filteredQuery.push(
          `street_address=${controls.streetaddress.value}`
        );
      }
      if (controls.state.value) {
        this.filteredQuery.push(
          `state=${controls.state.value}`
        );
      }
      if (controls.adj.value) {
        let result = controls.adj.value.replace(/\s+/g, '').replace(/[^\w\s]/gi, '');
        this.filteredQuery.push(
          `adj=${result}`
        );
      }
      if (controls.insured.value) {
        this.filteredQuery.push(
          `insured=${controls.insured.value}`
        );
      }
      if (controls.insurance_company.value) {
        this.filteredQuery.push(
          `insurance_company=${controls.insurance_company.value}`
        );
      }
      if (controls.claims_admin.value) {
        this.filteredQuery.push(
          `claims_admin=${controls.claims_admin.value}`
        );
      }
      if (controls.provider_type.value) {
        this.filteredQuery.push(
          `provider_type=${controls.provider_type.value}`
        );
      }
      if (controls.claim_number.value) {
        let result = controls.claim_number.value.replace(/\s+/g, '').replace(/[^\w\s]/gi, '');
        this.filteredQuery.push(
          `claim_number=${result}`
        );
        this.isClaimNumberSearched = true;
      }
      if (controls.claimant_first_name.value) {
        this.filteredQuery.push(
          `claimant_first_name=${controls.claimant_first_name.value}`
        );
      }
      if (controls.claimant_middle_name.value) {
        this.filteredQuery.push(
          `claimant_middle_name=${controls.claimant_middle_name.value}`
        );
      }
      if (controls.claimant_last_name.value) {
        this.filteredQuery.push(
          `claimant_last_name=${controls.claimant_last_name.value}`
        );
      }
      if (controls.claimant_name_suffix.value) {
        this.filteredQuery.push(
          `claimant_name_suffix=${controls.claimant_name_suffix.value}`
        );
      }
      if (controls.adjuster.value) {
        this.filteredQuery.push(
          `adjuster=${controls.adjuster.value}`
        );
      }

      this.filteredText = this.filteredQuery.join('&');

      this.isSearched = true;

      this.emptyFormMessage = false;

      if (isexport == 'true') {
        this.getSearchFilteredProviders('true');
      }
      else {
        this.getSearchFilteredProviders('false');
      }

    }
    else {
      this.emptyFormMessage = true;
    }

  }

  getSearchFilteredProviders(csv: string): void {

    if (csv == 'false') {
      this.blockUI.start();
      this.selectedProviders = [];
      this.hideme = [];
      this.providers = [];
      this.providerService.getProvidersFilteredFromFireStore(this.pageIndex, this.limit, this.isFilterOn, this.filteredText, false).subscribe(
        (response) => {
          if (response.details.hits.length > 0) {
            response.details.hits.forEach(adrs => {
              if (
                adrs.provider_street_address_1 == null &&
                adrs.provider_street_address_2 == null &&
                adrs.provider_city == null &&
                adrs.provider_state == null &&
                adrs.provider_zip == null
              ) {
                adrs.provider_full_address = '&#8212;';
              }
              else {
                adrs.provider_full_address = (adrs.provider_street_address_1 ? `${adrs.provider_street_address_1}, ` : '') + (adrs.provider_street_address_2 ? `${adrs.provider_street_address_2}, ` : '') + (adrs.provider_city ? `${adrs.provider_city}, ` : '') + (adrs.provider_state ? `${adrs.provider_state} ` : '') + (adrs.provider_zip ? adrs.provider_zip : '');
              }
            });
            response.details.hits.forEach(offcialName => {
              if (
                offcialName.official_first_name == null &&
                offcialName.official_middle_name == null &&
                offcialName.official_last_name == null
              ) {
                offcialName.official_name = '';
              }
              else {
                offcialName.official_name = (offcialName.official_first_name ? offcialName.official_first_name : '') + (offcialName.official_first_name && offcialName.official_middle_name ? ' ' : '') + (offcialName.official_middle_name ? ` ${offcialName.official_middle_name}` : '') + (offcialName.official_middle_name && offcialName.official_last_name ? ' ' : '') + (offcialName.official_last_name ? ` ${offcialName.official_last_name}` : '');
              }
            });

            if (this.isClaimNumberSearched) {
              let filteredData = response.details.hits.sort((a, b) => {
                const dateA = new Date(a.bill_review_br_complete_date);
                const dateB = new Date(b.bill_review_br_complete_date);
                return dateA.getTime() - dateB.getTime();
              });
              let x = [];
              x.push(filteredData[filteredData.length - 1]);
              this.providers = x;
              this.providerSize = 1;
              this.isClaimNumberSearched = false;
              this.stateService.setSearchedState(this.providers);
              this.stateService.setSearchedItemState(this.filteredQuery);
              if (this.isSpear) {
                this.stateService.setSearchedByState('byspear');
              }
              else {
                this.stateService.setSearchedByState('bylimos');
              }
              this.stateService.setProviderSizeState(this.providerSize);
            }
            else {
              this.providers = response.details.hits;
              this.providerSize = response.details.estimatedTotalHits;
              this.stateService.setSearchedState(response.details.hits);
              if (this.isSpear) {
                this.stateService.setSearchedByState('byspear');
              }
              else {
                this.stateService.setSearchedByState('bylimos');
              }
              this.stateService.setSearchedItemState(this.filteredQuery);
              this.stateService.setProviderSizeState(this.providerSize);
            }

            this.sortData = this.providers;
            this.activeSortObj ? this.onSort(this.activeSortObj) : null;
            this.blockUI.stop();
          }
          else{
            this.providers = [];
            this.blockUI.stop();
          }
        }
      );
    }
    else {
      this.providerService.getProvidersFilteredFromFireStoreCSV(this.searchText, this.pageIndex, this.limit, this.isFilterOn, this.filteredText, true).subscribe(
        (response) => {
          this.openExportCSV();
          this.interVal = setInterval(() => {
            this.http.get<any>(`https://spear-django-uq3cjixc7q-uw.a.run.app/api/v1/profile/export/status/${response.details.task_id}/`).subscribe((x) => {
              if (x.details.status == 'SUCCESS') {
                this.stopInterval = true;
                this.checkInterVal();
                this.closeExportCSV();
                const a = document.createElement("a");
                a.href = x.details.result.signed_url;
                a.download = "record.csv";
                a.click();
              }
            });
          }, 5000);
        });

    }

  }
  isAllChecked = (): boolean => {
    if (!this.providers || this.providers.length === 0) {
      return false;
    }
    return this.providers.every((all) => all.isSelected);
  };
  isAnyChecked = (): boolean => {
    if (this.selectedProviders.length < 1) {
      return false;
    } else {
      return true;
    }
  };
  isAnyRowChecked= (): boolean => {
    if (this.selectedAssociates.length < 1) {
      return false;
    } else {
      return true;
    }
  };
  isAnyTwoChecked = (): boolean => {
    if (this.selectedProviders.length < 2) {
      return false;
    } else {
      return true;
    }
  };
  onPageChange(event: any) {
    this.pageIndex = event;
    this.getSearchFilteredProviders('false');
  }

  onSizeChange(limit: number) {
    this.pageIndex = 1;
    this.limit = limit;
    this.getSearchFilteredProviders('false');
    this.page = 1;
  }
  checkInterVal() {
    if (this.stopInterval == true) {
      clearInterval(this.interVal);
    }
  }
  onReset(): void {
    this.form.reset();
    this.isSearched = false;
    this.emptyForm = false;
    this.emptyFormMessage = false;
  }

  onFieldReset(fld: string): void {
    let ctrl = this.form.get(fld);
    ctrl.reset();
    if(fld == 'claim_number'){
      this.isClaimNumberSearched = false;
    }
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {

    this.activeSortObj = { column, direction };

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });


    if (direction === '' || column === '') {
      this.providers = this.sortData;
    } else {
      this.providers = [...this.sortData].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }

  downloadCSV() {
    const result = this.providers;
    this.downloadFile(result, 'searchresult');
    // this.http.get<ProviderSearchReponseMOdel>(`${environment.fireStoreApiUrl}/api/v2/profile/search?limit=${this.limit}&offset=${this.pageIndex}&${this.filteredText}`).subscribe((data) => {
    //   const result = data.details.hits;
    //   this.downloadFile(result, 'searchresult')
    // });
  }

  downloadFile(data, filename) {
    let csvData;
    let blob;
    let formattedData = [];
    let street;
    let street2;
    let claimant;
    let carriername;
    let business;
    if (data) {
      for (let i = 0; i <= data.length - 1; i++) {
        if (data[i].provider_street_address_1) {
          street = data[i].provider_street_address_1.replace(/,/g, ' ');
        }
        else {
          street = ''
        }
        if (data[i].provider_street_address_2) {
          street2 = data[i].provider_street_address_2.replace(/,/g, ' ');
          street = street + ' ' + street2;
        }
        if (data[i].injured_worker_last_first) {
          claimant = data[i].injured_worker_last_first.replace(/,/g, ' ');
        }
        else {
          claimant = ''
        }
        if (data[i].bill_review_carrier_name) {
          carriername = data[i].bill_review_carrier_name.replace(/,/g, '')
        }
        else {
          carriername = ''
        }
        if (data[i].provider_name_business) {
          business = data[i].provider_name_business.replace(/,/g, '')
        }
        else {
          business = ''
        }
        formattedData.push({
          provider_name_person: data[i].provider_name_person || data[i].official_name || '',
          provider_alias_person: business,
          spear_id_prefix: data[i].spear_id_prefix ? data[i].spear_id_prefix : '',
          provider_street_address_1: street,
          provider_city: data[i].provider_city ? data[i].provider_city : '',
          provider_state: data[i].provider_state ? data[i].provider_state : '',
          provider_zip: data[i].provider_zip ? data[i].provider_zip : '',
          entity_type_code: data[i].entity_type_code ? data[i].entity_type_code : '',
          provider_npi_number: data[i].provider_npi_number ? data[i].provider_npi_number : '',
          wc_claim_number: data[i].wc_claim_number ? data[i].wc_claim_number : '',
          injured_worker_last_first: claimant,
          bill_review_client_name: data[i].bill_review_client_name ? data[i].bill_review_client_name : '',
          bill_review_carrier_name: carriername
        });
      }
    }
    if (this.isSpear) {
      csvData = this.ConvertToCSV(formattedData, ['Name', 'Business', 'Source', 'Street', 'City', 'State', 'Zip', 'Provider Type', 'NPI'], ['provider_name_person', 'provider_alias_person', 'spear_id_prefix', 'provider_street_address_1', 'provider_city', 'provider_state', 'provider_zip', 'entity_type_code', 'provider_npi_number']);
    }
    if (this.isLimos) {
      csvData = this.ConvertToCSV(formattedData, ['Claim Number', 'Claimant Name', 'Source', 'Client', 'Carrier'], ['wc_claim_number', 'injured_worker_last_first', 'spear_id_prefix', 'bill_review_client_name', 'bill_review_carrier_name']);
    }
    blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList, dataList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in dataList) {
        let head = dataList[index];

        if (head == 'entity_type_code' && array[i][head] == 1) {
          array[i][head] = 'Person';
        }
        else if (head == 'entity_type_code' && array[i][head] == 2) {
          array[i][head] = 'Business';
        }
        else if (head == 'entity_type_code') {
          array[i][head] = 'Entity';
        }

        line += array[i][head] + ',';
      }
      str += line + '\r\n';
    }
    return str;
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.page = 1;
      this.onSubmit('false');
    }
  }

  filterOn() {
    this.isFilterOn = true
  }
  filterOff() {
    this.isFilterOn = false
  }

  doAssociate(selectedAssociates: any) {
    const openAssociate = this.ngbModal.open(AssociateComponent, {
      ariaDescribedBy: 'modal-basic-title', size: 'lg', centered: true
    });
    openAssociate.componentInstance.selectedAssociates = selectedAssociates;
    openAssociate.componentInstance.evt.subscribe(($event) => {
      if($event){
        this.page = 1;
        this.onSubmit('false');
        this.selectedAssociates = [];
      }
    });
  }

  searchBy(tab: string) {
    this.form.reset();
    if (tab == 'SPEAR') {
      this.isSpear = true;
      this.isLimos = false;
      this.isSearched = false;
      this.formValueChangeSubscription = this.form.valueChanges.subscribe(() => {
        this.emptyForm = Object.keys(this.form.controls).some(formKey => this.form.controls[formKey].value);
      })
    }
    else if (tab == 'LIMOS') {
      this.isSpear = false;
      this.isLimos = true;
      this.isSearched = false;
      this.emptyForm = true;
    }
  }

  toLiens(url: string, id: number) {
    if (url === null) {
      return false;
    }
    else {
      window.open(`limos/${url}/${id}`, "_blank");
    }
  }

}
