import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'decimalPoint' })
export class DecimalPointPipe implements PipeTransform {
  transform(value: number, decimalPoints: number = 2): string {
    if (value && typeof value !== 'number') {
      value = Number(value);
    }
    return value?.toFixed(decimalPoints);
  }
}
