import { Component, OnInit, OnDestroy } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { ManageUsersService } from 'src/app/services/manage.users.service';
import { UserInfo } from 'src/app/models/manage-users/user.info';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DatePipe } from '@angular/common';
import { AppUtils } from 'src/app/utilities/app.utils';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from 'src/app/services/modal.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css']
})
export class ManageUsersComponent implements OnInit, OnDestroy {
  @BlockUI('manage-users-blockUI') blockUI: NgBlockUI;
  closeResult = '';
  users: UserInfo[];
  subsciptions: Subscription[] = [];

  signupForm: FormGroup;

  constructor(private addmodalService: NgbModal,
              private manageUsersService: ManageUsersService,
              public datepipe: DatePipe,
              private appUtils: AppUtils,
              private toastr: ToastrService,
              private modalService: ModalService) {
               }

  ngOnInit(): void {
    // this.loadUsers();
    // const subscription = this.manageUsersService.listenToloadUsers().subscribe(() => {
    //   this.loadUsers();
    // });
    // this.subsciptions.push(subscription);
  }
  ngOnDestroy(): void {
    this.subsciptions.forEach(x => x.unsubscribe());
  }

  private loadUsers(): void{
    this.blockUI.start();
    this.manageUsersService.getUsers().subscribe(data => {
      this.users = data as UserInfo[];
      this.blockUI.stop();
    },
      error => {
        this.blockUI.stop();
        this.appUtils.ProcessErrorResponse(this.toastr, error);
    });
  }
  openModal(): void {
    this.modalService.openModalForAddOrEditUser();
  }

  edit(userId: string): void {
    this.modalService.openModalForAddOrEditUser(userId);
  }
activateOrInactive(userId: string, isActive: boolean): void {
    this.blockUI.start();
    this.manageUsersService.activateOrInactivateUser(userId, !isActive).subscribe((data) => {
        this.loadUsers();
        this.blockUI.stop();
      }, error => {
          this.blockUI.stop();
          this.appUtils.ProcessErrorResponse(this.toastr, error);
});
}

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
