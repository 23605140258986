<div class="container-fluid predashboard-wrap overlayScreen">
    <!-- <button class="btn btn-transparent text-white float-right closebtn" (click)="closeDashboard($event)"><em
            class="fa far fa-times-circle"></em></button> -->
    <div class="row  justify-content-center">
        <div class="col-md-4 text-center">
            <h3 class="predashboard-header">
                <span><img src="../../../assets/images/af_bw_hexagons.png" width="50px" class="mr-3" alt="icon"></span> <span
                    class="text-white">OUR</span> SERVICES
            </h3>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-md-4">
            <div class="services_blocks align-items-center text-center">
                <h3 class="text-yellow">Armor</h3>
                <p>Armor is a revolutionary Provider Vetting Program seeking to protect and save significant medical
                    claims costs. Supported by proprietary SPEAR software, it flags non-qualified providers proacitvely.
                    Integrating ARMOR into your existing bill review, MPN, RFA and/or lien program platform, you avoid
                    paying providers needlessly.</p>
                <button class="btn btn-transparent prebtn">Enter</button>
            </div>
        </div>
    </div>

    <div class="row text-center">
        <div class="col-md-4 align-self-center">
            <div class="services_blocks align-items-center d-flex mt-4 my-auto flex-column" (click)="gotoSpear()">
                <h3 class="text-yellow ">Spear</h3>
                <p>Spear is a proprietary database built from decades of knowledge & experience with federal, state and
                    local data. The most powerful tool in the industry in preventing unwarranted payment to
                    non-qualified providers including; licensing issues, drug enforcement non-compliance, billing &
                    coding violations, services rendered anomalies, prohibited referrals and other abusive or fraudulent
                    practices.</p>
                <button class="btn btn-transparent prebtn">Enter</button>
            </div>
        </div>
        <div class="col-md-4 "><img src="../../../assets/images/icon-os-desktop-no_hexagons.png" class="mid-hexa-icon" alt="icon">
        </div>
        <div class="col-md-4 align-self-center">
            <div class="services_blocks align-items-center d-flex mt-4 my-auto flex-column">
                <h3 class="text-yellow">SIU</h3>
                <p>Industry leading SIU experts are identifying, protecting and prosecuting Provider, Claimant and
                    Premium Fraud. We offer full-service national compliance filings, mandated fraud reporting and
                    on-site SIU compliance training seminars. We also offer an on-line training platform with
                    certification.
                </p>
                <button class="btn btn-transparent prebtn">Enter</button>
            </div>
        </div>
    </div>

    <div class="row text-center">
        <div class="col-md-1">

        </div>
        <div class="col-md-4  align-self-center">
            <div class="services_blocks align-items-center d-flex mt-4 my-auto flex-column">
                <h3 class="text-yellow">Lien Defense</h3>
                <p>SPEAR is designed to help our clients on the front and back of claim management. Because SPEAR
                    identifies and prevents payments to absuive or fraudulent providers through Bill Review on the front
                    end, our Lien Defense program effectively settles liens from these providers on the back end for
                    either nominal or zero payment. A far better lien defense tool utilizing the lens of SIU experts.
                </p>
                <button class="btn btn-transparent prebtn">Enter</button>
            </div>
        </div>
        <div class="col-md-2">

        </div>
        <div class="col-md-4 align-self-center">
            <div class="services_blocks align-items-center d-flex mt-4 my-auto flex-column">
                <h3 class="text-yellow">Field Investigation</h3>
                <p>Armor is a revolutionary Provider Vetting Program seeking to protect and save significant medical
                    claims costs. Supported by proprietary SPEAR software, it flags non-qualified providers proacitvely.
                    Integrating ARMOR into your existing bill review, MPN, RFA and/or lien program platform, you avoid
                    paying providers needlessly.</p>
                <button class="btn btn-transparent prebtn">Enter </button>
            </div>
        </div>
        <div class="col-md-1">
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-md-4">
            <div class="services_blocks align-items-center text-center" (click)="showAnalytics()">
                <h3 class="text-yellow">Analytics</h3>
                <p>Armor is a revolutionary Provider Vetting Program seeking to protect and save significant medical
                    claims costs. Supported by proprietary SPEAR software, it flags non-qualified providers proacitvely.
                    Integrating ARMOR into your existing bill review, MPN, RFA and/or lien program platform, you avoid
                    paying providers needlessly.</p>
                <button class="btn btn-transparent prebtn">Enter</button>
            </div>
        </div>
    </div>



</div>

<div class="container-fluid predashboard-wrap" *ngIf="ifOverlay" #divToScroll>
    <button class="btn btn-transparent text-white float-right closebtn" (click)="closeDashboard($event)"><em
            class="fa far fa-times-circle"></em></button>
    <div class="row  justify-content-center">
        <div class="col-md-4 text-center">
            <h3 class="predashboard-header">
                <span><img src="../../../assets/images/af_bw_hexagons.png" width="50px" class="mr-3" alt="icon"></span> <span
                    class="text-white">ANALYTICS</span>
            </h3>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-md-4">
            <div class="services_blocks align-items-center text-center">
                <h3 class="text-yellow">BIU Review</h3>
                <p>Armor is a revolutionary Provider Vetting Program seeking to protect and save significant medical
                    claims costs. Supported by proprietary SPEAR software, it flags non-qualified providers proacitvely.
                    Integrating ARMOR into your existing bill review, MPN, RFA and/or lien program platform, you avoid
                    paying providers needlessly.</p>
                <button class="btn btn-transparent prebtn">Enter</button>
            </div>
        </div>
    </div>

    <div class="row text-center">
        <div class="col-md-4 align-self-center">
            <div class="services_blocks align-items-center d-flex mt-4 my-auto flex-column" (click)="gotoScrub()">
                <h3 class="text-yellow ">SCRUB</h3>
                <p>Spear is a proprietary database built from decades of knowledge & experience with federal, state and
                    local data. The most powerful tool in the industry in preventing unwarranted payment to
                    non-qualified providers including; licensing issues, drug enforcement non-compliance, billing &
                    coding violations, services rendered anomalies, prohibited referrals and other abusive or fraudulent
                    practices.</p>
                <button class="btn btn-transparent prebtn">Enter</button>
            </div>
        </div>
        <div class="col-md-4 "><img src="../../../assets/images/icon-os-desktop-no_hexagons.png" class="mid-hexa-icon" alt="icon">
        </div>
        <div class="col-md-4 align-self-center">
            <div class="services_blocks align-items-center d-flex mt-4 my-auto flex-column">
                <h3 class="text-yellow">EXAMS</h3>
                <p>Industry leading SIU experts are identifying, protecting and prosecuting Provider, Claimant and
                    Premium Fraud. We offer full-service national compliance filings, mandated fraud reporting and
                    on-site SIU compliance training seminars. We also offer an on-line training platform with
                    certification.
                </p>
                <button class="btn btn-transparent prebtn">Enter</button>
            </div>
        </div>
    </div>



    <div class="row justify-content-center  mt-5">
        <div class="col-md-4 mt-5">
            <div class="services_blocks align-items-center text-center" (click)="showAnalytics()">
                <h3 class="text-yellow">Reporting</h3>
                <p>Armor is a revolutionary Provider Vetting Program seeking to protect and save significant medical
                    claims costs. Supported by proprietary SPEAR software, it flags non-qualified providers proacitvely.
                    Integrating ARMOR into your existing bill review, MPN, RFA and/or lien program platform, you avoid
                    paying providers needlessly.</p>
                <button class="btn btn-transparent prebtn">Enter</button>
            </div>
        </div>
    </div>



</div>