<form>
      <h5 class="text-voilet font-weight-bold">Select Columns</h5>
      <hr>
      <div class="input-group input-group-navbar">
            <div class="input-group-append">
                <button class="btn rounded-left search-btn" type="button">
                    <em class="fa fa-search"></em>
                </button>
            </div>
            <input type="text" class="form-control search-control pl-0 border-0 mb-4" [(ngModel)]="searchText" placeholder="Search here..." [ngModelOptions]="{standalone: true}">
      </div>
      <div class="filterCheckbox pb-2">
            <perfect-scrollbar>
                  <div class="custom-control custom-checkbox mb-3" *ngFor="let i = index; let item of manageFields.allFields  | filterPipe: searchText: 'field';">
                        <input type="checkbox" class="custom-control-input" id="checkBox_{{i}}" [checked]="item.selected" value="item.id" (click)="select(item.field)">
                        <label class="custom-control-label control-custom-label control-custom-primary" for="checkBox_{{i}}">{{ item.field }}</label>
                  </div>
            </perfect-scrollbar>
      </div>
      <button type="button" class="btn text-center btnApply w-100" [disabled]="isDisabled"  data-toggle="modal" data-target="FilterModalCenter" (click)="openFilterModal()">Apply Filters</button>
</form>

<!--filter as a template Modal -->
<div [ngClass]="setClasses" class="modal fade" id="FilterModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" >
        <div class="modal-content border-0 rounder-3 p-5">
          <div class="modal-body text-center py-0">
            <img class="bg-warning p-3 mb-3" src="assets/images/template.png" alt="">
            <h4 class="title-modal mb-3 font-weight-semibold px-4">Do you want to create this filter as a template</h4>
            <p class="subtitle-modal px-2">You can save this filter as a template so that you can access this in future.</p>
          </div>
          <div class="modal-footer border-0 justify-content-center pb-0">
            <button type="button" class="rounded-0 btn btn-secondary-outline bg-white text-voilet mb-0" data-dismiss="modal" (click)="closeFilterModal()">No, Thanks</button>
            <button type="button" class="rounded-0 btn text-white primary-bg mb-0" data-toggle="modal" data-target="createTemplate" (click)="openTemplate()">Yes, Sure</button>
          </div>
        </div>
      </div>
</div>

<!--Create template Modal -->
<div [ngClass]="classSet" class="modal fade" id="createTemplate" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <form class="h-100" #createTemplateForm='ngForm' (ngSubmit)="saveTemplate()">
            <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content border-0 rounder-3 p-5">
                        <div class="modal-header px-0 mb-5">
                              <h4 class="modal-title">Create Template</h4>
                              <a class="btn d-block ml-auto btnclose text-gray rounded-circle p-0" (click)="closeTemplate()">
                                    <em class="fa fa-close"></em>
                              </a>
                        </div>
                        <div class="modal-body p-0">
                              <label class="subtitle-modal p0">Template Name</label>
                              <input class="form-control mb-5 template-control" [(ngModel)]="addTemplate.templateName" type="text" placeholder="Template Name" [ngModelOptions]="{standalone: true}">
                        </div>
                        <div class="modal-footer border-0 p-0">
                              <button type="button" class="rounded-0 btn btn-secondary-outline bg-white text-voilet mb-0" data-dismiss="modal" (click)="closeTemplate()">Cancel</button>
                              <button type="submit" class="rounded-0 btn text-white primary-bg mb-0">Save</button>
                        </div>
                  </div>
            </div>
      </form>
</div>