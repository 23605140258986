import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as html2pdf from 'html2pdf.js';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Observable, OperatorFunction, Subject, Subscription, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  ClaimTable,
  CreateNoteReq,
  CreateRes,
  Diary,
  GetLiens,
  GetNotepad,
  GetProviderNameResponse,
  GetTimeSpentResponse,
  GetTimeSpentResponseDetail,
  Hit,
  Invoice,
  LimosDataResponse,
  LimosNoteFile,
  LimosTable,
  NoteTable,
  PaginationData,
  ProviderSerchResult,
  UpdateLimosResponse
} from 'src/app/models/limos/limos.models';
import { LimosService } from 'src/app/services/limos.service';
import { environment } from 'src/environments/environment';
import { CreateTimeLogReq } from '../../models/limos/limos.models';

@Component({
  selector: 'app-limos',
  templateUrl: './limos.component.html',
  styleUrls: ['./limos.component.css'],
})
export class LimosComponent implements OnInit, OnDestroy {
  private subsciptions: Subscription[] = [];
  private rowid: string;
  private rowidTime: string;
  private claimantOldState = {
    provider_name_business: null,
    provider_npi_number: null,
    lien_amount: null,
    tax_id_number: null,
    lien_type: null,
    lien_request_date: null,
    lien_created: null,
    lien_requested_amount: null,
    lien_resolved_date: null,
    lien_resolved_amount: null,
  };
  private timeState = {
    log_date: null,
    provider: null,
    time_type: null,
    note: null,
    time_spent: null,
  };
  private limosId: number;
  private providerSerchResult: ProviderSerchResult;
  private phonePattern =
    /^$|^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  private emailPattern = /^$|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  private _selectedProvider: boolean = false;
  private _selectedProviderAdd: boolean = false;
  public adjNumber!: number;
  public limosStatus = new FormControl();
  public selectedLienType: string = 'Select a lien type';
  public selectedAssignedType: string = 'Select a assigned type';
  public limos_status: boolean = false;
  @BlockUI('manage-users-blockUI') public blockUI: NgBlockUI;
  @BlockUI('diaries-list-ui') public diariesBlockUI: NgBlockUI;
  @BlockUI('notes-list-ui') public notesBlockUI: NgBlockUI;
  public claimantTable = [];
  public contentEditableLien: boolean = false;
  public timeTable: GetTimeSpentResponseDetail[] = [];
  public provider_list = [];
  public adminForm: FormGroup;
  public adjForm1: FormGroup;
  public adjForm2: FormGroup;
  public adjForm3: FormGroup;
  public emptyForm: boolean = false;
  public emptyAdjForm1: boolean = false;
  public emptyAdjForm2: boolean = false;
  public emptyAdjForm3: boolean = false;
  public emptyadminForm: boolean = false;
  public adminValueChangeSubscription!: Subscription;
  public formValueChangeSubscription!: Subscription;
  public adjForm1ValueChangeSubscription!: Subscription;
  public adjForm2ValueChangeSubscription!: Subscription;
  public adjForm3ValueChangeSubscription!: Subscription;
  public showTimePostForm: boolean = false;
  public claimTable: ClaimTable;
  public limosTable: LimosTable;
  public deleTimeEntry = '';
  public deleClaimantEntry = '';
  public preloader = false;
  public preloader2 = false;
  public preloader3 = false;
  public preloader4 = false;
  public lienType = [
    'Diagnostics (MRI, X-Ray)',
    'Pharmaceuticals',
    'Interpreting (Interpreter - Translator)',
    'Chiropractic (DC)7',
    'Physicians',
    'Pain Management',
    'Acupuncturist',
    'ASC & Surgery Center',
    'Copy Service',
    'Nurse Practitioner (NP)',
    'Psychological (PhD)',
    'Ambulance (Air- Ambulance)/ Paramedic',
    'Physical Therapy/Occupational Therapy',
    'Laboratory Testing',
    'Hospital',
    'DME',
    'Transportation',
    'Sleep Studies',
    'Podiatry (DPM)',
    'Physician Assistant',
    'Dental (DMD, DDS)',
    'Licensed Marriage Family Therapist',
    'Respiratory Therapist',
    'Licensed Social Workers/Counselor',
    'Other',
  ].sort();
  public serverDateFormat = 'yyyy-MM-dd';
  public clientDateFormat = 'MM/dd/yyyy';
  public claimNumber: string;
  public assignedType = ['ADJUSTER', 'ARGUS', 'D/A', 'CO-DEF'];

  public assignedToUsers = [
    'Crystal Mendoza',
    'Frank Giaffoglione',
    'David Moreno',
    'Matthew Hopkins',
    'Lynda Stettler',
  ];
  public todayDate: Date = new Date();
  public accessGroups = [
    'Admin',
    'Internal',
    'Investigative',
    'Public',
    'Vendor Management',
    'Vendors',
  ];
  public selectedNoteFiles: any[];
  public selectedNoteTags: string[] = [];
  public createExpenseEntry = false;
  public noteBodyeditor = '';
  public noteTable: NoteTable[] = [];
  public noteType = [
    {
      value: '',
      label: 'Select Note Type',
    },
    {
      value: 'Lien Negotiation',
      label: 'Lien Negotiation',
    },
    {
      value: 'Client Correspondence',
      label: 'Client Correspondence',
    },
    {
      value: 'SIU Clerical',
      label: 'SIU Clerical',
    },
    {
      value: 'D/A Correspondence',
      label: 'D/A Correspondence',
    },
    {
      value: 'Analysis/Authority Request',
      label: 'Analysis/Authority Request',
    },
    {
      value: 'Authority Granted',
      label: 'Authority Granted',
    },
  ];
  activeTab: string = 'Dashboard';
  pageTabs = [
    'Dashboard',
    'Notes',
    'Diary',
    'Time Management',
    'Invoicing',
    'Resources',
  ];
  editor = ClassicEditor;
  @Input() invoices: Invoice[] = [];
  @Input() invoiceLimit: number = 30;

  @Output() invoiceSelected = new EventEmitter<Invoice>();
  @Output() teamSelected = new EventEmitter<ClaimTable>();
  private unsubscribe$ = new Subject<void>();
  noteLimit = 30;
  notePage = 1;
  noteTotal: number;
  notes: CreateNoteReq[] = [];
  diaryLimit = 30;
  diaryPage = 1;
  diaryTotal: number;
  diaries: Diary[] = [];
  noteForm: FormGroup;
  diaryForm: FormGroup;
  noteTimeSpent = 0;
  isDownloadStarted = {};
  editNoteData: CreateNoteReq;
  editDiaryData: Diary;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private addModal: NgbModal,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private limosService: LimosService,
    private cd: ChangeDetectorRef
  ) {}

  public setActiveTab(tab: string): void {
    if (this.pageTabs.includes(tab)) {
      this.activeTab = tab;
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.populateTimeManagementTable();
    this.activatedRoute.params.subscribe((param) => {
      this.blockUI.start();
      this.claimNumber = param.claimNumber;
      this.limosId = param.limos_id;
      this._populateNoteTable();
      this.http
        .get<GetLiens>(
          `${environment.fireStoreApiUrl}/api/v1/liens/${this.claimNumber}/${this.limosId}/`
        )
        .subscribe((claimData) => {
          if (claimData.details.liens_profile) {
            let data = claimData.details.liens_profile;
            this.claimTable = {
              id: data.id,
              claimNo: this.claimNumber,
              claimant_name: data.injured_worker_last_first,
              client: data.bill_review_client_name,
              admin: data.claim_administrator_1 || data.claim_administrator_2,
              carrier: data.bill_review_carrier_name,
              claim_status: data.wc_claim_status,
              bill_review_adjuster_name: data.bill_review_adjuster_name,
              adjNo: data.case_number,
            };
            this.diaryForm.get('case_number')?.setValue(this.claimTable.id);
          }

          if (claimData.details.limos_data) {
            let data = claimData.details.limos_data;
            this.limosTable = {
              created_at: data?.created_at,
              modified_at: data?.modified_at,
              wc_claim_number: data?.wc_claim_number,
              defense_attorney: data?.defense_attorney,
              assigned_to: data?.assigned_to,
              case_manager: data?.case_manager,
              phone_1: data?.phone_1,
              phone_2: data?.phone_2,
              email_1: data?.email_1,
              email_2: data?.email_2,
              email_3: data?.email_3,
            };
            this.claimTable.administrator = data?.administrator;
            this.claimTable.claimSettledBy = data?.claimSettledBy;
          }

          if (claimData.details.lien_claimants) {
            for (let i = 0; i < claimData.details.lien_claimants.length; i++) {
              let sav;
              if (
                claimData.details.lien_claimants[i].lien_resolved_amount &&
                claimData.details.lien_claimants[i].lien_requested_amount
              ) {
                let num =
                  (1 -
                    claimData.details.lien_claimants[i].lien_resolved_amount /
                      claimData.details.lien_claimants[i]
                        .lien_requested_amount) *
                  100;
                sav = Math.round(num * 100) / 100;
              }
              this.claimantTable.push({
                provider_name_business:
                  claimData.details.lien_claimants[i].provider_name_business,
                tax_id_number:
                  claimData.details.lien_claimants[i].tax_id_number,
                lien_type: claimData.details.lien_claimants[i].lien_type,
                provider_npi_number:
                  claimData.details.lien_claimants[i].provider_npi_number,
                lien_request_date:
                  claimData.details.lien_claimants[i].lien_request_date,
                lien_created: claimData.details.lien_claimants[i].lien_created,
                lien_amount: claimData.details.lien_claimants[i].lien_amount,
                lien_requested_amount:
                  claimData.details.lien_claimants[i].lien_requested_amount,
                authority_requested:
                  claimData.details.lien_claimants[i].authority_requested,
                assigned: claimData.details.lien_claimants[i].assigned,
                case_status: claimData.details.liens_profile.case_status,
                lien_resolved_date:
                  claimData.details.lien_claimants[i].lien_resolved_date,
                lien_resolved_amount:
                  claimData.details.lien_claimants[i].lien_resolved_amount,
                id: claimData.details.lien_claimants[i].id,
                saving: sav || null,
              });
            }
          }
          this.blockUI.stop();
        });
    });
    this.limosStatus.setValue('limos_status_open');

    this.adminForm = this.formBuilder.group({
      claimSettledBy: [''],
      adj_number: [''],
    });

    this.adjForm1 = this.formBuilder.group({
      adjuster: [''],
      phone_1: ['', [Validators.pattern(this.phonePattern)]],
      email_1: ['', [Validators.pattern(this.emailPattern)]],
    });
    this.adjForm2 = this.formBuilder.group({
      defense_attorney: [''],
      phone_2: ['', [Validators.pattern(this.phonePattern)]],
      email_2: ['', [Validators.pattern(this.emailPattern)]],
    });
    this.adjForm3 = this.formBuilder.group({
      assigned_to: [''],
      case_manager: [''],
      email_3: ['', [Validators.pattern(this.emailPattern)]],
    });
    this.adminValueChangeSubscription = this.adminForm.valueChanges.subscribe(
      () => {
        this.emptyadminForm = Object.keys(this.adminForm.controls).some(
          (formKey) => this.adminForm.controls[formKey].value
        );
      }
    );
    this.adjForm1ValueChangeSubscription = this.adjForm1.valueChanges.subscribe(
      () => {
        this.emptyAdjForm1 = Object.keys(this.adjForm1.controls).every(
          (formKey) => {
            const control = this.adjForm1.controls[formKey];
            return control.value !== null && control.value !== undefined;
          }
        );
      }
    );

    this.adjForm2ValueChangeSubscription = this.adjForm2.valueChanges.subscribe(
      () => {
        this.emptyAdjForm2 = Object.keys(this.adjForm2.controls).every(
          (formKey) => {
            const control = this.adjForm2.controls[formKey];
            return control.value !== null && control.value !== undefined;
          }
        );
      }
    );

    this.adjForm3ValueChangeSubscription = this.adjForm3.valueChanges.subscribe(
      () => {
        this.emptyAdjForm3 = Object.keys(this.adjForm3.controls).every(
          (formKey) => {
            const control = this.adjForm3.controls[formKey];
            return control.value !== null && control.value !== undefined;
          }
        );
      }
    );

    this.fetchTabListData();
  }

  fetchTabListData() {
    this.getNotes();
    this.getDiaries();
  }

  initForm() {
    this.noteForm = new FormGroup({
      access_group: new FormControl('', Validators.required),
      subject: new FormControl(''),
      title: new FormControl('', Validators.required),
      body: new FormControl('', Validators.required),
      tags: new FormControl([]),
      create_expense_entry: new FormControl(false),
    });

    this.diaryForm = new FormGroup({
      case_number: new FormControl(this.claimTable?.id),
      case_name: new FormControl(''),
      type: new FormControl(''),
      due_date: new FormControl(''),
      note: new FormControl(''),
      assigned_to: new FormControl(''),
    });
  }

  backToSearch(): void {
    this.router.navigate(['search']);
  }

  visTimePostForm(): void {
    this.showTimePostForm = true;
  }

  populateTimeManagementTable(): void {
    this.http
      .get<GetTimeSpentResponse>(
        `${environment.fireStoreApiUrl}/api/v1/liens/time-spent/all/`
      )
      .subscribe((data) => {
        if (data) {
          this.timeTable = data.details;
        }
      });
  }

  deleteTime(): void {
    this.http
      .delete(
        `${environment.fireStoreApiUrl}/api/v1/liens/time-spent/${this.deleTimeEntry}`
      )
      .subscribe((data) => {
        this.http
          .get<GetTimeSpentResponse>(
            `${environment.fireStoreApiUrl}/api/v1/liens/time-spent/all/`
          )
          .subscribe((data) => {
            if (data) {
              this.timeTable = data.details;
            }
          });
      });
    this.addModal.dismissAll();
  }

  deleteClaimant(): void {
    this.http
      .delete(
        `${environment.fireStoreApiUrl}/api/v1/liens/lien-claimant/delete/${this.deleClaimantEntry}`
      )
      .subscribe((data) => {
        this.http
          .get<GetLiens>(
            `${environment.fireStoreApiUrl}/api/v1/liens/${this.claimNumber}/${this.limosId}/`
          )
          .subscribe((claimData) => {
            if (claimData.details.lien_claimants) {
              this.blockUI.start();
              this.claimantTable = [];
              for (
                let i = 0;
                i < claimData.details.lien_claimants.length;
                i++
              ) {
                let sav;
                if (
                  claimData.details.lien_claimants[i].lien_resolved_amount &&
                  claimData.details.lien_claimants[i].lien_requested_amount
                ) {
                  let num =
                    (1 -
                      claimData.details.lien_claimants[i].lien_resolved_amount /
                        claimData.details.lien_claimants[i]
                          .lien_requested_amount) *
                    100;
                  sav = Math.round(num * 100) / 100;
                }
                this.claimantTable.push({
                  provider_name_business:
                    claimData.details.lien_claimants[i].provider_name_business,
                  tax_id_number:
                    claimData.details.lien_claimants[i].tax_id_number,
                  lien_type: claimData.details.lien_claimants[i].lien_type,
                  provider_npi_number:
                    claimData.details.lien_claimants[i].provider_npi_number,
                  lien_request_date:
                    claimData.details.lien_claimants[i].lien_request_date,
                  lien_created:
                    claimData.details.lien_claimants[i].lien_created,
                  lien_amount: claimData.details.lien_claimants[i].lien_amount,
                  lien_requested_amount:
                    claimData.details.lien_claimants[i].lien_requested_amount,
                  authority_requested:
                    claimData.details.lien_claimants[i].authority_requested,
                  assigned: claimData.details.lien_claimants[i].assigned,
                  case_status: claimData.details.liens_profile.case_status,
                  lien_resolved_date:
                    claimData.details.lien_claimants[i].lien_resolved_date,
                  lien_resolved_amount:
                    claimData.details.lien_claimants[i].lien_resolved_amount,
                  id: claimData.details.lien_claimants[i].id,
                  saving: sav || null,
                });
              }
            }
            this.blockUI.stop();
          });
      });
    this.addModal.dismissAll();
  }

  makeEditable(id: string) {
    this.rowid = '';
    this.rowid = id;
    document.getElementById(`edit-${id}`).hidden = true;
    document.getElementById(`save-${id}`).hidden = false;
    document.getElementById(`cancel-${id}`).hidden = false;
    document.getElementById(`delete-${id}`).hidden = true;
    document.getElementById(`tax-${id}`).removeAttribute('disabled');
    document.getElementById(`type-${id}`).removeAttribute('disabled');
    document.getElementById(`assignedType-${id}`).removeAttribute('disabled');
    document.getElementById(`npi-${id}`).removeAttribute('disabled');
    document.getElementById(`reqdate-${id}`).removeAttribute('disabled');
    document.getElementById(`reqamount-${id}`).removeAttribute('disabled');
    document.getElementById(`createddate-${id}`).removeAttribute('disabled');
    document.getElementById(`lienamount-${id}`).removeAttribute('disabled');
    document
      .getElementById(`authorityRequested-${id}`)
      .removeAttribute('disabled');
    document.getElementById(`provider-${id}`).classList.remove('d-block');
    document.getElementById(`provider-${id}`).classList.add('d-none');
    document.getElementById(`providerList-${id}`).classList.remove('d-none');
    document.getElementById(`providerList-${id}`).classList.add('d-block');
    this.claimantOldState.provider_name_business = (<HTMLElement>(
      document.getElementById(`provider-${id}`)
    )).innerText;
    this.claimantOldState.tax_id_number = (<HTMLInputElement>(
      document.getElementById(`tax-${id}`)
    )).value
      ? (<HTMLInputElement>document.getElementById(`tax-${id}`)).value
      : null;
    this.claimantOldState.lien_type = (<HTMLInputElement>(
      document.getElementById(`type-${id}`)
    )).value
      ? (<HTMLInputElement>document.getElementById(`type-${id}`)).value
      : null;
    this.claimantOldState.provider_npi_number = (<HTMLInputElement>(
      document.getElementById(`npi-${id}`)
    )).value
      ? (<HTMLInputElement>document.getElementById(`npi-${id}`)).value
      : null;
    this.claimantOldState.lien_request_date = (<HTMLInputElement>(
      document.getElementById(`reqdate-${id}`)
    )).value
      ? (<HTMLInputElement>document.getElementById(`reqdate-${id}`)).value
      : null;
    this.claimantOldState.lien_created = (<HTMLInputElement>(
      document.getElementById(`createddate-${id}`)
    )).value
      ? (<HTMLInputElement>document.getElementById(`createddate-${id}`)).value
      : null;
    this.claimantOldState.lien_amount = (<HTMLInputElement>(
      document.getElementById(`lienamount-${id}`)
    )).value
      ? (<HTMLInputElement>document.getElementById(`lienamount-${id}`)).value
      : null;
    this.claimantOldState.lien_requested_amount = (<HTMLInputElement>(
      document.getElementById(`reqamount-${id}`)
    )).value
      ? (<HTMLInputElement>document.getElementById(`reqamount-${id}`)).value
      : null;
    let editBut = document.querySelectorAll('.edit');
    for (let i = 0; i < editBut.length; i++) {
      editBut[i].setAttribute('disabled', 'disabled');
    }
  }

  setHidden(id: string, value: boolean) {
    const element = document.getElementById(id);
    if (element) {
      element.hidden = value;
    }
  }

  setDisplay(id: string, value: boolean) {
    const element = document.getElementById(id);
    if (element) {
      element.hidden = value;
    }
  }

  toggleClass(elementId, className) {
    const element = document.getElementById(elementId);
    if (element) {
      element.classList.remove('d-block');
      element.classList.remove('d-none');
      element.classList.add(className);
    }
  }

  saveEditable(id: string, content) {
    const disableFields = [
      'tax',
      'type',
      'reqdate',
      'reqamount',
      'createddate',
      'npi',
      'lienamount',
      'assignedType',
      'authorityRequested',
    ];

    disableFields.forEach((field) => {
      const element = document.getElementById(`${field}-${id}`);
      if (element) {
        element.setAttribute('disabled', 'disabled');
      }
    });

    const toggleElements = ['provider', 'providerList'];
    toggleElements.forEach((element) => {
      const currentElement = document.getElementById(`${element}-${id}`);
      const otherElement = document.getElementById(
        `${element === 'provider' ? 'providerList' : 'provider'}-${id}`
      );

      if (currentElement && otherElement) {
        currentElement.classList.remove('d-none');
        currentElement.classList.add('d-block');
        otherElement.classList.remove('d-block');
        otherElement.classList.add('d-none');
      }
    });

    const getInputValue = (field: string) => {
      const inputElement = document.getElementById(
        `${field}-${id}`
      ) as HTMLInputElement;
      return inputElement?.value || null;
    };

    const providername = getInputValue('providerListName');
    const taxid = getInputValue('tax');
    const type = getInputValue('type');
    const reqamount = getInputValue('reqamount');
    const authorityRequested = getInputValue('authorityRequested');
    const assignedType = getInputValue('assignedType');
    const reqdate = getInputValue('reqdate');
    const createddate = getInputValue('createddate');
    const npi = getInputValue('npi');
    const lienAmount = getInputValue('lienamount');

    const reqBody = {
      provider_name_business: providername,
      provider_npi_number: npi,
      lien_amount: lienAmount,
      tax_id_number: taxid,
      lien_type: type,
      lien_request_date: reqdate
        ? this.datePipe.transform(reqdate, this.serverDateFormat)
        : null,
      lien_created: createddate
        ? this.datePipe.transform(createddate, this.serverDateFormat)
        : null,
      lien_requested_amount: reqamount,
      authority_requested: authorityRequested,
      assigned: assignedType,
    };

    if (!this.deepEqual(reqBody, this.claimantOldState)) {
      if (
        this.claimantOldState.provider_name_business.trim() !== providername
      ) {
        if (!this._selectedProvider) {
          const providerListNameElement = document.getElementById(
            `providerListName-${id}`
          ) as HTMLInputElement;
          providerListNameElement.value = '';
          this.preloader = false;
          this.setHidden(`edit-${id}`, true);
          this.setHidden(`save-${id}`, false);
          this.toggleClass(`provider-${id}`, 'd-none');
          this.toggleClass(`providerList-${id}`, 'd-block');
          this.addModal.open(content, {
            ariaDescribedBy: 'modal-basic-title',
            windowClass: 'groupModal',
            size: 'sm',
            centered: true,
          });
        } else {
          this.http
            .put(
              `${environment.fireStoreApiUrl}/api/v1/liens/editable/${id}/${this.claimNumber}`,
              reqBody
            )
            .subscribe((resp) => {
              if (resp) {
                this.handleApiResponse(id);
              }
            });
        }
      } else {
        this.http
          .put(
            `${environment.fireStoreApiUrl}/api/v1/liens/editable/${id}/${this.claimNumber}`,
            reqBody
          )
          .subscribe((resp) => {
            if (resp) {
              this.handleApiResponse(id);
            }
          });
      }
    }

    this.setHidden(`cancel-${id}`, true);
    this.setHidden(`delete-${id}`, false);
  }

  handleApiResponse(id: string) {
    this.blockUI.start();
    this.claimantTable = [];

    this.http
      .get<GetLiens>(
        `${environment.fireStoreApiUrl}/api/v1/liens/${this.claimNumber}/${this.limosId}/`
      )
      .subscribe((claimData) => {
        if (claimData.details.lien_claimants) {
          for (let i = 0; i < claimData.details.lien_claimants.length; i++) {
            const sav = this.calculateSaving(
              claimData.details.lien_claimants[i]
            );
            this.claimantTable.push({
              provider_name_business:
                claimData.details.lien_claimants[i].provider_name_business,
              tax_id_number: claimData.details.lien_claimants[i].tax_id_number,
              lien_type: claimData.details.lien_claimants[i].lien_type,
              provider_npi_number:
                claimData.details.lien_claimants[i].provider_npi_number,
              lien_request_date:
                claimData.details.lien_claimants[i].lien_request_date,
              lien_created: claimData.details.lien_claimants[i].lien_created,
              lien_amount: claimData.details.lien_claimants[i].lien_amount,
              lien_requested_amount:
                claimData.details.lien_claimants[i].lien_requested_amount,
              authority_requested:
                claimData.details.lien_claimants[i].authority_requested,
              assigned: claimData.details.lien_claimants[i].assigned,
              case_status: claimData.details.liens_profile.case_status,
              lien_resolved_date:
                claimData.details.lien_claimants[i].lien_resolved_date,
              lien_resolved_amount:
                claimData.details.lien_claimants[i].lien_resolved_amount,
              id: claimData.details.lien_claimants[i].id,
              saving: sav || null,
            });
          }
        }

        this._selectedProvider = false;
        this.setHidden(`edit-${id}`, true);
        this.setHidden(`save-${id}`, false);

        let editBut = document.querySelectorAll('.edit');
        for (let i = 0; i < editBut.length; i++) {
          editBut[i].removeAttribute('disabled');
        }

        this.blockUI.stop();
      });
  }

  calculateSaving(claimant: any): number | null {
    if (claimant.lien_resolved_amount && claimant.lien_requested_amount) {
      let num =
        (1 - claimant.lien_resolved_amount / claimant.lien_requested_amount) *
        100;
      return Math.round(num * 100) / 100;
    }
    return null;
  }

  cancelEditable(id: string) {
    (<HTMLInputElement>document.getElementById(`provider-${id}`)).value =
      this.claimantOldState.provider_name_business;
    (<HTMLInputElement>document.getElementById(`tax-${id}`)).value =
      this.claimantOldState.tax_id_number;
    (<HTMLInputElement>document.getElementById(`type-${id}`)).value =
      this.claimantOldState.lien_type;
    (<HTMLInputElement>document.getElementById(`reqdate-${id}`)).value =
      this.claimantOldState.lien_request_date;
    (<HTMLInputElement>document.getElementById(`createddate-${id}`)).value =
      this.claimantOldState.lien_created;
    (<HTMLInputElement>document.getElementById(`reqamount-${id}`)).value =
      this.claimantOldState.lien_requested_amount;
    (<HTMLInputElement>document.getElementById(`npi-${id}`)).value =
      this.claimantOldState.provider_npi_number;
    (<HTMLInputElement>document.getElementById(`lienamount-${id}`)).value =
      this.claimantOldState.lien_amount;

    document.getElementById(`tax-${id}`).setAttribute('disabled', 'disabled');
    document.getElementById(`type-${id}`).setAttribute('disabled', 'disabled');
    document
      .getElementById(`reqdate-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`reqamount-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`createddate-${id}`)
      .setAttribute('disabled', 'disabled');
    document.getElementById(`npi-${id}`).setAttribute('disabled', 'disabled');
    document
      .getElementById(`lienamount-${id}`)
      .setAttribute('disabled', 'disabled');
    document.getElementById(`provider-${id}`).classList.remove('d-none');
    document.getElementById(`provider-${id}`).classList.add('d-block');
    document.getElementById(`providerList-${id}`).classList.remove('d-block');
    document.getElementById(`providerList-${id}`).classList.add('d-none');

    document.getElementById(`edit-${id}`).hidden = false;
    document.getElementById(`save-${id}`).hidden = true;
    document.getElementById(`cancel-${id}`).hidden = true;
    document.getElementById(`delete-${id}`).hidden = false;

    let editBut = document.querySelectorAll('.edit');
    for (let i = 0; i < editBut.length; i++) {
      editBut[i].removeAttribute('disabled');
    }
  }
  public openDeleteClaimantModal = (content, id: string): void => {
    this.addModal.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size: 'sm',
      centered: true,
    });
    this.deleClaimantEntry = id;
  };
  makeEditableTime(id: string) {
    this.rowidTime = '';
    this.rowidTime = id;
    document.getElementById(`editTime-${id}`).hidden = true;
    document.getElementById(`deleteTime-${id}`).hidden = true;
    document.getElementById(`saveTime-${id}`).hidden = false;
    document.getElementById(`cancelTime-${id}`).hidden = false;
    document.getElementById(`log_date-${id}`).removeAttribute('disabled');
    document.getElementById(`provider_name-${id}`).removeAttribute('disabled');
    document.getElementById(`time_type-${id}`).removeAttribute('disabled');
    document.getElementById(`note-${id}`).removeAttribute('disabled');
    document.getElementById(`time_spent-${id}`).removeAttribute('disabled');
    this.timeState.log_date = (<HTMLInputElement>(
      document.getElementById(`log_date-${id}`)
    )).value
      ? (<HTMLInputElement>document.getElementById(`log_date-${id}`)).value
      : null;
    this.timeState.provider = (<HTMLInputElement>(
      document.getElementById(`provider_name-${id}`)
    )).value
      ? (<HTMLInputElement>document.getElementById(`provider_name-${id}`)).value
      : null;
    this.timeState.time_type = (<HTMLInputElement>(
      document.getElementById(`time_type-${id}`)
    )).value
      ? (<HTMLInputElement>document.getElementById(`time_type-${id}`)).value
      : null;
    this.timeState.note = (<HTMLInputElement>(
      document.getElementById(`note-${id}`)
    )).value
      ? (<HTMLInputElement>document.getElementById(`note-${id}`)).value
      : null;
    this.timeState.time_spent = (<HTMLInputElement>(
      document.getElementById(`time_spent-${id}`)
    )).value
      ? (<HTMLInputElement>document.getElementById(`time_spent-${id}`)).value
      : null;
    let editBut = document.querySelectorAll('.editTime');
    for (let i = 0; i < editBut.length; i++) {
      editBut[i].setAttribute('disabled', 'disabled');
    }
  }

  saveEditableTime(id: string) {
    document
      .getElementById(`log_date-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`provider_name-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`time_type-${id}`)
      .setAttribute('disabled', 'disabled');
    document.getElementById(`note-${id}`).setAttribute('disabled', 'disabled');
    document
      .getElementById(`time_spent-${id}`)
      .setAttribute('disabled', 'disabled');
    let rawString = (<HTMLInputElement>(
      document.getElementById(`time_spent-${id}`)
    )).value;
    let num = Number(rawString.replace(' Hrs', '')) * 3600;
    let reqBody = {
      log_date: this.toServerDateFormat(`log_date-${id}`),
      provider_name_business: (<HTMLInputElement>(
        document.getElementById(`provider_name-${id}`)
      )).value,
      time_type: (<HTMLInputElement>document.getElementById(`time_type-${id}`))
        .value,
      note: (<HTMLInputElement>document.getElementById(`note-${id}`)).value,
      time_spent: Math.round(num),
    };
    if (!this.deepEqual(reqBody, this.timeState)) {
      this.http
        .patch(
          `${environment.fireStoreApiUrl}/api/v1/liens/time-spent/editable/${id}`,
          reqBody
        )
        .subscribe();
    }

    document.getElementById(`editTime-${id}`).hidden = false;
    document.getElementById(`saveTime-${id}`).hidden = true;
    document.getElementById(`cancelTime-${id}`).hidden = true;
    document.getElementById(`deleteTime-${id}`).hidden = false;

    let editBut = document.querySelectorAll('.editTime');
    for (let i = 0; i < editBut.length; i++) {
      editBut[i].removeAttribute('disabled');
    }
  }

  private toServerDateFormat(dateEleId: string) {
    const date = (document.getElementById(dateEleId) as HTMLInputElement).value;
    return date ? this.datePipe.transform(date, this.serverDateFormat) : null;
  }

  cancelEditableTime(id: string) {
    (<HTMLInputElement>document.getElementById(`log_date-${id}`)).value =
      this.timeState.log_date;
    (<HTMLInputElement>document.getElementById(`provider_name-${id}`)).value =
      this.timeState.provider;
    (<HTMLInputElement>document.getElementById(`time_type-${id}`)).value =
      this.timeState.time_type;
    (<HTMLInputElement>document.getElementById(`note-${id}`)).value =
      this.timeState.note;
    (<HTMLInputElement>document.getElementById(`time_spent-${id}`)).value =
      this.timeState.time_spent;

    document
      .getElementById(`log_date-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`provider_name-${id}`)
      .setAttribute('disabled', 'disabled');
    document
      .getElementById(`time_type-${id}`)
      .setAttribute('disabled', 'disabled');
    document.getElementById(`note-${id}`).setAttribute('disabled', 'disabled');
    document
      .getElementById(`time_spent-${id}`)
      .setAttribute('disabled', 'disabled');
    document.getElementById(`editTime-${id}`).hidden = false;
    document.getElementById(`saveTime-${id}`).hidden = true;
    document.getElementById(`cancelTime-${id}`).hidden = true;
    document.getElementById(`deleteTime-${id}`).hidden = false;

    let editBut = document.querySelectorAll('.editTime');
    for (let i = 0; i < editBut.length; i++) {
      editBut[i].removeAttribute('disabled');
    }
  }
  openDeleteTimeEntryModal(content, id: string) {
    this.addModal.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size: 'sm',
      centered: true,
    });
    this.deleTimeEntry = id;
  }
  deepEqual(obj1, obj2) {
    // Check if both parameters are objects
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      return obj1 === obj2;
    }

    // Get the keys of the objects
    const keys1 = Object.keys(obj1 || {});
    const keys2 = Object.keys(obj2 || {});

    // Check if the number of keys is the same
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Iterate over the keys and compare the properties
    for (let key of keys1) {
      if (!obj2.hasOwnProperty(key) || !this.deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  exportToPdf() {
    var element = document.getElementById('limosPage');
    var opt = {
      margin: 0.1,
      filename: `${this.claimNumber}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      jsPDF: { unit: 'in', format: 'a3', orientation: 'landscape' },
    };
    html2pdf.default(element, opt);
  }

  addLien(content) {
    if (
      (<HTMLInputElement>document.getElementById(`provider_name`)).value === ''
    ) {
      (<HTMLInputElement>document.getElementById(`provider_name`)).focus();
    } else if (this.selectedLienType === 'Select a lien type') {
      (<HTMLInputElement>document.getElementById(`type_add`)).focus();
    } else if (this.selectedAssignedType === 'Select a assigned type') {
      (<HTMLInputElement>document.getElementById(`assigned_type_add`)).focus();
    } else {
      let providername = (<HTMLInputElement>(
        document.getElementById(`provider_name`)
      )).value
        ? (<HTMLInputElement>document.getElementById(`provider_name`)).value
        : null;
      let taxid = (<HTMLInputElement>document.getElementById(`tax_add`)).value
        ? (<HTMLInputElement>document.getElementById(`tax_add`)).value
        : null;
      let type = (<HTMLInputElement>document.getElementById(`type_add`)).value
        ? (<HTMLInputElement>document.getElementById(`type_add`)).value
        : null;
      let reqamount = (<HTMLInputElement>(
        document.getElementById(`reqamount_add`)
      )).value
        ? (<HTMLInputElement>document.getElementById(`reqamount_add`)).value
        : null;
      let authorityRequested = (<HTMLInputElement>(
        document.getElementById(`authorityRequested_add`)
      )).value
        ? (<HTMLInputElement>document.getElementById(`authorityRequested_add`))
            .value
        : null;
      let assignedType = (<HTMLInputElement>(
        document.getElementById(`assigned_type_add`)
      )).value
        ? (<HTMLInputElement>document.getElementById(`assigned_type_add`)).value
        : null;
      let reqdate = (<HTMLInputElement>document.getElementById(`reqdate_add`))
        .value
        ? (<HTMLInputElement>document.getElementById(`reqdate_add`)).value
        : null;
      let createddate = (<HTMLInputElement>(
        document.getElementById(`createddate_add`)
      )).value
        ? (<HTMLInputElement>document.getElementById(`createddate_add`)).value
        : null;
      let npi = (<HTMLInputElement>document.getElementById(`npi_add`)).value
        ? (<HTMLInputElement>document.getElementById(`npi_add`)).value
        : null;
      let lienAmount = (<HTMLInputElement>(
        document.getElementById(`lien_amount_add`)
      )).value
        ? (<HTMLInputElement>document.getElementById(`lien_amount_add`)).value
        : null;
      let reqBody = {
        provider_name_business: providername,
        provider_npi_number: npi,
        lien_amount: lienAmount,
        tax_id_number: taxid,
        lien_type: type,
        lien_request_date: reqdate
          ? this.datePipe.transform(reqdate, this.serverDateFormat)
          : null,
        lien_created: createddate
          ? this.datePipe.transform(createddate, this.serverDateFormat)
          : null,
        lien_requested_amount: reqamount,
        authority_requested: authorityRequested,
        assigned: assignedType,
      };

      if (reqBody) {
        this.http
          .put(
            `${environment.fireStoreApiUrl}/api/v1/liens/editable/0/${this.claimNumber}/`,
            reqBody
          )
          .subscribe((data) => {
            if (data) {
              this.blockUI.start();
              this.claimantTable = [];
              this.http
                .get<GetLiens>(
                  `${environment.fireStoreApiUrl}/api/v1/liens/${this.claimNumber}/${this.limosId}/`
                )
                .subscribe((claimData) => {
                  if (claimData.details.lien_claimants) {
                    (<HTMLInputElement>(
                      document.getElementById(`provider_name`)
                    )).value = '';
                    (<HTMLInputElement>(
                      document.getElementById(`tax_add`)
                    )).value = '';
                    (<HTMLInputElement>(
                      document.getElementById(`type_add`)
                    )).value = '';
                    (<HTMLInputElement>(
                      document.getElementById(`reqamount_add`)
                    )).value = '';
                    (<HTMLInputElement>(
                      document.getElementById(`authorityRequested_add`)
                    )).value = '';
                    (<HTMLInputElement>(
                      document.getElementById(`assigned_type_add`)
                    )).value = '';
                    (<HTMLInputElement>(
                      document.getElementById(`reqdate_add`)
                    )).value = '';
                    (<HTMLInputElement>(
                      document.getElementById(`createddate_add`)
                    )).value = '';
                    (<HTMLInputElement>(
                      document.getElementById(`npi_add`)
                    )).value = '';
                    (<HTMLInputElement>(
                      document.getElementById(`lien_amount_add`)
                    )).value = '';
                    for (
                      let i = 0;
                      i < claimData.details.lien_claimants.length;
                      i++
                    ) {
                      let sav;
                      if (
                        claimData.details.lien_claimants[i]
                          .lien_resolved_amount &&
                        claimData.details.lien_claimants[i]
                          .lien_requested_amount
                      ) {
                        let num =
                          (1 -
                            claimData.details.lien_claimants[i]
                              .lien_resolved_amount /
                              claimData.details.lien_claimants[i]
                                .lien_requested_amount) *
                          100;
                        sav = Math.round(num * 100) / 100;
                      }
                      this.claimantTable.push({
                        provider_name_business:
                          claimData.details.lien_claimants[i]
                            .provider_name_business,
                        tax_id_number:
                          claimData.details.lien_claimants[i].tax_id_number,
                        lien_type:
                          claimData.details.lien_claimants[i].lien_type,
                        provider_npi_number:
                          claimData.details.lien_claimants[i]
                            .provider_npi_number,
                        lien_request_date:
                          claimData.details.lien_claimants[i].lien_request_date,
                        lien_created:
                          claimData.details.lien_claimants[i].lien_created,
                        lien_amount:
                          claimData.details.lien_claimants[i].lien_amount,
                        lien_requested_amount:
                          claimData.details.lien_claimants[i]
                            .lien_requested_amount,
                        authority_requested:
                          claimData.details.lien_claimants[i]
                            .authority_requested,
                        assigned: claimData.details.lien_claimants[i].assigned,
                        case_status:
                          claimData.details.liens_profile.case_status,
                        lien_resolved_date:
                          claimData.details.lien_claimants[i]
                            .lien_resolved_date,
                        lien_resolved_amount:
                          claimData.details.lien_claimants[i]
                            .lien_resolved_amount,
                        id: claimData.details.lien_claimants[i].id,
                        saving: sav || null,
                      });
                    }
                  }
                  this.blockUI.stop();
                  this._selectedProvider = false;
                  this.selectedLienType = 'Select a lien type';
                  this.selectedAssignedType = 'Select a assigned type';
                });
            }
          });
      }
    }
  }

  search: OperatorFunction<string, readonly any[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      tap((term) => {
        if (term.length <= 2) {
          this.preloader = false;
        }
      }),
      debounceTime(1000),
      distinctUntilChanged(),
      tap((term) => {
        if (term.length > 2) {
          this.preloader = true;
        }
      }),
      switchMap((term) =>
        term.length < 3
          ? []
          : this.http
              .get<GetProviderNameResponse>(
                `${environment.fireStoreApiUrl}/api/v2/liens/provider-names/?q=${term}&limit=20&offset=1`
              )
              .pipe(
                tap((result) => {
                  if (result) {
                    this.preloader = false;
                  }
                }),
                map((results) => {
                  this.providerSerchResult = results;
                  const startsWithResults =
                    this.providerSerchResult.details.hits.filter(
                      (result): any =>
                        result.provider_name_business
                          .toLowerCase()
                          .startsWith(term.toLowerCase())
                    );
                  const otherResults =
                    this.providerSerchResult.details.hits.filter(
                      (result) =>
                        !result.provider_name_business
                          .toLowerCase()
                          .startsWith(term.toLowerCase())
                    );
                  return [...startsWithResults, ...otherResults];
                }),
                catchError(() => {
                  return of([]);
                })
              )
      )
    );

  search2: OperatorFunction<string, readonly any[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      tap((term) => {
        if (term.length <= 2) {
          this.preloader2 = false;
        }
      }),
      debounceTime(1000),
      distinctUntilChanged(),
      tap((term) => {
        if (term.length > 2) {
          this.preloader2 = true;
        }
      }),
      switchMap((term) =>
        term.length < 3
          ? []
          : this.http
              .get<GetProviderNameResponse>(
                `${environment.fireStoreApiUrl}/api/v2/liens/provider-names/?q=${term}&limit=20&offset=1`
              )
              .pipe(
                tap((result) => {
                  if (result) {
                    this.preloader2 = false;
                  }
                }),
                map((results) => {
                  this.providerSerchResult = results;
                  const startsWithResults =
                    this.providerSerchResult.details.hits.filter((result) =>
                      result.provider_name_business
                        .toLowerCase()
                        .startsWith(term.toLowerCase())
                    );
                  const otherResults =
                    this.providerSerchResult.details.hits.filter(
                      (result) =>
                        !result.provider_name_business
                          .toLowerCase()
                          .startsWith(term.toLowerCase())
                    );
                  return [...startsWithResults, ...otherResults];
                }),
                catchError(() => {
                  return of([]);
                })
              )
      )
    );

  search3: OperatorFunction<string, readonly any[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      tap((term) => {
        if (term.length > 2) {
          this.preloader3 = true;
        }
      }),
      switchMap((term) =>
        term.length < 3
          ? []
          : this.http
              .get<GetProviderNameResponse>(
                `${environment.fireStoreApiUrl}/api/v2/liens/provider-names/?q=${term}&limit=20&offset=1`
              )
              .pipe(
                tap((result) => {
                  if (result) {
                    this.preloader3 = false;
                  }
                }),
                map((results) => {
                  this.providerSerchResult = results;
                  const startsWithResults =
                    this.providerSerchResult.details.hits.filter((result) =>
                      result.provider_name_business
                        .toLowerCase()
                        .startsWith(term.toLowerCase())
                    );
                  const otherResults =
                    this.providerSerchResult.details.hits.filter(
                      (result) =>
                        !result.provider_name_business
                          .toLowerCase()
                          .startsWith(term.toLowerCase())
                    );
                  return [...startsWithResults, ...otherResults];
                }),
                catchError(() => {
                  return of([]);
                })
              )
      )
    );

  search4: OperatorFunction<string, readonly any[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      tap((term) => {
        if (term.length > 2) {
          this.preloader4 = true;
        }
      }),
      switchMap((term) =>
        term.length < 3
          ? []
          : this.http
              .get<GetProviderNameResponse>(
                `${environment.fireStoreApiUrl}/api/v2/liens/provider-names/?q=${term}&limit=20&offset=1`
              )
              .pipe(
                tap((result) => {
                  if (result) {
                    this.preloader4 = false;
                  }
                }),
                map((results) => {
                  this.providerSerchResult = results;
                  const startsWithResults =
                    this.providerSerchResult.details.hits.filter((result) =>
                      result.provider_name_business
                        .toLowerCase()
                        .startsWith(term.toLowerCase())
                    );
                  const otherResults =
                    this.providerSerchResult.details.hits.filter(
                      (result) =>
                        !result.provider_name_business
                          .toLowerCase()
                          .startsWith(term.toLowerCase())
                    );
                  return [...startsWithResults, ...otherResults];
                }),
                catchError(() => {
                  return of([]);
                })
              )
      )
    );

  formatter = (x: any) => x.provider_name_business;
  selectedProvider(selectedProvider: Hit) {
    this._selectedProvider = true;
    (<HTMLInputElement>(
      document.getElementById(`provider-${this.rowid}`)
    )).value = '';
    (<HTMLInputElement>(
      document.getElementById(`provider-${this.rowid}`)
    )).value = selectedProvider.provider_name_business;
    (<HTMLInputElement>document.getElementById(`npi-${this.rowid}`)).value = '';
    (<HTMLInputElement>document.getElementById(`npi-${this.rowid}`)).value =
      selectedProvider?.npi ? selectedProvider?.npi.toString() : 'Not Listed';
    (<HTMLInputElement>document.getElementById(`tax-${this.rowid}`)).value = '';
    (<HTMLInputElement>document.getElementById(`tax-${this.rowid}`)).value =
      selectedProvider?.tin ? selectedProvider?.tin.toString() : 'Not Listed';
  }
  selectedProviderAdd(selectedProvider: Hit) {
    this._selectedProviderAdd = true;
    (<HTMLInputElement>document.getElementById(`npi_add`)).value = '';
    (<HTMLInputElement>document.getElementById(`npi_add`)).value =
      selectedProvider?.npi ? selectedProvider?.npi.toString() : 'Not Listed';
    (<HTMLInputElement>document.getElementById(`tax_add`)).value = '';
    (<HTMLInputElement>document.getElementById(`tax_add`)).value =
      selectedProvider?.tin ? selectedProvider?.tin.toString() : 'Not Listed';
  }

  selectedProviderTime(selectedProvider: Hit) {
    (<HTMLInputElement>(
      document.getElementById(`provider_name-${this.rowidTime}`)
    )).value = '';
    (<HTMLInputElement>(
      document.getElementById(`provider_name-${this.rowidTime}`)
    )).value = selectedProvider.provider_name_business;
  }
  public chooseLimosStatus = (e: any, content): void => {
    if (e.target.value === 'limos_status_close') {
      let check_resolved = [];
      this.claimantTable.forEach((x) => {
        if (x.lien_requested_amount > 0) {
          if (x.lien_resolved_amount > 0) check_resolved.push(1);
        }
      });
      if (check_resolved.length !== this.claimantTable.length) {
        this.openStatus(content);
        this.limosStatus.setValue('limos_status_open');
      } else {
        this.limos_status = true;
      }
    }
  };
  private openStatus(content) {
    this.addModal.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size: 'sm',
      centered: true,
    });
  }
  public updateLimosStatus(content): void {
    const body = {
      claim_number: this.claimNumber,
      limos_status: 'close',
    };
    this.http
      .post<UpdateLimosResponse>(
        `${environment.fireStoreApiUrl}/api/v1/liens/update-limos-status/`,
        body
      )
      .subscribe((resp) => {
        if (
          resp.details.message === 'Limos status has been updated successfully.'
        ) {
          this.addModal.open(content, {
            ariaDescribedBy: 'modal-basic-title',
            windowClass: 'groupModal',
            size: 'sm',
            centered: true,
          });
        }
      });
  }
  adjusterSave1(): void {
    const formData = {
      phone_1: (document.getElementById('phone_1') as HTMLInputElement).value,
      email_1: (document.getElementById('email_1') as HTMLInputElement).value,
    };
    const initialData = {
      phone_1: this.limosTable?.phone_1,
      email_1: this.limosTable?.email_1,
    };
    const updatedData = {};
    for (const key in formData) {
      if (formData.hasOwnProperty(key) && formData[key] !== initialData[key]) {
        updatedData[key] = formData[key];
      }
    }
    if (Object.keys(updatedData).length === 0) {
      this.emptyAdjForm1 = false;
    } else {
      this.http
        .put(
          `${environment.fireStoreApiUrl}/api/v1/liens/update-limos-data/${this.claimNumber}/`,
          updatedData
        )
        .subscribe((resp: any) => {
          if (resp) {
            this.emptyAdjForm1 = false;
            this.enableElements(
              ['enable-edit-adjuster-save1'],
              ['phone_1', 'email_1']
            );
            this.toastr.success(resp?.details?.message);
          }
        });
    }
  }

  adjusterSave2(): void {
    const formData = {
      defense_attorney: (
        document.getElementById('defense_attorney') as HTMLInputElement
      ).value,
      phone_2: (document.getElementById('phone_2') as HTMLInputElement).value,
      email_2: (document.getElementById('email_2') as HTMLInputElement).value,
    };
    const initialData = {
      defense_attorney: this.limosTable?.defense_attorney,
      phone_2: this.limosTable?.phone_2,
      email_2: this.limosTable?.email_2,
    };
    const updatedData = {};
    for (const key in formData) {
      if (formData.hasOwnProperty(key) && formData[key] !== initialData[key]) {
        updatedData[key] = formData[key];
      }
    }
    if (Object.keys(updatedData).length === 0) {
      this.emptyAdjForm2 = false;
    } else {
      this.http
        .put(
          `${environment.fireStoreApiUrl}/api/v1/liens/update-limos-data/${this.claimNumber}/`,
          updatedData
        )
        .subscribe((resp: any) => {
          if (resp) {
            this.emptyAdjForm2 = false;
            this.enableElements(
              ['enable-edit-adjuster-save2'],
              ['defense_attorney', 'phone_2', 'email_2']
            );
            this.toastr.success(resp?.details?.message);
          }
        });
    }
  }

  adjusterSave3(): void {
    const formData = {
      assigned_to: (document.getElementById('assigned_to') as HTMLInputElement)
        .value,
      case_manager: (
        document.getElementById('case_manager') as HTMLInputElement
      ).value,
      email_3: (document.getElementById('email_3') as HTMLInputElement).value,
    };
    const initialData = {
      assigned_to: this.limosTable?.assigned_to,
      case_manager: this.limosTable?.case_manager,
      email_3: this.limosTable?.email_3,
    };
    const updatedData = {};
    for (const key in formData) {
      if (formData.hasOwnProperty(key) && formData[key] !== initialData[key]) {
        updatedData[key] = formData[key];
      }
    }
    if (Object.keys(updatedData).length === 0) {
      this.emptyAdjForm3 = false;
    } else {
      this.http
        .put(
          `${environment.fireStoreApiUrl}/api/v1/liens/update-limos-data/${this.claimNumber}/`,
          updatedData
        )
        .subscribe((resp: any) => {
          if (resp) {
            this.emptyAdjForm3 = false;
            this.enableElements(
              ['enable-edit-adjuster-save3'],
              ['assigned_to', 'case_manager', 'email_3']
            );
            this.toastr.success(resp?.details?.message);
          }
        });
    }
  }

  adminSave(): void {
    const formData = {
      claimSettledBy: (
        document.getElementById('claimSettledBy') as HTMLInputElement
      ).value,
      case_number: (document.getElementById('adjNo') as HTMLInputElement).value,
    };

    const initialData = {
      administrator: this.claimTable.administrator,
      claimSettledBy: this.claimTable.claimSettledBy,
      case_number: this.claimTable.adjNo,
    };
    const updatedLimosData = {};
    const updatedAdjNoData = {};
    for (const key in formData) {
      if (formData.hasOwnProperty(key) && formData[key] !== initialData[key]) {
        if (key == 'claimSettledBy') {
          updatedLimosData[key] = formData[key];
        } else if (key == 'case_number') {
          updatedAdjNoData[key] = formData[key];
        }
      }
    }
    if (Object.keys(updatedLimosData).length === 0) {
      this.emptyadminForm = false;
    } else {
      this.http
        .put(
          `${environment.fireStoreApiUrl}/api/v1/liens/update-limos-data/${this.claimNumber}/`,
          updatedLimosData
        )
        .subscribe((resp: any) => {
          if (resp) {
            this.toastr.success(resp?.details?.message);
            this.emptyadminForm = false;
            this.enableElements(
              ['enable-edit-admin-save'],
              ['adjNo', 'claimSettledBy']
            );
          }
        });
    }

    if (Object.keys(updatedAdjNoData).length === 0) {
      this.emptyadminForm = false;
    } else {
      this.http
        .put(
          `${environment.fireStoreApiUrl}/api/v1/liens/update_adj_number/${this.claimNumber}/${this.limosId}/`,
          updatedAdjNoData
        )
        .subscribe((resp: any) => {
          if (resp) {
            this.toastr.success(resp?.details?.message);
            this.emptyadminForm = false;
            this.enableElements(
              ['enable-edit-admin-save'],
              ['adjNo', 'claimSettledBy']
            );
          }
        });
    }
  }
  public confirmTypeaheadSelect(): void {
    this.addModal.dismissAll();
  }

  public isCheckAllTimeMgmt(): boolean {
    return (
      this.timeTable?.length ===
      this.timeTable.filter((time) => time?.checked).length
    );
  }

  public checkAllTimeMgmt(event) {
    if (this.timeTable?.length) {
      this.timeTable.map((time) => (time.checked = event.target.checked));
    }
  }

  enableElements(enableIds: string[], disableIds: string[] = []) {
    enableIds.forEach((id) => {
      document.getElementById(id).removeAttribute('disabled');
    });

    disableIds.forEach((id) => {
      document.getElementById(id).setAttribute('disabled', 'disabled');
    });
  }

  openPopup(content, size = 'md') {
    this.addModal.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size,
      centered: true,
    });
  }

  private _populateNoteTable(): void {
    this.http
      .get<GetNotepad>(
        `${environment.fireStoreApiUrl}/api/v1/liens/limos-notepad/all/?claim_number=${this.claimNumber}`
      )
      .subscribe((resp) => {
        if (resp.details.length > 0) {
          this.noteTable = [];
          for (let i = 0; i < resp.details.length; i++) {
            this.noteTable.push({
              id: resp.details[i].id,
              created_at: resp.details[i].created_at,
              modified_at: resp.details[i].modified_at,
              wc_claim_number: resp.details[i].wc_claim_number,
              note_type: resp.details[i].note_type,
              note: resp.details[i].note,
              log_date: resp.details[i].log_date,
            });
          }
        }
      });
  }

  onNoteFileSelected(event: any) {
    this.selectedNoteFiles = Array.from(event.target.files).filter((file) =>
      this.isValidFileType(file)
    );
    this.selectedNoteFiles.map((file) => {
      file.formattedSize = this.formatFileSize(file.size);
      return file;
    });
  }

  isValidFileType(file: any): boolean {
    const allowedTypes = [
      'application/pdf',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'image/jpeg',
      'image/png',
    ];
    return allowedTypes.includes(file.type);
  }

  formatFileSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    if (bytes === 0) return '0 Bytes';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const size = bytes / Math.pow(1024, i);
    return `${size.toFixed(2)} ${sizes[i]}`;
  }

  addNoteTags(event: any) {
    const value = event.target.value ? event.target.value.trim() : '';
    const isTagExist = this.selectedNoteTags.find((tag) => tag == value);
    if (value && !isTagExist) {
      this.selectedNoteTags.push(value);
      this.noteForm.get('tags').setValue(this.selectedNoteTags);
    }
    event.target.value = '';
  }

  createNotepad(timeSpentContent) {
    if (this.noteForm.invalid) {
      return;
    }

    this.submitNotepad(timeSpentContent);
  }

  submitNotepad(timeSpentContent) {
    const { access_group, subject, title, body, tags, create_expense_entry } =
      this.noteForm.value;
    const formData = new FormData();

    formData.append('access_group', access_group);
    formData.append('subject', subject);
    formData.append('title', title);
    formData.append('body', body);
    formData.append('tags', tags);
    formData.append('create_expense_entry', create_expense_entry);
    if (this.selectedNoteFiles?.length) {
      formData.append('file', this.selectedNoteFiles[0]);
    }

    if(this.editNoteData.id){
      this.updateNotepad(formData)
    }else{
      this.saveNotepad(formData,timeSpentContent);
    }
  }

  saveNotepad(formData:FormData,timeSpentContent){
    this.limosService
    .createNotepad(formData)
    .pipe(takeUntil(this.unsubscribe$), take(1))
    .subscribe(
      (res: LimosDataResponse<CreateRes>) => {
        this.toastr.success(res?.details?.message);
        this.addModal.dismissAll();
        if (this.createExpenseEntry) {
          this.openPopup(timeSpentContent, 'sm');
        } else {
          this.noteForm.reset();
        }
        this.getNotes();
      },
      ({ error }: HttpErrorResponse) => {
        this.toastr.error(error?.errors[0]);
      }
    );
  }

  updateNotepad(formData){
    this.limosService
    .updateNote(this.editNoteData.id,formData)
    .pipe(takeUntil(this.unsubscribe$), take(1))
    .subscribe(
      (res: LimosDataResponse<CreateRes>) => {
        this.toastr.success(res?.details?.message);
        this.addModal.dismissAll();
        this.noteForm.reset();
        this.editNoteData = null;
        this.getNotes();
      },
      ({ error }: HttpErrorResponse) => {
        this.toastr.error(error?.errors[0]);
      }
    );
  }



  getNotes() {
    this.notesBlockUI.start();
    this.limosService
      .getNotes(this.noteLimit, this.notePage)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((resp: LimosDataResponse<PaginationData<CreateNoteReq>>) => {
          const { hits, estimatedTotalHits } = resp.details;
          this.notes = hits;
          this.noteTotal = estimatedTotalHits;
          this.notesBlockUI.stop();
        }),
        switchMap(() => {
          const noteIds = this.notes.map((note) => note.id);
          return this.limosService.getLimosFileDetails(noteIds);
        })
      )
      .subscribe(
        (noteFileRes: LimosNoteFile[]) => {
          this.notes.forEach((note) => {
            const noteFile = noteFileRes.find(
              (file) => file.limos_notes_id == note.id
            );
            if (noteFile) {
              note['file'] = noteFile;
            }
          });
        },
        (error) => {
          console.error('Error fetching cases:', error);
          this.notesBlockUI.stop();
        }
      );
  }

  onPageChangeNotes(event: any) {
    this.notePage = event;
    this.getNotes();
  }

  onSizeChangeNotes(limit: number) {
    this.noteLimit = limit;
    this.notePage = 1;
    this.getNotes();
  }

  onPageChangeDiaries(event: any) {
    this.diaryPage = event;
    this.getDiaries();
  }

  onSizeChangeDiaries(limit: number) {
    this.diaryLimit = limit;
    this.diaryPage = 1;
    this.getDiaries();
  }

  onSubmitDiary() {
    if (this.diaryForm.invalid) {
      return;
    }
    const dueDate = this.diaryForm.get('due_date').value;
    if (dueDate) {
      this.diaryForm
        .get('due_date')
        .setValue(this.datePipe.transform(dueDate, this.serverDateFormat));
    }

    if(this.editDiaryData.id){
      this.updateDiary();
    }else{
      this.saveDiary();
    }
  }

  saveDiary() {
    this.limosService
      .createDiary(this.diaryForm.value)
      .pipe(takeUntil(this.unsubscribe$), take(1))
      .subscribe(
        (res: LimosDataResponse<CreateRes>) => {
          this.toastr.success(res?.details?.message);
          this.addModal.dismissAll();
          this.getDiaries();
          this.diaryForm.reset();
        },
        ({ error }: HttpErrorResponse) => {
          this.toastr.error(error?.errors[0]);
        }
      );
  }

  updateDiary(){
    this.limosService
      .updateDiary(this.editDiaryData.id,this.diaryForm.value)
      .pipe(takeUntil(this.unsubscribe$), take(1))
      .subscribe(
        (res: LimosDataResponse<CreateRes>) => {
          this.toastr.success(res?.details?.message);
          this.addModal.dismissAll();
          this.getDiaries();
          this.editDiaryData = null;
          this.diaryForm.reset();
        },
        ({ error }: HttpErrorResponse) => {
          this.toastr.error(error?.errors[0]);
        }
      );
  }

  getDiaries() {
    this.diariesBlockUI.start();
    this.limosService
      .getDiaries(this.diaryLimit, this.diaryPage)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp: LimosDataResponse<PaginationData<Diary>>) => {
          const { hits, estimatedTotalHits } = resp.details;
          this.diaries = hits;
          this.diaryTotal = estimatedTotalHits;
          this.diariesBlockUI.stop();
        },
        (error) => {
          console.error('Error fetching cases:', error);
          this.diariesBlockUI.stop();
        }
      );
  }

  createExpense(): void {
    if (!this.noteForm.value.title) {
      return;
    }
    let reqBody: CreateTimeLogReq = {
      time_type: 'Other',
      note: this.noteForm.value.title,
      time_spent: this.noteTimeSpent
        ? Math.round(this.noteTimeSpent * 3600)
        : 0,
      log_date: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    };

    this.limosService.createTimeLog(reqBody).subscribe(
      (res: LimosDataResponse<CreateRes>) => {
        this.toastr.success(res?.details?.message);
        this.addModal.dismissAll();
        this.createExpenseEntry = false;
        this.selectedNoteTags = this.selectedNoteFiles = [];
        this.noteForm.reset();
      },
      ({ error }: HttpErrorResponse) => {
        this.toastr.error(error?.errors[0]);
      }
    );
  }

  openNotefilesInputDialog(notefilesInput) {
    notefilesInput.click();
  }

  handleInvoiceData(invoiceData: Invoice[]) {
    this.invoices = invoiceData;
  }

  downloadNoteFile(file: LimosNoteFile) {
    this.isDownloadStarted[file.id] = true;
    this.limosService.downloadFile(file.id).subscribe(
      (data: any) => {
        window.open(data.details.signed_url, '_blank');

        this.isDownloadStarted[file.id] = false;
      },
      (error) => {
        this.isDownloadStarted[file.id] = false;
        console.error('File preview failed', error);
      }
    );
  }

  editNote(createNote,note: CreateNoteReq){
    this.openPopup(createNote, 'lg');
    this.editNoteData = note;
    const {access_group,subject,title,body,tags,create_expense_entry,file} = note;
    this.noteForm = new FormGroup({
      access_group: new FormControl(access_group, Validators.required),
      subject: new FormControl(subject),
      title: new FormControl(title, Validators.required),
      body: new FormControl(body, Validators.required),
      tags: new FormControl(tags),
      create_expense_entry: new FormControl(create_expense_entry),
    });
    this.noteBodyeditor = body;
    this.selectedNoteTags = tags?.length ? tags.map((tag) => tag['tag']) : [];
    if(file && Object.keys(file).length > 0){
      this.selectedNoteFiles = [{...file,name:file?.file_name}];
    }

  }

  editDiary(createDiary,diary: Diary){
    this.openPopup(createDiary, 'lg');
    const {case_number,case_name,type,due_date,note,assigned_to} = diary;
    this.editDiaryData = diary;
    this.diaryForm = new FormGroup({
      case_number: new FormControl(case_number),
      case_name: new FormControl(case_name),
      type: new FormControl(type),
      due_date: new FormControl(due_date),
      note: new FormControl(note),
      assigned_to: new FormControl(assigned_to),
    });

  }

  deleteNote(id: number) {
    if(confirm('Are you sure you want to delete this note?')){
      this.limosService.deleteNote(id).subscribe(
        (res: LimosDataResponse<CreateRes>) => {
          this.toastr.success(res?.details?.message);
          this.getNotes();
        },
        ({ error }: HttpErrorResponse) => {
          this.toastr.error(error?.errors[0]);
        }
      );
    }
  }

  deleteDiary(id: number) {
    if(confirm('Are you sure you want to delete this diary?')){
      this.limosService.deleteDiary(id).subscribe(
        (res: LimosDataResponse<CreateRes>) => {
          this.toastr.success(res?.details?.message);
          this.getDiaries();
        },
        ({ error }: HttpErrorResponse) => {
          this.toastr.error(error?.errors[0]);
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.subsciptions.forEach((x) => x.unsubscribe());
    this.adjForm1ValueChangeSubscription.unsubscribe();
    this.adjForm2ValueChangeSubscription.unsubscribe();
    this.adjForm3ValueChangeSubscription.unsubscribe();
    this.adminValueChangeSubscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
