<div class="row tab-row">
  <div class="col-12 primary-tab w-auto">
    <ul class="nav nav-tabs border-0">
      <li class="nav-item">
        <button
          class="nav-link"
          [ngClass]="{
            'active search-limos-background': activeTab == 'overview'
          }"
          (click)="activeTab = 'overview'"
        >
          Overview
        </button>
      </li>
      <li class="nav-item">
        <button
          class="nav-link"
          [ngClass]="{
            'active search-limos-background': activeTab == 'documents'
          }"
          (click)="activeTab = 'documents'"
        >
          Documents
        </button>
      </li>
      <li class="nav-item">
        <button
          class="nav-link"
          [ngClass]="{
            'active search-limos-background': activeTab == 'lien-representative'
          }"
          (click)="activeTab = 'lien-representative'"
        >
          Lien Representative
        </button>
      </li>
      <li class="nav-item">
        <button
          class="nav-link"
          [ngClass]="{
            'active search-limos-background': activeTab == 'lien-resolution'
          }"
          (click)="activeTab = 'lien-resolution'"
        >
          Lien Resolution
        </button>
      </li>
      <li class="nav-item">
        <button
          class="nav-link"
          [ngClass]="{
            'active search-limos-background':
              activeTab == 'bill-review-and-payment-data'
          }"
          (click)="activeTab = 'bill-review-and-payment-data'"
        >
          Bill Review & Payment Data
        </button>
      </li>
      <li class="nav-item">
        <button
          class="nav-link"
          [ngClass]="{
            'active search-limos-background': activeTab == 'similar-claims'
          }"
          (click)="activeTab = 'similar-claims'; similarClaimTabClick()"
        >
          Similar Claims
        </button>
      </li>
    </ul>
  </div>
</div>
<div class="container-fluid p-0 claimant-summary-wrapper">
  <ng-container *ngIf="activeTab == 'overview'">
    <div class="row">
      <div class="col-12">
        <div class="card border-0">
          <div class="card-body search-limos-background">
            <div class="row mb-1">
              <div class="col-3">
                <div class="open-close-limos">
                  <span>LIMOS Status</span>
                  <select
                    class="form-control limos-status-select ml-1"
                    [formControl]="limosStatus"
                  >
                    <option value="Open">Open</option>
                    <option value="Closed">Closed</option>
                  </select>
                  <button
                    [disabled]="
                      claimantTable?.file_status === limosStatus.value
                    "
                    (click)="updateLimosStatus()"
                    class="btn btn-sm bg-voilet limos-button-background text-white px-1 py-1 pr-1 pl-1 ml-3"
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div class="col-3 d-flex align-items-center">
                <h4 class="limos-page-title">
                  {{ claimantTable?.provider_name_business || "Not Listed" }}
                </h4>
              </div>
              <div class="col-6 text-right">
                <button
                  *ngIf="claimantTable"
                  class="btn btn-sm limos-button-background text-white px-2 py-1 pr-2 pl-2"
                  (click)="editPrimaryTable()"
                  [disabled]="isPrimaryTableEditable"
                >
                  <em class="fa fas fa-pencil mr-2"></em>Edit
                </button>
                <button
                  *ngIf="claimantTable"
                  class="btn btn-sm limos-button-background text-white px-2 py-1 pr-2 pl-2 ml-2"
                  (click)="savePrimaryTable()"
                  [disabled]="!isPrimaryTableEditable"
                >
                  <em class="fa fas fa-save mr-2"></em>Save
                </button>
                <button
                  class="btn btn-sm spear-button-background text-uppercase text-white px-2 py-1 pr-2 pl-2 ml-2"
                >
                  Spear
                </button>
                <button
                  class="btn btn-sm limos-button-background text-uppercase text-white px-2 py-1 pr-2 pl-2 ml-2"
                >
                  LIMOS
                </button>
                <button
                  class="btn btn-sm adonis-button-background text-white text-uppercase px-2 py-1 pr-2 pl-2 ml-2"
                >
                  Adonis
                </button>
                <button
                  class="btn btn-sm urania-button-background text-white text-uppercase px-2 py-1 pr-2 pl-2 ml-2"
                >
                  Urania
                </button>
                <button
                  class="btn btn-sm midas-button-background text-white text-uppercase px-2 py-1 pr-2 pl-2 ml-2"
                >
                  Midas
                </button>
                <button
                  routerLink="/reports"
                  class="btn btn-sm achilles-button-background text-white text-uppercase px-2 py-1 pr-2 pl-2 ml-2"
                >
                  Achilles
                </button>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-6 pr-2">
                <div class="table-row mb-1">
                  <table
                    class="table providerTable limos-table-header-background small-text table-row cnstr-record product-tbl fixed-table table-8col"
                    *ngIf="claimantTable"
                  >
                    <caption>
                      Data Table
                    </caption>
                    <thead>
                      <tr>
                        <th class="border-0" scope="col">CLAIM &#35;</th>
                        <th class="border-0" scope="col">ADJ &#35;</th>
                        <th class="border-0" scope="col">CLAIMANT NAME</th>
                        <th class="border-0" scope="col">TPA/ADMINISTRATOR</th>
                        <th class="border-0" scope="col">CARRIER</th>
                        <th class="border-0" scope="col">CLAIM STATUS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="bg-white">
                        <td
                          [innerHTML]="
                            claimantTable?.wc_claim_number || 'Not Listed'
                          "
                        ></td>
                        <td
                          [innerHTML]="claimantTable?.adj || 'Not Listed'"
                        ></td>
                        <td
                          [innerHTML]="
                            claimantTable?.injured_worker_full_name ||
                            'Not Listed'
                          "
                        ></td>
                        <td class="text-uppercase">Sedgwick</td>
                        <!-- <td class="text-uppercase" [innerHTML]="claimantTable?.admin || 'Not Listed'"></td> -->
                        <td
                          [innerHTML]="
                            claimantTable?.carrier_name || 'Not Listed'
                          "
                        ></td>
                        <td
                          [innerHTML]="
                            claimantTable?.claim_status || 'Not Listed'
                          "
                        ></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="table-row mb-1">
                  <table
                    class="table providerTable limos-table-header-background small-text table-row cnstr-record product-tbl fixed-table table-8col"
                    *ngIf="claimantTable"
                  >
                    <caption>
                      Data Table
                    </caption>
                    <thead>
                      <tr>
                        <th class="border-0" scope="col">PROVIDER NAME</th>
                        <th class="border-0" scope="col">TIN &#35;</th>
                        <th class="border-0" scope="col">TOTAL BILLED</th>
                        <th class="border-0" scope="col">TOTAL FS</th>
                        <th class="border-0" scope="col">TOTAL BR</th>
                        <th class="border-0" scope="col">TOTAL PAID</th>
                        <th class="border-0" scope="col">LAST PAYMENT</th>
                        <th class="border-0" scope="col">AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="bg-white">
                        <td
                          [innerHTML]="
                            claimantTable?.provider_name_business ||
                            'Not Listed'
                          "
                        ></td>
                        <td
                          [innerHTML]="
                            claimantTable?.tin_number || 'Not Listed'
                          "
                        ></td>

                        <td>
                          <span [hidden]="isPrimaryTableEditable">{{
                            claimantTable?.siu_pay_code || "Not Listed"
                          }}</span>
                          <input
                            value="{{ claimantTable?.siu_pay_code }}"
                            class="form-control"
                            type="text"
                            id="siu_pay_code"
                            [hidden]="!isPrimaryTableEditable"
                          />
                        </td>
                        <td>
                          <span [hidden]="isPrimaryTableEditable">{{
                            claimantTable?.date_siu_objection
                              | date
                                : clientDateFormat ||
                                    "Not
                            Available"
                          }}</span>
                          <input
                            [value]="
                              claimantTable?.date_siu_objection
                                | date : clientDateFormat
                            "
                            class="form-control"
                            type="text"
                            id="date_siu_objection"
                            [hidden]="!isPrimaryTableEditable"
                            jDate
                          />
                        </td>
                        <td>
                          <span [hidden]="isPrimaryTableEditable">{{
                            claimantTable?.objection_from
                              | date : clientDateFormat || "Not Listed"
                          }}</span>
                          <input
                            [value]="
                              claimantTable?.objection_from
                                | date : clientDateFormat
                            "
                            class="form-control"
                            type="text"
                            id="objection_from"
                            [hidden]="!isPrimaryTableEditable"
                            jDate
                          />
                        </td>
                        <td>
                          <span [hidden]="isPrimaryTableEditable">{{
                            claimantTable?.objection_to
                              | date : clientDateFormat || "Not Listed"
                          }}</span>
                          <input
                            [value]="
                              claimantTable?.objection_to
                                | date : clientDateFormat
                            "
                            class="form-control"
                            type="text"
                            id="objection_to"
                            [hidden]="!isPrimaryTableEditable"
                            jDate
                          />
                        </td>
                        <td>
                          <span [hidden]="isPrimaryTableEditable">{{
                            claimantTable?.siu_objection_removed ||
                              "Data not available"
                          }}</span>
                          <input
                            value="{{ claimantTable?.siu_objection_removed }}"
                            class="form-control"
                            type="text"
                            id="siu_objection_removed"
                            [hidden]="!isPrimaryTableEditable"
                          />
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="table-row mb-1">
                  <table
                    class="table providerTable limos-table-header-background small-text table-row cnstr-record product-tbl fixed-table table-8col"
                    *ngIf="claimantTable"
                  >
                    <caption>
                      Data Table
                    </caption>
                    <thead>
                      <tr>
                        <th class="border-0" scope="col">CLAIMS ADJUSTER</th>
                        <th class="border-0" scope="col">PHONE &#35;</th>
                        <th class="border-0" scope="col">EMAIL</th>
                        <th class="border-0" scope="col">TOTAL BILLED</th>
                        <th class="border-0" scope="col">TOTAL FEE SCHEDULE</th>
                        <th class="border-0" scope="col">
                          TOTAL BILL REVIEW ALLOWANCE
                        </th>
                        <th class="border-0" scope="col">TOTAL PAID</th>
                        <th class="border-0"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="bg-white">
                        <td
                          [innerHTML]="claimantTable?.claim_adj || 'Not Listed'"
                        ></td>
                        <td>
                          <span [hidden]="isPrimaryTableEditable">{{
                            claimantTable?.phone || "Not Listed"
                          }}</span>
                          <input
                            value="{{ claimantTable?.phone }}"
                            class="form-control"
                            type="text"
                            id="phone"
                            [hidden]="!isPrimaryTableEditable"
                          />
                        </td>
                        <td>
                          <span [hidden]="isPrimaryTableEditable">{{
                            claimantTable?.email || "Not Listed"
                          }}</span>
                          <input
                            value="{{ claimantTable?.email }}"
                            class="form-control"
                            type="text"
                            id="email"
                            [hidden]="!isPrimaryTableEditable"
                          />
                        </td>
                        <td>
                          <span
                            *ngIf="claimantTable?.tot_billed"
                            [hidden]="isPrimaryTableEditable"
                            >&#36;</span
                          >
                          <span [hidden]="isPrimaryTableEditable">{{
                            (claimantTable?.tot_billed | decimalPoint) ||
                              "Not
                            Available"
                          }}</span>
                          <input
                            value="{{ claimantTable?.tot_billed }}"
                            class="form-control"
                            type="number"
                            id="tot_billed"
                            [hidden]="!isPrimaryTableEditable"
                          />
                        </td>
                        <td>
                          <span
                            *ngIf="claimantTable?.tot_fee_schdl"
                            [hidden]="isPrimaryTableEditable"
                            >&#36;</span
                          >
                          <span [hidden]="isPrimaryTableEditable">{{
                            (claimantTable?.tot_fee_schdl | decimalPoint) ||
                              "Not Listed"
                          }}</span>
                          <input
                            value="{{
                              claimantTable?.tot_fee_schdl | decimalPoint
                            }}"
                            class="form-control"
                            type="number"
                            id="tot_fee_schdl"
                            [hidden]="!isPrimaryTableEditable"
                          />
                        </td>
                        <td>
                          <!-- <span *ngIf="claimantTable?.tot_bill_rev_all"
                            >&#36;</span
                          > -->
                          <span
                            [innerHTML]="
                              (claimantTable?.tot_bill_rev_all
                                | decimalPoint
                                | dollarCurrency) || 'Not Listed'
                            "
                          ></span>
                        </td>
                        <td>
                          <span
                            *ngIf="claimantTable?.tot_paid"
                            [hidden]="isPrimaryTableEditable"
                            >&#36;</span
                          >
                          <span [hidden]="isPrimaryTableEditable">{{
                            (claimantTable?.tot_paid | decimalPoint) ||
                              "Not
                            Available"
                          }}</span>
                          <input
                            value="{{ claimantTable?.tot_paid }}"
                            class="form-control"
                            type="number"
                            id="tot_paid"
                            [hidden]="!isPrimaryTableEditable"
                          />
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6 pl-0">
                    <div
                      class="mb-1 font-weight-bold bg-primary-light text-dark py-2 d-flex align-items-center justify-content-between px-2 font-size-right-side-heading"
                    >
                      LIEN INFO
                      <span
                        ><button
                          class="btn small limos-button-background text-white px-2 py-1 pr-2 pl-2"
                          (click)="makeEditableLien()"
                          [disabled]="contentEditableLien"
                        >
                          <em class="fa fas fa-pencil mr-2"></em>Edit
                        </button>
                        <button
                          class="btn small limos-button-background text-white px-2 py-1 pr-2 pl-2 ml-2"
                          (click)="saveLien()"
                          [disabled]="!contentEditableLien"
                        >
                          <em class="fa fas fa-save mr-2"></em>Save
                        </button></span
                      >
                    </div>
                    <div class="table" *ngIf="liensTable">
                      <table
                        class="table small-text providerTable limos-table-header-background table-row cnstr-record product-tbl"
                        *ngIf="claimantTable"
                      >
                        <caption>
                          Data Table
                        </caption>
                        <tbody>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              LIEN REQUEST DATE
                            </td>
                            <td
                              [innerHTML]="
                                (liensTable?.lien_request_date
                                  | date : clientDateFormat) || 'Not Listed'
                              "
                            ></td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              EAMS FILING DATE
                            </td>
                            <td
                              [innerHTML]="
                                (liensTable?.eams_lien_filed_date
                                  | date : clientDateFormat) || 'Not Listed'
                              "
                            ></td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              LIEN AMOUNT
                            </td>
                            <td>
                              <span
                                [innerHTML]="
                                  (liensTable?.lien_amount
                                    | decimalPoint
                                    | dollarCurrency) || 'Not Listed'
                                "
                              ></span>
                            </td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              BILL REVIEW ALLOWANCE
                            </td>
                            <td>
                              <span
                                [innerHTML]="
                                  (liensTable?.bill_review_allowance
                                    | decimalPoint
                                    | dollarCurrency) || 'Not Listed'
                                "
                              ></span>
                            </td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              OFFER BY REP TO RESOLVED
                            </td>
                            <td
                              [innerHTML]="
                                liensTable?.lien_requested_amount
                                  | decimalPoint
                                  | dollarCurrency
                              "
                            ></td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              ARGUS AUTHORITY
                            </td>
                            <td>
                              <input
                                [disabled]="!contentEditableLien"
                                class="form-control"
                                [value]="
                                  liensTable?.dates_of_service_from
                                    | date : clientDateFormat
                                "
                                id="dates_of_service_from"
                                type="text"
                                jDate
                              />
                            </td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              DATES OF SERVICE TO
                            </td>
                            <td>
                              <input
                                [disabled]="!contentEditableLien"
                                class="form-control"
                                [value]="
                                  liensTable?.dates_of_service_to
                                    | date : clientDateFormat
                                "
                                id="dates_of_service_to"
                                type="text"
                                jDate
                              />
                            </td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              FILE AND SERVE DATE
                            </td>
                            <td>
                              <input
                                [disabled]="!contentEditableLien"
                                class="form-control"
                                [value]="
                                  liensTable?.analysis_date
                                    | date : clientDateFormat
                                "
                                id="analysis_date"
                                type="text"
                                jDate
                              />
                            </td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              LIEN HEARING DATE
                            </td>
                            <td>
                              <span
                                [innerHTML]="
                                  (liensTable?.authority_requested
                                    | decimalPoint
                                    | dollarCurrency) || 'Not Listed'
                                "
                              ></span>
                            </td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              LIEN HEARING TIME
                            </td>
                            <td>
                              <input
                                [disabled]="!contentEditableLien"
                                class="form-control"
                                [value]="
                                  liensTable?.lien_hearing_date
                                    | date : clientDateFormat
                                "
                                id="lien_hearing_date"
                                type="text"
                                jDate
                              />
                            </td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              WCAB VENUE
                            </td>
                            <td>
                              <input
                                [disabled]="!contentEditableLien"
                                class="form-control"
                                value="{{ liensTable?.wcab_venue }}"
                                id="lien_hearing_time"
                                jTime
                              />
                            </td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              WCAB JUDGE
                            </td>
                            <td>
                              <select
                                [disabled]="!contentEditableLien"
                                class="form-control"
                                id="wcab_venue"
                              >
                                <option
                                  value=""
                                  [selected]="
                                    liensTable?.wcab_judge === null ||
                                    liensTable?.wcab_judge === ''
                                  "
                                >
                                  Select a WCAB venue
                                </option>
                                <option
                                  value="{{ opt }}"
                                  [selected]="opt == liensTable?.wcab_judge"
                                  *ngFor="let opt of wcabVenues"
                                >
                                  {{ opt }}
                                </option>
                              </select>
                            </td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              ASSIGNMENT
                            </td>
                            <td>
                              <input
                                [disabled]="!contentEditableLien"
                                class="form-control"
                                value="{{ liensTable?.assignment }}"
                                id="wcab_judge"
                              />
                            </td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              ASSIGNMENT DATE
                            </td>
                            <td>
                              <input
                                [disabled]="!contentEditableLien"
                                class="form-control"
                                value="{{ liensTable?.assignment }}"
                                id="assignment"
                                type="text"
                              />
                            </td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              ASSIGNMENT ON FILE
                            </td>
                            <td>
                              <input
                                [disabled]="!contentEditableLien"
                                class="form-control"
                                [value]="
                                  liensTable?.assignement_date
                                    | date : clientDateFormat
                                "
                                id="assignment_date"
                                type="text"
                                jDate
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div _ngcontent-cyb-c175="" class="col-6 pl-0">
                    <div
                      _ngcontent-cyb-c175=""
                      class="mb-1 font-weight-bold bg-primary-light text-dark py-2 d-flex align-items-center justify-content-center px-2 font-size-right-side-heading"
                    >
                      HAMMER
                    </div>
                    <div class="table" *ngIf="liensTable">
                      <table
                        class="table small-text providerTable limos-table-header-background table-row cnstr-record product-tbl"
                        *ngIf="claimantTable"
                      >
                        <caption>
                          Data Table
                        </caption>
                        <tbody>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              BR FLAGGED
                            </td>
                            <td></td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              TIN MATCH
                            </td>
                            <td></td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              LIC NUMBER
                            </td>
                            <td>
                              <span></span>
                            </td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              LIC STATUS
                            </td>
                            <td>
                              <span></span>
                            </td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              VLID FNP
                            </td>
                            <td></td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              SEC OF STATE
                            </td>
                            <td></td>
                          </tr>
                          <tr class="bg-white">
                            <td width="50%">
                              <strong>NPI'S</strong>
                            </td>
                            <td>
                              <strong>TYPE</strong>
                            </td>
                          </tr>
                          <tr _ngcontent-cyb-c175="" class="bg-white">
                            <td
                              _ngcontent-cyb-c175=""
                              colspan="2"
                              class="text-center"
                            >
                              Data not available
                            </td>
                          </tr>

                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              SIU PAY CODE
                            </td>
                            <td></td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              SIU OBJ FROM
                            </td>
                            <td>
                              <span></span>
                            </td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              SIU OBJ THRU
                            </td>
                            <td></td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              DATE OBJ ISSUED
                            </td>
                            <td></td>
                          </tr>
                          <tr class="bg-white">
                            <td
                              class="primary-text font-weight-semibold"
                              width="50%"
                            >
                              OBJ REMOVED
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-12 pl-0">
                    <div class="mb-1">
                      <div
                        class="font-weight-bold bg-primary-light text-dark py-2 d-flex align-items-center justify-content-between px-2 font-size-right-side-heading"
                      >
                        <div class="w-50 pr-1">CORRECTIONS NEEDED</div>

                        <span class="w-100 text-right"
                          ><button
                            class="btn small limos-button-background text-white py-1 pr-2 pl-2"
                            (click)="editCorrectionNeeded()"
                          >
                            <em class="fa fas fa-pencil mr-2"></em>Edit
                          </button>
                          <button
                            class="btn small limos-button-background text-white px-2 py-1 pr-2 pl-2 ml-2"
                            [disabled]="!isTextAreaCorrectionNeeded"
                            (click)="saveCorrectionNeeded()"
                          >
                            <em class="fa fas fa-save mr-2"></em>Save
                          </button></span
                        >
                      </div>
                      <div class="py-2 scrolling-box">
                        <textarea
                          [formControl]="textAreaCorrectionNeeded"
                          name="textAreaCorrectionNeeded"
                          rows="5"
                        ></textarea>
                      </div>
                    </div>

                    <div class="mb-1">
                      <div
                        class="font-weight-bold w-100 bg-primary-light text-dark py-2 d-flex align-items-center justify-content-between px-2 font-size-right-side-heading"
                      >
                        <div class="w-50 pr-1">LIEN/CLIENT POA</div>
                        <div class="w-50 text-right">
                          <button
                            class="btn small limos-button-background text-white px-2 py-1 pr-2 pl-2"
                            (click)="editSRBillObjLng()"
                          >
                            <em class="fa fas fa-pencil mr-2"></em>Edit
                          </button>
                          <button
                            class="btn small limos-button-background text-white px-2 py-1 pr-2 pl-2 ml-2"
                            [disabled]="!isTextAreaSRBillObjLng"
                            (click)="saveSRBillObjLng()"
                          >
                            <em class="fa fas fa-save mr-2"></em>Save
                          </button>
                        </div>
                      </div>
                      <div class="py-2 scrolling-box">
                        <textarea
                          [formControl]="textAreaSRBillObjLng"
                          name="textAreaSRBillObjLng"
                          rows="5"
                        ></textarea>
                      </div>
                    </div>
                    <div class="mb-1">
                      <div
                        class="font-weight-bold w-100 bg-primary-light text-dark py-2 d-flex align-items-center justify-content-between px-2 font-size-right-side-heading"
                      >
                        <div class="w-50 pr-1">
                          SIU BR Bill OBJECTION LANGUAGE
                        </div>
                        <div class="w-50 text-right">
                          <button
                            class="btn small limos-button-background text-white px-2 py-1 pr-2 pl-2"
                            (click)="editSRBillObjLng()"
                          >
                            <em class="fa fas fa-pencil mr-2"></em>Edit
                          </button>
                          <button
                            class="btn small limos-button-background text-white px-2 py-1 pr-2 pl-2 ml-2"
                            [disabled]="!isTextAreaSRBillObjLng"
                            (click)="saveSRBillObjLng()"
                          >
                            <em class="fa fas fa-save mr-2"></em>Save
                          </button>
                        </div>
                      </div>
                      <div class="py-2 scrolling-box">
                        <textarea
                          [formControl]="textAreaSRBillObjLng"
                          name="textAreaSRBillObjLng"
                          rows="5"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12">
                <div
                  class="mb-1 font-weight-bold bg-primary-light text-dark py-2 d-flex align-items-center justify-content-between px-2 font-size-right-side-heading"
                >
                  <div class="align-items-center justify-content-between"></div>
                  NOTEPAD

                  <button
                    class="btn small limos-button-background text-white px-2 py-1 pr-2 pl-2 ml-2"
                    (click)="openAddNoteModal(createNote, 'lg')"
                    type="button"
                  >
                    Add Note
                  </button>
                </div>

                <div class="table">
                  <form [formGroup]="addNoteForm" name="addNoteForm">
                    <table
                      class="table small-text providerTable limos-table-header-background table-row cnstr-record product-tbl"
                      *ngIf="claimantTable"
                    >
                      <caption>
                        Data Table
                      </caption>
                      <thead>
                        <tr>
                          <th class="border-0" scope="col">DATE</th>
                          <th class="border-0" scope="col">NOTE TYPE</th>
                          <th class="border-0" scope="col">NOTE</th>
                          <th class="border-0" scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="bg-white">
                          <td width="100">
                            <input
                              class="form-control"
                              formControlName="noteDate"
                              name="noteDate"
                              disabled="disabled"
                            />
                          </td>
                          <td width="200">
                            <select
                              class="form-control"
                              formControlName="noteType"
                              name="noteType"
                            >
                              <option
                                *ngFor="let noty of noteType"
                                value="{{ noty.value }}"
                              >
                                {{ noty.label }}
                              </option>
                            </select>
                          </td>
                          <td>
                            <textarea
                              formControlName="note"
                              name="note"
                            ></textarea>
                          </td>
                          <td class="text-right">
                            <button
                              class="btn small limos-button-background text-white px-2 py-1 pr-2 pl-2 ml-2"
                              (click)="addNoteOld()"
                              type="submit"
                              [disabled]="!isNoteValid || addNoteForm.invalid"
                            >
                              <em class="fa fas fa-save mr-2"></em>Add
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </form>
                </div>

                <div class="scrolling-box mb-1">
                  <div class="table">
                    <table
                      class="table small-text providerTable limos-table-header-background table-row cnstr-record product-tbl"
                      *ngIf="claimantTable"
                    >
                      <caption>
                        Data Table
                      </caption>
                      <thead>
                        <tr>
                          <th class="border-0" scope="col">DATE</th>
                          <th class="border-0" scope="col">NOTE TYPE</th>
                          <th class="border-0" scope="col">NOTE</th>
                          <th class="border-0" scope="col"></th>
                        </tr>
                      </thead>
                      <tbody *ngIf="noteTable.length < 1">
                        <tr class="bg-white">
                          <td colspan="4" align="center">Data not available</td>
                        </tr>
                      </tbody>
                      <tbody *ngIf="noteTable.length > 0">
                        <tr class="bg-white" *ngFor="let note of noteTable">
                          <td>
                            <input
                              id="dateNote-{{ note.id }}"
                              class="form-control"
                              type="text"
                              [value]="note.log_date | date : clientDateFormat"
                              disabled="disabled"
                              jDate
                            />
                          </td>
                          <td>
                            <select
                              class="form-control"
                              id="notetypeNote-{{ note.id }}"
                              disabled="disabled"
                            >
                              <option
                                [selected]="noty.value === note.note_type"
                                *ngFor="let noty of noteType"
                                value="{{ noty.value }}"
                              >
                                {{ noty.label }}
                              </option>
                            </select>
                          </td>
                          <td>
                            <textarea
                              id="noteNote-{{ note.id }}"
                              class="form-control"
                              disabled="disabled"
                              >{{ note.note }}</textarea
                            >
                          </td>
                          <td>
                            <button
                              id="editNote-{{ note.id }}"
                              class="icon-button editNote"
                              (click)="editNote(note.id)"
                            >
                              <em class="fa fas fa-pencil mr-2"></em>
                            </button>
                            <button
                              id="saveNote-{{ note.id }}"
                              class="icon-button"
                              (click)="saveNote(note.id)"
                              hidden
                            >
                              <em class="fa fas fa-check mr-2"></em>
                            </button>
                            <button
                              id="cancelNote-{{ note.id }}"
                              class="icon-button"
                              (click)="cancelNote(note.id)"
                              hidden
                            >
                              <em class="fa fas fa-close"></em>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTab == 'documents'">
    <div class="row">
      <div class="col-12">
        <div class="card border-0">
          <div class="card-body search-limos-background">
            <div>
              <div
                class="font-weight-bold w-100 bg-primary-light text-dark py-2 d-flex align-items-center justify-content-between px-2"
              >
                <div>Documents</div>
                <div class="text-sm">
                  CLAIM #:
                  <span class="font-weight-normal">{{
                    claimantTable?.wc_claim_number || "Not Listed"
                  }}</span>
                </div>
                <div class="text-sm">
                  CLAIMANT NAME:
                  <span class="font-weight-normal">{{
                    claimantTable?.injured_worker_full_name || "Not Listed"
                  }}</span>
                </div>
                <div class="text-sm">
                  PROVIDER NAME:
                  <span class="font-weight-normal">{{
                    claimantTable?.provider_name_business || "Not Listed"
                  }}</span>
                </div>
                <div class="text-sm">
                  TIN #:<span class="font-weight-normal">{{
                    claimantTable?.tin_number || "Not Listed"
                  }}</span>
                </div>
                <div class="input-file">
                  <input
                    type="file"
                    id="fileupload-whitepaper"
                    accept=".doc,.docx,.pdf"
                    (change)="onFileSelected($event)"
                  />
                  <label
                    type="button"
                    for="fileupload-whitepaper"
                    class="btn small limos-button-background text-white px-2 py-1 pr-2 pl-2"
                    >Upload File</label
                  >
                </div>
              </div>
              <div class="px-2 py-2 scrolling-box">
                <div class="table-row">
                  <table
                    class="table small-text providerTable limos-table-header-background table-row cnstr-record product-tbl"
                  >
                    <caption>
                      Data Table
                    </caption>
                    <thead>
                      <tr>
                        <th class="border-0" scope="col">FILE NAME</th>
                        <th class="border-0" scope="col">DESCRIPTION</th>
                        <th class="border-0"></th>
                        <th class="border-0"></th>
                      </tr>
                    </thead>
                    <tbody *ngIf="filesTable.length > 0">
                      <tr class="bg-white" *ngFor="let row of filesTable">
                        <td [innerHTML]="row.file_name || 'Not Listed'"></td>
                        <td [innerHTML]="row.description || 'Not Listed'"></td>
                        <td>
                          <button
                            *ngIf="row.id != 'NA'"
                            (click)="download(row.id)"
                          >
                            <em
                              class="fa fa-arrow-circle-down"
                              aria-hidden="true"
                            ></em>
                          </button>
                        </td>
                        <td>
                          <button
                            (click)="
                              deleteModalOpen(
                                deleteFileModalConfirmation,
                                row.id
                              )
                            "
                          >
                            <em class="fa fa-trash" aria-hidden="true"></em>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="filesTable.length < 1">
                      <tr>
                        <td colspan="2" align="center">Not Listed</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTab == 'lien-representative'">
    <div class="row">
      <div class="col-12">
        <div class="card border-0">
          <div class="card-body search-limos-background">
            <div
              class="mb-1 font-weight-bold bg-primary-light text-dark py-2 d-flex align-items-center justify-content-between px-2"
            >
              Lien Representative
              <div class="text-sm">
                CLAIM #:
                <span class="font-weight-normal">{{
                  claimantTable?.wc_claim_number || "Not Listed"
                }}</span>
              </div>
              <div class="text-sm">
                CLAIMANT NAME:
                <span class="font-weight-normal">{{
                  claimantTable?.injured_worker_full_name || "Not Listed"
                }}</span>
              </div>
              <div class="text-sm">
                PROVIDER NAME:
                <span class="font-weight-normal">{{
                  claimantTable?.provider_name_business || "Not Listed"
                }}</span>
              </div>
              <div class="text-sm">
                TIN #:<span class="font-weight-normal">{{
                  claimantTable?.tin_number || "Not Listed"
                }}</span>
              </div>
            </div>
            <div class="table">
              <table
                class="table providerTable limos-table-header-background small-text table-row cnstr-record product-tbl"
              >
                <caption>
                  Data Table
                </caption>
                <thead>
                  <tr>
                    <th class="border-0" scope="col">REPRESENTATIVE NAME</th>
                    <th class="border-0" scope="col">TITLE</th>
                    <th class="border-0" scope="col">COMPANY</th>
                    <th class="border-0" scope="col">PHONE</th>
                    <th class="border-0" scope="col">EMAIL</th>
                    <th class="border-0" scope="col" width="80"></th>
                  </tr>
                </thead>
                <tbody *ngIf="lien_representative.length > 0">
                  <tr class="bg-white" *ngFor="let row of lien_representative">
                    <td>
                      <input
                        id="repName-{{ row.id }}"
                        class="form-control"
                        value="{{ row.representative_name }}"
                        disabled="disabled"
                      />
                    </td>
                    <td>
                      <input
                        id="repTitle-{{ row.id }}"
                        class="form-control"
                        value="{{ row.title }}"
                        disabled="disabled"
                      />
                    </td>
                    <td>
                      <input
                        id="repCompany-{{ row.id }}"
                        class="form-control"
                        value="{{ row.company }}"
                        disabled="disabled"
                      />
                    </td>
                    <td>
                      <input
                        id="repPhone-{{ row.id }}"
                        class="form-control"
                        type="text"
                        UsPhoneNoMask
                        value="{{ row.phone }}"
                        disabled="disabled"
                      />
                    </td>
                    <td>
                      <input
                        id="repEmail-{{ row.id }}"
                        class="form-control"
                        value="{{ row.email }}"
                        disabled="disabled"
                      />
                    </td>
                    <td>
                      <button
                        id="editRep-{{ row.id }}"
                        class="icon-button editRep"
                        (click)="editRep(row.id)"
                      >
                        <em class="fa fas fa-pencil mr-2"></em>
                      </button>
                      <button
                        id="saveRep-{{ row.id }}"
                        class="icon-button"
                        (click)="saveRep(row.id)"
                        hidden
                      >
                        <em class="fa fas fa-check mr-2"></em>
                      </button>
                      <button
                        id="cancelRep-{{ row.id }}"
                        class="icon-button"
                        (click)="cancelRep(row.id)"
                        hidden
                      >
                        <em class="fa fas fa-close mr-2"></em>
                      </button>
                      <button
                        id="deleteRep-{{ row.id }}"
                        class="icon-button"
                        (click)="
                          openDeleteRep(
                            row.id,
                            deleteRepresentativeModalConfirmation
                          )
                        "
                      >
                        <em class="fa fas fa-trash"></em>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody *ngIf="lien_representative.length < 1">
                  <tr>
                    <td colspan="6">Data not available</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr class="bg-white">
                    <td>
                      <input id="repName" class="form-control" />
                    </td>
                    <td>
                      <input id="repTitle" class="form-control" />
                    </td>
                    <td>
                      <input id="repCompany" class="form-control" />
                    </td>
                    <td>
                      <input id="repPhone" class="form-control" UsPhoneNoMask />
                    </td>
                    <td>
                      <input id="repEmail" class="form-control" />
                    </td>
                    <td>
                      <button class="icon-button" (click)="addRep()">
                        <em class="fa fas fa-plus"></em>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTab == 'lien-resolution'">
    <div class="row">
      <div class="col-12">
        <div class="card border-0">
          <div class="card-body search-limos-background">
            <div
              class="mb-1 font-weight-bold bg-primary-light text-dark py-2 d-flex align-items-center justify-content-between px-2"
            >
              Lien Resolution
              <div class="text-sm">
                CLAIM #:
                <span class="font-weight-normal">{{
                  claimantTable?.wc_claim_number || "Not Listed"
                }}</span>
              </div>
              <div class="text-sm">
                CLAIMANT NAME:
                <span class="font-weight-normal">{{
                  claimantTable?.injured_worker_full_name || "Not Listed"
                }}</span>
              </div>
              <div class="text-sm">
                PROVIDER NAME:
                <span class="font-weight-normal">{{
                  claimantTable?.provider_name_business || "Not Listed"
                }}</span>
              </div>
              <div class="text-sm">
                TIN #:<span class="font-weight-normal">{{
                  claimantTable?.tin_number || "Not Listed"
                }}</span>
              </div>
              <span>
                <button
                  class="btn small limos-button-background text-white px-2 py-1 pr-2 pl-2 ml-2"
                  (click)="exportBR(exportLienResolution)"
                >
                  <em class="fa fas fa-angle-double-right mr-2"></em>Export
                </button></span
              >
            </div>
            <div class="table" id="lienResTable">
              <table
                class="table providerTable limos-table-header-background small-text table-row cnstr-record product-tbl"
                #lienResTable
              >
                <caption>
                  Data Table
                </caption>
                <thead>
                  <tr>
                    <th class="border-0" scope="col">RESOLVED DATE</th>
                    <th class="border-0" scope="col">RESOLVED AMOUNT</th>
                    <th class="border-0" scope="col">SAVINGS</th>
                    <th class="border-0" scope="col">SAVINGS %</th>
                    <th class="border-0" scope="col">WHO RESOLVED</th>
                    <th class="border-0" scope="col">ARGUS DIRECTED</th>
                    <th class="border-0" scope="col">RESOLVED HOW</th>
                    <th
                      class="border-0"
                      scope="col"
                      width="70"
                      class="border-0"
                    ></th>
                  </tr>
                </thead>
                <tbody *ngIf="lien_resolution.length > 0">
                  <tr class="bg-white" *ngFor="let row of lien_resolution">
                    <td>
                      <input
                        id="resDate-{{ row.id }}"
                        class="form-control"
                        type="date"
                        value="{{ row.resolved_date }}"
                        disabled="disabled"
                      />
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <b *ngIf="row.resolved_amount">&#36;</b>
                        <input
                          id="resAmount-{{ row.id }}"
                          class="form-control"
                          type="number"
                          value="{{ row.resolved_amount }}"
                          disabled="disabled"
                          (keyup)="setSavingAmount(row.id)"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <b>&#36;</b>
                        <input
                          id="savingAmount-{{ row.id }}"
                          class="form-control"
                          type="number"
                          value="{{
                            liensTable?.lien_requested_amount -
                              row.resolved_amount
                          }}"
                          disabled="disabled"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <input
                          id="savingPercent-{{ row.id }}"
                          class="form-control"
                          type="text"
                          value="{{
                            liensTable?.lien_requested_amount
                              ? (
                                  (1 -
                                    row.resolved_amount /
                                      liensTable?.lien_requested_amount) *
                                  100
                                )?.toFixed(2)
                              : 0
                          }}%"
                          disabled="disabled"
                        />
                      </div>
                    </td>
                    <td>
                      <select
                        id="resEditWho-{{ row.id }}"
                        class="form-control"
                        disabled="disabled"
                      >
                        <option
                          value=""
                          [selected]="
                            row.who_resolved === '' || row.who_resolved === null
                          "
                        >
                          Please Select
                        </option>
                        <option
                          value="ARGUS"
                          [selected]="row.who_resolved === 'ARGUS'"
                        >
                          ARGUS
                        </option>
                        <option
                          value="ADJUSTER"
                          [selected]="row.who_resolved === 'ADJUSTER'"
                        >
                          ADJUSTER
                        </option>
                        <option
                          value="D/A"
                          [selected]="row.who_resolved === 'D/A'"
                        >
                          D/A
                        </option>
                        <option
                          value="CO-DEF"
                          [selected]="row.who_resolved === 'CO-DEF'"
                        >
                          CO-DEF
                        </option>
                      </select>
                    </td>
                    <td>
                      <select
                        id="resArgusDirected-{{ row.id }}"
                        class="form-control"
                        disabled="disabled"
                      >
                        <option
                          value=""
                          [selected]="
                            row.argus_directed === '' ||
                            row.argus_directed === null
                          "
                        >
                          Please Select
                        </option>
                        <option
                          value="true"
                          [selected]="row.argus_directed === true"
                        >
                          Yes
                        </option>
                        <option
                          value="false"
                          [selected]="row.argus_directed === false"
                        >
                          No
                        </option>
                      </select>
                    </td>
                    <td>
                      <input
                        id="resHow-{{ row.id }}"
                        class="form-control"
                        type="text"
                        disabled="disabled"
                        value="{{ row.resolved_how }}"
                      />
                    </td>
                    <td width="80">
                      <button
                        id="editRes-{{ row.id }}"
                        class="icon-button editRes"
                        (click)="editRes(row.id)"
                      >
                        <em class="fa fas fa-pencil mr-2"></em>
                      </button>
                      <button
                        id="saveRes-{{ row.id }}"
                        class="icon-button"
                        (click)="saveRes(row.id)"
                        hidden
                      >
                        <em class="fa fas fa-check mr-2"></em>
                      </button>
                      <button
                        id="cancelRes-{{ row.id }}"
                        class="icon-button mr-2"
                        (click)="cancelRes(row.id)"
                        hidden
                      >
                        <em class="fa fas fa-close"></em>
                      </button>
                      <button
                        id="deleteRes-{{ row.id }}"
                        class="icon-button"
                        (click)="
                          openDeleteRes(
                            row.id,
                            deleteResolutionModalConfirmation
                          )
                        "
                      >
                        <em class="fa fas fa-trash"></em>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody *ngIf="lien_resolution.length < 1">
                  <tr>
                    <td colspan="8" align="center">Data not available</td>
                  </tr>
                </tbody>
                <tbody id="last-tbody" *ngIf="lien_resolution.length == 0">
                  <tr class="bg-white">
                    <td>
                      <input
                        id="resResDate"
                        type="date"
                        class="form-control"
                        jDate
                      />
                    </td>
                    <td>
                      <div class="amount-value">
                        $<input
                          id="resResAmt"
                          type="number"
                          class="form-control"
                          (keyup)="setSavingAmount()"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="amount-value">
                        $<input
                          id="savingAmt"
                          type="number"
                          class="form-control"
                          disabled
                        />
                      </div>
                    </td>
                    <td>
                      <input
                        id="savingPercent"
                        type="text"
                        class="form-control"
                        disabled
                      />
                    </td>
                    <td class="position-relative">
                      <select id="resWhoRslvd" class="form-control">
                        <option value="">Please Select</option>
                        <option value="ARGUS">ARGUS</option>
                        <option value="ADJUSTER">ADJUSTER</option>
                        <option value="D/A">D/A</option>
                        <option value="CO-DEF">CO-DEF</option>
                      </select>
                    </td>
                    <td>
                      <select id="resArgusDirected" class="form-control">
                        <option value="">Please Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </td>
                    <td>
                      <input id="resHow" class="form-control" type="text" />
                    </td>
                    <td>
                      <button class="icon-button" (click)="addLienRes()">
                        <em class="fa fas fa-plus"></em>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTab == 'bill-review-and-payment-data'">
    <div class="row">
      <div class="col-12">
        <div class="card border-0">
          <div class="card-body search-limos-background">
            <div
              class="mb-1 font-weight-bold bg-primary-light text-dark py-2 d-flex align-items-center justify-content-between px-2"
            >
              Bill Review & Payment Data
              <div class="text-sm">
                CLAIM #:
                <span class="font-weight-normal">{{
                  claimantTable?.wc_claim_number || "Not Listed"
                }}</span>
              </div>
              <div class="text-sm">
                CLAIMANT NAME:
                <span class="font-weight-normal">{{
                  claimantTable?.injured_worker_full_name || "Not Listed"
                }}</span>
              </div>
              <div class="text-sm">
                PROVIDER NAME:
                <span class="font-weight-normal">{{
                  claimantTable?.provider_name_business || "Not Listed"
                }}</span>
              </div>
              <div class="text-sm">
                TIN #:<span class="font-weight-normal">{{
                  claimantTable?.tin_number || "Not Listed"
                }}</span>
              </div>
              <span
                ><button
                  class="btn small limos-button-background text-white px-2 py-1 pr-2 pl-2 ml-2"
                  (click)="exportBR(exportBillReview)"
                >
                  <em class="fa fas fa-angle-double-right mr-2"></em>Export
                </button></span
              >
            </div>
            <div *blockUI="'bill-review-payment-block-ui'">
              <table
                class="table providerTable limos-table-header-background small-text table-row cnstr-record product-tbl"
                *ngIf="claimantTable"
                id="billReviewTable"
                #billReviewTable
              >
                <caption>
                  Data Table
                </caption>
                <thead>
                  <tr>
                    <th class="border-0" scope="col">SOURCE</th>
                    <th class="border-0" scope="col">PROCESSED DATE</th>
                    <th class="border-0" scope="col">SERVICE FROM DATE</th>
                    <th class="border-0" scope="col">SERVICE TO DATE</th>
                    <th class="border-0" scope="col">BILLED CODE</th>
                    <th class="border-0" scope="col">DESCRIPTION</th>
                    <th class="border-0" scope="col">BILLED AMOUNT</th>
                    <th class="border-0" scope="col">BILL REVIEW ALLOWANCE</th>
                    <th class="border-0" scope="col">PAID</th>
                    <th class="border-0" scope="col">PROVIDER PHYSICIAN</th>
                    <th class="border-0" scope="col">FACILITY</th>
                    <th class="border-0" scope="col">REFERRING PHYSICIAN</th>
                    <th class="border-0" scope="col">BILLING PROVIDER</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white" *ngIf="!billReviewAndPayments?.length">
                    <td colspan="2" class="text-center">Data not available</td>
                  </tr>
                  <ng-container *ngIf="billReviewAndPayments?.length">
                    <tr
                      *ngFor="let brAndPayment of billReviewAndPayments"
                      [style.background-color]="brAndPayment.bgColor"
                    >
                      <td
                        [innerHTML]="brAndPayment.source || 'Not Listed'"
                      ></td>
                      <td
                        [innerHTML]="
                          brAndPayment.bill_review_br_process_date
                            ? (brAndPayment.bill_review_br_process_date
                              | date : clientDateFormat)
                            : 'Not Listed'
                        "
                      ></td>
                      <td
                        [innerHTML]="
                          brAndPayment.bill_review_service_from_date
                            ? (brAndPayment.bill_review_service_from_date
                              | date : clientDateFormat)
                            : 'Not Listed'
                        "
                      ></td>
                      <td
                        [innerHTML]="
                          brAndPayment.bill_review_service_through_date
                            ? (brAndPayment.bill_review_service_through_date
                              | date : clientDateFormat)
                            : 'Not Listed'
                        "
                      ></td>
                      <td
                        [innerHTML]="
                          brAndPayment.bill_review_service_code_value ||
                          'Not Listed'
                        "
                      ></td>
                      <td
                        [innerHTML]="
                          brAndPayment.bill_review_service_code_description ||
                          'Not Listed'
                        "
                      ></td>
                      <td>
                        <span
                          [innerHTML]="
                            (brAndPayment.bill_review_total_charges
                              | decimalPoint
                              | dollarCurrency) || 'Not Listed'
                          "
                        ></span>
                      </td>
                      <td>
                        <span
                          [innerHTML]="
                            (brAndPayment.bill_review_total_allowance
                              | decimalPoint
                              | dollarCurrency) || 'Not Listed'
                          "
                        ></span>
                      </td>
                      <td
                        [innerHTML]="
                          (brAndPayment.paid | decimalPoint | dollarCurrency) ||
                          'Not Listed'
                        "
                      ></td>
                      <td
                        [innerHTML]="
                          brAndPayment.bill_review_physician_rendering_name ||
                          'Not Listed'
                        "
                      ></td>
                      <td
                        [innerHTML]="brAndPayment.facility || 'Not Listed'"
                      ></td>
                      <td
                        [innerHTML]="
                          brAndPayment.referring_physician || 'Not Listed'
                        "
                      ></td>
                      <td
                        [innerHTML]="
                          brAndPayment.provider_name_business || 'Not Listed'
                        "
                      ></td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <div
                class="px-0 py-0 paginationWrapper"
                *ngIf="
                  !(
                    !billReviewAndPayments || billReviewAndPayments.length === 0
                  )
                "
              >
                <ngb-pagination
                  [(collectionSize)]="billReviewAndPaymentsTotal"
                  [(page)]="billReviewAndPaymentsPage"
                  [pageSize]="billReviewAndPaymentsLimit"
                  [maxSize]="10"
                  [boundaryLinks]="true"
                  (pageChange)="onPageChangeBillReviewAndPayments($event)"
                >
                </ngb-pagination>
                <div class="selected d-flex align-items-center">
                  <select
                    (change)="
                      onSizeChangeBillReviewAndPayments(
                        billReviewAndPaymentsLimit
                      )
                    "
                    class="custom-select table-select h-100"
                    [(ngModel)]="billReviewAndPaymentsLimit"
                  >
                    <option [ngValue]="20">20</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="75">75</option>
                    <option [ngValue]="100">100</option>
                  </select>
                  <p class="mb-0 ml-2 text-nowrap">
                    Showing
                    {{
                      billReviewAndPaymentsPage * billReviewAndPaymentsLimit -
                        billReviewAndPaymentsLimit +
                        1
                    }}
                    -
                    {{
                      billReviewAndPaymentsPage * billReviewAndPaymentsLimit >
                      billReviewAndPaymentsTotal
                        ? billReviewAndPaymentsTotal
                        : billReviewAndPaymentsPage * billReviewAndPaymentsLimit
                    }}
                    of {{ billReviewAndPaymentsTotal }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTab == 'similar-claims'">
    <div class="row">
      <div class="col-12">
        <div class="card border-0">
          <div class="card-body search-limos-background">
            <div
              class="mb-1 font-weight-bold bg-primary-light text-dark py-2 d-flex align-items-center justify-content-between px-2"
            >
              Similar Claims
              <div class="text-sm">
                CLAIM #:
                <span class="font-weight-normal">{{
                  claimantTable?.wc_claim_number || "Not Listed"
                }}</span>
              </div>
              <div class="text-sm">
                CLAIMANT NAME:
                <span class="font-weight-normal">{{
                  claimantTable?.injured_worker_full_name || "Not Listed"
                }}</span>
              </div>
              <div class="text-sm">
                PROVIDER NAME:
                <span class="font-weight-normal">{{
                  claimantTable?.provider_name_business || "Not Listed"
                }}</span>
              </div>
              <div class="text-sm">
                TIN #:<span class="font-weight-normal">{{
                  claimantTable?.tin_number || "Not Listed"
                }}</span>
              </div>
              <span
                ><button
                  class="btn small limos-button-background text-white px-2 py-1 pr-2 pl-2 ml-2"
                  (click)="exportSimilarClaims()"
                  [disabled]="similarClaimExportLoader"
                >
                  <em class="fa fas fa-angle-double-right mr-2"></em>Export
                  <i
                    *ngIf="similarClaimExportLoader"
                    class="fa fa-spinner fa-spin"
                  ></i></button
              ></span>
            </div>
            <div *blockUI="'similar-claims-block-ui'">
              <table
                class="table providerTable limos-table-header-background small-text table-row cnstr-record product-tbl"
                *ngIf="claimantTable"
              >
                <caption>
                  Data Table
                </caption>
                <thead>
                  <tr>
                    <th class="border-0" style="width: 10%" scope="col">
                      CLAIM No.
                    </th>
                    <th class="border-0" style="width: 10%" scope="col">
                      CLAIM STATUS
                    </th>
                    <th class="border-0" style="width: 10%" scope="col">
                      CLAIMANT NAME
                    </th>
                    <th class="border-0" style="width: 5%" scope="col">
                      ADJ NUMBER
                    </th>
                    <th class="border-0" style="width: 8%" scope="col">
                      LIEN REQUEST DATE
                    </th>
                    <th class="border-0" style="width: 8%" scope="col">
                      LIEN FILED DATE
                    </th>
                    <th class="border-0" style="width: 7%" scope="col">
                      LIEN AMOUNT
                    </th>
                    <th class="border-0" style="width: 8%" scope="col">
                      TOTAL BILLED AMOUNT
                    </th>
                    <th class="border-0" style="width: 10%" scope="col">
                      TOTAL BR ALLOWANCE
                    </th>
                    <th class="border-0" style="width: 7%" scope="col">
                      TOTAL PAID AMOUNT
                    </th>
                    <th class="border-0" style="width: 8%" scope="col">
                      RESOLVED DATE
                    </th>
                    <th class="border-0" style="width: 9%" scope="col">
                      RESOLVED BY
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white" *ngIf="!similar_claims.length">
                    <td colspan="12" class="text-center">Data not available</td>
                  </tr>
                  <tr class="bg-white" *ngFor="let row of similar_claims">
                    <td [innerHTML]="row.wc_claim_number || 'Not Listed'"></td>
                    <td [innerHTML]="row.wc_claim_status || 'Not Listed'"></td>
                    <td
                      [innerHTML]="
                        row.injured_worker_last_first || 'Not Listed'
                      "
                    ></td>
                    <td [innerHTML]="row.case_number || 'Not Listed'"></td>
                    <td
                      [innerHTML]="
                        row.lien_request_date
                          ? (row.lien_request_date | date : clientDateFormat)
                          : 'Not Listed'
                      "
                    ></td>
                    <td
                      [innerHTML]="
                        row.lien_created
                          ? (row.lien_created | date : clientDateFormat)
                          : 'Not Listed'
                      "
                    ></td>
                    <td
                      [innerHTML]="
                        (row.lien_amount | dollarCurrency) || 'Not Listed'
                      "
                    ></td>
                    <td
                      [innerHTML]="
                        (row.bill_review_total_charges | dollarCurrency) ||
                        'Not Listed'
                      "
                    ></td>
                    <td
                      [innerHTML]="
                        (row.bill_review_total_br_allowance | dollarCurrency) ||
                        'Not Listed'
                      "
                    ></td>
                    <td
                      [innerHTML]="
                        (row.total_paid | dollarCurrency) || 'Not Listed'
                      "
                    ></td>
                    <td
                      [innerHTML]="
                        row.resolved_date
                          ? (row.resolved_date | date : clientDateFormat)
                          : 'Not Listed'
                      "
                    ></td>
                    <td [innerHTML]="row.resolved_by || 'Not Listed'"></td>
                  </tr>
                </tbody>
              </table>
              <div
                class="d-flex px-0 py-0 paginationWrapper"
                *ngIf="!(!similar_claims || similar_claims.length === 0)"
              >
                <ngb-pagination
                  [(collectionSize)]="similarClaimsTotal"
                  [(page)]="similarClaimsPage"
                  [pageSize]="similarClaimsLimit"
                  [maxSize]="10"
                  [boundaryLinks]="true"
                  (pageChange)="onPageChangeSimilarClaims($event)"
                >
                </ngb-pagination>
                <div class="selected d-flex align-items-center">
                  <select
                    (change)="onSizeChangeSimilarClaims(similarClaimsLimit)"
                    class="custom-select table-select h-100"
                    [(ngModel)]="similarClaimsLimit"
                  >
                    <option [ngValue]="20">20</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="75">75</option>
                    <option [ngValue]="100">100</option>
                  </select>
                  <p class="mb-0 ml-2 text-nowrap">
                    Showing
                    {{
                      similarClaimsPage * similarClaimsLimit -
                        similarClaimsLimit +
                        1
                    }}
                    -
                    {{
                      similarClaimsPage * similarClaimsLimit >
                      similarClaimsTotal
                        ? similarClaimsTotal
                        : similarClaimsPage * similarClaimsLimit
                    }}
                    of {{ similarClaimsTotal }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #deleteFileModalConfirmation let-modal>
  <div class="modal-body">
    <div class="flex text-center flex-col w-full">
      <p>Are you sure?</p>
      <div class="flex">
        <button
          class="btn limos-button-background text-white mr-2"
          (click)="deleteConfirmation()"
        >
          Yes
        </button>
        <button
          class="btn limos-button-background text-white"
          (click)="modal.dismiss('Cross click')"
        >
          No
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #deleteRepresentativeModalConfirmation let-modal>
  <div class="modal-body">
    <div class="flex text-center flex-col w-full">
      <p>Are you sure?</p>
      <div class="flex">
        <button
          class="btn limos-button-background text-white mr-2"
          (click)="deleteRep()"
        >
          Yes
        </button>
        <button
          class="btn limos-button-background text-white"
          (click)="modal.dismiss('Cross click')"
        >
          No
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #deleteResolutionModalConfirmation let-modal>
  <div class="modal-body">
    <div class="flex text-center flex-col w-full">
      <p>Are you sure?</p>
      <div class="flex">
        <button
          class="btn limos-button-background text-white mr-2"
          (click)="deleteRes()"
        >
          Yes
        </button>
        <button
          class="btn limos-button-background text-white"
          (click)="modal.dismiss('Cross click')"
        >
          No
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #exportBillReview let-modal>
  <div class="modal-body">
    <div class="flex text-center flex-col w-full">
      <p>Export as</p>
      <div class="flex">
        <button
          class="btn limos-button-background text-white mr-2"
          (click)="exportToPdf('billReviewTable', 'landscape')"
        >
          PDF
        </button>
        <button
          class="btn limos-button-background text-white"
          (click)="downloadCSV_billReviewTable()"
        >
          CSV
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #exportLienResolution let-modal>
  <div class="modal-body">
    <div class="flex text-center flex-col w-full">
      <p>Export as</p>
      <div class="flex">
        <button
          class="btn limos-button-background text-white mr-2"
          (click)="exportToPdf('lienResTable', 'portrait')"
        >
          PDF
        </button>
        <button
          class="btn limos-button-background text-white"
          (click)="downloadCSV_lienResTable()"
        >
          CSV
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #createNote let-modal>
  <div class="modal-header">
    <h6>Add Note</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="addModal.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body create-note text-sm">
    <div class="row mb-2">
      <div class="col-6">
        <div>
          <span class="text-dark fw-500 mr-1" for="access_group"
            >Access Group</span
          ><span class="text-danger text-sm">*</span>
          <select name="access_group" id="access_group" class="form-control">
            <option value="">Select</option>
            <option [value]="opt" *ngFor="let opt of accessGroups">
              {{ opt }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div>
          <span class="text-dark fw-500" for="subject">Subject</span>
          <input type="text" name="subject" id="subject" class="form-control" />
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-12">
        <div>
          <span class="text-dark fw-500 mr-1" for="noteTitle">Title</span
          ><span class="text-danger text-sm">*</span>
          <input
            type="text"
            name="noteTitle"
            id="noteTitle"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <div>
          <span class="text-dark fw-500 mr-1" for="noteBody">Body</span
          ><span class="text-danger text-sm">*</span>
          <ckeditor
            class="text-black"
            name="noteBody"
            id="noteBody"
            [editor]="noteBodyeditor"
          ></ckeditor>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <div>
          <span class="text-dark fw-500" for="noteTags">Tags</span>
          <span class="text-sm">(Press enter to add tags.)</span>
          <div class="border p-1 rounded">
            <ng-container *ngFor="let tag of selectedNoteTags; let i = index">
              <span class="bg-light-dark text-dark text-sm mr-1 p-1 rounded">
                {{ tag }}
                <em
                  title="Remove"
                  class="fa fa-remove text-danger cursor-pointer"
                  (click)="selectedNoteTags.splice(i, 1)"
                ></em>
              </span>
            </ng-container>
            <input
              type="text"
              name="noteTags"
              id="noteTags"
              class="border-0 text-sm"
              placeholder="Enter tags here"
              (keydown.enter)="addNoteTags($event)"
            />
          </div>
          <span class="text-sm"
            >A tag is a keyword or label that categorizes your update with
            other, similar updates.</span
          >
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-12">
        <div>
          <div class="text-dark fw-500 mb-1" for="noteFiles">
            Files
            <span class="text-sm mr-1 text-gray">(Max File Size: 5G)</span>
            <button
              class="btn btn-sm btn-primary rounded"
              (click)="openNotefilesInputDialog(notefilesInput)"
              title="Add Files"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
          <input
            type="file"
            #notefilesInput
            class="d-none"
            name="noteFiles"
            id="noteFiles"
            multiple
            accept=".pdf,.xls,.xlsx"
            (change)="onNoteFileSelected($event)"
          />
        </div>
        <div *ngIf="selectedNoteFiles?.length; else noFilesSelected">
          <ol class="px-3">
            <li
              *ngFor="let file of selectedNoteFiles; let i = index"
              class="text-black text-sm"
            >
              <span class="text-dark-bold">{{ file.name }}</span> ({{
                file.formattedSize
              }})
              <button
                class="btn btn-sm text-danger"
                title="Remove"
                (click)="selectedNoteFiles.splice(i, 1)"
              >
                <em class="fa fa-remove"></em>
              </button>
            </li>
          </ol>
        </div>
        <ng-template #noFilesSelected>
          <span class="text-sm">No Files selected.</span>
        </ng-template>
      </div>
    </div>

    <div class="mt-4 d-flex justify-content-between">
      <div>
        <input
          type="checkbox"
          name="expense_entry"
          id="expense_entry"
          [(ngModel)]="createExpenseEntry"
        /><label for="expense_entry" class="text-dark fw-500 ml-1"
          >Create expense entry after save</label
        >
      </div>
      <div>
        <button
          type="button"
          class="btn btn-secondary btn-sm text-white mr-1"
          (click)="addModal.dismissAll()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn limos-button-background btn-sm text-white"
          (click)="createNotepad(createTimeSpent)"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #createTimeSpent let-modal>
  <div class="modal-header">
    <h6>Add Time Spent</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="addModal.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body create-time-spent">
    <div class="row mb-2">
      <div class="col-12">
        <div>
          <input
            type="text"
            name="time_spent"
            id="time_spent"
            class="form-control text-center"
            mask="00:00:00"
            placeholder="hh:mm:ss"
          />
        </div>
      </div>
    </div>

    <div class="mt-4 text-right">
      <div>
        <button
          type="button"
          class="btn btn-secondary btn-sm text-white mr-1"
          (click)="addModal.dismissAll()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn limos-button-background btn-sm text-white"
          (click)="createExpense()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</ng-template>
