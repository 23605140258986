import { Component, OnInit } from '@angular/core';

import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalService } from 'src/app/services/modal.service';
import {
  LimosDataResponse,
  LimosReportDataResponse,
  LimosReportCSVExportResponse,
  TaskStatus,
} from 'src/app/models/limos/limos.models';
import { LimosService } from 'src/app/services/limos.service';
import { ExportAllCsvComponent } from '../shared/Modals/export-all-csv/export-all-csv.component';

interface Provider {
  id: number;
  name: string;
  type: string;
  state: string;
  npi: number;
  source: string;
  notes: string;
  checked: boolean;
}

const PROVIDERS: Provider[] = [
  {
    id: 100215578,
    name: 'Jane Montgomery Inc',
    type: 'C',
    state: 'CA',
    npi: 10212522,
    source: 'NPI Full',
    notes: 'Google Hangout Invite',
    checked: false,
  },
  {
    id: 100215578,
    name: 'Jane Montgomery Inc',
    type: 'C',
    state: 'CA',
    npi: 10212522,
    source: 'NPI Full',
    notes: '',
    checked: false,
  },
  {
    id: 100215578,
    name: 'Jane Montgomery Inc',
    type: 'C',
    state: 'CA',
    npi: 10212522,
    source: 'NPI Full',
    notes: 'Join team Member calls',
    checked: false,
  },
  {
    id: 100215578,
    name: 'Jane Montgomery Inc',
    type: 'C',
    state: 'CA',
    npi: 10212522,
    source: 'NPI Full',
    notes: 'Mark the entry as paid',
    checked: false,
  },
  {
    id: 100215578,
    name: 'Jane Montgomery Inc',
    type: 'C',
    state: 'CA',
    npi: 10212522,
    source: 'NPI Full',
    notes: 'Trf. data to Provider ID',
    checked: false,
  },
  {
    id: 100215578,
    name: 'Jane Montgomery Inc',
    type: 'C',
    state: 'CA',
    npi: 10212522,
    source: 'NPI Full',
    notes: 'Join team Member calls',
    checked: false,
  },
  {
    id: 100215578,
    name: 'Jane Montgomery Inc',
    type: 'C',
    state: 'CA',
    npi: 10212522,
    source: 'NPI Full',
    notes: '',
    checked: false,
  },
];

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
})
export class ReportsComponent implements OnInit {
  show = 'hide';
  hide = 'show';

  constructor(
    private modalService: ModalService,
    private limosService: LimosService,
    private ngbModal: NgbModal
  ) {
    this.refreshProviders();
  }

  todayDate: Date = new Date();
  isSideBarOpen: boolean;

  providers = PROVIDERS;

  page = 1;
  pageSize = 20;
  collectionSize = PROVIDERS.length;
  provider: Provider[];

  activeButton = '';

  buttons = [
    { name: 'Export Providers with EIN' },
    { name: 'Export Providers with Empty Names' },
    { name: 'Export NPIs by Address' },
    { name: 'Find Empty Reason Codes' },
    { name: 'Export Empty Reason Codes' },
    { name: 'Find DWC Missing Reason Code' },
    { name: 'Export DWC Missing Reason Code' },
    { name: 'Find CC Missing Reason Code' },
    { name: 'Export  CC Missing Reason Code' },
    { name: 'Export All Reason Codes' },
    { name: 'Export Limos File Status Report' },
  ];

  showPhase(event): void {
    if (event.name == 'Export Limos File Status Report') {
      this.getLimosReportData();
      return;
    }
    console.log(event);
    this.show = 'show';
    this.activeButton = event;
  }

  refreshProviders(): void {
    this.providers = PROVIDERS.map((provider, i) => ({
      id: i + 1,
      ...provider,
    })).slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
  }

  ngOnInit(): void {}

  openModalx(value): void {
    this.show = 'hide';
    this.modalService.openModalForFindOrExport();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // showClass(): void{
  //   if (!this.isSideBarOpen){
  //     this.show = 'show';
  //   }
  // }
  hideClass(): void {
    this.show = 'hide';
  }

  getLimosReportData() {
    this.limosService
      .getLimosReport(1, 1000, true)
      .subscribe(
        (
          limosResponse: LimosDataResponse<
            LimosReportDataResponse & LimosReportCSVExportResponse
          >
        ) => {
          this.openExportCSV();
          this.checkLimosReportExportStatus(limosResponse?.details?.task_id);
          return;
        }
      );
  }

  openExportCSV(): void {
    this.ngbModal.open(ExportAllCsvComponent, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size: 'sm',
      centered: true,
    });
  }

  checkLimosReportExportStatus(taskId: string) {
    if (taskId) {
      const interVal = setInterval(() => {
        this.limosService
          .checkLimosReportTaskStatus(taskId)
          .subscribe((x: LimosDataResponse<TaskStatus>) => {
            if (x.details.status == 'SUCCESS') {
              this.ngbModal.dismissAll();
              const a = document.createElement('a');
              a.href = x.details.result.signed_url;
              a.download = 'record.csv';
              a.click();
            }
            clearInterval(interVal);
          });
      }, 5000);
    }
  }
}
