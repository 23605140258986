import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-entities-list',
  templateUrl: './entities-list.component.html',
  styleUrls: ['./entities-list.component.css']
})
export class EntitiesListComponent implements OnInit {

  chipsInput: string;

  isDisabled = true;
  closeResult = '';
  investigation_data = [];
  selectedId: number;
  rowidEntity = 0;
  idState = '';

  constructor(private modalService: NgbModal, private http: HttpClient) { }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: 'groupModal', centered: true })
      .result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  show(content1): void {
    this.modalService.open(content1, { ariaLabelledBy: 'modal-basic-title', windowClass: 'groupModal', size: 'lg', centered: true })
      .result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  del(deletecontent, id: any): void {
    this.modalService.open(deletecontent, { ariaLabelledBy: 'modal-basic-title', windowClass: 'groupModal delModal', centered: true })
      .result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    this.selectedId = id;
  }

  cnfdel(cnfdeletecontent): void {
    this.modalService.open(cnfdeletecontent, { ariaLabelledBy: 'modal-basic-title', windowClass: 'groupModal delModal', centered: true })
      .result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  deleteEntity() {
    this.http.delete(`https://spear-django-uq3cjixc7q-uw.a.run.app/api/v1/associations/entities/delete/${this.selectedId}/`).subscribe((data) => {
      this.loadInvestigations();
      this.modalService.dismissAll();
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  loadInvestigations() {
    this.investigation_data = [];
    this.http.get<any>('https://spear-django-uq3cjixc7q-uw.a.run.app/api/v1/associations/entities/all/').subscribe((data) => {
      if (data) {
        for (let i = 0; i < data.details.length; i++) {
          let obj = {
            id: data.details[i]?.id,
            entity_name: data.details[i]?.entity_name,
            associations: data.details[i]?.associations,
            created_by_name: data.details[i]?.created_by_name,
            created_at: data.details[i]?.created_at,
            modified_at: data.details[i]?.modified_at
          }
          this.investigation_data.push(obj);
        }
      }
    });
  }

  makeEditableEntity(id: number) {
    this.rowidEntity = 0;
    this.rowidEntity = id;
    document.getElementById(`editEntity-${id}`).hidden = true;
    document.getElementById(`deleteEntity-${id}`).hidden = true;
    document.getElementById(`saveEntity-${id}`).hidden = false;
    document.getElementById(`cancelEntity-${id}`).hidden = false;
    document.getElementById(`entity_name-${id}`).removeAttribute("disabled");
    document.getElementById(`entityName-${id}`).classList.add("d-none");
    document.getElementById(`entity_name-${id}`).classList.remove("d-none");
    this.idState = (<HTMLInputElement>document.getElementById(`entity_name-${id}`)).value ? (<HTMLInputElement>document.getElementById(`entity_name-${id}`)).value : null;
    let editBut = document.querySelectorAll(".editEntity");
    for (let i = 0; i < editBut.length; i++) {
      editBut[i].setAttribute("disabled", "disabled")
    }
  }

  saveEditableEntity(id: number) {
    document.getElementById(`entity_name-${id}`).removeAttribute("disabled");
    let rawString = (<HTMLInputElement>document.getElementById(`entity_name-${id}`)).value;
    if (this.idState != rawString) {
      let reqBody = {
        entity_name: rawString
      };
      this.http.put(`https://spear-django-uq3cjixc7q-uw.a.run.app/api/v1/associations/entities/edit/${id}`, reqBody).subscribe(() => {
        this.loadInvestigations();
        document.getElementById(`entity_name-${id}`).setAttribute("disabled", "disabled");
        document.getElementById(`entityName-${id}`).classList.remove("d-none");
        document.getElementById(`entity_name-${id}`).classList.add("d-none");
      });
    }

    document.getElementById(`editEntity-${id}`).hidden = false;
    document.getElementById(`saveEntity-${id}`).hidden = true;
    document.getElementById(`cancelEntity-${id}`).hidden = true;
    document.getElementById(`deleteEntity-${id}`).hidden = false;    

    let editBut = document.querySelectorAll(".editEntity");
    for (let i = 0; i < editBut.length; i++) {
      editBut[i].removeAttribute("disabled")
    }
  }

  cancelEditableEntity(id: number) {
    (<HTMLInputElement>document.getElementById(`entity_name-${id}`)).value = this.idState;

    document.getElementById(`entity_name-${id}`).setAttribute("disabled", "disabled");

    document.getElementById(`editEntity-${id}`).hidden = false;
    document.getElementById(`saveEntity-${id}`).hidden = true;
    document.getElementById(`cancelEntity-${id}`).hidden = true;
    document.getElementById(`deleteEntity-${id}`).hidden = false;
    document.getElementById(`entityName-${id}`).classList.remove("d-none");
    document.getElementById(`entity_name-${id}`).classList.add("d-none");

    let editBut = document.querySelectorAll(".editEntity");
    for (let i = 0; i < editBut.length; i++) {
      editBut[i].removeAttribute("disabled")
    }
  }

  onSubmit() {
    let reqBody = {
      entity_name: (<HTMLInputElement>document.getElementById('entityName')).value,
      spear_profile_ids: []
    }
    this.http.post<any>('https://spear-django-uq3cjixc7q-uw.a.run.app/api/v1/associations/entities/create/', reqBody).subscribe((data) => {
      if (data) {
        this.modalService.dismissAll();
        this.investigation_data = [];
        this.http.get<any>('https://spear-django-uq3cjixc7q-uw.a.run.app/api/v1/associations/entities/all/').subscribe((data) => {
          if (data) {
            for (let i = 0; i < data.details.length; i++) {
              let obj = {
                id: data.details[i]?.id,
                entity_name: data.details[i]?.entity_name,
                associations: data.details[i]?.associations,
                created_by_name: data.details[i]?.created_by_name,
                created_at: data.details[i]?.created_at,
                modified_at: data.details[i]?.modified_at
              }
              this.investigation_data.push(obj);
            }
          }
        });
      }
    });
  }


  ngOnInit(): void {
    this.loadInvestigations();
  }
  onClick(): void {
    this.isDisabled = !this.isDisabled;
  }

}
