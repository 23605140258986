import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'orderBy'
  })
  
  export class SortPipe implements PipeTransform {
    transform(value: any, propName: string) {
  
     return value.sort((a, b) => Number(a[propName]) - Number(b[propName]) );
  
    }
  }