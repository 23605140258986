import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][appCustomMaxLength]',
})
export class CustomMaxLengthDirective {
  @Input() max: number;
  constructor(public ngControl: NgControl) {
    this.max = Number(this.max);
  }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event): void {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event): void {
    this.onInputChange(event.target.value, true);
  }

  onInputChange(event, backspace): void {
    // for max length check
    const newVal = event ? event.replace(/\D/g, '') : '';
    if (newVal.length > this.max) {
      const value = newVal.substring(0, this.max);
      this.ngControl.valueAccessor.writeValue(value);
    }
  }
}
