import { Component, OnInit, ViewContainerRef, OnDestroy, NgZone, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { LoginModel } from 'src/app/models/LoginModel';
import { AuthService } from 'src/app/services/auth.service';
import { AccountService } from 'src/app/services/account.service';
import { AppUtils } from 'src/app/utilities/app.utils';
import { environment } from 'src/environments/environment';

declare let window: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {

  @BlockUI('frm-login') blockUI: NgBlockUI;
  private loginModel: LoginModel = { username: '', password: '' };
  private returnUrl: string;
  subscriptions: Subscription[] = [];
  apiURL = environment.fireStoreApiUrl;
  showLogin = true;
  errMsg = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private accountService: AccountService,
    private appUtils: AppUtils,
    protected vcr: ViewContainerRef,
    private toastr: ToastrService,
    private http: HttpClient,
    private nzZone: NgZone
  ) {
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params.returnUrl;
    });
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.navigate();
    }
  }

  ngAfterViewInit(): void {
    window.handleCredentialResponse = (response) => {
      this.handleCredentialResponse(response);
    };
    // Initialize Google Sign-In API
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    document.head.appendChild(script);
    script.onload = () => {
      // This will trigger the initialization of the Google Sign-In API
      window.google.accounts.id.initialize({
        client_id: '112767788198-l6mue54i0hvmc6ekit8a6jvqv7vus5vd.apps.googleusercontent.com',
        callback: this.handleCredentialResponse.bind(this)
      });
    };
  }

  handleCredentialResponse(response: any): void {
    this.http.post(`${this.apiURL}/api/v1/auth/google/`, { access_token: response?.credential })
      .subscribe(
        (data: any) => {
          this.handleGoogleAuthResponse(data);
        },
        error => {
          this.blockUI.stop();
          this.appUtils.ProcessErrorResponse(this.toastr, error);
        }
      );
  }

  handleGoogleAuthResponse(data: any): void {
    if (data !== null) {
      const auth: { access_token?: string } = data;
      this.authService.storeToken(auth.access_token);
      this.authService.notifyToLoadCurrentUserProfile();
      this.navigate();
      this.blockUI.stop();
    }
  }

  login(): void {
    this.errMsg = false;
    this.blockUI.start();
    this.preDashboard();
    this.accountService.login(this.loginModel)
      .subscribe(
        (data: any) => {
          if (data.access) {
            this.authService.storeToken(data.access);
            this.authService.notifyToLoadCurrentUserProfile();
            this.navigate();
            this.blockUI.stop();
          }
        },
        error => {
          this.blockUI.stop();
          if (error.error.detail === 'No active account found with the given credentials') {
            this.errMsg = true;
          }
        }
      );
  }

  preDashboard(): void {
    if (!localStorage.getItem('predashboard')) {
      localStorage.setItem('predashboard', 'true');
    }
  }

  valueChange(): void {
    this.errMsg = false;
  }

  private navigate(): void {
    const targetUrl = this.returnUrl ? this.returnUrl : 'search';
    this.nzZone.run(() => {
      window.open(targetUrl, '_self');
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(item => item.unsubscribe());
  }
}
