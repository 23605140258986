import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { BlockUIModule } from 'ng-block-ui';
import { ValidateEqualModule } from 'ng-validate-equal';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { TagInputModule } from 'ngx-chips';
import { JoyrideModule } from 'ngx-joyride';
import { NgxMaskModule } from 'ngx-mask';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';

export const AppModuleImports = [
  BrowserModule,
  AppRoutingModule,
  NgbModule,
  FormsModule,
  BrowserAnimationsModule,
  PerfectScrollbarModule,
  ReactiveFormsModule,
  BlockUIModule.forRoot(),
  HttpClientModule,
  ToastrModule.forRoot({
    positionClass: 'toast-bottom-center',
    preventDuplicates: true,
  }),
  NgbModalModule,
  ValidateEqualModule,
  TagInputModule,
  NgSelectModule,
  HttpClientModule,
  DragDropModule,
  CommonModule,
  BrowserModule,
  BsDatepickerModule.forRoot(),
  DatepickerModule.forRoot(),
  JoyrideModule.forRoot(),
  NgxMaskModule.forRoot(),
  CKEditorModule,
];
