import { EventEmitter, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import * as XLSX from 'xlsx';
import xml2js from 'xml2js';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  eventEmitter = new EventEmitter<string>();
  constructor() {}

  public importFromExcelFile(bstr: string): XLSX.AOA2SheetOpts {
    /* read workbook */
    const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

    /* grab first sheet */
    const wsname: string = wb.SheetNames[0];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];

    /* save data */
    const data = XLSX.utils.sheet_to_json(ws, {
      header: 1,
    }) as XLSX.AOA2SheetOpts;

    return data;
  }

  public exportToExcelFile(fileName: string, elementId: string): void {
    if (!elementId) {
      throw new Error('Element Id does not exists');
    }

    const tbl = document.getElementById(elementId);
    const wb = XLSX.utils.table_to_book(tbl);
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  public importFromCSVFile(bstr: string): string[] {
    return bstr.split(/\r\n|\n/);
  }

  public importFromXMLFile(bstr: string): Observable<any> {
    return from(this.xmlParser(bstr));
  }

  xmlParser = (source: string) => {
    return new Promise(resolve => {
      const parser = new xml2js.Parser(
          {
            trim: true,
            explicitArray: true
          });
      parser.parseString(source, (err, result) => {
      resolve(result);
      });
    });
  }
}
