import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { AppUtils } from 'src/app/utilities/app.utils';
import { ToastrService } from 'ngx-toastr';
import { ResetPasswordModel } from 'src/app/models/account/reset.password.model';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  @BlockUI('frm-resetPassword') blockUI: NgBlockUI;
  resetPasswordModel: ResetPasswordModel = {
    email: '',
    password: '',
    confirmPassword: '',
    token: '',
    userId: ''
  };
  constructor(private route: ActivatedRoute,
              private router: Router,
              private accountService: AccountService,
              private appUtils: AppUtils,
              private toastr: ToastrService) {
    this.route.queryParams.subscribe(params => {
      this.resetPasswordModel.email = params.email;
      this.resetPasswordModel.token = params.token;
    });
  }

  ngOnInit(): void {
  }

  resetPassword(): void {
    this.blockUI.start();
    this.accountService.resetPassword(this.resetPasswordModel).subscribe(data => {
      this.toastr.success(data as string);
      setTimeout (() => {
        this.router.navigate(['/login']);
        this.blockUI.stop();
     }, 1000);
    }
    // error => {
    //   this.blockUI.stop();
    //   this.appUtils.ProcessErrorResponse(this.toastr, error);
    // }
    );
  }

}
