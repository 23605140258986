<div class="container-fluid p-0">
    <div class="row">
        <div class="col-md-12">
            <div class="w-100 pt-4">
                <div class="row mb-2 mb-xl-3 align-items-start">
                    <div class="col-auto d-sm-block"></div>
                    <h3 class="page-title">
                        Scrub
                    </h3>

                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="card border-0">
                <div class="card-body">
                    <!-- <div class="card-title">
                        asd
                    </div> -->

                    <form class="new-case-form" name="form" [formGroup]="form">
                        <div class="row">
                            <div class="col-md-12">

                                <div class="row ">


                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-4">Upload Client Data <br><small>(XLS,CSV,XML)</small>
                                            </div>
                                            <div class="col-md-8">
                                                <input id="fileInput" name="file" type="file"
                                                    (change)="onFileChange($event)" formControlName="upload_data"
                                                    class="form-control-file"
                                                    accept=".csv,text/xml,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
                                            </div>
                                        </div>


                                    </div>

                                    <div class="col-md-5">
                                        <div class="row">
                                            <div class="col-md-4">Data source</div>
                                            <div class="col-md-8">

                                                <ul class="row">
                                                    <li class="col-12 mb-2">
                                                        {{fileName}}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>


                                    </div>


                                </div>

                                <div class="row  justify-content-end mt-3">
                                    <div class="col-md-auto align-items-end">
                                        <button class="btn bg-voilet text-white" (click)="onSubmit()">SCRUB</button>
                                    </div>
                                    <div class="col-md-auto align-items-end">
                                        <button class="btn bg-voilet text-white" (click)="onSubmit()"> Billing</button>
                                    </div>
                                    <div class="col-md-auto align-items-end">
                                        <button class="btn bg-voilet text-white" (click)="onSubmit()">Payments</button>
                                    </div>
                                    <div class="col-md-auto align-items-end">
                                        <button class="btn bg-voilet text-white " (click)="onSubmit()">MPN</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="card mt-4 border-0">
                <div class="card-body">


                    <!-- table -->

                    <div class="container-fluid p-0" id="scrubtableui" *blockUI="'file-input-loader'">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="w-100 pt-4">
                                    <div class="row mb-2 mb-xl-3 justify-content-between align-items-center">
                                        <div class="col-auto">
                                            <h3 class="page-title">Result</h3>
                                            <!-- <p>Get any excel to import document.</p> -->

                                        </div>
                                        <div class="col-auto">
                                            <div class="print-wrap">
                                                <button class="btn bg-voilet text-white "
                                                    (click)="printComponent('scrubtableui')"><em class="fa fa-print"></em>
                                                    Print</button>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="table-row table-responsive">
                                        <table class="table heading-hvr">
                                            <caption>Data Table</caption>
                                            <thead>
                                                <tr>
                                                    <th class="border-0" scope="col">
                                                        <div class="custom-control custom-checkbox checkbox-custom">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="customCheck">
                                                            <label
                                                                class="custom-control-label control-custom-label cursor-pointer"
                                                                for="customCheck"></label>
                                                        </div>
                                                    </th>
                                                    <th class="border-0" scope="col">NPI</th>
                                                    <th class="border-0" scope="col">Tax ID
                                                    </th>
                                                    <th class="border-0" scope="col">Name
                                                    </th>
                                                    <th class="border-0" scope="col">Hits</th>
                                                    <th class="border-0" scope="col">Total Amount
                                                    </th>

                                                </tr>
                                            </thead>
                                            <!-- for empty tr-->
                                            <tr class="bg-white"
                                                *ngIf="!importedScrubData || importedScrubData.length === 0">
                                                <td colspan="12" class="text-center">No Records Found</td>
                                            </tr>
                                            <tbody>
                                                <tr *ngFor="let scrubdata of importedScrubData; index as i">
                                                    <td class="primary-text font-weight-semibold">
                                                        <div
                                                            class="custom-control custom-checkbox checkbox-custom align-items-center">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="customCheck1_{{i}}">
                                                            <label
                                                                class="custom-control-label control-custom-label cursor-pointer"
                                                                for="customCheck1_{{i}}"></label>
                                                        </div>
                                                    </td>
                                                    <td>{{scrubdata.NPI}}</td>
                                                    <td>{{scrubdata.TAX_ID}}</td>
                                                    <td>{{scrubdata.NAME}}</td>
                                                    <td>{{scrubdata.HITS}}</td>
                                                    <td>{{scrubdata.TOTAL_AMOUNT}}</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end -->



                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-overlay" *ngIf="modalstatus">

</div>
<div id="modal-content-wrapper" *ngIf="modalstatus">

    <header id="modal-header">
        <h1 id="modal-title">Confirm</h1>
    </header>
    <section id="modal-body" class="mt-4">
        <p>Do you want to upload the same file <strong>{{fileName}} ({{convertedFileSize}} KB)</strong> again?</p>
    </section>
    <footer id="modal-footer" class="d-flex flex-row-reverse mt-5">

        <button (click)="onProceedModelPopup()" class="btn bg-voilet" id="modal-cancel-button">
            Proceed
        </button>
        <button (click)="onCancelModelPopup()" class="btn text-danger transparent-bg" id="modal-action-button">
            Cancel
        </button>
    </footer>
</div>