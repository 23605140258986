import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  Client,
  ClientCreateResponse,
  ClientDataResponse,
  ClientPaginatedData,
  CreateClientContactReq,
  CreateClientLocationReq,
  CreateClientReq,
} from '../models/client/client.interface';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private http: HttpClient) {}

  createClient(
    reqBody: CreateClientReq
  ): Observable<ClientDataResponse<ClientCreateResponse>> {
    return this.http.post<ClientDataResponse<ClientCreateResponse>>(
      `${environment.fireStoreApiUrl}/api/v1/client/`,
      reqBody
    );
  }

  getClients(
    limit: number,
    offSet: number
  ): Observable<ClientDataResponse<ClientPaginatedData>> {
    return this.http.get<ClientDataResponse<ClientPaginatedData>>(
      `${environment.fireStoreApiUrl}/api/v1/client/all/?limit=${limit}&offset=${offSet}`
    );
  }

  getClientDetail(id: number): Observable<ClientDataResponse<Client>> {
    return this.http.get<ClientDataResponse<Client>>(
      `${environment.fireStoreApiUrl}/api/v1/client/get/${id}`
    );
  }

  createClientContact(
    clientId: number,
    reqBody: CreateClientContactReq
  ): Observable<ClientDataResponse<ClientCreateResponse>> {
    return this.http.post<ClientDataResponse<ClientCreateResponse>>(
      `${environment.fireStoreApiUrl}/api/v1/client/contact/${clientId}/`,
      reqBody
    );
  }

  createClientLocation(
    clientId: number,
    reqBody: CreateClientLocationReq
  ): Observable<ClientDataResponse<ClientCreateResponse>> {
    return this.http.post<ClientDataResponse<ClientCreateResponse>>(
      `${environment.fireStoreApiUrl}/api/v1/client/location/${clientId}/`,
      reqBody
    );
  }

  deleteClient(
    id: number
  ): Observable<ClientDataResponse<ClientCreateResponse>> {
    return this.http.delete<ClientDataResponse<ClientCreateResponse>>(
      `${environment.fireStoreApiUrl}/api/v1/client/delete/${id}`
    );
  }

  updateClient(
    clientId: number,
    reqBody: CreateClientReq
  ): Observable<ClientDataResponse<ClientCreateResponse>> {
    return this.http.put<ClientDataResponse<ClientCreateResponse>>(
      `${environment.fireStoreApiUrl}/api/v1/client/update/${clientId}/`,
      reqBody
    );
  }
}
