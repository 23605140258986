<div class="container-fluid p-0 dashboard-wrapper">
  <div class="row">
    <div class="col-md-12">
      <div class="w-100">
        <div class="row mb-xl-3 justify-content-between">
          <div class="col-auto">
            <h3 class="page-title">Dashboard</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-8">
            <div class="card flex-fill border-0 bg-grey">
              <div class="card-header rounded pb-0 bg-white border-0">
                <div class="row mb-4 d-flex justify-content-end">
                  <div class="col-1"></div>
                  <div class="col-3">
                    <select name="case" id="case" class="form-control p-1">
                      <option value="" disabled selected>Select</option>
                      <option value="" disabled>Case Name</option>
                      <option value="" disabled>Case Number</option>
                      <option value="" disabled>Client Reference #</option>
                      <option value="" disabled>Insured</option>
                      <option value="" disabled>Policy #</option>
                    </select>
                  </div>
                  <div class="col-4 d-flex">
                    <input
                      type="text"
                      class="form-control mr-2"
                      placeholder="Enter text"
                    />
                    <button type="button" class="btn btn-sm btn-primary">
                      Search
                    </button>
                  </div>

                  <div class="col-2 text-right">
                    <button
                      type="button"
                      class="btn btn-sm btn-info p-1 w-100 h-100"
                      (click)="openModal(createNewCase, 'xl')"
                    >
                      New Case
                    </button>
                  </div>
                  <div
                    class="col-2 text-right"
                    *ngIf="activeDepartmentTab !== 'Company'"
                  >
                    <button
                      type="button"
                      class="btn btn-sm btn-secondary p-1 w-100 h-100"
                      (click)="openModal(createNewProgression, 'md')"
                    >
                      New Progression
                    </button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 primary-tab">
                    <!-- <h3 class="page-title d-inline-block mr-4">
                                    Search by
                                </h3>
                                <select (change)="searchBy($event)" [(ngModel)]="searchByInVal"
                                    class="form-control d-inline-block w-auto">
                                    <option value="byspear">Spear</option>
                                    <option value="bylimos">LIMOS</option>
                                </select> -->

                    <ul class="nav nav-tabs">
                      <li class="nav-item text-black-contrast">
                        <button
                          class="active nav-link text-uppercase"
                          [ngClass]="{
                            'search-spear-background':
                              activeDepartmentTab == 'Company'
                          }"
                          (click)="
                            activeDepartmentTab = 'Company';
                            getDepartmentProgressions()
                          "
                        >
                          Company
                        </button>
                      </li>
                      <li
                        class="nav-item text-black-contrast"
                        *ngFor="let tab of departmentNames"
                      >
                        <button
                          class="active nav-link text-uppercase"
                          [ngClass]="{
                            'search-spear-background':
                              activeDepartmentTab == tab.name
                          }"
                          (click)="
                            activeDepartmentTab = tab.name;
                            getDepartmentProgressions(tab.id);
                            setNewProgressionFormData(tab.id)
                          "
                        >
                          {{ tab.name }}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="card-body search-spear-background department-card">
                <div class="table-row" *blockUI="'department-card-ui'">
                  <table class="table spear-search-table-header-background">
                    <thead>
                      <tr>
                        <th class="border-top-0" scope="col"></th>
                        <th class="border-top-0" scope="col">DEPARTMENTS</th>
                        <ng-container *ngFor="let column of departmentColumns">
                          <th class="border-top-0" scope="col">
                            {{ column.name }}
                          </th>
                        </ng-container>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                      <ng-container
                        *ngIf="
                          activeDepartmentTab == 'Company' &&
                            departmentNames?.length;
                          else showProgressions
                        "
                      >
                        <tr *ngFor="let row of departmentNames" cdkDrag>
                          <td>
                            <span class="drag-handle" cdkDragHandle>
                              <i
                                class="fa fa-arrows-alt text-muted"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </td>
                          <td>
                            <span class="text-black-contrast">{{
                              row.name
                            }}</span>
                          </td>
                          <td *ngFor="let column of departmentColumns">#</td>
                          <td>
                            <button
                              type="button"
                              class="btn btn-sm text-primary"
                              title="Edit"
                              (click)="
                                editDepartmentName(createDepartmentName, row)
                              "
                            >
                              <i class="fa fa-pencil"></i>
                            </button>
                            <button
                              type="button"
                              (click)="deleteDepartmentName(row.id)"
                              class="btn btn-sm text-danger"
                              title="Remove"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      </ng-container>

                      <ng-template #showProgressions>
                        <tr *ngFor="let row of progressions" cdkDrag>
                          <td>
                            <span class="drag-handle" cdkDragHandle>
                              <i
                                class="fa fa-arrows-alt text-muted"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </td>
                          <td>
                            <span class="text-black-contrast text-capitalize">{{
                              row.name
                            }}</span>
                          </td>
                          <td *ngFor="let column of departmentColumns">#</td>
                          <td>
                            <button
                              class="btn btn-sm text-primary"
                              title="Edit"
                              (click)="
                                editProgression(row, createNewProgression)
                              "
                            >
                              <i class="fa fa-pencil"></i>
                            </button>
                            <button
                              class="btn btn-sm text-danger"
                              title="Remove"
                              (click)="deleteDepartmentProgression(row)"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      </ng-template>

                      <tr
                        *ngIf="
                          (!progressions?.length &&
                            departmentColumns?.length) ||
                          (!departmentNames?.length &&
                            departmentColumns?.length)
                        "
                      >
                        <td
                          class="text-center"
                          [attr.colspan]="departmentColumns?.length + 2"
                        >
                          Data not available
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="card flex-fill border-0">
              <div class="card-header">
                <h5>Recent Views</h5>
              </div>
              <div
                class="card-body rounded px-4 pt-3 pb-0 bg-white search-spear-background"
              >
                <div class="table-row">
                  <table class="table spear-search-table-header-background">
                    <thead>
                      <tr>
                        <th class="border-top-0" scope="col">Case Number</th>
                        <th class="border-top-0" scope="col">Name Of Case</th>
                        <th class="border-top-0" scope="col">Case Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="3" class="text-center">
                          Data not available
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-8" *blockUI="'diaries-list-ui'">
            <div class="card flex-fill border-0 mt-3">
              <div class="card-header">
                <ul class="nav nav-pills">
                  <li class="nav-item">
                    <a
                      class="nav-link active text-sm font-weight-bold text-dark"
                      data-toggle="pill"
                      href="#home"
                      (click)="setCaseDtlsColumns('Diaries')"
                      >Diaries</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link text-sm font-weight-bold text-dark"
                      data-toggle="pill"
                      href="#menu2"
                      (click)="setCaseDtlsColumns('Assigned Cases')"
                      >Assigned Cases</a
                    >
                  </li>
                </ul>
              </div>
              <div
                class="card-body rounded px-4 pt-3 pb-0 bg-white search-spear-background"
              >
                <div class="text-right" *ngIf="selectedCaseTab == 'Diaries'">
                  <input
                    type="checkbox"
                    name="all_diaries"
                    id="all_diaries"
                    [checked]="false"
                    (change)="checkAllDiaries($event.target.checked)"
                  />
                  <label
                    for="all_diaries"
                    class="text-sm text-black-contrast pl-1"
                    >All Diaries</label
                  >
                </div>
                <div class="table-row">
                  <table class="table spear-search-table-header-background">
                    <thead>
                      <tr>
                        <th
                          *ngFor="let column of caseDtlsColumns"
                          class="border-top-0"
                          scope="col"
                        >
                          {{ column }}
                        </th>
                      </tr>
                    </thead>
                    <ng-container>
                      <tbody
                        *ngIf="selectedCaseTab == 'Diaries' && diaries?.length"
                      >
                        <tr class="bg-white" *ngFor="let diary of diaries">
                          <td>{{ diary.case_number }}</td>
                          <td>{{ diary.case_name }}</td>
                          <td>{{ diary.type }}</td>
                          <td>{{ diary.note }}</td>
                          <td>
                            {{ diary.due_date | date : clientDateFormat }}
                          </td>
                        </tr>
                      </tbody>
                    </ng-container>

                    <tbody *ngIf="selectedCaseTab == 'Assigned Cases'"></tbody>
                    <tbody *ngIf="!diaries?.length">
                      <tr>
                        <td
                          [attr.colspan]="caseDtlsColumns.length"
                          class="text-center"
                        >
                          Data not available
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    class="px-0 py-2 paginationWrapper spearPaginationWrapper d-flex"
                    *ngIf="selectedCaseTab == 'Diaries' && diaries?.length"
                  >
                    <ngb-pagination
                      [(collectionSize)]="diaryTotal"
                      [(page)]="diaryPage"
                      [pageSize]="diaryLimit"
                      [maxSize]="10"
                      [boundaryLinks]="true"
                      (pageChange)="onPageChangeDiaries($event)"
                    >
                    </ngb-pagination>

                    <div class="selected d-flex align-items-center">
                      <select
                        (change)="onSizeChangeDiaries($event.target.value)"
                        class="custom-select table-select h-100"
                        [(ngModel)]="diaryLimit"
                      >
                        <option [value]="30">30</option>
                        <option [value]="50">50</option>
                        <option [value]="75">75</option>
                        <option [value]="100">100</option>
                      </select>
                      <p class="mb-0 ml-2 text-nowrap">
                        Showing
                        {{ diaryPage * diaryLimit - diaryLimit + 1 }} -
                        {{
                          diaryPage * diaryLimit > diaryTotal
                            ? diaryTotal
                            : diaryPage * diaryLimit
                        }}
                        of {{ diaryTotal }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="card flex-fill border-0">
              <div class="card-header">
                <h5>Diary Outlook</h5>
              </div>
              <div
                class="card-body rounded px-4 pt-3 pb-0 bg-white search-spear-background"
              >
                <div class="table-row">
                  <table class="table spear-search-table-header-background">
                    <thead>
                      <tr>
                        <th class="border-top-0" scope="col">Due Today</th>
                        <th class="border-top-0" scope="col">Due Tomorrow</th>
                        <th class="border-top-0" scope="col">Due This Week</th>
                        <th class="border-top-0" scope="col">Past Due</th>
                        <th class="border-top-0" scope="col">No Due Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="5" class="text-center">
                          Data not available
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #createNewCase let-modal>
  <div class="modal-header">
    <h6>New Case</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body create-new-case text-sm">
    <form [formGroup]="caseForm">
      <div class="row mb-2">
        <div class="col-3">
          <div>
            <span class="text-dark fw-500 mr-1" for="caseCreatedOn"
              >Created On</span
            >
            <input
              type="text"
              name="caseCreatedOn"
              id="caseCreatedOn"
              class="form-control"
              [formCtrl]="caseForm.get('created_on')"
              [value]="todayDate | date : 'MM/dd/yyyy'"
              jDate
            />
          </div>
        </div>
        <div class="col-3">
          <div>
            <span class="text-dark fw-500 mr-1" for="caseDueDate"
              >Due Date</span
            >
            <input
              type="text"
              name="caseDueDate"
              id="caseDueDate"
              class="form-control"
              jDate
              [formCtrl]="caseForm.get('due_date')"
            />
          </div>
        </div>
        <div class="col-3">
          <div>
            <span class="text-dark fw-500 mr-1" for="case_client">Client</span>
            <input
              type="text"
              name="billingTo"
              class="form-control pl-1 w-100"
              [ngbTypeahead]="searchClient"
              [resultTemplate]="clientSearchTemplate"
              [inputFormatter]="clientSearchFormatter"
              (ngModelChange)="onClientSelect($event)"
              formControlName="client_name"
            />
            <div *ngIf="clientSearchLoader" class="clientSearchLoader"></div>
            <ng-template #clientSearchTemplate let-r="result" let-t="term">
              <div [term]="t">
                {{ r.client_name }}
              </div>
            </ng-template>
          </div>
        </div>
        <div class="col-3">
          <div>
            <span class="text-dark fw-500 mr-1" for="case_name"
              >Client Location</span
            >
            <select
              name="client_location"
              id="client_location"
              class="form-control"
              formControlName="client_location"
            >
              <option value="">Select</option>
              <option
                [value]="location"
                *ngFor="let location of clientLocations"
              >
                {{ location }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-3">
          <div>
            <span class="text-dark fw-500 mr-1" for="case_program"
              >Program</span
            >
            <input
              type="text"
              name="case_program"
              id="case_program"
              class="form-control"
              formControlName="program"
            />
          </div>
        </div>
        <div class="col-3">
          <div>
            <span class="text-dark fw-500 mr-1" for="case_tpa">TPA</span>
            <input
              type="text"
              name="case_tpa"
              id="case_tpa"
              class="form-control"
              formControlName="tpa"
            />
          </div>
        </div>
        <div class="col-3">
          <div>
            <span class="text-dark fw-500 mr-1" for="case_mga">MGA</span>
            <input
              type="text"
              name="case_mga"
              id="case_mga"
              class="form-control"
              formControlName="mga"
            />
          </div>
        </div>

        <div class="col-3">
          <div>
            <span class="text-dark fw-500 mr-1" for="case_contact"
              >Case Contact</span
            >
            <input
              type="text"
              name="case_contact"
              id="case_contact"
              class="form-control"
              formControlName="case_contact"
            />
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-3">
          <div>
            <span class="text-dark fw-500 mr-1" for="case_client_contact"
              >Client Contact</span
            >
            <select
              name="clie_contact"
              id="client_contact"
              class="form-control"
              formControlName="client_contact"
            >
              <option value="">Select</option>
              <option [value]="contact" *ngFor="let contact of clientContacts">
                {{ contact }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-3">
          <div>
            <span class="text-dark fw-500 mr-1" for="case_type">Case Type</span>
            <select
              name="case_type"
              id="case_type"
              class="form-control"
              formControlName="case_type"
            >
              <option value="">Select</option>
              <option [value]="tag" *ngFor="let tag of departmentTags">
                {{ tag }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-3">
          <div>
            <span class="text-dark fw-500 mr-1" for="case_status"
              >Case Status</span
            >
            <select
              name="case_status"
              id="case_status"
              class="form-control"
              formControlName="case_status"
            >
              <option value="">Select</option>
            </select>
          </div>
        </div>

        <div class="col-3">
          <div>
            <span class="text-dark fw-500 mr-1" for="case_client_reference_no"
              >Client Reference #</span
            >
            <input
              type="text"
              name="case_client_reference_no"
              id="case_client_reference_no"
              class="form-control"
              formControlName="case_reference"
            />
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-3">
          <div>
            <span class="text-dark fw-500 mr-1" for="case_name">Case Name</span>
            <input
              type="text"
              name="case_name"
              id="case_name"
              class="form-control"
              formControlName="case_name"
            />
          </div>
        </div>
        <div class="col-3">
          <div>
            <span class="text-dark fw-500 mr-1" for="case_insured"
              >Insured</span
            >
            <input
              type="text"
              name="case_insured"
              id="case_insured"
              class="form-control"
              formControlName="case_insured"
            />
          </div>
        </div>
        <div class="col-3">
          <div>
            <span class="text-dark fw-500 mr-1" for="case_location"
              >Case Location</span
            >
            <input
              type="text"
              name="case_location"
              id="case_location"
              class="form-control"
              formControlName="case_location"
            />
          </div>
        </div>
        <div class="col-3">
          <div>
            <span class="text-dark fw-500 mr-1" for="case_claim_status"
              >Claim Status</span
            >
            <input
              type="text"
              name="case_claim_status"
              id="case_claim_status"
              class="form-control"
              formControlName="claim_status"
            />
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-3">
          <div>
            <span class="text-dark fw-500 mr-1" for="case_adj_no">ADJ #</span>
            <input
              type="text"
              name="case_adj_no"
              id="case_adj_no"
              class="form-control"
              formControlName="adj_number"
            />
          </div>
        </div>
      </div>

      <div>
        <div class="mt-4 d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-secondary btn-sm text-white mr-1"
            (click)="modalService.dismissAll()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn limos-button-background btn-sm text-white"
            [routerLink]="['/cases']"
            (click)="modalService.dismissAll()"
          >
            Save & Continue
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #createNewProgression let-modal>
  <div class="modal-header">
    <h6>
      {{ editDepartmentProgressionData?.id ? "Update" : "New" }} Progression
    </h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body create-new-case text-sm">
    <form [formGroup]="progressionForm">
      <div class="row mb-2">
        <div class="col-6">
          <div>
            <span class="text-dark fw-500 mr-1 required" for="caseCreatedOn"
              >Name</span
            >
            <input
              type="text"
              name="name"
              id="progression_name"
              class="form-control"
              formControlName="name"
            />
          </div>
        </div>
        <div class="col-6">
          <div>
            <span class="text-dark fw-500 mr-1" for="color">Color</span>
            <input
              type="text"
              name="color"
              id="progression_color"
              class="form-control"
              formControlName="color"
            />
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500 mr-1" for="notes">Notes</span>
            <textarea
              name="notes"
              id="notes"
              class="border w-100 rounded"
              formControlName="notes"
            >
            </textarea>
          </div>
        </div>
      </div>

      <div>
        <div class="mt-4 d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-secondary btn-sm text-white mr-1"
            (click)="modalService.dismissAll()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn limos-button-background btn-sm text-white"
            (click)="onSubmitdepartmentProgressionForm()"
            [disabled]="progressionForm.invalid"
          >
            {{ editDepartmentProgressionData?.id ? "Update" : "Save" }}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #createDepartmentName let-modal>
  <div class="modal-header">
    <h6>{{ editDepartmentNameData?.id ? "Update" : "Add" }} Department Name</h6>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="addmodalService.dismissAll()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body create-department text-sm">
    <form [formGroup]="departmentNameForm">
      <div class="row mb-2">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500 required" for="department_name"
              >Name</span
            >
            <input
              type="text"
              name="department_name"
              id="department_name"
              class="form-control"
              formControlName="name"
            />
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500" for="tpa">Tag</span>
            <input
              type="text"
              name="tag"
              id="tag"
              class="form-control"
              formControlName="tag"
            />
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12">
          <div>
            <span class="text-dark fw-500" for="general_notes">Notes</span>
            <textarea
              type="text"
              name="notes"
              id="notes"
              class="w-100 border-grey"
              rows="5"
              formControlName="notes"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="mt-4 d-flex justify-content-end">
        <div>
          <button
            type="button"
            class="btn btn-secondary btn-sm text-white mr-1"
            (click)="addmodalService.dismissAll()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn limos-button-background btn-sm text-white"
            (click)="updateDepartmentName()"
            [disabled]="
              departmentNameForm.invalid || isdepartmentNameFormSubmitted
            "
          >
            {{ editDepartmentNameData?.id ? "Update" : "Add" }}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
