<div class="container-fluid p-0" *blockUI="'manage-users-blockUI'">
    <div class="row">
        <div class="col-md-12">
            <div class="w-100 pt-4">
                <div class="row mb-2 mb-xl-3 justify-content-between align-items-center">
                    <div class="col-auto">
                        <h3 class="page-title">Manage users</h3>
                        <!-- <p>Get any excel to import document.</p> -->
                    </div>
                    <div class="col-auto btnGrp">
                        <button class="btn bg-voilet text-white px-3 py-2 ml-2" (click)="openModal()"><em class="fa fa-plus mr-2"></em>Add New User</button>
                    </div>
                </div>
                <div class="table-row">
                    <table class="table providerTable">
                        <caption>Provider Table</caption>
                        <thead>
                            <tr>
                                <th class="border-0 sorting" scope="col">Name</th>
                                <th class="border-0 sorting" scope="col">Email</th>
                                <th class="border-0 sorting" scope="col">Role</th>
                                <!-- <th class="border-0 sorting" scope="col">Status</th> -->
                                <th class="border-0 sorting" scope="col">Created On</th>
                                <!-- <th class="border-0 sorting" scope="col">UpdatedOn</th> -->
                                <th class="border-0" scope="col">Actions</th>
                            </tr>
                        </thead>
                            <tbody>
                                <tr class="bg-white" *ngFor="let user of users">
                                    <td class="primary-text text-uppercase font-weight-semibold">{{user.firstName}} {{user.lastName}}</td>
                                    <td class="">{{user.email}}</td>                                    
                                    <td class=""><span class="role">{{user.role}}</span></td>
                                    <!-- <td>{{user.status}}</td> -->
                                    <td class="">{{user.createdOn | dateTime}}</td>
                                    <!-- <td>{{user.updatedOn | dateTime}}</td> -->
                                    <td class=""><button class="btn text-voilet px-0"><em class="btn-bg rounded-circle fa fa-pencil edit" (click)="edit(user.userId)"></em></button>
                                    <button class="btn px-0 ml-3" (click)="activateOrInactive(user.userId, user.isActive)"><em *ngIf="user.isActive" title="active" class="btn-bg rounded-circle fa fa-check primary-text rounded-circle trash-icon"></em>
                                        <em *ngIf="!user.isActive" title="InActive" class="btn-bg rounded-circle fa fa-ban primary-text rounded-circle trash-icon"></em></button></td>
                                    </tr>
                            </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>