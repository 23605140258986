import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { AccountService } from '../services/account.service';
import { AuthService } from '../services/auth.service';
import { CurrentUserProfile } from '../models/account/current.user.profile';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'spear';
  componentWrapper = 'default';
  isUserAuthenticated = false;
  userRole = '';
  isAuthGuardActive: boolean;

  ifDashboard = false;


  constructor(private router: Router,
    private authService: AuthService,
    private accountService: AccountService) {

    this.isUserAuthenticated = this.authService.isAuthenticated();

    this.authService.listenToLoadCurrentUserProfile()
      .subscribe(() => {
        //console.log("listenToLoadCurrentUserProfile in app component");
          this.isUserAuthenticated = this.authService.isAuthenticated();
      });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const eventUrl = /(?<=\/).+/.exec(event.urlAfterRedirects);
        const currentRoute = (eventUrl || []).join('');
        const currentUrl = currentRoute.split('?')[0];
        const currentRouteConfig = this.router.config.find(f => f.path == currentUrl);
        this.isAuthGuardActive = currentRouteConfig != null && currentRouteConfig.canActivate != null;
        this.componentWrapper = currentRoute;
      }
    });

  }

  ngOnInit(): void {
    if (this.isUserAuthenticated) {
      this.getUserInfo();
    }
  }
  getDashboardConfiguration(): void {
    if (localStorage.getItem('predashboard')) {
      localStorage.getItem('predashboard') === 'false' ? this.ifDashboard = false : this.ifDashboard = true;
    } else {
      this.ifDashboard = true;
      localStorage.setItem('predashboard', 'true');
    }
  }
  getUserInfo(): void {
    this.accountService.getCurrentUserProfile().subscribe(
      (data: any) => {
        this.authService.notifyToSetUserInfo(data);
        this.isUserAuthenticated = true;
        this.getDashboardConfiguration();
        this.authService.setUserRole(data?.details?.role[0]);
      }, error => {
        if ((error.status == 0 || error.status === 401) && this.isAuthGuardActive) {
          this.authService.clearStorage();
          this.router.navigate(['/login']);
        }
        this.isUserAuthenticated = false;
      });
  }

  addItem(event): void {
    localStorage.setItem('predashboard', 'false');
    this.router.navigate(['/']);
    this.ifDashboard = false;
  }

}
