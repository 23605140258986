import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  Diary,
  LimosDataResponse,
  PaginationData,
} from 'src/app/models/limos/limos.models';
import { ProviderFilterModel } from 'src/app/models/provider/provider.filter.model';
import { LimosService } from 'src/app/services/limos.service';
import { ProviderPagedResult } from './../../models/provider/provider.paged.result';
import { RecordCount } from './../../models/provider/records.count';
import { ProviderService } from './../../services/provider.service';
import { AppUtils } from './../../utilities/app.utils';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  todayDate: Date = new Date();

  providers: ProviderPagedResult;
  recordsCount: RecordCount;
  providerfilterModel: ProviderFilterModel;
  searchText: string;
  searchCriteria: string[];
  page: number;
  pageSize: number;
  orderBy: string;
  isDescending: boolean;
  omitEmptyNames: boolean;
  showDublicateName: boolean;
  showNewRecords: boolean;
  providerType: number;
  SPRcount: any;
  caseDtls = [
    {
      tab: 'Diaries',
      columns: [
        'Case Number',
        'Name of Case',
        'Diary Type',
        'Diary Note',
        'Due Date',
      ],
    },
    {
      tab: 'Assigned Cases',
      columns: [
        'Case Number',
        'Name of Case',
        'Case Status',
        'Client',
        'Last Updated',
      ],
    },
  ];

  caseDtlsColumns = this.caseDtls[0].columns;
  private unsubscribe$ = new Subject<void>();
  @BlockUI('dashboard-screen') blockUI: NgBlockUI;
  @BlockUI('diaries-list-ui') public diariesBlockUI: NgBlockUI;

  diaryLimit = 30;
  diaryPage = 1;
  diaryTotal: number;
  diaries: Diary[] = [];
  selectedCaseTab: string;

  constructor(
    private providersService: ProviderService,
    private appUtils: AppUtils,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private limosService: LimosService
  ) {
    this.searchText = '';
    this.searchCriteria = [];
    this.page = 1;
    this.pageSize = 7;
    // this.orderBy = ProviderListHeadersEnum.createdDate as any;
    this.isDescending = false;
    this.omitEmptyNames = false;
    this.showDublicateName = false;
    this.showNewRecords = true;
    this.providerType = 1;
  }

  ngOnInit(): void {
    this.providers = new ProviderPagedResult();
    this.recordsCount = new RecordCount();
    this.providerfilterModel = new ProviderFilterModel(
      this.searchText,
      this.searchCriteria,
      this.page,
      this.pageSize,
      this.orderBy,
      this.isDescending,
      this.omitEmptyNames,
      this.showDublicateName,
      this.showNewRecords,
      this.providerType
    );
    //this.getRecordsCount();
    this.getProvidersPagedList();
    this.getSPRCount();
    this.getDiaries();
    this.setCaseDtlsColumns('Diaries');
  }

  getProvidersPagedList = (): void => {
    this.blockUI.start();
    this.providersService.getProviders(this.providerfilterModel).subscribe(
      (data) => {
        this.providers = data;
        this.blockUI.stop();
      },
      (error) => {
        this.appUtils.ProcessErrorResponse(this.toastr, error);
        this.blockUI.stop();
      }
    );
  };
  getRecordsCount = () => {
    this.blockUI.start();
    this.providersService.getRecordsCount().subscribe(
      (data) => {
        this.recordsCount = data;
      },
      (error) => {
        this.appUtils.ProcessErrorResponse(this.toastr, error);
        this.blockUI.stop();
      }
    );
  };

  getSPRCount = () => {
    this.blockUI.start();
    this.providersService.getSprCount('SPR').subscribe(
      (data) => {
        this.SPRcount = data;
        this.blockUI.stop();
      },
      (error) => {
        this.appUtils.ProcessErrorResponse(this.toastr, error);
        this.blockUI.stop();
      }
    );
  };

  openModal(content, size = 'md') {
    this.modalService.open(content, {
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'groupModal',
      size,
      centered: true,
    });
  }

  setCaseDtlsColumns(tab: string) {
    this.selectedCaseTab = tab;
    this.caseDtlsColumns = this.caseDtls.find((dtl) => dtl.tab == tab).columns;
  }

  getDiaries() {
    this.diariesBlockUI.start();
    this.limosService
      .getDiaries(this.diaryLimit, this.diaryPage)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp: LimosDataResponse<PaginationData<Diary>>) => {
          const { hits, estimatedTotalHits } = resp.details;
          this.diaries = hits;
          this.diaryTotal = estimatedTotalHits;
          this.diariesBlockUI.stop();
        },
        (error) => {
          console.error('Error fetching cases:', error);
          this.diariesBlockUI.stop();
        }
      );
  }

  onPageChangeDiaries(event: any) {
    this.diaryPage = event;
    this.getDiaries();
  }

  onSizeChangeDiaries(limit: number) {
    this.diaryLimit = limit;
    this.diaryPage = 1;
    this.getDiaries();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
